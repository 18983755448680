import { GroupMember } from "presentation/core/api/models";
import { DialogDataProps } from "presentation/core/components/dialog/_types";
import { Group } from "presentation/core/entities";
import { createProxy } from "presentation/share/utils/getPath";

export type HandoverDocumentPayloadType = DialogDataProps;

export type DocumentHandoverFormValues = {
  nextGroup?: Group["id"];
  nextOwner?: GroupMember["id"];
};

export const documentHandoverFormValuesProxy = createProxy<DocumentHandoverFormValues>();
