import {
  DInject,
  DInjectable
} from "presentation/core/features/dependencyInjection";
import { PaginationConfig } from "../../lib/contract/Pagination";
import { StoredDocumentRepositoryToken } from "../../domain/di/token/repository";
import { StoredDocumentApiRepository } from "../../infrastructure/api/repository/StoredDocumentApiRepository";
import { SortingConfig } from "lib/contract/Sorting";

@DInjectable()
export class FindStoredDocuments {
  constructor(
    @DInject(StoredDocumentRepositoryToken)
    protected repository: StoredDocumentApiRepository
  ) {}

  findAllPaginated(config: PaginationConfig, sorting?: SortingConfig) {
    return this.repository.getStoredDocuments(config, sorting);
  }
}
