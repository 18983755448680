import { PaginationConfig } from "lib/contract/Pagination";
import { useCallback } from "react";
import { SortingConfig } from "lib/contract/Sorting";
import { getOverviewsForSignature } from "../api";

export const useFindOverViewsForSignature = () => {
  return useCallback(
    (config: PaginationConfig, sorting?: SortingConfig) =>
      getOverviewsForSignature(config, sorting),
    [getOverviewsForSignature]
  );
};
