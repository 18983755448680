import { getType } from "typesafe-actions";
import {
  TableActionType,
  tableActionViewAction__Clear,
  tableActionViewAction__Refresh
} from "./_action";

export type TableActionStateType = {
  actionRefresh: boolean;
};
const initialState: TableActionStateType = {
  actionRefresh: false
};

const tableActionReducer = (
  state: TableActionStateType = initialState,
  action: TableActionType
): TableActionStateType => {
  switch (action.type) {
    case getType(tableActionViewAction__Refresh):
      return {
        actionRefresh: action.payload
      };

    case getType(tableActionViewAction__Clear):
      return initialState;

    default:
      return state;
  }
};

export default tableActionReducer;
