import { getService } from "presentation/core/features/dependencyInjection";
import { FindErasedDocument } from "../../../../useCase/recordRetention/FindErasedDocument";
import { useQM } from "../../../share/hook/query/useQM";

export const useFindDocumentErased = (nodeId: string) => {
  const findErasedDocument = getService(FindErasedDocument);

  return useQM(["recordRetention/erasedDocument", nodeId], () =>
    findErasedDocument.findErasedDocumentById(nodeId)
  );
};
