import { ReloadOutlined } from "@ant-design/icons";
import PreviewButton from "presentation/core/components/dialog/PreviewButton";
import { ComponentPreviewItem } from "presentation/core/components/dialog/_types";
import { renderComponentPreview } from "presentation/share/components/dialog/componentPreview";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import React, { useMemo } from "react";
import styled from "styled-components";
import { IconButton } from "../Button/IconButton";
import { Modal, ModalProps, ModalSize, ModalWithTabs } from "./Modal";

interface ChildrenWrapperProps {
  fullWidth: boolean;
}

interface ModalWithRefreshProps extends ModalProps {
  title: string;
  isShowRefresh?: boolean;
  hasTabs?: boolean;
  onRefresh?(): void;
  showPreview?: boolean;
  handleShowPreviewChange: (showPreview: boolean) => void;
  setPreviewItem: (previewItem: any) => void;
  previewItem?: ComponentPreviewItem;
}

export const ModalWithPreview: React.FC<ModalWithRefreshProps> = ({
  title,
  children,
  hasTabs = false,
  onRefresh,
  showPreview = false,
  handleShowPreviewChange,
  setPreviewItem,
  previewItem,
  ...props
}) => {
  const ModalComponent = hasTabs ? ModalWithTabs : Modal;

  const preview = useMemo(() => {
    return (
      showPreview &&
      Boolean(previewItem) &&
      renderComponentPreview?.(
        Boolean(previewItem?.fileIsEncrypted) || false,
        {},
        previewItem
      )
    );
  }, [showPreview, previewItem]);

  return (
    <ModalComponent
      size={preview ? ModalSize.FullScreen : ModalSize.Default}
      {...props}
      title={
        onRefresh || preview ? (
          <StyledTitleWrapper>
            {title}
            <StyledTitleActionsWrapper>
              {onRefresh && (
                <IconButton
                  icon={<ReloadOutlined rev={"default"} />}
                  tooltip={t(translationPath(lang.dialog.form.refresh))}
                  onClick={onRefresh}
                  style={{ top: "-1px" }}
                />
              )}

              {preview && (
                <PreviewButton
                  handlePreviewChange={handleShowPreviewChange}
                  showPreview={showPreview}
                  setPreviewItem={setPreviewItem}
                  previewItem={previewItem}
                />
              )}
            </StyledTitleActionsWrapper>
          </StyledTitleWrapper>
        ) : (
          title
        )
      }
    >
      <ContentWithPreviewContainer
        style={preview ? { display: "flex" } : { display: "block" }}
      >
        {preview && <PreviewContainer>{preview}</PreviewContainer>}
        <ContentContainer fullWidth={!preview}>{children}</ContentContainer>
      </ContentWithPreviewContainer>
    </ModalComponent>
  );
};

const ContentWithPreviewContainer = styled.div``;

const PreviewContainer = styled.div`
  border-right: 1px solid rgba(0, 0, 0, 0.4);
  margin-right: ${({ theme }: { theme: any }) => theme.margin.sm};
  margin-right: ${({ theme }: { theme: any }) => theme.margin.sm};
  max-width: 50%;
  width: 50%;
`;

const ContentContainer = styled.div<ChildrenWrapperProps>`
  max-width: ${(props: { fullWidth: any }) =>
    props.fullWidth ? "100%" : "50%"};
  min-width: 50%;
`;

const StyledTitleWrapper = styled("div")({
  display: "flex",
  position: "relative"
});

const StyledTitleActionsWrapper = styled("div")(
  ({ theme }: { theme: any }) => ({
    position: "absolute",
    right: theme.margin.lg,
    top: -theme.margin.xss,
    // buttons are close to the "close" modal button, sometimes when an user
    // clicks the nearest button to the "close" modal button, it closes the dialog.
    // So we need to provide bigger z-index to avoid this situation
    zIndex: 11
  })
);
