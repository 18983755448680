import { callAsyncAction } from "presentation/core/action";
import { secondaryAction } from "presentation/core/components/dialog/lib/actionsFactory";
import {
  DialogContentType,
  DialogDataGenericData,
  DialogType
} from "presentation/core/components/dialog/_types";
import NamedTitle from "presentation/core/components/namedTitle";
import React from "react";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import { SendShipmentContainer } from "./SendShipmentContainer";
import { sendShipmentAction } from "./_actions";
import { Notification } from "presentation/designSystem/notification/Notification";

export const sendShipmentDialogContent: DialogContentType = {
  actions: () => [
    secondaryAction(
      t(translationPath(lang.dialog.buttons.confirm)),
      ({ dialogProps, channels, buttonState, onClose, dispatch }) => {
        buttonState.setIsPending(true);
        const onError = () => {
          buttonState.setIsPending(false);
        };

        const onSuccess = () => {
          onClose();
        };
        if (channels?.contentTab?.state?.selectedComponentsIds?.length) {
          dispatch(
            callAsyncAction({
              action: sendShipmentAction,
              onError,
              onSuccess,
              payload: {
                nodeId: (dialogProps.data as DialogDataGenericData).id,
                shipmentType: (dialogProps.data as DialogDataGenericData)
                  .nodeType,
                shipmentsId:
                  channels?.contentTab?.state?.selectedComponentsIds || []
              }
            })
          );
        } else {
          Notification.error({
            message: t(
              translationPath(lang.dialog.notifications.noShipmentSelected)
            )
          });
          buttonState.setIsPending(false);
          return;
        }
      }
    )
  ],
  content: SendShipmentContainer,
  title: (props) => (
    <NamedTitle
      text={t(translationPath(lang.dialog.title.handoverShipmentsToDispatch))}
      {...props}
    />
  ),
  type: DialogType.SendShipment
};
