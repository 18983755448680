import { useMemo, useState } from "react";
import { cloneDeep } from "lodash";

import { FilePlanGroups } from "presentation/core/features/login/_types";

const defaultFiltering = (fileMark: FilePlanGroups) => {
  return fileMark.isValid;
};

const filterValidFileMarks = (
  fileMarks: FilePlanGroups[] = [],
  filteringFunction: (fileMark: FilePlanGroups) => boolean
): FilePlanGroups[] => {
  const validResult = [];
  for (const fileMark of fileMarks) {
    if (fileMark.isParent && fileMark.isValid) {
      const result = filterValidFileMarks(
        fileMark.filePlanGroups,
        filteringFunction
      );

      if (result.length) {
        validResult.push({
          ...fileMark,
          filePlanGroups: result
        });
      }
    }
    if (!fileMark.isParent && filteringFunction(fileMark)) {
      validResult.push(fileMark);
    }
  }

  return validResult;
};

export const useFileMarksSearch = (
  fileMarks: FilePlanGroups[],
  filteringFunction: (fileMark: FilePlanGroups) => boolean = defaultFiltering
) => {
  const defaultValue = "";
  const [searchedFileMarksValue, setSearchedFileMarksValue] = useState(
    defaultValue
  );

  const searchOccurrence = (
    array: FilePlanGroups[] = [],
    searchedValue: string
  ): FilePlanGroups[] => {
    const arr: FilePlanGroups[] = [];
    const arrayCopy = cloneDeep(array);
    for (const item of arrayCopy) {
      if (
        item.name.toLowerCase().includes(searchedValue.toLowerCase()) ||
        item.fullyDeterminedFileMark
          .toLowerCase()
          .includes(searchedValue.toLowerCase())
      ) {
        arr.push(item);
      }
      if (item.isParent) {
        const result = searchOccurrence(item?.filePlanGroups, searchedValue);

        if (!result || result.length === 0) {
          continue;
        }

        if (!arr.includes(item)) {
          item.filePlanGroups = result;
          arr.push(item);
        }
      }
    }
    return arr;
  };

  const validFileMarks = useMemo(
    () => filterValidFileMarks(fileMarks, filteringFunction),
    [fileMarks, filteringFunction]
  );

  const searchedFileMarks = (allFileMarks: FilePlanGroups[]) => {
    if (searchedFileMarksValue === defaultValue) {
      return allFileMarks;
    }
    return searchOccurrence(allFileMarks, searchedFileMarksValue);
  };

  const returnInputValue = (inputValue: string) => {
    setSearchedFileMarksValue(inputValue);
  };

  return {
    returnInputValue,
    searchedFileMarks,
    searchedFileMarksValue,
    validFileMarks
  };
};
