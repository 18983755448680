import { DialogContentPropsType } from "presentation/core/components/dialog/_types";
import MetaFormContainer from "presentation/core/components/MetaForm";
import React from "react";
import { RegisterDataboxForm } from "./RegisterDataboxForm";
import { SslDatabox } from "../../../../core/api/models";

export const MetadataFormTab = ({
  channel,
  dialogProps,
  onClose
}: DialogContentPropsType) => {
  return (
    <MetaFormContainer<SslDatabox>
      channel={channel}
      dialogProps={dialogProps}
      MetaForm={RegisterDataboxForm}
      onClose={onClose}
    />
  );
};
