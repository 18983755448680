import {
  DInject,
  DInjectable
} from "../../../presentation/core/features/dependencyInjection";
import { HttpClient } from "../../service/HttpClient";
import { ApiUrls } from "../struct/ApiTypesV2";
import {
  PaginatedResult,
  PaginationConfig
} from "../../../lib/contract/Pagination";
import { HistoryRepository } from "../../../domain/service/nameRegistry/HistoryRepository";
import { History as NameRegistryHistory } from "../../../domain/struct/nameRegistry/History";
import { ApiHistory, HistoryApiMapper } from "./HistoryApiMapper";
import { SortingConfig } from "@isfg/lib/build/contract/Sorting";

const URL: ApiUrls = {
  History: "/api/app/v2/name-register/subject/{subjectId}/history"
};

@DInjectable()
export class HistoryApiRepository implements HistoryRepository {
  constructor(@DInject(HttpClient) protected httpClient: HttpClient) {}

  findBySubjectId(
    subjectId: string,
    pagination: PaginationConfig,
    sorting?: SortingConfig
  ): Promise<PaginatedResult<NameRegistryHistory>> {
    return this.httpClient
      .fetchPaginated<ApiHistory>({
        url: URL.History,
        pagination,
        sorting,
        config: {
          urlWildCards: {
            subjectId
          }
        }
      })
      .then(HistoryApiMapper.mapHistoryToDomain);
  }
}
