import {
  DInject,
  DInjectable
} from "../../presentation/core/features/dependencyInjection";
import { PaginationConfig } from "../../lib/contract/Pagination";
import { RentedDocumentApiRepository } from "../../infrastructure/api/repository/RentedDocumentApiRepository";
import { RentedDocumentRepositoryToken } from "../../domain/di/token/repository";

@DInjectable()
export class FindRentedDocuments {
  constructor(
    @DInject(RentedDocumentRepositoryToken)
    protected repository: RentedDocumentApiRepository
  ) {}

  findAllPaginated(config: PaginationConfig) {
    return this.repository.getRentedDocuments(config);
  }
}
