import { defineAbility } from "@casl/ability";
import { User } from "../entities/user/User";
import { AllGroupType, UserGroupType } from "../features/login/_types";
import { getGroupInfo } from "./group";
import { definePresentationModuleAbility } from "./presentationModuleAbility";
import { AppAbility } from "./abilityType";

interface BuildAbilityForUserConfig {
  user?: User;
  isAdmin: boolean;
  activeGroup?: string;
  userGroups: UserGroupType[];
  allGroups: AllGroupType;
}

export const buildAbilityForUser = ({
  isAdmin,
  user,
  activeGroup = "",
  userGroups,
  allGroups
}: BuildAbilityForUserConfig) => {
  return defineAbility<AppAbility>((allow, forbid) => {
    // when user is empty it normally means, that user is unauthorized
    // but in our app, also when your are logged in as an admin, the user object is empty
    // so we have to check also the "isAdmin" property
    if (!user && !isAdmin) {
      return; // unauthorized user can do nothing
    }

    const groupInfo = getGroupInfo(activeGroup, allGroups, userGroups);

    definePresentationModuleAbility(allow, forbid, {
      isAdmin,
      activeGroup,
      groupInfo
    });

    if (!groupInfo.groupIsDispatch) {
      allow("view", "DocumentComponent");
    }
  });
};
