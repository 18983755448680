import { SslProperties } from "presentation/core/api/models";
import { useSelector } from "react-redux";
import { RootStateType } from "presentation/reducers";
import { useFileMark } from "../../../../share/hook/filePlan/useFileMark";

export const useFilePlans = (properties: SslProperties = {}) => {
  const filePlan = useFilePlan(properties.filePlan);
  const fileMark = useFileMark(properties.fileMark || "");

  return {
    ...properties,
    fileMark: fileMark
      ? `${fileMark.fullyDeterminedFileMark} - ${fileMark.name}`
      : "",
    filePlan: filePlan?.name || "",
    triggerActionId: fileMark?.triggerActionId || ""
  };
};

export const useFilePlan = (id?: string) => {
  const { filePlansFlat } = useSelector(
    (state: RootStateType) => state.loginReducer.global
  );

  return filePlansFlat?.find((plan) => plan.nodeId === id);
};
