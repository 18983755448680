import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { Formik } from "formik";
import { Select, TextField } from "formik-mui";
import { SslAnalog } from "presentation/core/api/models";
import { MetaFormProps } from "presentation/core/components/MetaForm/_types";
import Datepicker from "presentation/core/components/datepicker/DatePicker";
import {
  StyledField,
  StyledFieldWide,
  StyledFormControl,
  useStyles
} from "presentation/core/components/dialog/Dialog.styles";
import { SenderForm } from "presentation/core/components/senderForm/SenderForm";
import TimePicker from "presentation/core/components/timepicker";
import { sslPropsProxy } from "presentation/core/types";
import {
  DateTimeFormats,
  DeliveryMode,
  DocumentType
} from "presentation/enums";
import { SslDeliveryMode } from "presentation/share/components/form/fields/SSLDeliveryMode";
import {
  lastPathMember,
  translationPath
} from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { BaseField } from "../../../../core/components/form/fields/BaseField";
import { Form } from "../../../../designSystem/Form/v2/Form";
import { validate } from "./_validations";

const Component = ({
  initialValues,
  formRef,
  readonly
}: MetaFormProps<SslAnalog>) => {
  const [validating, setValidating] = useState(false);
  const [errors, setErrors] = useState({});
  const classes = useStyles();
  const handle = (): void => void 0;

  useEffect(() => {
    if (!!Object.keys(errors).length && validating) {
      document.getElementById("Ssl-delivery-mode")?.scrollIntoView();
    }
  }, [errors, validating]);

  return (
    <>
      <Formik<SslAnalog>
        initialValues={initialValues}
        validate={validate}
        innerRef={formRef || handle}
        onSubmit={handle}
      >
        {({ errors, isValidating }) => {
          setErrors(errors);
          setValidating(isValidating);

          return (
            <>
              <Form className={classes.form}>
                <Datepicker
                  data-test-id="meta-input-deliveryDate"
                  disabled={readonly}
                  disableFuture={true}
                  name={lastPathMember(sslPropsProxy.deliveryDate).path}
                  label={t(translationPath(lang.general.deliveryDate))}
                  required={true}
                />
                <TimePicker
                  format={DateTimeFormats.HoursMinutesSeconds}
                  data-test-id="carries-meta-input-deliveryTime"
                  disabled={readonly}
                  name={lastPathMember(sslPropsProxy.deliveryTime).path}
                  label={t(translationPath(lang.general.deliveryTime))}
                />
                <SslDeliveryMode
                  allowedModes={[
                    DeliveryMode.Currier,
                    DeliveryMode.Personally,
                    DeliveryMode.Post
                  ]}
                  disabled={readonly}
                  required={true}
                  id={"Ssl-delivery-mode"}
                />
                <StyledField
                  component={TextField}
                  data-test-id="meta-input-pid"
                  disabled={true}
                  name={lastPathMember(sslPropsProxy.pid).path}
                  type="text"
                  label={t(translationPath(lang.general.pid))}
                />

                <StyledFormControl>
                  <InputLabel htmlFor="form">
                    {t(translationPath(lang.general.form))}
                  </InputLabel>
                  <BaseField
                    component={Select}
                    data-test-id="meta-input-form"
                    disabled={true}
                    name={lastPathMember(sslPropsProxy.form).path}
                    inputProps={{
                      id: lastPathMember(sslPropsProxy.form).path
                    }}
                  >
                    <MenuItem value={DocumentType.Analog}>
                      {t(translationPath(lang.general.analog))}
                    </MenuItem>
                    <MenuItem value={DocumentType.Digital}>
                      {t(translationPath(lang.general.digital))}
                    </MenuItem>
                  </BaseField>
                </StyledFormControl>
                <StyledField
                  component={TextField}
                  data-test-id="meta-input-attachmentsCount"
                  disabled={readonly}
                  name={lastPathMember(sslPropsProxy.attachmentsCount).path}
                  type="number"
                  label={t(translationPath(lang.general.attachmentsCount))}
                />
                <StyledField
                  component={TextField}
                  data-test-id="meta-input-attachmentsType"
                  disabled={readonly}
                  name={lastPathMember(sslPropsProxy.attachmentsType).path}
                  type="string"
                  label={t(translationPath(lang.general.attachmentsType))}
                />
                <StyledField
                  component={TextField}
                  data-test-id="meta-input-listCount"
                  disabled={readonly}
                  name={lastPathMember(sslPropsProxy.listCount).path}
                  type="number"
                  label={t(translationPath(lang.general.listCount))}
                />

                <StyledField
                  component={TextField}
                  data-test-id="meta-input-listCountAttachments"
                  disabled={readonly}
                  name={lastPathMember(sslPropsProxy.listCountAttachments).path}
                  type="number"
                  label={t(translationPath(lang.general.listCountAttachments))}
                />
                <Datepicker
                  data-test-id="meta-input-settleTo"
                  disabled={readonly}
                  disablePast={true}
                  name={lastPathMember(sslPropsProxy.settleToDate).path}
                  label={t(translationPath(lang.general.settleToDate))}
                />
                <StyledField
                  component={TextField}
                  data-test-id="meta-input-senderIdent"
                  disabled={readonly}
                  name={lastPathMember(sslPropsProxy.senderIdent).path}
                  type="text"
                  label={t(translationPath(lang.general.senderIdent))}
                />
                <StyledField
                  component={TextField}
                  data-test-id="meta-input-senderSSID"
                  disabled={readonly}
                  name={lastPathMember(sslPropsProxy.senderSSID).path}
                  type="text"
                  label={t(translationPath(lang.general.senderSSID))}
                />

                <StyledFieldWide
                  component={TextField}
                  data-test-id="meta-input-subject"
                  disabled={readonly}
                  name={lastPathMember(sslPropsProxy.subject).path}
                  type="text"
                  label={t(translationPath(lang.general.subject))}
                />
                <StyledDiv>
                  <SenderForm
                    initialValues={initialValues}
                    readonly={readonly}
                    hidden={{ own: !readonly }}
                  />
                </StyledDiv>
              </Form>
            </>
          );
        }}
      </Formik>

      <Form name="analogForm">
        {/*  <Row gutter={30}>*/}
        {/*    <ColOneFourth>*/}
        {/*      <Item*/}
        {/*        data-test-id="meta-input-deliveryDate"*/}
        {/*        required={true}*/}
        {/*        name={"deliveryDate"}*/}
        {/*        label={t(translationPath(lang.documentMetaForm.deliveryDate))}*/}
        {/*      >*/}
        {/*        <DatePicker*/}
        {/*          style={{ width: "100%" }}*/}
        {/*          disabled*/}
        {/*          format={DateTimeFormat.DateTimeNoSeconds}*/}
        {/*        />*/}
        {/*      </Item>*/}
        {/*    </ColOneFourth>*/}
        {/*    <ColOneFourth>*/}
        {/*      <StyledItem*/}
        {/*        required={true}*/}
        {/*        name="deliveryTime"*/}
        {/*        label={t(translationPath(lang.general.deliveryTime))}*/}
        {/*      >*/}
        {/*        <DatePicker.TimePicker*/}
        {/*          // format={DateTimeFormats.HoursMinutesSeconds}*/}
        {/*          data-test-id="meta-input-deliveryTime"*/}
        {/*          disabled*/}
        {/*        />*/}
        {/*      </StyledItem>*/}
        {/*    </ColOneFourth>*/}
        {/*    <ColOneFourth>*/}
        {/*      <Item*/}
        {/*        required={true}*/}
        {/*        name={"deliveryMode"}*/}
        {/*        label={t(translationPath(lang.general.deliveryMode))}*/}
        {/*        // rules={validationAddressTypeRules}*/}
        {/*        data-test-id="meta-input-deliveryMode"*/}
        {/*      >*/}
        {/*        <Select*/}
        {/*          // loading={isFetching}*/}
        {/*          options={[*/}
        {/*            {*/}
        {/*              label: DeliveryMode.Email,*/}
        {/*              value: DeliveryMode.Email*/}
        {/*            }*/}
        {/*          ]}*/}
        {/*          disabled={true}*/}
        {/*        />*/}
        {/*      </Item>{" "}*/}
        {/*    </ColOneFourth>*/}
        {/*    <ColOneFourth>*/}
        {/*      <Item*/}
        {/*        required={true}*/}
        {/*        data-test-id="meta-input-pid"*/}
        {/*        name={"pid"}*/}
        {/*        label={t(translationPath(lang.documentMetaForm.pid))}*/}
        {/*      >*/}
        {/*        <Input disabled={true} />*/}
        {/*      </Item>*/}
        {/*    </ColOneFourth>*/}
        {/*  </Row>*/}
        {/*  <Row gutter={30}>*/}
        {/*    <ColOneFourth>*/}
        {/*      <Item*/}
        {/*        required={true}*/}
        {/*        name={"form"}*/}
        {/*        label={t(translationPath(lang.documentMetaForm.form))}*/}
        {/*        // rules={validationAddressTypeRules}*/}

        {/*        data-test-id="meta-input-form"*/}
        {/*      >*/}
        {/*        <Select*/}
        {/*          // loading={isFetching}*/}
        {/*          options={[*/}
        {/*            {*/}
        {/*              label: t(translationPath(lang.general.digital)),*/}
        {/*              value: DocumentType.Digital*/}
        {/*            }*/}
        {/*          ]}*/}
        {/*          disabled={true}*/}
        {/*        />*/}
        {/*      </Item>*/}
        {/*    </ColOneFourth>*/}
        {/*    <ColOneFourth>*/}
        {/*      <StyledItem*/}
        {/*        data-test-id="meta-input-attachmentsCount"*/}
        {/*        name={"attachmentCount"}*/}
        {/*        label={t(translationPath(lang.general.attachmentsCount))}*/}
        {/*      >*/}
        {/*        <StyledInputNumber />*/}
        {/*      </StyledItem>*/}
        {/*    </ColOneFourth>*/}
        {/*    <ColOneFourth>*/}
        {/*      <Item*/}
        {/*        data-test-id="meta-input-attachmentsType"*/}
        {/*        name={"attachmentType"}*/}
        {/*        label={t(translationPath(lang.general.attachmentsType))}*/}
        {/*      >*/}
        {/*        <Input />*/}
        {/*      </Item>*/}
        {/*    </ColOneFourth>*/}
        {/*    <ColOneFourth>*/}
        {/*      <Item*/}
        {/*        name={"settleToDate"}*/}
        {/*        label={t(translationPath(lang.general.settleToDate))}*/}
        {/*      >*/}
        {/*        <DatePicker*/}
        {/*          data-test-id="meta-input-settleToDate"*/}
        {/*          disabledDate={disablePastDates}*/}
        {/*        />*/}
        {/*      </Item>*/}
        {/*    </ColOneFourth>*/}
        {/*  </Row>*/}
        {/*  <Row gutter={30}>*/}
        {/*    <ColOneFourth>*/}
        {/*      <Item*/}
        {/*        data-test-id="carries-meta-input-senderIdent"*/}
        {/*        name={"senderIdent"}*/}
        {/*        label={t(translationPath(lang.general.senderIdent))}*/}
        {/*      >*/}
        {/*        <Input />*/}
        {/*      </Item>*/}
        {/*    </ColOneFourth>*/}
        {/*    <ColOneFourth>*/}
        {/*      <Item*/}
        {/*        data-test-id="carries-meta-input-senderSSID"*/}
        {/*        name={"senderSsid"}*/}
        {/*        label={t(translationPath(lang.general.senderSSID))}*/}
        {/*      >*/}
        {/*        <Input />*/}
        {/*      </Item>*/}
        {/*    </ColOneFourth>*/}
        {/*  </Row>*/}
        {/*  <Row gutter={30}>*/}
        {/*    <ItemSubjectStyled*/}
        {/*      data-test-id="meta-input-subject"*/}
        {/*      name={"subject"}*/}
        {/*      label={t(translationPath(lang.general.subject))}*/}
        {/*    >*/}
        {/*      <Input width={"100%"} />*/}
        {/*    </ItemSubjectStyled>*/}
        {/*  </Row>*/}
      </Form>
    </>
  );
};

export const AnalogEvidateMetadataForm = React.memo(
  Component
) as typeof Component;

const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`;
