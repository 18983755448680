import { getService } from "presentation/core/features/dependencyInjection";
import { FindPerson } from "useCase/nameRegistry/findPerson/FindPerson";
import { PaginationConfig } from "../../../../lib/contract/Pagination";
import { useCallback } from "react";
import { SortingConfig } from "lib/contract/Sorting";

export interface Config {
  onError?: (error: Error) => void;
  retry?: boolean | number;
  enabled?: boolean;
}

export const useFindPersons = () => {
  const findPersonsUseCase = getService(FindPerson);

  return useCallback(
    (config: PaginationConfig, sorting?: SortingConfig) =>
      findPersonsUseCase.findAllPaginated(config, sorting),
    [findPersonsUseCase]
  );
};
