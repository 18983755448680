import { createTheme } from "@mui/material";
import styled from "styled-components";
import { theme as designSystemTheme } from "../config/designSystem/theme";

export type ITheme = typeof theme;

export const theme = {
  colors: {
    backgroundSecondary: "#fefbf2",
    black: "#000",
    borderSecundary: "#000",
    buttonNegative: "#F40105",
    buttonPositive: "#288E00",
    buttonPrimary: "#fff",
    buttonPrimaryBackground: "#FF9600",
    buttonSecondary: "#000",
    buttonSecondaryBackground: "#fff !important",
    error: "#f44336",
    gray: "#808081",
    lightGray: "#d7d7d7",
    darkGray: "#313939",
    orange: "#FF9600",
    primary: "#141E1E",
    warning: "#ff9824",
    white: "#fff",
    whiteOpacity: "rgba(0,0,0,0.87)",
    green: "#82bea6",
    blue: "#1e5a93",
    lightBlue: "#3071b0"
  },
  drawerWidth: 250,
  fontFamily: "Roboto,Helvetica Neue,sans-serif",
  ...designSystemTheme
};

export const muiTheme = createTheme({
  components: {
    MuiDialog: {
      styleOverrides: {
        root: {
          zIndex: 1
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        containedSecondary: {
          color: "#ffffff"
        },
        root: {
          borderRadius: 0
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        colorPrimary: {
          "&.Mui-checked": {
            color: designSystemTheme.color.primary
          },
          color: designSystemTheme.color.primary
        }
      }
    },
    MuiRadio: {
      styleOverrides: {
        colorPrimary: {
          "&.Mui-checked": {
            color: designSystemTheme.color.primary
          },
          color: designSystemTheme.color.primary
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          lineHeight: 1,
          marginLeft: "0"
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&.MuiFormLabel-root--long-text": {
            top: "-10px !important"
          }
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          display: "flex",
          flexDirection: "column",
          maxHeight: "400px",
          overflowY: "scroll"
        }
      }
    },

    MuiTable: {
      styleOverrides: {
        root: {
          stickyHeader: {
            backgroundColor: "transparent"
          }
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&.MuiTableRow-selected": {
            "&:hover": { background: "rgba(255,150,0,0.06)" },
            background: "rgba(255,150,0,0.12)",
            borderColor: designSystemTheme.color.primary
          }
        }
      }
    },

    MuiTextField: {
      defaultProps: {
        variant: "filled"
      },
      styleOverrides: {
        root: {
          width: "100%"
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "#000",
          border: "1px solid #dadde9",
          color: "#fff",
          fontSize: 12,
          maxWidth: 220,
          padding: 10
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "0"
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: designSystemTheme.color.primary
        }
      }
    },
    MuiList: {
      styleOverrides: {
        root: {
          overflow: "hidden !important"
        }
      }
    }
  },
  palette: {
    background: { default: designSystemTheme.color.primary },
    primary: {
      main: "#000000"
    },
    secondary: {
      main: "#FF9600"
    }
  }
});

export default styled;
