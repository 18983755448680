import { ApiAlfrescoListResponse, ApiTypes } from "../struct/ApiTypes";
import { AlfrescoListResponseMapper } from "../mapper/AlfrescoListResponseMapper";
import { PaginatedResult } from "../../../lib/contract/Pagination";
import { Address } from "../../../domain/struct/nameRegistry/Address";
import { stringOrDefault } from "../../../lib/string";

export type ApiCreateAddress = ApiTypes["ISFG.SpisUm.ClientSide.Models.AddressBook.AddressCreate"];
export type ApiAddress = ApiTypes["ISFG.SpisUm.ClientSide.Models.NameRegister.AddressInfo"];

export class AddressApiMapper {
  static mapToApi(address: Address): ApiCreateAddress {
    const {
      country,
      postalCode,
      cityPart,
      city,
      orientationHouseNumberAddendum,
      orientationHouseNumber,
      houseNumber,
      houseNumberType,
      street,
      addressType
    } = address || {};
    return {
      addressType,
      city,
      cityDistrict: cityPart,
      countryCode: country,
      houseNumber,
      houseNumberType,
      street,
      houseReferenceNumber: orientationHouseNumber || null,
      houseReferenceNumberAppendix: orientationHouseNumberAddendum || null,
      zipCode: postalCode
    };
  }

  static mapAddressesToApi(addresses: Address[]): ApiCreateAddress[] {
    return addresses.map(AddressApiMapper.mapToApi);
  }

  static mapToDomain(address: ApiAddress): Address {
    const {
      addressType,
      city,
      cityDistrict,
      countryCode,
      houseNumber,
      houseNumberType,
      houseReferenceNumber,
      houseReferenceNumberAppendix,
      id,
      street,
      zipCode
    } = address || {};
    return {
      id,
      city: stringOrDefault(city),
      orientationHouseNumberAddendum: stringOrDefault(
        houseReferenceNumberAppendix
      ),
      postalCode: stringOrDefault(zipCode),
      orientationHouseNumber: stringOrDefault(houseReferenceNumber),
      country: stringOrDefault(countryCode),
      cityPart: stringOrDefault(cityDistrict),
      street: stringOrDefault(street),
      houseNumberType: stringOrDefault(houseNumberType),
      houseNumber: stringOrDefault(houseNumber),
      addressType: stringOrDefault(addressType)
    };
  }

  static mapAddressesToDomain(
    addresses: ApiAlfrescoListResponse<ApiAddress>
  ): PaginatedResult<Address> {
    return AlfrescoListResponseMapper.mapListToPagination(
      addresses,
      AddressApiMapper.mapToDomain
    );
  }
}
