import { useStyles } from "presentation/core/components/dialog/Dialog.styles";
import { DialogContentType } from "presentation/core/components/dialog/_types";
import React from "react";
import { ShreddingDiscardForm } from "./ShreddingDiscardForm";
import { ShreddingDiscardFormValues } from "./_types";
import { validate } from "./_validations";
import { ChannelForm } from "../ChannelForm/ChannelForm";

export const ShreddingDiscardContentDialog: DialogContentType["content"] = ({
  channel
}) => {
  const classes = useStyles();

  return (
    <div className={classes.modalSmall}>
      <ChannelForm<ShreddingDiscardFormValues>
        initialValues={{
          date: null,
          reason: ""
        }}
        validate={validate}
        channel={channel}
        component={ShreddingDiscardForm}
      />
    </div>
  );
};
