import { Location } from "presentation/modules/nameRegister/lib/location/contract/Location";
import { SubmenuItemType } from "presentation/core/components/menu/_types";
import React from "react";

export const createMenuItemFromLocation = (
  location: Location,
  menuItemConfig: Partial<SubmenuItemType>
): SubmenuItemType => {
  return {
    ...menuItemConfig,
    url: location.url,
    content: <location.component />
  };
};
