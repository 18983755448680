import { createColumn } from "presentation/designSystem/Table/column/column";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";

export const createSenderNameColumn = <Row extends { senderName?: string }>() =>
  createColumn<Row>({
    dataIndex: "senderName",
    title: t(translationPath(lang.general.sender)),
    render: (text, row) => {
      return row.senderName || t(translationPath(lang.general.own));
    }
  });
