import { ApiListResponse, ApiTypes } from "../struct/ApiTypesV2";
import { ErasedDocument } from "../../../domain/struct/recordRetention/ErasedDocument";
import { stringOrDefault } from "../../../lib/string";
import { ApiListResponseMapper } from "../mapper/ApiListResponseMapper";

export type ApiErasedDocument = ApiTypes["ISFG.SpisUm.ClientSide.Models.V2.Document.Response.DocumentErasedResponse"];
export class ErasedDocumentApiMapper {
  static mapListToDomain(response: ApiListResponse<ApiErasedDocument>) {
    return ApiListResponseMapper.mapListToPagination(
      response,
      ErasedDocumentApiMapper.mapToDomainErasedDocument
    );
  }

  static mapToDomainErasedDocument(
    response: ApiErasedDocument
  ): ErasedDocument {
    const {
      pid,
      nodeId,
      ssid,
      nodeType,
      createdDate,
      deliveryDate,
      eraseDate,
      filePlan,
      fileMark,
      reviewer,
      shreddingOperationReason,
      idDa,
      decisionDa,
      state,
      subject
    } = response;
    return {
      nodeId: stringOrDefault(nodeId),
      nodeType: stringOrDefault(nodeType),
      pid: stringOrDefault(pid),
      ssid: stringOrDefault(ssid),
      createdDate: stringOrDefault(createdDate),
      deliveryDate: stringOrDefault(deliveryDate),
      eraseDate: stringOrDefault(eraseDate),
      filePlan: stringOrDefault(filePlan),
      fileMark: stringOrDefault(fileMark),
      reviewer: stringOrDefault(reviewer),
      shreddingOperationReason: stringOrDefault(shreddingOperationReason),
      idDa: stringOrDefault(idDa),
      decisionDa: stringOrDefault(decisionDa),
      state: stringOrDefault(state),
      subject: stringOrDefault(subject)
    };
  }
}
