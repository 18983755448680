import { FilterOptionsState } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { Form } from "formik";
import { Select } from "formik-mui";
import { GroupMember } from "presentation/core/api/models";
import {
  StyledAutocompleteHalf,
  StyledFormControlHalf,
  useStyles
} from "presentation/core/components/dialog/Dialog.styles";
import { BaseField } from "presentation/core/components/form/fields/BaseField";
import FormControlWithError from "presentation/core/components/formControlWithError";
import FormikAutocomplete from "presentation/core/components/formikAutocomplete/FormikAutocomplete";
import {
  AutocompleteOptionsType,
  PossibleAutocompleteOptions
} from "presentation/core/components/formikAutocomplete/_types";
import { FormState } from "presentation/core/components/reactiveFormik/_types";
import { useGroupMembers } from "presentation/core/hooks/useGroupMembers";
import {
  lastPathMember,
  translationPath
} from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import React from "react";
import {
  DocumentHandoverFormValues,
  documentHandoverFormValuesProxy
} from "./_types";
import { useGroupsToHandover } from "./useGroupsToHandover";

const NUMBER_OF_ENTERED_CHARS = 3;

export const DocumentHandoverForm = React.memo(
  ({ values }: FormState<DocumentHandoverFormValues>) => {
    const classes = useStyles();
    const { data: groups } = useGroupsToHandover();

    const { entities: groupMembers, loading } = useGroupMembers(
      values.nextGroup
    );
    const nextGroup = !!values?.nextGroup;

    const getGroupMembersOptionLabel = (option: GroupMember) => {
      return option.displayName;
    };

    const filterGroupMembersOptions = (
      opts: AutocompleteOptionsType,
      state: FilterOptionsState<PossibleAutocompleteOptions>
    ) => {
      const { inputValue } = state;
      if (!inputValue || inputValue.length < NUMBER_OF_ENTERED_CHARS)
        return opts;
      return opts.filter((opt) =>
        opt.displayName.toLowerCase().includes(inputValue.toLocaleLowerCase())
      );
    };

    return (
      <Form className={classes.form}>
        <FormControlWithError
          name={lastPathMember(documentHandoverFormValuesProxy.nextGroup).path}
          component={StyledFormControlHalf}
        >
          <InputLabel
            htmlFor={
              lastPathMember(documentHandoverFormValuesProxy.nextGroup).path
            }
            required={true}
          >
            {t(translationPath(lang.dialog.handover.organizationlUnit))}
          </InputLabel>
          <BaseField
            component={Select}
            data-test-id="document-handover-form-nextGroup"
            name={
              lastPathMember(documentHandoverFormValuesProxy.nextGroup).path
            }
            inputProps={{
              id: lastPathMember(documentHandoverFormValuesProxy.nextGroup).path
            }}
          >
            {groups?.map((group) => (
              <MenuItem key={group.id} value={group.id}>
                {group.displayName}
              </MenuItem>
            ))}
          </BaseField>
        </FormControlWithError>

        {groupMembers && (
          <BaseField
            autocompleteComponent={StyledAutocompleteHalf}
            component={FormikAutocomplete}
            disabled={!nextGroup}
            disableOnLoad={true}
            getOptionLabel={getGroupMembersOptionLabel}
            label={t(translationPath(lang.dialog.handover.worker))}
            loading={loading}
            name={
              lastPathMember(documentHandoverFormValuesProxy.nextOwner).path
            }
            options={groupMembers}
            optionValueProperty="id"
            filterOptions={filterGroupMembersOptions}
          />
        )}
      </Form>
    );
  }
);
