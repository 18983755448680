import { isObject } from "../object/typeChecker";

export interface ActionResult {
  dataWasMutated: boolean;
}

export const isActionResult = (
  maybeResult: unknown
): maybeResult is ActionResult => {
  return isObject(maybeResult) && "dataWasMutated" in maybeResult;
};
