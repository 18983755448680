import { GroupMember } from "presentation/core/api/models";
import { createProxy } from "presentation/share/utils/getPath";
import { FetchStateType } from "presentation/types";
import { Configuration } from "../../../../domain/struct/configuration/Configuration";
import { User } from "../../entities/user/User";

export interface AllGroupType {
  dispatch: GroupMember[];
  main: GroupMember[];
  repository: GroupMember[];
  fileNodes: GroupMember[];
}

interface GlobalVariablesType {
  filePlans: FilePlan[];
  filePlansFlat: FilePlan[];
  validFilePlans: FilePlan[];
  fileMarkMap: FileMarkMap;
  expire: number;
  groups: AllGroupType;
  maxItems: number;
  paths: SslPathsType[];
}

export interface UserGroupType {
  isRoot: boolean;
  displayName: string;
  id: string;
}

export interface LoginRequestType {
  password: string;
  remember: boolean;
  username: string;
}

export interface LoginResponseType {
  remember: SessionRememberType;
  user: string;
  configuration: Configuration;
}

export interface LoginSetSessionType {
  domain: string;
  isAdmin: boolean;
  token: string;
}

export interface LoginFormValuesType {
  password: string;
  remember: boolean;
  username: string;
}

export const LoginFormValuesTypeProxy = createProxy<LoginFormValuesType>();

export type LoginStateType = FetchStateType &
  Readonly<{
    expireIn: number | null;
    configuration: Configuration;
    global: GlobalVariablesType;
    keepPending: boolean;
    logoutPending: boolean;
    session: SessionType;
  }>;

export enum SessionStatus {
  UNAUTHORIZED,
  AUTHORIZED
}

export type SessionRememberType = {
  password: string;
  username: string;
} | null;

export interface SessionType {
  domain: string;
  isAdmin?: boolean;
  activeGroup?: string;
  groups: UserGroupType[];
  myGroups: UserGroupType[];
  remember: SessionRememberType;
  status: SessionStatus;
  token?: string;
  user?: User;
}

export interface SslPathsType {
  childs?: SslPathsType[];
  name: string;
  path: string;
}

export interface FilePlanGroups {
  identificator: string;
  isValid: boolean;
  isParent: boolean;
  validFrom: string;
  validTo: string;
  name: string;
  fullyDeterminedFileMark: string;
  retentionPeriod?: number;
  retentionMark?: string;
  triggerActionId?: string;
  filePlanGroups?: FilePlanGroups[];
  isTypeFileRestricted?: boolean;
  depth?: number;
}

export interface FilePlan {
  id: string;
  nodeId: string;
  filePlanGroups: FilePlanGroups[];
  name: string;
  validFrom: string;
  validTo?: string;
}

export interface FileMarkMap {
  [key: string]: FilePlanGroups;
}
