import React from "react";
import * as Yup from "yup";
import { DialogContentType } from "presentation/core/components/dialog/_types";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import { DocumentHandoverFormValues } from "./_types";
import { DocumentHandoverForm } from "./DocumentHandoverForm";
import { ChannelForm } from "../ChannelForm/ChannelForm";

const documentHandoverFormSchema = Yup.object().shape({
  nextGroup: Yup.string()
    .trim()
    .required(t(translationPath(lang._validations.required)))
});

export const DocumentHandoverContentDialog: DialogContentType["content"] = ({
  channel
}) => {
  return (
    <ChannelForm<DocumentHandoverFormValues>
      initialValues={{
        nextGroup: "",
        nextOwner: ""
      }}
      validationSchema={documentHandoverFormSchema}
      channel={channel}
      component={DocumentHandoverForm}
    />
  );
};
