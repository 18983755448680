import { getService } from "presentation/core/features/dependencyInjection";
import { FindRentedDocuments } from "../../../../../../useCase/repository/FindRentedDocuments";
import { PaginationConfig } from "../../../../../../lib/contract/Pagination";
import { useCallback } from "react";

// 5. step - prepare to connect use case to presentation
export const useFindRentedDocuments = () => {
  const findRentedDocuments = getService(FindRentedDocuments);

  return useCallback(
    (config: PaginationConfig) => findRentedDocuments.findAllPaginated(config),
    [findRentedDocuments]
  );
};
