export const databoxMapper = {
  databoxAcceptanceTime: "acceptanceTime",
  databoxAllowSubstDelivery: "allowSubstDelivery",
  databoxLegalTitleLaw: "legalTitleLaw",
  databoxLegalTitlePar: "legalTitlePar",
  databoxLegalTitlePoint: "legalTitlePoint",
  databoxLegalTitleSect: "legalTitleSect",
  databoxLegalTitleYear: "legalTitleYear",
  databoxMessageState: "messageState",
  databoxMessageType: "messageType",
  databoxPersonalDelivery: "personalDelivery",
  databoxRecipient: "recipient",
  databoxRecipientAddress: "recipientAddress",
  databoxRecipientDataBoxType: "recipientDataBoxType",
  databoxRecipientDocumentIdent: "recipientDocumentIdent",
  databoxRecipientDocumentRefNumber: "recipientDocumentRefNumber",
  databoxRecipientName: "recipientName",
  databoxSender: "sender",
  databoxSenderAddress: "senderAddress",
  databoxSenderDataBoxType: "senderDataBoxType",
  databoxSenderIdent: "senderIdent",
  databoxSenderName: "senderName",
  databoxSenderRefNumber: "senderRefNumber",
  databoxToHands: "toHands",
  digitalDeliveryAttachmentsCount: "attachmentsCount",
  digitalDeliveryDeliveryDate: "deliveryDate",
  digitalDeliveryNotRegisteredReason: "notRegisteredReason",
  digitalDeliverySubject: "subject"
};

export const emailMapper = {
  digitalDeliveryAttachmentsCount: "attachmentsCount",
  digitalDeliveryDeliveryDate: "deliveryDate",
  digitalDeliveryNotRegisteredReason: "notRegisteredReason",
  digitalDeliverySubject: "subject",
  emailMessageId: "messageId",
  emailRecipient: "recipient",
  emailRecipientName: "recipientName",
  emailSender: "sender",
  emailSenderName: "senderName"
};
