import MenuLayout from "presentation/core/components/layout/MenuLayout";
import React from "react";
import { withTranslation } from "presentation/translation/i18n";
import { ForSignatureTable } from "./tables/ForSignatureTable";

const SignatureBookForSignature = () => {
  return (
    <MenuLayout>
      <ForSignatureTable />
    </MenuLayout>
  );
};

export default withTranslation()(SignatureBookForSignature);
