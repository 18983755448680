import { ListItemButton, ListItemText, MenuItem, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { theme } from "presentation/styles";
import styled from "styled-components";
import isfgPowered from "../../../../assets/icons/isfgPowered.svg";

export const StyledListItemTextWithoutWidth = styled(ListItemText).attrs<any>(
  ({ level }) => ({
    style: {
      paddingLeft: level > 1 ? level * 10 + 35 : level * 45
    }
  })
)`
  & > .MuiTypography-body1 {
    font-size: 15px;
    font-weight: 700;
    margin-left: 8px;
    vertical-align: sub;
  }

  & > li {
    background: #ff9600 !important;
  }

  margin-bottom: 3px;
  margin-top: 0;
  min-width: auto;
`;

// border-bottom: 2px solid rgba(255,255,255,.06);
//     width: 168px;
//     margin-left: 52px;
export const StyledListItemButtonWithPadding = styled(ListItemButton)<{
  padding: number;
}>({
  "&:hover": {},
  lineHeight: "30px !important",
  maxHeight: "48px !important",
  paddingLeft: 1,
  paddingRight: 1
});

export const MenuItemStyled = styled(MenuItem)(() => ({
  alignItems: "center",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "row",
  height: "inherit",
  padding: "0 16px",
  position: "relative"
}));

export const useStyles: Function = makeStyles((muiTheme: Theme) =>
  createStyles({
    active: {
      "& span": {
        margin: "8px 0px 0px 0px",
        textAlign: "center",
        width: 100
      },
      background: `${theme.colors.orange} !important`,
      borderRadius: 2,
      color: "#FFFFFF",

      height: 38,
      letterSpacing: 0,
      textAlign: "left"
    },
    closeMainMenu: {
      backgroundColor: `${theme.colors.orange} !important`,
      color: "#FFFFFF"
    },
    closeMenuItem: {
      top: 20
    },
    collapse: {
      "& .MuiListItem-root": {
        maxHeight: "36px !important"
      },
      backgroundColor: `${theme.colors.darkGray} !important`,
      color: "#FFFFFF",

      fontSize: 15,
      letterSpacing: 0,
      textAlign: "left"
    },
    hide: {
      display: "none"
    },
    listItemIcon: {
      "& > svg": {
        fontSize: 25,
        color: `${theme.colors.lightGray}`
      },
      color: `${theme.colors.white} !important`,
      height: 30,
      width: "fit-content",
      minWidth: "0 !important"
    },
    mainMenu: {
      backgroundColor: `${theme.colors.darkGray} !important`,
      color: `${theme.color.primary} !important`
    },
    mainSubMenu: {
      backgroundColor: "#313939"
    },
    menu: {
      left: 0,
      marginTop: 0
    },

    subMenu: {
      "& .MuiDivider-root": {
        marginLeft: 52,
        width: 168
      },
      "& .MuiTypography-body1": {
        fontSize: "14px !important",
        fontWeight: "400 !important",
        maxHeight: "36px !important"
      },
      color: "#EFF2F5 !important",
      fontFamily: "none",
      letterSpacing: 0,

      textAlign: "left"
    },
    treeSubMenu: {
      "& .MuiTypography-body1": {
        fontSize: "14px !important",
        fontWeight: "400 !important",
        maxHeight: "36px !important"
      },
      backgroundColor: `${theme.colors.darkGray} !important`,
      color: `${theme.colors.white} !important`,

      letterSpacing: 0,

      textAlign: "left"
    },
    treeSubMenuParent: {
      "& .MuiTypography-body1": {
        color: `${theme.color.primary} !important`,
        fontSize: "14px !important",
        fontWeight: "700 !important"
      },
      "&:hover": {
        backgroundColor: "#313939"
      },
      backgroundColor: `${theme.colors.darkGray} !important`,
      color: "#FFFFFF"
    }
  })
);

export const StyledPoweredByISFG = styled("div")<{}>(() => ({
  background: `url(${isfgPowered}) center center no-repeat`,
  backgroundSize: "contain",
  bottom: "15px",
  height: "23px",
  position: "absolute",
  width: "100%"
}));
