export const trimEnd = (str: string, char: string) => {
  if (str.endsWith(char)) {
    return str.substr(0, str.length - char.length);
  }

  return str;
};

export const stringOrDefault = (
  maybeString?: string | null | number,
  defaultValue = undefined
) => {
  return maybeString ? String(maybeString) : defaultValue;
};
