import { TableActions } from "@isfg/react-components/build/Table/TableActionsBar/contract";
import { ColumnType } from "presentation/designSystem/Table/Table";
import {
  createColumn,
  createDateTimeColumn
} from "presentation/designSystem/Table/column/column";
import React, { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { RentedEvidenceDocument } from "../../../../../../../domain/struct/evidence/RentedEvidenceDocument";
import { RentedDocument } from "../../../../../../../domain/struct/repository/RentedDocument";
import { dialogOpenAction } from "../../../../../../core/components/dialog/_actions";
import { DialogType } from "../../../../../../core/components/dialog/_types";
import { getNodeTypeByIdentifier } from "../../../../../../core/mappers/api/general";
import { BreadcrumbForTable } from "../../../../../../designSystem/Breadcrumb/Breadcrumb";
import { MenuFoldOutlined } from "../../../../../../designSystem/Icon/Icon";
import {
  GetRemoteDataClb,
  RemoteTable
} from "../../../../../../designSystem/Table/RemoteTable";
import { DocumentType } from "../../../../../../enums";
import { actionShowDetail } from "../../../../../../share/components/table/actionFactory";
import { FileMarkColumnValue } from "../../../../../../share/components/table/column/document/FilePlan/FileMarkColumnValue";
import { FilePlanColumnValue } from "../../../../../../share/components/table/column/document/FilePlan/FilePlanColumnValue";
import { getDetailActionForDocumentNewDataModel } from "../../../../../../share/utils/action/dialog/documentDetail";
import { translationPath } from "../../../../../../share/utils/getPath";
import { lang, t } from "../../../../../../translation/i18n";
import { useFindRentedEvidenceDocuments } from "../hooks/useFindRentedEvidenceDocuments";
import RepositoryGroupRow from "./RepositoryGroupRow";

const COLUMNS: ColumnType<RentedEvidenceDocument>[] = [
  createColumn({
    dataIndex: "pid",
    title: t(translationPath(lang.general.identifier))
  }),
  createColumn({
    dataIndex: "fileIdentifier",
    title: `${t(translationPath(lang.general.referenceNumber))}/${t(
      translationPath(lang.general.fileIdentifier)
    )}`,
    render: (text, row) => {
      if (row.fileIdentifier) {
        return row.fileIdentifier;
      }
      return row.ssid;
    }
  }),
  createColumn({
    dataIndex: "subject",
    title: t(translationPath(lang.general.subject))
  }),
  createDateTimeColumn({
    dataIndex: "createdAt",
    title: t(translationPath(lang.general.dateOfEvidence))
  }),
  createColumn({
    dataIndex: "filePlan",
    title: t(translationPath(lang.general.filePlan)),
    render: (text, row) => {
      const { filePlanId } = row;
      if (!filePlanId) {
        return null;
      }
      return <FilePlanColumnValue filePlanId={filePlanId} />;
    }
  }),
  createColumn({
    dataIndex: "fileMark",
    title: t(translationPath(lang.general.fileMark)),
    render: (text, row) => {
      const { fileMark } = row;
      if (!fileMark) {
        return null;
      }
      return <FileMarkColumnValue fileMarkId={fileMark} />;
    }
  }),
  createColumn({
    dataIndex: "retentionMode",
    title: t(translationPath(lang.general.retentionMode))
  }),
  createColumn({
    dataIndex: "repositoryGroup",
    title: t(translationPath(lang.general.repositoryName)),
    render: (text, row) => (
      <RepositoryGroupRow groupName={row.repositoryGroup} />
    )
  })
];

export function RentedEvidenceDocumentsTable() {
  const dispatch = useDispatch();
  const findRentedDocuments = useFindRentedEvidenceDocuments();

  const ACTIONS: TableActions<RentedEvidenceDocument> = useMemo(
    () => ({
      single: [
        actionShowDetail({
          action(selected: RentedEvidenceDocument[]) {
            const nodeType = getNodeTypeByIdentifier(selected[0]);
            const action = getDetailActionForDocumentNewDataModel(nodeType);
            dispatch(
              action({
                canUploadComponents: false,
                data: {
                  id: selected[0].nodeId,
                  nodeType
                },
                hideManageShipmentsIcon: true,
                initiator: nodeType,
                isReadonly: true
              })
            );
            return Promise.resolve();
          }
        }),
        {
          key: "return",
          icon: <MenuFoldOutlined rev={"default"} />,
          tooltip: t(translationPath(lang.general.return)),
          canBeDisplayed(selected) {
            return (
              selected.form === DocumentType.Digital ||
              selected.form === DocumentType.AD
            );
          },
          action(selected: RentedEvidenceDocument[]) {
            dispatch(
              dialogOpenAction({
                dialogProps: {
                  data: {
                    id: selected[0].nodeId,
                    nodeType: getNodeTypeByIdentifier(selected[0])
                  }
                },
                dialogType: DialogType.ReturnToRepository
              })
            );
            return Promise.resolve();
          }
        }
      ]
    }),
    [dispatch]
  );

  const getRemoteData: GetRemoteDataClb<RentedDocument> = useCallback(
    ({ pagination }) => findRentedDocuments(pagination),
    [findRentedDocuments]
  );

  return (
    <>
      <BreadcrumbForTable title={"borrowed"} />
      <TableStyled>
        <RemoteTable<RentedEvidenceDocument>
          name="evidence/rentedDocuments"
          rowKey="pid"
          actions={ACTIONS}
          columns={COLUMNS}
          getRemoteData={getRemoteData}
        />
      </TableStyled>
    </>
  );
}
const TableStyled = styled("div")`
  div > .ant-row {
    height: 50px;
  }
`;
