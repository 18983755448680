import {
  DInject,
  DInjectable
} from "presentation/core/features/dependencyInjection";
import { ApiUrls } from "infrastructure/api/struct/ApiTypesV2";
import { HttpClient, HttpMethod } from "infrastructure/service/HttpClient";
import { ErasedFileRepository } from "domain/service/recordRetention/ErasedFileRepository";
import {
  ErasedFile,
  ErasedFileId
} from "../../../domain/struct/recordRetention/ErasedFile";
import { ApiErasedFile, ErasedFileApiMapper } from "./ErasedFileApiMapper";
import {
  ApiErasedDocument,
  ErasedDocumentApiMapper
} from "./ErasedDocumentApiMapper";
import { ErasedDocument } from "../../../domain/struct/recordRetention/ErasedDocument";
import { PaginatedResult, PaginationConfig } from "lib/contract/Pagination";

const URL: ApiUrls = {
  GetOneErasedFile: "/api/app/v2/file/{nodeId}/erased",
  GetErasedDocumentsByFile: "/api/app/v2/file/{nodeId}/erased-documents"
};

@DInjectable()
export class ErasedFileApiRepository implements ErasedFileRepository {
  constructor(@DInject(HttpClient) protected httpClient: HttpClient) {}

  findErasedFileById(id: ErasedFileId): Promise<ErasedFile> {
    return this.httpClient
      .fetchWithThrow<ApiErasedFile>(URL.GetOneErasedFile, HttpMethod.GET, {
        urlWildCards: {
          nodeId: id
        }
      })
      .then(ErasedFileApiMapper.mapToDomainErasedFile);
  }

  findErasedDocumentsByFileId(
    fileId: ErasedFileId,
    pagination: PaginationConfig
  ): Promise<PaginatedResult<ErasedDocument>> {
    return this.httpClient
      .fetchPaginated<ApiErasedDocument>({
        url: URL.GetErasedDocumentsByFile,
        pagination,
        config: {
          urlWildCards: {
            nodeId: fileId
          }
        }
      })
      .then(ErasedDocumentApiMapper.mapListToDomain);
  }
}
