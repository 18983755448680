import {
  DInject,
  DInjectable
} from "presentation/core/features/dependencyInjection";
import { SortingConfig } from "lib/contract/Sorting";
import { PaginationConfig } from "lib/contract/Pagination";
import { RetentionProtocolsErasedRepositoryToken } from "../../domain/di/token/recordRetention";
import { RetentionProtocolsErasedApiRepository } from "../../infrastructure/api/recordRetention/RetentionProtocolsErasedApiRepository";

@DInjectable()
export class FindRetentionProtocolsErased {
  constructor(
    @DInject(RetentionProtocolsErasedRepositoryToken)
    protected repository: RetentionProtocolsErasedApiRepository
  ) {}

  findAllPaginated(config: PaginationConfig, sorting?: SortingConfig) {
    return this.repository.getRetentionProtocolsErased(config, sorting);
  }
  findOneRetentionErasedProtocol(nodeId: string) {
    return this.repository.findRetentionErasedProtocolById(nodeId);
  }
  findArchived(config: PaginationConfig, nodeId: string) {
    return this.repository.findArchived(config, nodeId);
  }
  findDestroyed(config: PaginationConfig, nodeId: string) {
    return this.repository.findDestroyed(config, nodeId);
  }
}
