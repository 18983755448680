import { PaginatedResult } from "@isfg/lib/build/contract/Pagination";
import { ApiListResponse } from "../../../../../../infrastructure/api/struct/ApiTypesV2";
import { ApiListResponseMapper } from "../../../../../../infrastructure/api/mapper/ApiListResponseMapper";
import {
  ClosedTypeFilesResponse,
  OpenTypeFilesResponse,
  TransactionHistory,
  TransactionHistoryResponse,
  TypeFileDocumentsResponse
} from "../types";

export const mapOpenTypeFilesPaginated = (
  apiResponse: ApiListResponse<OpenTypeFilesResponse>
): PaginatedResult<OpenTypeFilesResponse> => {
  return ApiListResponseMapper.mapListToPagination(
    apiResponse,

    (response) => response
  );
};

export const mapClosedTypeFilesPaginated = (
  apiResponse: ApiListResponse<ClosedTypeFilesResponse>
): PaginatedResult<ClosedTypeFilesResponse> => {
  return ApiListResponseMapper.mapListToPagination(
    apiResponse,

    (response) => response
  );
};

export const mapTypeFilesDocumentsPaginated = (
  apiResponse: ApiListResponse<TypeFileDocumentsResponse>
): PaginatedResult<TypeFileDocumentsResponse> => {
  return ApiListResponseMapper.mapListToPagination(
    apiResponse,

    (response) => response
  );
};

export const mapTypeFilesHistoryPaginated = (
  apiResponse: ApiListResponse<TransactionHistoryResponse>
): PaginatedResult<TransactionHistory> => {
  return ApiListResponseMapper.mapListToPagination(
    apiResponse,

    (response) => {
      return { ...response, occurredAt: response.occuredAt };
    }
  );
};
