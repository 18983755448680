import { Form } from "formik";
import { TextField } from "formik-mui";
import {
  StyledFieldWide,
  useStyles
} from "presentation/core/components/dialog/Dialog.styles";
import { DialogContentPropsType } from "presentation/core/components/dialog/_types";
import { lastPathMember } from "presentation/share/utils/getPath";
import { ChannelForm } from "../../../share/components/dialog/ChannelForm/ChannelForm";
import { ReasonFormValues, reasonFormValuesProxy } from "./_types";
import { validate } from "./_validations";
import React from "react";

interface OwnProps {
  reasonLabel: string;
}

export const ReasonForm = ({
  channel,
  reasonLabel
}: DialogContentPropsType & OwnProps) => {
  const classes = useStyles();

  return (
    <ChannelForm<ReasonFormValues>
      initialValues={{
        reason: ""
      }}
      validate={validate}
      channel={channel}
    >
      <Form className={classes.modalSmall}>
        <StyledFieldWide
          component={TextField}
          data-test-id={`${reasonLabel}-input-reason`}
          name={lastPathMember(reasonFormValuesProxy.reason).path}
          required={true}
          type="text"
          label={reasonLabel}
        />
      </Form>
    </ChannelForm>
  );
};
