import { omit } from "lodash";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { ActionType, getType } from "typesafe-actions";
import { User } from "../../../../domain/struct/administration/User";
import { RootStateType } from "../../../reducers";
import { UserFormValuesProxy } from "../../../share/components/dialog/createUserDialog/_types";
import { convertResponse } from "../../../share/utils/convert";
import { fetchSaga } from "../../../share/utils/fetch";
import { lastPathMember } from "../../../share/utils/getPath";
import { ApiURL } from "../../apiURL";
import { metaFormAction__Update } from "../../components/MetaForm/_actions";
import { dialogOpenAction } from "../../components/dialog/_actions";
import { DialogType } from "../../components/dialog/_types";
import { documentViewAction__UpdateItem } from "../../components/documentView/_actions";
import {
  changePasswordAction,
  createUserAction,
  deactivateUserAction,
  openCreateUserDialogAction,
  openEditUserDialogAction,
  updateUserAction
} from "./_actions";

export const createUserDefaultFormValues: User = {
  email: "",
  firstName: "",
  groups: [],
  id: "",
  lastName: "",
  mainGroup: "",
  signGroups: [],
  userJob: "",
  degreeBefore: "",
  degreeAfter: "",
  externalUser: false
};

export function* watchUserActions() {
  yield takeEvery(
    getType(changePasswordAction.request),
    function* ({ payload }: ActionType<typeof changePasswordAction.request>) {
      const { body } = payload;
      const isAdmin: boolean = yield select(
        (state: RootStateType) => state.loginReducer.session.isAdmin
      );
      const url: string = isAdmin
        ? ApiURL.ADMIN_CHANGE_PASSWORD
        : ApiURL.USER_CHANGE_PASSWORD;
      const { errorResponse, success } = yield call(fetchSaga, url, "POST", {
        bodyJSON: body
      });

      if (!success) {
        yield put(changePasswordAction.failure(errorResponse));
        return;
      }

      yield put(changePasswordAction.success());
    }
  );

  yield takeEvery(
    getType(createUserAction.request),
    function* ({ payload }: ActionType<typeof createUserAction.request>) {
      const { body } = payload;

      const { errorResponse, success } = yield call(
        fetchSaga,
        ApiURL.USER_CREATE,
        "POST",
        {
          bodyJSON: body
        }
      );

      if (!success) {
        yield put(createUserAction.failure(errorResponse));
        return;
      }

      yield put(createUserAction.success());
    }
  );

  yield takeEvery(
    getType(deactivateUserAction.request),
    function* ({ payload }: ActionType<typeof deactivateUserAction.request>) {
      const { userId } = payload;

      const { errorResponse, success, response } = yield call(
        fetchSaga,
        ApiURL.USER_DEACTIVATE,
        "POST",
        {
          urlWildCards: {
            userId
          }
        }
      );

      if (!success) {
        yield put(deactivateUserAction.failure(errorResponse));
        return;
      }

      yield put(documentViewAction__UpdateItem(response));
      yield put(deactivateUserAction.success(response));
    }
  );

  yield takeLatest(getType(openCreateUserDialogAction), function* () {
    yield put(
      dialogOpenAction({
        dialogProps: {
          dontUseDataModifiedDialog: true
        },
        dialogType: DialogType.CreateUser
      })
    );

    yield put(
      metaFormAction__Update({
        documentId: "createUser",
        formValues: {
          ...createUserDefaultFormValues
        }
      })
    );
  });

  yield takeLatest(
    getType(openEditUserDialogAction),
    function* ({ payload }: ActionType<typeof openEditUserDialogAction>) {
      yield put(
        dialogOpenAction({
          dialogProps: {
            dontUseDataModifiedDialog: true
          },
          dialogType: DialogType.EditUserDialog
        })
      );

      yield put(
        metaFormAction__Update({
          documentId: payload.id,
          formValues: { ...createUserDefaultFormValues, ...payload }
        })
      );
    }
  );

  yield takeEvery(
    getType(updateUserAction.request),
    function* ({ payload }: ActionType<typeof updateUserAction.request>) {
      const { body } = payload;
      const copyOfBody = {
        ...body
      };
      const { errorResponse, success, response } = yield call<typeof fetchSaga>(
        fetchSaga,
        ApiURL.USER_UPDATE,
        "POST",
        {
          bodyJSON: omit(copyOfBody, [
            lastPathMember(UserFormValuesProxy.id).path
          ]),
          urlWildCards: {
            userId: body.id!
          }
        }
      );

      if (!success) {
        yield put(updateUserAction.failure(errorResponse));
        return;
      }

      yield put(documentViewAction__UpdateItem(convertResponse(response)));
      yield put(updateUserAction.success());
    }
  );
}
