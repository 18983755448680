import { useForm } from "@isfg/react-components/build/Form/Form";
import {
  BaseModalProps,
  useModal
} from "@isfg/react-components/build/Modal/useModal";
import { Tabs } from "@isfg/react-components/build/Tabs/Tabs";
import { Notification } from "@isfg/react-components/build/notification/Notification";
import { Button } from "antd";
import { DialogDataType } from "presentation/core/components/dialog/_types";
import CommentsTabContainer from "presentation/core/components/dialog/tabs/comments";
import { useComponentsV2TabContainer } from "presentation/core/components/dialog/tabs/components/hooks/useComponentsV2TabContainer";
import { documentViewAction__Refresh } from "presentation/core/components/documentView/_actions";
import { ModalWithPreview } from "presentation/designSystem/Modal/ModalWithPreview";
import { RemoteTableApiContextProvider } from "presentation/designSystem/Table/contexts/RemoteTableApiContextProvider";
import { DocumentType, SpisumNodeTypes } from "presentation/enums";
import { SearchSubjectResult } from "presentation/modules/nameRegister/modal/SearchSubjectModal/SearchSubjectModal";
import { handoverDocument } from "presentation/share/components/dialog/documentHandoverDialog/_actions";
import { useDocumentConcernedSubjectTable } from "presentation/share/tables/document/ConcernedSubjectTable/useDocumentConcernedSubjectTable";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useInactiveComponentsV2TabContainer } from "../../../../../../../../core/components/dialog/tabs/InactivateComponents/hooks/useInactiveComponentsV2TabContainer";
import { getActiveComponentsForMainActions } from "../../../../../../../../core/components/dialog/tabs/components/api";
import { SortDirection } from "../../../../../../../../govDesignSystem/lib/contract/Sorting";
import UnsavedChangesModal from "../../../dataBox/unprocessed/modals/UnsavedChangesModal";
import {
  RegisterEmailDigitalForm,
  RegisterEmailDigitalFormValues
} from "../forms/RegisterEmailDigitalForm";
import { useUpdateDocument } from "../hooks/useUpdateDocument";
import { CreateEmailRegistration } from "../types";

interface RegisterEmailDigitalModalProps {
  onCancel?: BaseModalProps["onCancel"];
  onOk?: BaseModalProps["onOk"];
  document: CreateEmailRegistration;
  documentType: DocumentType;
  nodeType: SpisumNodeTypes;
  nodeId?: string;
  documentId?: string;
  defaultActiveTab?: string;
}

export enum RegisterEmailDigitalModalTabs {
  Metadata = "0",
  Components = "1",
  InActiveComponents = "2",
  ConcernedSubjects = "3",
  Comments = "4"
}

export const RegisterEmailDigitalModal = (
  props: RegisterEmailDigitalModalProps
) => {
  return (
    <RemoteTableApiContextProvider>
      <RegisterEmailDigitalModalInner {...props} />
    </RemoteTableApiContextProvider>
  );
};

export const RegisterEmailDigitalModalInner = ({
  onCancel,
  document,
  nodeId,
  nodeType,
  documentId,
  defaultActiveTab = RegisterEmailDigitalModalTabs.Metadata
}: RegisterEmailDigitalModalProps) => {
  const [activeTab, setActiveTab] = useState(defaultActiveTab);
  const [form] = useForm<RegisterEmailDigitalFormValues>();
  const dispatch = useDispatch();
  let [isEmailRegistered, setIsEmailRegistered] = useState<boolean>(false);

  const changeActiveTab = (activeTabIndex: string) => {
    setActiveTab(activeTabIndex);
  };
  const {
    updateAndRegister: updateAndRegisterDocument,
    updateAndRegisterLoading,
    update: updateDocument
  } = useUpdateDocument();

  const [unsavedChangesModal, unsavedChangesModalApi] =
    useModal(UnsavedChangesModal);

  const [showPreview, setShowPreview] = useState(false);
  const [previewItem, setPreviewItem] = useState({
    id: "",
    name: "",
    nodeType: SpisumNodeTypes.Databox,
    entityId: "",
    fileIsEncrypted: false
  });

  const registerDataboxDocumentAndHandOver = async () => {
    const checkTheComponent = await getActiveComponentsForMainActions(
      nodeId!,
      {
        page: 1,
        itemsPerPage: 100
      },
      [
        {
          property: "name",
          direction: SortDirection.Ascending
        }
      ]
    ).then((result) => {
      if (result.items.length > 0) {
        const settle = result.items.filter((item) => item.isReadable);
        if (settle.length > 0) {
          return false;
        }
      }
      return true;
    });
    if (checkTheComponent) {
      Notification.error({
        message: t(translationPath(lang._validations.componentsForEmail))
      });
    } else {
      if (isEmailRegistered) {
        await modalForHandover(nodeId!, form.getFieldsValue());
        return;
      }

      updateAndRegisterDocument(
        {
          id: nodeId!,
          input: form.getFieldsValue()
        },
        {
          onSuccess: () => {
            setIsEmailRegistered(true);
            modalForHandover(nodeId!, form.getFieldsValue());
          }
        }
      );
    }
  };

  const modalForHandover = async (nodeId: string, formValues: any) => {
    dispatch(
      handoverDocument({
        data: { id: nodeId, ...(formValues as DialogDataType) },
        onClose: () => {},
        onSuccess() {
          onCancel?.();
        }
      })
    );
  };

  const registerDataboxDocument = async () => {
    const checkTheComponent = await getActiveComponentsForMainActions(
      nodeId!,
      {
        page: 1,
        itemsPerPage: 100
      },
      [
        {
          property: "name",
          direction: SortDirection.Ascending
        }
      ]
    ).then((result) => {
      if (result.items.length > 0) {
        const settle = result.items.filter((item) => item.isReadable);
        if (settle.length > 0) {
          return false;
        }
      }
      return true;
    });
    if (checkTheComponent) {
      Notification.error({
        message: t(translationPath(lang._validations.componentsForEmail))
      });
    } else {
      if (isEmailRegistered) {
        await closeEmailModal();
        return;
      }
      updateAndRegisterDocument(
        {
          id: nodeId!,
          input: form.getFieldsValue()
        },
        {
          onSuccess: () => {
            setIsEmailRegistered(true);
            closeEmailModal();
          }
        }
      );
    }
  };

  const closeEmailModal = async () => {
    dispatch(documentViewAction__Refresh(true));
    onCancel?.();
  };

  const [showInactiveComponents, setShowInactiveComponents] = useState(false);
  const handleShowDeactivateComponent = () => {
    setShowInactiveComponents(true);
    setActiveTab("2");
  };

  const [componentsV2TabContainer, { fetchComponents }] =
    useComponentsV2TabContainer({
      nodeId: nodeId!,

      isActive: activeTab === RegisterEmailDigitalModalTabs.Components,
      dialogProps: {
        canUploadComponents: false,
        data: { nodeType: SpisumNodeTypes.Document }
      },
      documentId,
      showPreview,
      previewItem,
      setPreviewItem,
      setShowPreview,
      handleShowDeactivateComponent
    });
  const [inactiveComponentsV2TabContainer, { inActiveFetchComponent }] =
    useInactiveComponentsV2TabContainer({
      nodeId: nodeId!,
      isActive: activeTab === RegisterEmailDigitalModalTabs.InActiveComponents,
      dialogProps: {
        canUploadComponents: false,
        data: { nodeType: SpisumNodeTypes.Document }
      },
      documentId,
      showPreview,
      previewItem,
      setPreviewItem,
      setShowPreview
    });

  const [
    documentConcernedSubjectTable,
    { setSenderAsConcernedSubject, refreshDocConcernedSubjs }
  ] = useDocumentConcernedSubjectTable({
    documentId: nodeId!,
    readonly: false
  });

  const onSenderChange = (sender: SearchSubjectResult) => {
    setSenderAsConcernedSubject(sender.subject);
  };

  const tabsWithRefresh = {
    [RegisterEmailDigitalModalTabs.Components]: fetchComponents,
    [RegisterEmailDigitalModalTabs.ConcernedSubjects]: refreshDocConcernedSubjs,
    [RegisterEmailDigitalModalTabs.InActiveComponents]: inActiveFetchComponent
  };

  const saveChanges = async () =>
    updateDocument(
      {
        id: document.nodeId!,
        input: form.getFieldsValue(true)
      },
      {
        onSuccess: () => {
          closeEmailModal();
        }
      }
    );

  const handleCancel = () => {
    form.isFieldsTouched()
      ? unsavedChangesModalApi.open({
          saveChanges,
          closeModal: onCancel
        })
      : onCancel?.();
  };

  return (
    <>
      {unsavedChangesModal}
      <ModalWithPreview
        open={true}
        hasTabs
        onRefresh={tabsWithRefresh[activeTab as keyof typeof tabsWithRefresh]}
        title={t(translationPath(lang.dialog.title.digitalDocumentIncome))}
        onCancel={handleCancel}
        confirmLoading={false}
        handleShowPreviewChange={() => setShowPreview(false)}
        showPreview={showPreview}
        previewItem={previewItem}
        setPreviewItem={setPreviewItem}
        footer={[
          <Button key="back" onClick={handleCancel}>
            {t(translationPath(lang.modal.cancel))}
          </Button>,
          <Button
            key="registerAndHandover"
            type="primary"
            loading={false}
            onClick={registerDataboxDocumentAndHandOver}
          >
            {t(translationPath(lang.dialog.form.toRegisterAndRefer))}
          </Button>,
          <Button
            key="register"
            loading={updateAndRegisterLoading}
            onClick={registerDataboxDocument}
          >
            {t(translationPath(lang.dialog.form.toRegister))}
          </Button>
        ]}
      >
        <Tabs onChange={changeActiveTab} activeKey={activeTab}>
          <Tabs.TabPane
            tab={t(translationPath(lang.dialog.subjectDialog.tabs.metadata))}
            key={RegisterEmailDigitalModalTabs.Metadata}
          >
            <RegisterEmailDigitalForm
              formData={document}
              form={form}
              onSenderChange={onSenderChange}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={t(translationPath(lang.dialog.tabs.components))}
            key={RegisterEmailDigitalModalTabs.Components}
          >
            {componentsV2TabContainer}
          </Tabs.TabPane>
          {showInactiveComponents && (
            <Tabs.TabPane
              tab={t(translationPath(lang.dialog.tabs.InactiveComponents))}
              key={RegisterEmailDigitalModalTabs.InActiveComponents}
            >
              {inactiveComponentsV2TabContainer}
            </Tabs.TabPane>
          )}
          <Tabs.TabPane
            tab={t(translationPath(lang.dialog.tabs.concernedSubjects))}
            key={RegisterEmailDigitalModalTabs.ConcernedSubjects}
          >
            <div className="body-fullsize">{documentConcernedSubjectTable}</div>
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={t(translationPath(lang.dialog.tabs.notes))}
            key={RegisterEmailDigitalModalTabs.Comments}
          >
            <CommentsTabContainer
              nodeId={nodeId!}
              isActive={activeTab === RegisterEmailDigitalModalTabs.Comments}
              dialogProps={{ data: { nodeType } }}
            />
          </Tabs.TabPane>
        </Tabs>
      </ModalWithPreview>
    </>
  );
};
