import moment from "moment";
import { Form, Item, useForm } from "presentation/designSystem/Form/v2/Form";
import { Input } from "presentation/designSystem/Input/Input";
import { Modal, ModalSize } from "presentation/designSystem/Modal/Modal";
import { BaseModalProps } from "presentation/designSystem/Modal/useModal";
import { useMutation } from "presentation/share/hook/query/useQM";
import { getErrorCodeTranslation } from "presentation/share/utils/errorCodeTranslation";
import { translationPath } from "presentation/share/utils/getPath";
import { t } from "presentation/translation/i18n";
import lang from "presentation/translation/lang";
import { ErrorType } from "presentation/types";
import {
  PostWithdrawApplicationForSignatureSealParams,
  postWithdrawApplicationForSignatureSeal
} from "../api";
import React from "react";

export interface WithdrawApplicationForSignatureModalInterface
  extends BaseModalProps {
  nodeId: string;
  onOk?: BaseModalProps["onOk"];
  required?: boolean;
}

export const WithdrawApplicationForSignatureModal = ({
  onCancel,
  nodeId,
  onOk
}: WithdrawApplicationForSignatureModalInterface) => {
  const [form] = useForm<PostWithdrawApplicationForSignatureSealParams>();
  const getNotificationForError = (error: ErrorType) => {
    return {
      message: error.code
        ? getErrorCodeTranslation(error.code)
        : t(
            translationPath(
              lang.dialog.notifications.applicationDoesNotWithdraw
            )
          )
    };
  };
  const onConfirm = async () => {
    try {
      await form.validateFields();
      const { reason } = form.getFieldsValue();
      mutate({ reason, nodeId });
    } catch {}
  };

  const { isLoading, mutate } = useMutation(
    (params: PostWithdrawApplicationForSignatureSealParams) =>
      postWithdrawApplicationForSignatureSeal(params),
    {
      onSuccess() {
        onOk?.();
      },
      onSuccessNotification: {
        message: t(
          translationPath(
            lang.dialog.withdrawApplication.successWithdrawApplication
          )
        )
      },
      onErrorNotification: getNotificationForError
    }
  );

  return (
    <Modal
      open={true}
      title={t(
        translationPath(
          lang.dialog.withdrawApplication.withdrawApplicationForSignatureModal
        )
      )}
      onCancel={onCancel}
      onOk={onConfirm}
      confirmLoading={isLoading}
      size={ModalSize.Small}
      okText={t(translationPath(lang.modal.ok))}
      cancelText={t(translationPath(lang.modal.cancel))}
    >
      <Form form={form} initialValues={{ createdAt: moment() }}>
        <Item
          name="reason"
          label={t(
            translationPath(lang.dialog.withdrawApplication.reasonForWithdrawal)
          )}
          rules={[{ min: 4 }, { max: 30 }, { required: true }]}
          required={true}
        >
          <Input />
        </Item>
      </Form>
    </Modal>
  );
};
