import { callAsyncAction } from "presentation/core/action";
import { documentForSignatureActionType } from "presentation/core/api/document/_actions";
import { SslProperties } from "presentation/core/api/models";
import { secondaryAction } from "presentation/core/components/dialog/lib/actionsFactory";
import {
  DialogContentPropsType,
  DialogType
} from "presentation/core/components/dialog/_types";
import { documentViewAction__Refresh } from "presentation/core/components/documentView/_actions";
import NamedTitle from "presentation/core/components/namedTitle";
import { GenericDocument } from "presentation/core/types";
import React from "react";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import { ForSignatureContentDialog } from "./ForSignatureContentDialog";
import { ForSignatureFormValues } from "./_types";
import { ModalSize } from "presentation/designSystem/Modal/Modal";

export const forSignatureDialogContent = {
  actions: () => [
    secondaryAction(
      t(translationPath(lang.dialog.buttons.confirm)),
      ({ dispatch, channels, dialogProps, onClose, buttonState }) => {
        const onSuccess = () => {
          dispatch(documentViewAction__Refresh(true));
          onClose();
        };

        const onError = () => {
          buttonState.setIsPending(false);
        };

        buttonState.setIsPending(true);

        const { group, user } = channels.contentTab.state
          ?.formValues as ForSignatureFormValues;
        dispatch(
          callAsyncAction({
            action: documentForSignatureActionType,
            onError,
            onSuccess,
            payload: {
              body: {
                group,
                user
              } as SslProperties,
              nodeId: (dialogProps.data as GenericDocument).id
            }
          })
        );
      }
    )
  ],
  content: ForSignatureContentDialog,
  title: (props: DialogContentPropsType) => (
    <NamedTitle
      text={t(translationPath(lang.dialog.title.signatureRequest))}
      {...props}
    />
  ),
  type: DialogType.ForSignature,
  size: ModalSize.Small
};
