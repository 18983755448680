import { getService } from "presentation/core/features/dependencyInjection";
import { useMutation } from "../../../share/hook/query/useQM";
import { RequestPasswordReset } from "../../../../useCase/authentication/RequestPasswordReset";
import { t } from "../../../translation/i18n";
import { translationPath } from "../../../share/utils/getPath";
import lang from "../../../translation/lang";
import { getErrorCodeTranslation } from "../../../share/utils/errorCodeTranslation";
import { FormInstance } from "presentation/designSystem/Form/v2/Form";

export interface ResetPasswordConfiguration {
  readonly token: string;
  readonly newPassword: string;
}

export const useChangePassword = (form: FormInstance) => {
  const requestPasswordReset = getService(RequestPasswordReset);

  const getNotificationForError = (error: any) => ({
    message: getErrorCodeTranslation(error.code)
  });

  return useMutation(
    (passwordData: ResetPasswordConfiguration) =>
      requestPasswordReset.changePasswordReset(
        passwordData.token,
        passwordData.newPassword
      ),
    {
      onError() {
        form.resetFields();
      },
      onSuccessNotification: {
        message: t(
          translationPath(lang.dialog.notifications.passwordChangedSuccessfully)
        )
      },
      onErrorNotification: getNotificationForError
    }
  );
};
