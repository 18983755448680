import { useMutation } from "presentation/share/hook/query/useMutation";
import { registerDocumentFromEmail, updateUnfinishedDocument } from "../api";
import { RegisterEmailDigitalFormValues } from "../forms/RegisterEmailDigitalForm";
import { ErrorType } from "../../../../../../../../types";
import { lang, t } from "../../../../../../../../translation/i18n";
import { translationPath } from "../../../../../../../../share/utils/getPath";
import { getErrorCodeTranslation } from "presentation/share/utils/errorCodeTranslation";

interface UpdateDocumentVariables {
  id: string;
  input: RegisterEmailDigitalFormValues;
}

export const useUpdateDocument = () => {
  const { isLoading: registrationLoading, mutate: register } = useMutation(
    (id: string) => registerDocumentFromEmail(id),
    {
      onSuccess: () => {
        return Promise.resolve();
      }
    }
  );

  const {
    isLoading: updateLoading,
    mutate: update
  } = useMutation(({ id, input }: UpdateDocumentVariables) =>
    updateUnfinishedDocument(id, input)
  );

  const {
    isLoading: updateAndRegisterLoading,
    mutate: updateAndRegister
  } = useMutation(
    async ({ id, input }: UpdateDocumentVariables) => {
      await updateUnfinishedDocument(id, input);
      await registerDocumentFromEmail(id);
    },
    {
      onErrorNotification: (error: ErrorType) => ({
        message: error.code
          ? getErrorCodeTranslation(error.code)
          : Array.isArray(error.messages) && error.messages.length > 0
          ? error.messages[0]
          : t(translationPath(lang.dialog.notifications.fileTypeSettledFailed))
      })
    }
  );

  return {
    registrationLoading,
    updateLoading,
    updateAndRegisterLoading,
    update,
    register,
    updateAndRegister
  };
};
