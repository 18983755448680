import { ApiURL } from "presentation/core/apiURL";
import { File } from "presentation/core/entities";
import { getService } from "presentation/core/features/dependencyInjection";
import { HttpClient } from "infrastructure/service/HttpClient";

export interface SignerResponseComponent {
  id: string;
  component: string;
  status: string;
}

export interface SignerCreateResponse {
  batchId?: string;
  signer: string;
  components: string[];
}

const transformParams = (files: string[]): string => {
  return files?.reduce((acc, currVal) => {
    return `${acc}&componentId=${currVal}`;
  }, "");
};

export const signer = {
  async getSignerData(
    documentId: string,
    files: File[],
    visual: boolean
  ): Promise<SignerCreateResponse | null> {
    const httpClient = getService<HttpClient>(HttpClient);
    const params = transformParams(files.map((file) => file.id || ""));
    try {
      const response = await httpClient.fetch(
        `${
          ApiURL.SIGNER_CREATE_V2
        }?documentId=${documentId}${params}&visual=${String(visual)}`,
        "GET"
      );

      return response.success
        ? (response.response as SignerCreateResponse)
        : null;
    } catch (e) {
      return null;
    }
  },

  async getComponentsSignStatus(
    componentId: string
  ): Promise<SignerResponseComponent[]> {
    const httpClient = getService<HttpClient>(HttpClient);

    return await httpClient.fetchWithThrow<SignerResponseComponent[]>(
      ApiURL.SIGNER_GET_STATUS_V2,
      "GET",
      {
        params: {
          componentId
        }
      }
    );
  }
};
