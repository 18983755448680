import MenuLayout from "presentation/core/components/layout/MenuLayout";
import React from "react";
import { withTranslation } from "presentation/translation/i18n";
import { RetentionProtocolsTable } from "./tables/RetentionProtocolsTable";

const RetentionProtocols = () => {
  return (
    <MenuLayout>
      <RetentionProtocolsTable />
    </MenuLayout>
  );
};

export default withTranslation()(RetentionProtocols);
