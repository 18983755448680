import MenuLayout from "presentation/core/components/layout/MenuLayout";
import React from "react";
import { withTranslation } from "presentation/translation/i18n";
import { ConversionAllTable } from "../../tables/ConversionAll/ConversionAllTable";

const ConversionAll = () => {
  return (
    <MenuLayout>
      <ConversionAllTable />
    </MenuLayout>
  );
};

export default withTranslation()(ConversionAll);
