import { callAsyncAction } from "presentation/core/action";
import { documentChangeFileMarkAction } from "presentation/core/api/document/_actions";
import { fileChangeFileMarkAction } from "presentation/core/api/file/_actions";
import { secondaryAction } from "presentation/core/components/dialog/lib/actionsFactory";
import {
  ActionOnCloseType,
  DialogContentType,
  DialogType
} from "presentation/core/components/dialog/_types";
import { documentViewAction__Refresh } from "presentation/core/components/documentView/_actions";
import NamedTitle from "presentation/core/components/namedTitle";
import { isFile, isGenericDocument } from "presentation/core/types";
import React from "react";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import { ChangeFileMarkDialog } from "./ChangeFileMarkDialog";
import { ChangeFileMarkFormValues } from "./_types";
import { ModalSize } from "presentation/designSystem/Modal/Modal";
import { Notification } from "presentation/designSystem/notification/Notification";

export const changeFileMarkDialogContent: DialogContentType = {
  actions: () => [
    secondaryAction(
      t(translationPath(lang.dialog.buttons.confirm)),
      ({ dispatch, dialogProps, onClose, buttonState, channels }) => {
        if (!isGenericDocument(dialogProps.data)) {
          return;
        }

        buttonState.setIsPending(true);

        if (
          dialogProps.data.properties?.ssl?.fileMark ===
          (channels.contentTab.state?.formValues as ChangeFileMarkFormValues)
            .fileMark
        ) {
          buttonState.setIsPending(false);
          Notification.error({
            message: t(
              translationPath(lang.dialog.notifications.selectDifferentFileMark)
            )
          });
          return;
        }

        const onSuccess = () => {
          dispatch(documentViewAction__Refresh(true));
          onClose();
          dialogProps &&
            dialogProps.onClose &&
            dialogProps.onClose({} as ActionOnCloseType);
        };

        const onError = () => {
          buttonState.setIsPending(false);
        };

        if (
          dialogProps.data.properties?.ssl?.fileMark ===
          (channels.contentTab.state?.formValues as ChangeFileMarkFormValues)
            .fileMark
        ) {
          dialogProps &&
            dialogProps.onClose &&
            dialogProps.onClose({} as ActionOnCloseType);

          onClose();
          return;
        }

        dispatch(
          callAsyncAction({
            action: isFile(dialogProps.data)
              ? fileChangeFileMarkAction
              : documentChangeFileMarkAction,
            onError,
            onSuccess,
            payload: {
              fileMark: (channels.contentTab.state
                ?.formValues as ChangeFileMarkFormValues).fileMark,
              nodeId: dialogProps.data.id
            }
          })
        );
      }
    )
  ],
  content: ChangeFileMarkDialog,
  title: (props) => (
    <NamedTitle
      text={t(translationPath(lang.general.changeFileMark))}
      {...props}
    />
  ),
  type: DialogType.ChangeFileMark,
  size: ModalSize.Small
};
