import { SendModeValues } from "presentation/enums";
import { createProxy } from "../../../utils/getPath";

export interface CreateShipmentTableItemType {
  size: string;
  name: string;
  id: string;
}
createProxy<CreateShipmentTableItemType>();

export interface CreateShipmentFormValues {
  address1?: string;
  address2?: string;
  address3?: string;
  address4?: string;
  addressCity?: string;
  addressState?: string;
  addressStreet?: string;
  addressZip?: string;
  allowSubstDelivery?: boolean;
  components?: string[];
  dateFrom: Date;
  dateTo?: Date | null;
  days?: number | string;
  legalTitleLaw?: number | string;
  legalTitlePar?: number | string;
  legalTitlePoint?: string;
  legalTitleSect?: number | string;
  legalTitleYear?: number | string;
  note?: string;
  personalDelivery?: boolean;
  postItemCashOnDelivery?: number | string;
  postItemStatedPrice?: number | string;
  postType?: string[];
  postTypeOther?: string;
  postItemType?: string | null;
  postItemTypeOther?: string;
  postItemWeight?: number | string;
  recipient?: string;
  recipientContactEmail?: string;
  recipientContactDatabox?: string;
  sender?: string;
  sendMode: SendModeValues;
  subject?: string;
  toHands?: string;
  recipientId?: string;
  recipientAddressId?: string;
  recipientContactDataboxId?: string;
  recipientContactEmailBoxId?: string;
  listCount?: number | string;
  attachmentsCount?: number | string;
  listCountAttachments?: number | string;
  attachmentsType?: string;
}

export interface CreateShipmentsRequestType extends CreateShipmentFormValues {
  nodeId: string;
  nodeType: string;
  components: string[];
  recipient: string;
  sendMode: SendModeValues;
  subject: string;
  sender: string;
}

export const CreateShipmentFormValuesProxy = createProxy<CreateShipmentFormValues>();
