import { translationPath } from "presentation/share/utils/getPath";
import { lang, t, WithTranslation } from "presentation/translation/i18n";
import React, { useCallback } from "react";
import { withTranslation } from "react-i18next";
import { IconButton } from "../../../designSystem/Button/IconButton";
import {
  EyeInvisibleOutlined,
  EyeOutlined
} from "../../../designSystem/Icon/Icon";

interface OwnProps {
  showPreview: boolean;
  handlePreviewChange: (showPreview: boolean) => void;
  previewItem?: any;
  setPreviewItem?(previewItem: any): void;
}

const PreviewButton = ({
  showPreview,
  handlePreviewChange,
  previewItem,
  setPreviewItem
}: OwnProps & WithTranslation) => {
  const onClick = useCallback(() => {
    handlePreviewChange(!showPreview);
    if (showPreview) previewItem.id = "";
    setPreviewItem?.(previewItem);
  }, [handlePreviewChange, showPreview, setPreviewItem, previewItem]);

  return (
    <>
      {showPreview ? (
        <IconButton
          data-test-id="modal-hide-preview"
          style={{ marginLeft: "4px" }}
          icon={<EyeInvisibleOutlined rev={"default"} />}
          tooltip={t(translationPath(lang.modal.hidePreview))}
          onClick={onClick}
        />
      ) : (
        <IconButton
          data-test-id="modal-show-preview"
          style={{ marginLeft: "4px" }}
          icon={<EyeOutlined rev={"default"} />}
          tooltip={t(translationPath(lang.modal.showPreview))}
          onClick={onClick}
        />
      )}
    </>
  );
};

export default withTranslation()(PreviewButton);
