import { getService } from "presentation/core/features/dependencyInjection";
import { useQM } from "presentation/share/hook/query/useQM";
import { FindAddressType } from "../../../../useCase/codelist/findAddress/FindAddressType";
import { Config } from "./useFindPersons";

const FIND_ADDRESS_KEY = "nameRegister/findAddresses";

// 5. step - prepare to connect use case to presentation
export const useFindAddresses = (config: Config) => {
  const findAddressesUseCase = getService(FindAddressType);

  return useQM(FIND_ADDRESS_KEY, () => findAddressesUseCase.findAll(), config);
};
