import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { theme } from "presentation/styles";

export const useStyles: Function = makeStyles((muiTheme: Theme) => {
  return createStyles({
    inputInput: {
      padding: `${muiTheme.spacing(1, 1, 1, 7)} !important`,
      transition: muiTheme.transitions?.create("width"),
      [muiTheme.breakpoints?.up("sm")]: {
        "&:focus": {
          width: 100
        },
        width: 100
      },
      width: "100%"
    },
    inputRoot: {
      color: "inherit"
    },
    search: {
      "&:hover": {
        backgroundColor: "#FFD9A2"
      },
      backgroundColor: "#FFD9A2",
      borderRadius: 18,
      color: "#000",
      height: 36,
      marginLeft: 0,
      marginRight: 7,
      position: "relative",
      width: 177,
      [muiTheme.breakpoints?.up("sm")]: {
        marginLeft: `${muiTheme?.spacing(1)} !important`
      }
    },
    searchIcon: {
      alignItems: "center",
      color: theme.colors.orange,
      display: "flex",
      height: "100%",
      justifyContent: "center",
      pointerEvents: "none",
      position: "absolute",
      width: muiTheme.spacing(7)
    }
  });
});
