import { useCallback, useEffect, useRef } from "react";
import {
  FormInstance,
  SetFormInstanceClb
} from "../../../../designSystem/Form/Form";
import { DialogState, TabAndDialogChannelType } from "../_types";

export function useSyncFormWithChannel<FormValuesType>(
  channel: TabAndDialogChannelType
): SetFormInstanceClb<FormValuesType> {
  const formRef = useRef<FormInstance<FormValuesType>>(null);

  useEffect(() => {
    // register the triggerValidationFn (for usage after any action is clicked)
    channel.registerTriggerValidationClb(() => {
      if (!formRef.current) {
        channel.setIsValid(true);
        return Promise.resolve();
      }

      return formRef.current
        ?.submitForm()
        .then(() => {
          channel.setIsValid(!!formRef.current?.isValid);

          // autosync form values
          if (formRef.current?.isValid) {
            channel.setState({
              ...channel.state,
              formValues: formRef.current.values as any
            });
          }
        })
        .catch(() => {
          channel.setIsValid(false);
        });
    });

    channel.onDialogStateChanged((dialogState: DialogState) => {
      formRef.current?.setSubmitting(dialogState === DialogState.ActionRunning);
    });

    return () => {
      // clean up listeners
      channel.registerTriggerValidationClb(null);
      channel.onDialogStateChanged(null);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return useCallback((form: FormInstance<FormValuesType>) => {
    formRef.current = form;
  }, []);
}
