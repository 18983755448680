import { GetRemoteDataClb } from "@isfg/react-components/build/Table/RemoteTable";
import {
  createColumn,
  createDateTimeColumn
} from "@isfg/react-components/build/Table/column/column";
import { Description } from "@mui/icons-material";
import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { DateTimeFormat } from "../../../../../../../lib/dateTime";
import { openDocumentWithSaveButtonsAction } from "../../../../../../core/api/document/_actions";
import { BreadcrumbForTable } from "../../../../../../designSystem/Breadcrumb/Breadcrumb";
import { RemoteTable } from "../../../../../../designSystem/Table/RemoteTable";
import { ColumnType } from "../../../../../../designSystem/Table/Table";
import { SpisumNodeTypes } from "../../../../../../enums";
import { RootStateType } from "../../../../../../reducers";
import { translationPath } from "../../../../../../share/utils/getPath";
import { lang, t } from "../../../../../../translation/i18n";
import { useFindReturnForRework } from "../hooks/useFindReturnForRework";
import { ReturnForRework } from "../types/ReturnForRework";

const COLUMNS: ColumnType<ReturnForRework>[] = [
  createColumn({
    dataIndex: "pid",
    title: t(translationPath(lang.general.identifier)),
    sortDirections: ["ascend", "descend", "ascend"],
    sorter: true
  }),
  createColumn({
    dataIndex: "ssid",
    title: t(translationPath(lang.general.referenceNumber)),
    sortDirections: ["ascend", "descend", "ascend"],
    sorter: true
  }),
  createColumn({
    dataIndex: "subject",
    title: t(translationPath(lang.general.subject)),
    sortDirections: ["ascend", "descend", "ascend"],
    sorter: true
  }),
  createDateTimeColumn(
    {
      dataIndex: "deliveryDate",
      title: t(translationPath(lang.general.dateOfEvidence)),
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: true
    },
    DateTimeFormat.FullDateTime
  ),
  createColumn({
    dataIndex: "attachmentsCount",
    title: t(translationPath(lang.general.attachmentsCount)),
    sortDirections: ["ascend", "descend", "ascend"],
    sorter: true
  }),
  createDateTimeColumn(
    {
      dataIndex: "returnForReworkDate",
      title: t(translationPath(lang.general.returnedForReworkDate)),
      defaultSortOrder: "ascend",
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: true
    },
    DateTimeFormat.FullDateTime
  ),
  createColumn({
    dataIndex: "owner",
    title: t(translationPath(lang.general.owner)),
    sortDirections: ["ascend", "descend", "ascend"],
    sorter: true
  })
];

export const ReturnForReworkTable = React.memo(() => {
  const dispatch = useDispatch();
  const session = useSelector(
    (state: RootStateType) => state.loginReducer.session
  );
  const findOverViewsForSignature = useFindReturnForRework();

  const ACTIONS = useMemo(
    () => ({
      single: [
        {
          key: "overviews-for-signature",
          icon: <Description />,
          tooltip: t(translationPath(lang.general.showDetails)),
          action(selected: ReturnForRework[]) {
            dispatch(
              openDocumentWithSaveButtonsAction({
                data: {
                  id: selected[0].nodeId
                },
                canUploadComponents: false,
                hideManageShipmentsIcon: true,
                initiator: SpisumNodeTypes.Document,
                isReadonly: true
              })
            );
          }
        }
      ]
    }),
    [dispatch]
  );

  const getRemoteData: GetRemoteDataClb<ReturnForRework> = useCallback(
    ({ pagination, sorting }) => findOverViewsForSignature(pagination, sorting),
    [findOverViewsForSignature]
  );

  return (
    <>
      <BreadcrumbForTable title={"returnedForRework"}></BreadcrumbForTable>
      <TableStyled>
        <RemoteTable<ReturnForRework>
          name="overview/OverviewsForSignature"
          rowKey="nodeId"
          rowSelection={{ type: "checkbox" }}
          actions={ACTIONS}
          columns={COLUMNS}
          getRemoteData={getRemoteData}
        />
      </TableStyled>
    </>
  );
});
const TableStyled = styled("div")`
  div > .ant-row {
    height: 50px;
  }
`;
