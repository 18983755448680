import { put, takeLatest } from "@redux-saga/core/effects";
import { pick } from "lodash";
import { fetchDocument } from "presentation/core/action";
import {
  documentCreateActionType,
  openDocumentDetailsAction,
  openDocumentReadonlyDetailsAction,
  openDocumentWithSaveButtonsAction
} from "presentation/core/api/document/_actions";
import { databoxMapper, emailMapper } from "presentation/core/api/mapper";
import { SslAnalog, SslProperties } from "presentation/core/api/models";
import { metaFormAction__Update } from "presentation/core/components/MetaForm/_actions";
import { FormValues } from "presentation/core/components/MetaForm/_types";
import { dialogOpenAction } from "presentation/core/components/dialog/_actions";
import {
  DialogTypeReadOnly,
  DialogTypeWitRegisterButtons,
  DialogTypeWithSaveButtons
} from "presentation/core/components/dialog/_types";
import { transformDocumentPayload } from "presentation/core/mappers/api/document";
import { GenericDocument } from "presentation/core/types";
import { Notification } from "presentation/designSystem/notification/Notification";
import {
  DeliveryMode,
  DocumentType,
  SitePaths,
  SpisumNodeTypes
} from "presentation/enums";
import { documentRegisterAction } from "presentation/modules/mailroom/features/income/_actions";
import { translationPath } from "presentation/share/utils/getPath";
import { handleResponse } from "presentation/share/utils/typesafeActions";
import { mapKey2Value } from "presentation/share/utils/utils";
import { lang, t } from "presentation/translation/i18n";
import { ActionType, getType } from "typesafe-actions";
import { defaultAnalogValues } from "./analogDetailsDialog/_sagas";
import { defaultTechnicalCarriesValues } from "./technicalDataCarriesDetailsDialog/_sagas";

const defaultDialogFormValues = {
  analog: defaultAnalogValues(),
  digital: defaultTechnicalCarriesValues(),
  konverzeDA: defaultTechnicalCarriesValues(),
  konverzeAD: defaultTechnicalCarriesValues()
};

export function* watchDialogOpenDocumentWithSaveButtonsDetailsAction() {
  yield takeLatest(
    getType(openDocumentWithSaveButtonsAction),
    function* ({
      payload
    }: ActionType<typeof openDocumentWithSaveButtonsAction>) {
      const document = payload.data as GenericDocument;
      const form = document?.properties?.ssl?.form;

      yield put(
        fetchDocument.request({
          id: document.id,
          nodeType: document.nodeType
        })
      );

      const [successResponse, , success] = (yield handleResponse(
        fetchDocument
      )) as any;

      if (!success) {
        Notification.error({
          message: t(translationPath(lang.dialog.notifications.actionFailed))
        });
        return;
      }

      yield put(
        metaFormAction__Update({
          documentId: successResponse.entry?.id,
          formValues: {
            ...(form
              ? defaultDialogFormValues[
                  form as keyof typeof defaultDialogFormValues
                ]
              : defaultDialogFormValues.analog),
            ...transformDocumentPayload(
              successResponse.entry?.properties?.ssl as SslProperties
            ),
            createdAt: successResponse.entry?.createdAt || null,
            owner: successResponse.entry?.properties?.cm?.owner?.displayName,
            lockType: successResponse.entry?.properties?.cm?.lockType
          },
          nodeType: successResponse.entry?.nodeType
        })
      );

      yield put(
        dialogOpenAction({
          dialogProps: { ...payload, data: successResponse.entry },
          dialogType: (form
            ? DialogTypeWithSaveButtons[
                form as keyof typeof DialogTypeWithSaveButtons
              ]
            : DialogTypeWithSaveButtons.analog) as any
        })
      );
    }
  );
}

export function* watchDialogOpenReadOnlyDetailsAction() {
  yield takeLatest(
    getType(openDocumentReadonlyDetailsAction),
    function* ({
      payload
    }: ActionType<typeof openDocumentReadonlyDetailsAction>) {
      const document = payload?.data as GenericDocument;
      const form = document?.properties?.ssl?.form;

      yield put(
        fetchDocument.request({
          id: document.id,
          nodeType: document.nodeType
        })
      );

      const [successResponse, , success] = (yield handleResponse(
        fetchDocument
      )) as any;

      if (!success) {
        Notification.error({
          message: t(translationPath(lang.dialog.notifications.actionFailed))
        });
        return;
      }

      yield put(
        metaFormAction__Update({
          documentId: successResponse.entry?.id,
          formValues: {
            ...(form
              ? defaultDialogFormValues[
                  form as keyof typeof defaultDialogFormValues
                ]
              : defaultDialogFormValues.analog),
            ...transformDocumentPayload(
              successResponse.entry?.properties?.ssl as SslProperties
            ),
            owner: successResponse.entry?.properties?.cm?.owner?.displayName,
            lockType: successResponse.entry?.properties?.cm?.lockType
          },
          nodeType: successResponse.entry?.nodeType
        })
      );

      yield put(
        dialogOpenAction({
          dialogProps: {
            ...payload,
            isReadonly: true
          },
          dialogType: (form
            ? DialogTypeReadOnly[form as keyof typeof DialogTypeReadOnly]
            : DialogTypeReadOnly.analog) as any
        })
      );
    }
  );
}

export function* watchOpenDialogDetailsAction() {
  yield takeLatest(
    getType(openDocumentDetailsAction),
    function* ({ payload }: ActionType<typeof openDocumentDetailsAction>) {
      const document = payload?.data as GenericDocument;
      const form = document?.properties?.ssl?.form;

      yield put(
        fetchDocument.request({
          id: document.id,
          nodeType: document.nodeType
        })
      );

      const [successResponse, , success] = (yield handleResponse(
        fetchDocument
      )) as any;

      if (!success) {
        Notification.error({
          message: t(translationPath(lang.dialog.notifications.actionFailed))
        });
        return;
      }

      yield put(
        metaFormAction__Update({
          documentId: successResponse.entry?.id,
          formValues: {
            ...(form
              ? defaultDialogFormValues[
                  form as keyof typeof defaultDialogFormValues
                ]
              : defaultDialogFormValues.analog),
            ...transformDocumentPayload(
              successResponse.entry?.properties?.ssl as SslProperties
            ),
            owner: successResponse.entry?.properties?.cm?.owner?.displayName
          },
          nodeType: successResponse.entry?.nodeType
        })
      );

      yield put(
        dialogOpenAction({
          dialogProps: {
            ...payload,
            useAutoSave: true
          },
          dialogType: (form
            ? DialogTypeWitRegisterButtons[
                form as keyof typeof DialogTypeWitRegisterButtons
              ]
            : DialogTypeWitRegisterButtons.analog) as any
        })
      );
    }
  );
}

const getDefaultValuesForEmailAndDataboxRegisterForm = (
  nodeType: SpisumNodeTypes
) => {
  const deliveryMode =
    nodeType === SpisumNodeTypes.Databox
      ? DeliveryMode.Databox
      : DeliveryMode.Email;

  return {
    attachmentsCount: 0,
    attachmentsType: "",
    deliveryDate: new Date(),
    deliveryMode,
    deliveryTime: new Date(),
    form: DocumentType.Digital,
    pid: "",
    sender: "",
    senderType: null,
    senderAddressId: null,
    senderAddress: null,
    senderContactEmailId: null,
    senderContactEmail: null,
    senderContactDataboxId: null,
    senderContactDatabox: null,
    senderContactPhoneId: null,
    senderContactPhone: null,
    senderCompanyEmployeeJob: "",
    senderFullName: "",
    senderCompanyName: "",
    senderIdent: "",
    senderSSID: "",
    senderId: null,
    senderCompanyEmployeeUnit: "",
    settleToDate: null,
    subject: "0"
  };
};

export function* watchRegisterDocumentAction() {
  yield takeLatest(
    getType(documentRegisterAction),
    function* ({ payload }: ActionType<typeof documentRegisterAction>) {
      const {
        dialogType,
        documentType,
        document,
        nodeType,
        onSuccess,
        sitePath
      } = payload;

      yield put(
        documentCreateActionType.request({
          documentType,
          nodeId: document.id,
          nodeType: SpisumNodeTypes.Document
        })
      );

      const [successResponse, , success] = yield handleResponse(
        documentCreateActionType
      );

      if (!success) {
        Notification.error({
          message: t(translationPath(lang.dialog.notifications.actionFailed))
        });
        return;
      }

      const properties =
        nodeType === SpisumNodeTypes.Databox
          ? mapKey2Value<object, SslAnalog>(
              databoxMapper,
              document.properties?.ssl as object
            )
          : nodeType === SpisumNodeTypes.Email
            ? mapKey2Value<object, SslAnalog>(
                emailMapper,
                document.properties?.ssl as object
              )
            : document.properties?.ssl;

      const defaultValues =
        getDefaultValuesForEmailAndDataboxRegisterForm(nodeType);

      const allValues = {
        ...defaultValues,
        ...properties,
        ...successResponse.entry.properties?.ssl
      };
      const withDeliveryType = {
        ...allValues,
        deliveryTime: allValues.deliveryDate
      };

      yield put(
        dialogOpenAction({
          dialogProps: {
            disableConverIcon: true,
            onSuccess,
            saveOnOpen: sitePath === SitePaths.Unprocessed,
            useAutoSave: true
          },
          dialogType
        })
      );

      yield put(
        metaFormAction__Update({
          documentId: successResponse.entry.id,
          formValues: pick(
            withDeliveryType,
            Object.keys(defaultValues)
          ) as FormValues,
          isLoading: false,
          nodeType: successResponse.entry.nodeType
        })
      );
    }
  );
}
