import React from "react";
import { FilePlanGroups } from "presentation/core/features/login/_types";
import { DataNode } from "rc-tree-select/lib/interface";
import { TreeSelect } from "presentation/designSystem/TreeSelect/TreeSelect";

interface FileMarkFieldProps {
  fileMarks: FilePlanGroups[];
  onChange?: (value: string) => void;
  initialFileMark?: string;
  readOnly?: boolean;
}

const fullyDeterminedFileMarkRequested = "99";

export const FileMarkField = ({
  fileMarks,
  onChange,
  initialFileMark,
  readOnly
}: FileMarkFieldProps) => {
  const itemName = (mark: FilePlanGroups) => {
    let baseName = `${mark.fullyDeterminedFileMark} - ${mark.name}`;
    if (mark.retentionMark && mark.retentionPeriod) {
      baseName += ` (${mark.retentionMark}/${mark.retentionPeriod})`;
    }
    return baseName;
  };

  const filterItemsRequested = fileMarks.filter(
    (fileMark) =>
      fileMark.fullyDeterminedFileMark === fullyDeterminedFileMarkRequested
  );

  const filterItemsExpanded = filterItemsRequested.map(
    (mark) => mark.identificator
  );

  const mapTreeData = (items: FilePlanGroups[]): DataNode[] => {
    return items.map((item) => {
      return {
        title: itemName(item),
        value: item.identificator,
        children: mapTreeData(item.filePlanGroups ?? []),
        disabled: item.isTypeFileRestricted === false || item.depth !== 2
      };
    });
  };

  return (
    <TreeSelect
      treeData={mapTreeData(filterItemsRequested)}
      treeDefaultExpandedKeys={filterItemsExpanded}
      disabled={readOnly}
      defaultValue={initialFileMark || ""}
      onChange={onChange}
    />
  );
};
