import { DInjectionToken } from "../../../presentation/core/features/dependencyInjection";
import { AddressTypeRepository } from "../../service/codelist/AddressTypeRepository";
import { ContactRepository } from "../../service/codelist/ContactRepository";
import { HouseNumberRepository } from "../../service/codelist/HouseNumberRepository";
import { CountryRepository } from "../../service/codelist/CountryRepository";
import { NationalityRepository } from "../../service/codelist/NationalityRepository";

export const AddressTypeRepositoryToken = new DInjectionToken<AddressTypeRepository>(
  "domain.code-list.address-repository"
);

export const ContactRepositoryToken = new DInjectionToken<ContactRepository>(
  "domain.code-list.contact-repository"
);

export const HouseNumberRepositoryToken = new DInjectionToken<HouseNumberRepository>(
  "domain.code-list.house-number-repository"
);

export const CountryRepositoryToken = new DInjectionToken<CountryRepository>(
  "domain.code-list.country-repository"
);

export const NationalityRepositoryToken = new DInjectionToken<NationalityRepository>(
  "domain.code-list.nationality-repository"
);
