import {
  DInject,
  DInjectable
} from "../../../presentation/core/features/dependencyInjection";
import { PerformShreddingProcedure } from "../../../domain/struct/recordRetention/PerformShreddingProcedure";
import { PerformShreddingProcedureRepository } from "../../../domain/service/recordRetention/PerformShreddingProcedureRepository";
import { HttpClient, HttpMethod } from "../../service/HttpClient";
import { ApiUrls } from "../struct/ApiTypesV2";

const URL: ApiUrls = {
  PerformShreddingProcedurePost:
    "/api/app/v2/shredding/proposal/{nodeId}/shredding-operation"
};

@DInjectable()
export class PerformShreddingProcedureApiRepository
  implements PerformShreddingProcedureRepository {
  constructor(@DInject(HttpClient) protected httpClient: HttpClient) {}

  savePerformShreddingProcedure(
    payload: PerformShreddingProcedure
  ): Promise<void> {
    return this.httpClient.fetchWithThrow(
      URL.PerformShreddingProcedurePost,
      HttpMethod.Post,
      {
        bodyJSON: payload,
        urlWildCards: {
          nodeId: payload.id
        }
      }
    );
  }
}
