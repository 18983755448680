import { getService } from "presentation/core/features/dependencyInjection";
import { PaginationConfig } from "../../../../lib/contract/Pagination";
import { useCallback } from "react";
import { FindStoredDocuments } from "../../../../useCase/repository/FindStoredDocuments";
import { SortingConfig } from "lib/contract/Sorting";

export const useFindStoredDocuments = () => {
  const findStoredDocuments = getService(FindStoredDocuments);

  return useCallback(
    (config: PaginationConfig, sorting?: SortingConfig) =>
      findStoredDocuments.findAllPaginated(config, sorting),
    [findStoredDocuments]
  );
};
