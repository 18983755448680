import { DialogContentPropsType } from "presentation/core/components/dialog/_types";
import MetaFormContainer from "presentation/core/components/MetaForm";
import React from "react";
import { CreateUserMetadataForm } from "./CreateUserForm";
import { User } from "../../../../../domain/struct/administration/User";

export const MetaDataTab = ({
  channel,
  dialogProps,
  onClose
}: DialogContentPropsType) => (
  <MetaFormContainer<User>
    channel={channel}
    dialogProps={dialogProps}
    MetaForm={CreateUserMetadataForm}
  />
);
