import {
  DInject,
  DInjectable
} from "presentation/core/features/dependencyInjection";
import { ApiUrls } from "infrastructure/api/struct/ApiTypes";
import { HttpClient } from "infrastructure/service/HttpClient";
import { SignerGroupRepository } from "domain/service/user/SignerGroupRepository";
import { ApiSignerGroup, SignerGroupApiMapper } from "./SignerGroupApiMapper";
import { SignerGroup } from "../../../domain/struct/user/SignerGroup";

const URL: ApiUrls = {
  GetSignerGroup: "/api/app/v1/group/sign-groups"
};

@DInjectable()
export class SignerGroupApiRepository implements SignerGroupRepository {
  constructor(@DInject(HttpClient) protected httpClient: HttpClient) {}
  getSignerGroups(): Promise<SignerGroup[]> {
    return this.httpClient
      .fetchWithThrow<ApiSignerGroup[]>(URL.GetSignerGroup)
      .then(SignerGroupApiMapper.mapSignerGroups);
  }
}
