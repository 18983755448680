import SearchIcon from "@mui/icons-material/Search";
import { MenuItem, TextField } from "@mui/material";
import { Form } from "formik";
import React, { useState } from "react";
import styled from "styled-components";
import { lang, t } from "../../../../translation/i18n";
import { translationPath } from "../../../utils/getPath";

interface FirstMenuItemWithSearchProps {
  onSearch: (inputValue: string) => void;
}

export const FirstMenuItemWithSearch = ({
  onSearch
}: FirstMenuItemWithSearchProps) => {
  const [search, setSearch] = useState("");

  const onClick = () => onSearch(search);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSearch(event.target.value);
  return (
    <Form>
      <StyledMenuItem value="">
        <TextField
          type="text"
          value={search}
          onChange={onChange}
          label={t(translationPath(lang.general.search))}
          InputProps={{
            endAdornment: (
              <StyledButton onClick={onClick}>
                <StyledSearchIcon />
              </StyledButton>
            )
          }}
        />
      </StyledMenuItem>
    </Form>
  );
};

const StyledMenuItem = styled(MenuItem)`
  min-height: 33px;
  display: flex;
  align-items: center;

  .MuiListItem-button {
    transition: unset;
  }
  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
  }
`;

const StyledButton = styled.button`
  border: none;
  background-color: transparent;
  padding: 0;
  outline: none;
  margin-top: 5px;
`;

const StyledSearchIcon = styled(SearchIcon)`
  font-size: 1.5rem;
  cursor: pointer;
  color: dimgrey;
`;
