import {
  DInject,
  DInjectable
} from "presentation/core/features/dependencyInjection";
import { UserRepositoryToken } from "../../domain/di/token/user";
import type { UserRepository } from "../../domain/service/user/UserRepository";

@DInjectable()
export class GetUserInfo {
  constructor(
    @DInject(UserRepositoryToken) protected repository: UserRepository
  ) {}

  getCurrentUserInfo() {
    return this.repository.getById("-me-");
  }
}
