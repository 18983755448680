import "@abraham/reflection";
import { ThemeProvider } from "@emotion/react";
import { ThemeProvider as MuiThemeProvider } from "@mui/material";
import { StylesProvider } from "@mui/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { ConnectedRouter } from "connected-react-router";

import "normalize.css";
import "presentation/boot/wdyr";
import { ErrorBoundary } from "presentation/core/components/errorBoundary";
import { GlobalStyles } from "presentation/core/styles/global";
import Router from "presentation/router";
import { getStore } from "presentation/store";
import { muiTheme, theme } from "presentation/styles";
import React from "react";
import "react-app-polyfill/ie11";
import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import "presentation/translation/i18n";
import { boot } from "./presentation/boot";
import { GlobalError } from "./presentation/core/components/errorBoundary/errorTypes/GlobalError";

import moment from "moment";
import "moment/locale/cs";
import { createRoot } from "react-dom/client";

import { ReactQueryProvider } from "presentation/core/providers/ReactQueryProvider";
import { ReactQueryDevtools } from "react-query/devtools";
import { AuthorizationProvider } from "./presentation/core/providers/AuthorizationProvider";
import { ISFGComponentsConfigProvider } from "./presentation/core/providers/ISFGComponentsConfigProvider";

const LOCALE = "cs";

moment.locale(LOCALE);

const render = (Component: React.ComponentType) => {
  const appStore = getStore();
  const root = createRoot(document.getElementById("root") as HTMLElement);

  return root.render(
    <LocalizationProvider dateLibInstance={moment} adapterLocale={LOCALE}>
      <Provider store={appStore.store}>
        <PersistGate loading={null} persistor={appStore.persistor}>
          <StylesProvider injectFirst={true}>
            <MuiThemeProvider theme={muiTheme}>
              <ThemeProvider theme={theme}>
                <AuthorizationProvider>
                  <ReactQueryProvider>
                    <ISFGComponentsConfigProvider>
                      <ConnectedRouter history={appStore.history}>
                        <GlobalStyles />
                        <ErrorBoundary Component={GlobalError}>
                          <Component />
                        </ErrorBoundary>
                      </ConnectedRouter>
                    </ISFGComponentsConfigProvider>
                    <ReactQueryDevtools />
                  </ReactQueryProvider>
                </AuthorizationProvider>
              </ThemeProvider>
            </MuiThemeProvider>
          </StylesProvider>
        </PersistGate>
      </Provider>
    </LocalizationProvider>
  );
};
boot();
render(Router);
