import { getService } from "presentation/core/features/dependencyInjection";
import { PaginationConfig } from "lib/contract/Pagination";
import { useCallback } from "react";
import { SortingConfig } from "lib/contract/Sorting";
import { FindRetentionProposals } from "../../../../../../../useCase/recordRetention/FindRetentionProposals";

export const useFindRetentionProposal = () => {
  const findRetentionProposal = getService(FindRetentionProposals);

  return useCallback(
    (config: PaginationConfig, sorting?: SortingConfig) =>
      findRetentionProposal.findAllPaginated(config, sorting),
    [findRetentionProposal]
  );
};
