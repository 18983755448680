import MenuLayout from "presentation/core/components/layout/MenuLayout";
import React from "react";
import { withTranslation } from "presentation/translation/i18n";
import { RetentionProposalTable } from "./tables/RetentionProposalTable";

const RetentionProposal = () => {
  return (
    <MenuLayout>
      <RetentionProposalTable />
    </MenuLayout>
  );
};

export default withTranslation()(RetentionProposal);
