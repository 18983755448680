export const useScrollToElement = (defaultElementSelector?: string) => {
  return (elementSelector?: string) => {
    if (elementSelector) {
      const el = document.querySelector(elementSelector);
      el!.scrollIntoView({ behavior: "smooth", block: "end" });
      return;
    }
    if (!defaultElementSelector) {
      return;
    }
    const defaultEl = document.querySelector(defaultElementSelector);
    defaultEl?.scrollIntoView({ behavior: "smooth", block: "end" });
  };
};
