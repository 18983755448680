import { ApiUrls } from "../../../../../infrastructure/api/struct/ApiTypesV2";
import {
  HttpClient,
  HttpMethod
} from "../../../../../infrastructure/service/HttpClient";
import { FetchReturnType } from "../../../../share/utils/fetch";

export interface ConvertAnalogToDigitalContent {
  nodeId: string;
  pageCount: number;
  safetyElements: string[];
  file: File;
}

const URL: ApiUrls = {
  DigitalToAnalog: "/api/app/v2/document/{nodeId}/digital-to-analog",
  AnalogToDigital: "/api/app/v2/document/{nodeId}/analog-to-digital"
};

export const convertDigitalToAnalog = (
  nodeId: string
): Promise<FetchReturnType> => {
  const httpClient = new HttpClient();
  return httpClient.fetchWithThrowWithResponse(
    URL.DigitalToAnalog,
    HttpMethod.Post,
    {
      urlWildCards: {
        nodeId
      }
    }
  );
};

export const convertAnalogToDigital = ({
  nodeId,
  pageCount,
  safetyElements = [],
  file
}: ConvertAnalogToDigitalContent): Promise<FetchReturnType> => {
  const httpClient = new HttpClient();
  const formData = new FormData();
  formData.append("pageCount", "" + pageCount);
  safetyElements.forEach((element) => {
    formData.append("safetyElements", element);
  });
  formData.append("file", file);

  return httpClient.fetchWithThrow(URL.AnalogToDigital, HttpMethod.Post, {
    urlWildCards: { nodeId },
    bodyFormData: formData
  });
};
