import { getService } from "presentation/core/features/dependencyInjection";
import { PaginationConfig } from "../../../../lib/contract/Pagination";
import { useCallback } from "react";
import { FindSearchedSubjectPerson } from "../../../../useCase/nameRegistry/findSearchedSubject/FindSearchedSubjectPerson";
import { FindSearchedSubjectLegalEntity } from "../../../../useCase/nameRegistry/findSearchedSubject/FindSearchedSubjectLegalEntity";

export const useFindSearchedSubjectsPersons = () => {
  const findSearchedSubjectPersonUseCase = getService(
    FindSearchedSubjectPerson
  );

  return useCallback(
    (config: PaginationConfig, params?: Record<string, string>) =>
      findSearchedSubjectPersonUseCase.findAllPaginated(config, params),
    [findSearchedSubjectPersonUseCase]
  );
};

export const useFindSearchedSubjectsLegalEntities = () => {
  const findSearchedSubjectLegalEntityUseCase = getService(
    FindSearchedSubjectLegalEntity
  );

  return useCallback(
    (config: PaginationConfig, params?: Record<string, string>) =>
      findSearchedSubjectLegalEntityUseCase.findAllPaginated(config, params),
    [findSearchedSubjectLegalEntityUseCase]
  );
};
