import { CmProperties, SslFile } from "../../api/models";
import { filterSenderProps } from "./sender";

export const pickFileProps = <T extends SslFile & CmProperties>(payload: T) => {
  const {
    closureDate,
    createdAt,
    customSettleMethod,
    owner,
    pid,
    processor,
    settleDate,
    settleMethod,
    settleReason,
    toArchiveShreddingDate,
    toRepositoryDate,
    triggerActionId,
    triggerActionYear,
    ...rest
  } = payload;

  return filterSenderProps(rest);
};
