import MenuLayout from "presentation/core/components/layout/MenuLayout";
import React from "react";
import { withTranslation } from "presentation/translation/i18n";
import { RetentionProtocolsErasedTable } from "./tables/RetentionProtocolsErasedTable";

const RetentionProtocolsErased = () => {
  return (
    <MenuLayout>
      <RetentionProtocolsErasedTable />
    </MenuLayout>
  );
};

export default withTranslation()(RetentionProtocolsErased);
