import { HttpClient, HttpMethod } from "infrastructure/service/HttpClient";
import { PaginatedResult, PaginationConfig } from "lib/contract/Pagination";
import { Mappers } from "./mappers";
import { SortingConfig } from "lib/contract/Sorting";
import { ApiUrls } from "infrastructure/api/struct/ApiTypesV2";
import { File } from "presentation/core/entities";
import { ActiveComponent } from "../../../../../core/components/dialog/tabs/components/_types";

const URL: ApiUrls = {
  GetActiveCompoents: "/api/app/v2/concept/{nodeId}/components/active",
  updateActiveComponents:
    "/api/app/v2/concept/{nodeId}/component/{componentId}/update"
};

export const getActiveComponents = (
  nodeId: string,
  pagination: PaginationConfig,
  sorting?: SortingConfig
): Promise<PaginatedResult<File>> => {
  const httpClient = new HttpClient();
  return httpClient
    .fetchPaginated<File>({
      url: URL.GetActiveCompoents,
      pagination,
      sorting,
      config: {
        urlWildCards: {
          nodeId: nodeId
        }
      }
    })
    .then(Mappers.mapDocumentForSealListToDomain);
};

export const updateActiveComponents = (
  nodeId: string,
  body: ActiveComponent
): Promise<void> => {
  const httpClient = new HttpClient();

  return httpClient.fetchWithThrow(
    URL.updateActiveComponents,
    HttpMethod.Post,
    {
      bodyJSON: Mappers.mapToApi(body),
      urlWildCards: {
        nodeId,
        componentId: body.componentId
      }
    }
  );
};
