import { PaginationConfig } from "@isfg/lib/build/contract/Pagination";
import { SortingConfig } from "@isfg/lib/build/contract/Sorting";
import { ApiUrls } from "infrastructure/api/struct/ApiTypesV2";
import { HttpClient, HttpMethod } from "infrastructure/service/HttpClient";
import { PaginatedResult } from "lib/contract/Pagination";
import {
  mapClosedTypeFilesPaginated,
  mapTypeFilesHistoryPaginated,
  mapOpenTypeFilesPaginated,
  mapTypeFilesDocumentsPaginated
} from "./mapper";
import {
  ClosedTypeFilesParams,
  ClosedTypeFilesResponse,
  CreateTypeFileParams,
  DetailTypeFileDocument,
  FileTypeStructure,
  LockSectionOfTypeFileParams,
  LockTypeFileParams,
  OpenTypeFilesParams,
  SettleTypeFilesParams,
  TypeFileDocumentsResponse,
  OpenTypeFilesResponse,
  TransactionHistory,
  TransactionHistoryResponse,
  LockPartTypeFileParams,
  ValidateLockTypeFileParams
} from "../types";

const URL: ApiUrls = {
  GetOpenTypeFiles: "/api/app/v2/type-file/open",
  GetClosedTypeFiles: "/api/app/v2/type-file/closed",
  GetDetailTypeFile: "/api/app/v2/type-file/{nodeId}",
  GetTypeFileStructure: "/api/app/v2/type-file/{nodeId}/structure",
  GetTypeFileDocuments:
    "/api/app/v2/type-file/{nodeId}/section/{sectionId}/part/{partId}/documents",
  PostCloseSectionOfTypeFile:
    "/api/app/v2/type-file/{nodeId}/section/{sectionId}/close",
  PostSettledTypeFile: "/api/app/v2/document/{nodeId}/settle",
  GetTypeFileHistory: "/api/app/v2/type-file/{nodeId}/history",
  PostLockTypeFile: "/api/app/v2/type-file/{nodeId}/close",
  PostValidateLockTypeFile:
    "/api/app/v2/type-file/{nodeId}/part/{partId}/close/validate",
  PostLockPartTypeFile: "/api/app/v2/type-file/{nodeId}/part/{partId}/close"
};

// Fetch table OpenTypeFiles
export const getOpenTypeFiles = (
  pagination: PaginationConfig,
  sorting?: SortingConfig,
  params?: OpenTypeFilesParams
): Promise<PaginatedResult<OpenTypeFilesResponse>> => {
  const httpClient = new HttpClient();
  return httpClient
    .fetchPaginated<OpenTypeFilesResponse>({
      url: URL.GetOpenTypeFiles,
      pagination,
      sorting,
      config: {
        params
      }
    })
    .then(mapOpenTypeFilesPaginated);
};

// Create typeFile
export const createTypeFile = (params: CreateTypeFileParams) => {
  const httpClient = new HttpClient();
  return httpClient.fetchWithThrow<CreateTypeFileParams>(
    "/api/app/v2/type-file/create",
    "POST",
    { bodyJSON: params }
  );
};

// Lock typeFile
export const lockTypeFile = (params: LockTypeFileParams) => {
  const { nodeId, ...rest } = params;
  const httpClient = new HttpClient();
  return httpClient.fetchWithThrow<LockTypeFileParams>(
    URL.PostLockTypeFile,
    HttpMethod.Post,
    {
      bodyJSON: rest,
      urlWildCards: { nodeId }
    }
  );
};

// Can Close Part of Type File
export const canClosePartTypeFile = (params: ValidateLockTypeFileParams) => {
  const { nodeId, partId } = params;
  const httpClient = new HttpClient();
  return httpClient.fetchWithThrow<void>(
    URL.PostValidateLockTypeFile,
    HttpMethod.Post,
    {
      urlWildCards: {
        nodeId,
        partId
      }
    }
  );
};

// Lock part typeFile
export const lockPartTypeFile = (params: LockPartTypeFileParams) => {
  const { nodeId, partId, ...rest } = params;
  const httpClient = new HttpClient();
  return httpClient.fetchWithThrow<LockPartTypeFileParams>(
    URL.PostLockPartTypeFile,
    HttpMethod.Post,
    {
      bodyJSON: rest,
      urlWildCards: {
        nodeId,
        partId
      }
    }
  );
};

// Fetch table ClosedTypeFiles
export const getClosedTypeFiles = (
  pagination: PaginationConfig,
  sorting?: SortingConfig,
  params?: ClosedTypeFilesParams
): Promise<PaginatedResult<ClosedTypeFilesResponse>> => {
  const httpClient = new HttpClient();
  return httpClient
    .fetchPaginated<ClosedTypeFilesResponse>({
      url: URL.GetClosedTypeFiles,
      pagination,
      sorting,
      config: {
        params
      }
    })
    .then(mapClosedTypeFilesPaginated);
};

//Fetch FileTypeDetail Modal
export const getDetailTypeFile = (
  nodeId: string
): Promise<DetailTypeFileDocument> => {
  const httpClient = new HttpClient();
  return httpClient.fetchWithThrow<DetailTypeFileDocument>(
    URL.GetDetailTypeFile,
    "GET",
    {
      urlWildCards: {
        nodeId
      }
    }
  );
};

//Fetch File History
export const getTypeFileHistory = (
  nodeId: string,
  pagination: PaginationConfig,
  sorting?: SortingConfig
): Promise<PaginatedResult<TransactionHistory>> => {
  const httpClient = new HttpClient();
  return httpClient
    .fetchPaginated<TransactionHistoryResponse>({
      url: URL.GetTypeFileHistory,
      pagination,
      sorting,
      config: {
        urlWildCards: {
          nodeId
        }
      }
    })
    .then(mapTypeFilesHistoryPaginated);
};

//Fetch FileType structure
export const getTypeFileStructure = (
  nodeId: string
): Promise<FileTypeStructure[]> => {
  const httpClient = new HttpClient();
  return httpClient.fetchWithThrow<FileTypeStructure[]>(
    URL.GetTypeFileStructure,
    "GET",
    {
      urlWildCards: {
        nodeId
      }
    }
  );
};

//Fetch FileType document
export const getTypeFileDocuments = (
  nodeId: string,
  sectionId: string,
  partId: string,
  pagination: PaginationConfig,
  sorting?: SortingConfig
): Promise<PaginatedResult<TypeFileDocumentsResponse>> => {
  const httpClient = new HttpClient();
  return httpClient
    .fetchPaginated<TypeFileDocumentsResponse>({
      url: URL.GetTypeFileDocuments,
      pagination,
      sorting,
      config: {
        urlWildCards: {
          nodeId,
          sectionId,
          partId
        }
      }
    })
    .then(mapTypeFilesDocumentsPaginated);
};

// Close Section of typeFile
export const postCloseSectionOfTypeFile = (
  params: LockSectionOfTypeFileParams
) => {
  const { nodeId, sectionId } = params;
  const httpClient = new HttpClient();
  return httpClient.fetchWithThrow<LockSectionOfTypeFileParams>(
    URL.PostCloseSectionOfTypeFile,
    HttpMethod.Post,
    {
      urlWildCards: {
        nodeId,
        sectionId
      }
    }
  );
};

//Post Type File settled
export const settleTypeFile = (params: SettleTypeFilesParams) => {
  const { nodeId, ...rest } = params;
  const httpClient = new HttpClient();

  return httpClient.fetchWithThrow<void>(
    URL.PostSettledTypeFile,
    HttpMethod.Post,
    {
      bodyJSON: rest,
      urlWildCards: { nodeId }
    }
  );
};
