import { lookup } from "mime-types";

export const getAcceptStringForExtensions = (extensions: string[]) =>
  extensions
    .reduce((extensionsAndMimeTypes: string[], extensionToCheck: string) => {
      const mimeType = lookup(extensionToCheck);
      if (!mimeType) {
        return extensionsAndMimeTypes;
      }
      return [...extensionsAndMimeTypes, `.${extensionToCheck}`, mimeType];
    }, [])
    .join(", ");
