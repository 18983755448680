import { GetRemoteDataClb } from "presentation/designSystem/Table/RemoteTable";
import { useCallback, useMemo, useState } from "react";
import { ShipmentComponentTableProps } from "./ShipmentComponentTable";
import { getService } from "../../../../core/features/dependencyInjection";
import { FindShipmentComponent } from "../../../../../useCase/shipment/FindShipmentComponent";
import { ShipmentComponent } from "../../../../../domain/struct/shipment/ShipmentComponent";
import { useSelection } from "presentation/designSystem/Table/Table";
import { componentValidation } from "../../../../core/components/dialog/tabs/shipmentComponents/methods";
import { useAuthorization } from "../../../../core/hooks/useAuthorization";

const PAGINATION_CONFIG = {
  page: 1,
  itemsPerPage: 1000000
};
export const useLogic = ({
  documentId,
  shipmentId,
  shipmentType,
  shipmentComponentsIds = []
}: ShipmentComponentTableProps) => {
  const authorization = useAuthorization();
  const findShipmentComponent = getService(FindShipmentComponent);
  const [selectedComponentIds, setSelectedComponentIds] = useState(
    [] as string[]
  );

  const getComponents: GetRemoteDataClb<ShipmentComponent> = useCallback(
    ({ pagination }) => {
      // this will return all document components which can be selected for a shipment
      if (authorization.can("view", "DocumentComponent")) {
        return findShipmentComponent
          .getShipableComponents(documentId, PAGINATION_CONFIG)
          .then((result) => {
            setSelectedComponentIds(shipmentComponentsIds);

            return result;
          });
      }

      // this will return only components which are selected for a shipment
      return (
        findShipmentComponent
          .getShipmentComponents(shipmentId!, PAGINATION_CONFIG)
          // Shipment has its own copy of components (with new ids), so we can not use the given shipmentComponentsIds.
          // Because this components are all assigned to a shipment, we have to mark them all as selected.
          .then((result) => {
            setSelectedComponentIds(result.items.map(({ id }) => id));

            return result;
          })
      );
    },
    [
      authorization,
      documentId,
      findShipmentComponent,
      shipmentComponentsIds,
      shipmentId
    ]
  );

  const { rowSelection, keys, items } = useSelection<ShipmentComponent>(
    selectedComponentIds
  );

  const validationResult = useMemo(
    () => componentValidation(items, shipmentType),
    [items, shipmentType]
  );

  return {
    getComponents,
    rowSelection,
    validationResult,
    selectedComponentIds: keys
  };
};
