import { Modal, ModalSize } from "presentation/designSystem/Modal/Modal";
import { BaseModalProps } from "presentation/designSystem/Modal/useModal";
import { Notification } from "presentation/designSystem/notification/Notification";
import styled from "presentation/styles";
import { useMutation } from "../../../../../share/hook/query/useMutation";
import { getErrorCodeTranslation } from "../../../../../share/utils/errorCodeTranslation";
import { translationPath } from "../../../../../share/utils/getPath";
import { lang, t } from "../../../../../translation/i18n";
import { ErrorType } from "../../../../../types";
import { deleteWhiteListEmail } from "../api";
import React from "react";

export interface DeleteContactModalProps extends BaseModalProps {
  emailIds?: number[];
}

export const DeleteFromWhitelistModal = ({
  emailIds,
  onCancel,
  onOk
}: DeleteContactModalProps) => {
  const { mutate: deleteEmail } = useMutation(
    async () => {
      await deleteWhiteListEmail(emailIds);
    },
    {
      onError(error: ErrorType) {
        if (error.message === "Failed to fetch") {
          Notification.error({
            message: t(
              translationPath(
                lang.dialog.notifications.emailWasNotDeletedFromWhiteList
              )
            )
          });
          return;
        }
        if (error.code) {
          return Notification.error({
            message: getErrorCodeTranslation(error.code)
          });
        }
        if (error?.messages) {
          error.messages.map((error: string) =>
            Notification.error({
              message: getErrorCodeTranslation(error)
            })
          );
        } else {
          if (error.message) {
            return Notification.error({
              message: error.message
            });
          }
          Notification.error({
            message: t(translationPath(lang.dialog.notifications.actionFailed))
          });
        }
      },
      onSuccess() {
        Notification.success({
          message: t(
            translationPath(
              lang.dialog.notifications.emailWasDeletedFromWhiteList
            )
          )
        });
        onCancel?.();
      },
      onErrorNotification: null,
      onSuccessNotification: null
    }
  );

  const handleOk = () => {
    deleteEmail(null);
    onOk?.();
  };

  return (
    <Modal
      open={true}
      title={t(translationPath(lang.dialog.whitelistDialogs.delete.title))}
      onCancel={onCancel}
      onOk={handleOk}
      size={ModalSize.Small}
      okText={t(translationPath(lang.modal.ok))}
      cancelText={t(translationPath(lang.modal.cancel))}
    >
      <StyledDiv>
        {t(
          translationPath(
            lang.dialog.whitelistDialogs.delete
              .doYouReallyWantToDeleteEmailFromWhitelist
          )
        )}
      </StyledDiv>
    </Modal>
  );
};

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
