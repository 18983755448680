import { HttpClient } from "infrastructure/service/HttpClient";
import { mapNulltoUndefined } from "presentation/modules/mailroom/features/unfinished/api/mappers";
import { DocumentDetailsFormValues } from "../forms/DocumentDetailsForm";
import {
  DocumentGetEvidenceResponse,
  EvidenceDocumentDetails
} from "../_types";
import { mapDocumentToApi } from "./mappers";

export const getEvidenceDocument = (
  id: string
): Promise<EvidenceDocumentDetails> => {
  const httpClient = new HttpClient();

  return httpClient
    .fetchWithThrow<DocumentGetEvidenceResponse>(
      "/api/app/v2/document/{nodeId}/detail/evidence",
      "GET",
      {
        urlWildCards: {
          nodeId: id
        }
      }
    )
    .then(mapNulltoUndefined);
};

export const updateUnsettledDocument = (
  id: string,
  input: DocumentDetailsFormValues
) => {
  const httpClient = new HttpClient();
  const documentToApi = mapDocumentToApi(input);
  return httpClient.fetchWithThrow<void>(
    "/api/app/v2/document/{nodeId}/update/unsettled",
    "POST",
    {
      bodyJSON: documentToApi,
      urlWildCards: {
        nodeId: id
      }
    }
  );
};
