import { Save } from "@mui/icons-material";
import { nodeContentAction } from "presentation/core/api/node/_actions";
import { ApiURL } from "presentation/core/apiURL";
import { ControlsBarType } from "presentation/core/components/dataTable/_types";
import { DialogContentPropsType } from "presentation/core/components/dialog/_types";
import { FilePlan } from "presentation/core/features/login/_types";
import { fetchShreddingPlanComponent } from "presentation/core/helpers/api/ShreddingPlanComponentFetcher";
import { RootStateType } from "presentation/reducers";
import { lang, t } from "presentation/translation/i18n";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { translationPath } from "../../../utils/getPath";
import Components from "./Components";

type ComponentsInitalState = {
  pageNumber: number;
  rowsPerPage: number;
  sortAsc?: boolean;
  sortColumnIndex?: number;
  sortKeys?: string[];
};

const initialState: ComponentsInitalState = {
  pageNumber: 0,
  rowsPerPage: 100,
  sortAsc: false,
  sortColumnIndex: 1
};

const filterData = (
  items: FilePlan[],
  sortAsc: boolean | undefined,
  pageNumber: number,
  rowsPerPage: number,
  sortKeys?: string[]
) => {
  const start = pageNumber * rowsPerPage;
  return items
    .sort((a, b) => {
      const sortBy = sortKeys ? sortKeys[0] : "name";

      const aValue = a[sortBy as keyof typeof a] as any;
      const bValue = b[sortBy as keyof typeof b] as any;

      if (!aValue || !bValue) {
        return 1;
      }

      return sortAsc
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    })
    .slice(start, start + rowsPerPage);
};

const ComponentsContainer = React.memo(
  ({ channel }: DialogContentPropsType) => {
    const dispatch = useDispatch();
    const [
      { pageNumber, rowsPerPage, sortColumnIndex, sortAsc, sortKeys },
      setState
    ] = useState<ComponentsInitalState>(initialState);

    const itemsWithoutID = useSelector(
      (state: RootStateType) => state.loginReducer.global.filePlansFlat
    );
    const items = useMemo(
      () =>
        itemsWithoutID.map((filePlan) => ({
          ...filePlan,
          id: filePlan.nodeId
        })),
      [itemsWithoutID]
    );
    const filteredItems = useMemo<FilePlan[]>(
      () => filterData(items, sortAsc, pageNumber, rowsPerPage, sortKeys),
      [items, sortAsc, pageNumber, rowsPerPage, sortKeys]
    );

    const [selectedPlans, setSelectedPlans] = useState<FilePlan[]>([
      filteredItems[0]
    ]);

    const isUserAdmin = useSelector(
      (state: RootStateType) => state.loginReducer.session.isAdmin
    );
    const FileUrl = isUserAdmin
      ? ApiURL.A_FILE_PLAN_FILE_PLAN_PRINT
      : ApiURL.FILE_PLAN_FILE_PLAN_PRINT;

    useEffect(() => {
      if (!items.length) {
        return;
      }
      fetchAndPreviewComponent(selectedPlans[0]);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const fetchAndPreviewComponent = async (shreddingPlan: FilePlan) => {
      const component = await fetchShreddingPlanComponent(
        shreddingPlan,
        FileUrl
      );
      if (component) {
        dispatch(nodeContentAction.success(component));
        channel.setPreviewItem(component.isEncrypted || false, component, true);
      }
    };

    const handleChangePage: (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
      page: number
    ) => void = useCallback((_, page) => {
      setState((state) => ({
        ...state,
        pageNumber: page
      }));
    }, []);

    const handleChangeRowsPerPage: (
      event: React.ChangeEvent<HTMLInputElement>
    ) => void = useCallback((event) => {
      setState((state) => ({
        ...state,
        rowsPerPage: +event.target.value
      }));
    }, []);

    const handleShowPreview = (selected: FilePlan[]) => {
      if (selected.length) {
        fetchAndPreviewComponent(selected[0]);
      }
      if (selected.length === items.length) {
        setSelectedPlans(selected);
      } else if (selected.length === 0) {
        setSelectedPlans([]);
      } else {
        setSelectedPlans(selected.filter((s) => !selectedPlans.includes(s)));
      }
    };

    const controls: ControlsBarType<FilePlan> = {
      single: {
        items: [
          {
            action: (selected) => {
              fetchShreddingPlanComponent(selected[0], FileUrl, true);
            },
            icon: <Save />,
            title: t(translationPath(lang.general.download))
          }
        ]
      }
    };

    const handleSortingChange: (
      index: number,
      keys: string[]
    ) => (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void =
      (index, keys) => () => {
        setState((state) => ({
          ...state,
          sortAsc: index === state.sortColumnIndex ? !sortAsc : false,
          sortColumnIndex: index,
          sortKeys: keys
        }));
      };
    return (
      <Components
        controls={controls}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleSortingChange={handleSortingChange}
        handleSelectionChange={handleShowPreview}
        items={filteredItems}
        pageNumber={pageNumber}
        rowsPerPage={rowsPerPage}
        sortAsc={sortAsc}
        sortColumnIndex={sortColumnIndex}
        totalItems={items.length}
        selected={selectedPlans}
      />
    );
  }
);

export default ComponentsContainer;
