import { Modal, ModalSize } from "presentation/designSystem/Modal/Modal";
import { BaseModalProps } from "presentation/designSystem/Modal/useModal";
import { DocumentType } from "presentation/enums";
import { translationPath } from "presentation/share/utils/getPath";
import styled from "presentation/styles";
import { t } from "presentation/translation/i18n";
import lang from "presentation/translation/lang";
import React from "react";

export interface EncryptedComponentsModalProps
  extends BaseModalProps<string[]> {
  documentType?: string;
  encryptedComponents?: string[];
}

const DOCUMENT_TYPE_CONTENT = {
  [DocumentType.Databox]: lang.dialog.content.encryptedComponentsDatabox,
  [DocumentType.Email]: lang.dialog.content.encryptedComponentsEmail,
  [DocumentType.TechnicalDataCarries]: lang.dialog.content.encryptedComponent,
  [DocumentType.Concept]: lang.dialog.content.encryptedComponent
};

export const EncryptedComponentsModal = ({
  onOk,
  onCancel,
  documentType,
  encryptedComponents = []
}: EncryptedComponentsModalProps) => {
  return (
    <Modal
      open
      title={t(translationPath(lang.dialog.title.encryptedComponents))}
      onOk={() => onOk?.(encryptedComponents)}
      onCancel={onCancel}
      size={ModalSize.Small}
      okText={t(translationPath(lang.general.yes))}
      cancelText={t(translationPath(lang.general.no))}
    >
      <StyledDiv>
        {documentType &&
          t(
            translationPath(
              DOCUMENT_TYPE_CONTENT[
                documentType as keyof typeof DOCUMENT_TYPE_CONTENT
              ]
            )
          )}
      </StyledDiv>
    </Modal>
  );
};

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0;
`;
