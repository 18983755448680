import { PaginationConfig } from "lib/contract/Pagination";
import { useCallback } from "react";
import { SortingConfig } from "lib/contract/Sorting";
import { getReturnForRework } from "../api";

export const useFindReturnForRework = () => {
  return useCallback(
    (config: PaginationConfig, sorting?: SortingConfig) =>
      getReturnForRework(config, sorting),
    [getReturnForRework]
  );
};
