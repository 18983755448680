import { t } from "../../presentation/translation/i18n";

const getTranslation = (path: string) => t(`_validations:form.${path}`);

// antd form validation messages https://github.com/react-component/field-form/blob/master/src/utils/messages.ts
export const formValidationMessages = {
  default: getTranslation("default"),
  required: getTranslation("required"),
  date: {
    format: getTranslation("date.format"),
    parse: getTranslation("date.parse"),
    invalid: getTranslation("date.invalid")
  },
  types: {
    string: getTranslation("types.string"),
    number: getTranslation("types.number"),
    url: getTranslation("types.url"),
    email: getTranslation("types.email")
  },
  string: {
    len: getTranslation("string.len"),
    min: getTranslation("string.min"),
    max: getTranslation("string.max"),
    range: getTranslation("string.range")
  },
  number: {
    len: getTranslation("number.len"),
    min: getTranslation("number.min"),
    max: getTranslation("number.max"),
    range: getTranslation("number.range")
  }
};
