import dashboardSquares from "assets/images/dashboardSquares.png";
import MenuLayout from "presentation/core/components/layout/MenuLayout";
import { translationPath } from "presentation/share/utils/getPath";
import { t } from "presentation/translation/i18n";
import lang from "presentation/translation/lang";
import { useSelector } from "react-redux";
import { RootStateType } from "../../../reducers";
import { PDFLoader } from "../../../share/components/licence/PDFLoader";
import {
  StyledBox,
  StyledDashboardSquares,
  StyledDivider,
  StyledIconBox,
  StyledLicense,
  StyledManual,
  StyledSupport,
  StyledTextBox,
  StyledWrapper
} from "./Dashboard.styles";
import React from "react";

interface OwnProps {
  menupath?: string[];
}

const USER_GUIDE = "Uzivatelska_dokumentace_spisum.pdf";
const ADMIN_GUIDE = "Administrátorká_příručka_spisum.pdf";

const Dashboard = (props: OwnProps) => {
  const { isAdmin } = useSelector(
    (state: RootStateType) => state?.loginReducer?.session
  );
  return (
    <MenuLayout>
      <StyledWrapper>
        <StyledTextBox>
          <p>{t(translationPath(lang.dashboard.moveYourKnowledge))}</p>
          <p className="colored">
            {t(translationPath(lang.dashboard.documentDigitization))}
          </p>
          <p>{t(translationPath(lang.dashboard.toNewLevel))}</p>
        </StyledTextBox>
        <StyledDivider />
        <StyledBox>
          <PDFLoader
            fileName={isAdmin ? ADMIN_GUIDE : USER_GUIDE}
            icon={<StyledManual />}
            linkName={t(translationPath(lang.dashboard.usersManual))}
            data-test-id="usersManual"
          />
          <StyledIconBox>
            <a
              href="https://isfg.atlassian.net/servicedesk/customer/portal/14"
              rel="noopener noreferrer"
              target="_blank"
              data-test-id="servicedesk"
            >
              <StyledSupport />
            </a>
            <span>{t(translationPath(lang.dashboard.technicalSupport))}</span>
          </StyledIconBox>
          <PDFLoader
            fileName="Licencni_podminky_SpisUm.pdf"
            icon={<StyledLicense />}
            linkName={t(translationPath(lang.dashboard.license))}
            data-test-id="licence"
          />
        </StyledBox>
      </StyledWrapper>
    </MenuLayout>
  );
};

export default Dashboard;
