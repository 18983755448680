import { createProxy } from "../../../utils/getPath";
import { FilePlan } from "../../../../core/types";

export type ChangeValidToDateFormValues = {
  validFrom: string;
  validTo: string;
  nextDateToDisable?: string;
  nextItem?: FilePlan;
  previousDateToDisable?: string;
};

export const changeValidToDateProxy = createProxy<ChangeValidToDateFormValues>();
