import { registerDialog } from "presentation/core/components/dialog/RegisterDialog";
import { dialogs } from "presentation/share/components/dialog";
import { handoverDocumentDialogContent } from "presentation/share/components/dialog/documentHandoverDialog/handoverDocumentDialogContent";
import { renameComponentDialogContent } from "presentation/share/components/dialog/renameComponentDialog/renameComponentDialogContent";

const register = () => {
  [
    ...dialogs,
    handoverDocumentDialogContent,
    renameComponentDialogContent
  ].forEach(registerDialog);
};

const boot = {
  register
};

export default boot;
