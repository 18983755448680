import { ShipmentDocument } from "presentation/core/types";
import { ApiUrls } from "infrastructure/api/struct/ApiTypesV2";
import { HttpClient, HttpMethod } from "infrastructure/service/HttpClient";
import { ApiURL } from "presentation/core/apiURL";
import { SpisumNodeTypes } from "presentation/enums";
import { ErrorResponseDataType } from "presentation/share/utils/fetch";
import { PaginationConfig } from "lib/contract/Pagination";
import { mapGetShipments } from "./mappers";

const URL: ApiUrls = {
  GetDownloadDelivery: "/api/app/v2/shipment/{nodeId}/download/delivery"
};

export interface GetDownloadDeliveryParams {
  nodeId: string;
  dataBoxId?: string;
  dataBoxMessageId?: string;
  group?: string;
}

type Response = string | boolean | Blob | ErrorResponseDataType;

export const getDownloadDelivery = (
  params: GetDownloadDeliveryParams
): Promise<{
  response: Response;
  headers: Headers | undefined;
}> => {
  const httpClient = new HttpClient();
  const { nodeId, ...rest } = params;
  return httpClient.fetchWithReponseHeaders<Response>(
    URL.GetDownloadDelivery,
    HttpMethod.Get,
    {
      params: rest,
      urlWildCards: { nodeId }
    }
  );
};

export const getShipments = (nodeId: string, config: PaginationConfig) => {
  const httpClient = new HttpClient();

  return httpClient
    .fetchPaginatedAlfresco<ShipmentDocument>(ApiURL.NODE_CHILDREN, config, {
      params: {
        where: `(nodeType='${SpisumNodeTypes.Shipment} INCLUDESUBTYPES')`
      } as Record<string, string>,
      urlWildCards: {
        nodeId
      }
    })
    .then(mapGetShipments);
};
