import { NameRegisterConfiguration } from "../../../domain/struct/nameRegistry/NameRegisterConfiguration";
import { ApiTypes } from "../struct/ApiTypes";

export type ApiNameRegisterConfigurationResponse = ApiTypes["ISFG.SpisUm.ClientSide.Models.V1.NameRegister.NameRegisterConfiguration"];

export class NameRegisterConfigurationApiMapper {
  static mapToApi(
    configuration: NameRegisterConfiguration
  ): ApiNameRegisterConfigurationResponse {
    return {
      deleteSubjectsAfter: configuration.deleteSubjectsAfter,
      deleteLegalPerson: configuration.deleteLegalPerson
    };
  }
}
