import {
  DInject,
  DInjectable
} from "presentation/core/features/dependencyInjection";
import { ApiUrls } from "infrastructure/api/struct/ApiTypes";
import { HttpClient, HttpMethod } from "infrastructure/service/HttpClient";
import { GroupRepository } from "domain/service/user/GroupRepository";
import {
  ApiApplicableGroupsForUserResponse,
  ApiGroupsToHandover,
  ApiUserGroups,
  GroupApiMapper
} from "./GroupApiMapper";
import { GroupBag } from "../../../domain/struct/user/GroupBag";
import { UserId } from "../../../domain/struct/user/User";
import { UserGroups } from "../../../domain/struct/user/UserGroups";
import { GroupsToHandover } from "../../../domain/struct/user/GroupsToHandover";

const URL: ApiUrls = {
  GetApplicableGroupsForUser: "/api/admin/v1/group/members",
  GetUserGroups: "/api/admin/v1/users/{userId}/groups",
  GetGroupsToHandover: "/api/app/v1/group/handover-groups"
};

export const getGroupsToHandover = (): Promise<GroupsToHandover[]> => {
  const httpClient = new HttpClient();
  return httpClient
    .fetchWithThrow<ApiGroupsToHandover[]>(URL.GetGroupsToHandover)
    .then(GroupApiMapper.mapGroupsToHandover);
};

export const getApplicableGroupsForUser = (): Promise<GroupBag> => {
  const httpClient = new HttpClient();
  return httpClient
    .fetchWithThrow<ApiApplicableGroupsForUserResponse>(
      URL.GetApplicableGroupsForUser
    )
    .then(GroupApiMapper.mapApplicableGroupsForUser);
};

export const getUserGroups = (userId: UserId): Promise<UserGroups> => {
  const httpClient = new HttpClient();
  return httpClient
    .fetchWithThrow<ApiUserGroups>(URL.GetUserGroups, HttpMethod.Get, {
      urlWildCards: {
        userId
      }
    })
    .then(GroupApiMapper.mapUserGroupsToDomain);
};

@DInjectable()
export class GroupApiRepository implements GroupRepository {
  constructor(@DInject(HttpClient) protected httpClient: HttpClient) {}

  getApplicableGroupsForUser(): Promise<GroupBag> {
    return this.httpClient
      .fetchWithThrow<ApiApplicableGroupsForUserResponse>(
        URL.GetApplicableGroupsForUser
      )
      .then(GroupApiMapper.mapApplicableGroupsForUser);
  }

  getUserGroups(userId: UserId): Promise<UserGroups> {
    return this.httpClient
      .fetchWithThrow<ApiUserGroups>(URL.GetUserGroups, HttpMethod.Get, {
        urlWildCards: {
          userId
        }
      })
      .then(GroupApiMapper.mapUserGroupsToDomain);
  }

  getGroupsToHandover(): Promise<GroupsToHandover[]> {
    return this.httpClient
      .fetchWithThrow<ApiGroupsToHandover[]>(URL.GetGroupsToHandover)
      .then(GroupApiMapper.mapGroupsToHandover);
  }
}
