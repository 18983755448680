import { ApiAlfrescoListResponse, ApiTypes } from "../struct/ApiTypes";
import { PaginatedResult } from "../../../lib/contract/Pagination";
import {
  DocumentSubjectAssociation,
  FileConcernedSubject
} from "../../../domain/struct/nameRegistry/FileConcernedSubject";
import { ApiSubject, SubjectApiMapper } from "../nameRegistry/SubjectApiMapper";
import { Subject } from "../../../domain/struct/nameRegistry/Subject";
import { AlfrescoListResponseMapper } from "../mapper/AlfrescoListResponseMapper";
import { UUID } from "../../../lib/UUID";
import { DocumentSubjectRelationType } from "../../../domain/struct/document/DocumentConcernedSubject";

export type ApiFileConcernedSubject = ApiTypes["ISFG.SpisUm.ClientSide.Models.NameRegister.FileConcernedInfo"];
export type ApiDocumentSubjectAssociations = ApiTypes["ISFG.SpisUm.ClientSide.Models.NameRegister.DocumentSubjectAssociaton"];

export class FileConcernedSubjectApiMapper {
  static mapFindAll(
    response: ApiAlfrescoListResponse<ApiFileConcernedSubject>
  ): PaginatedResult<FileConcernedSubject> {
    return AlfrescoListResponseMapper.mapListToPagination(
      response,
      FileConcernedSubjectApiMapper.mapToDomain
    );
  }

  static mapToDomain(
    apiFileConcernedSubject: ApiFileConcernedSubject
  ): FileConcernedSubject {
    return {
      id: UUID.generate(), // need as a key to the table
      documentSubjectAssociations: FileConcernedSubjectApiMapper.mapAssociationsToDomain(
        apiFileConcernedSubject.documentSubjectAssociatons || []
      ),
      ...(apiFileConcernedSubject.subject && {
        subject: FileConcernedSubjectApiMapper.mapSubjectToDomain(
          apiFileConcernedSubject.subject
        )
      })
    };
  }

  static mapAssociationsToDomain(
    associations: ApiDocumentSubjectAssociations[]
  ): DocumentSubjectAssociation[] {
    return associations.map(
      FileConcernedSubjectApiMapper.mapAssociationToDomain
    );
  }

  static mapAssociationToDomain(
    association: ApiDocumentSubjectAssociations
  ): DocumentSubjectAssociation {
    return {
      relationId: association.relationId,
      relationType: association.relationType as DocumentSubjectRelationType,
      documentId: association.documentId || undefined,
      documentPid: association.documentPid || undefined,
      additionalDocumentId: association.additionalDocumentId || undefined
    };
  }

  static mapSubjectToDomain(subject: ApiSubject): Subject {
    return SubjectApiMapper.mapToDomain(subject);
  }
}
