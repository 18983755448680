import { ApiUrl } from "infrastructure/api/struct/ApiTypesV2";
import { HttpClient, HttpMethod } from "infrastructure/service/HttpClient";

const URL: ApiUrl = "/api/app/v2/document/{nodeId}/settle";
export interface SettleDocumentParams {
  nodeId: string;
  settleMethod: string;
  otherSettleMethod?: string;
  otherSettleReason?: string;
}

export const settleDocument = (params: SettleDocumentParams) => {
  const { nodeId, ...rest } = params;
  const httpClient = new HttpClient();

  return httpClient.fetchWithThrow(URL, HttpMethod.Post, {
    bodyJSON: rest,
    urlWildCards: { nodeId }
  });
};
