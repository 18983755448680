import { sslPropsProxy } from "presentation/core/types";
import {
  lastPathMember,
  translationPath
} from "presentation/share/utils/getPath";
import { t } from "presentation/translation/i18n";
import lang from "presentation/translation/lang";
import * as yup from "yup";

const positiveNumber = (propName: string) =>
  yup
    .string()
    .test(
      propName,
      t(translationPath(lang.dialog.errors.min), { val: 0 }),
      (val) => (val ? Number(val) >= 0 : true)
    );
export const listCount = () =>
  positiveNumber(lastPathMember(sslPropsProxy.listCount).path);

export const attachmentsCount = () =>
  positiveNumber(lastPathMember(sslPropsProxy.attachmentsCount).path);

export const listCountAttachments = () =>
  positiveNumber(lastPathMember(sslPropsProxy.listCountAttachments).path);

export const digits = (count: number, message?: string) => {
  const defaultMessage = t(translationPath(lang.dialog.errors.wrongFormat));

  return yup
    .string()
    .matches(/^[0-9]*$/, message || defaultMessage)
    .max(count, t(translationPath(lang.dialog.errors.maxLen), { len: count }));
};

export const letters = (count: number, message?: string) => {
  const defaultMessage = t(translationPath(lang.dialog.errors.wrongFormat));

  return yup
    .string()
    .matches(/^[a-z]*$/, message || defaultMessage)
    .max(count, t(translationPath(lang.dialog.errors.maxLen), { len: count }));
};

export const characters = (count: number, message?: string) => {
  const defaultMessage = t(translationPath(lang.dialog.errors.wrongFormat));

  return yup
    .string()
    .matches(/^[a-z0-9]*$/, message || defaultMessage)
    .max(count, t(translationPath(lang.dialog.errors.maxLen), { len: count }));
};
