import moment from "moment";
import { SslComponent } from "presentation/core/api/models";

export const showProperTime = (
  mainFile?: SslComponent
): Date | undefined | null => {
  const { usedTimeSign, usedTimeSeal } = mainFile || {};
  if (usedTimeSign && usedTimeSeal) {
    return moment(usedTimeSeal).isAfter(moment(usedTimeSign))
      ? usedTimeSeal
      : usedTimeSign;
  }

  return usedTimeSign || usedTimeSeal;
};
