import { SubjectPersistenceLogic } from "./SubjectPersistenceLogic";
import { Address } from "../../../../../../domain/struct/nameRegistry/Address";
import { Contact } from "../../../../../../domain/struct/nameRegistry/Contact";
import { PaginatedResult, PaginationConfig } from "lib/contract/Pagination";
import { Subject } from "../../../../../../domain/struct/nameRegistry/Subject";

const getDefaultResult = () => Promise.reject(new Error("Not implemented"));

export class InitialPersistenceSubjectLogic implements SubjectPersistenceLogic {
  addAddress(address: Address): Promise<void> {
    return getDefaultResult();
  }
  disableSubjectTypeSelect(): boolean {
    return true;
  }
  getAddressesFromState(): Address[] | null {
    return null;
  }
  getContactsFromState(): Contact[] | null {
    return null;
  }

  addContact(contact: Contact): Promise<void> {
    return getDefaultResult();
  }

  getAddresses(
    pagination: PaginationConfig
  ): Promise<PaginatedResult<Address>> {
    return getDefaultResult();
  }

  getContacts(pagination: PaginationConfig): Promise<PaginatedResult<Contact>> {
    return getDefaultResult();
  }

  getSubjectData(): Subject | undefined {
    return undefined;
  }

  removeAddress(address: Address): Promise<void> {
    return getDefaultResult();
  }

  removeContact(contact: Contact): Promise<void> {
    return getDefaultResult();
  }

  resetState(): void {}

  save(): Promise<void> {
    return getDefaultResult();
  }

  setSubjectData(subject: Subject): void {}

  updateAddress(address: Address): Promise<void> {
    return getDefaultResult();
  }

  updateContact(contact: Contact): Promise<void> {
    return getDefaultResult();
  }
}
