import { getService } from "presentation/core/features/dependencyInjection";
import { useMutation } from "../../../share/hook/query/useQM";
import { RequestPasswordReset } from "../../../../useCase/authentication/RequestPasswordReset";
import { t } from "../../../translation/i18n";
import { translationPath } from "../../../share/utils/getPath";
import lang from "../../../translation/lang";
import { getErrorCodeTranslation } from "../../../share/utils/errorCodeTranslation";

export const useForgotPassword = () => {
  const requestPasswordReset = getService(RequestPasswordReset);

  const getNotificationForError = (error: any) => ({
    message: getErrorCodeTranslation(error.code)
  });

  return useMutation(
    (userId: string) => requestPasswordReset.requestPasswordReset(userId),
    {
      onErrorNotification: getNotificationForError,
      onSuccessNotification: {
        message: t(
          translationPath(lang.dialog.notifications.passwordSentOnEmail)
        )
      }
    }
  );
};
