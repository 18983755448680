import { callAsyncAction } from "presentation/core/action";
import {
  DialogContentType,
  DialogType
} from "presentation/core/components/dialog/_types";
import { secondaryAction } from "presentation/core/components/dialog/lib/actionsFactory";
import { documentViewAction__Refresh } from "presentation/core/components/documentView/_actions";
import NamedTitle from "presentation/core/components/namedTitle";
import { GenericDocument } from "presentation/core/types";
import { ModalSize } from "presentation/designSystem/Modal/Modal";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import CancelDialog from "../cancelDialog/CancelDialog";
import { sendModeTypes } from "../sendShipmentDialog/_sagas";
import { returnToRepositoryActionType } from "./_actions";
import React from "react";

export const returnToRepositoryDialogContent: DialogContentType = {
  actions: () => [
    secondaryAction(
      t(translationPath(lang.dialog.buttons.confirm)),
      ({ dispatch, dialogProps, onClose, buttonState }) => {
        const dialogPropsTyped = dialogProps.data as GenericDocument;
        if (!dialogPropsTyped) {
          return;
        }

        const onSuccess = () => {
          dispatch(documentViewAction__Refresh(true));
          onClose();
        };

        buttonState.setIsPending(true);

        dispatch(
          callAsyncAction({
            action: returnToRepositoryActionType,
            onError: onClose,
            onSuccess,
            payload: {
              nodeId: dialogPropsTyped.id,
              nodeType:
                sendModeTypes[
                  dialogPropsTyped.nodeType as keyof typeof sendModeTypes
                ]
            }
          })
        );
      }
    )
  ],
  content: (props) => (
    <CancelDialog
      {...props}
      question={t(translationPath(lang.dialog.content.returnToRepository))}
    />
  ),
  title: (props) => (
    <NamedTitle text={t(translationPath(lang.general.return))} {...props} />
  ),
  type: DialogType.ReturnToRepository,
  size: ModalSize.Small
};
