import { Input } from "presentation/designSystem/Input/Input";
import { BaseModalProps } from "presentation/designSystem/Modal/useModal";
import styled from "styled-components";
import {
  RetentionProposalRemoveObject,
  RetentionProposalRemoveObjectsId
} from "../../../../../domain/struct/recordRetention/RetentionProposalRemoveObjects";
import { RemoveRetentionProposalObjects } from "../../../../../useCase/recordRetention/RemoveRetentionProposalObjects";
import { getService } from "../../../../core/features/dependencyInjection";
import { Form, Item, useForm } from "../../../../designSystem/Form/v2/Form";
import { Modal, ModalSize } from "../../../../designSystem/Modal/Modal";
import { useMutation } from "../../../../share/hook/query/useQM";
import { getErrorCodeTranslation } from "../../../../share/utils/errorCodeTranslation";
import { translationPath } from "../../../../share/utils/getPath";
import { lang, t } from "../../../../translation/i18n";
import React from "react";

type OnCancelClb = () => void;
export interface RetentionProposalRemoveObjProps extends BaseModalProps {
  nodeId: string;
  objectIds: RetentionProposalRemoveObjectsId[];
  onRetentionProposalRemove?: OnCancelClb;
}

const validationReasonRules = [{ required: true }, { min: 4 }, { max: 30 }];
const { TextArea } = Input;

export const RetentionProposalRemoveObjectModal = ({
  nodeId,
  objectIds,
  onOk,
  onCancel,
  onRetentionProposalRemove
}: RetentionProposalRemoveObjProps) => {
  const getNotificationForError = (error: any) => ({
    message:
      getErrorCodeTranslation(error.code) ||
      t(
        translationPath(
          lang.dialog.notifications.removeRetentionProposalObjFailed
        )
      )
  });

  const [removeRetentionProposalObjectForm] =
    useForm<RetentionProposalRemoveObject>();
  const removeRetentionProposalObj = getService(RemoveRetentionProposalObjects);

  const { isLoading, mutate: removeRetentionProposalObject } = useMutation(
    (nodeId: string) =>
      removeRetentionProposalObj.removeObject(
        nodeId,
        objectIds,
        removeRetentionProposalObjectForm.getFieldsValue().reason
      ),
    {
      onSuccess(data) {
        if (data?.isShreddingProposalCanceled) {
          onRetentionProposalRemove?.();
          return;
        }
        onOk?.();
      },
      onSuccessNotification: {
        message: t(
          translationPath(
            lang.dialog.notifications.removeRetentionProposalObjSucceeded
          )
        )
      },
      onErrorNotification: getNotificationForError
    }
  );

  const handleOk = async () => {
    try {
      await removeRetentionProposalObjectForm.validateFields();
      removeRetentionProposalObject(nodeId);
    } catch {}
  };

  return (
    <Modal
      open={true}
      title={t(
        translationPath(lang.dialog.removeRetentionProposalObjectForm.title)
      )}
      onCancel={onCancel}
      onOk={handleOk}
      confirmLoading={isLoading}
      size={ModalSize.Small}
      okText={t(translationPath(lang.modal.ok))}
      cancelText={t(translationPath(lang.modal.cancel))}
    >
      <StyledDiv>
        <Form
          name="removeRetentionProposalObjectForm"
          form={removeRetentionProposalObjectForm}
          style={{ width: "100%" }}
        >
          <Item name="reason" rules={validationReasonRules}>
            <TextArea
              placeholder={t(
                translationPath(lang.general.removeRetentionProposalObjReason)
              )}
            />
          </Item>
        </Form>
      </StyledDiv>
    </Modal>
  );
};

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1.5rem;
`;
