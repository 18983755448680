import { useQM } from "../../../share/hook/query/useQM";
import { getService } from "../../../core/features/dependencyInjection";
import { FindRetentionProposals } from "../../../../useCase/recordRetention/FindRetentionProposals";

const KEY = "retentionProposals/findRetentionProposal";

export const useFindRetentionProposalById = (nodeId: string) => {
  const findRetentionProposal = getService(FindRetentionProposals);
  return useQM([KEY, nodeId], () => findRetentionProposal.findOne(nodeId));
};
