import React from "react";
import { TableActionsButtons } from "./TableActionsButtons";
import { TableAction, TableActions } from "./contract";

export interface TableActionsBarProps<Row> {
  actions: TableActions<Row>;
  selectedRows: Row[];
  dataSource: Row[];
  onActionFinished?: (
    action: TableAction<Row>,
    dataWasMutated: boolean
  ) => void;
}

export function TableActionsBar<Row>({
  actions,
  selectedRows,
  dataSource,
  onActionFinished
}: TableActionsBarProps<Row>) {
  const canShowSingleActions = actions.single && selectedRows.length === 1;
  const canShowMultiActions = actions.multi && selectedRows.length > 1;

  return (
    <div>
      <div>
        {actions.default && (
          <TableActionsButtons
            actions={actions.default}
            selectedRows={selectedRows}
            dataSource={dataSource}
            onActionFinished={onActionFinished}
          />
        )}
        {canShowSingleActions && (
          <TableActionsButtons
            actions={actions.single!}
            selectedRows={selectedRows}
            dataSource={dataSource}
            onActionFinished={onActionFinished}
          />
        )}
        {canShowMultiActions && (
          <TableActionsButtons
            actions={actions.multi!}
            selectedRows={selectedRows}
            dataSource={dataSource}
            onActionFinished={onActionFinished}
          />
        )}
      </div>
    </div>
  );
}
