import LinearProgress from "@mui/material/LinearProgress";
import { useDocumentVisibility } from "ahooks";
import { DialogContentPropsType } from "presentation/core/components/dialog/_types";
import { signer } from "presentation/core/helpers/api/Signer";
import { Notification } from "presentation/designSystem/notification/Notification";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import React, { useCallback, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import CancelDialog from "../cancelDialog/CancelDialog";
import Timeout = NodeJS.Timeout;

const PERIOD = 3000;

const successStatuses = ["OK", "BatchResultDone"];

const SignAndTimestampDialog = ({
  channel,
  dialogProps,
  onClose
}: DialogContentPropsType) => {
  const dispatch = useDispatch();
  const isPageVisible = useDocumentVisibility();
  const intervalRef = useRef<Timeout | undefined>(undefined);

  const clearRequestInterval = useCallback(() => {
    if (!intervalRef.current) {
      return;
    }

    clearInterval(intervalRef.current);
  }, []);

  const handleSigningFinish = useCallback(
    (allSigned: boolean) => {
      clearRequestInterval();
      allSigned
        ? Notification.success({
            message: t(
              translationPath(lang.dialog.notifications.actionSucceeded)
            )
          })
        : Notification.error({
            message: t(translationPath(lang.dialog.notifications.actionFailed))
          });

      onClose?.();
    },
    [clearRequestInterval, dispatch, onClose]
  );

  const fetchFilesStatuses = useCallback(async () => {
    if (!dialogProps.signerComponentId) {
      return;
    }

    try {
      const componentsSignStatuses = await signer.getComponentsSignStatus(
        dialogProps.signerComponentId
      );

      if (!componentsSignStatuses || !componentsSignStatuses.length) {
        return;
      }

      const allSigned = successStatuses.includes(
        componentsSignStatuses[0].status
      );
      handleSigningFinish(allSigned);
    } catch (e) {
      handleSigningFinish(false);
    }
  }, [dialogProps.signerComponentId, handleSigningFinish]);

  useEffect(() => {
    // There is no sense to make request when the Signer app is opened.
    // Also browsers do some optimizations, so the setInterval callback can be delayed
    // and all pushed callbacks can be invoked at once when window gets focus again.
    // @see https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API#policies_in_place_to_aid_background_page_performance
    if (!isPageVisible) {
      clearRequestInterval();
      return;
    }

    intervalRef.current = setInterval(fetchFilesStatuses, PERIOD);

    return clearRequestInterval;
  }, [clearRequestInterval, fetchFilesStatuses, isPageVisible]);

  return (
    <CancelDialog
      channel={channel}
      dialogProps={dialogProps}
      question={
        <div>
          <p>{t(translationPath(lang.dialog.content.signingInProgress))}</p>
          <p>{t(translationPath(lang.dialog.content.signingInProgressHelp))}</p>
          <p>
            {t(translationPath(lang.dialog.content.signingInProgressHelpText))}
          </p>
          <LinearProgress />
        </div>
      }
    />
  );
};

export default SignAndTimestampDialog;
