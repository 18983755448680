import { Dayjs } from "dayjs";
import { DateTime, dateTime } from "../../../../../lib/dateTime";
import { DatePicker } from "../../../../designSystem/DatePicker/DatePicker";
import { Item, Rule } from "../../../../designSystem/Form/v2/Form";
import { ColOneFourth, Row } from "../../../../designSystem/Grid/Grid";
import { Input } from "../../../../designSystem/Input/Input";
import {
  required,
  stringMax
} from "../../../../designSystem/Input/InputValidations";
import { translationPath } from "../../../../share/utils/getPath";
import { lang, t } from "../../../../translation/i18n";
import { CitizenshipSelect } from "./Input/CitizenshipSelect";
import React from "react";

const disableFutureDates = (date: DateTime) => {
  return date && date > dateTime().endOf("day");
};

const STRING_MAX_50 = stringMax(50);
const STRING_MAX_20 = stringMax(20);
const RULES: Record<string, Rule[]> = {
  title: [STRING_MAX_20],
  firstName: [required, STRING_MAX_50],
  lastName: [required, STRING_MAX_50],
  degree: [STRING_MAX_20],
  maidenName: [STRING_MAX_50],
  bornPlace: [STRING_MAX_50]
};

export const PersonFields = () => {
  return (
    <>
      <Row justify="space-between">
        <ColOneFourth spaceAround>
          <Item
            name="title"
            label={t(
              translationPath(lang.dialog.form.personForm.titleBeforeName)
            )}
            rules={RULES.title}
          >
            <Input />
          </Item>
        </ColOneFourth>
        <ColOneFourth spaceAround>
          <Item
            name="firstName"
            label={t(translationPath(lang.dialog.form.personForm.name))}
            rules={RULES.firstName}
          >
            <Input />
          </Item>
        </ColOneFourth>
        <ColOneFourth spaceAround>
          <Item
            name="surname"
            label={t(translationPath(lang.dialog.form.personForm.surname))}
            rules={RULES.lastName}
          >
            <Input />
          </Item>
        </ColOneFourth>
        <ColOneFourth spaceAround>
          <Item
            name="degree"
            label={t(
              translationPath(lang.dialog.form.personForm.titleAfterName)
            )}
            rules={RULES.degree}
          >
            <Input />
          </Item>
        </ColOneFourth>
      </Row>
      <Row justify="space-between">
        <ColOneFourth spaceAround>
          <Item
            name="bornDate"
            label={t(translationPath(lang.dialog.form.personForm.dateOfBirth))}
          >
            <DatePicker
              disabledDate={(date: Dayjs) =>
                disableFutureDates(date as DateTime)
              }
            />
          </Item>
        </ColOneFourth>
        <ColOneFourth spaceAround>
          <Item
            name="bornPlace"
            label={t(translationPath(lang.dialog.form.personForm.cityOfBirth))}
            rules={RULES.bornPlace}
          >
            <Input />
          </Item>
        </ColOneFourth>
        <ColOneFourth spaceAround>
          <Item
            name="citizenship"
            label={t(translationPath(lang.dialog.form.personForm.nationality))}
          >
            <CitizenshipSelect />
          </Item>
        </ColOneFourth>
        <ColOneFourth spaceAround>
          <Item
            name="maidenName"
            label={t(translationPath(lang.dialog.form.personForm.birthSurname))}
            rules={RULES.maidenName}
          >
            <Input />
          </Item>
        </ColOneFourth>
      </Row>
    </>
  );
};
