import {
  DInject,
  DInjectable
} from "presentation/core/features/dependencyInjection";
import { ShipmentRepositoryToken } from "../../domain/di/token/shipment";
import type { ShipmentRepository } from "../../domain/service/shipment/ShipmentRepository";
import { ShipmentId } from "../../domain/struct/shipment/Shipment";

@DInjectable()
export class FindShipment {
  constructor(
    @DInject(ShipmentRepositoryToken) protected repository: ShipmentRepository
  ) {}

  getById(id: ShipmentId) {
    return this.repository.getById(id);
  }
}
