import { documentCreateActionType } from "presentation/core/api/document/_actions";
import { SslAnalogWithOwner } from "presentation/core/api/models";
import { dialogOpenAction } from "presentation/core/components/dialog/_actions";
import { DialogType } from "presentation/core/components/dialog/_types";
import { metaFormAction__Update } from "presentation/core/components/MetaForm/_actions";
import { Notification } from "presentation/designSystem/notification/Notification";

import { DocumentType, SpisumNodeTypes } from "presentation/enums";
import { put, takeLatest } from "redux-saga/effects";
import { createTechnicalDataCarriesDocument } from "presentation/share/components/dialog/technicalDataCarriesDetailsDialog/_actions";
import { translationPath } from "presentation/share/utils/getPath";
import { handleResponse } from "presentation/share/utils/typesafeActions";
import { lang, t } from "presentation/translation/i18n";
import { ActionType, getType } from "typesafe-actions";

export const defaultTechnicalCarriesValues = (): SslAnalogWithOwner => {
  return {
    attachmentsCount: undefined,
    attachmentsType: "",
    borrowDate: null,
    borrowReturnDate: null,
    createdAt: null,
    deliveryDate: new Date(),
    deliveryMode: "",
    deliveryTime: new Date(),
    fileMark: "",
    filePlan: "",
    form: DocumentType.Digital,
    listCountAttachments: undefined,
    owner: "",
    pid: "",
    retentionMark: "",
    retentionMode: "",
    retentionPeriod: null,
    senderId: "",
    senderType: "",
    senderAddressId: "",
    senderCompanyEmployeeJob: "",
    senderFullName: "",
    senderCompanyName: "",
    senderCompanyEmployeeUnit: "",
    settleToDate: null,
    shreddingDate: null,
    state: "",
    subject: ""
  };
};

export function* watchTechnicalDataCarriesDocumentAction() {
  yield takeLatest(
    getType(createTechnicalDataCarriesDocument),
    function* ({
      payload
    }: ActionType<typeof createTechnicalDataCarriesDocument>) {
      yield put(
        dialogOpenAction({
          dialogProps: {
            onClose: payload.onClose,
            saveOnOpen: true,
            useAutoSave: true
          },
          dialogType: DialogType.TechnicalDataCarriesDetails
        })
      );

      yield put(
        documentCreateActionType.request({
          documentType: DocumentType.Digital,
          nodeType: SpisumNodeTypes.Document
        })
      );

      const [successResponse, , success] = yield handleResponse(
        documentCreateActionType
      );

      if (!success) {
        Notification.error({
          message: t(translationPath(lang.dialog.notifications.actionFailed))
        });
        return;
      }

      yield put(
        metaFormAction__Update({
          documentId: successResponse.entry.id,
          formValues: {
            ...defaultTechnicalCarriesValues(),
            ...successResponse.entry.properties.ssl
          },
          isLocked: successResponse.entry.isLocked,
          nodeType: successResponse.entry.nodeType
        })
      );
    }
  );
}
