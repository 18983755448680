import { FindShipmentHistory } from "useCase/shipment/FindShipmentHistory";

import { ShipmentHistoryRepositoryToken } from "domain/di/token/shipment";
import { ShipmentHistoryApiRepository } from "infrastructure/api/shipment/ShipmentHistoryApiRepository";
import { FindShipment } from "useCase/shipment/FindShipment";

import { ShipmentRepositoryToken } from "domain/di/token/shipment";
import { ShipmentApiRepository } from "infrastructure/api/shipment/ShipmentApiRepository";
import { FindShipmentComponent } from "useCase/shipment/FindShipmentComponent";
import { ShipmentComponentRepositoryToken } from "domain/di/token/shipment";
import { ShipmentComponentApiRepository } from "infrastructure/api/shipment/ShipmentComponentApiRepository";

export const shipmentProviders = [
  // repository
  {
    provide: ShipmentRepositoryToken,
    useClass: ShipmentApiRepository
  },
  {
    provide: ShipmentComponentRepositoryToken,
    useClass: ShipmentComponentApiRepository
  },
  {
    provide: ShipmentHistoryRepositoryToken,
    useClass: ShipmentHistoryApiRepository
  },
  // use case
  FindShipment,
  FindShipmentHistory,
  FindShipmentComponent
];
