import { HttpClient } from "infrastructure/service/HttpClient";
import {
  DInject,
  DInjectable
} from "presentation/core/features/dependencyInjection";
import { CountryRepository } from "../../../domain/service/codelist/CountryRepository";
import { ApiCountries, CountryApiMapper } from "./CountryApiMapper";
import { CountryType } from "../../../domain/struct/codelist/CountryType";

const URL = "/api/app/v1/codelists/countries";

@DInjectable()
export class CountryApiRepository implements CountryRepository {
  constructor(@DInject(HttpClient) protected httpClient: HttpClient) {}

  findAll(): Promise<CountryType[]> {
    return this.httpClient
      .fetchWithThrow<ApiCountries[]>(URL)
      .then(CountryApiMapper.mapToDomain);
  }
}
