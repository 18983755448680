import { useState } from "react";
import { SubjectType } from "../../../../../../domain/struct/nameRegistry/SubjectType";
import { LegalEntity } from "../../../../../../domain/struct/nameRegistry/LegalEntity";
import { Person } from "../../../../../../domain/struct/nameRegistry/Person";

export const useSearchSubject = () => {
  const [selectedSubjectType, setSelectedSubjectType] = useState<SubjectType>(
    SubjectType.Person
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState<
    React.Key[] | undefined
  >();
  const [selectedSubject, setSelectedSubject] = useState<
    LegalEntity | Person | undefined
  >();
  const [remoteQueryParams, setRemoteQueryParams] = useState<
    Record<string, string>
  >({
    keyword: "",
    searchIndividualSubjects: "true"
  });

  const createQueryParamsBySubjectType = (
    value: SubjectType
  ): Record<string, string> => {
    if (value === SubjectType.Person) {
      return { searchIndividualSubjects: "true" };
    }
    return { searchLegalSubjects: "true" };
  };

  const onSearch = (value: string) => {
    clearSelectedSubject();
    setSelectedRowKeys([]);
    const params = createQueryParamsBySubjectType(selectedSubjectType);
    setRemoteQueryParams({
      keyword: value,
      ...params
    });
  };

  const onSelectChange = async (value: SubjectType) => {
    if (value) {
      clearSelectedSubject();
      setSelectedRowKeys([]);
      setSelectedSubjectType(value);
      const params = createQueryParamsBySubjectType(value);
      setRemoteQueryParams((prevState) => ({
        keyword: prevState.keyword,
        ...params
      }));
    }
  };

  const clearSelectedSubject = () => setSelectedSubject(undefined);

  return {
    selectedSubjectType,
    remoteQueryParams,
    setSelectedSubject,
    onSelectChange,
    selectedSubject,
    onSearch,
    selectedRowKeys,
    setSelectedRowKeys
  };
};
