import React, { useCallback, useState } from "react";
import { GetRemoteDataClb } from "presentation/designSystem/Table/RemoteTable";
import {
  DocumentConcernedSubject,
  DocumentSubjectRelationType
} from "domain/struct/document/DocumentConcernedSubject";
import { Subject, SubjectId } from "domain/struct/nameRegistry/Subject";
import { useRemoteTableApiContext } from "presentation/designSystem/Table/contexts/RemoteTableApiContextProvider";
import {
  ConcernedSubjectTable,
  ConcernedSubjectTableProps
} from "./ConcernedSubjectTable";
import { useDocumentConcernedSubjects } from "./hooks/useDocumentConcernedSubjects";
import { getDocumentConcernedSubjects } from "./api";
import { PaginatedResult } from "lib/contract/Pagination";

export interface UseDocConcrndSubjTableProps
  extends Omit<
    ConcernedSubjectTableProps,
    | "getSubjects"
    | "addConcernedSubjects"
    | "removeConcernedSubjects"
    | "entityId"
    | "queryConfig"
  > {
  documentId: ConcernedSubjectTableProps["entityId"];
}

export type UseDocConcrndSubjTableResult = [
  JSX.Element,
  {
    setSenderAsConcernedSubject(sender: Subject): void;
    refreshDocConcernedSubjs(): void;
  }
];

export const useDocumentConcernedSubjectTable = ({
  documentId,
  ...tableProps
}: UseDocConcrndSubjTableProps): UseDocConcrndSubjTableResult => {
  const {
    addDocConcernedSubjs,
    removeDocConcernedSubjs
  } = useDocumentConcernedSubjects();

  const { refreshData } = useRemoteTableApiContext();

  const [sender, setSender] = useState<Subject | undefined>();

  const addSenderAsConcernedSubject = useCallback(
    (paginatedResult: PaginatedResult<DocumentConcernedSubject>) => {
      return sender
        ? {
            ...paginatedResult,
            items: paginatedResult.items.concat({
              id:
                paginatedResult.items.length > 0
                  ? paginatedResult.items[paginatedResult.items.length - 1]
                      .id! + 1
                  : 0,
              documentId: documentId,
              relationType: DocumentSubjectRelationType.Sender,
              subject: sender
            })
          }
        : paginatedResult;
    },
    [documentId, sender]
  );
  const getSubjects: GetRemoteDataClb<DocumentConcernedSubject> = useCallback(
    ({ pagination }) =>
      getDocumentConcernedSubjects(documentId, pagination).then(
        addSenderAsConcernedSubject
      ),
    [documentId, addSenderAsConcernedSubject]
  );

  const removeConcernedSubjects = useCallback(
    async (subjectIds: SubjectId[]) => {
      removeDocConcernedSubjs(
        { nodeId: documentId, subjectIds },
        { onSuccess: refreshData }
      );
    },
    [removeDocConcernedSubjs, documentId, refreshData]
  );

  const addConcernedSubjects = useCallback(
    async (subjectIds: SubjectId[]) => {
      addDocConcernedSubjs(
        { nodeId: documentId, subjectIds },
        { onSuccess: refreshData }
      );
    },
    [addDocConcernedSubjs, documentId, refreshData]
  );

  const setSenderAsConcernedSubject = useCallback(
    (sender: Subject) => {
      setSender(sender);
      refreshData();
    },
    [refreshData]
  );

  const documentConcernedSubjectTable = (
    <ConcernedSubjectTable
      getSubjects={getSubjects}
      removeConcernedSubjects={removeConcernedSubjects}
      addConcernedSubjects={addConcernedSubjects}
      entityId={documentId}
      {...tableProps}
    />
  );

  return [
    documentConcernedSubjectTable,
    {
      setSenderAsConcernedSubject,
      refreshDocConcernedSubjs: refreshData
    }
  ];
};
