import { useModal } from "presentation/designSystem/Modal/useModal";
import { useMutation } from "presentation/share/hook/query/useQM";
import { useHistory } from "react-router-dom";
import { registerTechnicalDataCarriesDocument } from "../api";
import { TechnicalDataCarrierModal } from "../modal/TechnicalDataCarrierModal";

export const useCreateTechnicalDataCarrier = () => {
  const { goBack } = useHistory();
  const [modal, modalApi] = useModal(TechnicalDataCarrierModal, {
    onClose: goBack
  });
  const { isLoading, mutate: create } = useMutation(
    registerTechnicalDataCarriesDocument,
    {
      onSuccess: (data) => {
        modalApi.open({
          nodeId: data.nodeId,
          document: data
        });
      }
    }
  );

  return {
    modal,
    isLoading,
    create
  };
};
