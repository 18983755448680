import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { callAsyncAction } from "presentation/core/action";
import { componentDeleteAction } from "presentation/core/api/components/_actions";
import { useMetaFormDocument } from "presentation/core/components/dialog/hooks/useMetaFormDocument";
import { Associations, SpisumNodeTypes } from "presentation/enums";
import InactiveComponentsV2TabContainer, {
  ComponentsTabContainerState,
  ComponentsV2TabContainerProps
} from "../InactiveComponentsV2TabContainer";
import { DialogDataGenericData } from "../../../_types";
import {
  ChangePageFnType,
  ChangeRowsPerPageFnType,
  SortChangeFnType
} from "../InactiveComponentsTab";
import { lang, t } from "presentation/translation/i18n";
import { translationPath } from "presentation/share/utils/getPath";
import { NotificationSeverity } from "presentation/core/components/notifications/_types";
import { ErrorTypeWithFailedIds } from "presentation/types";
import { File } from "presentation/core/entities";
import { useGetInactiveComponents } from "./useGetInactiveComponents";
import { SortDirection } from "../../../../../../govDesignSystem/lib/contract/Sorting";

const initialState: ComponentsTabContainerState = {
  pageNumber: 0,
  rowsPerPage: 100
};

export interface UseComponentsTabContextProviderProps
  extends Partial<ComponentsV2TabContainerProps> {
  nodeId: string;
  showDelete?: boolean;
}

export type UseComponentsTabContainerResult = [
  JSX.Element,
  {
    inActiveFetchComponent(): void;
  }
];

export const useInactiveComponentsV2TabContainer = ({
  componentType,
  nodeId,
  showDelete = false,
  isActive,
  ...props
}: UseComponentsTabContextProviderProps): UseComponentsTabContainerResult => {
  const dispatch = useDispatch();
  const metaFormDocument = useMetaFormDocument();
  const [state, setState] = useState<ComponentsTabContainerState>(initialState);

  const nodeType =
    (props.dialogProps?.data as DialogDataGenericData)?.nodeType ||
    metaFormDocument.nodeType;
  const getWhereAssocType = useCallback(() => {
    switch (nodeType) {
      case SpisumNodeTypes.Databox:
        return `(assocType='${Associations.Databox}')`;
      case SpisumNodeTypes.Email:
        return `(assocType='${Associations.Email}')`;
      case SpisumNodeTypes.Concept:
        return `(assocType='${Associations.Components}')`;
      case SpisumNodeTypes.Document:
      case SpisumNodeTypes.File:
        return `(assocType='${Associations.Components}')`;
      default:
        return "";
    }
  }, [nodeType]);

  const { data, refetch, isFetching } = useGetInactiveComponents(
    nodeId,
    isActive,
    {
      page: state.pageNumber ? state.pageNumber : 1,
      itemsPerPage: state.rowsPerPage ? state.rowsPerPage : 10
    },
    [
      {
        property:
          state.sortKeys && state.sortKeys.length ? state.sortKeys![0] : "name",
        direction: state.sortAsc
          ? SortDirection.Ascending
          : SortDirection.Descending
      }
    ]
  );

  const inActiveFetchComponent = useCallback(() => {
    refetch();
  }, [dispatch, state, nodeId, getWhereAssocType]);

  const handleChangePage: ChangePageFnType = useCallback((_, page) => {
    setState((state) => ({
      ...state,
      pageNumber: page
    }));
  }, []);

  const handleChangeRowsPerPage: ChangeRowsPerPageFnType = useCallback(
    (event) => {
      setState((state) => ({
        ...state,
        pageNumber: 0,
        rowsPerPage: parseInt(event.target.value, 10)
      }));
    },
    []
  );

  const handleSortingChange: SortChangeFnType = useCallback(
    (index, keys) => (event) => {
      setState((state) => ({
        ...state,
        sortAsc: index === state.sortColumnIndex ? !state.sortAsc : false,
        sortColumnIndex: index,
        sortKeys: keys
      }));
    },
    []
  );

  const handleDeleteComponent = useCallback(
    (selectedComponents: File[]) => {
      const onErrorMessage =
        selectedComponents.length > 1
          ? t(
              translationPath(
                lang.dialog.notifications.componentDeleteOneOrMoreFailed
              )
            )
          : t(translationPath(lang.dialog.notifications.actionFailed));

      const onError = (errorResponse: ErrorTypeWithFailedIds) => {
        if (
          errorResponse?.ids &&
          errorResponse?.ids?.length < selectedComponents?.length
        ) {
          inActiveFetchComponent();
        }
      };

      dispatch(
        callAsyncAction({
          action: componentDeleteAction,
          onError,
          onErrorNotification: {
            message: onErrorMessage,
            severity: NotificationSeverity.Error
          },
          onSuccess: inActiveFetchComponent,
          payload: {
            componentIds: selectedComponents.map((c) => c.id),
            nodeId,
            nodeType: componentType
          }
        })
      );
    },
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const componentsTabContainerEl = (
    <InactiveComponentsV2TabContainer
      {...props}
      {...state}
      nodeId={nodeId}
      data={data}
      isFetching={isFetching}
      fetchComponents={inActiveFetchComponent}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleSortingChange={handleSortingChange}
      metaFormDocument={metaFormDocument}
      handleDeleteComponent={showDelete ? handleDeleteComponent : undefined}
    />
  );

  return [
    componentsTabContainerEl,
    {
      inActiveFetchComponent
    }
  ];
};
