import {
  DialogContentType,
  DialogType
} from "presentation/core/components/dialog/_types";
import NamedTitle from "presentation/core/components/namedTitle";
import React from "react";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import SignAndTimestampDialog from "./SignAndTimestampDialog";
import { ModalSize } from "@isfg/react-components/build/Modal/Modal";

export const signAndTimestampDialogContent: DialogContentType = {
  content: SignAndTimestampDialog,
  title: (props) => (
    <NamedTitle
      text={t(translationPath(lang.dialog.title.signing))}
      {...props}
    />
  ),
  type: DialogType.SignAndTimestamp,
  size: ModalSize.Small
};
