import {
  HttpClient,
  HttpMethod,
  HttpMethodString
} from "infrastructure/service/HttpClient";
import { getService } from "presentation/core/features/dependencyInjection";
import { ErrorType } from "presentation/types";
import { call } from "redux-saga/effects";
import { trimEnd } from "../../../lib/string";
import {
  UrlParamsObject,
  stringify as stringifyToQuery
} from "../../../lib/url/queryString";

type WildCards = Record<string, string | number>;

export type ErrorResponseDataType = {
  code?: string | number;
  data?: object;
  fields?: [] | null;
  message?: string;
  result?: {
    errors: [
      {
        errorMessage: string;
        propertyName: string;
      }
    ];
    isValid: boolean;
  };
};

export type ResponseDataType = string | object | Blob | true;

export interface FetchReturnType {
  errorResponse?: ErrorType;
  errorResponseData?: object | null;
  fields?: [] | null;
  response?: ResponseDataType | ErrorResponseDataType;
  responseHeaders?: Headers;
  status: number;
  success: boolean;
}

export const getURL = (suffixURL: string) => {
  let domain = process.env.REACT_APP_URL;
  let protocol = process.env.REACT_APP_PROTOCOL;
  const portString = process.env.REACT_APP_PORT
    ? `:${process.env.REACT_APP_PORT}`
    : "";

  if (!domain) {
    domain = window.location.host;
  }

  if (!protocol) {
    protocol = trimEnd(window.location.protocol, ":");
  }
  return `${protocol}://${domain}${portString}${suffixURL}`;
};

export const replaceWildCards = (suffixURL: string, wildCards?: WildCards) => {
  if (!wildCards) {
    return suffixURL;
  }

  return Object.keys(wildCards).reduce((subject, key) => {
    const replacement = `${wildCards[key]}`;
    return subject
      .replace(`:${key}`, replacement)
      .replace(`{${key}}`, replacement);
  }, suffixURL);
};

export const createUrl = (
  suffixURL: string,
  params?: UrlParamsObject,
  urlWildCards?: WildCards
) => {
  const appURL = `${getURL(replaceWildCards(suffixURL, urlWildCards))}`;

  return (params && `${appURL}${query(params)}`) || appURL;
};

export const query = (queryParams: UrlParamsObject) => {
  const query = stringifyToQuery(queryParams, {
    encodeValuesOnly: true,
    allowDots: true
  });

  return (query && `?${query}`) || "";
};

export function* fetchSaga(
  suffixURL: string,
  method: HttpMethodString = HttpMethod.Get,
  options: {
    bodyJSON?: object;
    bodyFormData?: FormData;
    params?: Record<string, string>;
    urlWildCards?: WildCards;
    contentType?: string;
  } = {}
): Generator<any, any, any> {
  const httpClient = getService<HttpClient>(HttpClient);
  const fetch = httpClient.fetch.bind(httpClient);
  try {
    return yield call(fetch, suffixURL, method, options);
  } catch (ex) {
    return yield call(httpClient.error);
  }
}
