import { callAsyncAction } from "presentation/core/action";
import { deactivateUserAction } from "presentation/core/api/user/_actions";
import { secondaryAction } from "presentation/core/components/dialog/lib/actionsFactory";
import {
  DialogContentType,
  DialogDataGenericData,
  DialogType
} from "presentation/core/components/dialog/_types";
import NamedTitle from "presentation/core/components/namedTitle";
import { NotificationSeverity } from "presentation/core/components/notifications/_types";
import React from "react";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import CancelDialog from "../cancelDialog/CancelDialog";
import { ModalSize } from "presentation/designSystem/Modal/Modal";
import { documentViewAction__Refresh } from "presentation/core/components/documentView/_actions";

export const deactivateUserDialogContent: DialogContentType = {
  actions: () => [
    secondaryAction(
      t(translationPath(lang.dialog.buttons.confirm)),
      ({ dispatch, dialogProps, onClose, buttonState }) => {
        if (!dialogProps.data) {
          return;
        }

        const onSuccess = () => {
          onClose();
          dispatch(documentViewAction__Refresh(true));
          dialogProps.onSuccess?.();
        };
        const onError = () => {
          buttonState.setIsPending(false);
          dialogProps.onError?.();
        };

        buttonState.setIsPending(true);

        dispatch(
          callAsyncAction({
            action: deactivateUserAction,
            onError: onError,
            onErrorNotification: {
              message: t(
                translationPath(lang.dialog.notifications.deactivateUserFailed)
              ),
              severity: NotificationSeverity.Error
            },
            onSuccess: onSuccess,
            onSuccessNotification: {
              message: t(
                translationPath(
                  lang.dialog.notifications.deactivateUserSucceeded
                )
              ),
              severity: NotificationSeverity.Success
            },
            payload: {
              userId: (dialogProps.data as DialogDataGenericData).id
            }
          })
        );
      }
    )
  ],
  content: (props) => (
    <CancelDialog
      {...props}
      style={{ fontWeight: "bold" }}
      question={t(translationPath(lang.dialog.content.deactivateUser), {
        id: (props.dialogProps.data as DialogDataGenericData)?.id
      })}
    />
  ),
  title: (props) => (
    <NamedTitle text={t(translationPath(lang.general.deactivate))} {...props} />
  ),
  type: DialogType.DeactivateUser,
  size: ModalSize.Small
};
