import {
  StyledErrorContainer,
  StyledErrorIcon,
  StyledErrorMessage
} from "presentation/core/features/login/FormRenderer.form.styles";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import React from "react";

export interface OwnProps {
  clearErrorState?: VoidFunction;
  message?: string;
  reloadTab?: VoidFunction;
  handleClose?: VoidFunction;
  children?: React.ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<
  OwnProps & { Component?: (props: OwnProps) => JSX.Element }
> {
  state: State = {
    hasError: false
  };

  message: string = t(translationPath(lang.errorBoundary.defaultMessage));

  constructor(props: OwnProps) {
    super(props);
    this.state = { hasError: false };
    this.message = props.message || this.message;
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // logErrorToMyService(error, errorInfo);
  }

  clearErrorState(ctx: React.Component<OwnProps>) {
    return () => {
      ctx.setState({ hasError: false });
    };
  }

  render() {
    const clearErrorState = this.clearErrorState(this);
    const { Component, ...props } = this.props;
    if (this.state.hasError) {
      if (Component) {
        return <Component clearErrorState={clearErrorState} {...props} />;
      }
      return (
        <StyledErrorContainer>
          <StyledErrorMessage>
            <StyledErrorIcon />
            <span>{this.message}</span>
          </StyledErrorMessage>
        </StyledErrorContainer>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
