import { useCallback } from "react";
import { PaginationConfig } from "@isfg/lib/build/contract/Pagination";
import { SortingConfig } from "@isfg/lib/build/contract/Sorting";
import { getTypeFileDocuments } from "../api";

export const useGetTypeFileDocuments = () => {
  return useCallback(
    (
      nodeId: string,
      sectionId: string,
      partId: string,
      config: PaginationConfig,
      sorting?: SortingConfig
    ) => getTypeFileDocuments(nodeId, sectionId, partId, config, sorting),
    [getTypeFileDocuments]
  );
};
