import { createSafeAsyncAction } from "../../../share/utils/typesafeActions";
import { ErrorType } from "../../../types";
import {
  ExportFilePlanType,
  ExportShreddingProposalType,
  FilePlanRequestType
} from "./_types";
import { FileChangeValidityDateType, ImportFilePlanType } from "./_types";

export const getFilePlanAction = createSafeAsyncAction(
  "@imprint/GET_FILE_PLAN_REQUEST",
  "@imprint/GET_FILE_PLAN_SUCCESS",
  "@imprint/GET_FILE_PLAN_FAILURE"
)<FilePlanRequestType, void, ErrorType>();

export const exportToPDFFilePlanAction = createSafeAsyncAction(
  "@imprint/EXPORT_TO_PDF_FILE_PLAN_REQUEST",
  "@imprint/EXPORT_TO_PDF_FILE_PLAN_SUCCESS",
  "@imprint/EXPORT_TO_PDF_FILE_PLAN_FAILURE"
)<ExportFilePlanType, void, ErrorType>();

export const exportFilePlanSummaryAction = createSafeAsyncAction(
  "@imprint/EXPORT_FILE_PLAN_SUMMARY_REQUEST",
  "@imprint/EXPORT_FILE_PLAN_SUMMARY_SUCCESS",
  "@imprint/EXPORT_FILE_PLAN_SUMMARY_FAILURE"
)<ExportFilePlanType, void, ErrorType>();

export const filePlanChangeValidityDate = createSafeAsyncAction(
  "@file/CHANGE_VALID_TO_DATE_REQUEST",
  "@file/CHANGE_VALID_TO_DATE_SUCCESS",
  "@file/CHANGE_VALID_TO_DATE_FAILURE"
)<FileChangeValidityDateType, void, ErrorType>();

export const importFilePlanAction = createSafeAsyncAction(
  "@components/OPEN_AND_SEND_ACTION_REQUEST",
  "@components/OPEN_AND_SEND_ACTION_SUCCESS",
  "@components/OPEN_AND_SEND_ACTION_FAILURE"
)<ImportFilePlanType, void, ErrorType>();

export const exportShreddingProposalAction = createSafeAsyncAction(
  "@components/EXPORT_SHREDDING_PROPOSAL_ACTION_REQUEST",
  "@components/EXPORT_SHREDDING_PROPOSAL_ACTION_SUCCESS",
  "@components/EXPORT_SHREDDING_PROPOSAL_ACTION_FAILURE"
)<ExportShreddingProposalType, void, ErrorType>();
