import { HttpClient, HttpMethod } from "infrastructure/service/HttpClient";
import { PaginatedResult, PaginationConfig } from "lib/contract/Pagination";
import { mapInactiveComponentToDomain, mapToComponentIds } from "./mappers";
import { SortingConfig } from "lib/contract/Sorting";
import { ApiUrls } from "infrastructure/api/struct/ApiTypesV2";
import { File } from "presentation/core/entities";

const URL: ApiUrls = {
  GetInactiveComponents: "/api/app/v2/document/{nodeId}/components/inactive",
  ActivateComponents: "/api/app/v2/document/{nodeId}/components/activate"
};

export const getInactiveComponents = (
  nodeId: string,
  pagination: PaginationConfig,
  sorting?: SortingConfig
): Promise<PaginatedResult<File>> => {
  const httpClient = new HttpClient();
  return httpClient
    .fetchPaginated<File>({
      url: URL.GetInactiveComponents,
      pagination,
      sorting,
      config: {
        urlWildCards: {
          nodeId: nodeId
        }
      }
    })
    .then(mapInactiveComponentToDomain);
};

export const postActivateComponents = (
  nodeId: string,
  components: File[]
): Promise<void> => {
  const httpClient = new HttpClient();

  return httpClient.fetchWithThrow(URL.ActivateComponents, HttpMethod.Post, {
    bodyJSON: mapToComponentIds(components),
    urlWildCards: {
      nodeId
    }
  });
};
