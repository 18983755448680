import {
  documentAddToFavoriteAction,
  documentRemoveFromFavoriteAction
} from "presentation/core/api/document/_actions";
import {
  fileDocumentAddToFavoriteAction,
  fileDocumentRemoveFromFavoriteAction
} from "presentation/core/api/file/_actions";
import { documentViewAction__UpdateIsFavorite } from "presentation/core/components/documentView/_actions";
import { Notification } from "presentation/designSystem/notification/Notification";
import { SpisumNodeTypes } from "presentation/enums";
import { translationPath } from "presentation/share/utils/getPath";
import { handleResponse } from "presentation/share/utils/typesafeActions";
import { lang, t } from "presentation/translation/i18n";
import { put, takeEvery } from "redux-saga/effects";
import { ActionType, getType } from "typesafe-actions";
import { changeDocumentIsFavoriteAction } from "./_actions";
import { ChangeDocumentIsFavoriteActionType } from "./_types";

const actions = {
  [SpisumNodeTypes.Document]: {
    [ChangeDocumentIsFavoriteActionType.Add]: documentAddToFavoriteAction,
    [ChangeDocumentIsFavoriteActionType.Remove]:
      documentRemoveFromFavoriteAction
  },
  [SpisumNodeTypes.File]: {
    [ChangeDocumentIsFavoriteActionType.Add]: fileDocumentAddToFavoriteAction,
    [ChangeDocumentIsFavoriteActionType.Remove]:
      fileDocumentRemoveFromFavoriteAction
  }
};

export function* watchEvidenceModuleActions() {
  yield takeEvery(
    getType(changeDocumentIsFavoriteAction),
    function* ({ payload }: ActionType<typeof changeDocumentIsFavoriteAction>) {
      const { items, nodeType, actionType } = payload;

      const action = actions[nodeType as keyof typeof actions][actionType];

      if (!action) {
        return;
      }

      const ids = items.map((item) => item.id);

      yield put(action.request({ ids }));

      const [, , success] = yield handleResponse(action);

      if (!success) {
        Notification.error({
          message: t(translationPath(lang.dialog.notifications.actionFailed))
        });
        return;
      }

      const isFavorite =
        actionType === ChangeDocumentIsFavoriteActionType.Add ? true : false;

      Notification.success({
        message: t(translationPath(lang.dialog.notifications.actionSucceeded))
      });

      yield put(documentViewAction__UpdateIsFavorite({ ids, isFavorite }));
    }
  );
}
