import { createProxy } from "../../../utils/getPath";

export interface PromoteConceptToDocumentFormValues {
  attachmentsCount: number | string;
  settleTo: Date | null | string;
  subject: string;
  author?: string;
  authorGroup?: string;
}

export const PromoteConceptToDocumentFormValuesProxy = createProxy<PromoteConceptToDocumentFormValues>();

export interface PromoteConceptToDocumentRequestType {
  nodeId: string;
  body: {
    author: string;
    attachmentsCount: number;
    settleTo: Date;
    subject: string;
  };
}
