import { DialogContentPropsType } from "presentation/core/components/dialog/_types";
import React from "react";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";

const DataModifiedDialog = ({ channel }: DialogContentPropsType) => {
  return (
    <div style={{ padding: "10px 0" }}>
      {t(translationPath(lang.dialog.content.unsavedChanges))}
    </div>
  );
};

export default DataModifiedDialog;
