import { PaginatedResult } from "../../../lib/contract/Pagination";
import { AlfrescoListResponseMapper } from "../mapper/AlfrescoListResponseMapper";
import { ApiAlfrescoListResponse, ApiTypes } from "../struct/ApiTypes";
import { LegalEntity } from "../../../domain/struct/nameRegistry/LegalEntity";
import { LegalEntityApiMapper } from "./LegalEntityApiMapper";

export type ApiSearchedSubject = Partial<
  ApiTypes["ISFG.SpisUm.ClientSide.Models.NameRegister.SubjectInfo"]
>;

export class SearchedSubjectLegalEntityApiMapper {
  static mapFindAllResponse(
    response: ApiAlfrescoListResponse<ApiSearchedSubject>
  ): PaginatedResult<LegalEntity> {
    return AlfrescoListResponseMapper.mapListToPagination(
      response,
      LegalEntityApiMapper.mapToDomain
    );
  }
}
