import { SessionStatus } from "presentation/core/features/login/_types";
import { logoutAction } from "presentation/core/features/logout/_actions";
import { CoreRoutes } from "presentation/core/routes";
import {
  createUrl,
  ErrorResponseDataType,
  FetchReturnType
} from "presentation/share/utils/fetch";
import { getStore } from "presentation/store";
import { ErrorType } from "presentation/types";
import { DInjectable } from "presentation/core/features/dependencyInjection";
import { PaginationConfig } from "../../lib/contract/Pagination";
import { SortingConfig } from "../../lib/contract/Sorting";
import {
  ApiAlfrescoListResponse,
  ApiListResponse,
  ApiPaginationQuery,
  ApiSortDirection,
  ApiSortQuery,
  ApiUrl
} from "../api/struct/ApiTypes";
import {
  ApiPaginationQuery as ApiPaginationQueryV2,
  ApiSortQuery as ApiSortQueryV2,
  ApiUrl as ApiUrlV2
} from "../api/struct/ApiTypesV2";
import { UrlParamsObject } from "../../lib/url/queryString";
import { loginUpdateExpireInAction } from "../../presentation/core/features/login/_actions";

export type HttpClientConfig = {
  authToken?: string;
  activeGroup?: string;
};

export type WildCards = Record<string, string | number>;

export interface FetchPayload {
  bodyJSON?: object;
  bodyFormData?: FormData;
  params?: UrlParamsObject;
  urlWildCards?: WildCards;
  contentType?: string;
  group?: string;
}

export interface FetchPaginatedPayload
  extends Omit<FetchPayload, "bodyJSON" | "bodyFormData"> {}

export interface CreateRequestPayload
  extends Omit<FetchPayload, "params" | "urlWildCards"> {
  method: HttpMethodString;
}

export type HttpMethodString = "GET" | "POST" | "DELETE";
export const HttpMethod: { [key: string]: HttpMethodString } = {
  Get: "GET",
  Post: "POST",
  Delete: "DELETE"
};

export interface FetchPaginatedConfig {
  url: ApiUrl | ApiUrlV2;
  pagination: PaginationConfig;
  sorting?: SortingConfig;
  config?: FetchPaginatedPayload;
}

@DInjectable()
export class HttpClient {
  config: HttpClientConfig = {};
  appStore = getStore();

  async fetch(
    suffixURL: string,
    method: HttpMethodString = HttpMethod.Get,
    {
      bodyJSON,
      bodyFormData,
      params,
      urlWildCards,
      contentType,
      group
    }: FetchPayload = {}
  ): Promise<FetchReturnType> {
    const response = await fetch(
      createUrl(suffixURL, params, urlWildCards),
      this.createRequest({ contentType, bodyFormData, bodyJSON, method, group })
    );

    const responseData = await this.parseResponse(response);

    if (response.ok) {
      this.appStore.store.dispatch(loginUpdateExpireInAction());
      return {
        response: responseData || responseData.toString() || true,
        responseHeaders: response.headers,
        status: response.status,
        success: true
      };
    }
    if (response.status === 500) {
      return this.error();
    }
    if (response.status === 503) {
      window.location.reload();
    }

    const state = this.appStore.store.getState();

    const sessionStatus: SessionStatus = state.loginReducer.session.status;

    if (
      response.status === 401 &&
      sessionStatus !== SessionStatus.UNAUTHORIZED
    ) {
      this.appStore.store.dispatch(logoutAction.success());
      this.appStore.history.push(CoreRoutes.LOGIN);
    }

    if (response.status === 401) {
      return this.error({ status: 401, message: "unauthorized" });
    }

    const errorResponseData = responseData as ErrorResponseDataType;
    if (errorResponseData?.result) {
      return {
        errorResponse: {
          messages: errorResponseData.result.errors?.map(
            (error: { errorMessage: string }) => error.errorMessage
          ),
          code: "",
          message: ""
        },
        errorResponseData:
          (errorResponseData && errorResponseData.data) || null,
        fields: (errorResponseData && errorResponseData.fields) || null,
        responseHeaders: response.headers,
        status: response.status,
        success: false
      };
    }

    return {
      errorResponse: {
        code:
          (errorResponseData && errorResponseData.code) ||
          response.status.toString(),
        message:
          (errorResponseData && errorResponseData.message) ||
          errorResponseData.result?.errors ||
          "badRequest"
      } as ErrorType,
      errorResponseData: (errorResponseData && errorResponseData.data) || null,
      fields: (errorResponseData && errorResponseData.fields) || null,
      responseHeaders: response.headers,
      status: response.status,
      success: false
    };
  }

  /**
   * Use this method if you need to handle not only response body but also other response attributes,
   * like headers.
   * @param suffixURL
   * @param method
   * @param fetchPayload
   */
  async fetchWithThrowWithResponse<FetchReturnType>(
    suffixURL: string,
    method: HttpMethodString = HttpMethod.Get,
    fetchPayload: FetchPayload = {}
  ) {
    const response = await this.fetch(suffixURL, method, fetchPayload);
    if (response.errorResponse) {
      throw response.errorResponse;
    }

    return response;
  }

  /**
   * Use this method if you need to handle new Ant design error messages. It expects throw to
   * show error toast properly.
   * @param suffixURL
   * @param method
   * @param fetchPayload
   */
  async fetchWithThrow<Response>(
    suffixURL: string,
    method: HttpMethodString = HttpMethod.Get,
    fetchPayload: FetchPayload = {}
  ) {
    const response = await this.fetch(suffixURL, method, fetchPayload);
    if (response.errorResponse) {
      throw response.errorResponse;
    }

    return response.response as Response;
  }

  async fetchWithReponseHeaders<Response>(
    suffixURL: string,
    method: HttpMethodString = HttpMethod.Get,
    fetchPayload: FetchPayload = {}
  ) {
    const response = await this.fetch(suffixURL, method, fetchPayload);
    if (response.errorResponse) {
      throw response.errorResponse;
    }

    return {
      response: response.response as Response,
      headers: response.responseHeaders
    };
  }

  async fetchPaginatedAlfresco<ApiItem>(
    suffixURL: string,
    paginationConfig: PaginationConfig,

    { urlWildCards, contentType, params, group }: FetchPaginatedPayload = {}
  ) {
    return this.fetchWithThrow<ApiAlfrescoListResponse<ApiItem>>(
      suffixURL,
      HttpMethod.Get,
      {
        urlWildCards,
        contentType,
        params: {
          ...params,

          maxItems: `${paginationConfig.itemsPerPage}`,
          skipCount: `${
            (paginationConfig.page - 1) * paginationConfig.itemsPerPage
          }`
        },
        group
      }
    );
  }
  async fetchPaginatedSortedAlfresco<ApiItem>(
    suffixURL: string,
    paginationConfig: PaginationConfig,
    sorting?: SortingConfig,
    { urlWildCards, contentType, params, group }: FetchPaginatedPayload = {}
  ) {
    return this.fetchWithThrow<ApiAlfrescoListResponse<ApiItem>>(
      suffixURL,
      HttpMethod.Get,
      {
        urlWildCards,
        contentType,
        params: {
          ...params,
          sorting,
          maxItems: `${paginationConfig.itemsPerPage}`,
          skipCount: `${
            (paginationConfig.page - 1) * paginationConfig.itemsPerPage
          }`
        },
        group
      }
    );
  }
  async fetchPaginated<ApiItem>({
    url,
    pagination,
    sorting,
    config
  }: FetchPaginatedConfig) {
    const query = {
      ...config?.params,
      ...(pagination && this.mapPaginationToQuery(pagination)),
      ...(sorting && this.mapSortingToQuery(sorting))
    };
    return this.fetchWithThrow<ApiListResponse<ApiItem>>(url, HttpMethod.Get, {
      ...config,
      params: query
    });
  }

  protected mapPaginationToQuery(
    pagination: PaginationConfig
  ): ApiPaginationQuery | ApiPaginationQueryV2 {
    return {
      currentPage: pagination.page.toString(),
      pageSize: pagination.itemsPerPage.toString()
    };
  }

  protected mapSortingToQuery(
    sorting: SortingConfig
  ): ApiSortQuery | ApiSortQueryV2 {
    const sorts = sorting.map(({ property, direction }) => ({
      property: property.toString(),
      direction: (direction === "ASC" ? "Asc" : "Desc") as ApiSortDirection
    }));

    return {
      sorts
    };
  }

  error(
    obj: {
      status: number;
      message?: string | null;
      errorResponseData?: object | null;
    } = {
      errorResponseData: null,
      message: null,
      // eslint-disable-next-line no-restricted-globals
      status: 500
    }
  ) {
    const { status, message, errorResponseData } = obj;

    return {
      errorResponse: {
        code: String(status),
        message
      } as ErrorType,
      errorResponseData,
      status,
      success: false
    };
  }

  setAuthToken(token: HttpClientConfig["authToken"]): HttpClient {
    this.config.authToken = token;

    return this;
  }

  public createRequest({
    contentType = "application/json",
    bodyFormData,
    bodyJSON,
    method,
    group
  }: CreateRequestPayload): RequestInit {
    const methodUppercase = method.toString().toUpperCase();
    const isMethodWithBody =
      methodUppercase === HttpMethod.Post ||
      methodUppercase === HttpMethod.Delete;
    const authToken =
      this.config.authToken ||
      this.appStore.store.getState().loginReducer?.session?.token;

    const headers: HeadersInit = {
      Accept: "application/json",
      ...(isMethodWithBody && bodyJSON && { "Content-Type": `${contentType}` }),
      ...(authToken && {
        Authorization: `${authToken}`
      }),
      Group:
        group ||
        this.appStore.store.getState().loginReducer?.session?.activeGroup
    } as HeadersInit;

    let body = null;

    if (isMethodWithBody && bodyJSON) {
      body = JSON.stringify(bodyJSON);
    } else if (isMethodWithBody && bodyFormData) {
      body = bodyFormData;
    }

    return {
      body,
      headers,
      method: methodUppercase
    };
  }

  private async parseResponse(response: Response) {
    const contentType = response.headers.get("content-type");

    if (contentType?.indexOf("text") !== -1) {
      return response.text();
    }

    if (contentType?.indexOf("application/json") !== -1) {
      return response.json().catch(() => {});
    }

    return response.blob();
  }
}
