import BlockIcon from "@mui/icons-material/Block";
import { RetentionProtocolProposalContent } from "domain/struct/recordRetention/RetentionProtocolProposalContent";
import { CopyFilled, CopyOutlined } from "presentation/designSystem/Icon/Icon";
import { useModal } from "presentation/designSystem/Modal/useModal";
import {
  GetRemoteDataClb,
  RemoteTable
} from "presentation/designSystem/Table/RemoteTable";
import { ColumnType } from "presentation/designSystem/Table/Table";
import { createColumn } from "presentation/designSystem/Table/column/column";
import { RootStateType } from "presentation/reducers";
import { actionShowDetail } from "presentation/share/components/table/actionFactory";
import { FileMarkColumnValue } from "presentation/share/components/table/column/document/FilePlan/FileMarkColumnValue";
import { FilePlanColumnValue } from "presentation/share/components/table/column/document/FilePlan/FilePlanColumnValue";
import { translationPath } from "presentation/share/utils/getPath";
import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { getDetailActionForDocumentNewDataModel } from "../../../../share/utils/action/dialog/documentDetail";
import {
  UserRepositoryRights,
  getUserRepositoryRights
} from "../../../../share/utils/user";
import { lang, t } from "../../../../translation/i18n";
import { useFindRetentionProtocolOrProposalContent } from "../../hooks/useFindRetentionProtocolOrProposalContent";
import { RetentionProposalRemoveObjectModal } from "../../modal/RetentionProposalRemoveObjectModal/RetentionProposalRemoveObjectModal";

const NO = "Ne";
const YES = "Ano";
const DIALOG_TYPE_PROPOSAL = "proposal";
const DIALOG_TYPE_PROTOCOL = "protocol";
const decisionDaNamesMapper = {
  neurčeno: "neurčeno",
  zničit: "zničit",
  vybratzaarchiválii: "vybrat za archiválii",
  předložitkvýběru: "předložit k výběru",
  vyřaditzvýběru: "vyřadit z výběru"
};

type OnCancelClb = () => void;
interface RetentionProposalOrProtocolContentTableInterface {
  id: string;
  dialogType: string;
  onRetentionProposalRemove?: OnCancelClb;
}

export const RetentionProposalOrProtocolContentTable = ({
  id,
  dialogType,
  onRetentionProposalRemove
}: RetentionProposalOrProtocolContentTableInterface) => {
  const getColumns = useMemo(() => {
    const COLUMNS = [
      createColumn({
        dataIndex: "pid",
        title: t(
          translationPath(
            lang.table.retentionProposalOrProtocolContentTable.pid
          )
        )
      }),
      createColumn({
        dataIndex: "ssidFileIdent",
        title: t(
          translationPath(
            lang.table.retentionProposalOrProtocolContentTable.ssidFileIdent
          )
        )
      }),
      createColumn({
        dataIndex: "subject",
        title: t(
          translationPath(
            lang.table.retentionProposalOrProtocolContentTable.subject
          )
        )
      }),
      createColumn<RetentionProtocolProposalContent>({
        dataIndex: "filePlan",
        title: t(
          translationPath(
            lang.table.retentionProposalOrProtocolContentTable.filePlan
          )
        ),
        defaultSortOrder: "ascend",
        render: (text, row) => {
          const { filePlan } = row;

          if (!filePlan) {
            return;
          }

          return <FilePlanColumnValue filePlanId={filePlan} />;
        }
      }),
      createColumn<RetentionProtocolProposalContent>({
        dataIndex: "fileMark",
        title: t(
          translationPath(
            lang.table.retentionProposalOrProtocolContentTable.fileMark
          )
        ),
        render: (text, row) => {
          const { fileMark } = row;

          if (!fileMark) {
            return;
          }

          return <FileMarkColumnValue fileMarkId={fileMark} />;
        }
      }),
      createColumn({
        dataIndex: "retentionMode",
        title: t(
          translationPath(
            lang.table.retentionProposalOrProtocolContentTable.retentionMode
          )
        )
      }),

      dialogType === "proposal" &&
        createColumn({
          dataIndex: "sipForSubmit",
          title: t(
            translationPath(
              lang.table.retentionProposalOrProtocolContentTable.sipForSubmit
            )
          ),
          render: (text, row) => {
            const { sipForSubmit } = row as RetentionProtocolProposalContent;

            if (!sipForSubmit) {
              return NO;
            }
            return YES;
          }
        }),
      dialogType === DIALOG_TYPE_PROTOCOL &&
        createColumn({
          dataIndex: "sipForArchive",
          title: t(
            translationPath(
              lang.table.retentionProposalOrProtocolContentTable.sipForArchive
            )
          ),
          render: (text, row) => {
            const { sipForArchive } = row as RetentionProtocolProposalContent;

            if (!sipForArchive) {
              return NO;
            }
            return YES;
          }
        }),
      createColumn({
        dataIndex: "decisionDa",
        title: t(
          translationPath(
            lang.table.retentionProposalOrProtocolContentTable.decisionDa
          )
        ),
        render: (text, row) => {
          const { decisionDa } = row as RetentionProtocolProposalContent;

          if (!decisionDa) {
            return null;
          }

          return decisionDaNamesMapper[
            decisionDa as keyof typeof decisionDaNamesMapper
          ];
        }
      }),
      dialogType === DIALOG_TYPE_PROTOCOL &&
        createColumn({
          dataIndex: "identifierDa",
          title: t(
            translationPath(
              lang.table.retentionProposalOrProtocolContentTable.identifierDa
            )
          )
        })
    ];
    return COLUMNS.filter(
      Boolean
    ) as ColumnType<RetentionProtocolProposalContent>[];
  }, [dialogType]);
  const findProtocolsOrProposals =
    useFindRetentionProtocolOrProposalContent(id);

  const dispatch = useDispatch();
  const session = useSelector(
    (state: RootStateType) => state.loginReducer.session
  );
  const [removeRetentionProposalObjModal, removeRetentionProposalObjModalApi] =
    useModal(RetentionProposalRemoveObjectModal);

  const ACTIONS = useMemo(
    () => ({
      single: [
        actionShowDetail({
          action(selected: RetentionProtocolProposalContent[]) {
            const nodeType = selected[0].nodeType || "";
            const action = getDetailActionForDocumentNewDataModel(nodeType);
            action &&
              dispatch(
                action({
                  canUploadComponents: false,
                  data: {
                    id: selected[0].nodeId,
                    nodeType
                  } as any,
                  hideManageShipmentsIcon: true,
                  initiator: nodeType,
                  isReadonly: true
                })
              );
          }
        }),
        {
          key: "generate-sip-without-components",
          icon: <CopyOutlined rev={"default"} />,
          tooltip: t(
            translationPath(
              lang.table.retentionProposalOrProtocolContentTable
                .generateSipWithoutComponents
            )
          ),
          action(selected: RetentionProtocolProposalContent[]) {},
          canBeDisplayed() {
            return dialogType === DIALOG_TYPE_PROPOSAL;
          }
        },
        {
          key: "generate-full-sip",
          icon: <CopyFilled rev={"default"} />,
          tooltip: t(
            translationPath(
              lang.table.retentionProposalOrProtocolContentTable.generateFullSip
            )
          ),
          action(selected: RetentionProtocolProposalContent[]) {},
          canBeDisplayed() {
            return dialogType === DIALOG_TYPE_PROPOSAL;
          }
        },
        {
          key: "generate-sip-for-archiving",
          icon: <CopyFilled rev={"default"} />,
          tooltip: t(
            translationPath(
              lang.table.retentionProposalOrProtocolContentTable
                .generateSipForArchiving
            )
          ),
          action(selected: RetentionProtocolProposalContent[]) {},
          canBeDisplayed() {
            return dialogType === DIALOG_TYPE_PROTOCOL;
          }
        },
        {
          key: "cancel-shredding-proposal",
          icon: <BlockIcon />,
          tooltip: t(
            translationPath(
              lang.table.retentionProposalOrProtocolContentTable
                .cancelShreddingProposal
            )
          ),
          action(selected: RetentionProtocolProposalContent[]) {
            const objectIds = selected.map((removeObj) => removeObj.nodeId);

            return removeRetentionProposalObjModalApi.open({
              nodeId: id!,
              objectIds: objectIds,
              onRetentionProposalRemove
            });
          },
          canBeDisplayed: () => {
            const rights = getUserRepositoryRights(
              session.activeGroup!,
              session.myGroups
            );
            return (
              dialogType === DIALOG_TYPE_PROPOSAL &&
              rights === UserRepositoryRights.Full
            );
          }
        }
      ],
      multi: [
        {
          key: "generate-sip-without-components",
          icon: <CopyOutlined rev={"default"} />,
          tooltip: t(
            translationPath(
              lang.table.retentionProposalOrProtocolContentTable
                .generateSipWithoutComponents
            )
          ),
          action(selected: RetentionProtocolProposalContent[]) {},
          canBeDisplayed() {
            return dialogType === DIALOG_TYPE_PROPOSAL;
          }
        },
        {
          key: "generate-full-sip",
          icon: <CopyFilled rev={"default"} />,
          tooltip: t(
            translationPath(
              lang.table.retentionProposalOrProtocolContentTable.generateFullSip
            )
          ),
          action(selected: RetentionProtocolProposalContent[]) {},
          canBeDisplayed() {
            return dialogType === DIALOG_TYPE_PROPOSAL;
          }
        },
        {
          key: "generate-sip-for-archiving",
          icon: <CopyFilled rev={"default"} />,
          tooltip: t(
            translationPath(
              lang.table.retentionProposalOrProtocolContentTable
                .generateSipForArchiving
            )
          ),
          action(selected: RetentionProtocolProposalContent[]) {},
          canBeDisplayed() {
            return dialogType === DIALOG_TYPE_PROTOCOL;
          }
        },
        {
          key: "cancel-shredding-proposal",
          icon: <BlockIcon />,
          tooltip: t(
            translationPath(
              lang.table.retentionProposalOrProtocolContentTable
                .cancelShreddingProposal
            )
          ),
          action(selected: RetentionProtocolProposalContent[]) {
            const objectIds = selected.map((removeObj) => removeObj.nodeId);

            return removeRetentionProposalObjModalApi.open({
              nodeId: id!,
              objectIds: objectIds,
              onRetentionProposalRemove: () => {
                return onRetentionProposalRemove?.();
              }
            });
          },
          canBeDisplayed() {
            const rights = getUserRepositoryRights(
              session.activeGroup!,
              session.myGroups
            );
            return (
              dialogType === DIALOG_TYPE_PROPOSAL &&
              rights === UserRepositoryRights.Full
            );
          }
        }
      ]
    }),
    [
      dispatch,
      dialogType,
      removeRetentionProposalObjModalApi,
      id,
      onRetentionProposalRemove,
      session.activeGroup,
      session.myGroups
    ]
  );

  const getRemoteData: GetRemoteDataClb<RetentionProtocolProposalContent> =
    useCallback(
      ({ pagination }) => findProtocolsOrProposals(pagination),
      [findProtocolsOrProposals]
    );

  return (
    <>
      {removeRetentionProposalObjModal}
      <RemoteTableStyled
        name={`repository/retentionProposalOrProtocolContentTable`}
        rowKey="pid"
        actions={ACTIONS}
        columns={getColumns}
        getRemoteData={getRemoteData}
      />
    </>
  );
};

const RemoteTableStyled = styled(RemoteTable)`
  overflow-x: scroll;
`;
