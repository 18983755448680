import React from "react";
import { Input, Select } from "antd";
import { Dayjs } from "dayjs";
import { DateTimeFormat } from "lib/dateTime";
import { camelCase } from "lodash";
import moment from "moment";
import { DatePicker } from "presentation/designSystem/DatePicker/DatePicker";
import {
  Form,
  FormInstance,
  Item
} from "presentation/designSystem/Form/v2/Form";
import { Col, Row } from "presentation/designSystem/Grid/Grid";
import { InputNumber } from "presentation/designSystem/Input/Input";
import { DeliveryMode, DocumentType } from "presentation/enums";
import { SenderFormNewProps } from "presentation/share/components/form/SenderFormAnt";
import { useSenderForm } from "presentation/share/components/form/hooks/useSenderForm";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import { AnalogDocument, UpdateAnalogFormFieldTypes } from "../types/types";

interface AnalogCreateFormProps {
  formData: AnalogDocument;
  form: FormInstance<UpdateAnalogFormFieldTypes>;
  onSenderChange: SenderFormNewProps["onSenderChange"];
}

export const AnalogDetailAntForm = ({
  formData,
  form,
  onSenderChange
}: AnalogCreateFormProps) => {
  const deliveryDate = moment(formData.deliveryDate!);
  const { senderFormData, senderForm } = useSenderForm({
    senderData: formData.sender,
    form,
    onSenderChange
  });
  const formInitialValues: UpdateAnalogFormFieldTypes = {
    ...formData,
    deliveryDate: moment(deliveryDate),
    settleToDate: formData.settleToDate
      ? moment(formData.settleToDate)
      : undefined,
    deliveryMode: formData.deliveryMode ?? "",
    ...senderFormData
  };

  const allowedDeliveryModes = Object.entries(DeliveryMode).filter(
    ([key, value]) =>
      [
        DeliveryMode.Currier,
        DeliveryMode.Personally,
        DeliveryMode.Post
      ].includes(value)
  );

  const disabledDate = (current: moment.Moment) => {
    return current.isAfter(moment(), "day");
  };

  const disabledTime = (current: moment.Moment) => {
    const { deliveryDate } = form.getFieldsValue();
    return deliveryDate?.isSame(moment(), "day") && current.isAfter(moment());
  };

  const onDateChange = (value: moment.Moment | null) => {
    if (value?.isSame(moment(), "day")) {
      form.setFieldsValue({
        deliveryDate: moment()
      });
    }
  };

  return (
    <>
      <Form initialValues={formInitialValues} form={form}>
        <Row gutter={6}>
          <Col span={6}>
            <Item
              required={true}
              name="deliveryDate"
              rules={[{ required: true }]}
              label={t(translationPath(lang.general.deliveryDate))}
            >
              <DatePicker
                format={DateTimeFormat.Date}
                style={{ width: "100%" }}
                disabledDate={(current: Dayjs) =>
                  disabledDate(moment(current as moment.Moment))
                }
                onChange={onDateChange}
              />
            </Item>
          </Col>
          <Col span={6}>
            <Item
              name="deliveryDate"
              label={t(translationPath(lang.general.deliveryTime))}
            >
              <DatePicker
                picker="time"
                format={DateTimeFormat.HoursMinutesSeconds}
                disabledDate={(current: Dayjs) =>
                  disabledTime(moment(current as moment.Moment))
                }
                style={{ width: "100%" }}
              />
            </Item>
          </Col>
          <Col span={6}>
            <Item
              rules={[
                {
                  required: true,
                  message: t(translationPath(lang._validations.required))
                }
              ]}
              name="deliveryMode"
              label={t(translationPath(lang.general.deliveryMode))}
            >
              <Select placeholder="Choose">
                {allowedDeliveryModes.map(([key, value]) => {
                  return (
                    <Select.Option key={key} value={value}>
                      {t(
                        translationPath(
                          lang.enums.deliveryMode[
                            camelCase(
                              key
                            ) as keyof typeof lang.enums.deliveryMode
                          ]
                        )
                      )}
                    </Select.Option>
                  );
                })}
              </Select>
            </Item>
          </Col>
          <Col span={6}>
            <Item
              name="pid"
              label={t(translationPath(lang.documentMetaForm.pid))}
            >
              <Input disabled />
            </Item>
          </Col>
        </Row>
        <Row gutter={6}>
          <Col span={6}>
            <Item
              name="form"
              label={t(translationPath(lang.documentMetaForm.form))}
            >
              <Select disabled>
                <Select.Option value={DocumentType.Analog}>
                  {t(translationPath(lang.general.analog))}
                </Select.Option>
              </Select>
            </Item>
          </Col>
          <Col span={6}>
            <Item
              name="attachmentsCount"
              label={t(translationPath(lang.general.attachmentsCount))}
            >
              <InputNumber
                type="number"
                min={0}
                max={99999}
                style={{ width: "100%" }}
              />
            </Item>
          </Col>
          <Col span={6}>
            <Item
              name="attachmentsType"
              label={t(translationPath(lang.general.attachmentsType))}
            >
              <Input maxLength={50} />
            </Item>
          </Col>
          <Col span={6}>
            <Item
              name="listCount"
              label={t(translationPath(lang.general.listCount))}
            >
              <InputNumber
                type="number"
                min={0}
                max={99999}
                style={{ width: "100%" }}
              />
            </Item>
          </Col>
        </Row>
        <Row gutter={6}>
          <Col span={6}>
            <Item
              name="listCountAttachments"
              label={t(translationPath(lang.general.listCountAttachments))}
            >
              <InputNumber
                type="number"
                min={0}
                max={99999}
                style={{ width: "100%" }}
              />
            </Item>
          </Col>
          <Col span={6}>
            <Item
              name="settleToDate"
              label={t(translationPath(lang.general.settleToDate))}
            >
              <DatePicker
                format={DateTimeFormat.Date}
                style={{ width: "100%" }}
                disabledDate={(current) => {
                  return current < moment().add(-1, "days");
                }}
              />
            </Item>
          </Col>
          <Col span={6}>
            <Item
              name="senderIdent"
              label={t(translationPath(lang.general.senderIdent))}
            >
              <Input maxLength={50} />
            </Item>
          </Col>
          <Col span={6}>
            <Item
              name="senderSsid"
              label={t(translationPath(lang.general.senderSSID))}
            >
              <Input maxLength={50} />
            </Item>
          </Col>
        </Row>
        <Row gutter={6}>
          <Col span={24}>
            <Item
              name="subject"
              label={t(translationPath(lang.general.subject))}
            >
              <Input maxLength={255} />
            </Item>
          </Col>
        </Row>

        {senderForm}
      </Form>
    </>
  );
};
