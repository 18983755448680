import { HttpClient } from "infrastructure/service/HttpClient";
import { DInjectable } from "presentation/core/features/dependencyInjection";
import {
  PaginatedResult,
  PaginationConfig
} from "../../../lib/contract/Pagination";
import { ApiUrls } from "../struct/ApiTypes";
import {
  SearchedSubjectLegalEntityRepository,
  SearchedSubjectPersonRepository
} from "../../../domain/service/nameRegistry/SearchedSubjectRepository";
import {
  ApiSearchedSubject,
  SearchedSubjectPersonApiMapper
} from "./SearchedSubjectPersonApiMapper";
import { SearchedSubjectLegalEntityApiMapper } from "./SearchedSubjectLegalEntityApiMapper";
import { Person } from "../../../domain/struct/nameRegistry/Person";
import { LegalEntity } from "../../../domain/struct/nameRegistry/LegalEntity";

const URL: ApiUrls = {
  GetSearchedSubject: `/api/app/v1/name-register/subjects/search`,
  CreatePerson: "/api/app/v1/name-register/subject/individual-subject/create"
};

@DInjectable()
export class SearchedSubjectPersonApiRepository
  implements SearchedSubjectPersonRepository {
  constructor(protected httpClient: HttpClient) {}

  findAllPaginated(
    config: PaginationConfig,
    params?: Record<string, string>
  ): Promise<PaginatedResult<Person>> {
    if (!params?.keyword) {
      delete params?.keyword;
    }
    return this.httpClient
      .fetchPaginatedAlfresco<ApiSearchedSubject>(
        URL.GetSearchedSubject,
        config,
        {
          params
        }
      )
      .then(SearchedSubjectPersonApiMapper.mapFindAllResponse);
  }
}

@DInjectable()
export class SearchedSubjectLegalEntityApiRepository
  implements SearchedSubjectLegalEntityRepository {
  constructor(protected httpClient: HttpClient) {}

  findAllPaginated(
    config: PaginationConfig,
    params?: Record<string, string>
  ): Promise<PaginatedResult<LegalEntity>> {
    if (!params?.keyword) {
      delete params?.keyword;
    }
    return this.httpClient
      .fetchPaginatedAlfresco<ApiSearchedSubject>(
        URL.GetSearchedSubject,
        config,
        {
          params
        }
      )
      .then(SearchedSubjectLegalEntityApiMapper.mapFindAllResponse);
  }
}
