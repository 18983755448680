import { HttpClient, HttpMethod } from "infrastructure/service/HttpClient";
import { PaginatedResult, PaginationConfig } from "lib/contract/Pagination";
import {
  mapDocumentForSignatureListToDomain,
  mapListToDomain,
  transformParams
} from "./mappers";
import { SortingConfig } from "lib/contract/Sorting";
import { DocumentForSignature, ForSignature } from "../types/ForSignature";
import { ApiUrls } from "infrastructure/api/struct/ApiTypesV2";
import { ApiUrl } from "../../../../../../infrastructure/api/struct/ApiTypes";

const URL: ApiUrls = {
  GetComponentForSignature: "/api/app/v2/signature-book/for-signature",
  GetSignatureBookForSignature:
    "/api/app/v2/signature-book/document/{nodeId}/components",
  CreateSignForSignature: "/api/app/v2/signature-book/sign/create",
  GetSignStatusForSignature: "/api/app/v2/signature-book/sign/status"
};

const DocumentSignaturePost: ApiUrl =
  "/api/app/v1/document/{nodeId}/from-signature";

export interface SignerResponseComponent {
  id: string;
  component: string;
  status: string;
}

export interface SignerCreateResponse {
  batchId?: string;
  signer: string;
  visual: boolean;
  components: string[];
}

export const getSignatureBookForSignature = (
  pagination: PaginationConfig,
  sorting?: SortingConfig
): Promise<PaginatedResult<ForSignature>> => {
  const httpClient = new HttpClient();
  return httpClient
    .fetchPaginated<ForSignature>({
      url: URL.GetComponentForSignature,
      pagination,
      sorting
    })
    .then(mapListToDomain);
};

export const getDocumentForSignature = (
  nodeId: string,
  pagination: PaginationConfig,
  sorting?: SortingConfig
): Promise<PaginatedResult<DocumentForSignature>> => {
  const httpClient = new HttpClient();
  return httpClient
    .fetchPaginated<DocumentForSignature>({
      url: URL.GetSignatureBookForSignature,
      pagination,
      sorting,
      config: {
        urlWildCards: {
          nodeId: nodeId
        }
      }
    })
    .then(mapDocumentForSignatureListToDomain);
};

export const postDocumentsForSignature = (nodeId: string): Promise<void> => {
  const httpClient = new HttpClient();
  return httpClient.fetchWithThrow(DocumentSignaturePost, HttpMethod.Post, {
    urlWildCards: {
      nodeId
    }
  });
};

export interface DocumentForSealForm {
  readonly group?: string;
  readonly user?: string;
}

export const postComponentsForSignature = (
  nodeId: string,
  componentId: string
): Promise<void> => {
  const httpClient = new HttpClient();
  return httpClient.fetchWithThrow(URL.PostSignComponent, HttpMethod.Post, {
    bodyJSON: {
      nodeId,
      componentId
    }
  });
};

export const getSignerData = (
  documentId: string,
  files: DocumentForSignature[],
  visual: boolean
): Promise<SignerCreateResponse | null> => {
  const httpClient = new HttpClient();
  const params = transformParams(files.map((file) => file.componentId || ""));

  return httpClient
    .fetch(
      `${
        URL.CreateSignForSignature
      }?documentId=${documentId}${params}&visual=${String(visual)}`,
      "GET"
    )
    .then((response) =>
      response.success ? (response.response as SignerCreateResponse) : null
    )
    .catch(() => null);
};

export const getComponentsSignStatus = (
  componentIds: string
): Promise<SignerResponseComponent[]> => {
  const httpClient = new HttpClient();

  return httpClient.fetchWithThrow<SignerResponseComponent[]>(
    URL.GetSignStatusForSignature,
    "GET",
    {
      params: {
        componentIds
      }
    }
  );
};
