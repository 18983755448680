import { callAsyncAction } from "presentation/core/action";
import { documentChangeLocationActionType } from "presentation/core/api/document/_actions";
import { fileChangeLocationAction } from "presentation/core/api/file/_actions";
import { secondaryAction } from "presentation/core/components/dialog/lib/actionsFactory";
import {
  DialogContentType,
  DialogType
} from "presentation/core/components/dialog/_types";
import { documentViewAction__Refresh } from "presentation/core/components/documentView/_actions";
import NamedTitle from "presentation/core/components/namedTitle";
import { GenericDocument } from "presentation/core/types";
import { SpisumNodeTypes } from "presentation/enums";
import React from "react";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import { ChangeLocationDialog } from "./ChangeLocationDialog";
import { ChangeLocationValues } from "./_types";
import { ModalSize } from "presentation/designSystem/Modal/Modal";
import { Notification } from "presentation/designSystem/notification/Notification";
import { getErrorCodeTranslation } from "../../../utils/errorCodeTranslation";

export const changeLocationDialogContent: DialogContentType = {
  actions: () => [
    secondaryAction(
      t(translationPath(lang.dialog.buttons.confirm)),
      ({ dispatch, dialogProps, onClose, buttonState, channels }) => {
        const onSuccess = () => {
          onClose();
          dispatch(documentViewAction__Refresh(true));
          dialogProps.onSuccess?.();
        };

        const onError = (error: any) => {
          buttonState.setIsPending(false);
          if (error?.messages) {
            error.messages.map((error: string) =>
              Notification.error({
                message: getErrorCodeTranslation(error)
              })
            );
          }
        };

        if (!dialogProps.data) {
          return;
        }
        const { nodeType, id } = dialogProps.data as GenericDocument;

        const action =
          nodeType === SpisumNodeTypes.Document
            ? documentChangeLocationActionType
            : nodeType === SpisumNodeTypes.File
            ? fileChangeLocationAction
            : undefined;

        if (!action) {
          console.log("Node type should not be undefined");
          return;
        }

        buttonState.setIsPending(true);

        dispatch(
          callAsyncAction({
            action,
            onError,
            onSuccess,
            payload: {
              body: {
                location: (channels.contentTab?.state
                  ?.formValues as ChangeLocationValues).location
              },
              nodeId: id
            }
          })
        );
      }
    )
  ],
  content: ChangeLocationDialog,
  title: (props) => (
    <NamedTitle
      text={t(translationPath(lang.general.editStorageLocation))}
      {...props}
    />
  ),
  type: DialogType.ChangeLocation,
  size: ModalSize.Small
};
