import { getService } from "presentation/core/features/dependencyInjection";
import { PaginationConfig } from "lib/contract/Pagination";
import { useCallback } from "react";
import { FindSearched } from "../../../useCase/search/FindSearched";
import { SortingConfig } from "../../../lib/contract/Sorting";

export const useFindSearch = () => {
  const findSearched = getService(FindSearched);

  return useCallback(
    (searchedTerm: string, config: PaginationConfig, sorting?: SortingConfig) =>
      findSearched.findAllPaginated(searchedTerm, config, sorting),
    [findSearched]
  );
};
