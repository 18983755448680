import moment from "moment";
import {
  lastPathMember,
  translationPath
} from "presentation/share/utils/getPath";
import { validateErrors } from "presentation/share/utils/validation";
import { lang, t } from "presentation/translation/i18n";
import * as yup from "yup";
import { ChangeValidToDateFormValues, changeValidToDateProxy } from "./_types";

export const validate = (values: ChangeValidToDateFormValues) => {
  return validateErrors(validationSchema(values), values);
};

const checkIfLastItemInPlan = (values: ChangeValidToDateFormValues) => {
  return !values.validTo && !values.nextItem;
};

export const validationSchema = (values: ChangeValidToDateFormValues) => {
  return yup.object().shape({
    [lastPathMember(changeValidToDateProxy.validTo).path]: yup
      .string()
      .nullable()
      .test(
        lastPathMember(changeValidToDateProxy.validTo).path,
        t(
          translationPath(
            lang._validations.validToDateIsSameOrBeforeValidFromDate
          )
        ),
        (dateSet) => {
          if (checkIfLastItemInPlan(values)) {
            return true;
          }
          return moment(dateSet).isSameOrAfter(moment(values.validFrom), "day");
        }
      )
      .test(
        lastPathMember(changeValidToDateProxy.validTo).path,
        t(
          translationPath(
            lang._validations.validToDateIsOlderThenValidToDateOfNextPlan
          )
        ),
        (dateSet) => {
          if (checkIfLastItemInPlan(values)) {
            return true;
          }

          if (!values.nextDateToDisable) {
            return true;
          }

          return moment(dateSet).isSameOrBefore(
            moment(values?.nextDateToDisable).subtract(2, "days"),
            "day"
          );
        }
      )
      .test(
        lastPathMember(changeValidToDateProxy.validTo).path,
        t(translationPath(lang._validations.datesAreTheSame)),
        (dateSet) => {
          if (checkIfLastItemInPlan(values)) {
            return true;
          }
          return !moment(dateSet).isSame(moment(values.validFrom));
        }
      )
      .test(
        lastPathMember(changeValidToDateProxy.validTo).path,
        t(translationPath(lang._validations.dateCannotBeOlderThanToday)),
        (dateSet) => {
          if (checkIfLastItemInPlan(values)) {
            return true;
          }

          return !moment(dateSet).isBefore(moment(new Date(), "days"), "day");
        }
      )
      .test(
        lastPathMember(changeValidToDateProxy.validTo).path,
        t(translationPath(lang._validations.dateIsNotValid)),
        (dateSet) => {
          if (checkIfLastItemInPlan(values)) {
            return true;
          }
          return moment(dateSet).isValid();
        }
      )
      .test(
        lastPathMember(changeValidToDateProxy.validTo).path,
        t(
          translationPath(
            lang._validations.validToCannotBeEmptyOnNotLastFilePlan
          )
        ),
        (dateSet) => {
          if (checkIfLastItemInPlan(values)) {
            return true;
          }

          return !!dateSet;
        }
      ),
    [lastPathMember(changeValidToDateProxy.validFrom).path]: yup
      .string()
      .nullable()
      .test(
        lastPathMember(changeValidToDateProxy.validFrom).path,
        t(
          translationPath(
            lang._validations.validFromDateIsSameOrAfterValidToDate
          )
        ),
        (dateSet) => {
          if (checkIfLastItemInPlan(values)) {
            return true;
          }

          return (
            !!values.validTo &&
            moment(dateSet).isSameOrBefore(moment(values.validTo), "day")
          );
        }
      )
      .test(
        lastPathMember(changeValidToDateProxy.validTo).path,
        t(
          translationPath(
            lang._validations
              .validFromDateIsOlderThenValidFromDateOfPreviousPlan
          )
        ),
        (dateSet) => {
          if (!values?.previousDateToDisable) {
            return true;
          }

          return moment(dateSet).isSameOrAfter(
            moment(values?.previousDateToDisable).add(2, "days"),
            "day"
          );
        }
      )
      .test(
        lastPathMember(changeValidToDateProxy.validTo).path,
        t(translationPath(lang._validations.datesAreTheSame)),
        (dateSet) => {
          if (checkIfLastItemInPlan(values)) {
            return true;
          }

          return !moment(dateSet).isSame(moment(values.validTo));
        }
      )
      .required(t(translationPath(lang._validations.required)))
      .test(
        lastPathMember(changeValidToDateProxy.validTo).path,
        t(translationPath(lang._validations.dateCannotBeOlderThanToday)),
        (dateSet) => {
          if (moment(dateSet).isSame(moment(values.validFrom))) {
            return true;
          }

          return !moment(dateSet).isBefore(moment(new Date(), "days"), "day");
        }
      )
      .test(
        lastPathMember(changeValidToDateProxy.validTo).path,
        t(translationPath(lang._validations.dateIsNotValid)),
        (dateSet) => {
          return moment(dateSet).isValid();
        }
      )
  });
};
