import React from "react";
import {
  Form,
  Item,
  FormInstance
} from "presentation/designSystem/Form/v2/Form";
import { Col, Row } from "presentation/designSystem/Grid/Grid";
import { Input } from "presentation/designSystem/Input/Input";
import { DatePicker } from "presentation/designSystem/DatePicker/DatePicker";
import { lang, t } from "presentation/translation/i18n";
import {
  lastPathMember,
  translationPath
} from "presentation/share/utils/getPath";
import { DateTimeFormat } from "lib/dateTime";
import moment from "moment";
import { SslEmail } from "presentation/core/api/models";
import { sslEmailPropsProxy } from "presentation/core/types";
import { TimePicker } from "antd";

interface EmailDetailsFormProps {
  formData: SslEmail;
  form: FormInstance;
}

export const EmailDetailsForm = ({ formData, form }: EmailDetailsFormProps) => {
  const deliveryDate = new Date(formData.digitalDeliveryDeliveryDate);
  const formInitialValues = {
    ...formData,
    digitalDeliveryDeliveryDate: moment(deliveryDate),
    emailDeliveryTime: moment(deliveryDate, DateTimeFormat.HoursMinutesSeconds)
  };

  return (
    <>
      <Form initialValues={formInitialValues} form={form}>
        <Row gutter={6}>
          <Col span={6}>
            <Item
              name={
                lastPathMember(sslEmailPropsProxy.digitalDeliveryDeliveryDate)
                  .path
              }
              label={t(translationPath(lang.documentMetaForm.deliveryDate))}
            >
              <DatePicker
                disabled
                format={DateTimeFormat.Date}
                style={{ width: "100%" }}
              />
            </Item>
          </Col>
          <Col span={6}>
            <Item
              name="emailDeliveryTime"
              label={t(translationPath(lang.general.deliveryTime))}
            >
              <TimePicker disabled style={{ width: "100%" }} />
            </Item>
          </Col>
          <Col span={6}>
            <Item
              name={
                lastPathMember(
                  sslEmailPropsProxy.digitalDeliveryAttachmentsCount
                ).path
              }
              label={t(translationPath(lang.general.attachmentsCount))}
            >
              <Input disabled type="number" />
            </Item>
          </Col>
          <Col span={6}>
            <Item
              name={lastPathMember(sslEmailPropsProxy.pid).path}
              label={t(translationPath(lang.documentMetaForm.pid))}
            >
              <Input disabled />
            </Item>
          </Col>
        </Row>
        <Row gutter={6}>
          <Col span={24}>
            <Item
              name={lastPathMember(sslEmailPropsProxy.emailSender).path}
              label={t(translationPath(lang.general.sender))}
            >
              <Input disabled />
            </Item>
          </Col>
        </Row>
        <Row gutter={6}>
          <Col span={24}>
            <Item
              name={
                lastPathMember(sslEmailPropsProxy.digitalDeliverySubject).path
              }
              label={t(translationPath(lang.general.subject))}
            >
              <Input disabled />
            </Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};
