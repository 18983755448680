import DataTable from "presentation/core/components/dataTable";
import {
  ControlsBarType,
  DataColumn
} from "presentation/core/components/dataTable/_types";
import { useStyles } from "presentation/core/components/dialog/Dialog.styles";
import { FilePlan } from "presentation/core/features/login/_types";
import React from "react";
import { lang, t } from "presentation/translation/i18n";
import { translationPath } from "../../../utils/getPath";

export type SortChangeFnType = (
  index: number,
  keys: string[]
) => (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void;

interface OwnProps {
  controls: ControlsBarType<FilePlan>;
  handleChangePage: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => void;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSortingChange: SortChangeFnType;
  items: FilePlan[];
  pageNumber: number;
  rowsPerPage: number;
  sortAsc?: boolean;
  sortColumnIndex?: number | null;
  totalItems: number;
  selected?: FilePlan[];
  handleSelectionChange?: (items: FilePlan[]) => void;
}

export const columns: DataColumn<FilePlan>[] = [
  {
    keys: ["name"],
    label: t(translationPath(lang.general.name))
  },
  {
    isDate: true,
    keys: ["validFrom"],
    label: t(translationPath(lang.general.validFrom)),
    sortKeys: ["validFrom"]
  },

  {
    isDate: true,
    keys: ["validTo"],
    label: t(translationPath(lang.general.validTo))
  }
];

const Components = ({
  controls,
  handleChangePage,
  handleChangeRowsPerPage,
  handleSortingChange,
  items,
  pageNumber,
  rowsPerPage,
  sortAsc,
  sortColumnIndex,
  totalItems,
  selected,
  handleSelectionChange
}: OwnProps) => {
  const dialogClasses = useStyles();

  return (
    <DataTable
      hideCheckbox
      controls={controls}
      breadcrumbs={[]}
      columns={columns}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleSortingChange={handleSortingChange}
      handleSelectionChange={handleSelectionChange}
      pageNumber={pageNumber}
      paginationClassName={
        items.length
          ? dialogClasses.shreddingTablePagination
          : dialogClasses.tablePagination
      }
      pending={false}
      rows={items}
      rowsCount={totalItems}
      rowsPerPage={rowsPerPage}
      selected={selected}
      sortAsc={sortAsc}
      sortColumnIndex={sortColumnIndex}
      tableLayoutClassName="dialog__table-layout"
    />
  );
};

export default React.memo(Components);
