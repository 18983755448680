import { Button } from "@mui/material";
import clsx from "clsx";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import { useStyles } from "../../dialog/Dialog.styles";
import {
  StyledErrorPic,
  useStyles as useBoundariesStyles
} from "../ErrorBoundary.styles";
import React from "react";

export const DocumentViewError = () => {
  const classes = useStyles();
  const errorBoundaryClasses = useBoundariesStyles();

  const reloadPage = () => {
    window.location.reload();
  };

  return (
    <div
      className={clsx(
        "body-fullsize",
        errorBoundaryClasses.centeredError,
        classes.modalContent
      )}
    >
      <StyledErrorPic />
      <div className={classes.mtGap}>
        {t(translationPath(lang.errorBoundary.defaultMessage))}
      </div>
      <div style={{ marginTop: 30 }}>
        <Button
          className={classes.mrGap}
          color="primary"
          data-test-id=""
          variant="outlined"
          onClick={reloadPage}
        >
          {t(translationPath(lang.errorBoundary.reload))}
        </Button>
      </div>
    </div>
  );
};
