import { callAsyncAction } from "presentation/core/action";
import { createOrganizationUnitAction } from "presentation/core/api/groups/_actions";
import { secondaryAction } from "presentation/core/components/dialog/lib/actionsFactory";
import {
  DialogContentType,
  DialogType
} from "presentation/core/components/dialog/_types";
import { documentViewAction__Refresh } from "presentation/core/components/documentView/_actions";
import NamedTitle from "presentation/core/components/namedTitle";
import { NotificationSeverity } from "presentation/core/components/notifications/_types";
import React from "react";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import { CreateOrganizationUnitDialog } from "./CreateOrganizationUnitDialog";
import { ModalSize } from "presentation/designSystem/Modal/Modal";

export const createOrganizationUnitDialogContent: DialogContentType = {
  actions: () => [
    secondaryAction(
      t(translationPath(lang.dialog.buttons.confirm)),
      ({ onClose, dispatch, channels, buttonState, dialogProps }) => {
        const formValues = channels?.contentTab?.state?.formValues;
        if (!formValues) return;
        buttonState.setIsPending(true);
        const onError = () => {
          buttonState.setIsPending(false);
        };
        const onSuccess = () => {
          onClose();
          dispatch(documentViewAction__Refresh(true));
        };

        dispatch(
          callAsyncAction({
            action: createOrganizationUnitAction,
            onError,
            onErrorNotification: {
              message: t(
                translationPath(
                  lang.dialog.notifications.createOrganizationUnitFailed
                )
              ),
              severity: NotificationSeverity.Error
            },
            onSuccess,
            onSuccessNotification: {
              message: t(
                translationPath(
                  lang.dialog.notifications.createOrganizationUnitSuccess
                )
              ),
              severity: NotificationSeverity.Success
            },
            payload: {
              ...formValues
            }
          })
        );
      }
    )
  ],
  content: CreateOrganizationUnitDialog,
  title: (props) => (
    <NamedTitle
      text={t(translationPath(lang.general.createOrganizationUnit))}
      {...props}
    />
  ),
  type: DialogType.CreateOrganizationUnit,
  size: ModalSize.Small
};
