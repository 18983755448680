import { useStyles } from "presentation/core/components/dialog/Dialog.styles";
import { DialogContentType } from "presentation/core/components/dialog/_types";
import React from "react";
import { ChangeValidToDateFormValues } from "./_types";
import { validate } from "./_validations";
import { ChangeValidityDateDialogForm } from "./ChangeValidityDateDialogForm";
import { FilePlansItem } from "../../../../core/components/documentView/_types";
import { ChannelForm } from "../ChannelForm/ChannelForm";

export const ChangeValidityDateDialog: DialogContentType["content"] = ({
  dialogProps,
  channel
}) => {
  const classes = useStyles();

  const filePlan = dialogProps?.data as FilePlansItem;
  const validFrom = dialogProps && filePlan?.properties?.ssl?.validFrom;
  const validTo = filePlan?.properties?.ssl?.validTo ?? null;
  const nextDateToDisable = dialogProps?.nextItem?.properties?.ssl?.validTo;
  const previousDateToDisable =
    dialogProps?.previousItem?.properties?.ssl?.validFrom;

  return (
    <div className={classes.modalSmall}>
      <ChannelForm<ChangeValidToDateFormValues>
        initialValues={{
          nextDateToDisable,
          nextItem: dialogProps?.nextItem,
          previousDateToDisable,
          validFrom,
          validTo
        }}
        validate={validate}
        channel={channel}
        component={ChangeValidityDateDialogForm}
      />
    </div>
  );
};
