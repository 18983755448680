import { ContactRepository } from "../../../domain/service/nameRegistry/ContactRepository";
import { Contact } from "../../../domain/struct/nameRegistry/Contact";
import {
  DInject,
  DInjectable
} from "../../../presentation/core/features/dependencyInjection";
import { HttpClient, HttpMethod } from "../../service/HttpClient";
import { ApiUrls } from "../struct/ApiTypes";
import { ApiContact, ContactApiMapper } from "./ContactApiMapper";
import {
  PaginatedResult,
  PaginationConfig
} from "../../../lib/contract/Pagination";

const URL: ApiUrls = {
  FindBySubjectId: "/api/app/v1/name-register/subject/{subjectId}/contacts",
  Create: "/api/app/v1/name-register/subject/{subjectId}/contact/create",
  Update:
    "/api/app/v1/name-register/subject/{subjectId}/contact/{contactId}/update",
  Delete: "/api/app/v1/name-register/subject/{subjectId}/contact/{contactId}"
};

@DInjectable()
export class ContactApiRepository implements ContactRepository {
  constructor(@DInject(HttpClient) protected httpClient: HttpClient) {}

  create(subjectId: string, contact: Contact): Promise<void> {
    return this.httpClient.fetchWithThrow(URL.Create, HttpMethod.Post, {
      bodyJSON: ContactApiMapper.mapToApi(contact),
      urlWildCards: {
        subjectId
      }
    });
  }

  delete(subjectId: string, contact: Contact): Promise<void> {
    return this.httpClient.fetchWithThrow(URL.Delete, HttpMethod.Delete, {
      bodyJSON: ContactApiMapper.mapToApi(contact),
      urlWildCards: {
        subjectId,
        contactId: Number(contact.id)
      }
    });
  }

  findBySubjectId(
    subjectId: string,
    pagination: PaginationConfig
  ): Promise<PaginatedResult<Contact>> {
    return this.httpClient
      .fetchPaginatedAlfresco<ApiContact>(URL.FindBySubjectId, pagination, {
        urlWildCards: {
          subjectId
        }
      })
      .then(ContactApiMapper.mapContactsToDomain);
  }

  update(subjectId: string, contact: Contact): Promise<void> {
    return this.httpClient.fetchWithThrow(URL.Update, HttpMethod.Post, {
      bodyJSON: ContactApiMapper.mapToApi(contact),
      urlWildCards: {
        subjectId,
        contactId: Number(contact.id)
      }
    });
  }
}
