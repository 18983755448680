import { RegisterDataboxDigitalFormFieldTypes } from "../forms/RegisterDataboxDigitalFormFieldTypes";
import { UpdateDocument } from "../hooks/useUpdateDocument";

export const mapDocumentToApi = (
  input: RegisterDataboxDigitalFormFieldTypes
): UpdateDocument => {
  return {
    attachmentsCount: input.attachmentsCount,
    deliveryDate: input.deliveryDate?.toISOString(),
    attachmentsType: input.attachmentsType,
    deliveryMode: input.deliveryMode,
    senderAddressId: input.senderAddressId,
    senderContactDataboxId: input.senderContactDataboxId,
    senderContactEmailId: input.senderContactEmailId,
    senderContactPhoneId: input.senderContactPhoneId,
    senderId: input.senderId ? Number(input.senderId) : undefined,
    senderIdent: input.senderIdent,
    senderSsid: input.senderSsid,
    settleToDate: input.settleToDate,
    subject: input.subject,
    senderCompanyEmployeeUnit: input.senderCompanyEmployeeUnit,
    senderCompanyEmployeeJob: input.senderCompanyEmployeeJob,
    senderCompanyEmployeeName: input.senderCompanyEmployeeName
  };
};
