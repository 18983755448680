import { HttpClient, HttpMethod } from "infrastructure/service/HttpClient";
import { PaginatedResult, PaginationConfig } from "lib/contract/Pagination";
import { Mappers } from "./mappers";
import { DocumentForSeal, ForSeal } from "../types/ForSeal";
import { SortingConfig } from "lib/contract/Sorting";
import { ApiUrls } from "infrastructure/api/struct/ApiTypesV2";

const URL: ApiUrls = {
  GetSignatureBookForSeal: "/api/app/v2/signature-book/for-seal",
  GetComponentForSeal:
    "/api/app/v2/signature-book/document/{nodeId}/components",
  DocumentForSeal: "/api/app/v2/document/{nodeId}/from-seal",
  ComponentForSeal:
    "/api/app/v2/signature-book/document/{nodeId}/component/{componentId}/seal"
};

export const getSignatureBookForSeal = (
  pagination: PaginationConfig,
  sorting?: SortingConfig
): Promise<PaginatedResult<ForSeal>> => {
  const httpClient = new HttpClient();
  return httpClient
    .fetchPaginated<ForSeal>({
      url: URL.GetSignatureBookForSeal,
      pagination,
      sorting
    })
    .then(Mappers.mapListToDomain);
};

export const getDocumentForSeal = (
  nodeId: string,
  pagination: PaginationConfig,
  sorting?: SortingConfig
): Promise<PaginatedResult<DocumentForSeal>> => {
  const httpClient = new HttpClient();
  return httpClient
    .fetchPaginated<DocumentForSeal>({
      url: URL.GetComponentForSeal,
      pagination,
      sorting,
      config: {
        urlWildCards: {
          nodeId: nodeId
        }
      }
    })
    .then(Mappers.mapDocumentForSealListToDomain);
};

export const postDocumentsForSeal = (nodeId: string): Promise<void> => {
  const httpClient = new HttpClient();
  return httpClient.fetchWithThrow(URL.DocumentForSeal, HttpMethod.Post, {
    urlWildCards: {
      nodeId
    }
  });
};

export const postComponentsForSeal = (
  nodeId: string,
  componentId: string
): Promise<void> => {
  const httpClient = new HttpClient();
  return httpClient.fetchWithThrow(URL.ComponentForSeal, HttpMethod.Post, {
    urlWildCards: {
      nodeId,
      componentId
    }
  });
};
