import {
  DInject,
  DInjectable
} from "presentation/core/features/dependencyInjection";
import { ApiUrls } from "infrastructure/api/struct/ApiTypes";
import { HttpClient, HttpMethod } from "infrastructure/service/HttpClient";
import { UserRepository } from "domain/service/administration/UserRepository";
import { ApiUser, UserApiMapper } from "./UserApiMapper";
import { PaginatedResult, PaginationConfig } from "lib/contract/Pagination";
import { SortingConfig } from "lib/contract/Sorting";
import { User } from "../../../domain/struct/administration/User";

const URL: ApiUrls = {
  FindAll: "/api/admin/v1/users"
};

@DInjectable()
export class UserApiRepository implements UserRepository {
  constructor(@DInject(HttpClient) protected httpClient: HttpClient) {}

  findAll(
    pagination: PaginationConfig,
    sorting?: SortingConfig
  ): Promise<PaginatedResult<User>> {
    return this.httpClient
      .fetchPaginated<ApiUser>({
        url: URL.FindAll,
        pagination,
        sorting
      })
      .then(UserApiMapper.mapListToDomain);
  }
}
