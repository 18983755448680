import { PasswordRepositoryToken } from "domain/di/token/authentication";
import { PasswordApiRepository } from "infrastructure/api/authentication/PasswordApiRepository";
import { Logout } from "useCase/authentication/Logout";
import { RequestPasswordReset } from "useCase/authentication/RequestPasswordReset";

import { LogoutRepositoryToken } from "domain/di/token/authentication";
import { LogoutApiRepository } from "infrastructure/api/authentication/LogoutApiRepository";

export const authenticationProviders = [
  // repository
  {
    provide: PasswordRepositoryToken,
    useClass: PasswordApiRepository
  },
  {
    provide: LogoutRepositoryToken,
    useClass: LogoutApiRepository
  },
  // use case
  RequestPasswordReset,
  Logout
];
