import gavel from "assets/icons/gavel.svg";
import manual from "assets/icons/manual.svg";
import support from "assets/icons/support.svg";
import styled from "presentation/styles";

const filter = "invert(10%)";
const iconBase = {
  "&:hover": {
    filter
  },
  backgroundSize: "contain",
  cursor: "pointer",
  height: "100px",
  margin: "10px 15px",
  width: "100px"
};

export const StyledIconBox = styled("div")<{}>(() => ({
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  fontSize: "1.125rem",
  fontWeight: "bold",
  justifyContent: "center",
  "& > span": {
    textWrap: "wrap",
    maxWidth: "100px",
    textAlign: "center"
  }
}));

export const StyledManual = styled("div")<{}>(() => ({
  background: `url(${manual}) center center no-repeat`,
  ...iconBase
}));

export const StyledSupport = styled("div")<{}>(() => ({
  background: `url(${support}) center center no-repeat`,
  ...iconBase
}));

export const StyledLicense = styled("div")<{}>(() => ({
  background: `url(${gavel}) center center no-repeat`,
  ...iconBase
}));

export const StyledDashboardSquares = styled("img")<{}>(() => ({
  width: "53%",
  height: "auto",
  position: "fixed",
  bottom: "0",
  right: "0"
}));

export const StyledTextBox = styled("div")<{}>(({ theme }) => ({
  "& > p": {
    color: theme.colors?.black,
    margin: "0",
    textAlign: "center",
    fontWeight: "bold"
  },
  "& > p.colored": {
    color: theme.colors?.orange,
    fontWeight: "bold"
  },
  color: theme.colors?.white,
  fontSize: "2.75rem",
  fontWeight: "normal",
  zIndex: 2
}));

export const StyledDivider = styled("div")<{}>(({ theme }) => ({
  background: "rgba(0, 0, 0, 0.5)",
  height: "1px",
  margin: "50px 0 40px 0",
  width: "100%"
}));

export const StyledBox = styled("div")<{}>(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between"
}));

export const StyledWrapper = styled("div")<{}>(() => ({
  margin: "80px auto",
  maxWidth: "800px",
  padding: "0 20px"
}));
