import { getService } from "presentation/core/features/dependencyInjection";

import { useQM } from "presentation/share/hook/query/useQM";

import { FindContact } from "../../../../useCase/codelist/findContact/FindContact";
import { Config } from "./useFindPersons";

const QUERY_KEY = "codeLists/findContacts";
// 5. step - prepare to connect use case to presentation

export const useFindContacts = (config: Config) => {
  const findUseCase = getService(FindContact);

  return useQM(QUERY_KEY, () => findUseCase.findAll(), config);
};
