import { ApiListResponseMapper } from "infrastructure/api/mapper/ApiListResponseMapper";
import {
  ApiListResponse,
  ApiTypes
} from "infrastructure/api/struct/ApiTypesV2";
import { stringOrDefault } from "lib/string";
import { OverViewsForSignature } from "../types/OverViewsForSignature";

export type ApiOverviewsForSignatureResponse = ApiTypes["ISFG.SpisUm.ClientSide.Models.V2.Overview.OverviewForSignatureResponse"];

export class Mappers {
  static mapListToDomain(
    response: ApiListResponse<ApiOverviewsForSignatureResponse>
  ) {
    return ApiListResponseMapper.mapListToPagination(
      response,
      Mappers.mapToDomain
    );
  }

  static mapToDomain(
    response: ApiOverviewsForSignatureResponse
  ): OverViewsForSignature {
    const {
      nodeId,
      pid,
      ssid,
      subject,
      deliveryDate,
      forSignatureDate,
      attachmentsCount,
      owner
    } = response;
    return {
      nodeId: stringOrDefault(nodeId),
      pid: stringOrDefault(pid),
      ssid: stringOrDefault(ssid),
      subject: stringOrDefault(subject),
      deliveryDate: stringOrDefault(deliveryDate),
      forSignatureDate: stringOrDefault(forSignatureDate),
      attachmentsCount,
      owner: stringOrDefault(owner)
    };
  }
}
