import React from "react";
import { FilePlanGroups } from "presentation/core/features/login/_types";

export interface FileMarkColumnValueProps {
  fileMarkId: FilePlanGroups["identificator"];
  separator?: string;
}

export const FileMarkColumnValue = ({
  fileMarkId,
  separator = "VS_"
}: FileMarkColumnValueProps) => {
  const mayBeFilemarkCode = fileMarkId?.split(separator);

  if (!mayBeFilemarkCode || mayBeFilemarkCode.length < 2) {
    return null;
  }

  return <>{mayBeFilemarkCode[1]}</>;
};
