import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { Form, Formik } from "formik";
import { Select, TextField } from "formik-mui";
import { SslAnalog } from "presentation/core/api/models";
import { MetaFormProps } from "presentation/core/components/MetaForm/_types";
import Datepicker from "presentation/core/components/datepicker/DatePicker";
import { StyledKeyboardDatePickerFifth } from "presentation/core/components/datepicker/DatePicker.styles";
import DateTimePicker from "presentation/core/components/datetimepicker";
import { StyledDateTimePickerFifth } from "presentation/core/components/datetimepicker/DateTimePicker.styles";
import {
  StyledFieldFifth,
  StyledFieldWide,
  StyledFormControlFifth,
  useStyles
} from "presentation/core/components/dialog/Dialog.styles";
import { FormState } from "presentation/core/components/reactiveFormik/_types";
import { SenderForm } from "presentation/core/components/senderForm/SenderForm";
import { cmPropsProxy, sslPropsProxy } from "presentation/core/types";
import { DocumentType } from "presentation/enums";
import { useIsValidOrExpireFilePlan } from "presentation/share/hook/filePlan/useIsValidOrExpireFilePlan";
import {
  lastPathMember,
  translationPath
} from "presentation/share/utils/getPath";
import { WithTranslation, lang, t } from "presentation/translation/i18n";
import React from "react";
import { withTranslation } from "react-i18next";
import { BaseField } from "../../../../core/components/form/fields/BaseField";
import FilePlanField from "../../form/fields/FilePlanField";
import { GeneralFileMarkField } from "../../form/fields/GeneralFileMarkField";
import { SslDocumentState } from "../../form/fields/SSLDocumentState";
import { validate } from "./_validations";

const Component = ({
  initialValues,
  formRef,
  readonly
}: MetaFormProps<SslAnalog> & WithTranslation) => {
  const classes = useStyles();
  const handle = (): void => void 0;

  const filePlans = useIsValidOrExpireFilePlan(initialValues.filePlan);
  const isReadonly = !!readonly;

  return (
    <Formik<SslAnalog>
      initialValues={initialValues}
      validate={validate}
      innerRef={formRef || handle}
      onSubmit={handle}
    >
      {({ setFieldValue, values, submitCount }: FormState<SslAnalog>) => {
        let filePlan = filePlans.find(
          (plan) => plan.nodeId === values.filePlan
        );
        let fileMarks = filePlan?.filePlanGroups || [];

        if (submitCount) {
        }
        const handlePlanChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          if (!e.target.value || filePlans.length > 1) {
            setFieldValue(lastPathMember(sslPropsProxy.fileMark).path, "");
            setFieldValue(lastPathMember(sslPropsProxy.retentionMode).path, "");
          }

          filePlan = filePlans.find((plan) => plan.nodeId === e.target.value);
          fileMarks = filePlan?.filePlanGroups || [];
        };

        return (
          <Form className={classes.form}>
            <StyledFieldFifth
              component={TextField}
              data-test-id="meta-input-pid"
              disabled={true}
              name={lastPathMember(sslPropsProxy.pid).path}
              type="text"
              label={t(translationPath(lang.general.pid))}
            />
            <StyledFormControlFifth>
              <InputLabel htmlFor="form">
                {t(translationPath(lang.general.form))}
              </InputLabel>
              <BaseField
                component={Select}
                data-test-id="meta-input-form"
                disabled={true}
                name={lastPathMember(sslPropsProxy.form).path}
                inputProps={{
                  id: lastPathMember(sslPropsProxy.form).path
                }}
              >
                <MenuItem value={DocumentType.Analog}>
                  {t(translationPath(lang.general.analog))}
                </MenuItem>
                <MenuItem value={DocumentType.Digital}>
                  {t(translationPath(lang.general.digital))}
                </MenuItem>
                <MenuItem value={DocumentType.Hybrid}>
                  {t(translationPath(lang.general.hybrid))}
                </MenuItem>
              </BaseField>
            </StyledFormControlFifth>
            <StyledFieldFifth
              component={TextField}
              data-test-id="meta-input-ssid"
              disabled={true}
              name={lastPathMember(sslPropsProxy.ssid).path}
              type="text"
              label={t(translationPath(lang.general.ssid))}
            />
            <StyledFieldFifth
              component={TextField}
              data-test-id="meta-input-fileIdentifier"
              disabled={true}
              name={lastPathMember(sslPropsProxy.fileIdentifier).path}
              type="text"
              label={t(translationPath(lang.general.fileIdentifier))}
            />
            <DateTimePicker
              component={StyledDateTimePickerFifth}
              data-test-id="meta-input-createdAt"
              disabled={true}
              name={lastPathMember(sslPropsProxy.createdAt).path}
              label={t(translationPath(lang.general.createdDateAndTime))}
            />
            <Datepicker
              component={StyledKeyboardDatePickerFifth}
              data-test-id="meta-input-createdDate"
              disabled={true}
              name={lastPathMember(sslPropsProxy.createdDate).path}
              label={t(translationPath(lang.general.dateOfEvidence))}
            />
            <Datepicker
              component={StyledKeyboardDatePickerFifth}
              data-test-id="meta-input-settleTo"
              disablePast={true}
              disabled={readonly}
              name={lastPathMember(sslPropsProxy.settleToDate).path}
              label={t(translationPath(lang.general.settleToDate))}
            />
            <StyledFieldFifth
              component={TextField}
              data-test-id="meta-input-associationCount"
              disabled={true}
              name={lastPathMember(sslPropsProxy.associationCount).path}
              type="number"
              label={t(translationPath(lang.general.documentsCount))}
            />
            <StyledFieldFifth
              component={TextField}
              data-test-id="meta-input-owner"
              disabled={true}
              name={lastPathMember(cmPropsProxy.owner).path}
              type="text"
              label={t(translationPath(lang.general.owner))}
            />

            <SslDocumentState isFile={true} />

            <StyledFieldFifth
              component={TextField}
              data-test-id="meta-input-retentionMode"
              disabled={true}
              name={lastPathMember(sslPropsProxy.retentionMode).path}
              type="string"
              label={t(translationPath(lang.general.retentionMode))}
            />

            <FilePlanField
              validFilePlans={filePlans}
              isReadonly={isReadonly}
              handlePlanChange={handlePlanChange}
            />
            <GeneralFileMarkField
              isReadonly={isReadonly}
              values={values}
              fileMarks={fileMarks}
            />
            <StyledFieldFifth
              component={TextField}
              data-test-id="meta-input-senderSSID"
              disabled={readonly}
              name={lastPathMember(sslPropsProxy.senderSSID).path}
              type="string"
              label={t(translationPath(lang.general.senderSSID))}
            />
            <StyledFieldFifth
              component={TextField}
              data-test-id="meta-input-senderIdent"
              disabled={readonly}
              InputLabelProps={{ className: "MuiFormLabel-root--long-text" }}
              name={lastPathMember(sslPropsProxy.senderIdent).path}
              type="text"
              label={t(translationPath(lang.general.senderIdent))}
            />
            <SenderForm
              initialValues={initialValues}
              readonly={true}
              isFileDialog={true}
            />
            <StyledFieldWide
              component={TextField}
              data-test-id="meta-input-subject"
              disabled={readonly}
              name={lastPathMember(sslPropsProxy.subject).path}
              type="string"
              label={t(translationPath(lang.general.subject))}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export const FileDetailsMetadataForm = withTranslation()(React.memo(Component));
