import ForwardRoundedIcon from "@mui/icons-material/ForwardRounded";
import styled from "styled-components";
import { Button } from "../../../designSystem/Button/Button";

export const StyledButton = styled(Button)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
}));

export const StyledForwardRoundedIcon = styled(ForwardRoundedIcon)(() => ({
  transform: "scaleY(0.8)",
  paddingTop: "3px"
}));
