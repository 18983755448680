import {
  NotificationConfig,
  Notification
} from "../../../designSystem/notification/Notification";
import type { UseMutationOptions, MutationFunction } from "react-query";

import { useMutation as useMutationOriginal } from "react-query";
import { isFunction } from "../../../../lib/function";
import { lang, t } from "../../../translation/i18n";
import { translationPath } from "../../utils/getPath";
import { useCallback } from "react";

export interface MutationConfig<TData, TError, TVariables, TContext>
  extends UseMutationOptions<TData, TError, TVariables, TContext> {
  onSuccessNotification?:
    | null // do not display the default notification
    | NotificationConfig // display this notification instead of the default one
    | ((result: TData) => NotificationConfig); // use this function to get notification to display
  onErrorNotification?:
    | null
    | NotificationConfig
    | ((result: TError) => NotificationConfig);
}

const defaultErrorNotification: NotificationConfig = {
  message: t(translationPath(lang.dialog.notifications.actionFailed))
};

const defaultSuccessNotification: NotificationConfig = {
  message: t(translationPath(lang.dialog.notifications.actionSucceeded))
};

/**
 * Wrapper around useMutation, which automatically displays an error or a success notification
 * when after a mutation function ends.
 *
 * @param mutationFn
 * @param config
 */
export const useMutation = <TData, TError, TVariables, TContext>(
  mutationFn: MutationFunction<TData, TVariables>,
  config?: MutationConfig<TData, TError, TVariables, TContext>
) => {
  const {
    onErrorNotification = defaultErrorNotification,
    onSuccessNotification = defaultSuccessNotification,
    onSuccess: onSuccessOriginal,
    onError: onErrorOriginal,
    ...mutationConfig
  } = config || {};

  const handleOnSuccess = useCallback(
    (result: TData, variables: TVariables, context?: TContext) => {
      const config = isFunction(onSuccessNotification)
        ? onSuccessNotification(result)
        : onSuccessNotification;

      Notification.success(config!);

      return onSuccessOriginal?.(result, variables, context);
    },
    [onSuccessNotification, onSuccessOriginal]
  );

  const onSuccess = onSuccessNotification ? handleOnSuccess : onSuccessOriginal;

  const handleOnError = useCallback(
    (error: TError, variables: TVariables, context?: TContext) => {
      const config = isFunction(onErrorNotification)
        ? onErrorNotification(error)
        : onErrorNotification;
      Notification.error(config!);

      return onErrorOriginal?.(error, variables, context);
    },
    [onErrorNotification, onErrorOriginal]
  );

  const onError = onErrorNotification ? handleOnError : onErrorOriginal;

  return useMutationOriginal(mutationFn, {
    ...mutationConfig,
    onError,
    onSuccess
  });
};
