import {
  DInject,
  DInjectable
} from "presentation/core/features/dependencyInjection";
import { SignerGroupRepositoryToken } from "../../domain/di/token/user";
import type { SignerGroupRepository } from "../../domain/service/user/SignerGroupRepository";

@DInjectable()
export class FindSignerGroups {
  constructor(
    @DInject(SignerGroupRepositoryToken)
    protected repository: SignerGroupRepository
  ) {}

  getSignerGroups() {
    return this.repository.getSignerGroups();
  }
}
