import {
  createColumn,
  createDateTimeColumn
} from "@isfg/react-components/build/Table/column/column";
import { Description, Undo } from "@mui/icons-material";
import { useModal } from "presentation/designSystem/Modal/useModal";
import {
  GetRemoteDataClb,
  RemoteTable
} from "presentation/designSystem/Table/RemoteTable";
import React, { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { DateTimeFormat } from "../../../../../../../lib/dateTime";
import { openDocumentWithSaveButtonsAction } from "../../../../../../core/api/document/_actions";
import { BreadcrumbForTable } from "../../../../../../designSystem/Breadcrumb/Breadcrumb";
import {
  ColumnType,
  TableRowSelection,
  useRemoteTableApi
} from "../../../../../../designSystem/Table/Table";
import { SpisumNodeTypes } from "../../../../../../enums";
import { translationPath } from "../../../../../../share/utils/getPath";
import { lang, t } from "../../../../../../translation/i18n";
import { useFindOverViewsForSignature } from "../hooks/useFindOverViewsForSignature";
import {
  WithdrawApplicationForSignatureModal,
  WithdrawApplicationForSignatureModalInterface
} from "../modals/WithdrawApplicationForSignatureModal";
import { OverViewsForSignature } from "../types/OverViewsForSignature";

const COLUMNS: ColumnType<OverViewsForSignature>[] = [
  createColumn({
    dataIndex: "pid",
    title: t(translationPath(lang.general.identifier)),
    sortDirections: ["ascend", "descend", "ascend"],
    sorter: true
  }),
  createColumn({
    dataIndex: "ssid",
    title: t(translationPath(lang.general.referenceNumber)),
    sortDirections: ["ascend", "descend", "ascend"],
    sorter: true
  }),
  createColumn({
    dataIndex: "subject",
    title: t(translationPath(lang.general.subject)),
    sortDirections: ["ascend", "descend", "ascend"],
    sorter: true
  }),
  createDateTimeColumn(
    {
      dataIndex: "deliveryDate",
      title: t(translationPath(lang.general.dateOfEvidence)),
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: true
    },
    DateTimeFormat.FullDateTime
  ),
  createColumn({
    dataIndex: "attachmentsCount",
    title: t(translationPath(lang.general.attachmentsCount)),
    sortDirections: ["ascend", "descend", "ascend"],
    sorter: true
  }),
  createDateTimeColumn(
    {
      dataIndex: "forSignatureDate",
      title: t(translationPath(lang.general.forSignatureDate)),
      defaultSortOrder: "ascend",
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: true
    },
    DateTimeFormat.FullDateTime
  ),
  createColumn({
    dataIndex: "owner",
    title: t(translationPath(lang.general.owner)),
    sortDirections: ["ascend", "descend", "ascend"],
    sorter: true
  })
];

export const OverviewsForSignatureTable = React.memo(() => {
  const dispatch = useDispatch();
  const findOverViewsForSignature = useFindOverViewsForSignature();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [tableApi, onRemoteTableApi] = useRemoteTableApi();
  const [
    withdrawApplicationForSignatureModal,
    withdrawApplicationForSignatureModalApi
  ] = useModal<WithdrawApplicationForSignatureModalInterface>(
    WithdrawApplicationForSignatureModal,
    {
      onOk: () => {
        setSelectedRowKeys([]);
        tableApi.refetch();
      }
    }
  );

  const ACTIONS = useMemo(
    () => ({
      single: [
        {
          key: "withdraw-application-for-signature",
          icon: <Undo />,
          tooltip: t(
            translationPath(lang.tooltip.withdrawApplicationForSignature)
          ),
          action(selected: OverViewsForSignature[]) {
            return withdrawApplicationForSignatureModalApi.open({
              nodeId: selected[0].nodeId!
            });
          }
        },
        {
          key: "overviews-for-signature",
          icon: <Description />,
          tooltip: t(translationPath(lang.general.showDetails)),
          action(selected: OverViewsForSignature[]) {
            dispatch(
              openDocumentWithSaveButtonsAction({
                data: {
                  id: selected[0].nodeId
                },
                canUploadComponents: false,
                hideManageShipmentsIcon: true,
                initiator: SpisumNodeTypes.Document,
                isReadonly: true
              })
            );
          }
        }
      ]
    }),
    [dispatch, withdrawApplicationForSignatureModalApi]
  );

  const getRemoteData: GetRemoteDataClb<OverViewsForSignature> = useCallback(
    ({ pagination, sorting }) => findOverViewsForSignature(pagination, sorting),
    [findOverViewsForSignature]
  );

  const onRowSelection: TableRowSelection<OverViewsForSignature> = {
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys);
    },
    selectedRowKeys,
    type: "checkbox"
  };

  return (
    <>
      {withdrawApplicationForSignatureModal}
      <BreadcrumbForTable title={"forSignature"}></BreadcrumbForTable>
      <TableStyled>
        <RemoteTable<OverViewsForSignature>
          name="overview/OverviewsForSignature"
          rowKey="nodeId"
          rowSelection={onRowSelection}
          actions={ACTIONS}
          columns={COLUMNS}
          getRemoteData={getRemoteData}
          onRemoteTableApi={onRemoteTableApi}
        />
      </TableStyled>
    </>
  );
});

const TableStyled = styled("div")`
  div > .ant-row {
    height: 50px;
  }
`;
