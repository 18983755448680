import { PaginationConfig } from "@isfg/lib/build/contract/Pagination";
import { SortingConfig } from "@isfg/lib/build/contract/Sorting";
import { ApiUrls } from "infrastructure/api/struct/ApiTypesV2";
import { PaginatedResult } from "lib/contract/Pagination";
import {
  HttpClient,
  HttpMethod
} from "../../../../../../infrastructure/service/HttpClient";
import { CodeListResponse, CodeListValueResponse } from "../types";
import {
  mapCodeListResponsePaginated,
  mapCodeListValueResponsePaginated
} from "./mapper";

const URL: ApiUrls = {
  // Table
  GetCodeList: "/api/admin/v2/codelist",
  GetCodeValueList: "/api/admin/v2/codelist/{codeList}",
  // Deactivate value
  PostDeactivateValue: "/api/admin/v2/codelist/{codeList}/deactivate",
  // Create new value
  PostCreateNewValue: "/api/admin/v2/codelist/{codeList}",
  // Export codelist
  PostCodeListExport: "/api/admin/v2/codelist/{codeList}/export",
  // Upload value
  PostUploadValue: "/api/admin/v2/codelist/{codeList}/import"
};

export interface CodeListParams {
  value: "OtherSettleMethod";
}

export interface CreateNewValueProps {
  value: string;
  codeList: string;
}

export interface DeactivateValueParams {
  codeList: string;
  value: string;
}

export interface ExportCodeListsParams {
  codeList?: string;
}

export interface UploadValueProps {
  fileData: string;
}

// Fetch table
export const getCodeList = (
  pagination: PaginationConfig,
  sorting?: SortingConfig,
  params?: CodeListParams
): Promise<PaginatedResult<CodeListResponse>> => {
  const httpClient = new HttpClient();
  return httpClient
    .fetchPaginated<CodeListResponse>({
      url: URL.GetCodeList,
      pagination,
      sorting,
      config: {
        params
      }
    })
    .then(mapCodeListResponsePaginated);
};

// Fetch table
export const getCodeListValues = (
  codeListType: string,
  pagination: PaginationConfig,
  sorting?: SortingConfig,
  params?: CodeListParams
): Promise<PaginatedResult<CodeListValueResponse>> => {
  const httpClient = new HttpClient();
  return httpClient
    .fetchPaginated<CodeListValueResponse>({
      url: URL.GetCodeValueList,
      pagination,
      sorting,
      config: {
        urlWildCards: {
          codeList: codeListType
        }
      }
    })
    .then(mapCodeListValueResponsePaginated);
};

// Create new Value
export const createNewValue = (params: CreateNewValueProps) => {
  const { codeList, ...rest } = params;
  const httpClient = new HttpClient();
  return httpClient.fetchWithThrow(URL.PostCreateNewValue, "POST", {
    bodyJSON: rest,
    urlWildCards: { codeList: codeList }
  });
};

// Deactivate value
export const postDeactivateValue = (params: DeactivateValueParams) => {
  const { codeList, ...rest } = params;
  const httpClient = new HttpClient();
  return httpClient.fetchWithThrow<DeactivateValueParams>(
    URL.PostDeactivateValue,
    HttpMethod.Post,
    {
      bodyJSON: rest,
      urlWildCards: { codeList }
    }
  );
};

export const postCodeListExport = (
  codeListType: string,
  params?: ExportCodeListsParams
): Promise<{
  response: ExportCodeListsParams;
  headers: Headers | undefined;
}> => {
  const httpClient = new HttpClient();
  return httpClient.fetchWithReponseHeaders<ExportCodeListsParams>(
    "/api/admin/v2/codelist/{codeList}/export",
    HttpMethod.Get,
    {
      params,
      urlWildCards: { codeList: codeListType }
    }
  );
};

export const postUploadValue = (
  codeList: string,
  file: File
): Promise<UploadValueProps> => {
  const bodyFormData = new FormData();
  bodyFormData.append("fileData", file);
  const httpClient = new HttpClient();
  return httpClient.fetchWithThrow<UploadValueProps>(
    URL.PostUploadValue,
    HttpMethod.Post,
    {
      bodyFormData,
      urlWildCards: {
        codeList
      }
    }
  );
};
