import LinearProgress from "@mui/material/LinearProgress";
import { BaseModalProps } from "presentation/designSystem/Modal/useModal";
import { getErrorCodeTranslation } from "presentation/share/utils/errorCodeTranslation";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import React, { useEffect } from "react";
import styled from "styled-components";
import { Modal, ModalSize } from "../../../../../../designSystem/Modal/Modal";
import { useMutation } from "../../../../../../share/hook/query/useMutation";
import { usePostComponentsForSeal } from "../../hooks/usePostComponentsForSeal";

export interface ForSealCancelProps extends BaseModalProps {
  nodeId: string;
  componentId: string;
}
const PERIOD = 3000;
const successStatuses = ["OK", "BatchResultDone"];

export const SendComponentsForSealModal = ({
  nodeId,
  componentId,
  onOk,
  onCancel
}: ForSealCancelProps) => {
  const getNotificationForError = (error: any) => ({
    message: getErrorCodeTranslation(error.code)
  });
  const compoentsForSeal = usePostComponentsForSeal();

  const { mutate: sealComponent } = useMutation(
    () => compoentsForSeal(nodeId, componentId),
    {
      onSuccess() {
        onOk?.();
      },
      onErrorNotification: {
        message: t(translationPath(lang.dialog.notifications.actionFailed))
      },
      onSuccessNotification: {
        message: t(translationPath(lang.dialog.notifications.actionSucceeded))
      }
    }
  );
  useEffect(() => {
    sealComponent(nodeId);
  }, [nodeId, sealComponent]);

  return (
    <Modal
      open={true}
      title={t(translationPath(lang.dialog.componentForSealModal.title))}
      onCancel={onCancel}
      size={ModalSize.Small}
      cancelText={t(translationPath(lang.dialog.componentForSealModal.cancel))}
      okButtonProps={{ style: { display: "none" } }}
    >
      <StyledDiv>
        <p>{t(translationPath(lang.dialog.content.SealingInProgress))}</p>
      </StyledDiv>
      <LinearProgress />
    </Modal>
  );
};

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1.5rem;
`;
