import { getService } from "presentation/core/features/dependencyInjection";
import { FindRentedEvidenceDocuments } from "../../../../../../../useCase/evidence/FindRentedEvidenceDocuments";
import { PaginationConfig } from "../../../../../../../lib/contract/Pagination";
import { useCallback } from "react";

// 5. step - prepare to connect use case to presentation
export const useFindRentedEvidenceDocuments = () => {
  const findRentedDocuments = getService(FindRentedEvidenceDocuments);

  return useCallback(
    (config: PaginationConfig) => findRentedDocuments.findAllPaginated(config),
    [findRentedDocuments]
  );
};
