import { DateTimePicker } from "@mui/x-date-pickers";
import styled from "presentation/styles";
import {
  responsiveFieldFifth,
  responsiveFieldHalf,
  responsiveFieldQuarter
} from "../dialog/Dialog.styles";

export const StyledDateTimePicker = styled(DateTimePicker)<{}>(
  () => responsiveFieldQuarter
);

export const StyledDateTimePickerFifth = styled(DateTimePicker)<{}>(
  () => responsiveFieldFifth
);

styled(DateTimePicker)<{}>(() => responsiveFieldHalf);
