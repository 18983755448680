import { ApiAlfrescoListResponse, ApiTypes } from "../struct/ApiTypes";
import { Contact } from "../../../domain/struct/nameRegistry/Contact";
import { AlfrescoListResponseMapper } from "../mapper/AlfrescoListResponseMapper";
import { PaginatedResult } from "../../../lib/contract/Pagination";

export type ApiCreateContact = ApiTypes["ISFG.SpisUm.ClientSide.Models.AddressBook.ContactCreate"];
export type ApiContact = ApiTypes["ISFG.SpisUm.ClientSide.Models.NameRegister.ContactInfo"];

export class ContactApiMapper {
  static mapToApi(contact: Contact): ApiCreateContact {
    return {
      contactType: contact.contactType,
      contact: contact.contact
    };
  }

  static mapContactsToApi(contacts: Contact[]): ApiCreateContact[] {
    return contacts.map(ContactApiMapper.mapToApi);
  }

  static mapToDomain(contact: ApiContact): Contact {
    return {
      id: contact.id,
      contactType: contact.contactType || "",
      contact: contact.name || ""
    };
  }

  static mapContactsToDomain(
    contacts: ApiAlfrescoListResponse<ApiContact>
  ): PaginatedResult<Contact> {
    return AlfrescoListResponseMapper.mapListToPagination(
      contacts,
      ContactApiMapper.mapToDomain
    );
  }
}
