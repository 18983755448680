import { ApiListResponseMapper } from "infrastructure/api/mapper/ApiListResponseMapper";
import { ApiListResponse } from "infrastructure/api/struct/ApiTypesV2";
import { stringOrDefault } from "lib/string";
import { File } from "presentation/core/entities";
import { ActiveComponent, ActiveComponentBody } from "../_types";

export function mapInactiveComponentListToDomain(list: ApiListResponse<File>) {
  return ApiListResponseMapper.mapListToPagination(list, mapDocumentToDomain);
}

function mapDocumentToDomain(document: File): File {
  return {
    ...document,
    mimeType: stringOrDefault(document.mimeType)
  };
}

export function mapToApi(
  activeComponent: ActiveComponent
): ActiveComponentBody {
  return {
    type: activeComponent.type ?? null,
    isReadable: activeComponent.isReadable ?? null
  };
}

export function mapToComponentIds(
  components: File[]
): { componentIds: string[] } {
  return {
    componentIds: components.map(function (component) {
      return component["id"];
    })
  };
}
