import { SaveDocumentActionType } from "presentation/core/api/document/_types";
import { ActionOnCloseType } from "presentation/core/components/dialog/_types";
import { createSafeAction } from "presentation/share/utils/typesafeActions";

export const createAnalogDocument = createSafeAction(
  "@document/CREATE_OPEN_ANALOG_DOCUMENT"
)<{ onClose?: (props: ActionOnCloseType) => void }>();

export const saveDocumentAction = createSafeAction(
  "@document/SAVE_DOCUMENT"
)<SaveDocumentActionType>();
