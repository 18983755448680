import { AssignmentInd } from "@mui/icons-material";
import { MenuItemType } from "presentation/core/components/menu/_types";
import { createMenuItemFromLocation } from "./component/menu/helpers";
import { legalEntityLocation, personsLocation } from "./locations";
import React from "react";

const MENU_TEST_ID = "main-menu-name-register";

export const menuItems: MenuItemType = {
  text: "Jmenný rejstřík",
  icon: <AssignmentInd />,
  __testId: MENU_TEST_ID,
  submenu: [
    createMenuItemFromLocation(personsLocation, {
      text: "Fyzické osoby",
      __testId: `${MENU_TEST_ID}-persons`
    }),
    createMenuItemFromLocation(legalEntityLocation, {
      text: "Právnické osoby",
      __testId: `${MENU_TEST_ID}-anti-persons`
    })
  ]
};
