import { ApiUrls } from "infrastructure/api/struct/ApiTypes";
import { HttpClient } from "infrastructure/service/HttpClient";
import { PaginationConfig } from "lib/contract/Pagination";
import { NodeVersion } from "presentation/core/api/models";
import { mapGetVersions } from "./mappers";

const URL: ApiUrls = {
  GetVersions: "/api/app/v1/node/{nodeId}/versions"
};

export const getVersions = (nodeId: string, config: PaginationConfig) => {
  const httpClient = new HttpClient();

  return httpClient
    .fetchPaginatedAlfresco<NodeVersion>(URL.GetVersions, config, {
      urlWildCards: {
        nodeId: nodeId
      }
    })
    .then(mapGetVersions);
};
