export enum PresentationModules {
  MailRoom = "mailroom",
  Evidence = "evidence",
  Dispatch = "dispatch",
  Repository = "repository",
  SignatureBook = "signatureBook",
  RetentionProcess = "recordRetentionProcess",
  NameRegister = "nameRegister",
  Administration = "administration"
}

// This class is used only for defining permissions for an access to a presentation module
export class PresentationModule {
  // modelName is used in our permissions system, so do not delete it
  static readonly modelName = "PresentationModule";

  constructor(public name: PresentationModules) {}
}
