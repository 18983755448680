import { ApiTypes } from "../../struct/ApiTypes";
import { DataBox } from "../../../../domain/struct/nameRegistry/DataBox";
import { stringOrDefault } from "../../../../lib/string";
import { SubjectType } from "../../../../domain/struct/nameRegistry/SubjectType";

export type ApiDataBox = ApiTypes["ISFG.SpisUm.ClientSide.Models.DataBox.DataBoxSearch"];

export class DataBoxApiMapper {
  static mapToDomain(response: ApiDataBox): DataBox {
    return {
      subjectName: stringOrDefault(response.subjectName),
      databoxId: stringOrDefault(response.dataBoxId),
      postDataboxEnabled: response.postDataboxEnabled,
      identificationNumber: stringOrDefault(response.identificationNumber),
      subjectType: stringOrDefault(response.subjectType) as SubjectType,
      ovmId: stringOrDefault(response.ovmId),
      address: {
        city: stringOrDefault(response.address?.city),
        cityPart: stringOrDefault(response.address?.cityDistrict),
        houseNumber: stringOrDefault(response.address?.houseNumber),
        street: stringOrDefault(response.address?.street),
        postalCode: stringOrDefault(response.address?.postalCode),
        orientationHouseNumber: stringOrDefault(
          response.address?.orientationalNumber
        ),
        orientationHouseNumberAddendum: stringOrDefault(
          response.address?.orientationalNumberAppendix
        )
      }
    };
  }
}
