import contentDisposition from "content-disposition";
import fileDownload from "js-file-download";
import { call, put, takeEvery } from "redux-saga/effects";
import { fetchSaga } from "presentation/share/utils/fetch";
import { translationPath } from "presentation/share/utils/getPath";
import { t } from "presentation/translation/i18n";
import lang from "presentation/translation/lang";
import { ActionType, getType } from "typesafe-actions";
import { ApiURL } from "../../apiURL";
import {
  exportFilePlanSummaryAction,
  exportShreddingProposalAction,
  exportToPDFFilePlanAction,
  filePlanChangeValidityDate,
  getFilePlanAction,
  importFilePlanAction
} from "./_actions";
import { Notification } from "presentation/designSystem/notification/Notification";

export function* watchFilePlan() {
  yield takeEvery(
    getType(getFilePlanAction.request),
    function* ({ payload }: ActionType<typeof getFilePlanAction.request>) {
      const { nodeId } = payload;

      const { success, response, responseHeaders } = yield call(
        fetchSaga,
        ApiURL.ADMIN_FILE_PLAN_DOWNLOAD,
        "GET",
        {
          urlWildCards: {
            nodeId
          }
        }
      );

      if (!success) {
        Notification.error({
          message: t(translationPath(lang.dialog.notifications.actionFailed))
        });
        return;
      }

      const disposition = contentDisposition.parse(
        responseHeaders.get("content-disposition")
      );

      fileDownload(response, disposition.parameters.filename);

      Notification.success({
        message: t(translationPath(lang.dialog.notifications.actionSucceeded))
      });
    }
  );

  yield takeEvery(
    getType(filePlanChangeValidityDate.request),
    function* ({
      payload
    }: ActionType<typeof filePlanChangeValidityDate.request>) {
      const { body, nodeId } = payload;
      const { errorResponse, response, success } = yield call(
        fetchSaga,
        ApiURL.FILE_CHANGE_VALIDITY_DATE,
        "POST",
        {
          bodyJSON: body,
          urlWildCards: {
            nodeId
          }
        }
      );

      if (!success) {
        yield put(filePlanChangeValidityDate.failure(errorResponse));
        return;
      }

      yield put(filePlanChangeValidityDate.success(response));
    }
  );

  yield takeEvery(
    getType(exportShreddingProposalAction.request),
    function* ({
      payload
    }: ActionType<typeof exportShreddingProposalAction.request>) {
      const { nodeId } = payload;
      const { errorResponse, response, responseHeaders, success } = yield call(
        fetchSaga,
        ApiURL.EXPORT_SHREDDING_PROPOSAL,
        "GET",
        {
          urlWildCards: {
            nodeId
          }
        }
      );

      if (!success) {
        Notification.error({
          message: t(translationPath(lang.dialog.notifications.actionFailed))
        });
        return;
      }

      const disposition = contentDisposition.parse(
        responseHeaders.get("content-disposition")
      );

      fileDownload(response, disposition.parameters.filename);

      Notification.success({
        message: t(translationPath(lang.dialog.notifications.actionSucceeded))
      });
    }
  );

  yield takeEvery(
    getType(importFilePlanAction.request),
    function* ({ payload }: ActionType<typeof importFilePlanAction.request>) {
      const { file } = payload;

      const bodyFormData = new FormData();
      bodyFormData.append("fileData", file);

      const { errorResponse, response, success } = yield call(
        fetchSaga,
        ApiURL.FILE_PLAN_IMPORT,
        "POST",
        {
          bodyFormData
        }
      );

      if (!success) {
        yield put(importFilePlanAction.failure(errorResponse));
        return;
      }

      yield put(importFilePlanAction.success(response));
    }
  );

  yield takeEvery(
    getType(exportFilePlanSummaryAction.request),
    function* ({
      payload
    }: ActionType<typeof exportFilePlanSummaryAction.request>) {
      const { nodeId, body } = payload;

      const { errorResponse, success, response, responseHeaders } = yield call(
        fetchSaga,
        ApiURL.FILE_EXPORT,
        "POST",
        {
          urlWildCards: {
            nodeId
          },
          bodyJSON: body
        }
      );

      if (!success) {
        yield put(exportFilePlanSummaryAction.failure(errorResponse.payload));

        Notification.error({
          message: t(translationPath(lang.dialog.notifications.actionFailed))
        });
        return;
      }

      Notification.success({
        message: t(translationPath(lang.dialog.notifications.actionSucceeded))
      });

      const disposition = contentDisposition.parse(
        responseHeaders.get("content-disposition")
      );

      fileDownload(response, disposition.parameters.filename);

      yield put(exportFilePlanSummaryAction.success(response));
    }
  );

  yield takeEvery(
    getType(exportToPDFFilePlanAction.request),
    function* ({
      payload
    }: ActionType<typeof exportToPDFFilePlanAction.request>) {
      const { nodeId } = payload;

      const { errorResponse, success, response, responseHeaders } = yield call(
        fetchSaga,
        ApiURL.A_FILE_PLAN_FILE_PLAN_PRINT,
        "GET",
        {
          params: {
            filePlanId: String(nodeId)
          } as Record<string, string>
        }
      );

      if (success) {
        Notification.success({
          message: t(translationPath(lang.dialog.notifications.actionSucceeded))
        });
      }

      if (!success) {
        yield put(exportToPDFFilePlanAction.failure(errorResponse.payload));

        Notification.error({
          message: t(translationPath(lang.dialog.notifications.actionFailed))
        });
        return;
      }

      const disposition = contentDisposition.parse(
        responseHeaders.get("content-disposition")
      );

      fileDownload(response, disposition.parameters.filename);

      yield put(exportToPDFFilePlanAction.success(response));
    }
  );
}
