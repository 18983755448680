import { ErasedFileRepository } from "domain/service/recordRetention/ErasedFileRepository";
import { ErasedDocumentRepository } from "domain/service/recordRetention/ErasedDocumentRepository";
import { RetentionProposalRemoveObjectsRepository } from "domain/service/recordRetention/RetentionProposalRemoveObjectsRepository";
import { RetentionProtocolsErasedRepository } from "domain/service/recordRetention/RetentionProtocolsErasedRepository";
import { RetentionProtocolsRepository } from "domain/service/recordRetention/RetentionProtocolsRepository";
import { RetentionProposalsRepository } from "domain/service/recordRetention/RetentionProposalsRepository";
import { DInjectionToken } from "../../../presentation/core/features/dependencyInjection";
import { RetentionForShreddingRepository } from "../../service/recordRetention/RetentionForShreddingRepository";
import { RetentionProtocolsOrProposalsContentRepository } from "../../service/recordRetention/RetentionProtocolsOrProposalsContentRepository";
import { RetentionProtocolsOrProposalsHistoryRepository } from "../../service/recordRetention/RetentionProtocolsOrProposalsHistoryRepository";
import { CancelProposalRepository } from "../../service/recordRetention/CancelProposalRepository";
import { PerformShreddingProcedureRepository } from "../../service/recordRetention/PerformShreddingProcedureRepository";
import { PerformDeletionRepository } from "../../service/recordRetention/PerformDeletionRepository";
export const ErasedFileRepositoryToken = new DInjectionToken<ErasedFileRepository>(
  "domain.recordRetention.erasedFile-repository"
);
export const ErasedDocumentRepositoryToken = new DInjectionToken<ErasedDocumentRepository>(
  "domain.recordRetention.ErasedDocumentRepository-repository"
);
export const RetentionProposalsRepositoryToken = new DInjectionToken<RetentionProposalsRepository>(
  "domain.recordRetention.retentionProposals-repository"
);
export const RetentionProtocolsRepositoryToken = new DInjectionToken<RetentionProtocolsRepository>(
  "domain.recordRetention.retentionProtocols-repository"
);
export const RetentionProtocolsErasedRepositoryToken = new DInjectionToken<RetentionProtocolsErasedRepository>(
  "domain.recordRetention.retentionProtocolsErased-repository"
);
export const RetentionProtocolsOrProposalsRepositoryTokenContent = new DInjectionToken<RetentionProtocolsOrProposalsContentRepository>(
  "domain.recordRetention.retentionProtocolsOrProposalsContent-repository"
);
export const RetentionProtocolsOrProposalsRepositoryTokenHistory = new DInjectionToken<RetentionProtocolsOrProposalsHistoryRepository>(
  "domain.recordRetention.retentionProtocolsOrProposalsHistory-repository"
);

export const RetentionForShreddingRepositoryToken = new DInjectionToken<RetentionForShreddingRepository>(
  "domain.recordRetention.retentionForShredding-repository"
);
export const CancelProposalRepositoryToken = new DInjectionToken<CancelProposalRepository>(
  "domain.recordRetention.cancelProposal-repository"
);
export const PerformShreddingProcedureToken = new DInjectionToken<PerformShreddingProcedureRepository>(
  "domain.recordRetention.performShreddingProcedure-repository"
);
export const RetentionProposalRemoveObjectsRepositoryToken = new DInjectionToken<RetentionProposalRemoveObjectsRepository>(
  "domain.recordRetention.retentionProposalRemoveObjects-repository"
);
export const PerformDeletionToken = new DInjectionToken<PerformDeletionRepository>(
  "domain.recordRetention.performDeletion-repository"
);
