import { useStyles } from "presentation/core/components/dialog/Dialog.styles";
import { DialogContentType } from "presentation/core/components/dialog/_types";
import React from "react";
import { RecoverDialogForm } from "./RecoverDialogForm";
import { RecoverDialogFormValues } from "./_types";
import { validate } from "./_validations";
import { ChannelForm } from "../ChannelForm/ChannelForm";

export const RecoverDialog: DialogContentType["content"] = ({ channel }) => {
  const classes = useStyles();

  return (
    <div className={classes.modalSmall}>
      <ChannelForm<RecoverDialogFormValues>
        initialValues={{
          reason: ""
        }}
        validate={validate}
        channel={channel}
        component={RecoverDialogForm}
      />
    </div>
  );
};
