import { SpisumNodeTypes } from "presentation/enums";
import { ApiURL } from "../../apiURL";

export const getNodeTypeSuffix = (nodeType: string | undefined): string => {
  if (
    nodeType === SpisumNodeTypes.Email ||
    nodeType === SpisumNodeTypes.Databox ||
    nodeType === SpisumNodeTypes.TakeDocumentForProcessing ||
    nodeType === SpisumNodeTypes.TakeDocumentProcessed
  ) {
    return "document";
  }
  if (
    nodeType === SpisumNodeTypes.TakeFileOpen ||
    nodeType === SpisumNodeTypes.TakeFileClosed
  ) {
    return "file";
  }
  if (nodeType === SpisumNodeTypes.TakeConcept) {
    return "concept";
  }
  const nodeTypeSuffix = nodeType?.replace("ssl:", "").replace("RM", "");
  return nodeTypeSuffix || "document";
};

export const getPreviewURL = (nodeType: string): string => {
  if (nodeType === SpisumNodeTypes.Shipment) {
    return ApiURL.NODE_SHIPMENT_PREVIEW;
  } else if (
    nodeType === SpisumNodeTypes.Email ||
    nodeType === SpisumNodeTypes.Databox
  ) {
    return ApiURL.NODE_DIGITALLY_DELIVERED_PREVIEW;
  } else if (nodeType === SpisumNodeTypes.Concept) {
    return ApiURL.NODE_CONCEPT_PREVIEW;
  } else {
    return ApiURL.NODE_DOCUMENT_PREVIEW;
  }
};

export const getNodeTypeByIdentifier = <
  T extends { fileIdentifier?: string; ssid?: string }
>(
  data: T
) => {
  return data.fileIdentifier ? SpisumNodeTypes.File : SpisumNodeTypes.Document;
};
