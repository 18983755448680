import {
  DInject,
  DInjectable
} from "presentation/core/features/dependencyInjection";
import { ApiUrls } from "infrastructure/api/struct/ApiTypesV2";
import { HttpClient, HttpMethod } from "infrastructure/service/HttpClient";
import { CancelProposalRepository } from "domain/service/recordRetention/CancelProposalRepository";

const URL: ApiUrls = {
  Delete: "/api/app/v2/shredding/proposal/{nodeId}/cancel",
  validateProposal: "/api/app/v2/shredding/proposal/{nodeId}/cancel/validate"
};

@DInjectable()
export class CancelProposalApiRepository implements CancelProposalRepository {
  constructor(@DInject(HttpClient) protected httpClient: HttpClient) {}

  delete(proposalId: string, reason: string): Promise<void> {
    return this.httpClient.fetchWithThrow(URL.Delete, HttpMethod.Post, {
      bodyJSON: { reason: reason },
      urlWildCards: {
        nodeId: proposalId
      }
    });
  }
  validateRetentionProposal(proposalId: string): Promise<void> {
    return this.httpClient.fetchWithThrow(
      URL.validateProposal,
      HttpMethod.Post,
      {
        urlWildCards: {
          nodeId: proposalId
        }
      }
    );
  }
}
