import { HttpClient, HttpMethod } from "infrastructure/service/HttpClient";
import { DInjectable } from "presentation/core/features/dependencyInjection";
import {
  CreatePayload,
  LegalEntityRepository
} from "../../../domain/service/nameRegistry/LegalEntityRepository";
import {
  LegalEntity,
  LegalEntityId
} from "../../../domain/struct/nameRegistry/LegalEntity";
import { ApiUrls } from "../struct/ApiTypes";
import {
  PaginatedResult,
  PaginationConfig
} from "../../../lib/contract/Pagination";
import { ApiLegalEntity, LegalEntityApiMapper } from "./LegalEntityApiMapper";
import { SortingConfig } from "lib/contract/Sorting";
import { ApiUrls as ApiUrls2 } from "../struct/ApiTypesV2";

const URL: ApiUrls = {
  Create: "/api/app/v1/name-register/subject/legal-subject/create",
  UpdateLegalEntity:
    "/api/app/v1/name-register/legal-subject/{subjectId}/update",
  GetByIdBasic: "/api/app/v1/name-register/subject/{subjectId}/basic",
  GetByIdFull: "/api/app/v1/name-register/subject/{subjectId}/full"
};
const URL2: ApiUrls2 = {
  FindAll: "/api/app/v2/name-register/legal-subjects"
};
@DInjectable()
export class LegalEntityApiRepository implements LegalEntityRepository {
  constructor(protected httpClient: HttpClient) {}

  findAllPaginated(
    pagination: PaginationConfig,
    sorting?: SortingConfig
  ): Promise<PaginatedResult<LegalEntity>> {
    return this.httpClient
      .fetchPaginated<ApiLegalEntity>({
        url: URL2.FindAll,
        pagination,
        sorting
      })
      .then(LegalEntityApiMapper.mapFindAllResponse);
  }

  create(payload: CreatePayload): Promise<void> {
    return this.httpClient.fetchWithThrow(URL.Create, HttpMethod.Post, {
      bodyJSON: LegalEntityApiMapper.mapCreateRequest(payload)
    });
  }

  update(legalEntity: LegalEntity): Promise<void> {
    return this.httpClient.fetchWithThrow(
      URL.UpdateLegalEntity,
      HttpMethod.Post,
      {
        bodyJSON: LegalEntityApiMapper.mapUpdateRequest(legalEntity),
        urlWildCards: {
          subjectId: legalEntity.id!
        }
      }
    );
  }

  findById(id: LegalEntityId): Promise<LegalEntity> {
    return this.httpClient
      .fetchWithThrow<ApiLegalEntity>(URL.GetByIdBasic, HttpMethod.Get, {
        urlWildCards: {
          subjectId: id
        }
      })
      .then(LegalEntityApiMapper.mapToDomain);
  }

  findFullById(id: LegalEntityId): Promise<LegalEntity> {
    return this.httpClient
      .fetchWithThrow<ApiLegalEntity>(URL.GetByIdFull, HttpMethod.Get, {
        urlWildCards: {
          subjectId: id
        }
      })
      .then(LegalEntityApiMapper.mapToDomain);
  }
}
