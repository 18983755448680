import { MenuItemType } from "../components/menu/_types";
import { useSelector } from "react-redux";
import { RootStateType } from "../../reducers";
import { userHasSealGroup, userHasSignGroup } from "../../share/utils/user";
import _ from "lodash";
import { useMemo } from "react";

export const useSubMenuItemsWithPermissions = (itemsOfMenu: MenuItemType[]) => {
  const menuWithSubMenu: MenuItemType[] = _.cloneDeep(itemsOfMenu);

  const session = useSelector(
    (state: RootStateType) => state.loginReducer.session
  );
  const userCanSealInThisGroup = userHasSealGroup(
    session.activeGroup!,
    session.myGroups
  );
  const userCanSignInThisGroup = userHasSignGroup(
    session.activeGroup!,
    session.myGroups
  );

  return useMemo(() => {
    return menuWithSubMenu.filter((item) => {
      if (item.text === "Podpisová kniha") {
        if (userCanSealInThisGroup && userCanSignInThisGroup) {
          return item;
        } else if (userCanSignInThisGroup && item.submenu!.length > 1) {
          return item.submenu!.pop();
        } else if (userCanSealInThisGroup && item.submenu!.length > 1) {
          return item.submenu!.shift();
        }
        return item;
      } else {
        return item;
      }
    });
  }, [userCanSignInThisGroup, userCanSealInThisGroup, itemsOfMenu]);
};
