import {
  SessionStatus,
  SessionType
} from "presentation/core/features/login/_types";
import { CoreRoutes } from "presentation/core/routes";
import { RootStateType, RouterReducerType } from "presentation/reducers";
import { RootRouterType } from "presentation/router";
import React from "react";
import { useStore } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { AnyAction } from "redux";

interface OwnProps {
  children: React.ReactNode;
  exact?: boolean;
  path?: RootRouterType;
  redirectTo: RootRouterType;
}

interface StateProps {
  session: SessionType;
}

const renderComponent =
  ({ children, redirectTo, session }: OwnProps & StateProps) =>
  () => {
    if (session && session.status === SessionStatus.AUTHORIZED) {
      return children;
    }

    return <Redirect to={redirectTo} />;
  };

const ProtectedRoute = (props: OwnProps) => {
  const session = useStore<
    RootStateType & RouterReducerType,
    AnyAction
  >().getState().loginReducer.session;
  return <Route {...props}>{renderComponent({ ...props, session })}</Route>;
};

ProtectedRoute.defaultProps = {
  redirectTo: CoreRoutes.LOGIN
};

export default ProtectedRoute;
