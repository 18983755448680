import { useModal } from "presentation/designSystem/Modal/useModal";
import { ActionResult } from "@isfg/lib/build/contract/Action";
import {
  EncryptedComponentsModal,
  EncryptedComponentsModalProps
} from "../modals/EncryptedComponentsModal";

export interface UseEncryptedComponentsModalProps
  extends EncryptedComponentsModalProps {}

export interface UseEncryptedComponentsModalReturn {
  encryptedComponentsModal: false | JSX.Element;
  openEncryptedComponentsModal(
    props?: Omit<EncryptedComponentsModalProps, "onOk" | "onCancel">
  ): Promise<ActionResult | undefined>;
}

export const useEncryptedComponentsModal = (
  props: UseEncryptedComponentsModalProps
): UseEncryptedComponentsModalReturn => {
  const [modal, modalApi] = useModal(EncryptedComponentsModal, props);

  return {
    encryptedComponentsModal: modal,
    openEncryptedComponentsModal: modalApi.open
  };
};
