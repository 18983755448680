import { useStyles } from "presentation/core/components/dialog/Dialog.styles";
import { DialogContentType } from "presentation/core/components/dialog/_types";
import React from "react";
import { BorrowDialogForm } from "./BorrowDialogForm";
import { BorrowFormValues } from "./_types";
import { borrowDialogValidationSchema } from "./_validations";
import { ChannelForm } from "../ChannelForm/ChannelForm";

export const BorrowDialog: DialogContentType["content"] = ({ channel }) => {
  const classes = useStyles();

  return (
    <div className={classes.modalSmall}>
      <ChannelForm<BorrowFormValues>
        initialValues={{
          group: "",
          user: ""
        }}
        validationSchema={borrowDialogValidationSchema}
        channel={channel}
        component={BorrowDialogForm}
      />
    </div>
  );
};
