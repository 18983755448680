import { createProxy } from "presentation/share/utils/getPath";

export interface User {
  firstName: string;
  displayName: string;
  emailNotificationsEnabled: boolean;
  company: object;
  id: string;
  enabled: boolean;
  email: string;
  properties: {
    ssl: {
      group: string;
      userExternal: boolean;
    };
  };
}

export const usersProxy = createProxy<User>();

export enum DisabledUsers {
  Admin = "admin",
  Databox = "databox",
  Emailbox = "emailbox",
  Guest = "guest",
  SAdmin = "sAdmin",
  Spisum = "spisum"
}
