import { DInjectable } from "presentation/core/features/dependencyInjection";
import { Contact } from "../../../../domain/struct/nameRegistry/Contact";
import { ContactType } from "../../../../domain/struct/nameRegistry/ContactType";

@DInjectable()
export class ContactListHelpers {
  findContactById(contacts: Contact[], id: number) {
    return contacts.find((contact) => contact.id === id);
  }

  findContactByType(contacts: Contact[], type: string) {
    return contacts.find((contact) => type === contact.contactType);
  }

  findContactsByType(contacts: Contact[], type: ContactType) {
    return contacts.filter(({ contactType }) => contactType === type);
  }
}
