import { call, put, takeEvery } from "redux-saga/effects";
import { fetchSaga } from "presentation/share/utils/fetch";
import { ActionType, getType } from "typesafe-actions";
import { ApiURL } from "../../apiURL";
import { daImport } from "./_actions";

export function* watchRetentionDA() {
  yield takeEvery(
    getType(daImport.request),
    function* ({ payload }: ActionType<typeof daImport.request>) {
      const { nodeId, file } = payload;

      const bodyFormData = new FormData();
      bodyFormData.append("fileData", file);

      const { errorResponse, response, success } = yield call(
        fetchSaga,
        ApiURL.DA_IMPORT,
        "POST",
        {
          urlWildCards: {
            nodeId
          },
          bodyFormData
        }
      );

      if (!success) {
        yield put(daImport.failure(errorResponse));
        return;
      }

      yield put(daImport.success(response));
    }
  );
}
