import {
  GetRemoteDataClb,
  RemoteTable
} from "presentation/designSystem/Table/RemoteTable";
import {
  createColumn,
  createDateTimeColumn
} from "presentation/designSystem/Table/column/column";
import { actionShowDetail } from "presentation/share/components/table/actionFactory";
import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import { RetentionProtocolsErased } from "../../../../../../../domain/struct/recordRetention/RetentionProtocolsErased";
import { StoredDocument } from "../../../../../../../domain/struct/repository/StoredDocument";
import { DateTimeFormat } from "../../../../../../../lib/dateTime";
import { BreadcrumbForTable } from "../../../../../../designSystem/Breadcrumb/Breadcrumb";
import { useModal } from "../../../../../../designSystem/Modal/useModal";
import { ColumnType } from "../../../../../../designSystem/Table/Table";
import { translationPath } from "../../../../../../share/utils/getPath";
import { lang, t } from "../../../../../../translation/i18n";
import { DetailsOfErasedRetentionProtocolOrProposalModal } from "../../../../modal/DetailsOfErasedRententionProtocolsModal";
import { useFindRetentionProtocolsErased } from "../hooks/useFindRetentionProtocolsErased";

const COLUMNS: ColumnType<RetentionProtocolsErased>[] = [
  createColumn({
    dataIndex: "pid",
    title: t(translationPath(lang.general.identifier))
  }),
  createColumn({
    dataIndex: "name",
    title: t(translationPath(lang.general.name))
  }),
  createDateTimeColumn(
    {
      dataIndex: "createdDate",
      title: t(translationPath(lang.general.createdDateAndTime))
    },
    DateTimeFormat.FullDateTime
  ),
  createColumn({
    dataIndex: "shreddingObjectsCount",
    title: t(translationPath(lang.general.shreddingObjectsCount))
  }),

  createDateTimeColumn(
    {
      dataIndex: "shreddingOperationDateTime",
      title: t(translationPath(lang.general.shreddingOperationDateTime))
    },
    DateTimeFormat.FullDateTime
  ),
  createColumn({
    dataIndex: "reviewerFullName",
    title: t(translationPath(lang.general.reviewerFullName))
  }),
  createDateTimeColumn(
    {
      dataIndex: "eraseDate",
      title: t(translationPath(lang.general.eraseDate)),
      defaultSortOrder: "descend",
      sortDirections: ["descend", "ascend", "descend"],
      sorter: true
    },
    DateTimeFormat.FullDateTime
  ),
  createColumn({
    dataIndex: "authorFullName",
    title: t(translationPath(lang.general.authorFullName))
  })
];

export const RetentionProtocolsErasedTable = React.memo(() => {
  const findRetentionProposal = useFindRetentionProtocolsErased();
  const [modal, modalApi] = useModal(
    DetailsOfErasedRetentionProtocolOrProposalModal,
    {}
  );
  const ACTIONS = useMemo(
    () => ({
      single: [
        actionShowDetail({
          action(selected: StoredDocument[]) {
            const selectedNodeId = selected[0]?.nodeId;

            if (selectedNodeId) {
              modalApi.open({
                nodeId: selectedNodeId,
                dialogType: "erased",
                onCancel() {
                  modalApi.close();
                }
              });
            }
          }
        })
      ]
    }),
    []
  );

  const getRemoteData: GetRemoteDataClb<RetentionProtocolsErased> = useCallback(
    ({ pagination, sorting }) => findRetentionProposal(pagination, sorting),
    [findRetentionProposal]
  );

  return (
    <>
      {modal}
      <BreadcrumbForTable title={"retentionAfterDeletion"}></BreadcrumbForTable>
      <TableStyled>
        <RemoteTable<RetentionProtocolsErased>
          name="retention/RetentionProtocolsErased"
          rowKey="pid"
          actions={ACTIONS}
          columns={COLUMNS}
          getRemoteData={getRemoteData}
        />
      </TableStyled>
    </>
  );
});

const TableStyled = styled("div")`
  div > .ant-row {
    height: 50px;
  }
`;
