import PlusCircleOutlined from "@ant-design/icons/lib/icons/PlusCircleOutlined";
import { Description, Lock } from "@mui/icons-material";
import { TableRowSelection } from "antd/lib/table/interface";
import { DateTimeFormat } from "lib/dateTime";
import MenuLayout from "presentation/core/components/layout/MenuLayout";
import { BreadcrumbForTable } from "presentation/designSystem/Breadcrumb/Breadcrumb";
import { useModal } from "presentation/designSystem/Modal/useModal";
import {
  GetRemoteDataClb,
  RemoteTable
} from "presentation/designSystem/Table/RemoteTable";
import {
  ColumnType,
  TableActions,
  useRemoteTableApi
} from "presentation/designSystem/Table/Table";
import {
  createColumn,
  createDateTimeColumn
} from "presentation/designSystem/Table/column/column";
import { FileMarkColumnValue } from "presentation/share/components/table/column/document/FilePlan/FileMarkColumnValue";
import { FilePlanColumnValue } from "presentation/share/components/table/column/document/FilePlan/FilePlanColumnValue";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { getOpenTypeFiles } from "../api";
import { useGetTypeFileOpen } from "../hooks/useGetTypeFileOpen";
import { CreateTypeFile } from "../modals/CreateTypeFile";
import {
  LockTypeFile,
  LockTypeFileModalInterface
} from "../modals/LockTypeFile";
import { OpenTypeFilesResponse } from "../types";

const COLUMNS: ColumnType<OpenTypeFilesResponse>[] = [
  createColumn({
    dataIndex: "pid",
    title: t(translationPath(lang.table.openTypeFilesTable.pid)),
    sortDirections: ["ascend", "descend", "ascend"],
    sorter: true
  }),

  createColumn({
    dataIndex: "subject",
    title: t(translationPath(lang.table.openTypeFilesTable.subject)),
    sortDirections: ["ascend", "descend", "ascend"],
    sorter: true
  }),

  createDateTimeColumn(
    {
      dataIndex: "createdAt",
      title: t(translationPath(lang.table.openTypeFilesTable.createdAt)),
      sortDirections: ["ascend", "descend", "ascend"],
      defaultSortOrder: "ascend",
      sorter: true
    },
    DateTimeFormat.FullDateTime
  ),

  createColumn({
    dataIndex: "filePlanId",
    title: t(translationPath(lang.table.openTypeFilesTable.filePlanId)),
    render: (text, row) => {
      const { filePlanId } = row;
      if (!filePlanId) {
        return null;
      }
      return <FilePlanColumnValue filePlanId={filePlanId} />;
    }
  }),

  createColumn({
    dataIndex: "fileMark",
    title: t(translationPath(lang.table.openTypeFilesTable.fileMark)),
    render: (text, row) => {
      const { fileMark } = row;
      if (!fileMark) {
        return null;
      }
      return <FileMarkColumnValue fileMarkId={fileMark} />;
    }
  })
];

interface OpenTypeFilesTableProps {
  remoteQueryParams?: Record<string, string>;
}

export const OpenTypeFilesTable = ({
  remoteQueryParams
}: OpenTypeFilesTableProps) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [createTypeFileModal, createTypeFileModalApi] =
    useModal(CreateTypeFile);
  const [lockTypeFileModal, lockTypeFileModalApi] =
    useModal<LockTypeFileModalInterface>(LockTypeFile, {
      onOk: () => {
        setSelectedRowKeys([]);
      }
    });
  const [selectedId, setSelectedId] = useState<string>("");
  const [tableApi, onRemoteTableApi] = useRemoteTableApi();

  const {
    modal: openTypeFileDetailModal,
    open: openOpenTypeFileDetailModal,
    data: openTypeFileDetailData
  } = useGetTypeFileOpen({
    id: selectedId,
    refreshOpenTypeFiles: tableApi.refetch
  });

  const ACTIONS: TableActions<OpenTypeFilesResponse> = useMemo(
    () => ({
      default: [
        {
          key: "create-type-file",
          icon: <PlusCircleOutlined rev={"default"} />,
          tooltip: t(
            translationPath(lang.table.openTypeFilesTable.createNewTypeFile)
          ),
          action() {
            return createTypeFileModalApi.open({});
          }
        }
      ],
      single: [
        {
          key: "detail-open-type-file",
          action: (selected: OpenTypeFilesResponse[]) => {
            if (selected[0]?.nodeId === selectedId) {
              setSelectedRowKeys([]);
              openOpenTypeFileDetailModal({
                document: openTypeFileDetailData!,
                refreshOpenTypeFiles: tableApi.refetch
              });
            } else {
              setSelectedId(selected[0]?.nodeId ?? "");
              setSelectedRowKeys([]);
            }
          },

          icon: <Description />,
          tooltip: t(
            translationPath(lang.table.openTypeFilesTable.typeFileDetail)
          )
        },
        {
          key: "lock-type-file",
          icon: <Lock />,
          tooltip: t(
            translationPath(lang.table.openTypeFilesTable.lockTypeFile)
          ),
          action(selected: OpenTypeFilesResponse[]) {
            return lockTypeFileModalApi.open({
              nodeId: selected[0].nodeId!
            });
          },
          validation: (selected: OpenTypeFilesResponse[]) =>
            selected[0]?.canBeClosed
              ? ""
              : t(
                  translationPath(
                    lang.table.openTypeFilesTable.allComponentsNotClosed
                  )
                )
        }
      ]
    }),
    [
      createTypeFileModalApi,
      selectedId,
      openOpenTypeFileDetailModal,
      openTypeFileDetailData,
      tableApi.refetch,
      lockTypeFileModalApi
    ]
  );

  const onRowSelection: TableRowSelection<OpenTypeFilesResponse> = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
    },
    selectedRowKeys
  };

  const getRemoteData: GetRemoteDataClb<OpenTypeFilesResponse> = useCallback(
    ({ pagination, sorting }) => getOpenTypeFiles(pagination, sorting),
    []
  );

  return (
    <MenuLayout>
      <>
        {createTypeFileModal}
        {openTypeFileDetailModal}
        {lockTypeFileModal}

        <BreadcrumbForTable title={"openTypeFiles"} />
        <TableStyled>
          <RemoteTable<OpenTypeFilesResponse>
            name="evidence/OpenTypeFiles"
            rowKey="pid"
            actions={ACTIONS}
            columns={COLUMNS}
            remoteQueryParams={remoteQueryParams}
            getRemoteData={getRemoteData}
            rowSelection={onRowSelection}
            onRemoteTableApi={onRemoteTableApi}
          />
        </TableStyled>
      </>
    </MenuLayout>
  );
};

const TableStyled = styled("div")`
  div > .ant-row {
    height: 50px;
  }
`;
