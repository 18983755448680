import {
  DInject,
  DInjectable
} from "presentation/core/features/dependencyInjection";
import { ApiUrls } from "infrastructure/api/struct/ApiTypes";
import { HttpClient } from "infrastructure/service/HttpClient";
import { FileConcernedSubjectRepository } from "domain/service/file/FileConcernedSubjectRepository";
import {
  PaginatedResult,
  PaginationConfig
} from "../../../lib/contract/Pagination";
import {
  ApiFileConcernedSubject,
  FileConcernedSubjectApiMapper
} from "./FileConcernedSubjectApiMapper";
import { FileConcernedSubject } from "../../../domain/struct/nameRegistry/FileConcernedSubject";

const URL: ApiUrls = {
  FindAll: "/api/app/v1/file/{nodeId}/concerned-subjects"
};

@DInjectable()
export class FileConcernedSubjectApiRepository
  implements FileConcernedSubjectRepository {
  constructor(@DInject(HttpClient) protected httpClient: HttpClient) {}

  findAllPaginated(
    fileId: string,
    config: PaginationConfig
  ): Promise<PaginatedResult<FileConcernedSubject>> {
    return this.httpClient
      .fetchPaginatedAlfresco<ApiFileConcernedSubject>(URL.FindAll, config, {
        urlWildCards: {
          nodeId: fileId
        }
      })
      .then(FileConcernedSubjectApiMapper.mapFindAll);
  }
}
