import { DocumentSubjectRelationType } from "../../../../../domain/struct/document/DocumentConcernedSubject";
import { t } from "../../../../translation/i18n";

export const getRelationTypeTranslation = (
  relationType: DocumentSubjectRelationType
) => {
  return getTranslation(relationType.toLowerCase());
};

export const getTranslation = (key: string) =>
  t(`table:concernedSubjectTable.${key}`);
