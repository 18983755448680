import React from "react";
import { FilePlan } from "presentation/core/features/login/_types";
import { useFilePlan } from "../../../../../hook/filePlan/useFilePlan";

export interface FilePlanColumnValueProps {
  filePlanId: FilePlan["nodeId"];
}

export const FilePlanColumnValue = ({
  filePlanId
}: FilePlanColumnValueProps) => {
  const filePlan = useFilePlan(filePlanId);

  if (!filePlan) {
    return <></>;
  }

  return <>{filePlan?.name}</>;
};
