import { DInjectable } from "presentation/core/features/dependencyInjection";
import { GenericDocument } from "../../presentation/core/types";
import { DocumentType, SenderType } from "../../presentation/enums";
import { SslProperties } from "../../presentation/core/api/models";

@DInjectable()
export class DocumentOwnership {
  // TODO: move GenericDocument etc. to the domain directory
  isOwn(document: GenericDocument) {
    return this.isOwnFromProperties(document.properties?.ssl || {});
  }

  isOwnFromProperties(properties: SslProperties) {
    return (
      // user is owner
      properties.senderType === SenderType.Own ||
      // it is concept or document created from concept
      properties.documentType === DocumentType.Concept
    );
  }
}
