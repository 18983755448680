import {
  documentCreateActionType,
  documentRegisterActionType
} from "presentation/core/api/document/_actions";
import {
  SslAnalogWithOwner,
  SslProperties
} from "presentation/core/api/models";
import { dialogOpenAction } from "presentation/core/components/dialog/_actions";
import { DialogType } from "presentation/core/components/dialog/_types";
import { metaFormAction__Update } from "presentation/core/components/MetaForm/_actions";
import { Notification } from "presentation/designSystem/notification/Notification";
import { DocumentType, SpisumNodeTypes } from "presentation/enums";
import { put, takeLatest } from "redux-saga/effects";
import {
  createAnalogDocument,
  saveDocumentAction
} from "presentation/share/components/dialog/analogDetailsDialog/_actions";
import { handleResponse } from "presentation/share/utils/typesafeActions";
import { lang, t } from "presentation/translation/i18n";
import { ActionType, getType } from "typesafe-actions";
import { translationPath } from "../../../utils/getPath";

export const defaultAnalogValues = (): SslAnalogWithOwner => {
  return {
    CDCount: "",
    USBCount: "",
    attachmentsCount: undefined,
    borrowDate: null,
    borrowReturnDate: null,
    createdAt: null,
    deliveryDate: new Date(),
    deliveryMode: "",
    deliveryTime: new Date(),
    description: "",
    fileMark: "",
    filePlan: "",
    form: DocumentType.Digital,
    listCount: undefined,
    listCountAttachments: undefined,
    openSheetCount: 0,
    owner: "",
    pid: "",
    retentionMark: "",
    retentionMode: "",
    retentionPeriod: null,
    senderId: "",
    senderIdent: "",
    senderSSID: "",
    senderType: "",
    senderAddressId: "",
    senderCompanyEmployeeJob: "",
    senderFullName: "",
    senderCompanyName: "",
    senderCompanyEmployeeUnit: "",
    settleToDate: null,
    shreddingDate: null,
    ssid: "",
    state: "",
    subject: "",
    volumesCount: 0
  };
};

export function* watchDocumentDetailAction() {
  yield takeLatest(
    getType(createAnalogDocument),
    function* ({ payload }: ActionType<typeof createAnalogDocument>) {
      yield put(
        dialogOpenAction({
          dialogProps: {
            onClose: payload.onClose,
            saveOnOpen: true,
            useAutoSave: true
          },
          dialogType: DialogType.AnalogDetails
        })
      );

      yield put(
        documentCreateActionType.request({
          nodeType: SpisumNodeTypes.Document
        })
      );

      const [successResponse, , success] = yield handleResponse(
        documentCreateActionType
      );

      if (!success) {
        Notification.error({
          message: t(translationPath(lang.dialog.notifications.actionFailed))
        });
        return;
      }

      yield put(
        metaFormAction__Update({
          documentId: successResponse.entry.id,
          formValues: {
            ...defaultAnalogValues(),
            ...successResponse.entry.properties.ssl
          },
          isLocked: successResponse.entry.isLocked,
          nodeType: successResponse.entry.nodeType
        })
      );
    }
  );

  yield takeLatest(
    getType(saveDocumentAction),
    function* ({ payload }: ActionType<typeof saveDocumentAction>) {
      yield put(
        documentRegisterActionType.request({
          body: {
            properties: payload.data as SslProperties
          },
          nodeId: payload.id
        })
      );

      const [, , success] = yield handleResponse(documentRegisterActionType);

      if (!success) {
        Notification.error({
          message: t(translationPath(lang.dialog.notifications.notRegistered))
        });
        return;
      }
      Notification.success({
        message: t(translationPath(lang.dialog.notifications.registered))
      });

      payload.onSuccess?.();
    }
  );
}
