import { FileSyncOutlined } from "@ant-design/icons";
import { useModal } from "@isfg/react-components/build/Modal/useModal";
import {
  Description,
  Eject,
  Gesture,
  Mail,
  NextWeek,
  WorkOff
} from "@mui/icons-material";
import BrightnessHighIcon from "@mui/icons-material/BrightnessHigh";
import GetAppIcon from "@mui/icons-material/GetApp";
import { tableOfContentsAction } from "presentation/core/api/node/_actions";
import { GenericDocument, genericDocumentProxy } from "presentation/core/types";
import { DocumentState, DocumentType, SenderType } from "presentation/enums";
import { ProcessDocumentModal } from "presentation/modules/evidence/features/documents/forProcessing/modal/ProcessDocumentModal";
import { RootStateType } from "presentation/reducers";
import {
  classPath,
  lastPathMember,
  translationPath
} from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import { default as React, useCallback, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { SortDirection } from "../../../../../govDesignSystem/lib/contract/Sorting";
import { DocumentsForSealModal } from "../../../../../modules/evidence/features/documents/forProcessing/modals/DocumentsForSealModal";
import {
  ConvertAnalogToDigitalModal,
  isDocumentAvailableForAnalogToDigitalConversion
} from "../../../../../share/components/modals/conversions/ConvertAnalogToDigitalModal";
import {
  ConvertDigitalToAnalogModal,
  isDocumentAvailableForDigitalToAnalogConversion
} from "../../../../../share/components/modals/conversions/ConvertDigitalToAnalogModal";
import { isEmptyString } from "../../../../../share/utils/utils";
import { validateItems } from "../../../../../share/utils/validation";
import { callAsyncAction } from "../../../../action";
import { openDocumentWithSaveButtonsAction } from "../../../../api/document/_actions";
import { exportFilePlanSummaryAction } from "../../../../api/fileplan/_actions";
import { FileMetaType } from "../../../../entities";
import { ControlsBarType } from "../../../dataTable/_types";
import { dialogOpenAction } from "../../_actions";
import { DialogTabContentPropsType, DialogType } from "../../_types";
import { getActiveComponentsForMainActions } from "../components/api";
import TableOfContentsTab from "./TableOfContentsTab";

type TableOfContentsTabContainerProps = DialogTabContentPropsType & {
  nodeId: string;
};

interface TableOfContentsTabContainerState {
  pageNumber: number;
  rowsPerPage: number;
}

const initialState: TableOfContentsTabContainerState = {
  pageNumber: 0,
  rowsPerPage: 50
};

const TableOfContentsTabContainer = ({
  channel,
  isActive,
  nodeId,
  dialogProps
}: TableOfContentsTabContainerProps) => {
  const [{ pageNumber, rowsPerPage }, setState] =
    useState<TableOfContentsTabContainerState>(initialState);
  const [wasLoaded, setWasLoaded] = useState<boolean>(false);
  const [processModal, processModalApi] = useModal(ProcessDocumentModal, {
    onClose: () => {
      loadData();
    }
  });
  const [modal, modalApi] = useModal(DocumentsForSealModal, {
    onOk: () => {
      loadData();
    }
  });
  const [convertDigitalToAnalogModal, convertDigitalToAnalogModalApi] =
    useModal(ConvertDigitalToAnalogModal);

  const { entities, totalItems, loading } = useSelector(
    (state: RootStateType) => {
      return {
        entities: state.tableOfContentsReducer.entities,
        loading: state.tableOfContentsReducer.loading,
        totalItems: state.tableOfContentsReducer.pagination?.totalItems || 0
      };
    },
    shallowEqual
  );

  const dispatch = useDispatch();
  const loadData = useCallback(
    () =>
      dispatch(
        tableOfContentsAction.request({
          maxItems: rowsPerPage,
          nodeId,
          skipCount: pageNumber * rowsPerPage
        })
      ),
    [pageNumber, rowsPerPage] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const [convertAnalogToDigitalModal, convertAnalogToDigitalModalApi] =
    useModal(ConvertAnalogToDigitalModal, {
      onOk: () => {
        loadData();
      }
    });

  channel.refreshData = loadData;

  useEffect(() => {
    if (isActive && !wasLoaded) {
      loadData();
      setWasLoaded(true);
    }
  }, [isActive]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isActive) {
      loadData();
    }
  }, [pageNumber, rowsPerPage]); // eslint-disable-line react-hooks/exhaustive-deps

  const isDocumentProcessed = (document: GenericDocument): boolean => {
    const pathName = document?.path?.name || "";
    return pathName.includes("/Documents/Processed");
  };
  const controls: ControlsBarType<GenericDocument> = {
    multi: {
      items: [
        {
          action: (selected: GenericDocument[]) => {
            dispatch(
              dialogOpenAction({
                dialogProps: {
                  data: {
                    id: nodeId,
                    selected
                  },
                  onClose: loadData
                },
                dialogType: DialogType.TakeOutFromFile
              })
            );
          },
          icon: <Eject />,
          title: t(translationPath(lang.general.takeOutFromFile)),
          validation: async (items: GenericDocument[]) => {
            for (let element of items) {
              if (
                element?.isFile &&
                element?.properties?.ssl?.state === DocumentState.Closed
              ) {
                return t(translationPath(lang._validations.forClosedFiles));
              } else if (
                element?.properties?.ssl?.forSealUser ||
                element?.properties?.ssl?.forSignatureUser
              ) {
                return t(translationPath(lang._validations.inSignatureBook));
              } else if (
                element?.properties?.ssl?.state === DocumentState.Settled
              ) {
                return t(translationPath(lang._validations.forClosedDocuments));
              }
            }
            return "";
          }
        },
        {
          action: (selected: GenericDocument[]) => {
            dispatch(
              callAsyncAction({
                action: exportFilePlanSummaryAction,
                payload: {
                  nodeId: nodeId,
                  body: {
                    documentIds: selected.map((x) => x.id)
                  }
                }
              })
            );
          },
          icon: <GetAppIcon />,
          title: t(translationPath(lang.general.exportToFilePlan))
        }
      ]
    },
    single: {
      items: [
        {
          action: (selected: GenericDocument[]) => {
            const isDocumentLocked = !!selected[0]?.properties?.cm?.lockType;
            dispatch(
              openDocumentWithSaveButtonsAction({
                data: selected[0],
                initiator: dialogProps?.initiator,
                onClose: loadData,
                isReadonly: isDocumentLocked
              })
            );
          },
          icon: <Description />,
          title: t(translationPath(lang.general.showDetails))
        },
        {
          action: (selected: GenericDocument[]) => {
            dispatch(
              callAsyncAction({
                action: exportFilePlanSummaryAction,
                payload: {
                  nodeId: nodeId,
                  body: {
                    documentIds: [selected[0]?.id]
                  }
                }
              })
            );
          },
          icon: <GetAppIcon />,
          title: t(translationPath(lang.general.exportToFilePlan))
        },
        {
          action: (selected: GenericDocument[]) => {
            dispatch(
              dialogOpenAction({
                dialogProps: {
                  data: selected[0],
                  onClose: loadData
                },
                dialogType: DialogType.ForSignature
              })
            );
          },

          filter: (document) =>
            (document?.properties?.ssl?.form === DocumentType.Digital ||
              document?.properties?.ssl?.form === DocumentType.AD ||
              document?.properties?.ssl?.form === DocumentType.DA) &&
            document?.properties?.ssl?.senderType === SenderType.Own,
          icon: <Gesture />,
          title: t(translationPath(lang.general.toSign)),
          validation: async (items: GenericDocument[]) => {
            if (
              items[0]?.isFile &&
              items[0]?.properties?.ssl?.state === DocumentState.Closed
            ) {
              return t(translationPath(lang._validations.forClosedFiles));
            } else if (
              items[0]?.properties?.ssl?.forSealUser ||
              items[0]?.properties?.ssl?.forSignatureUser
            ) {
              return t(translationPath(lang._validations.inSignatureBook));
            }

            let isValid = true;
            const checkTheComponent = await getActiveComponentsForMainActions(
              items[0]?.id,
              {
                page: 1,
                itemsPerPage: 100
              },
              [
                {
                  property: "name",
                  direction: SortDirection.Ascending
                }
              ]
            ).then((result) => {
              if (result.items.length > 0) {
                isValid = result.items.every(
                  (x) => x.isInOutputFormat !== "no"
                );
                const settle = result.items.filter(
                  (item) => item.type === FileMetaType.main && item.isReadable
                );

                if (settle.length > 0) {
                  return false;
                }
              }
              return true;
            });

            const validationMessages = [];

            if (!isValid) {
              validationMessages.push(
                t(translationPath(lang._validations.componentsNotInOutputMode))
              );
            }
            if (checkTheComponent) {
              validationMessages.push(
                t(translationPath(lang._validations.componentsForOwnSign))
              );
            }

            if (items[0]?.properties?.ssl?.state === DocumentState.Settled) {
              validationMessages.push(
                t(translationPath(lang._validations.forClosedDocuments))
              );
            }

            return validationMessages.join("\n");
          }
        },
        {
          action: (selected: GenericDocument[]) => {
            return modalApi.open({
              nodeId: selected[0].id!
            });
          },
          filter: (document) =>
            (document?.properties?.ssl?.form === DocumentType.Digital ||
              document?.properties?.ssl?.form === DocumentType.AD ||
              document?.properties?.ssl?.form === DocumentType.DA) &&
            document?.properties?.ssl?.senderType === SenderType.Own,
          icon: <BrightnessHighIcon />,
          title: t(translationPath(lang.general.forSeal)),
          validation: async (items: GenericDocument[]) => {
            if (
              items[0]?.isFile &&
              items[0]?.properties?.ssl?.state === DocumentState.Closed
            ) {
              return t(translationPath(lang._validations.forClosedFiles));
            } else if (
              items[0]?.properties?.ssl?.forSealUser ||
              items[0]?.properties?.ssl?.forSignatureUser
            ) {
              return t(translationPath(lang._validations.inSignatureBook));
            }

            validateItems(items, {
              [classPath(genericDocumentProxy.properties!.ssl!.pid).path]: yup
                .string()
                .required(t(translationPath(lang._validations.requiredPid))),
              [classPath(genericDocumentProxy.properties!.ssl!.ssid).path]: yup
                .string()
                .required(t(translationPath(lang._validations.requiredSsid))),
              [classPath(genericDocumentProxy.properties!.ssl!.subject).path]:
                yup
                  .string()
                  .required(
                    t(translationPath(lang._validations.requiredSubject))
                  ),
              [classPath(genericDocumentProxy.properties!.ssl!.senderFullName)
                .path]: yup
                .string()
                .test(
                  "oneOfRequired",
                  t(translationPath(lang._validations.requiredSender)),
                  function () {
                    return (
                      !isEmptyString(
                        this.parent[
                          lastPathMember(
                            genericDocumentProxy.properties!.ssl!
                              .senderCompanyName
                          ).path
                        ]
                      ) ||
                      !isEmptyString(
                        this.parent[
                          lastPathMember(
                            genericDocumentProxy.properties!.ssl!.senderFullName
                          ).path
                        ]
                      ) ||
                      !isEmptyString(
                        this.parent[
                          lastPathMember(
                            genericDocumentProxy.properties!.ssl!.senderType
                          ).path
                        ]
                      )
                    );
                  }
                ),
              ...(items.filter(
                (document) =>
                  document?.properties?.ssl?.form === DocumentType.Analog
              ).length === items.length && {
                [classPath(genericDocumentProxy.properties!.ssl!.listCount)
                  .path]: yup
                  .string()
                  .required(
                    t(translationPath(lang._validations.requiredListCount))
                  ),

                [classPath(
                  genericDocumentProxy.properties!.ssl!.listCountAttachments
                ).path]: yup
                  .string()
                  .required(
                    t(translationPath(lang._validations.listCountAttachments))
                  ),

                [classPath(
                  genericDocumentProxy.properties!.ssl!.attachmentsCount
                ).path]: yup
                  .string()
                  .required(
                    t(
                      translationPath(
                        lang._validations.requiredAttachmentsCount
                      )
                    )
                  )
              }),
              ...(items.filter(
                (document) =>
                  document?.properties?.ssl?.form === DocumentType.Digital
              ).length === items.length && {
                [classPath(
                  genericDocumentProxy.properties!.ssl!.associationCount
                ).path]: yup
                  .string()
                  .required(
                    t(
                      translationPath(
                        lang._validations.requiredAttachmentsCount
                      )
                    )
                  ),
                [classPath(
                  genericDocumentProxy.properties!.ssl!.attachmentsCount
                ).path]: yup
                  .string()
                  .required(
                    t(
                      translationPath(
                        lang._validations.requiredAttachmentsCount
                      )
                    )
                  )
              }),
              ...(items.filter(
                (document) =>
                  document?.properties?.ssl?.form === DocumentType.DA
              ).length === items.length && {
                [classPath(
                  genericDocumentProxy.properties!.ssl!.associationCount
                ).path]: yup
                  .string()
                  .required(
                    t(
                      translationPath(
                        lang._validations.requiredAttachmentsCount
                      )
                    )
                  ),
                [classPath(
                  genericDocumentProxy.properties!.ssl!.attachmentsCount
                ).path]: yup
                  .string()
                  .required(
                    t(
                      translationPath(
                        lang._validations.requiredAttachmentsCount
                      )
                    )
                  )
              }),
              ...(items.filter(
                (document) =>
                  document?.properties?.ssl?.form === DocumentType.AD
              ).length === items.length && {
                [classPath(
                  genericDocumentProxy.properties!.ssl!.associationCount
                ).path]: yup
                  .string()
                  .required(
                    t(
                      translationPath(
                        lang._validations.requiredAttachmentsCount
                      )
                    )
                  ),
                [classPath(
                  genericDocumentProxy.properties!.ssl!.attachmentsCount
                ).path]: yup
                  .string()
                  .required(
                    t(
                      translationPath(
                        lang._validations.requiredAttachmentsCount
                      )
                    )
                  )
              }),

              [classPath(genericDocumentProxy.properties!.cm!.owner!.id).path]:
                yup
                  .string()
                  .required(
                    t(translationPath(lang._validations.requiredOwner))
                  ),
              [classPath(genericDocumentProxy.properties!.ssl!.fileMark).path]:
                yup
                  .string()
                  .required(
                    t(translationPath(lang._validations.requiredFileMark))
                  ),
              [classPath(genericDocumentProxy.properties!.ssl!.filePlan).path]:
                yup
                  .string()
                  .required(
                    t(translationPath(lang._validations.requiredFilePlan))
                  ),
              [classPath(genericDocumentProxy.properties!.ssl!.retentionMode)
                .path]: yup
                .string()
                .required(
                  t(translationPath(lang._validations.requiredRetentionMode))
                ),
              [classPath(genericDocumentProxy.properties!.ssl!.form).path]: yup
                .string()
                .required(
                  t(translationPath(lang._validations.requiredFormDocument))
                )
            } as any);

            let isValid = true;
            const checkTheComponent = await getActiveComponentsForMainActions(
              items[0]?.id,
              {
                page: 1,
                itemsPerPage: 100
              },
              [
                {
                  property: "name",
                  direction: SortDirection.Ascending
                }
              ]
            ).then((result) => {
              if (result.items.length > 0) {
                isValid = result.items.every(
                  (x) => x.isInOutputFormat !== "no"
                );
                const settle = result.items.filter(
                  (item) => item.type === FileMetaType.main && item.isReadable
                );

                if (settle.length > 0) {
                  return false;
                }
              }
              return true;
            });

            const validationMessages = [];

            if (!isValid) {
              validationMessages.push(
                t(translationPath(lang._validations.componentsNotInOutputMode))
              );
            }
            if (checkTheComponent) {
              validationMessages.push(
                t(translationPath(lang._validations.componentsForOwnSeal))
              );
            }

            if (items[0]?.properties?.ssl?.state === DocumentState.Settled) {
              validationMessages.push(
                t(translationPath(lang._validations.forClosedDocuments))
              );
            }

            return validationMessages.join("\n");
          }
        },
        {
          action: (selected: GenericDocument[]) => {
            processModalApi.open({
              nodeId: selected[0].id
            });
          },
          filter: (document) => {
            return !isDocumentProcessed(document);
          },
          icon: <NextWeek />,
          title: t(translationPath(lang.general.settle)),
          validation: async (items: GenericDocument[]) => {
            if (items[0]?.properties?.ssl?.senderType === SenderType.Own) {
              const isComponent = await getActiveComponentsForMainActions(
                items[0]?.id,
                {
                  page: 1,
                  itemsPerPage: 100
                },
                [
                  {
                    property: "name",
                    direction: SortDirection.Ascending
                  }
                ]
              ).then((result) => result.items.length > 0);
              if (!isComponent) {
                return t(translationPath(lang._validations.requiredComponent));
              }
            }
            if (
              items.filter(
                (document) =>
                  document?.properties?.ssl?.form === DocumentType.DA
              ).length === items.length &&
              !items[0].properties!.ssl!.associationCount
            ) {
              return t(
                translationPath(lang._validations.requiredAttachmentsCount)
              );
            } else if (!items[0]?.properties?.cm?.owner?.id) {
              return t(translationPath(lang._validations.requiredOwner));
            } else if (!items[0]?.properties?.ssl?.fileMark) {
              return t(translationPath(lang._validations.requiredFileMark));
            } else if (!items[0]?.properties?.ssl?.filePlan) {
              return t(translationPath(lang._validations.requiredFilePlan));
            } else if (items[0]?.properties?.ssl?.fileIsEncrypted) {
              return t(
                translationPath(lang._validations.documentHasEncryptedComponent)
              );
            } else if (!items[0]?.properties?.ssl?.retentionMode) {
              return t(
                translationPath(lang._validations.requiredRetentionMode)
              );
            } else if (!items[0]?.properties?.ssl?.form) {
              return t(translationPath(lang._validations.requiredFormDocument));
            } else if (
              !!items[0]?.properties?.ssl?.forSealUser ||
              !!items[0]?.properties?.ssl?.forSignatureUser
            ) {
              return t(translationPath(lang._validations.inSignatureBook));
            } else {
              if (
                items[0]?.properties?.ssl?.documentType ===
                  DocumentType.Databox ||
                items[0]?.properties?.ssl?.documentType ===
                  DocumentType.TechnicalDataCarries
              ) {
                const checkTheComponent =
                  await getActiveComponentsForMainActions(
                    items[0]?.id,
                    {
                      page: 1,
                      itemsPerPage: 100
                    },
                    [
                      {
                        property: "name",
                        direction: SortDirection.Ascending
                      }
                    ]
                  ).then((result) => {
                    if (result.items.length > 0) {
                      const settle = result.items.filter(
                        (item) =>
                          item.type === FileMetaType.main && item.isReadable
                      );
                      if (settle.length > 0) {
                        return false; //t(translationPath(lang._validations.componentsForDatabox));
                      }
                    } else if (
                      result.items.length <= 0 &&
                      items[0]?.properties?.ssl?.documentType ===
                        DocumentType.TechnicalDataCarries
                    ) {
                      return false;
                    }
                    return true;
                  });
                if (checkTheComponent) {
                  if (
                    items[0]?.properties?.ssl?.documentType ===
                    DocumentType.Databox
                  ) {
                    return t(
                      translationPath(lang._validations.componentsForDatabox)
                    );
                  } else {
                    return t(
                      translationPath(
                        lang._validations.componentsForTechnicalDataCarrier
                      )
                    );
                  }
                } else {
                  return "";
                }
              } else {
                return "";
              }
            }
          }
        },
        {
          action: (selected: GenericDocument[]) => {
            dispatch(
              dialogOpenAction({
                dialogProps: {
                  data: selected[0],
                  onClose: loadData
                },
                dialogType: DialogType.CancelProcessing
              })
            );
          },
          filter: (document) => {
            return isDocumentProcessed(document);
          },
          icon: <WorkOff />,
          title: t(translationPath(lang.general.cancelProcessing)),
          validation: async (items: GenericDocument[]) => {
            if (
              items[0]?.isFile &&
              items[0]?.properties?.ssl?.state === DocumentState.Closed
            ) {
              return t(translationPath(lang._validations.forClosedFiles));
            } else if (
              items[0]?.properties?.ssl?.forSealUser ||
              items[0]?.properties?.ssl?.forSignatureUser
            ) {
              return t(translationPath(lang._validations.inSignatureBook));
            } else {
              return "";
            }
          }
        },
        {
          action: (selected: GenericDocument[]) => {
            dispatch(
              dialogOpenAction({
                dialogProps: {
                  data: selected[0],
                  onClose: loadData
                },
                dialogType: DialogType.SendShipment
              })
            );
          },
          icon: <Mail />,
          title: t(translationPath(lang.general.manageShipments)),
          validation: async (items: GenericDocument[]) => {
            if (
              items[0]?.isFile &&
              items[0]?.properties?.ssl?.state === DocumentState.Closed
            ) {
              return t(translationPath(lang._validations.forClosedFiles));
            } else if (
              items[0]?.properties?.ssl?.forSealUser ||
              items[0]?.properties?.ssl?.forSignatureUser
            ) {
              return t(translationPath(lang._validations.inSignatureBook));
            } else if (
              items[0]?.properties?.ssl?.state === DocumentState.Settled
            ) {
              return t(translationPath(lang._validations.forClosedDocuments));
            } else {
              return "";
            }
          }
        },
        {
          action: (selected: GenericDocument[]) => {
            convertDigitalToAnalogModalApi.open({
              nodeId: selected[0].id
            });
          },
          filter: (selected: GenericDocument) => {
            return isDocumentAvailableForDigitalToAnalogConversion(selected);
          },
          validation: (selected: GenericDocument[]) => {
            if (selected[0]?.properties?.ssl?.fileIsEncrypted) {
              return t(
                translationPath(lang._validations.documentHasEncryptedComponent)
              );
            }
            if (!selected[0]?.properties?.ssl?.fileMark) {
              return t(
                translationPath(
                  lang.dialog.convertions.digitalToAnalog.missingFilePlan
                )
              );
            }
            return null;
          },
          icon: (
            <FileSyncOutlined
              rev={"default"}
              style={{ fontSize: "16px" }}
              data-test-id="digital-to-analog"
            />
          ),
          title: t(translationPath(lang.general.convertDA))
        },
        {
          action: (selected: GenericDocument[]) => {
            convertAnalogToDigitalModalApi.open({
              nodeId: selected[0].id
            });
          },
          filter: (selected: GenericDocument) => {
            return isDocumentAvailableForAnalogToDigitalConversion(selected);
          },
          validation: (selected: GenericDocument[]) => {
            if (!selected[0]?.properties?.ssl?.fileMark) {
              return t(
                translationPath(
                  lang.dialog.convertions.analogToDigital.missingFilePlan
                )
              );
            }
            return null;
          },
          icon: (
            <FileSyncOutlined
              rev={"default"}
              style={{ fontSize: "16px" }}
              data-test-id="analog-to-digital"
            />
          ),
          title: t(translationPath(lang.general.convertAD))
        },
        {
          action: (selected: GenericDocument[]) => {
            dispatch(
              dialogOpenAction({
                dialogProps: {
                  data: { id: nodeId, selected },
                  onClose: loadData
                },
                dialogType: DialogType.TakeOutFromFile
              })
            );
          },
          icon: <Eject />,
          title: t(translationPath(lang.general.takeOutFromFile)),
          validation: async (items: GenericDocument[]) => {
            if (
              items[0]?.isFile &&
              items[0]?.properties?.ssl?.state === DocumentState.Closed
            ) {
              return t(translationPath(lang._validations.forClosedFiles));
            } else if (
              items[0]?.properties?.ssl?.forSealUser ||
              items[0]?.properties?.ssl?.forSignatureUser
            ) {
              return t(translationPath(lang._validations.inSignatureBook));
            } else if (
              items[0]?.properties?.ssl?.state === DocumentState.Settled
            ) {
              return t(translationPath(lang._validations.forClosedDocuments));
            } else {
              return "";
            }
          }
        }
      ]
    }
  };

  const handleChangePage: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => void = (_, page) => {
    setState((state) => ({
      pageNumber: page,
      rowsPerPage: state.rowsPerPage
    }));
  };

  const handleChangeRowsPerPage: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void = (event) => {
    setState(() => ({
      pageNumber: 0,
      rowsPerPage: parseInt(event.target.value, 10)
    }));
  };

  return (
    <>
      {modal}
      {processModal}
      {convertDigitalToAnalogModal}
      {convertAnalogToDigitalModal}
      <TableOfContentsTab
        controls={controls}
        items={entities}
        totalItems={totalItems}
        pageNumber={pageNumber}
        refreshTable={loadData}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        isLoading={!!loading}
      />
    </>
  );
};

export default TableOfContentsTabContainer;
