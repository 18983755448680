import {
  QueryFunction,
  QueryKey,
  RefetchOptions,
  useQuery,
  UseQueryOptions
} from "react-query";
import { useCallback, useEffect, useRef, useState } from "react";

// wrapper around useQuery, which adds some delay for the "refetch" function call.
// Useful when a backend is slower in returning fresh data after some mutation.
export const useDelayedQuery = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData
>(
  queryKey: QueryKey,
  refetchDelay = 1000,
  queryFn: QueryFunction<TQueryFnData>,
  options?: UseQueryOptions<TQueryFnData, TError, TData>
) => {
  // We use ref because we do not want our dealyedRefetch function
  // to be re-created every time isFetchingDelayed or isFetching changes.
  // We want to avoid infinite component re-renders: In some components
  // we have the useEffect hook which is calling the refetch function and it has the
  // refetch function in its dependencies. So every time we will call refetch will
  // lead to creation of new refetch function, which will trigger the useEffect hook,
  // which will call the refetch function and it will lead to an inifinite loop.
  const isFetchingRef = useRef(false);
  const [isFetchingDelayed, setIsFetchingDelayed] = useState(false);

  const { refetch, isFetching, ...rest } = useQuery(queryKey, queryFn, options);

  useEffect(() => {
    isFetchingRef.current = isFetchingDelayed || isFetching;
    // return () => {
    //   isFetchingRef.current = false;
    // };
  }, [isFetching, isFetchingDelayed]);

  const dealyedRefetch = useCallback(
    (options?: RefetchOptions | undefined) => {
      // do not fetch data, when we are already fetching
      if (isFetchingRef.current) {
        return;
      }

      setIsFetchingDelayed(true);

      return new Promise((resolve, reject) => {
        setTimeout(
          () =>
            refetch(options)
              .then(resolve)
              .catch(reject)
              .finally(() => {
                setIsFetchingDelayed(false);
                // if (isFetchingRef.current) {
                //   setIsFetchingDelayed(false);
                // }
              }),
          refetchDelay
        );
      });
    },
    [refetch, refetchDelay]
  );

  return {
    ...rest,
    isFetching: isFetchingDelayed || isFetching,
    refetch: dealyedRefetch
  };
};
