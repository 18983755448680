export enum ProcessMethod {
  ProcessMethod = "Způsob vyřízení"
}

export enum SettleMethod {
  dokumentem = "Dokumentem",
  zaznamemNaDokumentu = "Záznamem na dokumentu",
  postoupenim = "Postoupením",
  jinyZpusob = "Jiný způsob",
  vzetimNaVedomi = "Vzetím na vědomí"
}

export enum SettleMethodOther {
  odeslanim = "Odesláním",
  zverejnenim = "Zveřejněním"
}
