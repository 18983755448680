import React, { useCallback, useMemo } from "react";
import { DialogTabContentPropsType } from "../../_types";
import {
  ShipmentComponentTable,
  ShipmentComponentTableProps
} from "../../../../../modules/evidence/component/ShipmentComponentTable/ShipmentComponentTable";
import { ShipmentDocument } from "../../../../types";
import { ShipmentPost, SpisumNodeTypes } from "presentation/enums";

type OwnProps = DialogTabContentPropsType & {
  nodeId: string;
  isReadonly?: boolean;
};

const ShipmentComponentTabContainer = React.memo(
  ({ channel, dialogProps, isReadonly, nodeId }: OwnProps) => {
    const shipment = dialogProps.data as ShipmentDocument;
    const { documentId, id: shipmentId, nodeType, properties } = shipment;

    const internalState = properties?.ssl?.internalState;
    const shouldBeReadOnly =
      isReadonly ||
      internalState === ShipmentPost.ToDispatch ||
      internalState === ShipmentPost.Dispatched;

    const defaultComponentIds = useMemo(
      () => properties?.ssl?.components || [],
      [properties?.ssl?.components]
    );

    const onSelectionChanged: ShipmentComponentTableProps["onSelectionChanged"] = useCallback(
      (ids, validationResult) => {
        channel.setState({
          ...channel.state,
          selectedComponentsIds: ids,
          preventAction:
            validationResult.isWrongCountOfFiles || validationResult.isTooBig
        });
      },
      [channel]
    );

    const onPreviewClicked: ShipmentComponentTableProps["onPreviewClicked"] = useCallback(
      (component) => {
        channel.setPreviewItem(
          component.isEncrypted || false,
          {
            id: component.id,
            nodeType: SpisumNodeTypes.Shipment,
            entityId: nodeId,
            name: component.fileName,
            isEncrypted: component.isEncrypted
          },
          true
        );
      },
      [channel, nodeId]
    );

    return (
      <ShipmentComponentTable
        documentId={documentId!}
        shipmentId={shipmentId}
        shipmentType={nodeType}
        shipmentComponentsIds={defaultComponentIds}
        readonly={shouldBeReadOnly}
        onSelectionChanged={onSelectionChanged}
        onPreviewClicked={onPreviewClicked}
      />
    );
  }
);

export default ShipmentComponentTabContainer;
