import { Save } from "@mui/icons-material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import GetAppIcon from "@mui/icons-material/GetApp";
import Publish from "@mui/icons-material/Publish";
import moment from "moment";
import { Notification } from "presentation/designSystem/notification/Notification";
import { classPath, translationPath } from "presentation/share/utils/getPath";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { callAsyncAction } from "../../../../core/action";
import {
  exportToPDFFilePlanAction,
  getFilePlanAction,
  importFilePlanAction
} from "../../../../core/api/fileplan/_actions";
import { FILE_PLAN_ALLOWED_EXTENSIONS } from "../../../../core/api/fileplan/constants";
import {
  ControlsBarType,
  DataColumn
} from "../../../../core/components/dataTable/_types";
import { dialogOpenAction } from "../../../../core/components/dialog/_actions";
import { DialogType } from "../../../../core/components/dialog/_types";
import { ComponentUpload } from "../../../../core/components/dialog/tabs/components/ComponentUpload";
import DocumentView from "../../../../core/components/documentView";
import { documentViewAction__Refresh } from "../../../../core/components/documentView/_actions";
import MenuLayout from "../../../../core/components/layout/MenuLayout";
import { NotificationSeverity } from "../../../../core/components/notifications/_types";
import { FilePlan, sslFileProxy } from "../../../../core/types";
import { RootStateType } from "../../../../reducers";
import { lang, t } from "../../../../translation/i18n";
import { ErrorType } from "../../../../types";
import {
  hasLastItemValidToDate,
  returnNextAndPrevSelectedItem
} from "./helpers/helpers";

const defaultColumn: DataColumn<FilePlan> = {
  isDate: true,
  keys: [classPath(sslFileProxy.properties?.ssl?.validFrom).path],
  label: t(translationPath(lang.general.validFrom)),
  sortKeys: ["ssl:validFrom"]
};

const columns: DataColumn<FilePlan>[] = [
  {
    keys: [classPath(sslFileProxy.properties?.ssl?.pid).path],
    label: t(translationPath(lang.general.identifier))
  },
  {
    keys: [classPath(sslFileProxy.properties?.ssl?.name).path],
    label: t(translationPath(lang.general.name))
  },
  defaultColumn,
  {
    isDate: true,
    keys: [classPath(sslFileProxy.properties?.ssl?.validTo).path],
    label: t(translationPath(lang.general.validTo))
  }
];

const FilePlans = () => {
  const dispatch = useDispatch();

  const items = useSelector(
    (state: RootStateType) => state.documentViewReducer.items
  );

  const handleUploadComponent = useCallback(
    (files: globalThis.File[]) => {
      const onSuccess = () => {
        dispatch(documentViewAction__Refresh(true));
      };

      const onError = (error: ErrorType) => {
        if (error.code === "500" || error.code === "404") {
          Notification.error({
            message: t(translationPath(lang.dialog.notifications.actionFailed))
          });
          return;
        }

        dispatch(
          dialogOpenAction({
            dialogProps: { data: { error } },
            dialogType: DialogType.ErrorInImport
          })
        );
      };

      dispatch(
        callAsyncAction({
          action: importFilePlanAction,
          payload: {
            file: files[0]
          },
          onError,
          onSuccess,
          onSuccessNotification: {
            message: t(
              translationPath(
                lang.dialog.notifications.componentUploadWasSuccessful
              )
            ),
            severity: NotificationSeverity.Success
          },
          onErrorNotification: null
        })
      );
    },
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const controls: ControlsBarType<FilePlan> = {
    default: {
      items: [
        {
          filter: () =>
            !items.length || hasLastItemValidToDate(items as FilePlan[]),
          icon: () => (
            <ComponentUpload
              allowedExtensions={FILE_PLAN_ALLOWED_EXTENSIONS}
              multipleFilesAllowed={false}
              handleUploadComponent={handleUploadComponent}
              title={t(translationPath(lang.general.import))}
              icon={<Publish />}
            />
          )
        }
      ]
    },
    single: {
      items: [
        {
          action: (selected: FilePlan[]) => {
            dispatch(
              callAsyncAction({
                action: getFilePlanAction,
                payload: {
                  nodeId: selected[0].id
                }
              })
            );
          },
          icon: <GetAppIcon />,
          title: t(translationPath(lang.general.export))
        },
        {
          action: (selected: FilePlan[]) => {
            dispatch(
              callAsyncAction({
                action: exportToPDFFilePlanAction,
                payload: {
                  nodeId: selected[0].id
                }
              })
            );
          },
          icon: <Save />,
          title: t(translationPath(lang.general.exportToPDF))
        },
        {
          action: ([filePlan]: FilePlan[]) => {
            const { nextSelectedItem, previousSelectedItem } =
              returnNextAndPrevSelectedItem(items as FilePlan[], filePlan);

            dispatch(
              dialogOpenAction({
                dialogProps: {
                  data: filePlan,
                  nextItem: nextSelectedItem,
                  previousItem: previousSelectedItem
                },
                dialogType: DialogType.ChangeValidityDate
              })
            );
          },
          filter: (source) => !isDateNotValid(source),
          icon: <AccessTimeIcon />,
          title: t(translationPath(lang.general.editValidity))
        }
      ]
    }
  };

  const isDateNotValid = ({ properties }: FilePlan) => {
    const validDate = properties?.ssl?.validTo;
    if (validDate) {
      return moment(validDate).isSameOrBefore(
        moment(new Date()).subtract(1, "days"),
        "day"
      );
    }
    return false;
  };

  return (
    <MenuLayout>
      <DocumentView
        children={{
          relativePath: "Sites/Administration/documentLibrary/FilePlans"
        }}
        columns={columns}
        controls={controls}
        customTitle={t(translationPath(lang.menu.items.filePlans))}
        defaultSortColumn={defaultColumn}
        isDateNotValid={isDateNotValid}
      />
    </MenuLayout>
  );
};

export default FilePlans;
