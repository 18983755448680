import { FormInstance } from "antd";
import moment from "moment";
import { DatePicker } from "presentation/designSystem/DatePicker/DatePicker";
import { Form, Item } from "presentation/designSystem/Form/v2/Form";
import { Row } from "presentation/designSystem/Grid/Grid";
import { Input } from "presentation/designSystem/Input/Input";
import { lang, t } from "presentation/translation/i18n";
import styled from "styled-components";
import { DateTimeFormat } from "../../../../../../lib/dateTime";
import { emailValidation } from "../../../../../designSystem/Input/InputValidations";
import { translationPath } from "../../../../../share/utils/getPath";
import { Whitelist } from "../_types";
import React from "react";

interface AddEmailToWhitelistFormInterface {
  form: FormInstance;
  onChange: () => void;
  selectedWhiteList: Whitelist;
}

export const DetailWhitelistForm = ({
  form,
  onChange,
  selectedWhiteList
}: AddEmailToWhitelistFormInterface) => {
  const formInitial = {
    ...selectedWhiteList,
    createdAt: moment(selectedWhiteList.createdAt)
  };
  return (
    <Form name="detailWhitelistForm" initialValues={formInitial} form={form}>
      <StyledRow>
        <Item
          name="email"
          rules={[{ required: true }, { max: 50 }, emailValidation]}
          label={t(
            translationPath(lang.dialog.whitelistDialogs.detail.emailAddress)
          )}
        >
          <Input onChange={onChange} />
        </Item>
        <Item
          name="createdAt"
          label={t(
            translationPath(lang.dialog.whitelistDialogs.detail.createdAt)
          )}
        >
          <DatePicker disabled format={DateTimeFormat.FullDateTime} />
        </Item>
      </StyledRow>
      <Item
        name="comment"
        rules={[{ max: 100 }, { min: 4 }]}
        label={t(translationPath(lang.dialog.whitelistDialogs.detail.comment))}
      >
        <Input onChange={onChange} />
      </Item>
    </Form>
  );
};

const StyledRow = styled(Row)`
  display: flex;
  justify-content: space-between;
  .ant-row {
    width: 48%;
  }
`;
