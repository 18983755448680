import { PaginatedResult } from "../../../lib/contract/Pagination";
import { AlfrescoListResponseMapper } from "../mapper/AlfrescoListResponseMapper";
import { ApiAlfrescoListResponse, ApiTypes } from "../struct/ApiTypes";
import { Person } from "../../../domain/struct/nameRegistry/Person";
import { PersonApiMapper } from "./PersonApiMapper";

export type ApiSearchedSubject = Partial<
  ApiTypes["ISFG.SpisUm.ClientSide.Models.NameRegister.SubjectInfo"]
>;

export class SearchedSubjectPersonApiMapper {
  static mapFindAllResponse(
    response: ApiAlfrescoListResponse<ApiSearchedSubject>
  ): PaginatedResult<Person> {
    return AlfrescoListResponseMapper.mapListToPagination(
      response,
      PersonApiMapper.mapToDomain
    );
  }
}
