import { Button } from "presentation/designSystem/Button/Button";
import { Modal, ModalSize } from "presentation/designSystem/Modal/Modal";
import { BaseModalProps } from "presentation/designSystem/Modal/useModal";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import React, { useState } from "react";

interface UnsavedChangesModalProps extends BaseModalProps {
  saveChanges: (callback?: () => void) => Promise<void>;
  closeModal?: () => void;
}

const UnsavedChangesModal = ({
  onCancel,
  closeModal,
  saveChanges
}: UnsavedChangesModalProps) => {
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const onDontSave = () => {
    onCancel?.();
    closeModal?.();
  };
  const onSave = async () => {
    setIsSaveLoading(true);
    try {
      await saveChanges(() => {
        setIsSaveLoading(false);
        onCancel?.();
      });
    } catch {
      setIsSaveLoading(false);
    }
  };

  return (
    <Modal
      open={true}
      title={t(translationPath(lang.dialog.title.unsavedChanges))}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>
          {t(translationPath(lang.modal.cancel))}
        </Button>,
        <Button key="dontSave" danger onClick={onDontSave}>
          {t(translationPath(lang.dialog.form.dontSave))}
        </Button>,
        <Button
          key="save"
          type="primary"
          onClick={onSave}
          loading={isSaveLoading}
        >
          {t(translationPath(lang.dialog.form.save))}
        </Button>
      ]}
      size={ModalSize.Small}
    >
      <div style={{ padding: "10px 0" }}>
        {t(translationPath(lang.dialog.content.unsavedChanges))}
      </div>
    </Modal>
  );
};

export default UnsavedChangesModal;
