import MomentUtils from "@date-io/moment";
import EventIcon from "@mui/icons-material/Event";
import { DatePickerProps } from "@mui/lab";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { useField, useFormikContext } from "formik";
import { DatepickerValueType } from "presentation/core/components/datepicker/_types";
import { translationPath } from "presentation/share/utils/getPath";
import { t } from "presentation/translation/i18n";
import lang from "presentation/translation/lang";
import React, { useEffect, useState } from "react";
import { DateTimeFormats } from "../../../enums";
import { StyledKeyboardDatePicker } from "./DatePicker.styles";

const format: string = DateTimeFormats.Date;

interface OwnProps {
  className?: string;
  component?: React.FC<DatePickerProps<any>>;
  disabled?: boolean;
  disableFuture?: boolean;
  disablePast?: boolean;
  format?: string;
  label: string;
  name: string;
  onDateChange?: (value: DatepickerValueType) => void;
  required?: boolean;
  showCalendarIcon?: boolean;
  style?: object;
  shouldDisableDate?: (date: DatepickerValueType) => boolean;
  value?: any;
}

const DatePicker = ({
  component: Com = StyledKeyboardDatePicker,
  disabled: disabledProp,
  ...props
}: OwnProps) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>("");
  const [disabled, setDisabled] = useState<boolean | undefined>(disabledProp);
  const { setFieldValue, errors, isSubmitting } = useFormikContext();
  const { onDateChange, showCalendarIcon = true, ...fieldProps } = props;
  const [field] = useField(fieldProps);
  const hideIcon = disabled || !showCalendarIcon;

  const handleOnChange = (value: DatepickerValueType) => {
    setFieldValue(field.name, value);
    if (onDateChange) {
      onDateChange(value);
    }
  };

  useEffect(() => {
    setDisabled(disabledProp || isSubmitting);
    setErrorMessage(errors[field.name as keyof typeof errors]);
  }, [errors, field, isSubmitting]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <LocalizationProvider utils={MomentUtils}>
      <Com
        format={props.format || format}
        disabled={disabled}
        margin="none"
        onChange={handleOnChange}
        value={field.value}
        helperText={errorMessage}
        error={!!errorMessage}
        required={props.required}
        okLabel={t(translationPath(lang.dialog.buttons.confirm))}
        cancelLabel={t(translationPath(lang.modal.cancel))}
        keyboardIcon={hideIcon ? <></> : <EventIcon />}
        {...fieldProps}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
