import React, { useCallback } from "react";
import {
  ColumnType,
  TableRowSelection
} from "../../../../../designSystem/Table/Table";
import { Person } from "../../../../../../domain/struct/nameRegistry/Person";
import { LegalEntity } from "../../../../../../domain/struct/nameRegistry/LegalEntity";
import { createColumn } from "../../../../../designSystem/Table/column/column";
import { lang, t } from "../../../../../translation/i18n";
import { translationPath } from "../../../../../share/utils/getPath";
import {
  GetRemoteDataClb,
  RemoteTable
} from "../../../../../designSystem/Table/RemoteTable";
import { TableActions } from "../../../../../designSystem/Table/Table";
import { useFindSearchedSubjectsLegalEntities } from "../../../hooks/useFindSearchedSubject";

interface SearchSubjectLegalEntityTableProps {
  actions: TableActions<Person | LegalEntity> | undefined;
  remoteQueryParams: Record<string, string> | undefined;
  onRowSelection: TableRowSelection<Person | LegalEntity> | undefined;
}

const COLUMNS: ColumnType<LegalEntity>[] = [
  createColumn({
    dataIndex: "fullName",
    title: t(translationPath(lang.table.searchSubjectTable.nameOfSubject))
  }),
  createColumn({
    dataIndex: "companyFullName",
    title: t(translationPath(lang.table.searchSubjectTable.nameOfOrganization))
  }),
  createColumn({
    dataIndex: "identificationNumber",
    title: t(
      translationPath(lang.table.searchSubjectTable.identificationNumber)
    )
  })
];

export const SearchSubjectLegalEntityTable = ({
  actions,
  remoteQueryParams,
  onRowSelection
}: SearchSubjectLegalEntityTableProps) => {
  const findLegalEntities = useFindSearchedSubjectsLegalEntities();

  const getRemoteDataLegalEntities: GetRemoteDataClb<LegalEntity> = useCallback(
    ({ pagination, params }) => findLegalEntities(pagination, params),
    [findLegalEntities]
  );

  return (
    <RemoteTable<Person | LegalEntity>
      name="nameRegistry/searchedSubjectTable"
      actions={actions}
      columns={COLUMNS}
      getRemoteData={getRemoteDataLegalEntities}
      remoteQueryParams={remoteQueryParams}
      rowSelection={onRowSelection}
      rowKey="id"
    />
  );
};
