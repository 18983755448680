import { PaginatedResult } from "../../../lib/contract/Pagination";
import { ApiTypes } from "../struct/ApiTypes";
import { CreatePayload } from "../../../domain/service/nameRegistry/LegalEntityRepository";
import { components } from "../generated/SpisumApiTypes";
import { LegalEntity } from "../../../domain/struct/nameRegistry/LegalEntity";
import { ContactApiMapper } from "./ContactApiMapper";
import { AddressApiMapper } from "./AddressApiMapper";
import { SubjectApiMapper } from "./SubjectApiMapper";
import { ApiListResponse } from "../struct/ApiTypesV2";
import { ApiListResponseMapper } from "../mapper/ApiListResponseMapper";

export type ApiLegalEntity = ApiTypes["ISFG.SpisUm.ClientSide.Models.NameRegister.SubjectInfo"];
export type ApiCreateLegalEntity = components["schemas"]["ISFG.SpisUm.ClientSide.Models.NameRegister.Query.LegalSubjectCreate"];

export class LegalEntityApiMapper {
  static mapFindAllResponse(
    response: ApiListResponse<ApiLegalEntity>
  ): PaginatedResult<LegalEntity> {
    return ApiListResponseMapper.mapListToPagination(
      response,
      LegalEntityApiMapper.mapToDomain
    );
  }

  static mapToDomain(apiLegalEntity: ApiLegalEntity): LegalEntity {
    return SubjectApiMapper.mapToLegalEntity(apiLegalEntity);
  }

  static mapCreateRequest({
    legalEntity,
    addresses,
    contacts
  }: CreatePayload): ApiCreateLegalEntity {
    return {
      companyName: legalEntity.companyFullName,
      identificationNumber: legalEntity.identificationNumber,
      vatIdentificationNumber: legalEntity.taxIdentificationNumber,
      addresses: AddressApiMapper.mapAddressesToApi(addresses),
      contacts: ContactApiMapper.mapContactsToApi(contacts)
    };
  }

  static mapUpdateRequest(legalEntity: LegalEntity) {
    return {
      companyName: legalEntity.companyFullName,
      identificationNumber: legalEntity.identificationNumber,
      vatIdentificationNumber: legalEntity.taxIdentificationNumber
    };
  }
}
