import { getService } from "../../../../core/features/dependencyInjection";
import { useCallback } from "react";
import { PaginationConfig } from "lib/contract/Pagination";
import { FileId } from "../../../../../domain/struct/file/File";
import { FindFileHistory } from "../../../../../useCase/file/FindFileHistory";
import { SortingConfig } from "lib/contract/Sorting";

export const useFileHistory = (id: FileId) => {
  const findHistory = getService(FindFileHistory);

  return useCallback(
    (paginationConfig: PaginationConfig, sortingConfig?: SortingConfig) =>
      findHistory.findPaginated(id, paginationConfig, sortingConfig),
    [findHistory, id]
  );
};
