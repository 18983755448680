import {
  DocumentConcernedSubject,
  DocumentSubjectRelationType
} from "domain/struct/document/DocumentConcernedSubject";
import { Subject } from "domain/struct/nameRegistry/Subject";
import { ApiDocumentConcernedSubject } from "infrastructure/api/document/DocumentConcernedSubjectApiMapper";
import { AlfrescoListResponseMapper } from "infrastructure/api/mapper/AlfrescoListResponseMapper";
import {
  ApiSubject,
  SubjectApiMapper
} from "infrastructure/api/nameRegistry/SubjectApiMapper";
import { ApiAlfrescoListResponse } from "infrastructure/api/struct/ApiTypes";
import { PaginatedResult } from "lib/contract/Pagination";

export const mapFindAll = (
  response: ApiAlfrescoListResponse<ApiDocumentConcernedSubject>
): PaginatedResult<DocumentConcernedSubject> => {
  return AlfrescoListResponseMapper.mapListToPagination(response, mapToDomain);
};

export const mapToDomain = (
  apiDocumentConcernedSubject: ApiDocumentConcernedSubject
): DocumentConcernedSubject => {
  return {
    id: apiDocumentConcernedSubject.relationId,
    documentId: apiDocumentConcernedSubject.documentId || undefined,
    relationType:
      ((apiDocumentConcernedSubject.relationType as unknown) as DocumentSubjectRelationType) ||
      undefined,
    ...(apiDocumentConcernedSubject.subject && {
      subject: mapSubjectToDomain(apiDocumentConcernedSubject.subject)
    })
  };
};

export const mapSubjectToDomain = (subject: ApiSubject): Subject => {
  return SubjectApiMapper.mapToDomain(subject);
};
