import { componentDownloadAction } from "presentation/core/api/components/_actions";
import {
  DataColumn,
  ValueType
} from "presentation/core/components/dataTable/_types";
import { GenericDocument } from "presentation/core/types";
import { DocumentType, SenderType, SpisumNodeTypes } from "presentation/enums";
import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStateType } from "presentation/reducers";
import { classPath, translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import { callAsyncAction } from "../../../../action";
import { ComponentType } from "../../../../api/components/_types";
import { SslProperties } from "../../../../api/models";
import { File, FileMetaType, fileProxy } from "../../../../entities";
import { Notification } from "presentation/designSystem/notification/Notification";
import { DialogDataGenericData, DialogDataProps } from "../../_types";
import InactiveComponentsTab, {
  ChangePageFnType,
  ChangeRowsPerPageFnType,
  SortChangeFnType
} from "./InactiveComponentsTab";
import { SelectedComponentsFnType } from "./_types";
import { DocumentStateType } from "presentation/core/components/MetaForm/_types";
import { PaginatedResult } from "lib/contract/Pagination";
import { useMutation } from "../../../../../share/hook/query/useMutation";
import { getErrorCodeTranslation } from "../../../../../share/utils/errorCodeTranslation";
import { useUpdateActiveComponents } from "../components/hooks/useUpdateActiveComponents";
import { ActiveComponent } from "../components/_types";
import { useQueryClient } from "../../../../../govDesignSystem/Table/hook/query/useQuery";
import { postActivateComponents } from "./api";
import { INACTIVE_DOCUMENTS_QUERY_KEY } from "./hooks/useGetInactiveComponents";
import { ACTIVE_DOCUMENTS_QUERY_KEY } from "../components/hooks/useGetActiveComponents";

export interface ComponentsTabContainerState {
  pageNumber: number;
  rowsPerPage: number;
  sortAsc?: boolean;
  sortColumnIndex?: number;
  sortKeys?: string[];
}

export type ComponentsV2TabContainerProps = {
  nodeId: string;
  data?: PaginatedResult<File>;
  isFetching?: boolean;
  isReadonly?: boolean;
  componentType?: ComponentType;
  dialogProps?: DialogDataProps;
  isActive?: boolean;
  documentId?: string;
  fetchComponents(): void;
  handleChangePage: ChangePageFnType;
  handleChangeRowsPerPage: ChangeRowsPerPageFnType;
  handleSortingChange: SortChangeFnType;
  handleDeleteComponent?: SelectedComponentsFnType;
  metaFormDocument: DocumentStateType;
  pageNumber: number;
  rowsPerPage: number;
  sortAsc?: boolean;
  sortColumnIndex?: number;
  sortKeys?: string[];
  showPreview?: boolean;
  previewItem?: any;
  setPreviewItem?(previewItem: any): void;
  setShowPreview?(showPreview: boolean): void;
};

const InactiveComponentsV2TabContainer = React.memo(
  ({
    componentType = ComponentType.Document,
    dialogProps,
    isReadonly,
    nodeId,
    data,
    isFetching,
    isActive,
    documentId,
    fetchComponents,
    handleChangePage,
    handleChangeRowsPerPage,
    handleSortingChange,
    handleDeleteComponent,
    metaFormDocument,
    pageNumber,
    rowsPerPage,
    sortAsc,
    sortColumnIndex,
    sortKeys,
    showPreview,
    previewItem,
    setPreviewItem,
    setShowPreview
  }: ComponentsV2TabContainerProps) => {
    const dispatch = useDispatch();

    const { error } = useSelector(
      (state: RootStateType) => state.componentsReducer
    );
    const nodeType =
      (dialogProps?.data as DialogDataGenericData)?.nodeType ||
      metaFormDocument?.nodeType;

    const sortedComponents = useMemo(() => {
      return data ? data.items : [];
    }, [data, sortKeys]);

    const getNotificationForError = (error: any) => ({
      message: getErrorCodeTranslation(error.code)
    });
    const updateInActiveComponents = useUpdateActiveComponents();
    const { isLoading, mutate: updatingActiveComponent } = useMutation(
      (body: ActiveComponent) => updateInActiveComponents(nodeId, body),
      {
        onSuccess() {
          fetchComponents();
        },
        onSuccessNotification: null,
        onErrorNotification: getNotificationForError
      }
    );
    const queryClient = useQueryClient();
    const { mutate: activateComponents } = useMutation(
      (components: File[]) => postActivateComponents(nodeId, components),
      {
        onSuccess() {
          queryClient.invalidateQueries(INACTIVE_DOCUMENTS_QUERY_KEY);
          queryClient.invalidateQueries(ACTIVE_DOCUMENTS_QUERY_KEY);
        },
        onSuccessNotification: {
          message: t(
            translationPath(lang.dialog.notifications.componentsActivated)
          )
        },
        onErrorNotification: {
          message: t(
            translationPath(
              lang.dialog.notifications.componentsActivationFailed
            )
          )
        }
      }
    );
    useEffect(() => {
      // channel.refreshData = fetchComponents;
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      fetchComponents();
    }, [fetchComponents]);

    useEffect(() => {
      if (
        !sortedComponents.length ||
        !handleCanShowPreview(sortedComponents[0])
      ) {
        return;
      }
    }, [nodeId, sortedComponents]);

    const handleDownloadComponent = useCallback(
      (selectedComponents: File[]) => {
        Notification.success({
          message: t(
            translationPath(
              lang.dialog.notifications.componentDownloadIsPreparing
            )
          )
        });

        dispatch(
          callAsyncAction({
            action: componentDownloadAction,
            payload: {
              componentIds: selectedComponents.map((c) => c.id),
              nodeId,
              nodeType
            }
          })
        );
      },
      [dispatch, nodeId, nodeType]
    );

    const handleColumnChange = useCallback(
      (row: File, column: DataColumn<File>, value: ValueType) => {
        let updateComponentObject = {
          [column.keys[0]]: value,
          componentId: row.id
        };
        if (column.keys[0] === classPath(fileProxy.type).path) {
          updateComponentObject = {
            ...updateComponentObject,
            isReadable: row.isReadable
          };
        } else {
          updateComponentObject = { ...updateComponentObject, type: row.type };
        }
        updatingActiveComponent(updateComponentObject);
      },
      [dispatch, metaFormDocument.documentId, metaFormDocument.nodeType]
    );
    const showActions = () => {
      if (isReadonly || dialogProps?.canUploadComponents === false) {
        return false;
      }

      const document = dialogProps?.data as GenericDocument;
      const nodeType = document.nodeType;

      const { isLocked, formValues } = metaFormDocument;
      const typedFormValues = formValues as SslProperties;
      const form = document?.properties?.ssl?.form || typedFormValues?.form;
      const senderType =
        document?.properties?.ssl?.senderType || typedFormValues?.senderType;
      const documentType =
        document?.properties?.ssl?.documentType ||
        typedFormValues?.documentType;
      const documentIsLocked = document?.isLocked || isLocked;

      return (
        (form === DocumentType.Analog ||
          nodeType === SpisumNodeTypes.Concept ||
          (form === DocumentType.Digital &&
            (senderType === SenderType.Own ||
              documentType === DocumentType.TechnicalDataCarries))) &&
        !documentIsLocked
      );
    };

    const handleCanActivateComponent = (file: File) =>
      file?.type !== FileMetaType.main ? true : false;

    const handleCanShowPreview = (component: File) => {
      // filter by extensions
      return true;
    };

    const handleShowPreview = useCallback(
      (selected: File[]) => {
        if (selected[0]) {
          if (!previewItem?.id) {
            setPreviewItem?.({
              id: selected[0].id,
              name: selected[0].name,
              nodeType: nodeType,
              entityId: nodeId,
              fileIsEncrypted: selected[0].isEncrypted
            });

            setShowPreview?.(true);
          } else if (previewItem.id !== selected[0].id) {
            setPreviewItem?.({
              id: selected[0].id,
              name: selected[0].name,
              nodeType: nodeType,
              entityId: nodeId,
              fileIsEncrypted: selected[0].isEncrypted
            });
          } else {
            setShowPreview?.(false);
            setPreviewItem?.({
              id: "",
              name: selected[0].name,
              nodeType: nodeType,
              entityId: nodeId,
              fileIsEncrypted: selected[0].isEncrypted
            });
          }
        }
      },
      [nodeId, nodeType, setPreviewItem, setShowPreview, previewItem.id]
    );

    const handleSelectionChange = useCallback(
      (selected: File[]) => {
        if (!selected[0] || !handleCanShowPreview(selected[0])) {
          return;
        }
        if (showPreview && previewItem.id !== selected[0].id) {
          setPreviewItem?.({
            id: selected[0].id,
            name: selected[0].name,
            nodeType: nodeType,
            entityId: nodeId,
            fileIsEncrypted: selected[0].isEncrypted
          });
        }
      },
      [nodeId, nodeType, previewItem.id, setPreviewItem, showPreview]
    );

    const handleActivateComponent = useCallback(
      (selectedComponents: File[]) => {
        activateComponents(selectedComponents);
      },

      [
        dispatch,
        metaFormDocument.documentId,
        metaFormDocument.nodeType,
        nodeId,
        nodeType
      ]
    );
    if (error) {
      return <div />;
    }

    return (
      <InactiveComponentsTab
        handleCanShowPreview={handleCanShowPreview}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleColumnChange={handleColumnChange}
        handleDownloadComponent={handleDownloadComponent}
        handleSelectionChange={handleSelectionChange}
        handleShowPreview={handleShowPreview}
        handleSortingChange={handleSortingChange}
        handleActivateComponent={handleActivateComponent}
        handleCanActivateComponent={handleCanActivateComponent}
        isLoading={isFetching || isLoading}
        isReadonly={isReadonly || !!dialogProps?.isReadonly}
        items={sortedComponents}
        pageNumber={data ? data.page - 1 : 0}
        refreshTable={fetchComponents}
        rowsPerPage={data ? data.itemsPerPage : 10}
        sortAsc={sortAsc}
        sortColumnIndex={sortColumnIndex}
        totalItems={data?.total || 0}
      />
    );
  }
);

export default InactiveComponentsV2TabContainer;
