import { callAsyncAction } from "presentation/core/action";
import { conceptUpdateActionType } from "presentation/core/api/concept/_actions";
import {
  NodeChildAssociationEntry,
  SslConcept,
  SslProperties
} from "presentation/core/api/models";
import { secondaryAction } from "presentation/core/components/dialog/lib/actionsFactory";
import { CommentsTab } from "presentation/core/components/dialog/tabs/comments";
import { HistoryTab } from "presentation/core/components/dialog/tabs/history";
import { VersionTab } from "presentation/core/components/dialog/tabs/version";
import { DialogType } from "presentation/core/components/dialog/_types";
import { documentViewAction__UpdateItem } from "presentation/core/components/documentView/_actions";
import NamedTitle from "presentation/core/components/namedTitle";
import React from "react";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import { createDocumentDialog } from "../baseDocumentDialog/documentDialogFactory";
import { ConceptComponentsTab } from "../createConceptDialog/ConceptComponentsTab";
import { MetadataFormTab } from "./MetadataFormTab";
import { conceptConcernedSubjectTab } from "../../dialogTabs/ConcernedSubject";

export const conceptDetailsDialogContent = createDocumentDialog({
  actions: () => [
    secondaryAction(
      t(translationPath(lang.dialog.form.save)),
      ({ dispatch, dialogProps, onClose, buttonState, channels }) => {
        const formValues = channels?.Metadata?.state?.formValues;
        if (!formValues) {
          return;
        }

        const onSuccess = (response: NodeChildAssociationEntry<SslConcept>) => {
          for (const key in channels) {
            if (!channels.hasOwnProperty(key)) continue;
            channels[key].setIsSaved(true);
          }
          buttonState.setIsPending(false);
          dispatch(documentViewAction__UpdateItem(response));
          dialogProps.onSuccess?.();
          onClose();
        };

        const onError = () => {
          buttonState.setIsPending(false);
          dialogProps.onError?.();
        };

        buttonState.setIsPending(true);

        dispatch(
          callAsyncAction({
            action: conceptUpdateActionType,
            onError,
            onSuccess,
            payload: {
              body: formValues as SslProperties,
              nodeId: channels.Metadata?.state?.id
            }
          })
        );
      }
    )
  ],
  tabs: [
    {
      content: MetadataFormTab,
      label: t(translationPath(lang.dialog.tabs.metadata))
    },
    {
      content: ConceptComponentsTab,
      label: t(translationPath(lang.dialog.tabs.components))
    },
    conceptConcernedSubjectTab,
    {
      content: VersionTab,
      label: t(translationPath(lang.dialog.tabs.version))
    },
    {
      content: CommentsTab,
      label: t(translationPath(lang.dialog.tabs.notes))
    },
    {
      content: HistoryTab,
      label: t(translationPath(lang.dialog.tabs.history))
    }
  ],
  title: (props) => (
    <NamedTitle
      text={t(translationPath(lang.dialog.title.conceptDetails))}
      {...props}
    />
  ),
  type: DialogType.ConceptDetails
});
