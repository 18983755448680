import { ConceptConcernedSubjectRepositoryToken } from "../../../domain/di/token/document";
import type { ConceptConcernedSubjectRepository } from "../../../domain/service/document/ConceptConcernedSubjectRepository";
import { SubjectId } from "../../../domain/struct/nameRegistry/Subject";
import {
  DInject,
  DInjectable
} from "../../../presentation/core/features/dependencyInjection";

@DInjectable()
export class RemoveConceptConcernedSubject {
  constructor(
    @DInject(ConceptConcernedSubjectRepositoryToken)
    protected repository: ConceptConcernedSubjectRepository
  ) {}

  removeConcernedSubjects(conceptId: string, subjectIds: SubjectId[]) {
    return this.repository.removeConcernedSubjects(conceptId, subjectIds);
  }
}
