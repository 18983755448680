import { SslProperties } from "../../../../core/api/models";
import React from "react";
import { SenderType } from "../../../../enums";

interface DisplaySenderNameByTypeProps {
  ssl?: SslProperties;
}

export const DisplaySenderNameByType: React.FC<DisplaySenderNameByTypeProps> = ({
  ssl
}) => {
  const { senderType, senderCompanyName, senderFullName } = ssl || {};
  if (senderType === SenderType.Legal) {
    return <>{senderCompanyName}</>;
  }
  if (senderType === SenderType.Individual) {
    return <>{senderFullName}</>;
  }
  if (senderType === SenderType.Own) {
    return <>{"Vlastní"}</>;
  }
  return <></>;
};
