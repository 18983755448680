import {
  HttpClient,
  HttpMethod
} from "../../../../../../../infrastructure/service/HttpClient";

import {
  DocumentForSealForm,
  FileTypeStructure,
  GroupsToSeal,
  TypeFileField,
  TypeFilesResponse
} from "../_types";
import { ApiUrls } from "infrastructure/api/struct/ApiTypesV2";
import {
  ApiGroupsAndUsersForSealResponse,
  GroupApiMapper,
  mapApiToOtherMethods,
  mapTypeFileToDomain
} from "./mappers";
import {
  PaginatedResult,
  PaginationConfig
} from "@isfg/lib/build/contract/Pagination";
import { SortingConfig } from "@isfg/lib/build/contract/Sorting";
import {
  CodeListType,
  CodeListValueResponse
} from "presentation/modules/administration/features/codeList/types";

const OTHER_SETTLE_METHOD: CodeListType = "OtherSettleMethod";

const URL: ApiUrls = {
  GetGroupsToSeal: "/api/app/v2/group/seal-groups",
  GetUserSealGroups: "/api/app/v2/group/seal-group/{groupId}/users",
  PostDocumentForSeal: "/api/app/v2/document/{nodeId}/for-seal",
  FindTypeFile: "/api/app/v2/type-file/search",
  GetTypeFileStructure: "/api/app/v2/type-file/{nodeId}/structure",
  AddTypeFile: "/api/app/v2/type-file/{nodeId}/add",
  GetCodeList: "/api/app/v2/codelists/{codeList}"
};

export const getGroupsToSeal = (): Promise<GroupsToSeal[]> => {
  const httpClient = new HttpClient();
  return httpClient
    .fetchWithThrow<ApiGroupsAndUsersForSealResponse[]>(URL.GetGroupsToSeal)
    .then(GroupApiMapper.mapUsersAndGroupsToDomain);
};

export const getSealGroupsUsers = (
  groupId: string
): Promise<GroupsToSeal[]> => {
  const httpClient = new HttpClient();
  return httpClient
    .fetchWithThrow<ApiGroupsAndUsersForSealResponse[]>(
      URL.GetUserSealGroups,
      HttpMethod.Get,
      {
        urlWildCards: {
          groupId
        }
      }
    )
    .then(GroupApiMapper.mapUsersAndGroupsToDomain);
};

export const postDocumentForSeal = (
  nodeId: string,
  body: DocumentForSealForm
): Promise<void> => {
  const httpClient = new HttpClient();
  return httpClient.fetchWithThrow(URL.PostDocumentForSeal, HttpMethod.Post, {
    bodyJSON: body,
    urlWildCards: {
      nodeId: nodeId
    }
  });
};

// find type file
export const getFileType = (
  keyword: string,
  pagination: PaginationConfig,
  sorting?: SortingConfig
): Promise<PaginatedResult<TypeFilesResponse>> => {
  const httpClient = new HttpClient();
  return httpClient
    .fetchPaginated<TypeFilesResponse>({
      url: URL.FindTypeFile,
      pagination,
      sorting,
      config: {
        params: {
          keyword
        }
      }
    })
    .then(mapTypeFileToDomain);
};

// get FileType structure
export const getTypeFileStructure = (
  nodeId: string
): Promise<FileTypeStructure[]> => {
  const httpClient = new HttpClient();

  return httpClient.fetchWithThrow<FileTypeStructure[]>(
    URL.GetTypeFileStructure,
    "GET",
    {
      urlWildCards: {
        nodeId
      }
    }
  );
};

// add type file
export const addTypeFile = (id: string, input: TypeFileField) => {
  const httpClient = new HttpClient();
  return httpClient.fetchWithThrow<void>(URL.AddTypeFile, "POST", {
    bodyJSON: input,
    urlWildCards: {
      nodeId: id
    }
  });
};

export const getOtherSettleMethods = () => {
  const httpClient = new HttpClient();

  return httpClient
    .fetchWithThrow<CodeListValueResponse[]>(URL.GetCodeList, "GET", {
      urlWildCards: {
        codeList: OTHER_SETTLE_METHOD
      }
    })
    .then(mapApiToOtherMethods);
};
