import { callAsyncAction } from "presentation/core/action";
import { secondaryAction } from "presentation/core/components/dialog/lib/actionsFactory";
import {
  DialogContentType,
  DialogType
} from "presentation/core/components/dialog/_types";
import NamedTitle from "presentation/core/components/namedTitle";
import React from "react";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import { SearchSubjectContainer } from "./SearchSubjectContainer";
import { sendShipmentAction } from "./_actions";

export const searchSubjectDialogContent: DialogContentType = {
  actions: () => [
    secondaryAction(
      t(translationPath(lang.dialog.buttons.confirm)),
      ({ dialogProps, channels, buttonState, onClose, dispatch }) => {
        buttonState.setIsPending(true);
        const onError = () => {
          buttonState.setIsPending(false);
        };

        const onSuccess = () => {
          onClose();
        };

        dispatch(
          callAsyncAction({
            action: sendShipmentAction,
            onError,
            onSuccess,
            payload: {
              // nodeId: (dialogProps.data as DialogDataGenericData).id,
              // shipmentType: (dialogProps.data as DialogDataGenericData)
              //   .nodeType,
              // shipmentsId:
              //   channels?.contentTab?.state?.selectedComponentsIds || []
            }
          })
        );
      }
    )
  ],
  content: SearchSubjectContainer,
  title: (props) => (
    <NamedTitle
      text={t(translationPath(lang.dialog.title.handoverShipmentsToDispatch))}
      {...props}
    />
  ),
  type: DialogType.SearchSubject
};
