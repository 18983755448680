import { RentedEvidenceDocumentApiRepository } from "../../infrastructure/api/evidence/RentedEvidenceDocumentApiRepository";
import { RentedDocumentEvidenceRepositoryToken } from "../../domain/di/token/evidence";
import { FindRentedEvidenceDocuments } from "../../useCase/evidence/FindRentedEvidenceDocuments";

export const evidenceProviders = [
  // repository
  {
    provide: RentedDocumentEvidenceRepositoryToken,
    useClass: RentedEvidenceDocumentApiRepository
  },
  // use case
  FindRentedEvidenceDocuments
];
