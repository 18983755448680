import React from "react";
import RegisterDialog from "../dialog";
import NotificationsList from "../notifications";
import { StyledGlobalContainer } from "./GlobalLayout.styles";

interface OwnProps {
  children: React.ReactNode;
}

export const GlobalLayout = ({ children }: OwnProps) => {
  return (
    <StyledGlobalContainer>
      <RegisterDialog />
      <NotificationsList />
      {children}
    </StyledGlobalContainer>
  );
};
