import { Button } from "@mui/material";
import { OwnProps as ErrorBoundaryProps } from "presentation/core/components/errorBoundary/ErrorBoundary";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import { useDispatch } from "react-redux";
import { callAsyncAction } from "../../../action";
import { logoutAction } from "../../../features/logout/_actions";
import { useStyles } from "../../dialog/Dialog.styles";
import {
  StyledErrorBoundaryContainer,
  StyledErrorPic
} from "../ErrorBoundary.styles";
import React from "react";

export const GlobalError = (props: ErrorBoundaryProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { clearErrorState } = props;

  const reloadPage = () => {
    window.location.reload();
  };

  const goToLoginPage = () => {
    dispatch(
      callAsyncAction({
        action: logoutAction,
        onSuccess: clearErrorState
      })
    );
  };

  return (
    <StyledErrorBoundaryContainer>
      <StyledErrorPic />
      <div className={classes.mtGap}>
        {t(translationPath(lang.errorBoundary.globalError))}
      </div>
      <div style={{ marginTop: 30 }}>
        <Button
          className={classes.mrGap}
          color="primary"
          data-test-id=""
          variant="outlined"
          onClick={reloadPage}
        >
          {t(translationPath(lang.errorBoundary.reload))}
        </Button>
        <Button
          className={classes.mlGap}
          color="primary"
          data-test-id=""
          variant="outlined"
          onClick={goToLoginPage}
        >
          {t(translationPath(lang.errorBoundary.goToLogin))}
        </Button>
      </div>
    </StyledErrorBoundaryContainer>
  );
};
