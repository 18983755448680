import { DocumentConcernedSubjectRepositoryToken } from "../../../domain/di/token/document";
import type { DocumentConcernedSubjectRepository } from "../../../domain/service/document/DocumentConcernedSubjectRepository";
import { SubjectId } from "../../../domain/struct/nameRegistry/Subject";
import {
  DInject,
  DInjectable
} from "../../../presentation/core/features/dependencyInjection";

@DInjectable()
export class RemoveConcernedSubject {
  constructor(
    @DInject(DocumentConcernedSubjectRepositoryToken)
    protected repository: DocumentConcernedSubjectRepository
  ) {}

  removeConcernedSubjects(nodeId: string, subjectIds: SubjectId[]) {
    return this.repository.removeConcernedSubjects(nodeId, subjectIds);
  }
}
