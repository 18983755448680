import React, { useMemo } from "react";
import { Address } from "../../../../../domain/struct/nameRegistry/Address";

type StreetRowProps = Partial<
  Pick<
    Address,
    | "street"
    | "houseNumber"
    | "orientationHouseNumber"
    | "orientationHouseNumberAddendum"
  >
>;

export const StreetRow: React.FC<StreetRowProps> = ({
  street,
  houseNumber,
  orientationHouseNumber,
  orientationHouseNumberAddendum
}) => {
  const values = useMemo(() => {
    const composedStreet = [street, " ", houseNumber];
    if (orientationHouseNumber) {
      composedStreet.push(...["/", orientationHouseNumber]);
      if (orientationHouseNumberAddendum) {
        composedStreet.push(orientationHouseNumberAddendum);
      }
    }
    return composedStreet.join("");
  }, [
    street,
    houseNumber,
    orientationHouseNumber,
    orientationHouseNumberAddendum
  ]);

  return <>{values}</>;
};
