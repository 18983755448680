import { DialogContentType } from "presentation/core/components/dialog/_types";
import React from "react";
import { SettleDocumentForm } from "./SettleDocumentDialogForm";
import { SettleDocumentFormValues } from "./_types";
import { validate } from "./_validations";
import { ChannelForm } from "../ChannelForm/ChannelForm";

export const SettleDocumentContentDialog: DialogContentType["content"] = ({
  channel
}) => (
  <div className="body-midsize" style={{ minHeight: 200 }}>
    <ChannelForm<SettleDocumentFormValues>
      initialValues={{
        customSettleMethod: "",
        settleDate: new Date(),
        settleMethod: "",
        settleReason: ""
      }}
      validate={validate}
      channel={channel}
      component={SettleDocumentForm}
    />
  </div>
);
