import React, { useCallback } from "react";
import { DocumentId } from "../../../../../domain/struct/document/Document";
import { HistoryTable, HistoryTableProps } from "../../history/HistoryTable";
import { useDocumentHistory } from "./useDocumentHistory";

export interface DocumentHistoryTableProps
  extends Pick<HistoryTableProps, "onRemoteTableApi" | "queryConfig"> {
  id: DocumentId;
}

export const DocumentHistoryTable: React.FC<DocumentHistoryTableProps> = React.memo(
  ({ id, onRemoteTableApi, queryConfig }) => {
    const findHistory = useDocumentHistory(id);

    const getRemoteData: HistoryTableProps["getRemoteData"] = useCallback(
      ({ pagination, sorting }) => findHistory(pagination, sorting),
      [findHistory]
    );

    return (
      <HistoryTable
        id={id}
        getRemoteData={getRemoteData}
        onRemoteTableApi={onRemoteTableApi}
        queryConfig={queryConfig}
      />
    );
  }
);
