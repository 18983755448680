import { SslPathsType } from "./_types";

export const pathsTree: SslPathsType[] = [
  {
    childs: [
      {
        childs: [
          {
            name: "NotRegistered",
            path: "Mailroom/documentLibrary/DataBox/NotRegistered"
          },
          {
            name: "Unprocessed",
            path: "Mailroom/documentLibrary/DataBox/Unprocessed"
          }
        ],
        name: "DataBox",
        path: "Mailroom/documentLibrary/DataBox"
      },
      {
        childs: [
          {
            name: "NotRegistered",
            path: "Mailroom/documentLibrary/MailBox/NotRegistered"
          },
          {
            name: "Unprocessed",
            path: "Mailroom/documentLibrary/MailBox/Unprocessed"
          },
          {
            name: "Incomplete",
            path: "Mailroom/documentLibrary/MailBox/Incomplete"
          }
        ],
        name: "MailBox",
        path: "Mailroom/documentLibrary/MailBox"
      },
      {
        name: "NotPassed",
        path: "Mailroom/documentLibrary/NotPassed"
      },
      {
        name: "Unfinished",
        path: "Mailroom/documentLibrary/Unfinished"
      },
      {
        name: "WaitingForTakeOver",
        path: "Mailroom/documentLibrary/WaitingForTakeOver"
      }
    ],
    name: "Mailroom",
    path: "Mailroom/documentLibrary"
  },
  {
    childs: [
      {
        childs: [
          {
            childs: [
              {
                name: "ForSignature",
                path:
                  "Evidence/documentLibrary/Documents/ForProcessing/ForSignature"
              },
              {
                name: "WaitingForTakeOver",
                path:
                  "Evidence/documentLibrary/Documents/ForProcessing/WaitingForTakeOver"
              }
            ],
            name: "ForProcessing",
            path: "Evidence/documentLibrary/Documents/ForProcessing"
          },
          {
            childs: [
              {
                name: "WaitingForTakeOver",
                path:
                  "Evidence/documentLibrary/Documents/Processed/WaitingForTakeOver"
              }
            ],
            name: "Processed",
            path: "Evidence/documentLibrary/Documents/Processed"
          }
        ],
        name: "Documents",
        path: "Evidence/documentLibrary/Documents"
      },
      {
        name: "Cancelled",
        path: "Evidence/documentLibrary/Cancelled"
      },
      {
        childs: [
          {
            name: "WaitingForTakeOver",
            path: "Evidence/documentLibrary/Concepts/WaitingForTakeOver"
          }
        ],
        name: "Concepts",
        path: "Evidence/documentLibrary/Concepts"
      },
      {
        childs: [
          {
            childs: [
              {
                name: "Cancelled",
                path: "Evidence/documentLibrary/Files/Documents/Cancelled"
              },
              {
                childs: [
                  {
                    name: "ForSignature",
                    path:
                      "Evidence/documentLibrary/Files/Documents/ForProcessing/ForSignature"
                  }
                ],
                name: "ForProcessing",
                path: "Evidence/documentLibrary/Files/Documents/ForProcessing"
              },
              {
                name: "LostDestroyed",
                path: "Evidence/documentLibrary/Files/Documents/LostDestroyed"
              },
              {
                name: "Processed",
                path: "Evidence/documentLibrary/Files/Documents/Processed"
              }
            ],
            name: "Documents",
            path: "Evidence/documentLibrary/Files/Documents"
          },
          {
            childs: [
              {
                name: "WaitingForTakeOver",
                path: "Evidence/documentLibrary/Files/Closed/WaitingForTakeOver"
              }
            ],
            name: "Closed",
            path: "Evidence/documentLibrary/Files/Closed"
          },
          {
            childs: [
              {
                name: "WaitingForTakeOver",
                path: "Evidence/documentLibrary/Files/Open/WaitingForTakeOver"
              }
            ],
            name: "Open",
            path: "Evidence/documentLibrary/Files/Open"
          }
        ],
        name: "Files",
        path: "Evidence/documentLibrary/Files"
      },
      {
        name: "LostDestroyed",
        path: "Evidence/documentLibrary/LostDestroyed"
      },
      {
        name: "ForSignature",
        path: "Evidence/documentLibrary/ForSignature"
      },
      {
        name: "ToTakeOver",
        path: "Evidence/documentLibrary/ToTakeOver"
      },
      {
        name: "WaitingForSignature",
        path: "Evidence/documentLibrary/WaitingForSignature"
      },
      {
        name: "WaitingForTakeOver",
        path: "Evidence/documentLibrary/WaitingForTakeOver"
      }
    ],
    name: "Evidence",
    path: "Evidence/documentLibrary"
  },
  {
    childs: [
      {
        name: "Created",
        path: "Dispatch/documentLibrary/Created"
      },
      {
        name: "ToDispatch",
        path: "Dispatch/documentLibrary/ToDispatch"
      },
      {
        name: "Dispatched",
        path: "Dispatch/documentLibrary/Dispatched"
      },
      {
        name: "Returned",
        path: "Dispatch/documentLibrary/Returned"
      }
    ],
    name: "Dispatch",
    path: "Dispatch/documentLibrary"
  },
  {
    name: "Components",
    path: "Components/documentLibrary"
  },
  {
    childs: [
      {
        name: "Archived",
        path: "Repository/documentLibrary/Archived"
      },
      {
        name: "DocumentsInFiles",
        path: "Repository/documentLibrary/DocumentsInFiles"
      },
      {
        name: "Rented",
        path: "Repository/documentLibrary/Rented"
      },
      {
        name: "Shredded",
        path: "Repository/documentLibrary/Shredded"
      },
      {
        name: "ShreddingProposal",
        path: "Repository/documentLibrary/ShreddingProposal"
      },
      {
        name: "Stored",
        path: "Repository/documentLibrary/Stored"
      }
    ],
    name: "Repository",
    path: "Repository/documentLibrary"
  },
  {
    childs: [],
    name: "Administration",
    path: "Administration/documentLibrary"
  }
];
