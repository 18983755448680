import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { Form } from "formik";
import { Select } from "formik-mui";
import {
  StyledFormControlWide,
  useStyles
} from "presentation/core/components/dialog/Dialog.styles";
import { DialogContentPropsType } from "presentation/core/components/dialog/_types";
import FormControlWithError from "presentation/core/components/formControlWithError";
import { RootStateType } from "presentation/reducers";
import { lang, t } from "presentation/translation/i18n";
import { useSelector } from "react-redux";
import { BaseField } from "../../../../core/components/form/fields/BaseField";
import { lastPathMember, translationPath } from "../../../utils/getPath";
import { ChannelForm } from "../ChannelForm/ChannelForm";
import {
  ActiveGroupType,
  GroupChangeFormValuesType,
  groupChangeFormValuesProxy
} from "./_types";
import { validate } from "./_validations";

export const GroupChangeContentDialog = ({
  channel
}: DialogContentPropsType) => {
  const classes = useStyles();
  const { groups, activeGroup } = useSelector(
    (state: RootStateType) => state?.loginReducer?.session
  );
  const filteredGroups = groups.filter((grp) => grp.id !== activeGroup);

  return (
    <div>
      <ChannelForm<GroupChangeFormValuesType>
        initialValues={{
          activeGroup: ""
        }}
        validate={validate}
        channel={channel}
      >
        <Form className={classes.form}>
          <FormControlWithError
            name={lastPathMember(groupChangeFormValuesProxy.activeGroup).path}
            component={StyledFormControlWide}
          >
            <InputLabel
              htmlFor={
                lastPathMember(groupChangeFormValuesProxy.activeGroup).path
              }
              required={true}
            >
              {t(translationPath(lang.general.group))}
            </InputLabel>
            <BaseField
              component={Select}
              data-test-id="group-change-form-activeGroup"
              name={lastPathMember(groupChangeFormValuesProxy.activeGroup).path}
              inputProps={{
                id: lastPathMember(groupChangeFormValuesProxy.activeGroup).path
              }}
            >
              {filteredGroups.map((group: ActiveGroupType) => {
                return (
                  <MenuItem key={group.id} value={group.id}>
                    {group.displayName}
                  </MenuItem>
                );
              })}
            </BaseField>
          </FormControlWithError>
        </Form>
      </ChannelForm>
    </div>
  );
};
