import MetaFormContainer from "presentation/core/components/MetaForm";
import { DialogContentPropsType } from "presentation/core/components/dialog/_types";
import { ShipmentDocument } from "presentation/core/types";
import { SpisumNodeTypes } from "presentation/enums";
import { ShipmentFormValues } from "./_types";
import { ShipmentDataboxForm } from "./forms/databoxForm/ShipmentDataboxForm";
import { ShipmentEmailForm } from "./forms/emailForm/ShipmentEmailForm";
import { ShipmentPersonalForm } from "./forms/personalFrom/ShipmentPersonalForm";
import { ShipmentPostForm } from "./forms/postForm/ShipmentPostForm";
import { ShipmentPublishForm } from "./forms/publishForm/ShipmentPublishForm";
import React from "react";

export const shipmentDetailFormList = {
  [SpisumNodeTypes.ShipmentDatabox]: ShipmentDataboxForm,
  [SpisumNodeTypes.ShipmentEmail]: ShipmentEmailForm,
  [SpisumNodeTypes.ShipmentPost]: ShipmentPostForm,
  [SpisumNodeTypes.ShipmentPersonally]: ShipmentPersonalForm,
  [SpisumNodeTypes.ShipmentPublish]: ShipmentPublishForm
};

const MetaDataFormTab = ({
  channel,
  dialogProps,
  onClose
}: DialogContentPropsType) => {
  const shipmentDocument = dialogProps.data as ShipmentDocument;

  const form =
    shipmentDetailFormList[
      shipmentDocument.nodeType as keyof typeof shipmentDetailFormList
    ];

  return (
    <MetaFormContainer<ShipmentFormValues>
      channel={channel}
      dialogProps={dialogProps}
      MetaForm={form}
      onClose={onClose}
    />
  );
};

export default MetaDataFormTab;
