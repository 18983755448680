import { FindConversionAll } from "useCase/administration/FindConversionAll";

import { ConversionAllRepositoryToken } from "domain/di/token/administration";
import { ConversionAllApiRepository } from "infrastructure/api/administration/ConversionAllApiRepository";
import { FindImprint } from "useCase/administration/FindImprint";

import { ImprintRepositoryToken } from "domain/di/token/administration";
import { ImprintApiRepository } from "infrastructure/api/administration/ImprintApiRepository";
import { FindUser } from "useCase/administration/FindUser";

import { UserRepositoryToken } from "domain/di/token/administration";
import { UserApiRepository } from "infrastructure/api/administration/UserApiRepository";
export const administrationProviders = [
  // repository
  {
    provide: UserRepositoryToken,
    useClass: UserApiRepository
  },
  {
    provide: ImprintRepositoryToken,
    useClass: ImprintApiRepository
  },
  {
    provide: ConversionAllRepositoryToken,
    useClass: ConversionAllApiRepository
  },
  // use case
  FindUser,
  FindImprint,
  FindConversionAll
];
