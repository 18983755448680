import MenuLayout from "presentation/core/components/layout/MenuLayout";
import React, { useEffect } from "react";
import { useCreateAnalogDocument } from "./hooks/useCreateAnalogdocument";
import Loading from "presentation/core/components/dataTable/components/Loading";

export const AnalogDetailLayout = () => {
  const {
    modal,
    create: createAnalogDocument,
    isLoading
  } = useCreateAnalogDocument();

  useEffect(() => {
    createAnalogDocument(null);
  }, [createAnalogDocument]);
  return (
    <MenuLayout>
      <>
        {isLoading && <Loading />}
        {modal}
      </>
    </MenuLayout>
  );
};
