import { useStyles } from "presentation/core/components/dialog/Dialog.styles";
import { ComponentsFromSignatureTab } from "presentation/core/components/dialog/tabs/fromSignatureComponents";
import {
  DialogContentPropsType,
  DialogContentType
} from "presentation/core/components/dialog/_types";
import { FormState } from "presentation/core/components/reactiveFormik/_types";
import React from "react";
import { FromSignatureForm } from "./FromSignatureForm";
import { FromSignatureFormValues } from "./_types";
import { ChannelForm } from "../ChannelForm/ChannelForm";

export const FromSignatureDialog: DialogContentType["content"] = (
  props: DialogContentPropsType
) => {
  const classes = useStyles();
  const onSubmit = () => {};

  return (
    <div className={classes.fromSignatureContainer}>
      <ComponentsFromSignatureTab {...props} />
      <ChannelForm<FromSignatureFormValues>
        initialValues={{
          visual: false
        }}
        onSubmit={onSubmit}
        channel={props.channel}
      >
        {(formProps: FormState<FromSignatureFormValues>) => {
          props.dialogProps.signerVisual = formProps.values.visual;

          return <FromSignatureForm {...formProps} />;
        }}
      </ChannelForm>
    </div>
  );
};
