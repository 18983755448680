import { PaginationConfig } from "lib/contract/Pagination";
import { useQM } from "presentation/share/hook/query/useQM";
import { useMutation } from "presentation/share/hook/query/useMutation";
import {
  addDocumentConcernedSubjects,
  getDocumentConcernedSubjects,
  removeDocumentConcernedSubjects
} from "../api";
import { SubjectId } from "domain/struct/nameRegistry/Subject";

interface ApiPathParams {
  nodeId: string;
  subjectIds: SubjectId[];
}

export const DOC_CONC_SUBJS_QUERY_KEY = "document/concerned-subjects/get";
export const useDocumentConcernedSubjects = () =>
  // nodeId: string,
  // config: PaginationConfig
  {
    // const {
    //   isLoading: isLoadingGetDocConcernedSubjs,
    //   data: documentConcernedSubjects
    // } = useQM(
    //   [DOC_CONC_SUBJS_QUERY_KEY, nodeId, config],
    //   () => getDocumentConcernedSubjects(nodeId, config),
    //   {
    //     onSuccess: () => {
    //       return Promise.resolve();
    //     }
    //   }
    // );

    const {
      isLoading: isLoadigAddDocConcernedSubjs,
      mutate: addDocConcernedSubjs
    } = useMutation(({ nodeId, subjectIds }: ApiPathParams) =>
      addDocumentConcernedSubjects(nodeId, subjectIds)
    );

    const {
      isLoading: isLoadigRemoveDocConcernedSubjs,
      mutate: removeDocConcernedSubjs
    } = useMutation(({ nodeId, subjectIds }: ApiPathParams) =>
      removeDocumentConcernedSubjects(nodeId, subjectIds)
    );

    return {
      // documentConcernedSubjects,
      // isLoadingGetDocConcernedSubjs,

      addDocConcernedSubjs,
      isLoadigAddDocConcernedSubjs,

      removeDocConcernedSubjs,
      isLoadigRemoveDocConcernedSubjs
    };
  };
