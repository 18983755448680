import { SearchedRepositoryToken } from "../../domain/di/token/search";
import { SearchedApiRepository } from "../../infrastructure/api/search/SearchedApiRepository";
import { FindSearched } from "../../useCase/search/FindSearched";

export const searchProviders = [
  // repository
  {
    provide: SearchedRepositoryToken,
    useClass: SearchedApiRepository
  },

  // use case
  FindSearched
];
