export enum AddressFormEnum {
  Street = "Ulice",
  City = "Obec",
  Country = "Země",
  AddressType = "Typ adresy",
  HouseNumberType = "Typ domovního čísla",
  HouseNumber = "Číslo domovní",
  OrientationHouseNumber = "Číslo orientační",
  OrientationHouseNumberAddendum = "Dodatek orientačního čísla ",
  CityPart = "Část obce",
  PostCode = "PSČ"
}

export enum HouseNumberEnum {
  DescriptiveNumber = "Popisné",
  EvidencingNumber = "Evidenční"
}

export enum LegalEntityAddressType {
  CompanyHeadquarters = "COMPANY_HEADQUARTERS",
  CompanyBranch = "COMPANY_BRANCH"
}
