import { FileSyncOutlined } from "@ant-design/icons";
import { useModal } from "@isfg/react-components/build/Modal/useModal";
import { Description, Send } from "@mui/icons-material";
import { openDocumentWithSaveButtonsAction } from "presentation/core/api/document/_actions";
import {
  ControlsBarType,
  DataColumn
} from "presentation/core/components/dataTable/_types";
import DocumentView from "presentation/core/components/documentView";
import MenuLayout from "presentation/core/components/layout/MenuLayout";
import { GenericDocument, genericDocumentProxy } from "presentation/core/types";
import { SitePaths, SpisumNodeTypes } from "presentation/enums";
import { RootStateType } from "presentation/reducers";
import { handoverDocument } from "presentation/share/components/dialog/documentHandoverDialog/_actions";
import {
  ConvertAnalogToDigitalModal,
  isDocumentAvailableForAnalogToDigitalConversion
} from "presentation/share/components/modals/conversions/ConvertAnalogToDigitalModal";
import { classPath, translationPath } from "presentation/share/utils/getPath";
import { getRelativePath } from "presentation/share/utils/query";
import { lang, t, withTranslation } from "presentation/translation/i18n";
import { useDispatch, useSelector } from "react-redux";
import {
  ConvertDigitalToAnalogModal,
  isDocumentAvailableForDigitalToAnalogConversion
} from "../../../../share/components/modals/conversions/ConvertDigitalToAnalogModal";
import { DisplaySenderNameByType } from "../../../../share/components/table/column/DisplaySenderNameByType";
import React from "react";

const defaultColumn: DataColumn<GenericDocument> = {
  isDateTime: true,
  keys: [classPath(genericDocumentProxy.properties!.ssl!.deliveryDate).path],
  label: t(translationPath(lang.general.deliveryDate))
};

export const columns: DataColumn<GenericDocument>[] = [
  {
    keys: [classPath(genericDocumentProxy.properties!.ssl!.pid).path],
    label: t(translationPath(lang.general.identifier))
  },
  {
    keys: [classPath(genericDocumentProxy.properties!.ssl!.ssid).path],
    label: t(translationPath(lang.general.referenceNumber))
  },
  {
    keys: [classPath(genericDocumentProxy.properties!.ssl!.subject).path],
    label: t(translationPath(lang.general.subject))
  },
  {
    getValue: (row) => <DisplaySenderNameByType ssl={row?.properties?.ssl} />,
    keys: [
      classPath(genericDocumentProxy.properties!.ssl!.senderCompanyName).path,
      classPath(genericDocumentProxy.properties!.ssl!.senderFullName).path
    ],
    label: t(translationPath(lang.general.sender))
  },
  defaultColumn
];

const NotPassed = () => {
  const dispatch = useDispatch();
  const [convertDigitalToAnalogModal, convertDigitalToAnalogModalApi] =
    useModal(ConvertDigitalToAnalogModal);
  const [convertAnalogToDigitalModal, convertAnalogToDigitalModalApi] =
    useModal(ConvertAnalogToDigitalModal);
  const { components } = useSelector(
    (state: RootStateType) => state.componentsReducer
  );
  const relativePath = useSelector((state: RootStateType) =>
    getRelativePath(
      state.loginReducer.global.paths,
      null,
      SitePaths.Mailroom,
      SitePaths.NotPassed
    )
  );

  const dispatchOpenDialog: (row: GenericDocument) => void = (row) => {
    dispatch(
      openDocumentWithSaveButtonsAction({
        canUploadComponents: false,
        data: row,
        hideShipmentsTab: true
      })
    );
  };

  const controls: ControlsBarType<GenericDocument> = {
    single: {
      items: [
        {
          action: (selected: GenericDocument[]) => {
            dispatchOpenDialog(selected[0]);
          },
          icon: <Description />,
          title: t(translationPath(lang.general.showDetails))
        },
        {
          action: (selected: GenericDocument[]) => {
            convertDigitalToAnalogModalApi.open({
              nodeId: selected[0].id
            });
          },
          filter: (selected: GenericDocument) => {
            return isDocumentAvailableForDigitalToAnalogConversion(selected);
          },
          validation: (selected: GenericDocument[]) => {
            if (selected[0]?.properties?.ssl?.fileIsEncrypted) {
              return t(
                translationPath(lang._validations.documentHasEncryptedComponent)
              );
            }
            if (!selected[0]?.properties?.ssl?.fileMark) {
              return t(
                translationPath(
                  lang.dialog.convertions.digitalToAnalog.missingFilePlan
                )
              );
            } else if (components.pagination?.totalItems === 0) {
              return t(
                translationPath(
                  lang.dialog.convertions.digitalToAnalog.missingComponent
                )
              );
            }
            return null;
          },
          icon: (
            <FileSyncOutlined
              rev={"default"}
              style={{ fontSize: "17px" }}
              data-test-id="digital-to-analog"
            />
          ),
          title: t(translationPath(lang.general.convertDA))
        },
        {
          action: (selected: GenericDocument[]) => {
            convertAnalogToDigitalModalApi.open({
              nodeId: selected[0].id
            });
          },
          filter: (selected: GenericDocument) => {
            return isDocumentAvailableForAnalogToDigitalConversion(selected);
          },
          validation: (selected: GenericDocument[]) => {
            if (!selected[0]?.properties?.ssl?.fileMark) {
              return t(
                translationPath(
                  lang.dialog.convertions.analogToDigital.missingFilePlan
                )
              );
            }
            return null;
          },
          icon: (
            <FileSyncOutlined
              rev={"default"}
              style={{ fontSize: "17px" }}
              data-test-id="analog-to-digital"
            />
          ),
          title: t(translationPath(lang.general.convertAD))
        },
        {
          action: (selected: GenericDocument[]) => {
            dispatch(handoverDocument({ data: selected[0] }));
          },
          icon: <Send data-test-id="handover" />,
          title: t(translationPath(lang.general.handOVer))
        }
      ]
    }
  };

  return (
    <>
      {convertDigitalToAnalogModal}
      {convertAnalogToDigitalModal}
      <MenuLayout>
        <DocumentView
          children={{
            relativePath,
            where: `(nodeType='${SpisumNodeTypes.Document}')`
          }}
          columns={columns}
          controls={controls}
          customTitle={t(translationPath(lang.table.notPassed))}
          defaultSortAsc={true}
          defaultSortColumn={defaultColumn}
          handleDoubleClick={dispatchOpenDialog}
        />
      </MenuLayout>
    </>
  );
};

export default withTranslation()(NotPassed);
