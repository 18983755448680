import React from "react";
import { Item, Rule } from "../../../../designSystem/Form/v2/Form";
import { Input } from "../../../../designSystem/Input/Input";
import {
  identificationNumber,
  required,
  stringMax,
  taxIdentificationNumber
} from "../../../../designSystem/Input/InputValidations";
import { ColOneFourth, Row } from "presentation/designSystem/Grid/Grid";

const RULES: Record<string, Rule[]> = {
  organizationName: [required, stringMax(255)],
  identificationNumber: [required, ...identificationNumber],
  taxIdentificationNumber: [taxIdentificationNumber]
};

interface LegalEntityFieldsProps {
  disableVat: boolean;
}

export const LegalEntityFields = ({ disableVat }: LegalEntityFieldsProps) => {
  return (
    <Row justify="space-between">
      {" "}
      <ColOneFourth spaceAround>
        {" "}
        <Item
          name="companyFullName"
          label="Název organizace"
          rules={RULES.organizationName}
        >
          <Input style={{ width: 215 }} />
        </Item>
      </ColOneFourth>
      <ColOneFourth spaceAround>
        {" "}
        <Item
          name="identificationNumber"
          label="IČO"
          rules={RULES.identificationNumber}
        >
          <Input disabled={disableVat} />
        </Item>
      </ColOneFourth>
      <ColOneFourth spaceAround>
        <Item
          name="taxIdentificationNumber"
          label="DIČ"
          rules={RULES.taxIdentificationNumber}
        >
          <Input />
        </Item>
      </ColOneFourth>
      <ColOneFourth spaceAround />
    </Row>
  );
};
