import { AllInbox, AssignmentInd, SwapHoriz } from "@mui/icons-material";
import { TableRowSelection } from "antd/lib/table/interface";
import { DateTimeFormat } from "lib/dateTime";
import { ColumnType } from "presentation/designSystem/Table/Table";
import React, { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { StoredDocument } from "../../../../../../domain/struct/repository/StoredDocument";
import { openDocumentWithSaveButtonsAction } from "../../../../../core/api/document/_actions";
import { dialogOpenAction } from "../../../../../core/components/dialog/_actions";
import { DialogType } from "../../../../../core/components/dialog/_types";
import { BreadcrumbForTable } from "../../../../../designSystem/Breadcrumb/Breadcrumb";
import {
  GetRemoteDataClb,
  RemoteTable
} from "../../../../../designSystem/Table/RemoteTable";
import {
  createColumn,
  createDateTimeColumn
} from "../../../../../designSystem/Table/column/column";
import { DocumentType, SpisumNodeTypes } from "../../../../../enums";
import { actionShowDetail } from "../../../../../share/components/table/actionFactory";
import { FileMarkColumnValue } from "../../../../../share/components/table/column/document/FilePlan/FileMarkColumnValue";
import { FilePlanColumnValue } from "../../../../../share/components/table/column/document/FilePlan/FilePlanColumnValue";
import { translationPath } from "../../../../../share/utils/getPath";
import { lang, t } from "../../../../../translation/i18n";
import { createSenderNameColumn } from "../../../factory/table/columnFactory";
import { useFindStoredDocuments } from "../../../hooks/useFindStoredDocuments";

const COLUMNS: ColumnType<StoredDocument>[] = [
  createColumn({
    dataIndex: "pid",
    title: t(translationPath(lang.general.identifier))
  }),
  createColumn({
    dataIndex: "ssid",
    title: t(translationPath(lang.general.referenceNumber))
  }),
  createColumn({
    dataIndex: "subject",
    title: t(translationPath(lang.general.subject))
  }),
  createSenderNameColumn(),
  createColumn({
    dataIndex: "filePlan",
    title: t("general:filePlan"),
    render: (text, row) => {
      const { filePlanId } = row;

      if (!filePlanId) {
        return;
      }

      return <FilePlanColumnValue filePlanId={filePlanId} />;
    }
  }),
  createColumn({
    dataIndex: "fileMark",
    title: t("general:fileMark"),
    render: (text, row) => {
      const { fileMark } = row;

      if (!fileMark) {
        return;
      }

      return <FileMarkColumnValue fileMarkId={fileMark} />;
    }
  }),
  createColumn({
    dataIndex: "retentionMode",
    title: t(translationPath(lang.general.retentionMode))
  }),
  createColumn({
    dataIndex: "location",
    title: t(translationPath(lang.general.storageLocation))
  }),
  createDateTimeColumn(
    {
      dataIndex: "shreddingYear",
      title: t(translationPath(lang.general.yearOfShredding)),
      defaultSortOrder: "ascend",
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: true
    },
    DateTimeFormat.Year
  )
];

export const StoredDocumentsTable = React.memo(() => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);

  const dispatch = useDispatch();
  const findStoredDocuments = useFindStoredDocuments();

  const ACTIONS = useMemo(
    () => ({
      single: [
        actionShowDetail({
          action(selected: StoredDocument[]) {
            dispatch(
              openDocumentWithSaveButtonsAction({
                data: {
                  id: selected[0].nodeId
                },
                canUploadComponents: false,
                hideManageShipmentsIcon: true,
                initiator: SpisumNodeTypes.File,
                isReadonly: true
              })
            );
          }
        }),
        {
          key: "change-file-mark",
          icon: <SwapHoriz />,
          tooltip: t(translationPath(lang.general.changeFileMark)),
          action(selected: StoredDocument[]) {
            dispatch(
              dialogOpenAction({
                dialogProps: {
                  data: {
                    id: selected[0].nodeId,
                    nodeType: SpisumNodeTypes.Document,
                    properties: {
                      ssl: {
                        filePlan: selected[0].filePlanId,
                        fileMark: selected[0].fileMark
                      }
                    }
                  },
                  onClose: () => {
                    setSelectedRowKeys([]);
                  }
                },
                dialogType: DialogType.ChangeFileMark
              })
            );
          }
        },
        {
          key: "edit-storage-location",
          icon: <AllInbox />,
          tooltip: t(translationPath(lang.general.editStorageLocation)),
          canBeDisplayed(selected: StoredDocument) {
            return (
              selected.form === DocumentType.Analog ||
              selected.form === DocumentType.AD
            );
          },
          action(selected: StoredDocument[]) {
            dispatch(
              dialogOpenAction({
                dialogProps: {
                  data: {
                    id: selected[0].nodeId,
                    nodeType: SpisumNodeTypes.Document
                  }
                },
                dialogType: DialogType.ChangeLocation
              })
            );
          }
        },
        {
          key: "borrow",
          icon: <AssignmentInd />,
          tooltip: t(translationPath(lang.general.borrow)),
          action(selected: StoredDocument[]) {
            dispatch(
              dialogOpenAction({
                dialogProps: {
                  data: {
                    id: selected[0].nodeId,
                    nodeType: SpisumNodeTypes.Document
                  }
                },
                dialogType: DialogType.Borrow
              })
            );
          }
        }
      ]
    }),
    [dispatch]
  );

  const getRemoteData: GetRemoteDataClb<StoredDocument> = useCallback(
    ({ pagination, sorting }) => findStoredDocuments(pagination, sorting),
    [findStoredDocuments]
  );

  const onRowSelection: TableRowSelection<StoredDocument> = {
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys);
    },
    selectedRowKeys
  };

  return (
    <>
      <BreadcrumbForTable title={"storedDocuments"}></BreadcrumbForTable>
      <TableStyled>
        <RemoteTable<StoredDocument>
          name="repository/storedDocuments"
          rowKey="pid"
          actions={ACTIONS}
          columns={COLUMNS}
          getRemoteData={getRemoteData}
          rowSelection={onRowSelection}
        />
      </TableStyled>
    </>
  );
});
const TableStyled = styled("div")`
  div > .ant-row {
    height: 50px;
  }
`;
