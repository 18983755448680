export enum EvidenceRoutes {
  EVIDENCE = "/evidence",
  EVIDENCE_CONCEPTS = "/evidence/concepts",
  EVIDENCE_DOCUMENTS_FORPROCESSING = "/evidence/documents/for-processing",
  EVIDENCE_DOCUMENTS_PROCESSED = "/evidence/documents/processed",
  EVIDENCE_FILES_CLOSED = "/evidence/files/closed",
  EVIDENCE_FILES_OPEN = "/evidence/files/open",
  EVIDENCE_OVERVIEWS_FORSIGNATURE = "/evidence/overviews/for-signature",
  EVIDENCE_OVERVIEWS_FORSEAL = "/evidence/overviews/for-seal",
  EVIDENCE_OVERVIEWS_LOSTDESTROYED = "/evidence/overviews/lost-destroyed",
  EVIDENCE_OVERVIEWS_REJECTEDBYTAKEOVER = "/evidence/overviews/rejected-by-takeover",
  EVIDENCE_OVERVIEWS_RENTED = "/evidence/overviews/borrowed",
  EVIDENCE_OVERVIEWS_RETURNEDFORREWORK = "/evidence/overviews/returned-for-rework",
  EVIDENCE_OVERVIEWS_RETURNEDFROMDISPATCH = "/evidence/overviews/returned-from-dispatch",
  EVIDENCE_OVERVIEWS_CANCEL = "/evidence/overviews/cancel",
  EVIDENCE_TOTAKEOVER = "/evidence/to-take-over",
  EVIDENCE_TYPE_FILES_CLOSED = "/evidence/type-files/closed",
  EVIDENCE_TYPE_FILES_OPEN = "/evidence/type-files/open",
  EVIDENCE_WAITINGTOTAKEOVER = "/evidence/waiting-to-takeover"
}
