import { ApiListResponseMapper } from "infrastructure/api/mapper/ApiListResponseMapper";
import {
  ApiListResponse,
  ApiTypes
} from "infrastructure/api/struct/ApiTypesV2";
import { stringOrDefault } from "lib/string";
import { DocumentForSeal, ForSeal } from "../types/ForSeal";
import { History } from "../../../../../../domain/struct/history/History";
import { ApiHistory } from "../../../../../../infrastructure/api/history/HistoryApiMapper";

export type ApiSignatureBookForSealResponse = ApiTypes["ISFG.SpisUm.ClientSide.Models.V2.Overview.OverviewForSealResponse"];
export type ApiDocumentForSealResponse = ApiTypes["ISFG.SpisUm.ClientSide.Models.V2.SignatureBook.SignatureBookGetComponentsResponse"];

export class Mappers {
  static mapListToDomain(
    response: ApiListResponse<ApiSignatureBookForSealResponse>
  ) {
    return ApiListResponseMapper.mapListToPagination(
      response,
      Mappers.mapToDomain
    );
  }

  static mapToDomain(response: ApiSignatureBookForSealResponse): ForSeal {
    const {
      nodeId,
      pid,
      ssid,
      subject,
      deliveryDate,
      forSealDate,
      attachmentsCount,
      owner
    } = response;
    return {
      nodeId: stringOrDefault(nodeId),
      pid: stringOrDefault(pid),
      ssid: stringOrDefault(ssid),
      subject: stringOrDefault(subject),
      deliveryDate: stringOrDefault(deliveryDate),
      forSealDate: stringOrDefault(forSealDate),
      attachmentsCount,
      owner: stringOrDefault(owner)
    };
  }

  static mapDocumentForSealListToDomain(
    list: ApiListResponse<ApiDocumentForSealResponse>
  ) {
    return ApiListResponseMapper.mapListToPagination(
      list,
      Mappers.mapDocumentToDomain
    );
  }

  static mapDocumentToDomain(
    document: ApiDocumentForSealResponse
  ): DocumentForSeal {
    return {
      componentId: stringOrDefault(document.componentId),
      canBeSigned: document.canBeSigned,
      fileName: stringOrDefault(document.fileName),
      isSign: document.isSign,
      isSealed: document.isSealed,
      usedTimeSign: stringOrDefault(document.usedTimeSign),
      usedTimeSeal: stringOrDefault(document.usedTimeSeal)
    };
  }
}
