import { ConcernedSubjectTable } from "./ConcernedSubjectTable";
import { getService } from "../../../../core/features/dependencyInjection";
import { FindConcernedSubject } from "../../../../../useCase/document/concernedSubject/FindConcernedSubject";
import { GetRemoteDataClb } from "../../../../designSystem/Table/RemoteTable";
import { DocumentConcernedSubject } from "../../../../../domain/struct/document/DocumentConcernedSubject";
import React, { useCallback } from "react";
import { RemoveConcernedSubject } from "../../../../../useCase/document/concernedSubject/RemoveConcernedSubject";
import { AddConcernedSubject } from "../../../../../useCase/document/concernedSubject/AddConcernedSubject";
import { SubjectId } from "../../../../../domain/struct/nameRegistry/Subject";
import { TabAndDialogChannelType } from "../../../../core/components/dialog/_types";
import { useRemoteTableApiContext } from "presentation/designSystem/Table/contexts/RemoteTableApiContextProvider";

export interface DocumentConcernedSubjectTableProps {
  documentId: string;
  readonly: boolean;
  channel?: TabAndDialogChannelType;
}

export const DocumentConcernedSubjectTable = ({
  documentId,
  readonly,
  channel
}: DocumentConcernedSubjectTableProps) => {
  const findUseCase = getService(FindConcernedSubject);
  const removeUseCase = getService(RemoveConcernedSubject);
  const addUseCase = getService(AddConcernedSubject);

  const { refreshData } = useRemoteTableApiContext();

  const getSubjects: GetRemoteDataClb<DocumentConcernedSubject> = useCallback(
    ({ pagination }) => findUseCase.findPaginated(documentId, pagination),
    [documentId, findUseCase]
  );

  const removeConcernedSubjects = useCallback(
    async (subjectIds: SubjectId[]) => {
      await removeUseCase.removeConcernedSubjects(documentId, subjectIds);
      refreshData();
    },
    [documentId, removeUseCase, refreshData]
  );

  const addConcernedSubjects = useCallback(
    async (subjectIds: SubjectId[]) => {
      await addUseCase.addConcernedSubjects(documentId, subjectIds);
      refreshData();
    },
    [addUseCase, documentId, refreshData]
  );

  return (
    <ConcernedSubjectTable
      entityId={documentId}
      getSubjects={getSubjects}
      removeConcernedSubjects={removeConcernedSubjects}
      addConcernedSubjects={addConcernedSubjects}
      readonly={readonly}
      channel={channel}
    />
  );
};
