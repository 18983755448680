import contentDisposition from "content-disposition";
import { File } from "presentation/core/entities/file/File";
import { getService } from "presentation/core/features/dependencyInjection";
import { HttpClient } from "infrastructure/service/HttpClient";
import fileDownload from "js-file-download";
import mime from "mime-types";
import { FilePlan } from "../../features/login/_types";

export const fetchShreddingPlanComponent = async (
  shreddingPlan: FilePlan,
  fileUrl: string,
  download?: boolean
): Promise<File | null> => {
  const httpClient = getService<HttpClient>(HttpClient);
  const filePlanId = shreddingPlan.nodeId;
  try {
    const { response, success, responseHeaders } = await httpClient.fetch(
      fileUrl,
      "GET",
      {
        contentType: "application/octet-stream",
        params: {
          filePlanId
        }
      }
    );

    if (success) {
      const disposition = contentDisposition.parse(
        responseHeaders?.get("content-disposition") || ""
      );
      const contentType = responseHeaders?.get("content-type") || "";
      const extension = mime.extension(contentType);

      if (download) {
        fileDownload(response as Blob, `_${shreddingPlan.name}.${extension}`);
      }

      return {
        content: response as File["content"],
        id: filePlanId,
        name: `${disposition.parameters.filename}.${extension}`
      };
    }

    return null;
  } catch (e) {
    return null;
  }
};
