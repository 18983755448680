import { ApiTypes } from "../struct/ApiTypes";
import { HouseNumberType } from "../../../domain/struct/codelist/HouseNumberType";

export type ApiCountries = ApiTypes["ISFG.SpisUm.ClientSide.Models.NameRegister.CodeLists.Country"];

export class CountryApiMapper {
  static mapToDomain(response: ApiCountries[]): HouseNumberType[] {
    return response.map((apiContact) => ({
      code: apiContact.code || "",
      czechName: apiContact.czechName || ""
    }));
  }
}
