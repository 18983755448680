import React from "react";
import { Form } from "../../../../designSystem/Form/v2/Form";
import { AddressFields } from "./AddressFields";
import { Address } from "../../../../../domain/struct/nameRegistry/Address";
import { FormInstance } from "presentation/designSystem/Form/v2/Form";

export interface AddressesFormProps {
  form: FormInstance<Address>;
  address?: Address;
}

export const AddressForm = ({ form, address }: AddressesFormProps) => {
  return (
    <Form name="addressForm" form={form}>
      <AddressFields address={address} />
    </Form>
  );
};
