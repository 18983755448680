import { debounce } from "lodash";
import { DialogContentPropsType } from "presentation/core/components/dialog/_types";
import { FormState } from "presentation/core/components/reactiveFormik/_types";
import { GenericDocument } from "presentation/core/types";
import { SitePaths, SpisumNodeTypes } from "presentation/enums";
import { RootStateType } from "presentation/reducers";
import { alfrescoQuery, getQueryPath } from "presentation/share/utils/query";
import { Dispatch, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ChannelForm } from "../ChannelForm/ChannelForm";
import { CreateNewDocumentFileForm } from "./CreateNewDocumentFileForm";
import {
  SearchFilesActionType,
  clearResultsAction,
  searchFilesAction
} from "./_actions";
import { CreateNewDocumentFileFormValues, FileOption } from "./_types";
import * as React from "react";

const DEBOUNCE_WAIT = 500;
const MIN_TERM_LENGTH = 3;
const MAX_ITEMS = 25;

export const CreateNewDocumentFileDialog = ({
  channel,
  dialogProps
}: DialogContentPropsType) => {
  const dispatch: Dispatch<SearchFilesActionType> = useDispatch();

  const { results, isLoading } = useSelector(
    (state: RootStateType) => state.searchFilesReducer
  );

  const pathFilesOpen = useSelector(
    (state: RootStateType) =>
      getQueryPath(
        state.loginReducer.global.paths,
        "*",
        SitePaths.Evidence,
        SitePaths.Files,
        SitePaths.Open
      )?.path || ""
  );

  const getSearchQuery = (term: string) => ({
    query: alfrescoQuery({
      paths: [pathFilesOpen],
      type: [SpisumNodeTypes.File],
      where: `(ssl:pid:'*${term}*' OR ssl:fileIdentifier:'*${term}*' OR ssl:subject:'*${term}*')`
    })
  });

  const onInputChange = debounce((value: string) => {
    if (value.length >= MIN_TERM_LENGTH) {
      dispatch(
        searchFilesAction.request({
          include: ["properties"],
          paging: {
            maxItems: MAX_ITEMS,
            skipCount: 0
          },
          query: getSearchQuery(value)
        })
      );
    }
  }, DEBOUNCE_WAIT);

  useEffect(() => {
    dispatch(clearResultsAction());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ChannelForm<CreateNewDocumentFileFormValues>
      initialValues={{
        selected: FileOption.Existing
      }}
      channel={channel}
    >
      {(props: FormState<CreateNewDocumentFileFormValues>) => (
        <CreateNewDocumentFileForm
          {...props}
          document={dialogProps.data as GenericDocument}
          isLoading={isLoading}
          onFileSearchChange={onInputChange}
          searchResults={results || []}
        />
      )}
    </ChannelForm>
  );
};
