import { useCallback } from "react";
import { getGroupsToSeal, getSealGroupsUsers } from "../api";
import { useQM } from "../../../../../../share/hook/query/useQM";
const QUERY_KEY = "forSeal/findUsers";
export const useFindUsersForSeal = (groupId: string) => {
  const isValid = groupId !== "";

  return useQM(
    [QUERY_KEY, groupId],
    () => getSealGroupsUsers(groupId + "_Seal"),
    { enabled: isValid }
  );
};
