import {
  DInject,
  DInjectable
} from "presentation/core/features/dependencyInjection";
import { ApiUrls } from "infrastructure/api/struct/ApiTypesV2";
import { HttpClient, HttpMethod } from "infrastructure/service/HttpClient";
import { ErasedDocumentRepository } from "domain/service/recordRetention/ErasedDocumentRepository";
import {
  ApiErasedDocument,
  ErasedDocumentApiMapper
} from "./ErasedDocumentApiMapper";
import {
  ErasedDocument,
  ErasedDocumentId
} from "../../../domain/struct/recordRetention/ErasedDocument";

const URL: ApiUrls = {
  GetOneErasedDocument: "/api/app/v2/document/{nodeId}/erased"
};

@DInjectable()
export class ErasedDocumentApiRepository implements ErasedDocumentRepository {
  constructor(@DInject(HttpClient) protected httpClient: HttpClient) {}

  findErasedDocumentById(id: ErasedDocumentId): Promise<ErasedDocument> {
    return this.httpClient
      .fetchWithThrow<ApiErasedDocument>(
        URL.GetOneErasedDocument,
        HttpMethod.GET,
        {
          urlWildCards: {
            nodeId: id
          }
        }
      )
      .then(ErasedDocumentApiMapper.mapToDomainErasedDocument);
  }
}
