import { DInjectable } from "presentation/core/features/dependencyInjection";
import { Address } from "../../../../domain/struct/nameRegistry/Address";

@DInjectable()
export class AddressListHelpers {
  findAddressByType(addresses: Address[], type: string) {
    return addresses.find((address) => type === address.addressType);
  }

  findAddressById(id: number, addresses: Address[]) {
    return addresses.find((address) => address.id === id);
  }
}
