import { BaseModalProps } from "@isfg/react-components/build/Modal/useModal";
import { Button } from "presentation/designSystem/Button/Button";
import { useForm } from "presentation/designSystem/Form/v2/Form";
import { ModalWithRefresh } from "presentation/designSystem/Modal/ModalWithRefresh";
import { RemoteTableApi } from "presentation/designSystem/Table/RemoteTable";
import { useRemoteTableApi } from "presentation/designSystem/Table/Table";
import { RemoteTableApiContextProvider } from "presentation/designSystem/Table/contexts/RemoteTableApiContextProvider";
import { Tabs } from "presentation/designSystem/Tabs/Tabs";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import React, { useState } from "react";
import { TypeFileHistoryTab } from "../components/TypeFileHistoryTab";
import { TypeFileDetailsForm } from "../form/TypeFilesDetailOpenForm";
import { DetailFormFieldTypes, DetailTypeFileDocument } from "../types";
import { TypeFilesContentTab } from "./tabs/TypeFilesContentTab";

export interface TypeFileDetailModalOpenProps {
  onCancel?: BaseModalProps["onCancel"];
  document: DetailTypeFileDocument;
  refreshOpenTypeFiles: RemoteTableApi["refetch"];
}

enum TabKeys {
  Metadata = "0",
  Content = "1",
  History = "2"
}

export const TypeFileDetailModalOpen = (
  props: TypeFileDetailModalOpenProps
) => {
  return (
    <RemoteTableApiContextProvider>
      <TypeFileDetailOpenModalInner {...props} />
    </RemoteTableApiContextProvider>
  );
};

export const TypeFileDetailOpenModalInner = ({
  onCancel,
  document,
  refreshOpenTypeFiles
}: TypeFileDetailModalOpenProps) => {
  const [form] = useForm<DetailFormFieldTypes>();
  const [activeTab, setActiveTab] = useState("0");
  const [tableApi, onRemoteTableApi] = useRemoteTableApi();

  const changeActiveTab = (activeTabIndex: string) => {
    setActiveTab(activeTabIndex);
  };

  const tabsWithRefresh = {
    [TabKeys.History]: tableApi.refetch
  };

  return (
    <>
      <ModalWithRefresh
        open={true}
        title={t(translationPath(lang.dialog.title.typeFileDetail))}
        hasTabs
        onRefresh={tabsWithRefresh[activeTab as keyof typeof tabsWithRefresh]}
        onCancel={onCancel}
        confirmLoading={false}
        footer={[
          <Button key="back" onClick={onCancel}>
            {t(translationPath(lang.modal.cancel))}
          </Button>
        ]}
      >
        <Tabs onChange={changeActiveTab} activeKey={activeTab}>
          <Tabs.TabPane
            tab={t(translationPath(lang.dialog.tabs.metadata))}
            key={TabKeys.Metadata}
          >
            <TypeFileDetailsForm form={form} formData={document} />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={t(translationPath(lang.dialog.tabs.content))}
            key={TabKeys.Content}
          >
            <TypeFilesContentTab
              nodeId={document.nodeId!}
              refreshOpenTypeFiles={refreshOpenTypeFiles}
              isOpenContent
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={t(translationPath(lang.dialog.tabs.history))}
            key={TabKeys.History}
          >
            <TypeFileHistoryTab
              nodeId={document.nodeId!}
              onRemoteTableApi={onRemoteTableApi}
            />
          </Tabs.TabPane>
        </Tabs>
      </ModalWithRefresh>
    </>
  );
};
