import {
  GetRemoteDataClb,
  RemoteTable
} from "presentation/designSystem/Table/RemoteTable";
import {
  DocumentConcernedSubject,
  DocumentSubjectRelationType
} from "domain/struct/document/DocumentConcernedSubject";
import { createColumn } from "presentation/designSystem/Table/column/column";
import {
  ColumnType,
  TableAction,
  TableActions
} from "presentation/designSystem/Table/Table";
import React, { useEffect, useMemo } from "react";
import { useLogic } from "./useLogic";
import {
  actionAdd,
  actionRemove,
  actionShowDetail
} from "../../../components/table/actionFactory";
import { SubjectId } from "../../../../../domain/struct/nameRegistry/Subject";
import { getRelationTypeTranslation, getTranslation } from "./helper";
import { TabAndDialogChannelType } from "../../../../core/components/dialog/_types";

const COLUMNS: ColumnType<DocumentConcernedSubject>[] = [
  createColumn({
    dataIndex: ["subject", "fullName"],
    title: getTranslation("subjectName")
  }),
  createColumn({
    dataIndex: ["subject", "subjectType"],
    title: getTranslation("subjectType")
  }),
  createColumn({
    dataIndex: "relationType",
    title: getTranslation("relationType"),
    render(value) {
      return getRelationTypeTranslation(value);
    }
  })
];

export interface ConcernedSubjectTableProps {
  entityId: string; // used for cache key - so it is scoped
  getSubjects: GetRemoteDataClb<DocumentConcernedSubject>;
  removeConcernedSubjects: (subjectIds: SubjectId[]) => Promise<void>;
  addConcernedSubjects: (subjectIds: SubjectId[]) => Promise<void>;
  readonly: boolean;
  channel?: TabAndDialogChannelType;
}

export const ConcernedSubjectTable = (props: ConcernedSubjectTableProps) => {
  const { readonly, getSubjects, channel, entityId } = props;
  const {
    modal,
    onShowDetail,
    onAddConcernedSubjects,
    onRemoveConcernedSubjects,
    refreshData,
    setTableApi
  } = useLogic(props);

  useEffect(() => {
    if (channel && !channel.refreshData) {
      channel.refreshData = refreshData;
    }
  }, [channel, refreshData]);

  const ACTIONS: TableActions<DocumentConcernedSubject> = useMemo(() => {
    const ACTION_REMOVE: TableAction<DocumentConcernedSubject> = actionRemove({
      action: onRemoveConcernedSubjects,
      isDisabled: readonly,
      canBeDisplayed(selected) {
        return (
          selected.relationType === DocumentSubjectRelationType.ConcernedSubject
        );
      }
    });
    return {
      default: [
        actionAdd({
          action: onAddConcernedSubjects,
          isDisabled: readonly
        })
      ],
      single: [
        actionShowDetail({
          action: onShowDetail
        }),
        ACTION_REMOVE
      ],
      multi: [ACTION_REMOVE]
    };
  }, [
    onAddConcernedSubjects,
    onRemoveConcernedSubjects,
    onShowDetail,
    readonly
  ]);

  return (
    <>
      {modal}
      <RemoteTable<DocumentConcernedSubject>
        name={`nameRegister/concernedSubject/${entityId}`}
        columns={COLUMNS}
        actions={ACTIONS}
        getRemoteData={getSubjects}
        onRemoteTableApi={setTableApi}
        queryConfig={{
          refetchOnWindowFocus: false,
          refetchOnMount: false
        }}
      />
    </>
  );
};
