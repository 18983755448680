import { call, put, takeEvery } from "redux-saga/effects";
import { fetchSaga } from "presentation/share/utils/fetch";
import { ActionType, getType } from "typesafe-actions";
import { ApiURL } from "../../../apiURL";
import { ErrorResponseType } from "../../models";
import { getGroupMembersAction } from "./_actions";
import { GetGroupMembersSuccessType } from "./_types";

export function* watchGetGroupMembers() {
  yield takeEvery(
    getType(getGroupMembersAction.request),
    function* ({ payload }: ActionType<typeof getGroupMembersAction.request>) {
      const { groupId } = payload;

      const url = groupId.includes("Sign")
        ? ApiURL.SIGHNER_GROUP_MEMBERS
        : ApiURL.GROUP_MEMBERS;
      const {
        errorResponse,
        response,
        success
      }: {
        response: GetGroupMembersSuccessType;
        errorResponse: ErrorResponseType;
        success: boolean;
      } = yield call(fetchSaga, url, "GET", {
        urlWildCards: {
          groupId
        }
      });

      if (!success) {
        yield put(getGroupMembersAction.failure(errorResponse));
        return;
      }

      yield put(getGroupMembersAction.success(response));
    }
  );
}
