import { DialogTabContentPropsType } from "presentation/core/components/dialog/_types";
import React from "react";
import { useDocumentId } from "../../hooks/useDocumentId";
import ShipmentComponentTabContainer from "./ShipmentComponentTabContainer";

export const ComponentsShipmentTab = (props: DialogTabContentPropsType) => {
  const documentId = useDocumentId(props);

  if (documentId) {
    return (
      <ShipmentComponentTabContainer
        nodeId={documentId}
        isReadonly={
          props.dialogProps.isReadonly || props.dialogProps.isComponentReadonly
        }
        {...props}
      />
    );
  }

  return <></>;
};
