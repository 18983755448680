import fileSize from "filesize";
import React from "react";

export interface FileSizeProps {
  size: number;
}

export const FileSize = ({ size }: FileSizeProps) => {
  return <span>{fileSize.filesize(size)}</span>;
};
