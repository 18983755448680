import React, { useEffect } from "react";
import MenuLayout from "presentation/core/components/layout/MenuLayout";
import { useCreateTechnicalDataCarrier } from "./hook/useCreateTechnicalDataCarrier";
import Loading from "presentation/core/components/dataTable/components/Loading";

export const TechnicalDataCarrier = () => {
  const { modal, create, isLoading } = useCreateTechnicalDataCarrier();

  useEffect(() => {
    create([]);
  }, [create]);

  return (
    <MenuLayout>
      <>
        {modal}
        {isLoading && <Loading />}
      </>
    </MenuLayout>
  );
};
