import styled from "presentation/styles";
import { Input } from "presentation/designSystem/Input/Input";

export const StyledChangedInput = styled(Input.Password)(() => ({
  /* background: "#F2F2F2",
  border: "1px solid #C6CDD3",
  borderRadius: 3,
  fontSize: "15px",
  height: "50px",
  marginTop: 7,
  marginBottom: 0,
  padding: " 6px 4px 6px"*/
}));
