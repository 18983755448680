import {
  DInject,
  DInjectable
} from "presentation/core/features/dependencyInjection";
import { ApiUrls } from "infrastructure/api/struct/ApiTypes";
import { HttpClient } from "infrastructure/service/HttpClient";
import { ConversionAllRepository } from "domain/service/administration/ConversionAllRepository";
import { ConversionAllApiMapper } from "./ConversionAllApiMapper";
import { PaginatedResult, PaginationConfig } from "lib/contract/Pagination";
import { SortingConfig } from "lib/contract/Sorting";
import { ConversionAll } from "../../../domain/struct/administration/ConversionAll";

const URL: ApiUrls = { FindAll: "/api/admin/v1/conversion/all" };

@DInjectable()
export class ConversionAllApiRepository implements ConversionAllRepository {
  constructor(@DInject(HttpClient) protected httpClient: HttpClient) {}
  findAll(
    pagination: PaginationConfig,
    sorting?: SortingConfig
  ): Promise<PaginatedResult<ConversionAll>> {
    return this.httpClient
      .fetchPaginated<ConversionAll>({
        url: URL.FindAll,
        pagination,
        sorting
      })
      .then(ConversionAllApiMapper.mapListToDomain);
  }
}
