import { getService } from "presentation/core/features/dependencyInjection";
import { useQM } from "../../../share/hook/query/useQM";
import { FindErasedFile } from "../../../../useCase/recordRetention/FindErasedFile";

export const useFindFileErased = (nodeId: string) => {
  const findErasedFile = getService(FindErasedFile);

  return useQM(["recordRetention/erasedFile", nodeId], () =>
    findErasedFile.findErasedFileById(nodeId)
  );
};
