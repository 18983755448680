import { BaseModalProps } from "presentation/designSystem/Modal/useModal";
import { getErrorCodeTranslation } from "presentation/share/utils/errorCodeTranslation";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import styled from "styled-components";
import { useStyles } from "../../../../../core/components/dialog/Dialog.styles";
import { Modal, ModalSize } from "../../../../../designSystem/Modal/Modal";
import { useMutation } from "../../../../../share/hook/query/useMutation";
import { usePostDocumentsForSignature } from "../hooks/usePostDocumentsForSignature";
import { DocumentsForSignatureTable } from "../tables/DocumentsForSignatureTable";
import React from "react";

export interface ForSealCancelProps extends BaseModalProps {
  nodeId: string;
  nodeType: string;
  dialogType: string;
}

export const DocumentsForSignatureModal = ({
  nodeId,
  nodeType,
  onOk,
  onCancel
}: ForSealCancelProps) => {
  const getNotificationForError = (error: any) => ({
    message: getErrorCodeTranslation(error.code)
  });
  const classes = useStyles();
  const postDocumentsForSignature = usePostDocumentsForSignature();
  const { isLoading, mutate: documentForSign } = useMutation(
    (nodeId: string) => postDocumentsForSignature(nodeId),
    {
      onSuccess(data) {
        onOk?.();
      },
      onSuccessNotification: {
        message: t(
          translationPath(lang.dialog.notifications.postDocumentForSeal)
        )
      },
      onErrorNotification: getNotificationForError
    }
  );

  const handleOk = () => {
    documentForSign(nodeId);
  };

  return (
    <Modal
      open={true}
      title={t(translationPath(lang.dialog.title.forSignature))}
      onCancel={onCancel}
      onOk={handleOk}
      confirmLoading={isLoading}
      className={classes.fromSignatureContainer}
      size={ModalSize.FullScreen}
      okText={t(translationPath(lang.modal.okForSeal))}
      cancelText={t(translationPath(lang.modal.cancel))}
    >
      <StyledDiv>
        <DocumentsForSignatureTable
          selectedNodeId={nodeId}
          nodeType={nodeType}
        />
      </StyledDiv>
    </Modal>
  );
};

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1.5rem;
`;
