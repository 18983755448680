import { ActiveComponent } from "presentation/core/components/dialog/tabs/components/_types";
import { useCallback } from "react";
import { updateActiveComponents } from "../api";

export const useUpdateActiveComponents = () => {
  return useCallback(
    (nodeId: string, body: ActiveComponent) =>
      updateActiveComponents(nodeId, body),
    [updateActiveComponents]
  );
};
