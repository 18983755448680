export enum AdministrationRoutes {
  ADMINISTRATION_CERTIFICATE = "/administration/certificate",
  ADMINISTRATION_CODELIST = "/administration/code-list",
  ADMINISTRATION_CONVERSION = "/administration/conversion",
  ADMINISTRATION_ORGANIZATION_UNIT = "/administration/organization-unit",
  ADMINISTRATION_USERS = "/administration/users",
  ADMINISTRATION_NAME_REGISTER_CONFIGURATION = "/administration/name-register-configuration",
  ADMINISTRATION_TRANSACTION_PROTOCOL_DAILY_IMPRINT = "/administration/transaction-protocol/daily-imprint/overview",
  ADMINISTRATION_TRANSACTION_PROTOCOL_DAILY_IMPRINT_SEARCH = "/administration/transaction-protocol/search",
  ADMINISTRATION_FILE_PLANS = "/administration/file-plans/overview",
  ADMINISTRATION_DISPATCH_WHITELIST = "/administration/dispatch-whitelist"
}
