import { ConcernedSubjectTable } from "./ConcernedSubjectTable";
import { getService } from "../../../../core/features/dependencyInjection";
import { GetRemoteDataClb } from "../../../../designSystem/Table/RemoteTable";
import { DocumentConcernedSubject } from "../../../../../domain/struct/document/DocumentConcernedSubject";
import React, { useCallback } from "react";
import { FindConceptConcernedSubject } from "../../../../../useCase/document/concernedSubject/FindConceptConcernedSubject";
import { RemoveConceptConcernedSubject } from "../../../../../useCase/document/concernedSubject/RemoveConceptConcernedSubject";
import { AddConceptConcernedSubject } from "../../../../../useCase/document/concernedSubject/AddConceptConcernedSubject";
import { SubjectId } from "../../../../../domain/struct/nameRegistry/Subject";
import { TabAndDialogChannelType } from "../../../../core/components/dialog/_types";
import { useRemoteTableApiContext } from "presentation/designSystem/Table/contexts/RemoteTableApiContextProvider";

export interface ConceptConcernedSubjectTableProps {
  documentId: string;
  readonly: boolean;
  channel?: TabAndDialogChannelType;
}

export const ConceptConcernedSubjectTable = ({
  documentId,
  readonly,
  channel
}: ConceptConcernedSubjectTableProps) => {
  const findUseCase = getService(FindConceptConcernedSubject);
  const removeUseCase = getService(RemoveConceptConcernedSubject);
  const addUseCase = getService(AddConceptConcernedSubject);

  const { refreshData } = useRemoteTableApiContext();

  const getSubjects: GetRemoteDataClb<DocumentConcernedSubject> = useCallback(
    ({ pagination }) => findUseCase.findPaginated(documentId, pagination),
    [documentId, findUseCase]
  );

  const removeConcernedSubjects = useCallback(
    async (subjectIds: SubjectId[]) => {
      await removeUseCase.removeConcernedSubjects(documentId, subjectIds);
      refreshData();
    },
    [documentId, removeUseCase, refreshData]
  );

  const addConcernedSubjects = useCallback(
    async (subjectIds: SubjectId[]) => {
      await addUseCase.addConcernedSubjects(documentId, subjectIds);
      refreshData();
    },
    [addUseCase, documentId, refreshData]
  );

  return (
    <ConcernedSubjectTable
      entityId={documentId}
      getSubjects={getSubjects}
      removeConcernedSubjects={removeConcernedSubjects}
      addConcernedSubjects={addConcernedSubjects}
      readonly={readonly}
      channel={channel}
    />
  );
};
