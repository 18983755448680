import React, { useMemo } from "react";
import SenderFormAnt, { SenderFormNewProps } from "../SenderFormAnt";
import { DocumentSender, mapDocumentToSenderForm } from "../utils/utils";
import { useFindCountries } from "presentation/modules/nameRegister/hooks/useFindCountries";
import { DeliveryMode } from "presentation/enums";

export interface UseSenderFormProps extends SenderFormNewProps {
  senderData?: DocumentSender;
}

export const useSenderForm = ({
  senderData,
  ...senderFormProps
}: UseSenderFormProps) => {
  const { deliveryMode, initialDatabox, initialEmail } = senderFormProps;
  const { data: countryCodesAndNames } = useFindCountries({ retry: false });
  const senderFormData = useMemo(() => {
    return {
      senderData,
      ...mapDocumentToSenderForm(senderData || {}, countryCodesAndNames),
      ...(deliveryMode === DeliveryMode.Email &&
        initialEmail && {
          senderContactEmail: initialEmail
        }),
      ...(deliveryMode === DeliveryMode.Databox &&
        initialDatabox && {
          senderContactDatabox: initialDatabox
        })
    };
  }, [
    senderData,
    countryCodesAndNames,
    deliveryMode,
    initialDatabox,
    initialEmail
  ]);

  const senderForm = useMemo(() => <SenderFormAnt {...senderFormProps} />, [
    senderFormProps
  ]);

  return {
    senderFormData,
    senderForm
  };
};
