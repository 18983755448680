import { ApiTypes } from "../struct/ApiTypes";
import { AddressType } from "../../../domain/struct/codelist/AddressType";
import { Address } from "../../../domain/struct/nameRegistry/Address";

export type ApiAddressTypes = ApiTypes["ISFG.SpisUm.ClientSide.Models.NameRegister.CodeLists.AddressType"];
export type ApiAddress = ApiTypes["ISFG.SpisUm.ClientSide.Models.AddressBook.AddressCreate"];

export class AddressTypeApiMapper {
  static mapToDomain(response: ApiAddressTypes[]): AddressType[] {
    return response.map((apiAddressType) => ({
      code: apiAddressType.code || "",
      czechName: apiAddressType.czechName || "",
      subjectType: apiAddressType.subjectType || "",
      minOccurrence: apiAddressType.minOccurrence || 0,
      maxOccurrence: apiAddressType.maxOccurrence || 0
    }));
  }

  static mapToApi(address: Address): ApiAddress {
    return {
      addressType: address.addressType,
      street: address.street,
      houseNumberType: address.houseNumberType,
      houseNumber: address.houseNumber,
      houseReferenceNumber: address.orientationHouseNumber,
      houseReferenceNumberAppendix: address.orientationHouseNumberAddendum,
      city: address.city,
      cityDistrict: address.cityPart,
      zipCode: address.postalCode,
      countryCode: address.country
    };
  }

  static mapAddressesToApi(addresses: Address[]): ApiAddress[] {
    return addresses.map(AddressTypeApiMapper.mapToApi);
  }
}
