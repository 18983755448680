import { ApiListResponse, ApiTypes } from "../struct/ApiTypesV2";
import { ShipmentComponent } from "../../../domain/struct/shipment/ShipmentComponent";
import { ApiListResponseMapper } from "../mapper/ApiListResponseMapper";

export type ApiDocumentShipableComponent = ApiTypes["ISFG.SpisUm.ClientSide.Models.V2.Document.Response.DocumentShipmentComponentsResponse"];
export type ApiShipmentComponent = ApiTypes["ISFG.SpisUm.ClientSide.Models.V2.Shipments.ShipmentComponentResponse"];

export class ShipmentComponentApiMapper {
  static mapListToDomain(
    response: ApiListResponse<ApiDocumentShipableComponent>
  ) {
    return ApiListResponseMapper.mapListToPagination(
      response,
      ShipmentComponentApiMapper.mapToDomain
    );
  }

  static mapToDomain(
    apiComponent: ApiDocumentShipableComponent | ApiShipmentComponent
  ): ShipmentComponent {
    return {
      id: apiComponent.id!,
      isSelectable:
        "isSelectable" in apiComponent ? apiComponent.isSelectable! : false,
      size: apiComponent.sizeInBytes!,
      fileName: apiComponent.fileName!,
      mimeType: apiComponent.mimeType!,
      isEncrypted: apiComponent.isEncrypted
    };
  }
}
