import {
  DInject,
  DInjectable
} from "presentation/core/features/dependencyInjection";
import { ApiUrls } from "infrastructure/api/struct/ApiTypesV2";
import { HttpClient } from "infrastructure/service/HttpClient";
import { ImprintRepository } from "domain/service/administration/ImprintRepository";
import { ApiImprint, ImprintApiMapper } from "./ImprintApiMapper";
import { PaginatedResult, PaginationConfig } from "lib/contract/Pagination";
import { SortingConfig } from "lib/contract/Sorting";
import { Imprint } from "../../../domain/struct/administration/Imprint";

const URL: ApiUrls = {
  FindAll: "/api/admin/v2/transaction-history/imprints"
};

@DInjectable()
export class ImprintApiRepository implements ImprintRepository {
  constructor(@DInject(HttpClient) protected httpClient: HttpClient) {}
  findAll(
    pagination: PaginationConfig,
    sorting?: SortingConfig
  ): Promise<PaginatedResult<Imprint>> {
    return this.httpClient
      .fetchPaginated<ApiImprint>({
        url: URL.FindAll,
        pagination,
        sorting
      })
      .then(ImprintApiMapper.mapListToDomain);
  }
}
