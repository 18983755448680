import { useModal } from "@isfg/react-components/build/Modal/useModal";
import { Description } from "@mui/icons-material";
import BackspaceIcon from "@mui/icons-material/Backspace";
import GestureIcon from "@mui/icons-material/Gesture";
import { openDocumentWithSaveButtonsAction } from "presentation/core/api/document/_actions";
import { useRemoteTableApi } from "presentation/designSystem/Table/Table";
import { SpisumNodeTypes } from "presentation/enums";
import {
  GetRemoteDataClb,
  RemoteTable
} from "presentation/govDesignSystem/Table/RemoteTable";
import { RemoteTableApiContextProvider } from "presentation/govDesignSystem/Table/contexts/RemoteTableApiContextProvider";
import { RootStateType } from "presentation/reducers";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataColumn } from "../../../../../core/components/dataTable/_types";
import { dialogOpenAction } from "../../../../../core/components/dialog/_actions";
import { DialogType } from "../../../../../core/components/dialog/_types";
import { useFindSignatureBookForSignature } from "../hooks/useFindSignatureBookForSignature";
import { DocumentsForSignatureModal } from "../modal/DocumentsForSignatureModal";
import { ForSignature } from "../types/ForSignature";

const defaultColumn: DataColumn<ForSignature> = {
  isDateTime: true,
  sorter: true,
  keys: ["forSignatureDate"],
  label: t(translationPath(lang.general.requestDate)),
  defaultSortOrder: "ascend"
};
export const COLUMNS: DataColumn<ForSignature>[] = [
  {
    keys: ["pid"],
    label: t(translationPath(lang.general.identifier)),
    sorter: true,
    defaultSortOrder: "ascend"
  },
  {
    keys: ["ssid"],
    label: t(translationPath(lang.general.referenceNumber)),
    sorter: true
  },
  {
    keys: ["subject"],
    label: t(translationPath(lang.general.subject)),
    sorter: true
  },
  {
    sorter: true,
    keys: ["attachmentsCount"],
    label: t(translationPath(lang.general.attachmentsCount))
  },
  {
    sorter: true,
    keys: ["owner"],
    label: t(translationPath(lang.general.owner))
  },
  defaultColumn
];

export const ForSignatureTable = React.memo(() => {
  const dispatch = useDispatch();
  const session = useSelector(
    (state: RootStateType) => state.loginReducer.session
  );
  const [tableApi, onRemoteTableApi] = useRemoteTableApi();
  const findSignatureBookForSignature = useFindSignatureBookForSignature();
  const [modal, modalApi] = useModal(DocumentsForSignatureModal, {
    onClose() {}
  });
  const ACTIONS = useMemo(
    () => ({
      single: [
        {
          key: "signature-book-for-signature-detail",
          icon: <Description />,
          tooltip: t(translationPath(lang.general.showDetails)),
          action(selected: ForSignature[]) {
            dispatch(
              openDocumentWithSaveButtonsAction({
                data: {
                  id: selected[0].nodeId
                },
                canUploadComponents: false,
                hideManageShipmentsIcon: true,
                initiator: SpisumNodeTypes.Document,
                isReadonly: true
              })
            );
          }
        },
        {
          key: "signature-book-for-signature",
          icon: <GestureIcon />,
          tooltip: t(translationPath(lang.general.forSignature)),
          action(selected: ForSignature[]) {
            return modalApi.open({
              nodeId: selected[0].nodeId!,
              nodeType: SpisumNodeTypes.Document,
              dialogType: "documentsForSignature"
            });
          }
        },
        {
          key: "signature-book-for-signature-return-for-work",
          icon: <BackspaceIcon />,
          tooltip: t(translationPath(lang.general.signatureReturnForRework)),
          action(selected: ForSignature[]) {
            dispatch(
              dialogOpenAction({
                dialogProps: {
                  data: {
                    ...selected[0],
                    id: selected[0].nodeId
                  },
                  onClose: () => {
                    tableApi.refetch();
                  }
                },
                dialogType: DialogType.ReturnForRework
              })
            );
          }
        }
      ]
    }),
    [dispatch, modalApi, tableApi]
  );

  const getRemoteData: GetRemoteDataClb<ForSignature> = useCallback(
    ({ pagination, sorting }) =>
      findSignatureBookForSignature(pagination, sorting),
    [findSignatureBookForSignature]
  );

  return (
    <>
      {modal}

      <RemoteTableApiContextProvider<ForSignature>
        children={
          <RemoteTable<ForSignature>
            title={"documentForSignature"}
            name="signatureBook/SignatureBookForSignature"
            actions={ACTIONS}
            columns={COLUMNS}
            defaultSortAsc={true}
            defaultSortColumn={defaultColumn}
            onRemoteTableApi={onRemoteTableApi}
            getRemoteData={getRemoteData}
          />
        }
      />
    </>
  );
});
