import { DocumentDetailsFormValues } from "../forms/DocumentDetailsForm";
import { DocumentUpdateUnsettledRequest } from "../_types";

export const mapDocumentToApi = (
  input: DocumentDetailsFormValues
): DocumentUpdateUnsettledRequest => {
  return {
    ...input,
    senderId: input.senderId ? Number(input.senderId) : undefined,
    settleToDate: input.settleToDate?.toISOString()
  };
};
