import { PaginatedResult } from "../../../lib/contract/Pagination";
import { Person } from "../../../domain/struct/nameRegistry/Person";
import { ApiTypes } from "../struct/ApiTypes";
import { CreatePayload } from "../../../domain/service/nameRegistry/PersonRepository";
import { components } from "../generated/SpisumApiTypes";
import { ContactApiMapper } from "./ContactApiMapper";
import { SubjectApiMapper } from "./SubjectApiMapper";
import { AddressApiMapper } from "./AddressApiMapper";
import { formatDate } from "../dateTime";
import { ApiListResponse } from "../struct/ApiTypesV2";
import { ApiListResponseMapper } from "../mapper/ApiListResponseMapper";

export type ApiPerson = ApiTypes["ISFG.SpisUm.ClientSide.Models.NameRegister.SubjectInfo"];
export type ApiCreatePerson = components["schemas"]["ISFG.SpisUm.ClientSide.Models.NameRegister.Query.IndividualSubjectCreate"];
export type ApiUpdatePerson = components["schemas"]["ISFG.SpisUm.ClientSide.Models.NameRegister.Query.IndividualSubjectUpdate"];

export class PersonApiMapper {
  static mapFindAllResponse(
    response: ApiListResponse<ApiPerson>
  ): PaginatedResult<Person> {
    return ApiListResponseMapper.mapListToPagination(
      response,
      PersonApiMapper.mapToDomain
    );
  }

  static mapCreatePerson({
    person,
    addresses,
    contacts
  }: CreatePayload): ApiCreatePerson {
    return {
      name: person.firstName,
      surname: person.surname,
      birthSurname: person.maidenName,
      birthdate: formatDate(person.bornDate),
      birthPlace: person.bornPlace,
      nationality: person.citizenship,
      titleBefore: person.title,
      titleAfter: person.degree,
      addresses: AddressApiMapper.mapAddressesToApi(addresses),
      contacts: ContactApiMapper.mapContactsToApi(contacts)
    };
  }

  static mapUpdatePerson({
    firstName,
    surname,
    maidenName,
    bornDate,
    bornPlace,
    citizenship,
    title,
    degree
  }: Person): ApiUpdatePerson {
    return {
      name: firstName,
      surname,
      birthSurname: maidenName,
      birthdate: formatDate(bornDate),
      birthPlace: bornPlace,
      nationality: citizenship,
      titleBefore: title,
      titleAfter: degree
    };
  }

  static mapToDomain(apiPerson: ApiPerson): Person {
    return SubjectApiMapper.mapToPerson(apiPerson);
  }
}
