import {
  ApiListResponse,
  ApiTypes
} from "infrastructure/api/struct/ApiTypesV2";
import { GroupsToSeal, TypeFilesResponse } from "../_types";
import { ApiListResponseMapper } from "../../../../../../../infrastructure/api/mapper/ApiListResponseMapper";
import {
  CodeList,
  CodeListValueResponse
} from "presentation/modules/administration/features/codeList/types";

export type ApiGroupsAndUsersForSealResponse = ApiTypes["ISFG.SpisUm.ClientSide.Models.V1.User.GroupMember"];

export class GroupApiMapper {
  static mapUsersAndGroupsToDomain(
    response: ApiGroupsAndUsersForSealResponse[]
  ): GroupsToSeal[] {
    return response.map((resp) => ({
      id: resp.id || "",
      displayName: resp.displayName || ""
    }));
  }
}

export function mapTypeFileToDomain(list: ApiListResponse<TypeFilesResponse>) {
  return ApiListResponseMapper.mapListToPagination(
    list,
    (typeFile) => typeFile
  );
}

export function mapApiToOtherMethods(
  response: CodeListValueResponse[]
): CodeList[] {
  return response.filter((item): item is CodeList => Boolean(item));
}
