import { ApiTypes } from "../struct/ApiTypes";
import { Shipment } from "../../../domain/struct/shipment/Shipment";

export type ApiShipment = ApiTypes["ISFG.SpisUm.ClientSide.Models.Shipments.Response.ShipmentResponse"];

export class ShipmentApiMapper {
  static mapToDomain(apiShipment: ApiShipment): Shipment {
    return {
      id: apiShipment.shipment?.id || "",
      componentIds: apiShipment.componentIds || []
    };
  }
}
