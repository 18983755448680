import {
  Grid,
  LabelDisplayedRowsArgs,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import clsx from "clsx";
import { unionBy } from "lodash";
import { DataTableValues } from "presentation/core/components/documentView/_types";
import { useSafeSetState } from "presentation/core/hooks/useSafeSetState";
import { RootStateType } from "presentation/reducers";
import { translationPath } from "presentation/share/utils/getPath";
import { getPaginationLabel } from "presentation/share/utils/utils";
import { lang, t } from "presentation/translation/i18n";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  PaperLayoutStyled,
  TableContainerLayoutStyled,
  WrapperAboveTable,
  WrapperStyled,
  useStyles
} from "./DataTable.styles";
import { ControlsBarType, DataColumn } from "./_types";
import { BreadcrumbForTable } from "./components/Breadcrumb/Breadcrumb";
import Loading from "./components/Loading";
import NoData from "./components/NoData";
import { Row, RowProps } from "./components/Row";
import { TableActionsBar } from "./components/TableActionsBar/TableActionsBar";
import {
  TableAction,
  TableActions
} from "./components/TableActionsBar/contract";
import {
  RemoteTableApiContext,
  RemoteTableApiState
} from "./contexts/RemoteTableApiContextProvider";

interface OwnProps<T> {
  pid?: string;
  title?: string;
  columns: DataColumn<T>[];
  controls?: ControlsBarType<T>;
  customTitle?: string;
  customActionBarClassName?: string;
  dataTableValues?: DataTableValues;
  defaultActionFirst?: boolean;
  footerText?: string;
  readonly?: boolean;
  isRowDisabled?: (row: T) => boolean;
  handleCheckboxRowClick?: (row: T) => void;
  handleCustomRowClick?: (row: T) => void;
  handleDoubleClick?: (row: T) => void;
  handleSortingChange: (
    index: number,
    keys: string[]
  ) => (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void;
  handleChangePage: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void;
  handleChangeRowsPerPage: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleColumnChange?: RowProps<T>["onColumnChange"];
  handleSelectionChange?: (selection: T[]) => void;
  hideCheckbox?: boolean;
  pageNumber: number;
  pending?: boolean;
  refreshPending?: boolean;
  refreshTable?: VoidFunction;
  rows: T[];
  rowsCount: number;
  rowsPerPage: number;
  selected?: T[];
  sortAsc?: boolean;
  sortColumnIndex?: number | null;
  paginationClassName?: string;
  tableLayoutClassName?: string;
  tableWrapperClassName?: string;
  noShipmentHeightClassName?: string;
  isDateNotValid?: (row: T) => boolean;
  actions?: TableActions<T>;
  onActionFinished?: (action: TableAction<T>, dataWasMutated: boolean) => void;
}

const MIN_LOADING_TIME = 1500;

export interface ViewType {
  pid?: string;
}

const DataTable = <T extends ViewType>(props: OwnProps<T>) => {
  const {
    title,
    columns,
    customActionBarClassName,
    dataTableValues,
    footerText,
    readonly,
    handleDoubleClick,
    handleCheckboxRowClick,
    handleCustomRowClick,
    handleChangePage,
    handleChangeRowsPerPage,
    handleSortingChange,
    handleColumnChange,
    handleSelectionChange,
    hideCheckbox,
    isRowDisabled,
    pageNumber,
    pending,
    refreshPending,
    rows,
    rowsCount,
    rowsPerPage,
    sortAsc,
    sortColumnIndex,
    paginationClassName,
    tableLayoutClassName = "",
    tableWrapperClassName = "",
    noShipmentHeightClassName = "",
    isDateNotValid,
    actions,
    onActionFinished
  } = props;

  useTranslation();
  const classes = useStyles();
  useSelector((state: RootStateType) => state.tableActionReducer);
  const [selectedInState, setSelectedInState] = useSafeSetState<T[]>([]);
  const selected = props.selected || selectedInState;
  const [minLoadingTimePassed, setMinLoadingTimePassed] =
    useSafeSetState<boolean>(!pending);
  // Minimal loading time
  useEffect(() => {
    if (!pending) return;
    setMinLoadingTimePassed(false);
    window.setTimeout(() => {
      setMinLoadingTimePassed(true);
    }, MIN_LOADING_TIME);
  }, [pending]); // eslint-disable-line react-hooks/exhaustive-deps

  const setSelected = (items: T[]) => {
    setSelectedInState(items);
    handleSelectionChange?.(items);
  };

  useEffect(() => {
    if (dataTableValues && dataTableValues.resetIcons) {
      dataTableValues.resetIcons = false;
      setSelected(selected);
    }
  }, [dataTableValues]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelected = (
    row: T,
    append: boolean,
    alreadySelected: boolean
  ) => {
    if (append) {
      if (alreadySelected) {
        return setSelected(selected.filter((s) => s.pid !== row.pid));
      }
      setSelected(unionBy([...selected, row], "pid"));
    } else {
      setSelected([row]);
    }
  };

  const handleRowClick = (event: React.MouseEvent, row: T) => {
    const target = event.target as HTMLElement;
    const alreadySelected = isSelected(row);

    if (!isRowDisabled?.(row)) {
      if (target.tagName === "TD") {
        const e = event as React.MouseEvent<HTMLTableRowElement>;
        // metaKey for MacOS system cmd key instead of ctrl
        handleSelected(
          row,
          e.ctrlKey || e.metaKey || alreadySelected,
          alreadySelected
        );
        handleCustomRowClick?.(row);
      } else if (
        target.tagName === "INPUT" &&
        (target as HTMLInputElement).type === "checkbox"
      ) {
        handleSelected(row, true, alreadySelected);
        handleCheckboxRowClick?.(row);
      }
    }
  };

  const handleRowDoubleClick = (row: T) => handleDoubleClick?.(row);

  const handleAllRowsSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const validRows = isRowDisabled
        ? rows.filter((row) => !isRowDisabled?.(row))
        : rows;
      setSelected(validRows as T[]);
      return;
    }
    setSelected([]);
  };

  const selectedIds = selected.map((s) => s?.pid);
  const isSelected = (row: T) =>
    selectedIds.findIndex((sId) => sId === row?.pid) !== -1;

  const refreshPendingText = t(
    translationPath(lang.general.refreshPendingTableText)
  );

  const PaginatorWrapper: React.FC<{ children?: React.ReactNode }> = ({
    children
  }) => {
    return (
      <div className={clsx(classes.pagination, paginationClassName)}>
        <Grid container={true}>
          <Grid className={classes.footerText} item={true} xs={6}>
            {refreshPending ? refreshPendingText : footerText}
          </Grid>
          <Grid item={true} xs={6}>
            {children}
          </Grid>
        </Grid>
      </div>
    );
  };
  const labelDisplayedRows = ({ from, to, count }: LabelDisplayedRowsArgs) =>
    `${from}-${to} ${t(translationPath(lang.general.from))} ${
      count !== -1
        ? count
        : `${t(translationPath(lang.general.moreThan))} ${to}`
    }`;

  const { tableState } = useContext(
    RemoteTableApiContext<T>() as React.Context<RemoteTableApiState<T>>
  );

  let rowSelected = tableState.selected;

  return (
    <WrapperStyled className={`dataTable ${tableWrapperClassName}`}>
      <WrapperAboveTable
        className={`${customActionBarClassName} action-bar__container`}
        container={true}
        alignItems="center"
        justifyContent="space-between"
        alignContent="flex-end"
        direction="row"
      >
        {title && <BreadcrumbForTable title={title}></BreadcrumbForTable>}
        {actions && (
          <TableActionsBar<T>
            actions={actions}
            selectedRows={rowSelected}
            dataSource={rows || []}
            onActionFinished={onActionFinished}
          />
        )}
      </WrapperAboveTable>
      <TableContainerLayoutStyled
        className={clsx(classes.height, tableLayoutClassName)}
      >
        <TableContainer
          className={classes.container}
          component={PaperLayoutStyled}
        >
          {((pending || !minLoadingTimePassed) && <Loading />) ||
            (rows && rows.length && (
              <Table
                stickyHeader={true}
                aria-label="Data Table"
                className={classes.table}
              >
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    {!hideCheckbox && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          // indeterminate={numSelected > 0 && numSelected < rowsCount}
                          // checked={rowsCount > 0 && numSelected === rowsCount}
                          onChange={handleAllRowsSelect}
                        />
                      </TableCell>
                    )}
                    {columns &&
                      columns.length &&
                      columns.map((column) => {
                        const index = columns.indexOf(column);
                        return (
                          <TableCell
                            key={index}
                            sortDirection={
                              sortColumnIndex === index
                                ? sortAsc === true
                                  ? "asc"
                                  : "desc"
                                : false
                            }
                          >
                            <TableSortLabel
                              active={sortColumnIndex === index}
                              direction={
                                sortColumnIndex === index
                                  ? sortAsc === true
                                    ? "asc"
                                    : "desc"
                                  : "desc"
                              }
                              onClick={handleSortingChange(
                                index,
                                column.sortKeys || column.keys || []
                              )}
                            >
                              {column.label}
                            </TableSortLabel>
                          </TableCell>
                        );
                      })}
                  </TableRow>
                </TableHead>
                <TableBody className={classes.tbody}>
                  {rows.map((row, rowIndex) => (
                    <Row
                      key={rowIndex}
                      row={row}
                      columns={columns}
                      hideCheckbox={hideCheckbox}
                      readonly={readonly}
                      selected={isSelected(row)}
                      onRowClick={handleRowClick}
                      onRowDoubleClick={handleRowDoubleClick}
                      onColumnChange={handleColumnChange}
                      rowDisabled={isRowDisabled?.(row)}
                      isDateNotValid={isDateNotValid?.(row)}
                    />
                  ))}
                </TableBody>
              </Table>
            )) || <NoData noDataHeightClassName={noShipmentHeightClassName} />}
          {!pending && minLoadingTimePassed && (
            <TablePagination
              getItemAriaLabel={getPaginationLabel}
              rowsPerPageOptions={[10, 25, 50, 100]}
              component={PaginatorWrapper}
              labelDisplayedRows={labelDisplayedRows}
              labelRowsPerPage={t(translationPath(lang.table.rowsPerPage))}
              count={rowsCount}
              rowsPerPage={rowsPerPage}
              page={pageNumber - 1}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </TableContainer>
      </TableContainerLayoutStyled>
    </WrapperStyled>
  );
};

export default DataTable;
