import { AutoComplete, Select } from "antd";
import Radio, { RadioChangeEvent } from "antd/lib/radio";
import {
  Form,
  FormInstance,
  Item
} from "presentation/designSystem/Form/v2/Form";
import { Col, Row } from "presentation/designSystem/Grid/Grid";
import { Input } from "presentation/designSystem/Input/Input";
import { Tooltip } from "presentation/designSystem/Tooltip/Tooltip";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GenericDocument } from "../../../../../../core/types";
import { SortDirection } from "../../../../../../govDesignSystem/lib/contract/Sorting";
import { RootStateType } from "../../../../../../reducers";
import { useLocalStyles } from "../../../../../../share/components/dialog/createNewDocumentFileDialog/FileRadioGroup.styles";
import { clearResultsAction } from "../../../../../../share/components/dialog/createNewDocumentFileDialog/_actions";
import {
  CreateNewDocumentFileFormValues,
  FileOption
} from "../../../../../../share/components/dialog/createNewDocumentFileDialog/_types";
import { translationPath } from "../../../../../../share/utils/getPath";
import { lang, t } from "../../../../../../translation/i18n";
import {
  FileTypeStructure,
  NewDocumentFileForm,
  TypeFilesResponse
} from "../_types";
import { getFileType } from "../api";
import { useGetTypeFileStructure } from "../hooks/useGetTypeFileStructure";

export interface CreateNewDocumentFileFormProps {
  form: FormInstance<NewDocumentFileForm>;
  document: GenericDocument;
  initialValues: CreateNewDocumentFileFormValues;
  onFileSearchChange: (value: string) => void;
}

interface OptionTypeAntd {
  key: string;
  value: string;
}

const CreateNewDocumentFileForm = ({
  form,
  document,
  initialValues,
  onFileSearchChange
}: CreateNewDocumentFileFormProps) => {
  /*Old Code*/
  const dispatch = useDispatch();

  const { results } = useSelector(
    (state: RootStateType) => state.searchFilesReducer
  );
  const styles = useLocalStyles();
  useEffect(() => {
    form.setFieldsValue({ radioGroupName: FileOption.Existing });
  }, []);

  const [formState, setFormState] = useState<CreateNewDocumentFileFormValues>({
    selected: initialValues.selected
  });

  const onRadioFieldChange = (e: RadioChangeEvent) => {
    form.resetFields();
    setTypeFiles([]);
    setTypeFilesStructure([]);
    dispatch(clearResultsAction());
    setFormState({ selected: e.target.value as FileOption });
    form.setFieldsValue({ radioGroupName: e.target.value });
  };

  const onInputValueChange = (value: string) => {
    onFileSearchChange(value);
  };

  const options: OptionTypeAntd[] = results
    ? results.map((item) => ({
        value: `${item.properties?.ssl?.pid || "-"}, ${
          item.properties?.ssl?.fileIdentifier || "-"
        }, ${item.properties?.ssl?.subject || "-"}`,
        key: item.id
      }))
    : [];

  const isNewFileOptionDisabled = useMemo(() => {
    const { senderCompanyName, senderFullName, senderType } =
      document.properties?.ssl || {};

    return (
      [senderCompanyName, senderFullName, senderType].filter((field) => !!field)
        .length === 0
    );
  }, [document.properties?.ssl]);

  /*----------*/

  const [typeFiles, setTypeFiles] = useState<OptionTypeAntd[]>();
  const [typeFilesStructure, setTypeFilesStructure] =
    useState<FileTypeStructure[]>();
  const [selectedTypeFile, setSelectedTypeFile] = useState<string>("");
  const getFileTypeStructure = useGetTypeFileStructure();
  const searchResult = (result: TypeFilesResponse[]): OptionTypeAntd[] => {
    return result.map((item) => ({
      key: item.nodeId ?? "",
      value: item.pid + ", " + item.subject
    }));
  };
  const onFileTypeValueChange = async (selectedFileType: string) => {
    if (!selectedFileType) {
      console.log("here");
      setTypeFilesStructure([]);
      form.setFieldsValue({ sectionId: "" });
      setSelectedTypeFile(selectedFileType);
      return;
    }
    const data = await getFileType(
      selectedFileType,
      {
        page: 1,
        itemsPerPage: 100
      },
      [
        {
          property: "pid",
          direction: SortDirection.Ascending
        }
      ]
    );
    setTypeFiles(data.items.length > 0 ? searchResult(data.items) : undefined);
  };
  const onSelectFileType = async (value: string, option: any) => {
    setTypeFilesStructure([]);
    form.setFieldsValue({ sectionId: "" });
    setSelectedTypeFile(option.text);
    form.setFieldsValue({ existingFileTypeKey: option.key?.toString() });
    const data = await getFileTypeStructure(option.key);
    setTypeFilesStructure(data);
  };
  const onSelectExistingFile = (value: string, option: any) => {
    form.setFieldsValue({ existingFileKey: option.key?.toString() });
  };
  const handleSelectFileClear = () => {
    setSelectedTypeFile("");
  };
  const handleSectionChange = () => {};

  return (
    <Form
      name="createNewDocumentFileForm"
      form={form}
      style={{ width: "100%" }}
    >
      <Item name={"radioGroupName"} style={{ marginBottom: "-19px" }}>
        <Radio.Group
          value={formState.selected}
          defaultValue={formState.selected}
          className={styles.radioGroupWrapper}
          onChange={onRadioFieldChange}
        >
          <Row gutter={[16, 20]}>
            <Col span={24}>
              <Radio
                value={FileOption.Create}
                disabled={isNewFileOptionDisabled}
              >
                <Tooltip
                  title={
                    isNewFileOptionDisabled
                      ? t(translationPath(lang.general.newFileDisabled))
                      : ""
                  }
                  placement="bottom"
                >
                  {t(translationPath(lang.general.newFile))}
                </Tooltip>
              </Radio>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Row gutter={[16, 10]}>
                <Col span={24}>
                  <Radio value={FileOption.Existing} disabled={false}>
                    {t(translationPath(lang.general.existingFile))}
                  </Radio>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={24}>
                  <Item
                    name={"existing"}
                    rules={[
                      { required: formState.selected === FileOption.Existing }
                    ]}
                    required={formState.selected === FileOption.Existing}
                  >
                    <AutoComplete
                      dropdownMatchSelectWidth={252}
                      style={{
                        width: "100%"
                      }}
                      notFoundContent={t(
                        translationPath(lang.general.noOptionsText)
                      )}
                      options={options}
                      onSelect={onSelectExistingFile}
                      onSearch={onInputValueChange}
                      allowClear={true}
                      disabled={
                        formState.selected === FileOption.Create ||
                        formState.selected === FileOption.ExistingTypeFile
                      }
                    >
                      <Input
                        style={{
                          width: "100%"
                        }}
                        placeholder={t(
                          translationPath(lang.general.inputTitleOrLabelOrId)
                        )}
                      />
                    </AutoComplete>
                  </Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Row gutter={[16, 10]}>
                <Col span={24}>
                  <Radio value={FileOption.ExistingTypeFile}>
                    {t(translationPath(lang.general.existingTypeFile))}
                  </Radio>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Item
                    name={"existingFileType"}
                    rules={[
                      {
                        required:
                          formState.selected === FileOption.ExistingTypeFile
                      }
                    ]}
                    required={
                      formState.selected === FileOption.ExistingTypeFile
                    }
                  >
                    <AutoComplete
                      dropdownMatchSelectWidth={252}
                      style={{
                        width: "100%"
                      }}
                      value={selectedTypeFile}
                      options={typeFiles}
                      notFoundContent={t(
                        translationPath(lang.general.noOptionsText)
                      )}
                      onSelect={onSelectFileType}
                      onClear={handleSelectFileClear}
                      onSearch={onFileTypeValueChange}
                      allowClear={true}
                      disabled={
                        formState.selected === FileOption.Create ||
                        formState.selected === FileOption.Existing
                      }
                    >
                      <Input
                        style={{
                          width: "100%"
                        }}
                        placeholder={t(
                          translationPath(lang.general.inputTitleOrLabelOrId)
                        )}
                      />
                    </AutoComplete>
                  </Item>

                  <Item
                    name="sectionId"
                    label={t(
                      translationPath(lang.table.openTypeFilesTable.section)
                    )}
                    rules={[
                      {
                        required:
                          formState.selected === FileOption.ExistingTypeFile
                      }
                    ]}
                    required={true}
                  >
                    <Select
                      onChange={handleSectionChange}
                      notFoundContent={t(
                        translationPath(lang.general.noOptionsText)
                      )}
                      disabled={
                        formState.selected === FileOption.Create ||
                        formState.selected === FileOption.Existing ||
                        selectedTypeFile === ""
                      }
                    >
                      {typeFilesStructure &&
                        typeFilesStructure.map((item) => (
                          <Select.Option key={item.nodeId} value={item.nodeId}>
                            {item.subject}
                          </Select.Option>
                        ))}
                    </Select>
                  </Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Radio.Group>
      </Item>

      <Item name="existingFileKey" hidden>
        <Input type="hidden" />
      </Item>

      <Item name="existingFileTypeKey" hidden>
        <Input type="hidden" />
      </Item>
    </Form>
  );
};

export default CreateNewDocumentFileForm;
