import { DocumentState } from "presentation/enums";
import React from "react";
import { SaveAndDiscardTabForm } from "./SaveAndDiscardTabForm";
import { useMetaFormValuesWithFilePlans } from "../../hooks/useMetaFormValuesWithFilePlans";
import { useMetaFormDocument } from "../../hooks/useMetaFormDocument";
import { SslProperties } from "presentation/core/api/models";

export const SaveAndDiscardTab = () => {
  const initialValues = useMetaFormValuesWithFilePlans();
  const { formValues } = useMetaFormDocument();

  return (
    <div className="body-fullsize">
      <SaveAndDiscardTabForm
        readonly={true}
        initialValues={{
          ...initialValues,
          fileMark: (formValues as SslProperties).fileMark
        }}
      />
    </div>
  );
};

const allowedStates = [
  DocumentState.ReferedToArchive,
  DocumentState.ReferedToRepository,
  DocumentState.Shredded
];

SaveAndDiscardTab.filter = (state: string | undefined) => {
  return state ? allowedStates.includes(state as DocumentState) : false;
};
