import filesSvg from "assets/icons/files.svg";
import React from "react";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import { useStyles } from "./NoData.styles";

interface OwnProps {
  noDataHeightClassName?: string;
}

const NoData = (props: OwnProps) => {
  const { noDataHeightClassName = "" } = props;

  const classes = useStyles();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexFlow: "column",
        alignItems: "center",
        width: "100%",
        height: "60%",
        color: "#8E8E8E",
        background: "white"
      }}
    >
      <h1>{t(translationPath(lang.table.noData))}</h1>
      <img
        className={classes.noDataContainerImg}
        src={filesSvg}
        alt="No data icon"
      />
    </div>
  );
};

export default NoData;
