import { getService } from "presentation/core/features/dependencyInjection";
import { useCallback } from "react";
import { FindRetentionProtocolsErased } from "../../../../useCase/recordRetention/FindRetentionProtocolsErased";
import { PaginationConfig } from "../../../../lib/contract/Pagination";

export const useFindRetentionArchived = (nodeId: string) => {
  const findArchived = getService(FindRetentionProtocolsErased);

  return useCallback(
    (config: PaginationConfig) => findArchived.findArchived(config, nodeId),
    [findArchived, nodeId]
  );
};
