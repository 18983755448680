import { PaginationConfig } from "lib/contract/Pagination";
import { SortingConfig } from "lib/contract/Sorting";
import {
  DInject,
  DInjectable
} from "presentation/core/features/dependencyInjection";
import { ConversionAllRepositoryToken } from "../../domain/di/token/administration";

import type { ConversionAllRepository } from "../../domain/service/administration/ConversionAllRepository";

@DInjectable()
export class FindConversionAll {
  constructor(
    @DInject(ConversionAllRepositoryToken)
    protected repository: ConversionAllRepository
  ) {}
  findAll(pagination: PaginationConfig, sorting?: SortingConfig) {
    return this.repository.findAll(pagination, sorting);
  }
}
