import { DocumentId } from "../../../../../domain/struct/document/Document";
import { getService } from "../../../../core/features/dependencyInjection";
import { FindDocumentHistory } from "../../../../../useCase/document/FindDocumentHistory";
import { useCallback } from "react";
import { PaginationConfig } from "lib/contract/Pagination";
import { SortingConfig } from "lib/contract/Sorting";

export const useDocumentHistory = (id: DocumentId) => {
  const findHistory = getService(FindDocumentHistory);

  return useCallback(
    (paginationConfig: PaginationConfig, sortingConfig?: SortingConfig) =>
      findHistory.findPaginated(id, paginationConfig, sortingConfig),
    [findHistory, id]
  );
};
