import React, { useCallback } from "react";

import { ColumnType } from "../../../../designSystem/Table/Table";
import { createColumn } from "../../../../designSystem/Table/column/column";
import {
  GetRemoteDataClb,
  RemoteTable
} from "../../../../designSystem/Table/RemoteTable";
import { lang, t } from "../../../../translation/i18n";
import { translationPath } from "presentation/share/utils/getPath";
import { useFindConcernedContent } from "../../hooks/useFindConcernedContent";
import { ConcernedContent } from "../../../../../domain/struct/nameRegistry/ConcernedContent";
import { getRelationTypeTranslation } from "../../../../share/tables/document/ConcernedSubjectTable/helper";

const COLUMNS: ColumnType<ConcernedContent>[] = [
  createColumn({
    dataIndex: "documentPid",
    title: t(translationPath(lang.table.concernedContentTable.documentPid))
  }),
  createColumn({
    dataIndex: "documentSsid",
    title: t(translationPath(lang.table.concernedContentTable.documentSsid))
  }),
  createColumn({
    dataIndex: "documentSubject",
    title: t(translationPath(lang.table.concernedContentTable.documentSubject))
  }),
  createColumn({
    dataIndex: "relationType",
    title: t(translationPath(lang.table.concernedContentTable.relationType)),
    render(value) {
      return getRelationTypeTranslation(value);
    }
  })
];

interface ConcernedContentTableProps {
  subjectId: string;
}

export const ConcernedContentTable: React.FC<ConcernedContentTableProps> = ({
  subjectId
}) => {
  const find = useFindConcernedContent(subjectId!);

  const getRemoteData: GetRemoteDataClb<ConcernedContent> = useCallback(
    ({ pagination }) => find(pagination),
    [find]
  );

  return (
    <>
      <RemoteTable<ConcernedContent>
        name={`nameRegistry/concernedContentTable/${subjectId}`}
        columns={COLUMNS}
        getRemoteData={getRemoteData}
      />
    </>
  );
};
