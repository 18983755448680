import { DialogContentType } from "presentation/core/components/dialog/_types";
import { GenericDocument } from "presentation/core/types";
import React from "react";
import { PromoteConceptToDocumentForm } from "./PromoteConceptToDocumentForm";
import { PromoteConceptToDocumentFormValues } from "./_types";
import { validate } from "./_validations";
import { ChannelForm } from "../ChannelForm/ChannelForm";

export const PromoteConceptToDocumentContentDialog: DialogContentType["content"] = ({
  channel,
  dialogProps
}) => {
  return (
    <ChannelForm<PromoteConceptToDocumentFormValues>
      initialValues={{
        attachmentsCount: "",
        authorGroup: "",
        author: "",
        settleTo: null,
        subject:
          (dialogProps.data as GenericDocument)?.properties?.ssl?.subject || ""
      }}
      validate={validate}
      channel={channel}
      component={PromoteConceptToDocumentForm}
    />
  );
};
