import { useSubMenuItemsWithPermissions } from "presentation/core/hooks/useSubMenuItemsWithPermission";
import React from "react";
import { useMenuItemsWithPermissions } from "../../hooks/useMenuItemsWithPermissons";
import Menu from "../menu";
import SignedLayout from "./SignedLayout";

interface OwnProps {
  children: React.ReactElement<{ menupath: string[] }>;
}

const MenuLayout = ({ children }: OwnProps) => {
  const itemsOfMenu = useMenuItemsWithPermissions();
  const itemsAndSubMenu = useSubMenuItemsWithPermissions(itemsOfMenu);
  return (
    <SignedLayout>
      <Menu itemsOfMenu={itemsAndSubMenu}>{children}</Menu>
    </SignedLayout>
  );
};

export default MenuLayout;
