import { createGlobalStyle } from "styled-components";
import React from "react";

export const GlobalStyles = createGlobalStyle`
  .ant-select-dropdown {
    z-index: 1300;
  }

  .MuiFormControl-root.MuiTextField-root {
    padding-bottom: 1.5em;
  }

  .MuiFormHelperText-root.Mui-error {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .MuiLinearProgress-barColorPrimary {
    background-color: #ff9600;
  }

  .ant-modal-content {
    width: 100% !important;

    & .ant-modal-close-x .ant-btn-icon-only {
      vertical-align: 0.3px;
    }
  }

  .dailyImprint > div > .ant-row {
    height: 50px;
  }

  .ant-modal-confirm {
    top: 30%;

    & .ant-modal-body {
      padding: 32px 32px 12px;
    }

    & .ant-modal-confirm-btns {
      margin-top: 40px !important;
      margin-right: -15px;
    }
  }

  .ant-modal-confirm-body {
    margin: -32px;
    border-bottom: 1px solid #f0f0f0;

    & span svg {
      display: none !important;
    }

    & .ant-modal-confirm-title {
      padding: 16px 24px;
      border-radius: 2px 2px 0 0;
      color: #fff;
      font-size: 20px;
      line-height: 22px;
      background: #000;
      height: 50px;
    }

    & .ant-modal-confirm-content {
      margin: 22px !important;
    }
  }
`;
