import { FindFileHistory } from "useCase/file/FindFileHistory";
import { FileHistoryRepositoryToken } from "domain/di/token/file";
import { FileHistoryApiRepository } from "infrastructure/api/file/FileHistoryApiRepository";
import { FileConcernedSubjectRepositoryToken } from "domain/di/token/file";
import { FileConcernedSubjectApiRepository } from "infrastructure/api/file/FileConcernedSubjectApiRepository";
import { FindFileConcernedSubject } from "../../useCase/file/FindFileConcernedSubject";

export const fileProviders = [
  // repository
  {
    provide: FileHistoryRepositoryToken,
    useClass: FileHistoryApiRepository
  },
  {
    provide: FileConcernedSubjectRepositoryToken,
    useClass: FileConcernedSubjectApiRepository
  },
  // use case
  FindFileHistory,
  FindFileConcernedSubject
];
