import { PaginationConfig } from "lib/contract/Pagination";
import { SortingConfig } from "lib/contract/Sorting";
import { useQM } from "presentation/share/hook/query/useQM";
import { getActiveComponents } from "../api";

const QUERY_KEY = "documents/activeComponents";
export const useGetActiveConceptComponents = (
  nodeId: string,
  isActive: boolean = false,
  pagination: PaginationConfig,
  sorting?: SortingConfig
) => {
  return useQM(
    [QUERY_KEY, nodeId, pagination, sorting],
    () => getActiveComponents(nodeId, pagination, sorting),
    {
      enabled: isActive
    }
  );
};
