import { FilePlan } from "../../../core/features/login/_types";
import { useSelector } from "react-redux";
import { RootStateType } from "../../../reducers";

export const useIsValidOrExpireFilePlan = (id?: string): FilePlan[] => {
  const { filePlans, validFilePlans } = useSelector(
    (state: RootStateType) => state.loginReducer.global
  );
  if (!id) {
    return validFilePlans;
  } else {
    let isValid = validFilePlans.find((plan) => plan.nodeId === id);
    if (isValid) {
      return validFilePlans;
    } else {
      return filePlans;
    }
  }
};
