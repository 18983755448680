import { useMutation } from "presentation/share/hook/query/useMutation";
import { RegisterDataboxDigitalFormFieldTypes } from "../forms/RegisterDataboxDigitalFormFieldTypes";
import { registerDocument, updateUnfinishedDocument } from "../api";
import { ErrorType } from "../../../../../../../../types";
import { lang, t } from "../../../../../../../../translation/i18n";
import { translationPath } from "../../../../../../../../share/utils/getPath";

export interface UpdateDocument {
  readonly deliveryDate?: string;
  readonly deliveryMode?: string;
  readonly attachmentsCount?: number;
  readonly attachmentsType?: string;
  readonly listCount?: number;
  readonly listCountAttachments?: number;
  readonly settleToDate?: string;
  readonly senderIdent?: string;
  readonly senderSsid?: string;
  readonly subject?: string;
  readonly senderId?: number;
  readonly senderAddressId?: number;
  readonly senderContactDataboxId?: number;
  readonly senderContactEmailId?: number;
  readonly senderContactPhoneId?: number;
  readonly senderCompanyEmployeeUnit?: string;
  readonly senderCompanyEmployeeJob?: string;
  readonly senderCompanyEmployeeName?: string;
}

interface UpdateDocumentVariables<T> {
  id: string;
  input: T;
}

export const useUpdateDocument = <
  T = RegisterDataboxDigitalFormFieldTypes
>() => {
  const { isLoading: registrationLoading, mutate: register } = useMutation(
    (id: string) => registerDocument(id),
    {
      onSuccess: () => {
        return Promise.resolve();
      },
      onErrorNotification: (error: ErrorType) => ({
        message:
          Array.isArray(error.messages) && error.messages.length > 0
            ? error.messages[0]
            : t(translationPath(lang.dialog.notifications.actionFailed))
      })
    }
  );

  const {
    isLoading: updateLoading,
    mutate: update
  } = useMutation(({ id, input }: UpdateDocumentVariables<T>) =>
    updateUnfinishedDocument<T>(id, input)
  );

  const {
    isLoading: updateAndRegisterLoading,
    mutate: updateAndRegister
  } = useMutation(
    async ({ id, input }: UpdateDocumentVariables<T>) => {
      await updateUnfinishedDocument(id, input);
      await registerDocument(id);
    },
    {
      onSuccess: () => {
        return Promise.resolve();
      },
      onErrorNotification: (error: ErrorType) => ({
        message:
          Array.isArray(error.messages) && error.messages.length > 0
            ? error.messages[0]
            : t(translationPath(lang.dialog.notifications.actionFailed))
      })
    }
  );

  return {
    registrationLoading,
    updateLoading,
    updateAndRegisterLoading,
    update,
    register,
    updateAndRegister
  };
};
