import { callAsyncAction } from "presentation/core/action";
import { documentHandoverActionType } from "presentation/core/api/document/_actions";
import { SslProperties } from "presentation/core/api/models";
import { secondaryAction } from "presentation/core/components/dialog/lib/actionsFactory";
import {
  DialogContentPropsType,
  DialogType
} from "presentation/core/components/dialog/_types";
import { documentViewAction__Refresh } from "presentation/core/components/documentView/_actions";
import NamedTitle from "presentation/core/components/namedTitle";
import { GenericDocument } from "presentation/core/types";
import React from "react";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import { DocumentHandoverContentDialog } from "./DocumentHandoverContentDialog";
import { DocumentHandoverFormValues } from "./_types";
import { ModalSize } from "../../../../designSystem/Modal/Modal";

export const handoverDocumentDialogContent = {
  actions: () => [
    secondaryAction(
      t(translationPath(lang.dialog.buttons.confirm)),
      ({ dispatch, channels, dialogProps, onClose, buttonState }) => {
        const onSuccess = () => {
          dispatch(documentViewAction__Refresh(true));
          dialogProps?.onSuccess?.();
          onClose();
        };

        const onError = () => {
          buttonState.setIsPending(false);
        };

        buttonState.setIsPending(true);

        const { nextGroup, nextOwner } = channels.contentTab.state
          ?.formValues as DocumentHandoverFormValues;

        dispatch(
          callAsyncAction({
            action: documentHandoverActionType,
            onError,
            onSuccess,
            payload: {
              body: {
                nextGroup,
                ...(nextOwner && {
                  nextOwner
                })
              } as SslProperties,
              cancelDocumentOwner: !!dialogProps.cancelDocumentOwner,
              nodeId: (dialogProps.data as GenericDocument).id,
              nodeType: (dialogProps.data as GenericDocument).nodeType
            }
          })
        );
      }
    )
  ],
  content: DocumentHandoverContentDialog,
  title: (props: DialogContentPropsType) => (
    <NamedTitle
      text={t(translationPath(lang.dialog.title.handover))}
      {...props}
    />
  ),
  type: DialogType.HandoverDocument,
  size: ModalSize.Small
};
