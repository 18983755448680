import { callAsyncAction } from "presentation/core/action";
import { secondaryAction } from "presentation/core/components/dialog/lib/actionsFactory";
import {
  DialogContentType,
  DialogType
} from "presentation/core/components/dialog/_types";
import { documentViewAction__Refresh } from "presentation/core/components/documentView/_actions";
import NamedTitle from "presentation/core/components/namedTitle";
import { ReasonFormValues } from "presentation/core/components/reasonForm/_types";
import { GenericDocument } from "presentation/core/types";
import React from "react";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import ReturnShipmentDialog from "./ReturnShipmentDialog";
import { returnShipmentAction } from "./_actions";
import { ModalSize } from "presentation/designSystem/Modal/Modal";

export const returnShipmentDialogContent: DialogContentType = {
  actions: () => [
    secondaryAction(
      t(translationPath(lang.dialog.buttons.confirm)),
      ({ dispatch, channels, dialogProps, onClose, buttonState }) => {
        buttonState.setIsPending(true);

        const onSuccess = () => {
          dispatch(documentViewAction__Refresh(true));
          onClose();
        };

        const onError = () => {
          buttonState.setIsPending(false);
        };

        dispatch(
          callAsyncAction({
            action: returnShipmentAction,
            onError,
            onSuccess,
            payload: {
              nodeId: (dialogProps.data as GenericDocument).id,
              reason: (channels?.contentTab?.state
                ?.formValues as ReasonFormValues).reason
            }
          })
        );
      }
    )
  ],
  content: ReturnShipmentDialog,
  title: (props) => (
    <NamedTitle
      text={t(translationPath(lang.general.returnShipment))}
      {...props}
    />
  ),
  type: DialogType.ReturnShipment,
  size: ModalSize.Small
};
