import {
  DInject,
  DInjectable
} from "../../../presentation/core/features/dependencyInjection";
import { HttpClient, HttpMethod } from "../../service/HttpClient";
import { ApiUrls } from "../struct/ApiTypes";
import {
  PaginatedResult,
  PaginationConfig
} from "../../../lib/contract/Pagination";
import { AddressRepository } from "../../../domain/service/nameRegistry/AddressRepository";
import { Address } from "../../../domain/struct/nameRegistry/Address";
import { AddressApiMapper, ApiAddress } from "./AddressApiMapper";

const URL: ApiUrls = {
  FindBySubjectId: "/api/app/v1/name-register/subject/{subjectId}/addresses",
  Create: "/api/app/v1/name-register/subject/{subjectId}/address/create",
  Update:
    "/api/app/v1/name-register/subject/{subjectId}/address/{addressId}/update",
  Delete: "/api/app/v1/name-register/subject/{subjectId}/address/{addressId}"
};

@DInjectable()
export class AddressApiRepository implements AddressRepository {
  constructor(@DInject(HttpClient) protected httpClient: HttpClient) {}

  create(subjectId: string, address: Address): Promise<void> {
    return this.httpClient.fetchWithThrow(URL.Create, HttpMethod.Post, {
      bodyJSON: AddressApiMapper.mapToApi(address),
      urlWildCards: {
        subjectId
      }
    });
  }

  delete(subjectId: string, address: Address): Promise<void> {
    return this.httpClient.fetchWithThrow(URL.Delete, HttpMethod.Delete, {
      bodyJSON: AddressApiMapper.mapToApi(address),
      urlWildCards: {
        subjectId,
        addressId: Number(address.id)
      }
    });
  }

  findBySubjectId(
    subjectId: string,
    pagination: PaginationConfig
  ): Promise<PaginatedResult<Address>> {
    return this.httpClient
      .fetchPaginatedAlfresco<ApiAddress>(URL.FindBySubjectId, pagination, {
        urlWildCards: {
          subjectId
        }
      })
      .then(AddressApiMapper.mapAddressesToDomain);
  }

  update(subjectId: string, address: Address): Promise<void> {
    return this.httpClient.fetchWithThrow(URL.Update, HttpMethod.Post, {
      bodyJSON: AddressApiMapper.mapToApi(address),
      urlWildCards: {
        subjectId,
        addressId: Number(address.id)
      }
    });
  }
}
