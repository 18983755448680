import MenuLayout from "presentation/core/components/layout/MenuLayout";
import React from "react";
import { withTranslation } from "presentation/translation/i18n";
import { OverviewsForSignatureTable } from "./tables/OverviewsForSignatureTable";

const OverviewsForSignature = () => {
  return (
    <MenuLayout>
      <OverviewsForSignatureTable />
    </MenuLayout>
  );
};

export default withTranslation()(OverviewsForSignature);
