import React, { useState } from "react";
import styled from "styled-components";

import { Descriptions } from "presentation/designSystem/Descriptions/Descriptions";
import { Address } from "../../../../../domain/struct/nameRegistry/Address";
import { LegalEntityAddressType } from "../../../../../domain/struct/nameRegistry/AddressFormEnums";
import { DataBox } from "../../../../../domain/struct/nameRegistry/DataBox";
import { SubjectType } from "../../../../../domain/struct/nameRegistry/SubjectType";
import { Checkbox } from "../../../../designSystem/Checkbox/Checkbox";
import { Form, FormInstance } from "../../../../designSystem/Form/v2/Form";
import { ColOneFourth, Row } from "../../../../designSystem/Grid/Grid";
import { Modal } from "../../../../designSystem/Modal/Modal";
import { translationPath } from "../../../../share/utils/getPath";
import { lang, t } from "../../../../translation/i18n";
import { useSubjectModalContext } from "../../contexts/SubjectModalContextProvider";
import { AddressTypeSelect } from "../../form/AddressesForm/Input/AddressTypeSelect";
import { CountryTypeSelect } from "../../form/AddressesForm/Input/CountryTypeSelect";
import { useAddressValidations } from "../../form/AddressesForm/hooks/useAddressValidations";
import { useAddressesFilteredOptions } from "../../hooks/useAddressesFilteredOptions";

interface DataBoxModalProps {
  dataBoxData: DataBox;
  isDataBoxFetching: boolean;
  onCreate?: (values: any) => void;
  onConfirmDataBox: () => void;
  onCancel: () => void;
  typeOfSubject: string;
  checkedAddress: boolean;
  setCheckedAddress: React.Dispatch<React.SetStateAction<boolean>>;
  checkedMetadata: boolean;
  setCheckedMetadata: React.Dispatch<React.SetStateAction<boolean>>;
  form: FormInstance<Address>;
}

const showFullStreet = (address: Address) => {
  const {
    street,
    houseNumber,
    orientationHouseNumber,
    orientationHouseNumberAddendum
  } = address || {};
  const fullStreet = [street, houseNumber];
  if (orientationHouseNumber) {
    fullStreet.push(
      ...[
        "/",
        `${orientationHouseNumber}${orientationHouseNumberAddendum || ""}`
      ]
    );
  }

  return fullStreet.filter(Boolean).join(" ");
};

const showAddress = (address: Address): string => {
  const { postalCode, cityPart, city } = address || {};
  return `${[postalCode, cityPart].filter(Boolean).join(" ")}${
    city ? ", " + city : ""
  }`;
};

const DataBoxModal: React.FC<DataBoxModalProps> = ({
  dataBoxData,
  onConfirmDataBox,
  onCancel,
  typeOfSubject,
  checkedAddress,
  setCheckedAddress,
  checkedMetadata,
  setCheckedMetadata,
  form
}) => {
  const [modalVisibility, setModalVisibility] = useState(true);

  const { isFetching, options } = useAddressesFilteredOptions(typeOfSubject);
  const { persistence } = useSubjectModalContext();

  const { subjectName, subjectType, identificationNumber, databoxId, address } =
    dataBoxData || {};

  const onOk = async () => {
    try {
      await form.validateFields();
      onConfirmDataBox();
      setModalVisibility(false);
    } catch {}
  };
  const { onCountryChange } = useAddressValidations(address?.country);

  const makeEnabled = () => {
    if (persistence.getSubjectData()?.subjectType === SubjectType.Person) {
      return subjectType === SubjectType.Person;
    }

    return subjectType !== SubjectType.Person;
  };

  const databoxModalSelectOptions =
    typeOfSubject === SubjectType.LegalEntity
      ? options.filter(
          (address) =>
            address.value === LegalEntityAddressType.CompanyHeadquarters ||
            address.value === LegalEntityAddressType.CompanyBranch
        )
      : options;
  return (
    <Modal
      visible={modalVisibility}
      title={t(translationPath(lang.dataBox.modalTitle))}
      onOk={onOk}
      onCancel={onCancel}
      okButtonProps={{
        disabled: !makeEnabled()
      }}
    >
      <StyledTitle>{t(translationPath(lang.dataBox.title))}</StyledTitle>
      <Descriptions bordered column={1}>
        {subjectType && (
          <Descriptions.Item
            label={t(translationPath(lang.dataBox.subjectType))}
          >
            {subjectType}
          </Descriptions.Item>
        )}
        {identificationNumber && (
          <Descriptions.Item label={t(translationPath(lang.dataBox.ico))}>
            {identificationNumber}
          </Descriptions.Item>
        )}
        {subjectName && (
          <Descriptions.Item label={t(translationPath(lang.dataBox.name))}>
            {subjectName}
          </Descriptions.Item>
        )}
        {databoxId && (
          <Descriptions.Item label={t(translationPath(lang.dataBox.dataBoxId))}>
            {databoxId}
          </Descriptions.Item>
        )}
        {address && (
          <Descriptions.Item label={t(translationPath(lang.dataBox.address))}>
            {showFullStreet(address)} <br />
            {showAddress(address)}
          </Descriptions.Item>
        )}
      </Descriptions>
      {!makeEnabled() ? (
        <StyledError>
          {t(
            translationPath(
              lang.dataBox.databoxCannotBeAddedAsTypeOfSubjectIsDifferent
            )
          )}
        </StyledError>
      ) : (
        <>
          <StyledFooter>{t(translationPath(lang.dataBox.footer))}</StyledFooter>
          {address && (
            <Form
              name="databoxForm"
              form={form}
              initialValues={{ country: "CZE" }}
            >
              <StyledRow>
                <Checkbox
                  disabled={!makeEnabled()}
                  onChange={() => setCheckedMetadata(!checkedMetadata)}
                >
                  {t(translationPath(lang.dataBox.fillInDataOfSubject))}{" "}
                </Checkbox>
              </StyledRow>
              <StyledRow>
                <Checkbox
                  disabled={!makeEnabled()}
                  onChange={() => setCheckedAddress(!checkedAddress)}
                >
                  {t(translationPath(lang.dataBox.saveAddressOfSubject))}{" "}
                </Checkbox>
              </StyledRow>
              {checkedAddress && (
                <StyledRow>
                  <ColOneFourth spaceAround>
                    <AddressTypeSelect
                      type={subjectType || ""}
                      options={databoxModalSelectOptions}
                      isFetching={isFetching}
                    />
                  </ColOneFourth>
                  <ColOneFourth spaceAround>
                    <CountryTypeSelect onChange={onCountryChange} />
                  </ColOneFourth>
                </StyledRow>
              )}
            </Form>
          )}
        </>
      )}
    </Modal>
  );
};

const StyledFooter = styled.div`
  margin-top: 1.5rem;
  font-weight: 700;
`;

const StyledTitle = styled.div`
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
`;

const StyledRow = styled(Row)`
  margin: 1rem 0;
  .ant-col:nth-of-type(1) {
    padding-right: 2rem;
  }
`;

const StyledError = styled.p`
  color: red;
  margin: 3rem 0;
`;

export default DataBoxModal;
