import { SubjectType } from "../../../../domain/struct/nameRegistry/SubjectType";
import {
  Subject,
  SubjectId
} from "../../../../domain/struct/nameRegistry/Subject";
import { useIsNameRegisterGroupActive } from "../../../share/hook/useIsNameRegisterGroupActive";
import { getService } from "../../../core/features/dependencyInjection";
import { FindPerson } from "../../../../useCase/nameRegistry/findPerson/FindPerson";
import { FindLegalEntity } from "../../../../useCase/nameRegistry/FindLegalEntity";
import { useQM } from "../../../share/hook/query/useQM";

const KEY = "nameRegister/findSubject";

export const useFindSubject = (
  subjectType: SubjectType,
  subjectId?: SubjectId
) => {
  const isNameRegisterGroupActive = useIsNameRegisterGroupActive();
  const findPerson = getService(FindPerson);
  const findLegalEntity = getService(FindLegalEntity);

  const findUseCase =
    subjectType === SubjectType.Person ? findPerson : findLegalEntity;

  return useQM<Subject | undefined>(
    [KEY, subjectType, subjectId, isNameRegisterGroupActive],
    () => {
      if (!subjectId) {
        return Promise.resolve(undefined);
      }

      return isNameRegisterGroupActive
        ? findUseCase.findFullById(subjectId)
        : findUseCase.findById(subjectId);
    }
  );
};
