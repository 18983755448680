import { useEffect } from "react";

// Dirty hack to take care about aggressive MUI Dialogs
export const useMuiVisibility = () => {
  useEffect(() => {
    const muiDialogContainers = document.getElementsByClassName(
      "MuiDialog-container"
    );
    if (muiDialogContainers && muiDialogContainers.length) {
      Array.from(muiDialogContainers).forEach((container) => {
        container.setAttribute("style", "visibility: hidden");
      });
    }

    return () => {
      if (muiDialogContainers && muiDialogContainers.length) {
        Array.from(muiDialogContainers).forEach((container) => {
          container.setAttribute("style", "visibility: visible");
        });
      }
    };
  });
};
