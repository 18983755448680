import { ModalWithTabs } from "presentation/designSystem/Modal/Modal";
import { BaseModalProps } from "presentation/designSystem/Modal/useModal";
import { Tabs } from "presentation/designSystem/Tabs/Tabs";
import React, { useCallback } from "react";
import { translationPath } from "../../../share/utils/getPath";
import { lang, t } from "../../../translation/i18n";
import { RetentionProposalOrProtocolContentTable } from "../features/table/RetentionProposalOrProtocolContentTable";
import { RetentionProposalOrProtocolHistoryTable } from "../features/table/RetentionProposalOrProtocolHistoryTable";
import { RetentionProposalMetadataForm } from "./form/RetentionProposalMetadataForm";
// import { lang, t } from "../../../../translation/i18n";

interface ShowDetailsOfRetentionProposalOrProtocolProps extends BaseModalProps {
  nodeId: string;
  dialogType: string;
}

export const DetailsOfRetentionProtocolOrProposalModal = ({
  nodeId,
  dialogType,
  onCancel,
  onOk
}: ShowDetailsOfRetentionProposalOrProtocolProps) => {
  const TITLE_MAP = {
    titleProtocol: t(
      translationPath(
        lang.dialog.showDetailsOfRetentionProposalOrProtocolModal.titleProtocol
      )
    ),
    titleProposal: t(
      translationPath(
        lang.dialog.showDetailsOfRetentionProposalOrProtocolModal.titleProposal
      )
    )
  };
  const tabsAndForms = [
    {
      tab: t(translationPath(lang.table.retentionProposalTable.metadata)),
      form: <RetentionProposalMetadataForm id={nodeId} />
    },
    {
      tab: t(translationPath(lang.table.retentionProposalTable.content)),
      form: (
        <RetentionProposalOrProtocolContentTable
          dialogType="proposal"
          id={nodeId}
          onRetentionProposalRemove={onOk}
        />
      )
    },
    {
      tab: t(translationPath(lang.table.retentionProposalTable.history)),
      form: <RetentionProposalOrProtocolHistoryTable id={nodeId} />
    }
  ];

  const handleOk = useCallback(() => onOk?.(), [onOk]);

  return (
    <ModalWithTabs
      okText={t(translationPath(lang.modal.ok))}
      cancelText={t(translationPath(lang.modal.cancel))}
      open={true}
      title={
        dialogType === "proposal"
          ? TITLE_MAP.titleProposal
          : TITLE_MAP.titleProtocol
      }
      onOk={handleOk}
      onCancel={onCancel}
    >
      <Tabs>
        {tabsAndForms.map((tabAndForm, index) => {
          return (
            <Tabs.TabPane tab={tabAndForm.tab} key={index}>
              <div>{tabAndForm.form}</div>
            </Tabs.TabPane>
          );
        })}
      </Tabs>
    </ModalWithTabs>
  );
};
