import {
  DInject,
  DInjectable
} from "presentation/core/features/dependencyInjection";
import { ApiUrls } from "infrastructure/api/struct/ApiTypes";
import { HttpClient, HttpMethod } from "infrastructure/service/HttpClient";
import { ShipmentRepository } from "domain/service/shipment/ShipmentRepository";
import { ApiShipment, ShipmentApiMapper } from "./ShipmentApiMapper";
import { Shipment, ShipmentId } from "../../../domain/struct/shipment/Shipment";

const URL: ApiUrls = {
  GetShipment: "/api/app/v1/shipment/{nodeId}"
};

@DInjectable()
export class ShipmentApiRepository implements ShipmentRepository {
  constructor(@DInject(HttpClient) protected httpClient: HttpClient) {}

  getById(id: ShipmentId): Promise<Shipment> {
    return this.httpClient
      .fetchWithThrow<ApiShipment>(URL.GetShipment, HttpMethod.Get, {
        urlWildCards: {
          nodeId: id
        }
      })
      .then(ShipmentApiMapper.mapToDomain);
  }
}
