import { useCallback, useState } from "react";
import { useMutation } from "presentation/share/hook/query/useQM";
import { useModal } from "presentation/designSystem/Modal/useModal";
import {
  RegisterDataboxDigitalModal,
  RegisterDataboxDigitalModalTabs
} from "../modals/RegisterDataboxDigitalModal";
import { DocumentType, SpisumNodeTypes } from "presentation/enums";
import { createDocumentFromDataBox } from "../api";
import { useEncryptedComponentsModal } from "../../../hooks/useEncryptedComponentsModal";
import { useGetDocumentComponentsValidation } from "../../../hooks/useGetDocumentComponentsValidation";
import { ErrorType } from "../../../../../../../../types";
import { lang, t } from "../../../../../../../../translation/i18n";
import { translationPath } from "../../../../../../../../share/utils/getPath";
import { EncryptedComponentsPasswordModal } from "../../../modals/EncryptedComponentsPasswordModal";
import { ComponentType } from "presentation/core/api/components/_types";
import { CreateDataboxRegistration } from "../types";

export interface UseCreateDataboxRegistrationReturn {
  isLoading: boolean;
  register(...args: Parameters<typeof createDocumentFromDataBox>): void;
  modal: (false | JSX.Element)[];
}

export const useCreateDataboxRegistration = (): UseCreateDataboxRegistrationReturn => {
  const [documentData, setDocumentData] = useState<
    CreateDataboxRegistration | undefined
  >();

  const [modal, modalApi] = useModal(RegisterDataboxDigitalModal, {});

  const openRegisterModal = useCallback(
    (defaultActiveTab?: string) => {
      modalApi.open({
        document: documentData!,
        nodeId: documentData?.nodeId,
        documentType: DocumentType.Digital,
        nodeType: SpisumNodeTypes.Databox,
        documentId: documentData?.nodeId,
        defaultActiveTab
      });
    },
    [documentData, modalApi]
  );

  const {
    encryptedComponentsModal,
    openEncryptedComponentsModal
  } = useEncryptedComponentsModal({
    onOk: (encryptedComponents) => {
      if (encryptedComponents && encryptedComponents.length === 1) {
        encryptedComponentsPasswordModalApi.open({
          componentType: ComponentType.Document,
          nodeId: documentData?.nodeId!,
          componentId: encryptedComponents[0]
        });
      } else {
        openRegisterModal(RegisterDataboxDigitalModalTabs.Components);
      }
    },
    onCancel: () => openRegisterModal()
  });
  const [
    encryptedComponentsPasswordModal,
    encryptedComponentsPasswordModalApi
  ] = useModal(EncryptedComponentsPasswordModal, {
    onCancel: () => openRegisterModal(),
    onOk: () => openRegisterModal()
  });

  const { isValidateLoading, validate } = useGetDocumentComponentsValidation({
    documentId: documentData?.nodeId,
    onSuccess: (data) => {
      const encryptedComponents = data.reduce(
        (acc, item) => (item.isEncrypted ? acc.concat(item.componentId!) : acc),
        [] as string[]
      );

      if (encryptedComponents.length > 0) {
        openEncryptedComponentsModal({
          documentType: DocumentType.Databox,
          encryptedComponents
        });
      } else {
        openRegisterModal();
      }
    }
  });

  const {
    isLoading: isCreateDocumentLoading,
    mutate: createDocument
  } = useMutation(createDocumentFromDataBox, {
    onSuccess: (data) => {
      setDocumentData(data);
      validate();
    },
    onErrorNotification: (error: ErrorType) => ({
      message:
        Array.isArray(error.messages) && error.messages.length > 0
          ? error.messages[0]
          : t(translationPath(lang.dialog.notifications.actionFailed))
    })
  });

  return {
    isLoading: isValidateLoading || isCreateDocumentLoading,
    register: createDocument,
    modal: [modal, encryptedComponentsModal, encryptedComponentsPasswordModal]
  };
};
