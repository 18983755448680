import React, { useEffect } from "react";
import { DialogDataGenericData, DialogTabContentPropsType } from "../../_types";
import { DocumentHistoryTable } from "../../../../../share/tables/document/DocumentHistoryTable/DocumentHistoryTable";
import { FileHistoryTable } from "presentation/share/tables/file/FileHistoryTable/FileHistoryTable";
import { ConceptHistoryTable } from "presentation/share/tables/concept/ConceptHistoryTable/ConceptHistoryTable";
import { ShipmentHistoryTable } from "presentation/share/tables/shipment/ShipmentHistoryTable/ShipmentHistoryTable";
import { useRemoteTableApi } from "presentation/designSystem/Table/Table";

type HistoryTabContainerProps = DialogTabContentPropsType & {
  nodeId: string;
};

const HistoryTabContainer = React.memo(
  ({ nodeId, dialogProps, isActive, channel }: HistoryTabContainerProps) => {
    const [tableApi, onRemoteTableApi] = useRemoteTableApi();
    const nodeType = (dialogProps.data as DialogDataGenericData)?.nodeType;

    useEffect(() => {
      // we need to re-fetch data every time the tab is active
      if (!isActive) {
        return;
      }

      tableApi.refetch();
    }, [isActive, tableApi]);

    useEffect(() => {
      // provide callback for the dialog refresh button
      channel.refreshData = () => {
        tableApi.refetch();
      };
    }, [channel, tableApi]);

    const nodeTypeLowercase = nodeType?.toLowerCase();

    return (
      <>
        {nodeTypeLowercase?.includes("document") && (
          <DocumentHistoryTable
            id={nodeId}
            onRemoteTableApi={onRemoteTableApi}
          />
        )}
        {nodeTypeLowercase?.includes("file") && (
          <FileHistoryTable id={nodeId} onRemoteTableApi={onRemoteTableApi} />
        )}
        {nodeTypeLowercase?.includes("concept") && (
          <ConceptHistoryTable
            id={nodeId}
            onRemoteTableApi={onRemoteTableApi}
          />
        )}
        {nodeTypeLowercase?.includes("shipment") && (
          <ShipmentHistoryTable
            id={nodeId}
            onRemoteTableApi={onRemoteTableApi}
          />
        )}
      </>
    );
  }
);

export default HistoryTabContainer;
