import { getService } from "presentation/core/features/dependencyInjection";
import { useCallback } from "react";
import { PaginationConfig } from "../../../../lib/contract/Pagination";
import { FindImprint } from "../../../../useCase/administration/FindImprint";

export const useFindDailyImprints = () => {
  const findImprints = getService(FindImprint);

  return useCallback(
    (config: PaginationConfig, sorting: any) =>
      findImprints.findAll(config, sorting),
    [findImprints]
  );
};
