import { CONFIG } from "../../../config";

enum Environment {
  Development = "development",
  Production = "production",
  Test = "test"
}

export const inDevelopmentEnv = () => {
  const { NODE_ENV } = process.env;

  return (
    NODE_ENV === Environment.Development ||
    NODE_ENV === Environment.Test ||
    isAppRunningOnDevelopmentHost()
  );
};

const isAppRunningOnDevelopmentHost = () =>
  CONFIG.development.hosts.indexOf(window.location.hostname) !== -1;
