import React from "react";
import { Address } from "../../../../../domain/struct/nameRegistry/Address";
import { useFindAddresses } from "../../hooks/useFindAddresses";

type AddressTypeRowProps = Pick<Address, "addressType">;

export const AddressTypeRow: React.FC<AddressTypeRowProps> = ({
  addressType
}) => {
  const { data } = useFindAddresses({});

  const value = data?.find((dataItem) => dataItem.code === addressType);

  return <>{value?.czechName}</>;
};
