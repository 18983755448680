import { getService } from "presentation/core/features/dependencyInjection";

import { useQM } from "presentation/share/hook/query/useQM";

import { Config } from "./useFindPersons";
import { FindDataBox } from "../../../../useCase/nameRegistry/isds/FindDataBox";

const QUERY_KEY = "name-registry/findDataBox";

export const useFindDataBox = (
  id: string,
  subjectId: string,
  config: Config
) => {
  const findUseCase = getService(FindDataBox);

  return useQM(
    [QUERY_KEY, id, subjectId],
    () => findUseCase.findOne(id, subjectId),
    config
  );
};
