import React, { useMemo } from "react";

import { Select } from "../../../../../designSystem/Select/Select";
import { useFindContacts } from "../../../hooks/useFindContacts";
import { Item } from "../../../../../designSystem/Form/v2/Form";
import { ContactType } from "../../../../../../domain/struct/nameRegistry/ContactType";
import { lang, t } from "../../../../../translation/i18n";
import { translationPath } from "../../../../../share/utils/getPath";

export interface ContactSelectProps {
  onChange?: (value: ContactType) => void;
  disabled?: boolean;
}

const validationRules = [{ required: true }];

export const ContactsSelect = ({ onChange, disabled }: ContactSelectProps) => {
  const { data, isFetching } = useFindContacts({ retry: false });
  const options = useMemo(
    () =>
      data
        ? data.map(({ code, czechName }) => ({
            label: czechName,
            value: code
          }))
        : [],

    [data]
  );

  return (
    <Item
      name="contactType"
      label={t(translationPath(lang.dialog.form.contactForm.contactType))}
      rules={validationRules}
    >
      <Select<ContactType>
        style={{ minWidth: "11rem" }}
        loading={isFetching}
        showSearch
        options={options}
        onChange={onChange}
        disabled={disabled}
      />
    </Item>
  );
};
