import { Lock, LockOpen } from "@mui/icons-material";
import { classPath, translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import { File, FileMetaType, fileProxy } from "../../../../entities";
import { DataColumn, DataColumnType } from "../../../dataTable/_types";
import React from "react";

const readableValues = [
  {
    text: "---",
    value: ""
  },
  { value: "true", text: t(translationPath(lang.general.yes)) },
  { value: "false", text: t(translationPath(lang.general.no)) }
];

const dmFileMetaTypeValues = [
  {
    text: "---",
    value: ""
  },
  {
    text: t(translationPath(lang.enums.fileMetaTypes.deliveryNote)),
    value: FileMetaType.deliveryNote
  },
  {
    text: t(translationPath(lang.enums.fileMetaTypes.signature)),
    value: FileMetaType.signature
  },
  {
    text: t(translationPath(lang.enums.fileMetaTypes.main)),
    value: FileMetaType.main
  },
  {
    text: t(translationPath(lang.enums.fileMetaTypes.meta)),
    value: FileMetaType.meta
  },
  {
    text: t(translationPath(lang.enums.fileMetaTypes.enclosure)),
    value: FileMetaType.enclosure
  },
  {
    text: t(
      translationPath(lang.enums.fileMetaTypes.signatureVerificationProtocol)
    ),
    value: FileMetaType.signatureVerificationProtocol
  }
];

const dmFileMetaTypeValuesWithoutMain = dmFileMetaTypeValues.filter(
  (x) => x.value !== FileMetaType.main
);

const getMetaTypeItems = (mainFile: File | undefined) => (row: File) => {
  // there is no main file, or we are generating dropdown items for the main file
  if (!mainFile || row.id === mainFile.id) {
    return dmFileMetaTypeValues;
  }
  return dmFileMetaTypeValuesWithoutMain;
};

export const generateColumns = (
  mainFile: File | undefined,
  handleOpenPopUpPassword: (file: File) => void
): DataColumn<File>[] => {
  return [
    {
      keys: [classPath(fileProxy.name).path],
      label: t(translationPath(lang.general.name)),
      sortKeys: ["name"]
    },
    {
      getDropdownItems: getMetaTypeItems(mainFile),
      keys: [classPath(fileProxy.type).path],
      label: t(translationPath(lang.general.type)),
      type: DataColumnType.dropdown
    },
    {
      keys: [classPath(fileProxy.sizeInBytes).path],
      label: t(translationPath(lang.general.size)),
      sortKeys: ["sizeInBytes"],
      type: DataColumnType.fileSize
    },
    {
      dropdownItems: readableValues,
      keys: [classPath(fileProxy.isReadable).path],
      label: t(translationPath(lang.general.readable)),
      type: DataColumnType.dropdown
    },
    {
      keys: [classPath(fileProxy.isEncrypted).path],
      label: "",
      type: DataColumnType.iconButton,
      icon: <Lock />,
      hoverIcon: <LockOpen />,
      sorter: false,
      onClick: handleOpenPopUpPassword,
      tooltip: t(translationPath(lang.dialog.form.inputPassword)),
      noBorder: true
    }
  ];
};
