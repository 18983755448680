import React, { useCallback } from "react";

import { ColumnType } from "../../../../designSystem/Table/Table";
import {
  createColumn,
  createDateTimeColumn
} from "../../../../designSystem/Table/column/column";
import {
  GetRemoteDataClb,
  RemoteTable
} from "../../../../designSystem/Table/RemoteTable";
import { lang, t } from "../../../../translation/i18n";
import { translationPath } from "presentation/share/utils/getPath";
import { History as NameRegistryHistory } from "../../../../../domain/struct/nameRegistry/History";
import { useFindHistory } from "../../hooks/useFindHistory";
import { DateTimeFormat } from "../../../../../lib/dateTime";

const COLUMNS: ColumnType<NameRegistryHistory>[] = [
  createColumn({
    dataIndex: "pid",
    title: t(translationPath(lang.table.historyTable.identifier)),
    sortDirections: ["descend", "ascend", "descend"],
    sorter: true
  }),
  createDateTimeColumn(
    {
      dataIndex: "occuredAt",
      title: t(translationPath(lang.table.historyTable.dateOfChange)),
      sortDirections: ["descend", "ascend", "descend"],
      sorter: true
    },
    DateTimeFormat.FullDateTime
  ),
  createColumn({
    dataIndex: "description",
    title: t(translationPath(lang.table.historyTable.description)),
    sortDirections: ["descend", "ascend", "descend"],
    sorter: true
  }),
  createColumn({
    dataIndex: "userId",
    title: t(translationPath(lang.table.historyTable.authorOfChange)),
    sortDirections: ["descend", "ascend", "descend"],
    sorter: true
  }),
  createColumn({
    dataIndex: "eventType",
    title: t(translationPath(lang.table.historyTable.typeOfChange)),
    sortDirections: ["descend", "ascend", "descend"],
    sorter: true
  })
];

interface HistoryTableProps {
  subjectId: string;
}

export const HistoryTable: React.FC<HistoryTableProps> = ({ subjectId }) => {
  const findHistory = useFindHistory(subjectId!);

  const getRemoteData: GetRemoteDataClb<NameRegistryHistory> = useCallback(
    ({ pagination, sorting }) => findHistory(pagination, sorting),
    [findHistory]
  );

  return (
    <>
      <RemoteTable<NameRegistryHistory>
        name={`nameRegistry/historyTable/${subjectId}`}
        columns={COLUMNS}
        getRemoteData={getRemoteData}
      />
    </>
  );
};
