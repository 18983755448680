import { ApiAlfrescoListResponse, ApiTypes } from "../struct/ApiTypes";
import { AlfrescoListResponseMapper } from "../mapper/AlfrescoListResponseMapper";
import { PaginatedResult } from "../../../lib/contract/Pagination";
import { stringOrDefault } from "../../../lib/string";
import { ConcernedContent } from "../../../domain/struct/nameRegistry/ConcernedContent";

export type ApiConcernedContent = ApiTypes["ISFG.SpisUm.ClientSide.Models.NameRegister.SubjectRelationInfo"];

export class ConcernedContentApiMapper {
  static mapToDomain(concernedContent: ApiConcernedContent): ConcernedContent {
    return {
      id: concernedContent.relationId,
      documentPid: stringOrDefault(concernedContent.documentPid),
      documentSsid: stringOrDefault(concernedContent.documentSsid),
      documentSubject: stringOrDefault(concernedContent.documentSubject),
      relationType: stringOrDefault(concernedContent.relationType)
    };
  }

  static mapConcernedContentToDomain(
    concernedContent: ApiAlfrescoListResponse<ApiConcernedContent>
  ): PaginatedResult<ConcernedContent> {
    return AlfrescoListResponseMapper.mapListToPagination(
      concernedContent,
      ConcernedContentApiMapper.mapToDomain
    );
  }
}
