import { PaginationConfig } from "lib/contract/Pagination";
import {
  DInject,
  DInjectable
} from "presentation/core/features/dependencyInjection";
import { ErasedFileRepositoryToken } from "../../domain/di/token/recordRetention";
import type { ErasedFileRepository } from "../../domain/service/recordRetention/ErasedFileRepository";
import { ErasedFileId } from "../../domain/struct/recordRetention/ErasedFile";

@DInjectable()
export class FindErasedDocuments {
  constructor(
    @DInject(ErasedFileRepositoryToken)
    protected repository: ErasedFileRepository
  ) {}

  findErasedDocumentsByFileId(id: ErasedFileId, config: PaginationConfig) {
    return this.repository.findErasedDocumentsByFileId(id, config);
  }
}
