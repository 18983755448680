import { BaseModalProps } from "presentation/designSystem/Modal/useModal";
import styled from "styled-components";
import { Contact } from "../../../../../domain/struct/nameRegistry/Contact";
import { Modal, ModalSize } from "../../../../designSystem/Modal/Modal";
import { useMutation } from "../../../../share/hook/query/useQM";
import { translationPath } from "../../../../share/utils/getPath";
import { lang, t } from "../../../../translation/i18n";
import { useSubjectModalContext } from "../../contexts/SubjectModalContextProvider";
import React from "react";

export interface DeleteContactModalProps extends BaseModalProps {
  contact?: Contact;
}

export const ContactDeleteModal = ({
  contact,
  onOk,
  onCancel
}: DeleteContactModalProps) => {
  const { persistence } = useSubjectModalContext();
  const { isLoading, mutate: deleteContact } = useMutation(
    (contact: Contact) => persistence.removeContact(contact),
    {
      onSuccess() {
        onOk?.();
      },
      onSuccessNotification: {
        message: t(translationPath(lang.dialog.notifications.contactWasDeleted))
      },
      onErrorNotification: {
        message: t(
          translationPath(lang.dialog.notifications.contactWasNotDeleted)
        )
      }
    }
  );

  const handleOk = () => deleteContact(contact!);

  return (
    <Modal
      open={true}
      title={t(translationPath(lang.dialog.contactsDeleteDialog.title))}
      onCancel={onCancel}
      onOk={handleOk}
      confirmLoading={isLoading}
      className={""}
      size={ModalSize.Small}
      centered={true}
      okText={t(translationPath(lang.modal.ok))}
      cancelText={t(translationPath(lang.modal.cancel))}
    >
      <StyledDiv>
        {t(
          translationPath(
            lang.dialog.contactsDeleteDialog.doYouReallyWantToDeleteContact
          )
        )}
      </StyledDiv>
    </Modal>
  );
};

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
