import { PreviewerType } from "../_types";
import React from "react";
import { StyledWrapper } from "./HtmlPreview.styles";

export interface PreviewerProps {
  name: string;
  content: string;
}
export const HtmlPreview: PreviewerType = React.memo(
  ({ content }: PreviewerProps) => (
    <StyledWrapper
      dangerouslySetInnerHTML={{ __html: content }}
    ></StyledWrapper>
  )
) as PreviewerType;
