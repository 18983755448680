import {
  ContentInfo,
  Node,
  PathInfo,
  SslComponent
} from "presentation/core/api/models";
import { isObject } from "lodash";
import { createProxy, lastPathMember } from "presentation/share/utils/getPath";

export type File = {
  canBeSigned?: SslComponent["canBeSigned"];
  id: Node["id"];
  isReadable?: SslComponent["fileIsReadable"];
  name?: SslComponent["fileName"];
  size?: ContentInfo["sizeInBytes"];
  metaType?: FileMetaType;
  path?: PathInfo["name"];
  content?: string | Blob;

  fileIsInOutputFormat?: SslComponent["fileIsInOutputFormat"];
  isInOutputFormat?: SslComponent["fileIsInOutputFormat"];
  isLocked?: Node["isLocked"];
  keepForm?: SslComponent["keepForm"];
  senderType?: string;

  fileIsSigned?: SslComponent["fileIsSigned"];
  fileIsEncrypted?: SslComponent["fileIsEncrypted"];
  isSigned?: SslComponent["isSign"];
  isSealed?: SslComponent["isSealed"];
  wasSigned?: boolean;
  wasSealed?: boolean;
  isEncrypted?: boolean;
  usedTimeSeal?: SslComponent["usedTimeSeal"];
  usedTimeSign?: SslComponent["usedTimeSign"];
  usedTime?: Date | null;
  nodeType?: string;
  documentId?: string;
  type?: FileMetaType;
  mimeType?: string;
  sizeInBytes?: number;
};

export enum FileMetaType {
  deliveryNote = "dorucenka",
  enclosure = "enclosure",
  main = "main",
  meta = "meta",
  signature = "signature",
  signatureVerificationProtocol = "protokol_overeni_podpisu"
}

export const fileProxy = createProxy<File>();

export const isFile = (file: unknown): file is File =>
  isObject(file) && file.hasOwnProperty(lastPathMember(fileProxy.id).path);
