import { DocumentBaseRequestType } from "presentation/core/api/document/_types";
import { SslShipment } from "presentation/core/api/models";
import { ShipmentDocument } from "presentation/core/types";
import { SendModeValues, SpisumNodeTypes } from "presentation/enums";
import { createProxy } from "../../../utils/getPath";
import { MetaFormProps } from "../_types";
import { DialogDataProps } from "../../../../core/components/dialog/_types";

export type ShipmentFormValues = SslShipment & {
  createdAt: ShipmentDocument["createdAt"];
  sendMode: SendModeValues;
  nodeType: SpisumNodeTypes;
  nodeTypeName: string;
  dateFrom?: Date;
  dateTo?: Date | null;
  days?: number | string;
};

export interface ShipmentPersonalFormValues {
  recipient?: string;
  subject?: string;
  sendMode: SendModeValues;
  address1?: string;
  address2?: string;
  address3?: string;
  address4?: string;
  addressStreet?: string;
  addressCity?: string;
  addressZip?: string;
  addressState?: string;
  recipientId: string;
  recipientAddressId: string;
  listCount: number;
  attachmentsCount: number;
  listCountAttachments: number;
  attachmentsType?: string;
}

export interface ShipmentDataboxFormValues {
  sender: string;
  allowSubstDelivery: boolean;
  legalTitleLaw: number;
  legalTitleYear: number;
  legalTitleSect: string;
  legalTitlePar: number;
  legalTitlePoint: string;
  personalDelivery: boolean;
  recipient: string;
  recipientContactDatabox: string;
  subject: string;
  toHands: string;
  components: string[];
  recipientId: string;
  recipientContactDataboxId: string;
}
export interface ShipmentPostFormValues {
  address1: string;
  address2: string;
  address3: string;
  address4: string;
  addressStreet: string;
  addressCity: string;
  addressZip: string;
  addressState: string;
  postType: string;
  postTypeOther: string;
  postItemType: string;
  postItemTypeOther: string;
  postItemWeight: number;
  postItemPrice: number;
  postItemNumber: string;
  postItemId: string;
  postItemCashOnDelivery?: number | string;
  postItemStatedPrice?: number | string;
  recipientId: string;
  recipientAddressId: string;
  listCount: number;
  attachmentsCount: number;
  listCountAttachments: number;
  attachmentsType?: string;
}

export interface ShipmentPublishFormValues {
  dateFrom: Date;
  dateTo?: Date | null;
  days?: number | string;
  note?: string;
}

//documentId is a parent shipment ID if you dont have a parent dont send It
export interface ShipmentOpenDialogDetailActionType {
  documentId?: string;
  selected: ShipmentDocument;
  readonly?: boolean;
  componentReadonly?: boolean;
  onClose?: DialogDataProps["onClose"];
}

export const shipmentFormValuesProxy = createProxy<ShipmentFormValues>();
export const shipmentPersonalFormValuesProxy = createProxy<ShipmentPersonalFormValues>();
export const shipmentPostFormValuesProxy = createProxy<ShipmentPostFormValues>();
createProxy<ShipmentDataboxFormValues>();
createProxy<ShipmentPostFormValues>();
export const shipmentPublishFormValuesProxy = createProxy<ShipmentPublishFormValues>();

export interface ShipmentSaveRequestType extends DocumentBaseRequestType {
  nodeType: SpisumNodeTypes;
}
export interface ComponentUpperFormType
  extends MetaFormProps<ShipmentFormValues> {
  toDispatchDatePosition?: toDispatchDatePosition;
  onlyThreeColumns?: boolean;
}
type toDispatchDatePosition = "bottom" | "top";
