import { FindStoredFiles } from "useCase/repository/FindStoredFiles";
import { StoredFilesRepositoryToken } from "domain/di/token/repository";
import { StoredFilesApiRepository } from "infrastructure/api/repository/StoredFilesApiRepository";
import { RentedDocumentApiRepository } from "../../infrastructure/api/repository/RentedDocumentApiRepository";
import { RentedDocumentRepositoryToken } from "../../domain/di/token/repository";
import { StoredDocumentRepositoryToken } from "../../domain/di/token/repository";
import { StoredDocumentApiRepository } from "../../infrastructure/api/repository/StoredDocumentApiRepository";
import { FindStoredDocuments } from "../../useCase/repository/FindStoredDocuments";
import { FindRentedDocuments } from "../../useCase/repository/FindRentedDocuments";

export const repositoryProviders = [
  // repository
  {
    provide: RentedDocumentRepositoryToken,
    useClass: RentedDocumentApiRepository
  },
  {
    provide: StoredFilesRepositoryToken,
    useClass: StoredFilesApiRepository
  },
  {
    provide: StoredDocumentRepositoryToken,
    useClass: StoredDocumentApiRepository
  },
  // use case
  FindStoredDocuments,
  FindRentedDocuments,
  FindStoredFiles
];
