import React, { useEffect } from "react";
import styled from "styled-components";
import { RetentionProtocolOrProposalOrErased } from "../../../../../../domain/struct/recordRetention/RetentionProtocolOrProposalOrErased";
import { DateTimeFormat, formatDate } from "../../../../../../lib/dateTime";
import { FormInstance, Item } from "../../../../../designSystem/Form/v2/Form";
import { Row } from "../../../../../designSystem/Grid/Grid";
import { Input } from "../../../../../designSystem/Input/Input";
import { translationPath } from "../../../../../share/utils/getPath";
import { t } from "../../../../../translation/i18n";
import lang from "../../../../../translation/lang";

interface BaseProtocolOrProposalFormProps {
  data?: RetentionProtocolOrProposalOrErased;
  form: FormInstance<RetentionProtocolOrProposalOrErased>;
}
export const BaseProtocolOrProposalForm = ({
  data,
  form
}: BaseProtocolOrProposalFormProps) => {
  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        ...data,
        createdAt: formatDate(data.createdAt, DateTimeFormat.FullDateTime),
        createdDate: formatDate(data.createdDate, DateTimeFormat.FullDateTime)
      });
    }
  }, [data, form]);

  return (
    <>
      <StyledRow>
        <Item
          name="pid"
          label={t(
            translationPath(
              lang.dialog.form.ShowDetailsOfRetentionProposalOrProtocolForm.pid
            )
          )}
        >
          <Input disabled={true} />
        </Item>
        <Item
          name={data?.createdAt ? "createdAt" : "createdDate"}
          label={t(
            translationPath(
              lang.dialog.form.ShowDetailsOfRetentionProposalOrProtocolForm
                .createdAt
            )
          )}
        >
          <Input disabled={true} />
        </Item>
        <Item
          name="shreddingObjectsCount"
          label={t(
            translationPath(
              lang.dialog.form.ShowDetailsOfRetentionProposalOrProtocolForm
                .shreddingObjectsCount
            )
          )}
        >
          <Input disabled={true} />
        </Item>
        <Item
          name="authorFullName"
          label={t(
            translationPath(
              lang.dialog.form.ShowDetailsOfRetentionProposalOrProtocolForm
                .authorFullName
            )
          )}
        >
          <Input disabled={true} />
        </Item>
      </StyledRow>
    </>
  );
};

const StyledRow = styled(Row)`
  display: flex;
  margin-bottom: 2rem;

  .ant-form-item {
    margin-right: 2.2rem;
    //max-width: 12.7rem;
    min-width: 14rem;

    :nth-last-of-type(1) {
      margin-right: 0;
    }
  }
`;
