import { Form, Item, useForm } from "presentation/designSystem/Form/v2/Form";
import { Row } from "presentation/designSystem/Grid/Grid";
import { Input } from "presentation/designSystem/Input/Input";
import React, { useEffect } from "react";
import styled from "styled-components";
import { RetentionProtocolOrProposalOrErased } from "../../../../../domain/struct/recordRetention/RetentionProtocolOrProposalOrErased";
import { DateTimeFormat, formatDate } from "../../../../../lib/dateTime";
import { translationPath } from "../../../../share/utils/getPath";
import { t } from "../../../../translation/i18n";
import lang from "../../../../translation/lang";
import { useFindOneRetentionErasedProtocol } from "../../features/recordRetentionProcess/retentionProtocols/hooks/useFindOneRetentionErasedProtocol";
import { BaseProtocolOrProposalForm } from "./fields/BaseProtocolOrProposalForm";

interface ShowDetailsOfRetentionProtocolFormProps {
  id: string;
}
export const RetentionProtocolErasedMetadataForm = ({
  id
}: ShowDetailsOfRetentionProtocolFormProps) => {
  const [form] = useForm<RetentionProtocolOrProposalOrErased>();
  const { data } = useFindOneRetentionErasedProtocol(id);
  // TODO: change datamodel and usecase as soon as erasedmetadataform is implemented and add formatter for the erased date
  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        ...data,
        createdDate: formatDate(data.createdDate, DateTimeFormat.FullDateTime),
        erasedDate: formatDate(data.erasedDate, DateTimeFormat.FullDateTime),
        shreddingOperationDateTime: formatDate(
          data.shreddingOperationDateTime,
          DateTimeFormat.FullDateTime
        )
      });
    }
  }, [data, form]);

  return (
    <Form form={form} name="retentionProtocol">
      <BaseProtocolOrProposalForm data={data} form={form} />

      <StyledRow>
        <Item
          name="shreddingOperationDateTime"
          label={t(
            translationPath(
              lang.dialog.form.ShowDetailsOfRetentionProposalOrProtocolForm
                .retentionModeDateAndTime
            )
          )}
        >
          <Input disabled={true} />
        </Item>
        <Item
          name="reviewerFullName"
          label={t(
            translationPath(
              lang.dialog.form.ShowDetailsOfRetentionProposalOrProtocolForm
                .reviewer
            )
          )}
        >
          <Input disabled={true} />
        </Item>
        <Item
          name="erasedDate"
          label={t(
            translationPath(
              lang.dialog.form.ShowDetailsOfRetentionProposalOrProtocolForm
                .deletionDateAndTime
            )
          )}
        >
          <Input disabled={true} />
        </Item>
      </StyledRow>
      <Item
        name="name"
        label={t(
          translationPath(
            lang.dialog.form.ShowDetailsOfRetentionProposalOrProtocolForm.name
          )
        )}
      >
        <Input disabled={true} size={"large"} />
      </Item>
    </Form>
  );
};

const StyledRow = styled(Row)`
  display: flex;
  margin-bottom: 2rem;

  .ant-form-item {
    margin-right: 2.2rem;
    //max-width: 12.7rem;
    min-width: 14rem;

    :nth-last-of-type(1) {
      margin-right: 0;
    }
  }
`;
