import { useStyles } from "presentation/core/components/dialog/Dialog.styles";
import { DialogContentType } from "presentation/core/components/dialog/_types";
import { File } from "presentation/core/entities";
import React from "react";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import * as Yup from "yup";
import { RenameComponentForm } from "./RenameComponentForm";
import { RenameComponentFormValues } from "./_types";
import { getNameWithoutExtension } from "./_utils";
import { ChannelForm } from "../ChannelForm/ChannelForm";

const renameComponentFormSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .min(0, t(translationPath(lang.dialog.errors.minLen), { len: 0 }))
    .max(50, t(translationPath(lang.dialog.errors.maxLen), { len: 50 }))
    .required(t(translationPath(lang._validations.required)))
});

export const RenameComponentContentDialog: DialogContentType["content"] = ({
  channel,
  dialogProps
}) => {
  const classes = useStyles();

  return (
    <div className={classes.modalSmall}>
      <ChannelForm<RenameComponentFormValues>
        initialValues={{
          name: getNameWithoutExtension(dialogProps.data as File)
        }}
        validationSchema={renameComponentFormSchema}
        channel={channel}
        component={RenameComponentForm}
      />
    </div>
  );
};
