import { Button } from "presentation/designSystem/Button/Button";
import { Select } from "presentation/designSystem/Select/Select";
import { Switch } from "presentation/designSystem/Switch/Switch";
import styled from "styled-components";
import { Form, Item } from "../../../../designSystem/Form/v2/Form";
import {
  lastPathMember,
  translationPath
} from "../../../../share/utils/getPath";
import { lang, t } from "../../../../translation/i18n";
import { useUpdateNameRegisterConfiguration } from "./hooks/useUpdateNameRegisterConfiguration";
import { NameRegisterConfiguration } from "domain/struct/nameRegistry/NameRegisterConfiguration";
import { configFormValuesProxy } from "./_types";
import React from "react";
import { useFindNameRegisterConfiguration } from "./hooks/useFindNameRegisterConfiguration";
import useForm from "antd/lib/form/hooks/useForm";

const options = [
  { key: "1", value: "1" },
  { key: "2", value: "2" },
  { key: "3", value: "3" }
];
const formItemLayout = {
  labelCol: {
    span: 21
  },
  wrapperCol: {
    span: 3
  }
};
export const NameRegisterConfigurationForm = () => {
  const { mutate } = useUpdateNameRegisterConfiguration();

  const { data: configuration } = useFindNameRegisterConfiguration();

  const onFinish = (values: any) => {
    mutate(values as NameRegisterConfiguration);
  };

  const [form] = useForm();

  React.useEffect(() => {
    if (configuration) {
      form.setFieldsValue({
        [lastPathMember(configFormValuesProxy.deleteSubjectsAfter).path]:
          configuration.deleteSubjectsAfter,
        [lastPathMember(configFormValuesProxy.deleteLegalPerson).path]:
          configuration.deleteLegalPerson
      });
    }
  }, [configuration, form]);

  return (
    <StyledForm
      form={form}
      {...formItemLayout}
      name="nameRegisterConfigurationForm"
      onFinish={onFinish}
      style={{
        background: "#F0F1F3",
        height: "calc(100vh - 95px - 55px - 55px)"
      }}
    >
      <StyledItem
        label={t(translationPath(lang.general.deleteSubjectsAfter))}
        name={lastPathMember(configFormValuesProxy.deleteSubjectsAfter).path}
        rules={[
          {
            required: true,
            message: t(translationPath(lang._validations.form.required))
          }
        ]}
      >
        <Select style={{ width: 120 }} options={options} />
      </StyledItem>

      <StyledItem
        label={t(translationPath(lang.general.deleteLegalPerson))}
        name={lastPathMember(configFormValuesProxy.deleteLegalPerson).path}
        valuePropName="checked"
      >
        <Switch />
      </StyledItem>

      <Item>
        <StyledButton type="primary" htmlType="submit">
          {t(translationPath(lang.general.save))}
        </StyledButton>
      </Item>
    </StyledForm>
  );
};
const StyledItem = styled(Item)`
  & > div {
    display: flex !important;
    flex-direction: row !important;
    margin-bottom: 0px !important;
    padding: 16px 22px 9px 22px;
    border-bottom: 2px solid #c5c9cc;
  }

  & label {
    font-size: 17px;
    font-weight: 700;
  }
  & .ant-form-item-explain-error {
    display: flex;
    justify-content: flex-end;
  }
  & .ant-form-item-control-input {
    display: flex;

    & .ant-form-item-control-input-content {
      display: flex;
      justify-content: flex-end;
    }
  }
`;
const StyledButton = styled(Button)`
  position: fixed;
  bottom: 65px;
  right: 22px;
`;

const StyledForm = styled(Form)`
  & .ant-form-item {
    margin-bottom: 0px !important;
  }
`;
