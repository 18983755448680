import { NameRegisterConfiguration } from "domain/struct/nameRegistry/NameRegisterConfiguration";
import { getService } from "presentation/core/features/dependencyInjection";
import { useMutation } from "presentation/share/hook/query/useQM";
import { UpdateNameRegisterConfiguration } from "useCase/nameRegistry/UpdateNameRegisterConfiguration";

export const useUpdateNameRegisterConfiguration = () => {
  const nameRegisterConfig = getService(UpdateNameRegisterConfiguration);
  return useMutation((values: NameRegisterConfiguration) =>
    nameRegisterConfig.updateNameRegisterConfiguration(values)
  );
};
