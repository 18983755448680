import { DateTimeFormat } from "lib/dateTime";
import {
  GetRemoteDataClb,
  RemoteTable,
  RemoteTableProps
} from "presentation/designSystem/Table/RemoteTable";
import { ColumnType } from "presentation/designSystem/Table/Table";
import {
  createColumn,
  createDateTimeColumn
} from "presentation/designSystem/Table/column/column";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import React, { useCallback } from "react";
import styled from "styled-components";
import { getTypeFileHistory } from "../api";
import { TransactionHistory } from "../types";

const COLUMNS: ColumnType<TransactionHistory>[] = [
  createColumn({
    dataIndex: "pid",
    title: t(translationPath(lang.table.historyTable.identifier)),
    sortDirections: ["ascend", "descend", "ascend"],
    sorter: true
  }),
  createDateTimeColumn(
    {
      dataIndex: "occurredAt",
      title: t(translationPath(lang.table.historyTable.dateOfChange)),
      sortDirections: ["ascend", "descend", "ascend"],
      defaultSortOrder: "descend",
      sorter: true
    },
    DateTimeFormat.FullDateTime
  ),
  createColumn({
    dataIndex: "description",
    title: t(translationPath(lang.table.historyTable.description))
  }),
  createColumn({
    dataIndex: "userId",
    title: t(translationPath(lang.table.historyTable.authorOfChange)),
    sortDirections: ["descend", "ascend", "descend"],
    sorter: true
  }),
  createColumn({
    dataIndex: "eventType",
    title: t(translationPath(lang.table.historyTable.typeOfChange)),
    sortDirections: ["ascend", "descend", "ascend"],
    sorter: true
  })
];
interface TypeFileHistoryTabProps {
  nodeId: string;
  onRemoteTableApi?: RemoteTableProps<TransactionHistory>["onRemoteTableApi"];
}

export const TypeFileHistoryTab = ({
  nodeId,
  onRemoteTableApi
}: TypeFileHistoryTabProps) => {
  const getRemoteData: GetRemoteDataClb<TransactionHistory> = useCallback(
    ({ pagination, sorting }) =>
      getTypeFileHistory(nodeId, pagination, sorting),
    [nodeId]
  );

  return (
    <TableStyled>
      <RemoteTable<TransactionHistory>
        name={`evidence/typeFileHistory/${nodeId}`}
        rowKey="pid"
        columns={COLUMNS}
        getRemoteData={getRemoteData}
        onRemoteTableApi={onRemoteTableApi}
      />
    </TableStyled>
  );
};

const TableStyled = styled("div")`
  div > .ant-row {
    height: 50px;
  }
`;
