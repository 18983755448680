import { ApiTypes } from "../struct/ApiTypes";
import { Subject } from "../../../domain/struct/nameRegistry/Subject";
import { Person } from "../../../domain/struct/nameRegistry/Person";
import { LegalEntity } from "../../../domain/struct/nameRegistry/LegalEntity";
import { SubjectType } from "../../../domain/struct/nameRegistry/SubjectType";
import { stringOrDefault } from "../../../lib/string";

export type ApiSubject = ApiTypes["ISFG.SpisUm.ClientSide.Models.NameRegister.SubjectInfo"];

export class SubjectApiMapper {
  static mapToDomain(subject: ApiSubject): Subject {
    return subject?.subjectType === SubjectType.Person
      ? SubjectApiMapper.mapToPerson(subject)
      : SubjectApiMapper.mapToLegalEntity(subject);
  }

  static mapToPerson(apiPerson: ApiSubject): Person {
    return {
      id: stringOrDefault(apiPerson.id),
      identifier: stringOrDefault(apiPerson.identifier),
      surname: stringOrDefault(apiPerson.surname),
      firstName: stringOrDefault(apiPerson.firstName),
      maidenName: stringOrDefault(apiPerson.birthSurname),
      fullName: stringOrDefault(apiPerson.fullName),
      processedAt: stringOrDefault(apiPerson.processedAt),
      title: stringOrDefault(apiPerson.degreeBefore),
      degree: stringOrDefault(apiPerson.degreeAfter),
      bornDate: stringOrDefault(apiPerson.birthdate),
      bornPlace: stringOrDefault(apiPerson.birthPlace),
      subjectType: SubjectType.Person,
      citizenship: stringOrDefault(apiPerson.nationalityCode)
    };
  }

  static mapToLegalEntity(apiLegalEntity: ApiSubject): LegalEntity {
    return {
      id: stringOrDefault(apiLegalEntity.id),
      identifier: stringOrDefault(apiLegalEntity.identifier),
      companyFullName: stringOrDefault(apiLegalEntity.companyFullName),
      identificationNumber: stringOrDefault(
        apiLegalEntity.identificationNumber
      ),
      taxIdentificationNumber: stringOrDefault(
        apiLegalEntity.vatIdentificationNumber
      ),
      processedAt: stringOrDefault(apiLegalEntity.processedAt),
      fullName: stringOrDefault(apiLegalEntity.fullName),
      subjectType: SubjectType.LegalEntity
    };
  }
}
