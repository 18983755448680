import { getService } from "presentation/core/features/dependencyInjection";
import { FindStoredFiles } from "../../../../../../useCase/repository/FindStoredFiles";
import { PaginationConfig } from "lib/contract/Pagination";
import { useCallback } from "react";
import { SortingConfig } from "lib/contract/Sorting";

// 5. step - prepare to connect use case to presentation
export const useFindStoredFiles = () => {
  const findStoredFiles = getService(FindStoredFiles);

  return useCallback(
    (config: PaginationConfig, sorting?: SortingConfig) =>
      findStoredFiles.findAllPaginated(config, sorting),
    [findStoredFiles]
  );
};
