import { Form } from "formik";
import moment from "moment";
import { useStyles } from "presentation/core/components/dialog/Dialog.styles";
import {
  DialogDataGenericData,
  DialogDataProps,
  TabAndDialogChannelType
} from "presentation/core/components/dialog/_types";
import { GenericDocument } from "presentation/core/types";
import { SendModeValues, SpisumNodeTypes } from "presentation/enums";
import { EnumSelect } from "presentation/share/components/form/enumSelect/EnumSelect";
import { lang, t } from "presentation/translation/i18n";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Address } from "../../../../../domain/struct/nameRegistry/Address";
import { Contact } from "../../../../../domain/struct/nameRegistry/Contact";
import { LegalEntity } from "../../../../../domain/struct/nameRegistry/LegalEntity";
import { Person } from "../../../../../domain/struct/nameRegistry/Person";
import { useModal } from "../../../../designSystem/Modal/useModal";
import { Notification } from "../../../../designSystem/notification/Notification";
import { SearchSubjectModal } from "../../../../modules/nameRegister/modal/SearchSubjectModal/SearchSubjectModal";
import { lastPathMember, translationPath } from "../../../utils/getPath";
import { ChannelForm } from "../ChannelForm/ChannelForm";
import { FormFieldsBasedOnSendMode } from "./forms/formFieldsBasedOnSendMode/FormFieldsBasedOnSendMode";
import { MuiFormikShipmentHelper } from "./MuiFormikShipmentHelper";
import clsx from "clsx";
import {
  CreateShipmentFormValues,
  CreateShipmentFormValuesProxy
} from "./_types";
import { validate } from "./_validations";
import {
  ShipmentComponentTable,
  ShipmentComponentTableProps
} from "../../../../modules/evidence/component/ShipmentComponentTable/ShipmentComponentTable";
import { useScrollToElement } from "../../../../core/components/dialog/hooks/useScrollToElement";
import { Text } from "presentation/designSystem/Typography/Typography";

const attachmentComponentBlacklist = [
  SendModeValues.Post,
  SendModeValues.Personally
];

interface OwnProps {
  channel: TabAndDialogChannelType;
  dialogProps: DialogDataProps;
  readonly: boolean;
}

export interface SearchSubjectResult {
  subject?: Person | LegalEntity;
  addresses?: Address[];
  contacts?: Contact[];
}

export const CreateShipmentForm = React.memo(
  ({ channel, dialogProps, readonly }: OwnProps) => {
    const dialogClasses = useStyles();
    const scrollToElement = useScrollToElement(".scrollingContainer");
    const dialogData = dialogProps.data as DialogDataGenericData;
    const isFileType = dialogData.nodeType === SpisumNodeTypes.File;

    const [sendMode, setSendMode] = useState(
      isFileType ? SendModeValues.Post : SendModeValues.Email
    );

    useEffect(() => {
      channel.setState({
        ...channel.state,
        preventAction:
          // it is a not file and it should have components -> 0 components is selected, disable action
          !isFileType && !attachmentComponentBlacklist.includes(sendMode)
      });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const defaultDays = 15;

    const initialValues = useMemo(
      () => ({
        address1: "",
        address2: "",
        address3: "",
        address4: "",
        addressCity: "",
        addressState: "",
        addressStreet: "",
        addressZip: "",
        allowSubstDelivery: false,
        dateFrom: moment().toDate(),
        dateTo: moment().add(defaultDays, "d").toDate(),
        days: defaultDays,
        legalTitleLaw: "",
        legalTitlePar: "",
        legalTitlePoint: "",
        legalTitleSect: "",
        legalTitleYear: "",
        note: "",
        personalDelivery: false,
        postItemCashOnDelivery: 0,
        postItemStatedPrice: 0,
        postItemType: "",
        postItemTypeOther: "",
        postItemWeight: "",
        postType: [],
        postTypeOther: "",
        recipientContactEmail: "",
        recipientContactDatabox: "",
        sendMode: isFileType ? SendModeValues.Post : SendModeValues.Email,
        sender: "",
        subject:
          (dialogProps.data as GenericDocument)?.properties?.ssl?.subject || "",
        toHands: "",
        recipientId: "",
        recipientAddressId: "",
        recipientContactDataboxId: "",
        recipientContactEmailBoxId: "",
        listCount: "",
        attachmentsCount: "",
        listCountAttachments: ""
      }),
      [isFileType, defaultDays, dialogProps.data]
    );

    const [searchResult, setSearchResult] = useState<SearchSubjectResult>();

    const [modal, modalApi] = useModal(SearchSubjectModal, {
      onOk: (result?: SearchSubjectResult) => {
        if (
          sendMode === SendModeValues.Email ||
          sendMode === SendModeValues.Databox
        ) {
          const contactTypeCheck = result?.contacts?.find(
            (contact) => contact.contactType.toLowerCase() === sendMode
          );

          if (!contactTypeCheck) {
            Notification.error({
              message:
                sendMode === SendModeValues.Email
                  ? t(translationPath(lang.general.mailNotExists))
                  : t(translationPath(lang.general.dataBoxNotExists))
            });

            return Promise.reject();
          }
        }
        setSearchResult(result);
        return Promise.resolve();
      }
    });

    const onSearchSubject = () => {
      modalApi.open({
        deliveryMode: sendMode
      });
    };

    const onSelectionChanged: ShipmentComponentTableProps["onSelectionChanged"] = useCallback(
      (ids, validationResult) => {
        channel.setState({
          ...channel.state,
          selectedComponentsIds: ids,
          preventAction:
            validationResult.isWrongCountOfFiles || validationResult.isTooBig
        });
      },
      [channel]
    );

    const onPreviewClicked: ShipmentComponentTableProps["onPreviewClicked"] = useCallback(
      (component) => {
        channel.setPreviewItem(
          component.isEncrypted || false,
          {
            id: component.id,
            nodeType: dialogData.nodeType,
            entityId: dialogData.id,
            name: component.fileName,
            fileIsEncrypted: component.isEncrypted
          },
          true
        );
      },
      [channel, dialogData.id, dialogData.nodeType]
    );
    const emptyField = (
      keys: string[],
      isSubmitting: boolean,
      isValidating: boolean
    ) => {
      if (keys.length > 0 && isSubmitting && !isValidating) {
        const selector = `[name="${keys[0]}"]`;
        scrollToElement(selector);
      } else if (keys.length === 0 && isSubmitting && !isValidating) {
        scrollToElement();
      }
    };
    return (
      <>
        {modal}
        <ChannelForm<CreateShipmentFormValues>
          enableReinitialize
          initialValues={initialValues}
          validate={validate}
          channel={channel}
        >
          {({
            setFieldValue,
            values,
            resetForm,
            isSubmitting,
            isValidating,
            errors
          }) => {
            const showAttachmentsComponent = !attachmentComponentBlacklist.includes(
              values.sendMode
            );

            const keys = Object.keys(errors!);
            emptyField(keys, isSubmitting, isValidating);

            const handleSendModeChange = (
              e: React.ChangeEvent<HTMLInputElement>
            ) => {
              resetForm({ values: initialValues });
              const value = e.target.value as SendModeValues;
              const sslSubject =
                (dialogProps.data as GenericDocument)?.properties?.ssl
                  ?.subject || "";
              setFieldValue("sendMode", value);
              setFieldValue(
                "subject",
                value === SendModeValues.Databox || SendModeValues.Email
                  ? sslSubject
                  : ""
              );
              setSendMode(value);

              if (attachmentComponentBlacklist.includes(value)) {
                channel.setState({
                  ...channel.state,
                  preventAction: false
                });
              }
            };

            return (
              <Form className={dialogClasses.form}>
                <MuiFormikShipmentHelper
                  searchSubjectResult={searchResult}
                  sendMode={values.sendMode}
                />
                <EnumSelect
                  enumType={SendModeValues}
                  translations={lang.enums.deliveryMode}
                  name={
                    lastPathMember(CreateShipmentFormValuesProxy.sendMode).path
                  }
                  label={t(translationPath(lang.general.sendMode))}
                  disabledItems={
                    isFileType
                      ? [
                          SendModeValues.Databox,
                          SendModeValues.Email,
                          SendModeValues.Publish
                        ]
                      : undefined
                  }
                  onChange={handleSendModeChange}
                />
                <FormFieldsBasedOnSendMode
                  readonly={readonly}
                  sendModeValue={values.sendMode}
                  values={values}
                  onSearchSubject={onSearchSubject}
                />
                {showAttachmentsComponent && (
                  <div
                    className={clsx(
                      dialogClasses.fullWidth,
                      "scrollingContainer"
                    )}
                  >
                    <div style={{ paddingBottom: "1.5rem" }}>
                      <Text strong>
                        {t(
                          translationPath(lang.dialog.form.sections.attachments)
                        )}
                      </Text>
                    </div>
                    <ShipmentComponentTable
                      documentId={dialogData.id!}
                      shipmentType={sendMode}
                      onSelectionChanged={onSelectionChanged}
                      onPreviewClicked={onPreviewClicked}
                    />
                  </div>
                )}
              </Form>
            );
          }}
        </ChannelForm>
      </>
    );
  }
);
