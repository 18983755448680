import { ApiListResponseMapper } from "infrastructure/api/mapper/ApiListResponseMapper";
import {
  ApiListResponse,
  ApiTypes
} from "infrastructure/api/struct/ApiTypesV2";
import { stringOrDefault } from "lib/string";
import { ReturnForRework } from "../types/ReturnForRework";

export type ApiReturnForReworkResponse = ApiTypes["ISFG.SpisUm.ClientSide.Models.V2.Overview.OverviewReturnedForReworkResponse"];

export class Mappers {
  static mapListToDomain(
    response: ApiListResponse<ApiReturnForReworkResponse>
  ) {
    return ApiListResponseMapper.mapListToPagination(
      response,
      Mappers.mapToDomain
    );
  }

  static mapToDomain(response: ApiReturnForReworkResponse): ReturnForRework {
    const {
      nodeId,
      pid,
      ssid,
      subject,
      deliveryDate,
      returnForReworkDate,
      attachmentsCount,
      owner
    } = response;
    return {
      nodeId: stringOrDefault(nodeId),
      pid: stringOrDefault(pid),
      ssid: stringOrDefault(ssid),
      subject: stringOrDefault(subject),
      deliveryDate: stringOrDefault(deliveryDate),
      returnForReworkDate: stringOrDefault(returnForReworkDate),
      attachmentsCount,
      owner: stringOrDefault(owner)
    };
  }
}
