import { Contact } from "../../../../../../../../domain/struct/nameRegistry/Contact";
import { ContactType } from "../../../../../../../../domain/struct/nameRegistry/ContactType";
import { Address } from "../../../../../../../../domain/struct/nameRegistry/Address";
import { CountryType } from "../../../../../../../../domain/struct/codelist/CountryType";
import { t } from "../../../../../../../translation/i18n";

export const findContactById = (contacts: Contact[], id: number) => {
  return contacts.find((contact) => contact.id === id);
};

export const findContactByType = (contacts: Contact[], type: string) => {
  return contacts.find((contact) => type === contact.contactType);
};

export const findContactsByType = (contacts: Contact[], type: ContactType) => {
  return contacts.filter(({ contactType }) => contactType === type);
};

export const formatAddress = (
  {
    addressType,
    street,
    houseNumber,
    orientationHouseNumber,
    orientationHouseNumberAddendum,
    city,
    cityPart,
    country,
    postalCode
  }: Address,
  countryCodesAndNames?: CountryType[] | undefined
) => {
  const countryCzechName =
    countryCodesAndNames &&
    countryCodesAndNames.find(
      (countryCodeAndName) => country === countryCodeAndName.code
    );

  return `${street ?? ""} ${houseNumber ?? ""}${
    orientationHouseNumber ? "/" + orientationHouseNumber : ""
  }${orientationHouseNumberAddendum ? orientationHouseNumberAddendum : ""}, ${
    postalCode ?? ""
  } ${cityPart ? cityPart : ""}, ${city ?? ""}, ${
    countryCzechName?.czechName ?? ""
  } ${getAddressTypeTranslation(addressType)} `;
};

export const getAddressTypeTranslation = (addressType?: string) => {
  if (!addressType) {
    return "";
  }

  const translation = t(`enums:addressType.${addressType}`);

  return `(${translation})`;
};
