import React from "react";
import MenuLayout from "presentation/core/components/layout/MenuLayout";
import { withTranslation } from "presentation/translation/i18n";
import { RentedDocumentsTable } from "./tables/RentedDocumentsTable";

const Rented = () => {
  return (
    <MenuLayout>
      <RentedDocumentsTable />
    </MenuLayout>
  );
};

export default withTranslation()(Rented);
