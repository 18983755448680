import { callAsyncAction } from "presentation/core/action";
import { updateFileAction } from "presentation/core/api/file/_actions";
import { SslProperties } from "presentation/core/api/models";
import { secondaryAction } from "presentation/core/components/dialog/lib/actionsFactory";
import { CommentsTab } from "presentation/core/components/dialog/tabs/comments";
import { HistoryTab } from "presentation/core/components/dialog/tabs/history";
import { ProcessingOrClosingTab } from "presentation/core/components/dialog/tabs/processingOrClosing/ProcessingOrClosingTab";
import { SaveAndDiscardTab } from "presentation/core/components/dialog/tabs/saveAndDiscard/SaveAndDiscardTab";
import { ShipmentTab } from "presentation/core/components/dialog/tabs/shipment";
import { TableOfContentsTab } from "presentation/core/components/dialog/tabs/tableOfContents";
import {
  DialogContentType,
  DialogDataGenericData,
  DialogType
} from "presentation/core/components/dialog/_types";
import { documentViewAction__Refresh } from "presentation/core/components/documentView/_actions";
import NamedTitle from "presentation/core/components/namedTitle";
import { GenericDocument } from "presentation/core/types";
import React from "react";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import { createDocumentDialog } from "../baseDocumentDialog/documentDialogFactory";
import MetadataFormTab from "./MetadataFormTab";
import { fileConcernedSubjectTab } from "../../dialogTabs/ConcernedSubject";

export const fileDetailsDialogContent: DialogContentType = createDocumentDialog(
  {
    actions: () => [
      secondaryAction(
        t(translationPath(lang.dialog.form.save)),
        ({ dispatch, dialogProps, onClose, channels, buttonState }) => {
          const formValues = channels?.Metadata?.state?.formValues;

          if (!formValues) {
            return;
          }

          const onSuccess = () => {
            // set all tabs to saved to close modal
            for (const key in channels) {
              if (!channels.hasOwnProperty(key)) continue;
              channels[key].setIsSaved(true);
            }

            dispatch(documentViewAction__Refresh(true));
            onClose();
          };

          const onError = () => {
            buttonState.setIsPending(false);
            channels?.Metadata?.setIsSaved(false);
          };

          buttonState.setIsPending(true);
          dispatch(
            callAsyncAction({
              action: updateFileAction,
              onError,
              onSuccess,
              payload: {
                nodeId: (dialogProps.data as DialogDataGenericData)?.id,
                properties: formValues as SslProperties
              }
            })
          );
        }
      )
    ],
    tabs: [
      {
        content: MetadataFormTab,
        label: t(translationPath(lang.dialog.tabs.metadata))
      },
      {
        content: TableOfContentsTab,
        label: t(translationPath(lang.dialog.tabs.content))
      },
      fileConcernedSubjectTab,
      {
        content: ProcessingOrClosingTab,
        filter: ({ dialogProps }) => {
          const { state } =
            (dialogProps.data as GenericDocument)?.properties?.ssl || {};
          return ProcessingOrClosingTab.filter(state);
        },
        label: t(translationPath(lang.dialog.tabs.processOrClose))
      },
      {
        content: ShipmentTab,
        label: t(translationPath(lang.dialog.tabs.shipment))
      },
      {
        content: SaveAndDiscardTab,
        filter: ({ dialogProps }) => {
          const { state } =
            (dialogProps.data as GenericDocument)?.properties?.ssl || {};

          return SaveAndDiscardTab.filter(state);
        },
        label: t(translationPath(lang.dialog.tabs.saveAndDiscard))
      },
      {
        content: CommentsTab,
        label: t(translationPath(lang.dialog.tabs.notes))
      },
      {
        content: HistoryTab,
        label: t(translationPath(lang.dialog.tabs.history))
      }
    ],
    title: (props) => (
      <NamedTitle
        text={t(translationPath(lang.dialog.title.fileDetail))}
        {...props}
      />
    ),
    type: DialogType.FileDetails
  }
);
