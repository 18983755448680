import {
  componentCreateAction,
  componentDownloadAction
} from "presentation/core/api/components/_actions";
import {
  DataColumn,
  ValueType
} from "presentation/core/components/dataTable/_types";
import { GenericDocument } from "presentation/core/types";
import { useModal } from "presentation/designSystem/Modal/useModal";
import { Notification } from "presentation/designSystem/notification/Notification";
import {
  ComponentKeepForm,
  DocumentType,
  SenderType,
  SpisumNodeTypes
} from "presentation/enums";
import { useQueryClient } from "presentation/govDesignSystem/Table/hook/query/useQuery";
import { EncryptedComponentsPasswordModal } from "presentation/modules/mailroom/features/income/digitalDocument/modals/EncryptedComponentsPasswordModal";
import { RootStateType } from "presentation/reducers";
import { renameComponentAction } from "presentation/share/components/dialog/renameComponentDialog/_actions";
import { getErrorCodeTranslation } from "presentation/share/utils/errorCodeTranslation";
import { classPath, translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import { ErrorType } from "presentation/types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SortDirection } from "../../../../../govDesignSystem/lib/contract/Sorting";
import { useMutation } from "../../../../../share/hook/query/useMutation";
import { callAsyncAction } from "../../../../action";
import { ComponentType } from "../../../../api/components/_types";
import { SslProperties } from "../../../../api/models";
import { File, FileMetaType, fileProxy } from "../../../../entities";
import { useMetaFormDocument } from "../../hooks/useMetaFormDocument";
import { dialogOpenAction } from "../../_actions";
import {
  DialogDataGenericData,
  DialogTabContentPropsType,
  DialogType
} from "../../_types";
import {
  INACTIVE_DOCUMENTS_QUERY_KEY,
  useGetInactiveComponents
} from "../InactivateComponents/hooks/useGetInactiveComponents";
import { postDeActivateComponents } from "./api";
import ComponentsTab from "./ComponentsTab";
import {
  ACTIVE_DOCUMENTS_QUERY_KEY,
  useGetDocumentsActiveComponents
} from "./hooks/useGetActiveComponents";
import { useUpdateActiveComponents } from "./hooks/useUpdateActiveComponents";
import { ActiveComponent, SelectedComponentsFnType } from "./_types";

type CommentsTabContainerState = {
  pageNumber: number;
  rowsPerPage: number;
  sortAsc?: boolean;
  sortColumnIndex?: number;
  sortKeys?: string[];
};

const initialState: CommentsTabContainerState = {
  pageNumber: 0,
  rowsPerPage: 100
};

type OwnProps = DialogTabContentPropsType & {
  nodeId: string;
  isReadonly?: boolean;
  componentType?: ComponentType;
};

const ComponentsTabContainer = React.memo(
  ({
    channel,
    componentType = ComponentType.Document,
    dialogProps,
    isReadonly,
    nodeId,
    showTab
  }: OwnProps) => {
    const dispatch = useDispatch();
    const [
      { pageNumber, rowsPerPage, sortKeys, sortColumnIndex, sortAsc },
      setState
    ] = useState<CommentsTabContainerState>(initialState);

    const { error } = useSelector(
      (state: RootStateType) => state.componentsReducer
    );
    const metaFormDocument = useMetaFormDocument();
    const nodeType =
      (dialogProps.data as DialogDataGenericData)?.nodeType ||
      metaFormDocument?.nodeType;
    const { data, refetch, isFetching } = useGetDocumentsActiveComponents(
      nodeId,
      SpisumNodeTypes.Document,
      true,
      {
        page: pageNumber ? pageNumber : 1,
        itemsPerPage: rowsPerPage ? rowsPerPage : 10
      },
      [
        {
          property: sortKeys && sortKeys.length ? sortKeys![0] : "name",
          direction: sortAsc
            ? SortDirection.Ascending
            : SortDirection.Descending
        }
      ]
    );

    const {
      data: inactiveComponent,
      refetch: refetchInactiveComponents
    } = useGetInactiveComponents(
      nodeId,
      true,
      {
        page: 1,
        itemsPerPage: 5
      },
      [
        {
          property: "name",
          direction: SortDirection.Ascending
        }
      ]
    );

    const getNotificationForError = (error: ErrorType) => ({
      message: getErrorCodeTranslation(error.code ?? "")
    });
    const updateActiveComponents = useUpdateActiveComponents();
    const { isLoading, mutate: updatingActiveComponent } = useMutation(
      (body: ActiveComponent) => updateActiveComponents(nodeId, body),
      {
        onSuccess() {
          fetchComponents();
        },
        onSuccessNotification: null,
        onErrorNotification: getNotificationForError
      }
    );
    const queryClient = useQueryClient();
    const { mutate: deActivateComponents } = useMutation(
      (components: File[]) => postDeActivateComponents(nodeId, components),
      {
        onSuccess() {
          queryClient.invalidateQueries(INACTIVE_DOCUMENTS_QUERY_KEY);
          queryClient.invalidateQueries(ACTIVE_DOCUMENTS_QUERY_KEY);
        },
        onSuccessNotification: {
          message: t(
            translationPath(lang.dialog.notifications.componentsDeactivated)
          )
        },
        onErrorNotification: {
          message: t(
            translationPath(
              lang.dialog.notifications.componentsDeactivationFailed
            )
          )
        }
      }
    );
    let sortedComponents = useMemo(() => {
      return data ? data.items : [];
    }, [data, sortKeys]);
    const fetchComponents = () => {
      refetch();
    };
    useEffect(() => {
      channel.refreshData = fetchComponents;
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      refetch();
    }, [nodeId, rowsPerPage, pageNumber, sortColumnIndex, sortAsc]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      if (
        !sortedComponents.length ||
        !handleCanShowPreview(sortedComponents[0]) ||
        channel.previewItem
      ) {
        return;
      }

      channel.setPreviewItem(sortedComponents[0].isEncrypted || false, {
        ...sortedComponents[0],
        entityId: nodeId,
        nodeType
      });
    }, [channel, nodeId, nodeType, sortedComponents]);
    const showActions = () => {
      if (isReadonly || dialogProps.canUploadComponents === false) {
        return false;
      }

      const document = dialogProps.data as GenericDocument;

      const { isLocked, formValues } = metaFormDocument;
      const typedFormValues = formValues as SslProperties;
      const form = document?.properties?.ssl?.form || typedFormValues?.form;
      const file = document?.isFile;

      const senderType =
        document?.properties?.ssl?.senderType || typedFormValues?.senderType;
      const documentType =
        document?.properties?.ssl?.documentType ||
        typedFormValues?.documentType;
      const documentIsLocked = document?.isLocked || isLocked;

      return (
        (form === DocumentType.Analog ||
          (DocumentType.Digital &&
            (senderType === SenderType.Own ||
              (documentType === DocumentType.TechnicalDataCarries &&
                !file)))) &&
        !documentIsLocked
      );
    };

    const onError = useCallback((file: globalThis.File, error: ErrorType) => {
      Notification.error({
        message: error.code
          ? getErrorCodeTranslation(error.code)
          : Array.isArray(error.messages) && error.messages.length > 0
          ? error.messages[0]
          : t(translationPath(lang.dialog.notifications.actionFailed))
      });
    }, []);

    const handleCanDeactivateComponent = (component: File) =>
      component.type !== FileMetaType.main;

    const handleDeactivateComponent = useCallback(
      (selectedComponents: File[]) => {
        const document = dialogProps?.data as GenericDocument;
        const signedDocument = document?.properties?.ssl?.isSign;
        const sealedDocument = document?.properties?.ssl?.isSealed;
        // For the DOWNLOADED signed component :
        // const sealedComponent = selectedComponents[0].isSealed;
        // const signedComponent = selectedComponents[0].isSigned;
        // return !(signedDocument || sealedDocument) && !(sealedComponent || signedComponent) && deActivateComponents(selectedComponents);
        return (
          !(signedDocument || sealedDocument) &&
          deActivateComponents(selectedComponents)
        );
      },
      [deActivateComponents, dialogProps?.data]
    );
    const handleCanShowDeactivateComponent = () => {
      return true;
    };

    const [
      encryptedComponentsPasswordModal,
      encryptedComponentsPasswordModalApi
    ] = useModal(EncryptedComponentsPasswordModal, {
      onOk: () => {
        fetchComponents();
        refetchInactiveComponents();
      }
    });

    const handleOpenPopUpPassword = (file: File) =>
      encryptedComponentsPasswordModalApi.open({
        componentType: ComponentType.Document,
        nodeId,
        componentId: file.id
      });

    const handleValidateCanShowDeactivateComponent = useCallback(async () => {
      return inactiveComponent && inactiveComponent.items.length > 0
        ? ""
        : t(translationPath(lang._validations.noInactiveComponents));
    }, [inactiveComponent]);

    const handleShowDeactivateComponent = useCallback(() => {
      showTab("2", true); // inactive tab has key '2' and we want to switch to this tab
    }, [showTab]);
    const handleChangePage: (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
      page: number
    ) => void = useCallback((_, page) => {
      setState((state) => ({
        ...state,
        pageNumber: page
      }));
    }, []);

    const handleChangeRowsPerPage: (
      event: React.ChangeEvent<HTMLInputElement>
    ) => void = useCallback((event) => {
      setState((state) => ({
        ...state,
        pageNumber: 0,
        rowsPerPage: parseInt(event.target.value, 10)
      }));
    }, []);

    const handleUploadComponent = useCallback(
      (files: globalThis.File[]) => {
        const onSuccess = () => {
          Notification.success({
            message: t(
              translationPath(
                lang.dialog.notifications.componentUploadWasSuccessful
              )
            )
          });
        };

        dispatch(
          componentCreateAction.request({
            files,
            nodeId,
            onEnd: fetchComponents,
            onError,
            onSuccess,
            type: componentType
          })
        );
      },
      [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const handleSwapComponentContent = useCallback(
      (selected: File[], files: globalThis.File[]) => {
        const onSuccess = () => {
          Notification.success({
            message: t(
              translationPath(
                lang.dialog.notifications.componentUploadWasSuccessful
              )
            )
          });
        };

        dispatch(
          componentCreateAction.request({
            componentId: selected[0].id,
            files,
            nodeId,
            onEnd: fetchComponents,
            onError,
            onSuccess,
            type: componentType
          })
        );
      },
      [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const handleDownloadComponent = useCallback(
      (selectedComponents: File[]) => {
        Notification.success({
          message: t(
            translationPath(
              lang.dialog.notifications.componentDownloadIsPreparing
            )
          )
        });

        dispatch(
          callAsyncAction({
            action: componentDownloadAction,
            payload: {
              componentIds: selectedComponents.map((c) => c.id),
              nodeId,
              nodeType
            }
          })
        );
      },
      [dispatch, nodeId, nodeType]
    );

    const handleColumnChange = useCallback(
      (row: File, column: DataColumn<File>, value: ValueType) => {
        let updateComponentObject = {
          [column.keys[0]]: value,
          componentId: row.id
        };
        if (column.keys[0] === classPath(fileProxy.type).path) {
          updateComponentObject = {
            ...updateComponentObject,
            isReadable: row.isReadable
          };
        } else {
          updateComponentObject = { ...updateComponentObject, type: row.type };
        }
        updatingActiveComponent(updateComponentObject);
      },
      [dispatch, metaFormDocument.documentId, metaFormDocument.nodeType]
    );

    const handleRenameComponent: SelectedComponentsFnType = useCallback(
      (selectedComponents) => {
        dispatch(renameComponentAction(selectedComponents[0]));
      },
      [dispatch]
    );

    const handleCanDeleteComponent = showActions;

    const handleCanCreateComponent = () => {
      const document = dialogProps?.data as GenericDocument;
      const signedDocument = document?.properties?.ssl?.isSign;
      const sealedDocument = document?.properties?.ssl?.isSealed;
      return !(signedDocument || sealedDocument) && showActions();
    };

    const handleCanUploadNewVersion = (component: File) => {
      const document = dialogProps?.data as GenericDocument;
      const signedDocument = document?.properties?.ssl?.isSign;
      const sealedDocument = document?.properties?.ssl?.isSealed;
      const { formValues } = metaFormDocument;
      const typedFormValues = formValues as SslProperties;
      const documentType =
        document?.properties?.ssl?.documentType ||
        typedFormValues?.documentType;
      if (documentType === DocumentType.TechnicalDataCarries) {
        return false;
      } else if (!showActions() || component.isLocked) {
        return false;
      } else if (signedDocument || sealedDocument) {
        return false;
      } else if (component.senderType === SenderType.Own) {
        return true;
      }

      return component?.keepForm !== ComponentKeepForm.OriginalInOutputFormat;
    };

    const handleCanShowPreview = (component: File) => {
      // filter by extensions
      return true;
    };

    const handleConvertToOutputFormat = (cmp: File[]) => {
      return dispatch(
        dialogOpenAction({
          dialogProps: {
            data: {
              componentId: cmp[0].id,
              id: nodeId
            },
            onSuccess: fetchComponents
          },
          dialogType: DialogType.ConvertToOutput
        })
      );
    };

    const handleCanRenameComponent = showActions;

    const handleCanConvertToOutputFormat = (component: File) => {
      const document = dialogProps?.data as GenericDocument;
      const signedDocument = document?.properties?.ssl?.isSign;
      const sealedDocument = document?.properties?.ssl?.isSealed;

      return (
        dialogProps.disableConverIcon !== true &&
        !document?.isLocked &&
        component?.isInOutputFormat !== "yes" &&
        // in case of isInOutputFormat "impossible" - can be shown, but needs a validation
        // due to an error, it was decided to hide components with "impossible"
        component?.isInOutputFormat !== "impossible" &&
        !!component?.isEncrypted === false &&
        component?.isInOutputFormat !== "converted" &&
        !isReadonly &&
        !(signedDocument || sealedDocument)
      );
    };

    const handleShowPreview = useCallback(
      (selected: File[]) => {
        if (!selected[0]) {
          return;
        }

        channel.setPreviewItem(
          selected[0]?.isEncrypted || false,
          {
            ...selected[0],
            entityId: nodeId,
            nodeType
          },
          true
        );
      },
      [channel, nodeId, nodeType]
    );

    const handleSelectionChange = useCallback(
      (selected: File[]) => {
        if (!selected[0] || !handleCanShowPreview(selected[0])) {
          return;
        }

        channel.setPreviewItem(selected[0].isEncrypted || false, {
          ...selected[0],
          entityId: nodeId,
          nodeType
        });
      },
      [channel, nodeId, nodeType]
    );

    const handleSortingChange: (
      index: number,
      keys: string[]
    ) => (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void = (
      index,
      keys
    ) => (event) => {
      setState((state) => ({
        ...state,
        sortAsc: index === state.sortColumnIndex ? !sortAsc : false,
        sortColumnIndex: index,
        sortKeys: keys
      }));
    };

    if (error) {
      return <div />;
    }
    return (
      <>
        {encryptedComponentsPasswordModal}
        <ComponentsTab
          handleCanConvertToOutputFormat={handleCanConvertToOutputFormat}
          handleCanCreateComponent={handleCanCreateComponent}
          handleCanDeleteComponent={handleCanDeleteComponent}
          handleCanRenameComponent={handleCanRenameComponent}
          handleCanShowPreview={handleCanShowPreview}
          handleCanUploadNewVersion={handleCanUploadNewVersion}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleColumnChange={handleColumnChange}
          handleConvertToOutputFormat={handleConvertToOutputFormat}
          handleDownloadComponent={handleDownloadComponent}
          handleRenameComponent={handleRenameComponent}
          handleSelectionChange={handleSelectionChange}
          handleShowPreview={handleShowPreview}
          handleSortingChange={handleSortingChange}
          handleSwapComponentContent={handleSwapComponentContent}
          handleUploadComponent={handleUploadComponent}
          handleShowDeactivateComponent={handleShowDeactivateComponent}
          handleDeactivateComponent={handleDeactivateComponent}
          handleCanShowDeactivateComponent={handleCanShowDeactivateComponent}
          handleValidateCanShowDeactivateComponent={
            handleValidateCanShowDeactivateComponent
          }
          handleCanDeactivateComponent={handleCanDeactivateComponent}
          handleOpenPopUpPassword={handleOpenPopUpPassword}
          isLoading={isFetching || isLoading}
          isReadonly={isReadonly || !!dialogProps.isReadonly}
          items={sortedComponents}
          pageNumber={data ? data.page - 1 : 0}
          refreshTable={fetchComponents}
          rowsPerPage={data ? data.itemsPerPage : 10}
          sortAsc={sortAsc}
          sortColumnIndex={sortColumnIndex}
          totalItems={data?.total || 0}
          dialogProps={dialogProps}
        />
      </>
    );
  }
);
export default ComponentsTabContainer;
