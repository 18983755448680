import { callAsyncAction } from "presentation/core/action";
import { documentLostDestroyedActionType } from "presentation/core/api/document/_actions";
import { lostDestroyedFileAction } from "presentation/core/api/file/_actions";
import { secondaryAction } from "presentation/core/components/dialog/lib/actionsFactory";
import {
  DialogContentType,
  DialogType
} from "presentation/core/components/dialog/_types";
import { documentViewAction__Refresh } from "presentation/core/components/documentView/_actions";
import NamedTitle from "presentation/core/components/namedTitle";
import { ReasonFormValues } from "presentation/core/components/reasonForm/_types";
import { GenericDocument } from "presentation/core/types";
import { SpisumNodeTypes } from "presentation/enums";
import React from "react";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import { LostDestroyedDialog } from "./LostDestroyedDialog";
import { ModalSize } from "presentation/designSystem/Modal/Modal";

export const lostDestroyedDialogContent: DialogContentType = {
  actions: () => [
    secondaryAction(
      t(translationPath(lang.dialog.form.confirm)),
      ({ dispatch, channels, dialogProps, onClose, buttonState }) => {
        const onSuccess = () => {
          onClose();
          dispatch(documentViewAction__Refresh(true));
          buttonState.setIsPending(false);
          dialogProps.onSuccess?.();
        };

        const onError = () => {
          buttonState.setIsPending(false);
          dialogProps.onError?.();
        };

        const { nodeType, id } = dialogProps.data as GenericDocument;

        const action =
          nodeType === SpisumNodeTypes.Document
            ? documentLostDestroyedActionType
            : nodeType === SpisumNodeTypes.File
            ? lostDestroyedFileAction
            : undefined;

        if (!action) {
          return;
        }

        buttonState.setIsPending(true);

        dispatch(
          callAsyncAction({
            action,
            onError,
            onSuccess,
            payload: {
              body: {
                reason: (channels.contentTab?.state
                  ?.formValues as ReasonFormValues).reason
              },
              nodeId: id
            }
          })
        );
      }
    )
  ],
  content: LostDestroyedDialog,
  title: (props) => (
    <NamedTitle text={t(translationPath(lang.general.damaged))} {...props} />
  ),
  type: DialogType.LostDestroyed,
  size: ModalSize.Small
};
