import {
  DInject,
  DInjectable
} from "../../presentation/core/features/dependencyInjection";
import { PaginationConfig } from "../../lib/contract/Pagination";
import { RentedEvidenceDocumentApiRepository } from "../../infrastructure/api/evidence/RentedEvidenceDocumentApiRepository";
import { RentedDocumentEvidenceRepositoryToken } from "../../domain/di/token/evidence";

@DInjectable()
export class FindRentedEvidenceDocuments {
  constructor(
    @DInject(RentedDocumentEvidenceRepositoryToken)
    protected repository: RentedEvidenceDocumentApiRepository
  ) {}

  findAllPaginated(config: PaginationConfig) {
    return this.repository.getRentedDocuments(config);
  }
}
