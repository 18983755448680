import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStateType } from "presentation/reducers";
import Dialog from "./DialogModal";
import { automaticLogoutDialogCloseAction } from "./_actions";

let initialRender = true;

const AutomaticLogout = () => {
  const dispatch = useDispatch();
  const logoutInterval = useSelector(
    (state: RootStateType) =>
      state.automaticLogoutDialogReducer.logoutInterval || 0
  );

  React.useEffect(() => {
    if (initialRender) {
      dispatch(automaticLogoutDialogCloseAction());
    }
    return () => {
      initialRender = false;
    };
  });

  // return (logoutInterval > 0 && <Dialog timeout={logoutInterval} />) || null;
  return null;
};

export default AutomaticLogout;
