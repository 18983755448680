import {
  fiveDigits,
  positiveNumbers,
  smallCapitalCharactersWithoutDiacritics
} from "../../../../../designSystem/Input/InputValidations";
import { useCallback, useState } from "react";

const DEFAULT_VALIDATION = "DEFAULT";
const INITIAL_VALIDATION_COUNTRY = "CZE";
const validationHouseNumberRules = {
  CZE: [{ required: true }, { max: 5 }],
  SVK: [{ required: true }, { max: 5 }],
  [DEFAULT_VALIDATION]: [{ required: true }, { max: 10 }]
};
const validationOrientationHouseNumberRules = {
  CZE: [{ max: 4 }, positiveNumbers],
  SVK: [{ max: 4 }, positiveNumbers],
  [DEFAULT_VALIDATION]: [{ max: 10 }]
};
const validationOrientationHouseNumberAddendumRules = {
  CZE: [{ max: 1 }, smallCapitalCharactersWithoutDiacritics],
  SVK: [{ max: 1 }, smallCapitalCharactersWithoutDiacritics],
  [DEFAULT_VALIDATION]: [{ max: 5 }]
};
const validationPostCodeRules = {
  CZE: [{ required: true }, { len: 5 }, fiveDigits],
  SVK: [{ required: true }, { len: 5 }, fiveDigits],
  [DEFAULT_VALIDATION]: [{ required: true }, { max: 15 }]
};

const selectedCountry = (value?: string) => {
  if (!value) {
    return INITIAL_VALIDATION_COUNTRY;
  }
  if (value === "CZE" || value === "SVK") {
    return value;
  }
  return DEFAULT_VALIDATION;
};

export const useAddressValidations = (country?: string) => {
  const initialCountry = selectedCountry(country);
  const [validationRules, setValidationRules] = useState(() => ({
    validationHouseNumberRules: validationHouseNumberRules[initialCountry],
    validationPostCodeRules: validationPostCodeRules[initialCountry],
    validationOrientationHouseNumberRules:
      validationOrientationHouseNumberRules[initialCountry],
    validationOrientationHouseNumberAddendumRules:
      validationOrientationHouseNumberAddendumRules[initialCountry]
  }));

  const onCountryChange = useCallback((value: string) => {
    const country = value?.toUpperCase();
    const countryKey = selectedCountry(country);
    setValidationRules({
      validationHouseNumberRules: validationHouseNumberRules[countryKey],
      validationPostCodeRules: validationPostCodeRules[countryKey],
      validationOrientationHouseNumberRules:
        validationOrientationHouseNumberRules[countryKey],
      validationOrientationHouseNumberAddendumRules:
        validationOrientationHouseNumberAddendumRules[countryKey]
    });
  }, []);

  return {
    validationRules,
    onCountryChange
  };
};
