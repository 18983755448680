import React from "react";
import {
  Form,
  Item,
  FormInstance
} from "presentation/designSystem/Form/v2/Form";
import { Col, Row } from "presentation/designSystem/Grid/Grid";
import { Input } from "presentation/designSystem/Input/Input";
import { DatePicker } from "presentation/designSystem/DatePicker/DatePicker";
import { lang, t } from "../../../../../../../../translation/i18n";
import {
  lastPathMember,
  translationPath
} from "../../../../../../../../share/utils/getPath";
import { DateTimeFormat } from "../../../../../../../../../lib/dateTime";
import moment from "moment";
import { SslDatabox } from "presentation/core/api/models";
import { sslDataboxPropsProxy } from "presentation/core/types";
import { TimePicker } from "antd";

interface DataboxDetailsFormProps {
  formData: SslDatabox;
  form: FormInstance;
}

export const DataboxDetailsForm = ({
  formData,
  form
}: DataboxDetailsFormProps) => {
  const deliveryDate = new Date(formData.digitalDeliveryDeliveryDate);
  const formInitialValues = {
    ...formData,
    digitalDeliveryDeliveryDate: moment(deliveryDate),
    databoxDeliveryTime: moment(
      deliveryDate,
      DateTimeFormat.HoursMinutesSeconds
    )
  };

  return (
    <>
      <Form initialValues={formInitialValues} form={form}>
        <Row gutter={6}>
          <Col span={6}>
            <Item
              name={
                lastPathMember(sslDataboxPropsProxy.digitalDeliveryDeliveryDate)
                  .path
              }
              label={t(translationPath(lang.documentMetaForm.deliveryDate))}
            >
              <DatePicker
                disabled
                format={DateTimeFormat.Date}
                style={{ width: "100%" }}
              />
            </Item>
          </Col>
          <Col span={6}>
            <Item
              name="databoxDeliveryTime"
              label={t(translationPath(lang.general.deliveryTime))}
            >
              <TimePicker disabled style={{ width: "100%" }} />
            </Item>
          </Col>
          <Col span={6}>
            <Item
              name={
                lastPathMember(
                  sslDataboxPropsProxy.digitalDeliveryAttachmentsCount
                ).path
              }
              label={t(translationPath(lang.general.attachmentsCount))}
            >
              <Input disabled type="number" />
            </Item>
          </Col>
          <Col span={6}>
            <Item
              name={lastPathMember(sslDataboxPropsProxy.pid).path}
              label={t(translationPath(lang.documentMetaForm.pid))}
            >
              <Input disabled />
            </Item>
          </Col>
        </Row>
        <Row gutter={6}>
          <Col span={24}>
            <Item
              name={lastPathMember(sslDataboxPropsProxy.databoxSender).path}
              label={t(translationPath(lang.general.sender))}
            >
              <Input disabled />
            </Item>
          </Col>
        </Row>
        <Row gutter={6}>
          <Col span={24}>
            <Item
              name={
                lastPathMember(sslDataboxPropsProxy.digitalDeliverySubject).path
              }
              label={t(translationPath(lang.general.subject))}
            >
              <Input disabled />
            </Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};
