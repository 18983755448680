import React from "react";
import { FilePlanGroups } from "presentation/core/features/login/_types";
import { DataNode } from "rc-tree-select/lib/interface";
import { TreeSelect } from "presentation/designSystem/TreeSelect/TreeSelect";

interface FileMarkFieldProps {
  fileMarks: FilePlanGroups[];
  onChange?: (value: string) => void;
  initialFileMark?: string;
  readOnly?: boolean;
}

export const FileMarkField = ({
  fileMarks,
  onChange,
  initialFileMark,
  readOnly
}: FileMarkFieldProps) => {
  const itemName = (mark: FilePlanGroups) => {
    let baseName = `${mark.fullyDeterminedFileMark} - ${mark.name}`;
    if (mark.retentionMark && mark.retentionPeriod) {
      baseName += ` (${mark.retentionMark}/${mark.retentionPeriod})`;
    }
    return baseName;
  };

  const mapTreeData = (items: FilePlanGroups[]): DataNode[] => {
    return items.map((item) => {
      return {
        title: itemName(item),
        value: item.identificator,
        children: mapTreeData(item.filePlanGroups ?? []),
        disabled: item.isParent
      };
    });
  };

  return (
    <TreeSelect
      treeData={mapTreeData(fileMarks)}
      disabled={readOnly || fileMarks.length === 0}
      defaultValue={initialFileMark || ""}
      onChange={onChange}
      showSearch
      filterTreeNode={(searchValue, treeNode) => {
        return treeNode
          ? treeNode
              .title!.toString()
              .toLowerCase()
              .indexOf(searchValue.toLowerCase()) >= 0
          : true;
      }}
    />
  );
};
