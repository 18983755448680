import { DialogContentPropsType } from "presentation/core/components/dialog/_types";
import { isGenericDocument } from "presentation/core/types";
import React from "react";
import { ChangeFileMarkForm } from "./ChangeFileMarkForm";

export const ChangeFileMarkDialog = ({
  channel,
  dialogProps
}: DialogContentPropsType) => {
  if (!isGenericDocument(dialogProps.data)) {
    return <div />;
  }

  const validSettleDate =
    dialogProps?.data?.properties?.ssl?.settleDate ?? new Date();
  const validClosureDate =
    dialogProps?.data?.properties?.ssl?.closureDate ?? new Date();

  return (
    <ChangeFileMarkForm
      originalFileMark={dialogProps.data.properties?.ssl?.fileMark}
      filePlanId={dialogProps.data.properties?.ssl?.filePlan}
      channel={channel}
      settleDate={validSettleDate}
      closureDate={validClosureDate}
    />
  );
};
