import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import error from "assets/images/error.svg";
import styled from "presentation/styles";

export const StyledErrorPic = styled("div")<{}>(() => ({
  background: `url(${error}) center center no-repeat`,
  backgroundSize: "contain",
  height: 400,
  width: 400
}));

export const StyledErrorBoundaryContainer = styled("div")<{}>(() => ({
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  height: "100%",
  justifyContent: "center",
  width: "100%"
}));

export const useStyles: Function = makeStyles((muiTheme: Theme) => {
  return createStyles({
    centeredError: {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center"
    }
  });
});
