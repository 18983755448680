import { PaginatedResult } from "lib/contract/Pagination";
import { callAsyncAction } from "presentation/core/action";
import {
  componentCreateAction,
  componentDownloadAction
} from "presentation/core/api/components/_actions";
import { ComponentType } from "presentation/core/api/components/_types";
import { SslProperties } from "presentation/core/api/models";
import {
  DataColumn,
  ValueType
} from "presentation/core/components/dataTable/_types";
import { ActiveComponent } from "presentation/core/components/dialog/tabs/components/_types";
import { dialogOpenAction } from "presentation/core/components/dialog/_actions";
import {
  DialogDataGenericData,
  DialogDataProps,
  DialogType
} from "presentation/core/components/dialog/_types";
import { DocumentStateType } from "presentation/core/components/MetaForm/_types";
import { File, fileProxy } from "presentation/core/entities";
import { Component, GenericDocument } from "presentation/core/types";
import { Notification } from "presentation/designSystem/notification/Notification";
import {
  ComponentKeepForm,
  DocumentType,
  SenderType,
  SpisumNodeTypes
} from "presentation/enums";
import { RootStateType } from "presentation/reducers";
import { renameComponentAction } from "presentation/share/components/dialog/renameComponentDialog/_actions";
import { getErrorCodeTranslation } from "presentation/share/utils/errorCodeTranslation";
import { classPath, translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import { ErrorType } from "presentation/types";
import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "../../../../share/hook/query/useMutation";
import EvidenceComponentsV2Tab, {
  ChangePageFnType,
  ChangeRowsPerPageFnType,
  SortChangeFnType
} from "./EvidenceComponentsV2Tab";
import { useUpdateActiveComponents } from "./hooks/useUpdateActiveComponents";
import { SelectedComponentsFnType } from "./_types";

export interface EvidenceComponentsTabContainerState {
  pageNumber: number;
  rowsPerPage: number;
  sortAsc?: boolean;
  sortColumnIndex?: number;
  sortKeys?: string[];
}

export type EvidenceComponentsV2TabContainerProps = {
  nodeId: string;
  data?: PaginatedResult<File>;
  isFetching?: boolean;
  isReadonly?: boolean;
  componentType?: ComponentType;
  dialogProps?: DialogDataProps;
  isActive?: boolean;
  documentId?: string;
  fetchComponents(): void;
  handleChangePage: ChangePageFnType;
  handleChangeRowsPerPage: ChangeRowsPerPageFnType;
  handleSortingChange: SortChangeFnType;
  handleDeleteComponent?: SelectedComponentsFnType;
  handleOpenPopUpPassword: (file: File) => void;
  metaFormDocument: DocumentStateType;
  pageNumber: number;
  rowsPerPage: number;
  sortAsc?: boolean;
  sortColumnIndex?: number;
  sortKeys?: string[];
  showPreview?: boolean;
  previewItem?: any;
  setPreviewItem?(previewItem: any): void;
  setShowPreview?(showPreview: boolean): void;
  onUploadFinished?(components: Component[]): void;
};

const ComponentsV2TabContainer = React.memo(
  ({
    componentType = ComponentType.Document,
    dialogProps,
    isReadonly,
    nodeId,
    data,
    isFetching,
    isActive,
    documentId,
    fetchComponents,
    handleChangePage,
    handleChangeRowsPerPage,
    handleSortingChange,
    handleDeleteComponent,
    handleOpenPopUpPassword,
    metaFormDocument,
    pageNumber,
    rowsPerPage,
    sortAsc,
    sortColumnIndex,
    sortKeys,
    showPreview,
    previewItem,
    setPreviewItem,
    setShowPreview,
    onUploadFinished
  }: EvidenceComponentsV2TabContainerProps) => {
    const dispatch = useDispatch();

    const { error } = useSelector(
      (state: RootStateType) => state.componentsReducer
    );
    const nodeType =
      (dialogProps?.data as DialogDataGenericData)?.nodeType ||
      metaFormDocument?.nodeType;

    const sortedComponents = useMemo(() => {
      return data ? data.items : [];
    }, [data, sortKeys]);

    const getNotificationForError = (error: any) => ({
      message: getErrorCodeTranslation(error.code)
    });
    const updateActiveComponents = useUpdateActiveComponents();
    const { mutate: updatingActiveComponet } = useMutation(
      (body: ActiveComponent) => updateActiveComponents(nodeId, body),
      {
        onSuccess() {
          fetchComponents();
        },
        onSuccessNotification: null,
        onErrorNotification: getNotificationForError
      }
    );

    useEffect(() => {
      // channel.refreshData = fetchComponents;
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      fetchComponents();
    }, [fetchComponents]);

    useEffect(() => {
      if (
        !sortedComponents.length ||
        !handleCanShowPreview(sortedComponents[0])
        // channel.previewItem
      ) {
        return;
      }

      // channel.setPreviewItem({
      //   ...sortedComponents[0],
      //   entityId: nodeId,
      //   nodeType
      // });
    }, [nodeId, sortedComponents]);

    const onError = useCallback((file: globalThis.File, error: ErrorType) => {
      Notification.error({
        message: error.code
          ? getErrorCodeTranslation(error.code)
          : Array.isArray(error.messages) && error.messages.length > 0
          ? error.messages[0]
          : t(translationPath(lang.dialog.notifications.actionFailed))
      });
    }, []);

    const handleUploadComponent = useCallback(
      (files: globalThis.File[]) => {
        const onSuccess = () => {
          Notification.success({
            message: t(
              translationPath(
                lang.dialog.notifications.componentUploadWasSuccessful
              )
            )
          });
        };

        const onEnd = (components: Component[]) => {
          onUploadFinished?.(components);
          fetchComponents();
        };

        dispatch(
          componentCreateAction.request({
            files,
            nodeId,
            onEnd,
            onError,
            onSuccess,
            type: componentType
          })
        );
      },
      [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const handleSwapComponentContent = useCallback(
      (selected: File[], files: globalThis.File[]) => {
        const onSuccess = () => {
          Notification.success({
            message: t(
              translationPath(
                lang.dialog.notifications.componentUploadWasSuccessful
              )
            )
          });
        };

        dispatch(
          componentCreateAction.request({
            componentId: selected[0].id,
            files,
            nodeId,
            onEnd: fetchComponents,
            onError,
            onSuccess,
            type: componentType
          })
        );
      },
      [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const handleDownloadComponent = useCallback(
      (selectedComponents: File[]) => {
        Notification.success({
          message: t(
            translationPath(
              lang.dialog.notifications.componentDownloadIsPreparing
            )
          )
        });

        dispatch(
          callAsyncAction({
            action: componentDownloadAction,
            payload: {
              componentIds: selectedComponents.map((c) => c.id),
              nodeId,
              nodeType
            }
          })
        );
      },
      [dispatch, nodeId, nodeType]
    );

    const handleColumnChange = useCallback(
      (row: File, column: DataColumn<File>, value: ValueType) => {
        let updateComponentObject = {
          [column.keys[0]]: value,
          componentId: row.id
        };
        if (column.keys[0] === classPath(fileProxy.type).path) {
          updateComponentObject = {
            ...updateComponentObject,
            isReadable: row.isReadable
          };
        } else {
          updateComponentObject = { ...updateComponentObject, type: row.type };
        }
        updatingActiveComponent(updateComponentObject);
        // dispatch(
        //   callAsyncAction({
        //     action: componentUpdateAction,
        //     payload: {
        //       ...row,
        //       [column.keys[0]]: value,
        //       documentId: metaFormDocument.documentId || documentId || nodeId,
        //       nodeType: metaFormDocument.nodeType || nodeType
        //     }
        //   })
        // );
      },
      [
        dispatch,
        documentId,
        metaFormDocument.documentId,
        metaFormDocument.nodeType,
        nodeId,
        nodeType
      ]
    );

    const updateComponents = useUpdateActiveComponents();
    const { mutate: updatingActiveComponent } = useMutation(
      (body: ActiveComponent) => updateComponents(nodeId, body),
      {
        onSuccess: fetchComponents,
        onSuccessNotification: null,
        onErrorNotification: getNotificationForError
      }
    );

    const handleRenameComponent: SelectedComponentsFnType = useCallback(
      (selectedComponents) => {
        dispatch(renameComponentAction(selectedComponents[0]));
      },
      [dispatch]
    );

    const showActions = () => {
      if (isReadonly || dialogProps?.canUploadComponents === false) {
        return false;
      }

      const document = dialogProps?.data as GenericDocument;
      const nodeType = document.nodeType;

      const { isLocked, formValues } = metaFormDocument;
      const typedFormValues = formValues as SslProperties;
      const form = document?.properties?.ssl?.form || typedFormValues?.form;
      const senderType =
        document?.properties?.ssl?.senderType || typedFormValues?.senderType;
      const documentType =
        document?.properties?.ssl?.documentType ||
        typedFormValues?.documentType;
      const documentIsLocked = document?.isLocked || isLocked;

      return (
        (form === DocumentType.Analog ||
          nodeType === SpisumNodeTypes.Concept ||
          (form === DocumentType.Digital &&
            (senderType === SenderType.Own ||
              documentType === DocumentType.TechnicalDataCarries))) &&
        !documentIsLocked
      );
    };

    const handleCanDeleteComponent = showActions;

    const handleCanCreateComponent = showActions;

    const handleCanUploadNewVersion = (component: File) => {
      if (!showActions() || component.isLocked) {
        return false;
      }

      if (component.senderType === SenderType.Own) {
        return true;
      }

      return component?.keepForm !== ComponentKeepForm.OriginalInOutputFormat;
    };

    const handleCanShowPreview = (component: File) => {
      // filter by extensions
      return true;
    };

    const handleConvertToOutputFormat = (cmp: File[]) => {
      dispatch(
        dialogOpenAction({
          dialogProps: {
            data: {
              componentId: cmp[0].id,
              id: nodeId
            },
            onSuccess: fetchComponents
          },
          dialogType: DialogType.ConvertToOutput
        })
      );
    };

    const handleCanRenameComponent = showActions;

    const handleCanConvertToOutputFormat = (component: File) => {
      const document = dialogProps?.data as GenericDocument;
      return (
        dialogProps?.disableConverIcon !== true &&
        !document?.isLocked &&
        component?.isInOutputFormat !== "yes" &&
        // in case of isInOutputFormat "impossible" - can be shown, but needs a validation
        component?.isInOutputFormat !== "converted" &&
        !isReadonly
      );
    };

    const handleShowPreview = useCallback(
      (selected: File[]) => {
        if (selected[0]) {
          // preview first time
          if (!previewItem?.id) {
            setPreviewItem?.({
              id: selected[0].id,
              name: selected[0].name,
              nodeType: nodeType,
              entityId: nodeId,
              fileIsEncrypted: selected[0].isEncrypted
            });

            // set active preview mode
            setShowPreview?.(true);
          }

          // preview is active and different component
          else if (previewItem.id !== selected[0].id) {
            setPreviewItem?.({
              id: selected[0].id,
              name: selected[0].name,
              nodeType: nodeType,
              entityId: nodeId,
              fileIsEncrypted: selected[0].isEncrypted
            });
          }

          // the selected same node clicked preview button twice
          else {
            setShowPreview?.(false);
            setPreviewItem?.({
              id: "",
              name: selected[0].name,
              nodeType: nodeType,
              entityId: nodeId,
              fileIsEncrypted: selected[0].isEncrypted
            });
          }
        }
      },
      [nodeId, nodeType, setPreviewItem, setShowPreview, previewItem.id]
    );

    const handleSelectionChange = useCallback(
      (selected: File[]) => {
        if (!selected[0] || !handleCanShowPreview(selected[0])) {
          return;
        }

        if (showPreview && previewItem.id !== selected[0].id) {
          setPreviewItem?.({
            id: selected[0].id,
            name: selected[0].name,
            nodeType: nodeType,
            entityId: nodeId,
            fileIsEncrypted: selected[0].isEncrypted
          });
        }
      },
      [nodeId, nodeType, previewItem.id, setPreviewItem, showPreview]
    );

    if (error) {
      return <div />;
    }

    return (
      <EvidenceComponentsV2Tab
        handleCanConvertToOutputFormat={handleCanConvertToOutputFormat}
        handleCanCreateComponent={handleCanCreateComponent}
        handleCanDeleteComponent={handleCanDeleteComponent}
        handleCanRenameComponent={handleCanRenameComponent}
        handleCanShowPreview={handleCanShowPreview}
        handleCanUploadNewVersion={handleCanUploadNewVersion}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleColumnChange={handleColumnChange}
        handleConvertToOutputFormat={handleConvertToOutputFormat}
        handleDeleteComponent={handleDeleteComponent}
        handleDownloadComponent={handleDownloadComponent}
        handleRenameComponent={handleRenameComponent}
        handleSelectionChange={handleSelectionChange}
        handleShowPreview={handleShowPreview}
        handleSortingChange={handleSortingChange}
        handleSwapComponentContent={handleSwapComponentContent}
        handleUploadComponent={handleUploadComponent}
        handleOpenPopUpPassword={handleOpenPopUpPassword}
        isLoading={isFetching}
        isReadonly={isReadonly || !!dialogProps?.isReadonly}
        items={sortedComponents}
        pageNumber={data ? data.page - 1 : 0}
        refreshTable={fetchComponents}
        rowsPerPage={data ? data.itemsPerPage : 10}
        sortAsc={sortAsc}
        sortColumnIndex={sortColumnIndex}
        totalItems={data?.total || 0}
      />
    );
  }
);

export default ComponentsV2TabContainer;
