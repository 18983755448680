import MenuLayout from "presentation/core/components/layout/MenuLayout";
import React from "react";
import { withTranslation } from "presentation/translation/i18n";
import { ReturnForReworkTable } from "./tables/ReturnForReworkTable";

const ReturnedForReworkOverViews = () => {
  return (
    <MenuLayout>
      <ReturnForReworkTable />
    </MenuLayout>
  );
};

export default withTranslation()(ReturnedForReworkOverViews);
