import { recoverDialogContent } from "./recoverDialog/recoverDialogContent";
import { analogDetailsDialogContent } from "./analogDetailsDialog/analogDetailsDialogContent";
import { analogReadonlyDetailsDialogContent } from "./analogDetailsReadonly/analogReadonlyDetailsDialogContent";
import { documentDetailsWithSaveButtonsDialogContent } from "./analogDetailsWithSaveButtonDialog/documentDetailsWithSaveButtonsDialogContent";
import { borrowDialogContent } from "./borrowDialog/borrowDialogContent";
import { cancelDialogContent } from "./cancelDialog/cancelDialogContent";
import { cancelDiscardDialogContent } from "./cancelDiscardDialog/cancelDiscardDialogContent";
import { cancelProcessingDialogContent } from "./cancelProcessing/cancelProcessingDialogContent";
import { cancelShipmentDialogContent } from "./cancelShipmentDialog/cancelShipmentDialogContent";
import { changeFileMarkDialogContent } from "./changeFileMarkDialog/changeFileMarkDialogContent";
import { changeLocationDialogContent } from "./changeLocationDialog/changeLocationDialogContent";
import { changePasswordDialogContent } from "./changePasswordDialog/changePasswordDialogContent";
import { changeToADialogContent } from "./changeToADialog/changeToADialogContent";
import { changeToSDialogContent } from "./changeToSDialog/changeToSDialogContent";
import { changeValidityDateContent } from "./changeValidToDateDialog/changeValidityDateContent";
import { closeFileDialogContent } from "./closeFileDialog/closeFileDialogContent";
import { conceptDetailsDialogContent } from "./conceptDetailsDialog/conceptDetailsDialogContent";
import { convertToOutputFormatDialogContent } from "./convertToOutputDialog/convertToOutputFormatDialogContent";
import { evidenceCreateConceptDialogContent } from "./createConceptDialog/evidenceCreateConceptDialogContent";
import { createNewDocumentFileDialogContent } from "./createNewDocumentFileDialog/createNewDocumentFileDialogContent";
import { createOrganizationUnitDialogContent } from "./createOrganizationUnitDialog/createOrganizationUnitDialogContent";
import { createRetentionProposalDialogContent } from "./createRetentionProposal/createRetentionProposalDialogContent";
import { createShipmentDialogContent } from "./createShipmentDialog/createShipmentDialogContent";
import { createUserDialogContent } from "./createUserDialog/createUserDialogContent";
import { databoxDetailsDialogContent } from "./databoxDetailsDialog/databoxDetailsDialogContent";
import { dataModifiedDialogContent } from "./dataModifiedDialog/dataModifiedDialogContent";
import { deactivateUserDialogContent } from "./deactivateUserDialog/deactivateUserDialogContent";
import { declineHandoverDialogContent } from "./declineHandoverDialog/declineHandoverDialogContent";
import { deleteOrganizationUnitDialogContent } from "./deleteOrganizationUnitDialog/deleteOrganizationUnitDialogContent";
import { dispatchPostShipmentDialogContent } from "./dispatchPostShipmentDialog/dispatchPostShipmentDialogContent";
import { dispatchPublishShipmentDialogContent } from "./dispatchPublishShipmentDialog/dispatchPublishShipmentDialogContent";
import { dontRegisterDocumentDialogContent } from "./dontRegisterDocument/dontRegisterDocumentDialogContent";
import { editUserDialogContent } from "./editUserDialog/editUserDialogContent";
import { emailDetailsDialogContent } from "./emailDetailsDialog/emailDetailsDialogContent";
import { evidenceCancelDialogContent } from "./evidenceCancelDialog/evidenceCancelDialogContent";
import { fileDetailsDialogContent } from "./fileDetailsDialog/fileDetailsDialogContent";
import { fileDetailsReadonlyDialogContent } from "./fileDetailsReadonlyDialog/fileDetailsReadonlyDialogContent";
import { openFileDialogContent } from "./fileOpenDialog/openFileDialogContent";
import { forSignatureDialogContent } from "./forSignatureDialog/forSignatureDialogContent";
import { foundDialogContent } from "./foundDialog/foundDialogContent";
import { fromSignatureDialogContent } from "./fromSignatureDialog/fromSignatureDialogContent";
import { groupChangeDialogContent } from "./groupChangeDialog/groupChangeDialogContent";
import { handoverBackDialogContent } from "./handoverBackDialog/handoverBackDialogContent";
import { incompleteDocumentDialogContent } from "./incompleteDocument/incompleteDocumentDialogContent";
import { lostDestroyedDialogContent } from "./lostDestroyedDialog/lostDestroyedDialogContent";
import { promoteConceptToDocumentDialogContent } from "./promoteConceptToDocumentDialog/promoteConceptToDocumentDialogContent";
import { registerDataboxDialogContent } from "./registerDataboxDialog/registerDataboxDialogContent";
import { registerEmailDialogContent } from "./registerEmailDialog/registerEmailDialogContent";
import { repositoryAndShreddingPlanDialogContent } from "./repositoryAndShreddingPlanDialog/repositoryAndShreddingPlanDialogContent";
import { resendShipmentDialogContent } from "./resendShipmentDialog/resendShipmentDialogContent";
import { returnForReworkDialogContent } from "./returnForRework/returnForReworkDialogContent";
import { returnShipmentDialogContent } from "./returnShipment/returnShipmentDialogContent";
import { returnToRepositoryDialogContent } from "./returnToRepositoryDialog/returnToRepositoryDialogContent";
import { searchSubjectDialogContent } from "./searchSubjectDialog/searchSubjectDialogContent";
import { sendShipmentDialogContent } from "./sendShipmentDialog/sendShipmentDialogContent";
import { settleDocumentDialogContent } from "./settleDocumentDialog/settleDocumentDialogContent";
import { openShipmentDetailDialogContent } from "./shipmentDetailDialog/openShipmentDetailDialogContent";
import { shreddingDiscardDialogContent } from "./shreddingDiscardDialog/shreddingDiscardDialogContent";
import { signAndTimestampDialogContent } from "./signAndTimestamp/signAndTimestampDialogContent";
import { submitToRepositoryDialogContent } from "./submitToRepository/submitToRepositoryDialogContent";
import { takeOutFromFileDialogContent } from "./takeOutFromFile/takeOutFromFileDialogContent";
import { technicalDataCarriesDetailsDialogContent } from "./technicalDataCarriesDetailsDialog/technicalDataCarriesDetailsDialogContent";
import { technicalDataCarriesDetailsWithSaveButtonsDialogContent } from "./technicalDataCarriesDetailsDialogWithSaveButtons/technicalDataCarriesDetailsWithSaveButtonsDialogContent";
import { technicalDataCarriesReadonlyDetailsDialogContent } from "./technicalDataCarriesDetailsReadonly/technicalDataCarriesReadonlyDetailsDialogContent";
import { UpdateOrganizationUnitDialogContent } from "./updateOrganizationUnitDialog/UpdateOrganizationUnitDialogContent";
import { emailNotRegisterDetailsDialogContent } from "./emailNotRegisterDetailsDialog/emailNotRegisterDetailsDialogContent";
import { databoxNotRegisterDetailsDialogContent } from "./databoxNotRegisterDetailsDialog/databoxNotRegisterDetailsDialogContent";

export const dialogs = [
  analogDetailsDialogContent,
  analogReadonlyDetailsDialogContent,
  borrowDialogContent,
  cancelDialogContent,
  cancelDiscardDialogContent,
  cancelProcessingDialogContent,
  cancelShipmentDialogContent,
  changeFileMarkDialogContent,
  changeLocationDialogContent,
  changePasswordDialogContent,
  changeToADialogContent,
  changeToSDialogContent,
  changeValidityDateContent,
  closeFileDialogContent,
  conceptDetailsDialogContent,
  convertToOutputFormatDialogContent,
  createNewDocumentFileDialogContent,
  createOrganizationUnitDialogContent,
  createRetentionProposalDialogContent,
  createShipmentDialogContent,
  createUserDialogContent,
  databoxDetailsDialogContent,
  databoxNotRegisterDetailsDialogContent,
  dataModifiedDialogContent,
  deactivateUserDialogContent,
  declineHandoverDialogContent,
  deleteOrganizationUnitDialogContent,
  dispatchPostShipmentDialogContent,
  dispatchPublishShipmentDialogContent,
  documentDetailsWithSaveButtonsDialogContent,
  dontRegisterDocumentDialogContent,
  emailDetailsDialogContent,
  emailNotRegisterDetailsDialogContent,
  evidenceCancelDialogContent,
  evidenceCreateConceptDialogContent,
  fileDetailsDialogContent,
  fileDetailsReadonlyDialogContent,
  forSignatureDialogContent,
  foundDialogContent,
  fromSignatureDialogContent,
  groupChangeDialogContent,
  handoverBackDialogContent,
  incompleteDocumentDialogContent,
  lostDestroyedDialogContent,
  openFileDialogContent,
  openShipmentDetailDialogContent,
  promoteConceptToDocumentDialogContent,
  recoverDialogContent,
  registerDataboxDialogContent,
  registerEmailDialogContent,
  repositoryAndShreddingPlanDialogContent,
  resendShipmentDialogContent,
  returnForReworkDialogContent,
  returnShipmentDialogContent,
  returnToRepositoryDialogContent,
  searchSubjectDialogContent,
  sendShipmentDialogContent,
  settleDocumentDialogContent,
  shreddingDiscardDialogContent,
  signAndTimestampDialogContent,
  submitToRepositoryDialogContent,
  takeOutFromFileDialogContent,
  technicalDataCarriesDetailsDialogContent,
  technicalDataCarriesDetailsWithSaveButtonsDialogContent,
  technicalDataCarriesReadonlyDetailsDialogContent,
  editUserDialogContent,
  UpdateOrganizationUnitDialogContent
];
