import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import clsx from "clsx";
import { Form, Formik, FormikHelpers } from "formik";
import { Select, TextField } from "formik-mui";
import { SslAnalog } from "presentation/core/api/models";
import { MetaFormProps } from "presentation/core/components/MetaForm/_types";
import Datepicker from "presentation/core/components/datepicker";
import {
  StyledField,
  StyledFieldWide,
  StyledFormControl,
  useStyles
} from "presentation/core/components/dialog/Dialog.styles";
import { useScrollToElement } from "presentation/core/components/dialog/hooks/useScrollToElement";
import { FormState } from "presentation/core/components/reactiveFormik/_types";
import { SenderForm } from "presentation/core/components/senderForm/SenderForm";
import TimePicker from "presentation/core/components/timepicker";
import { sslAnalogPropsProxy } from "presentation/core/types";
import { DateTimeFormats, DeliveryMode } from "presentation/enums";
import {
  lastPathMember,
  translationPath
} from "presentation/share/utils/getPath";
import { WithTranslation, lang, t } from "presentation/translation/i18n";
import React from "react";
import { withTranslation } from "react-i18next";
import { BaseField } from "../../../../core/components/form/fields/BaseField";
import { SslDeliveryMode } from "../../form/fields/SSLDeliveryMode";
import { validate } from "./_validations";

const Component = ({
  initialValues,
  formRef,
  readonly
}: MetaFormProps<SslAnalog> & WithTranslation) => {
  const classes = useStyles();
  const scrollToElement = useScrollToElement();
  const emptyField = (
    keys: string[],
    isSubmitting: boolean,
    isValidating: boolean
  ) => {
    if (keys.length > 0 && isSubmitting && !isValidating) {
      const selector = `[name="${keys[0]}"]`;
      scrollToElement(selector);
    }
  };
  const onSubmit = (
    values: SslAnalog,
    formikHelpers: FormikHelpers<SslAnalog>
  ) => {};

  return (
    <Formik<SslAnalog>
      initialValues={initialValues}
      innerRef={formRef}
      onSubmit={onSubmit}
      validate={validate}
    >
      {({
        setFieldValue,
        values,
        isSubmitting,
        isValidating,
        errors
      }: FormState<SslAnalog>) => {
        const keys = Object.keys(errors!);
        emptyField(keys, isSubmitting, isValidating);

        return (
          <Form className={classes.form}>
            <Datepicker
              data-test-id="carries-meta-input-deliveryDate"
              disabled={readonly}
              name={lastPathMember(sslAnalogPropsProxy.deliveryDate).path}
              label={t(translationPath(lang.general.deliveryDate))}
              disableFuture={true}
              required={true}
            />
            <TimePicker
              format={DateTimeFormats.HoursMinutesSeconds}
              data-test-id="carries-meta-input-deliveryTime"
              disabled={readonly}
              name={lastPathMember(sslAnalogPropsProxy.deliveryTime).path}
              label={t(translationPath(lang.general.deliveryTime))}
            />

            <SslDeliveryMode
              disabledModes={[DeliveryMode.Email, DeliveryMode.Databox]}
              disabled={readonly}
              firstEmpty={false}
              required={true}
            />

            <StyledField
              component={TextField}
              data-test-id="carries-meta-input-pid"
              disabled={true}
              name={lastPathMember(sslAnalogPropsProxy.pid).path}
              type="text"
              label={t(translationPath(lang.general.identifier))}
              required={true}
            />

            <StyledFormControl>
              <InputLabel htmlFor="form">
                {t(translationPath(lang.general.form))}
              </InputLabel>
              <BaseField
                component={Select}
                data-test-id="carries-meta-input-form"
                disabled={true}
                name={lastPathMember(sslAnalogPropsProxy.form).path}
                inputProps={{
                  id: "form"
                }}
              >
                <MenuItem value={"digital"}>
                  {t(translationPath(lang.general.digital))}
                </MenuItem>
              </BaseField>
            </StyledFormControl>
            <StyledField
              component={TextField}
              data-test-id="carries-meta-input-attachmentsCount"
              disabled={readonly}
              min={0}
              name={lastPathMember(sslAnalogPropsProxy.attachmentsCount).path}
              type="number"
              label={t(translationPath(lang.general.attachmentsCount))}
            />
            <StyledField
              component={TextField}
              data-test-id="carries-meta-input-attachmentsType"
              disabled={readonly}
              name={lastPathMember(sslAnalogPropsProxy.attachmentsType).path}
              type="text"
              label={t(translationPath(lang.general.attachmentsType))}
            />
            <Datepicker
              data-test-id="carries-meta-input-settleToDate"
              disabled={readonly}
              name={lastPathMember(sslAnalogPropsProxy.settleToDate).path}
              label={t(translationPath(lang.general.settleToDate))}
              disablePast={true}
            />

            <div className={clsx(classes.fullWidth, classes.mtGap)}>
              <StyledField
                component={TextField}
                className={classes.gapRight}
                data-test-id="carries-meta-input-senderIdent"
                disabled={readonly}
                name={lastPathMember(sslAnalogPropsProxy.senderIdent).path}
                type="text"
                label={t(translationPath(lang.general.senderIdent))}
              />
              <StyledField
                component={TextField}
                data-test-id="carries-meta-input-senderSSID"
                disabled={readonly}
                name={lastPathMember(sslAnalogPropsProxy.senderSSID).path}
                type="text"
                label={t(translationPath(lang.general.senderSSID))}
              />
            </div>

            <StyledFieldWide
              component={TextField}
              data-test-id="carries-meta-input-subject"
              disabled={readonly}
              name={lastPathMember(sslAnalogPropsProxy.subject).path}
              type="text"
              label={t(translationPath(lang.general.subject))}
            />
            <SenderForm
              initialValues={initialValues}
              readonly={readonly}
              hidden={{ own: !readonly }}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export const DigitalEvidateMetadataForm = withTranslation()(
  React.memo(Component)
);
