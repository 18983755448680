import {
  DInject,
  DInjectable
} from "presentation/core/features/dependencyInjection";
import { ApiUrls } from "../struct/ApiTypesV2";
import { HttpClient } from "infrastructure/service/HttpClient";
import { StoredFilesRepository } from "domain/service/repository/StoredFilesRepository";
import { StoredFile } from "../../../domain/struct/repository/StoredFile";
import {
  ApiRepositoryStoredFilesViewResponse,
  StoredFilesApiMapper
} from "./StoredFilesApiMapper";
import { SortingConfig } from "lib/contract/Sorting";
import { PaginatedResult, PaginationConfig } from "lib/contract/Pagination";

const URL: ApiUrls = {
  GetRepositoryStoredFiles: "/api/app/v2/repository/stored-files"
};
@DInjectable()
export class StoredFilesApiRepository implements StoredFilesRepository {
  constructor(@DInject(HttpClient) protected httpClient: HttpClient) {}

  getStoredFiles(
    pagination: PaginationConfig,
    sorting?: SortingConfig
  ): Promise<PaginatedResult<StoredFile>> {
    return this.httpClient
      .fetchPaginated<ApiRepositoryStoredFilesViewResponse>({
        url: URL.GetRepositoryStoredFiles,
        pagination,
        sorting
      })
      .then(StoredFilesApiMapper.mapListToDomain);
  }
}
