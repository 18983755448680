import Typography from "@mui/material/Typography";
import { translationPath } from "presentation/share/utils/getPath";
import { t } from "presentation/translation/i18n";
import lang from "presentation/translation/lang";
import { useLocation } from "react-router-dom";
import {
  BreadCrumbsSectionStyled,
  PathStyled,
  TitleStyled
} from "../../DataTable.styles";
import React from "react";

interface BreadcrumbProps {
  title: string;
}

const tranformToTranslatable = (item: string) => {
  const [first, ...rest] = item.split("-");
  if (rest && rest.length) {
    return (
      first +
      rest.reduce((acc, curr) => {
        return acc + curr.charAt(0).toUpperCase() + curr.slice(1);
      }, "")
    );
  }
  return first;
};
export const BreadcrumbForTable = ({ title }: BreadcrumbProps) => {
  const { pathname } = useLocation();
  const breadcrumbItems = pathname.split("/").filter(Boolean);
  return (
    <BreadCrumbsSectionStyled xs={7} item={true}>
      <TitleStyled className="title" variant="h5" gutterBottom={true}>
        {t(
          translationPath(
            lang.breadcrumb[title as keyof typeof lang.breadcrumb]
          )
        )}
      </TitleStyled>
      <PathStyled separator=">">
        {breadcrumbItems.map((item: string) => (
          <Typography color="textPrimary">
            {t(
              translationPath(
                lang.breadcrumb[
                  tranformToTranslatable(item) as keyof typeof lang.breadcrumb
                ]
              )
            )}
          </Typography>
        ))}
      </PathStyled>
    </BreadCrumbsSectionStyled>
  );
};
