import {
  BlockOutlined,
  Input,
  RestorePageOutlined,
  Save,
  SwapVert,
  Visibility
} from "@mui/icons-material";
import { File } from "presentation/core/entities";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import { ControlsBarType } from "../../../dataTable/_types";
import { ComponentUpload } from "./ComponentUpload";
import { ControlsType, ReadonlyControlsType } from "./_types";
import React from "react";

export const controls: ControlsType = ({
  handleCanConvertToOutputFormat,
  handleCanCreateComponent,
  handleCanDeleteComponent,
  handleCanShowPreview,
  handleCanUploadNewVersion,
  handleConvertToOutputFormat,
  handleDeleteComponent,
  handleDownloadComponent,
  handleShowPreview,
  handleSwapComponentContent,
  handleUploadComponent,
  handleShowDeactivateComponent,
  handleDeactivateComponent,
  handleCanDeactivateComponent,
  handleCanShowDeactivateComponent,
  handleValidateCanShowDeactivateComponent
}): ControlsBarType<File> => {
  return {
    default: {
      items: [
        {
          filter: () => handleCanCreateComponent(),
          icon: () => (
            <ComponentUpload
              handleUploadComponent={handleUploadComponent}
              title={t(translationPath(lang.general.add))}
            />
          )
        },
        {
          action: handleShowDeactivateComponent,
          filter: () => handleCanShowDeactivateComponent(),
          icon: <RestorePageOutlined data-test-id="tab-show-de-active" />,
          title: t(translationPath(lang.general.showDeactivate)),
          validation: handleValidateCanShowDeactivateComponent
        }
      ]
    },
    multi: {
      items: [
        {
          action: handleDeactivateComponent,
          filter: (file: File) => handleCanDeactivateComponent(file),
          icon: <BlockOutlined data-test-id="tab-deactivate" />,
          title: t(translationPath(lang.general.deactivate))
        },
        {
          action: handleDownloadComponent,
          icon: <Save data-test-id="tab-download" />,
          title: t(translationPath(lang.general.download))
        }
      ]
    },
    single: {
      items: [
        {
          action: handleDeactivateComponent,
          filter: (file: File) => handleCanDeactivateComponent(file),
          icon: <BlockOutlined data-test-id="tab-deactivate" />,
          title: t(translationPath(lang.general.deactivate))
        },
        {
          action: handleDownloadComponent,
          icon: <Save data-test-id="tab-download" />,
          title: t(translationPath(lang.general.download))
        },
        {
          action: handleShowPreview,
          filter: handleCanShowPreview,
          icon: <Visibility data-test-id="tab-show-preview" />,
          title: t(translationPath(lang.general.showPreview))
        },
        {
          filter: handleCanUploadNewVersion,
          icon: (selectedComponents) => {
            const handleSelected = (selected: globalThis.File[]) =>
              handleSwapComponentContent(selectedComponents, selected);

            return (
              <ComponentUpload
                handleUploadComponent={handleSelected}
                multipleFilesAllowed={false}
                icon={<SwapVert data-test-id="tab-upload-new-version" />}
                title={t(translationPath(lang.general.uploadNewVersion))}
              />
            );
          }
        },
        /* V2
        {
          action: handleRenameComponent,
          filter: (file) => handleCanRenameComponent(),
          icon: <Edit />,
          title: t(translationPath(lang.general.rename))
        },
        */
        {
          action: handleConvertToOutputFormat,
          filter: handleCanConvertToOutputFormat,
          icon: <Input data-test-id="tab-convert" />,
          title: t(translationPath(lang.general.convertToOutputFormat)),
          validation: (source) => {
            return source[0]?.isEncrypted
              ? t(translationPath(lang._validations.componentEncrypted))
              : null;
          }
        }
      ]
    }
  };
};

export const readOnlyControls: ReadonlyControlsType = ({
  handleCanShowPreview,
  handleShowPreview,
  handleDownloadComponent,
  handleShowDeactivateComponent,
  handleCanShowDeactivateComponent,
  handleValidateCanShowDeactivateComponent
}) => ({
  default: {
    items: [
      {
        action: handleShowDeactivateComponent,
        filter: () =>
          typeof handleCanShowDeactivateComponent == "function"
            ? handleCanShowDeactivateComponent()
            : false,
        icon: <RestorePageOutlined data-test-id="tab-show-de-active" />,
        title: t(translationPath(lang.general.showDeactivate)),
        validation: handleValidateCanShowDeactivateComponent
      }
    ]
  },
  multi: {
    items: [
      {
        action: handleDownloadComponent,
        icon: <Save data-test-id="tab-download" />,
        title: t(translationPath(lang.general.download))
      }
    ]
  },
  single: {
    items: [
      {
        action: handleDownloadComponent,
        icon: <Save data-test-id="tab-download" />,
        title: t(translationPath(lang.general.download))
      },
      {
        action: handleShowPreview,
        filter: handleCanShowPreview,
        icon: <Visibility data-test-id="tab-show-preview" />,
        title: t(translationPath(lang.general.showPreview))
      }
    ]
  }
});
