import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

export const useStyles: Function = makeStyles((theme: Theme) => {
  const tableBase = {
    "& .dialog__table-layout": {
      height: "300px"
    }
  };
  return createStyles({
    createdTable: {
      ...tableBase,
      marginTop: "60px"
    },
    returnedTable: tableBase,
    shipmentTableHeight: {
      height: "85.5%"
    }
  });
});
