import { isPerson } from "../../../../../domain/struct/nameRegistry/Person";
import { SubjectType } from "../../../../../domain/struct/nameRegistry/SubjectType";
import { isLegalEntity } from "../../../../../domain/struct/nameRegistry/LegalEntity";
import { useModal } from "../../../../designSystem/Modal/useModal";
import { SubjectModal } from "../../../../modules/nameRegister/modal/SubjectModal/SubjectModal";
import { FileConcernedSubject } from "../../../../../domain/struct/nameRegistry/FileConcernedSubject";

export const useFileConcernedSubjectLogic = () => {
  const [subjectDetailModal, subjectDetailModalApi] = useModal(SubjectModal);
  const onShowDetail = (selected: FileConcernedSubject[]) => {
    const { subject } = selected[0];

    if (isPerson(subject)) {
      return subjectDetailModalApi.open({
        type: SubjectType.Person,
        person: subject
      });
    } else if (isLegalEntity(subject)) {
      return subjectDetailModalApi.open({
        type: SubjectType.LegalEntity,
        legalEntity: subject
      });
    }

    return undefined;
  };

  return {
    onShowDetail,
    modal: [subjectDetailModal]
  };
};
