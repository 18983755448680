import { ApiListResponse } from "../../../../../../infrastructure/api/struct/ApiTypesV2";
import { ApiListResponseMapper } from "../../../../../../infrastructure/api/mapper/ApiListResponseMapper";
import { stringOrDefault } from "../../../../../../lib/string";
import { EmailWhitelistResponse } from "./index";
import { Whitelist } from "../_types";
import { PaginatedResult } from "lib/contract/Pagination";

export class Mappers {
  static mapListToDomain(
    response: ApiListResponse<EmailWhitelistResponse>
  ): PaginatedResult<Whitelist> {
    return ApiListResponseMapper.mapListToPagination(
      response,
      Mappers.mapToDomain
    );
  }

  static mapToDomain(response: EmailWhitelistResponse): Whitelist {
    const { comment, createdAt, email, emailId } = response;
    return {
      comment: stringOrDefault(comment),
      createdAt: stringOrDefault(createdAt),
      email: stringOrDefault(email),
      emailId: emailId
    };
  }
}
