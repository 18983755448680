import { DefaultsConfiguration } from "@isfg/react-components/build/defaults/DefaultsConfiguration";
import { theme } from "presentation/styles";
import styled from "styled-components";
import { CloseOutlined } from "../../presentation/designSystem/Icon/Icon";
import React from "react";

const StyledCloseIcon = styled(CloseOutlined)(() => ({
  color: `${theme.color.white}`,
  padding: "0.5rem",
  borderRadius: "50%",
  "&:hover": {
    color: `${theme.color.black}`,
    background: `${theme.color.white}`
  },
  transition: "0.2s"
}));

const closeIcon = <StyledCloseIcon rev={"default"} />;

export const isfgComponentsDefaults: DefaultsConfiguration = {
  modal: {
    closeIcon: closeIcon
  }
};
