import MenuLayout from "../../../../core/components/layout/MenuLayout";
import { SubjectModalContextProvider } from "../../contexts/SubjectModalContextProvider";
import { LegalEntityTable } from "../../tables/LegalEntityTable/LegalEntityTable";
import React from "react";

export const LegalEntityPage = () => (
  <MenuLayout>
    <SubjectModalContextProvider>
      <LegalEntityTable />
    </SubjectModalContextProvider>
  </MenuLayout>
);
