import { useState } from "react";
import { Address } from "../../../../../../domain/struct/nameRegistry/Address";

export const useAddressFields = (address?: Address) => {
  const [houseNumberType, setHouseNumberType] = useState(
    address?.houseNumberType ?? ""
  );
  const [
    showOrientationHouseNumberAddendum,
    setShowOrientationHouseNumberAddendum
  ] = useState(!!address?.orientationHouseNumber);

  const onChange = (value: string) => {
    setHouseNumberType(value as string);
  };

  function checkHouseNumber(event: any) {
    setShowOrientationHouseNumberAddendum(!!event.target.value);
    return event.target.value;
  }

  return {
    onChange,
    checkHouseNumber,
    houseNumberType,
    showOrientationHouseNumberAddendum
  };
};
