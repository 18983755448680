import { DatePicker, DateTimePicker } from "@mui/lab";
import styled from "presentation/styles";
import {
  responsiveFieldFifth,
  responsiveFieldHalf,
  responsiveFieldQuarter
} from "../dialog/Dialog.styles";

export const StyledKeyboardDatePicker = styled(DatePicker)<{}>(
  () => responsiveFieldQuarter
);

export const StyledKeyboardDatePickerFifth = styled(DatePicker)<{}>(
  () => responsiveFieldFifth
);
export const StyledKeyboardDatePickerHalf = styled(DatePicker)<{}>(
  () => responsiveFieldHalf
);

export const StyledKeyboardDateTimePickerFifth = styled(DateTimePicker)<{}>(
  () => responsiveFieldFifth
);
