import { FileHistoryRepository } from "domain/service/file/FileHistoryRepository";
import { DInjectionToken } from "presentation/core/features/dependencyInjection";
import { FileConcernedSubjectRepository } from "domain/service/file/FileConcernedSubjectRepository";

export const FileConcernedSubjectRepositoryToken = new DInjectionToken<FileConcernedSubjectRepository>(
  "domain.file.FileConcernedSubject-repository"
);

export const FileHistoryRepositoryToken = new DInjectionToken<FileHistoryRepository>(
  "domain.file.FileHistory-repository"
);
