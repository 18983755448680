// 1. step - define data types
// It should be a domain class, not only type
// and business rules should be defined on domain entity
export const DESCRIPTIVE_HOUSE_NUMBER_TYPE = "DESCRIPTIVE";
export const EVIDENCE_HOUSE_NUMBER_TYPE = "EVIDENCE";

export interface Address {
  readonly id?: number;
  readonly street?: string;
  readonly city?: string;
  readonly postalCode?: string;
  readonly country?: string;
  readonly addressType?: string;
  readonly houseNumberType?: string;
  readonly houseNumber?: string;
  readonly orientationHouseNumber?: string;
  readonly orientationHouseNumberAddendum?: string;
  readonly cityPart?: string;
}
