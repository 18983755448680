import { PaginationConfig } from "lib/contract/Pagination";
import { useQM } from "presentation/share/hook/query/useQM";
import { getShipments } from "../api";

export const useGetShipments = (
  nodeId: string,
  config: PaginationConfig,
  enabled?: boolean
) => {
  return useQM(
    ["documents/shipments", nodeId, config],
    () => getShipments(nodeId, config),
    {
      enabled
    }
  );
};
