import { ApiTypes } from "../struct/ApiTypes";
import { SignerGroup } from "../../../domain/struct/user/SignerGroup";

export type ApiSignerGroup = ApiTypes["ISFG.SpisUm.ClientSide.Models.Group.GroupMember"];

export class SignerGroupApiMapper {
  static mapSignerGroups(response: ApiSignerGroup[]): SignerGroup[] {
    return response.map((resp) => ({
      id: resp.id || "",
      displayName: resp.displayName || ""
    }));
  }
}
