import {
  DInject,
  DInjectable
} from "presentation/core/features/dependencyInjection";
import { ApiUrls } from "infrastructure/api/struct/ApiTypesV2";
import { HttpClient } from "infrastructure/service/HttpClient";
import { ShipmentComponentRepository } from "domain/service/shipment/ShipmentComponentRepository";
import { DocumentId } from "../../../domain/struct/document/Document";
import { PaginationConfig } from "lib/contract/Pagination";
import {
  ApiDocumentShipableComponent,
  ApiShipmentComponent,
  ShipmentComponentApiMapper
} from "./ShipmentComponentApiMapper";
import { ShipmentId } from "../../../domain/struct/shipment/Shipment";

const URL: ApiUrls = {
  GetComponentsSuitableForShipment:
    "/api/app/v2/document/{nodeId}/components/shipment",
  GetShipmentComponents: "/api/app/v2/shipment/{nodeId}/components"
};

@DInjectable()
export class ShipmentComponentApiRepository
  implements ShipmentComponentRepository {
  constructor(@DInject(HttpClient) protected httpClient: HttpClient) {}

  getShipableComponents(documentId: DocumentId, pagination: PaginationConfig) {
    return this.httpClient
      .fetchPaginated<ApiDocumentShipableComponent>({
        url: URL.GetComponentsSuitableForShipment,
        pagination,
        config: {
          urlWildCards: {
            nodeId: documentId
          }
        }
      })
      .then(ShipmentComponentApiMapper.mapListToDomain);
  }

  getShipmentComponents(shipmentId: ShipmentId, pagination: PaginationConfig) {
    return this.httpClient
      .fetchPaginated<ApiShipmentComponent>({
        url: URL.GetShipmentComponents,
        pagination,
        config: {
          urlWildCards: {
            nodeId: shipmentId
          }
        }
      })
      .then(ShipmentComponentApiMapper.mapListToDomain);
  }
}
