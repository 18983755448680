import { ApiListResponse, ApiTypes } from "../struct/ApiTypesV2";
import { ApiListResponseMapper } from "../mapper/ApiListResponseMapper";
import { stringOrDefault } from "../../../lib/string";
import { formatDate } from "../dateTime";
import { RetentionProtocolOrProposalOrErased } from "../../../domain/struct/recordRetention/RetentionProtocolOrProposalOrErased";

export type ApiRepositoryRetentionProtocolsViewResponse = ApiTypes["ISFG.SpisUm.ClientSide.Models.V2.Shredding.Response.ShreddingProtocolViewResponse"];
export type ApiRepositoryRetentionProtocolsOne = ApiTypes["ISFG.SpisUm.ClientSide.Models.V2.Shredding.Response.ShreddingProposalProtocolDetailResponse"];
export type ApiRepositoryRetentionProtocolsUploadAcceptanceFromDA = {
  fileData: string;
};

export class RetentionProtocolsApiMapper {
  static mapListToDomain(
    response: ApiListResponse<ApiRepositoryRetentionProtocolsViewResponse>
  ) {
    return ApiListResponseMapper.mapListToPagination(
      response,
      RetentionProtocolsApiMapper.mapToDomain
    );
  }

  static mapToDomain(
    response: ApiRepositoryRetentionProtocolsViewResponse
  ): RetentionProtocolOrProposalOrErased {
    const {
      pid,
      nodeId,
      name,
      createdDate,
      authorFullName,
      shreddingObjectsCount,
      shreddingOperationDateTime,
      reviewerFullName
    } = response;
    return {
      nodeId: stringOrDefault(nodeId),
      pid: stringOrDefault(pid),
      name: stringOrDefault(name),
      createdDate: stringOrDefault(createdDate),
      authorFullName: stringOrDefault(authorFullName),
      shreddingObjectsCount,
      ShreddingOperationDate: stringOrDefault(shreddingOperationDateTime),
      reviewerFullName: stringOrDefault(reviewerFullName)
    };
  }

  static mapFindProtocol({
    nodeId,
    pid,
    name,
    createdDate,
    shreddingObjectsCount,
    authorFullName,
    nodeType,
    shreddingOperationDateTime,
    erasedDate,
    reviewerFullName
  }: ApiRepositoryRetentionProtocolsOne): RetentionProtocolOrProposalOrErased {
    return {
      nodeId: stringOrDefault(nodeId),
      pid: stringOrDefault(pid),
      name: stringOrDefault(name),
      createdAt: createdDate || undefined,
      shreddingObjectsCount: shreddingObjectsCount || undefined,
      authorFullName: stringOrDefault(authorFullName),
      nodeType: stringOrDefault(nodeType),
      shreddingOperationDateTime: formatDate(shreddingOperationDateTime),
      erasedDate: formatDate(erasedDate),
      reviewerFullName: stringOrDefault(reviewerFullName)
    };
  }
}
