import { Concept } from "presentation/core/entities/concept/Concept";
import { sslPropsProxy } from "presentation/core/types";
import {
  lastPathMember,
  translationPath
} from "presentation/share/utils/getPath";
import { validateErrors } from "presentation/share/utils/validation";
import { lang, t } from "presentation/translation/i18n";
import * as yup from "yup";

export const validate = (values: Concept) =>
  validateErrors(validationSchema, values);

export const validationSchema = yup.object().shape({
  [lastPathMember(sslPropsProxy.subject).path]: yup
    .string()
    .max(255, t(translationPath(lang.dialog.errors.maxLen), { len: 255 }))
});
