import {
  SessionType,
  UserGroupType
} from "presentation/core/features/login/_types";
import { SpisumGroups } from "presentation/enums";

export const isUserInLeadership = (session: SessionType) => {
  if (!session || !session.user) {
    return false;
  }
  const leaderShipGroups = [
    `${session.activeGroup}_Coordinator`,
    SpisumGroups.Admin
  ];
  return (
    session.myGroups.find((x) => leaderShipGroups.indexOf(x.id) !== -1) !== null
  );
};

export const userHasSignPermissions = (session: SessionType) => {
  if (!session || !session.user) {
    return false;
  }

  return session.myGroups.some((x: UserGroupType) => {
    return x.id === `${session.activeGroup}_Sign`;
  });
};

export const userHasSignGroup = (
  activeGroup: string,
  userGroups: UserGroupType[]
) => {
  return userGroups.some((x) => {
    return x.id === `${activeGroup}_Sign`;
  });
};

export const userHasSealPermissions = (session: SessionType) => {
  if (!session || !session.user) {
    return false;
  }

  return session.myGroups.some((x: UserGroupType) => {
    return x.id === `${session.activeGroup}_Seal`;
  });
};

export const userHasSealGroup = (
  activeGroup: string,
  userGroups: UserGroupType[]
) => {
  return userGroups.some((x) => {
    return x.id === `${activeGroup}_Seal`;
  });
};

export const userHasCoordinatorGroup = (
  activeGroup: string,
  userGroups: UserGroupType[]
) => {
  return userGroups.some((x) => {
    return x.id === `${activeGroup}_Coordinator`;
  });
};

export enum UserRepositoryRights {
  None = "None",
  Basic = "Basic",
  Full = "Full"
}
export const getUserRepositoryRights = (
  activeGroup: string,
  userGroups: UserGroupType[]
): UserRepositoryRights => {
  const hasFullRights = userGroups.find(
    (group) => group.id === `${activeGroup}_FULL`
  );

  if (hasFullRights) {
    return UserRepositoryRights.Full;
  }

  const hasBasicRights = userGroups.find(
    (group) => group.id === `${activeGroup}_BASIC`
  );

  return hasBasicRights
    ? UserRepositoryRights.Basic
    : UserRepositoryRights.None;
};
