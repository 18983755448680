import MenuLayout from "presentation/core/components/layout/MenuLayout";
import React from "react";
import { withTranslation } from "presentation/translation/i18n";
import { RentedEvidenceDocumentsTable } from "./tables/RentedEvidenceDocumentsTable";

const RentedOverviews = () => {
  return (
    <MenuLayout>
      <RentedEvidenceDocumentsTable />
    </MenuLayout>
  );
};

export default withTranslation()(RentedOverviews);
