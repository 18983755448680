import { AnalogDocument } from "../../income/analog/types/types";
import { CreateDataboxRegistration } from "../../income/digitalDocument/dataBox/unprocessed/types";
import { CreateEmailRegistration } from "../../income/digitalDocument/emailBox/unprocessed/types";
import { TechnicalDataCarrierDocument } from "../../income/digitalDocument/technicalDataCarries/types/types";
import { DocumentGetUnfinishedResponse } from "../types";

export const mapNulltoUndefined = <T>(data: T) =>
  Object.keys(data as {}).reduce((acc, key) => {
    if (data[key as keyof typeof data] === null) {
      acc[key as keyof typeof acc] = undefined; // map null as undefined
    } else if (typeof data[key as keyof typeof data] === "object") {
      acc[key as keyof typeof acc] = mapNulltoUndefined(
        data[key as keyof typeof data]
      ); // map nested objects
    } else {
      acc[key as keyof typeof acc] = data[key as keyof typeof data]; // otherwise map old value
    }
    return acc;
  }, {} as any);

export const mapApiToDataboxDocument = (
  response: DocumentGetUnfinishedResponse
): CreateDataboxRegistration => {
  const data = mapNulltoUndefined(response);
  return {
    ...data,
    attachmentCount: data.attachmentsCount,
    attachmentType: data.attachmentsType
  };
};

export const mapApiToEmailDocument = (
  response: DocumentGetUnfinishedResponse
): CreateEmailRegistration => {
  const data = mapNulltoUndefined(response);
  return {
    ...data,
    attachmentCount: data.attachmentsCount,
    attachmentType: data.attachmentsType
  };
};

export const mapApiToAnalogDocument = (
  response: DocumentGetUnfinishedResponse
): AnalogDocument => mapNulltoUndefined(response);

export const mapApiToTechnDataCarrierDocument = (
  response: DocumentGetUnfinishedResponse
): TechnicalDataCarrierDocument => mapNulltoUndefined(response);
