import {
  DialogContentPropsType,
  DialogDataGenericData
} from "presentation/core/components/dialog/_types";
import React from "react";
import { ReasonFormValues } from "../../../../../core/components/reasonForm/_types";
import { validate } from "../../../../../core/components/reasonForm/_validations";
import { Form, Formik } from "formik";
import { t } from "../../../../../translation/i18n";
import { getErrorTranslationKey } from "presentation/modules/administration/features/filePlan/helpers/helpers";

const ErrorInImportDialog = ({ dialogProps }: DialogContentPropsType) => {
  const { error } = dialogProps.data as DialogDataGenericData;

  const onSubmit = () => {
    console.log("Error found");
  };

  return (
    <Formik<ReasonFormValues> // this type will be changed as soon as BE is ready
      initialValues={{
        // this object will be changed as soon as BE is ready
        reason: ""
      }}
      onSubmit={onSubmit} // this function will be changed as soon as BE is ready
      // innerRef={setFormRef}
      validate={validate} // this function will be changed as soon as BE is ready
    >
      {() => (
        <Form>
          {error?.message || t(getErrorTranslationKey(error?.code || ""))}
        </Form>
      )}
    </Formik>
  );
};

export default React.memo(ErrorInImportDialog) as typeof ErrorInImportDialog;
