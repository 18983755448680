import { getService } from "presentation/core/features/dependencyInjection";
import { PaginationConfig } from "../../../../lib/contract/Pagination";
import { useCallback } from "react";
import { FindRetentionProtocolsOrProposalsContent } from "../../../../useCase/recordRetention/FindRetentionProtocolsOrProposalsContent";

export const useFindRetentionProtocolOrProposalContent = (nodeId: string) => {
  const findRetentionProtocolOrProposalUseCase = getService(
    FindRetentionProtocolsOrProposalsContent
  );

  return useCallback(
    (config: PaginationConfig) =>
      findRetentionProtocolOrProposalUseCase.findAllPaginated(nodeId, config),
    [findRetentionProtocolOrProposalUseCase, nodeId]
  );
};
