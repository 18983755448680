import { Breadcrumbs, Grid, Typography } from "@mui/material";
import MenuLayout from "presentation/core/components/layout/MenuLayout";
import { translationPath } from "presentation/share/utils/getPath";
import styled, { theme } from "presentation/styles";
import { lang, t } from "presentation/translation/i18n";
import { NameRegisterConfigurationForm } from "./NameRegisterConfigurationForm";
import React from "react";

const ActionBarContainerStyled = styled(Grid)(() => ({
  background: "#fff",
  padding: "30px 6px 10px 20px"
}));
const ActionBarSectionStyled = styled(Grid)(() => ({}));
const TitleStyled = styled(Typography)(() => ({
  color: theme.color.primary,
  fontWeight: 600
}));
const PathStyled = styled(Breadcrumbs)(() => ({
  "& p": { color: theme.colors.gray },
  color: theme.colors.gray
}));

export const NameRegisterConfigurationPage = () => {
  const breadcrumbs = [
    t(translationPath(lang.menu.items.administration)),
    t(translationPath(lang.menu.items.nameRegister))
  ];

  return (
    <MenuLayout>
      <div>
        <ActionBarContainerStyled
          container={true}
          alignItems="center"
          justifyContent="space-between"
          alignContent="flex-end"
          direction="row"
        >
          <ActionBarSectionStyled xs={7} item={true}>
            <TitleStyled className="title" variant="h5" gutterBottom={true}>
              {t(translationPath(lang.menu.items.nameRegister))}
            </TitleStyled>
            <PathStyled separator="›" aria-label="breadcrumb">
              {breadcrumbs &&
                breadcrumbs.length &&
                breadcrumbs.map((item) => (
                  <Typography key={item} color="textPrimary">
                    {item}
                  </Typography>
                ))}
            </PathStyled>
          </ActionBarSectionStyled>
        </ActionBarContainerStyled>
        <NameRegisterConfigurationForm />
      </div>
    </MenuLayout>
  );
};
