import {
  DInject,
  DInjectable
} from "../../../presentation/core/features/dependencyInjection";

import { CountryRepositoryToken } from "../../../domain/di/token/codelist";

import type { CountryRepository } from "../../../domain/service/codelist/CountryRepository";

@DInjectable()
export class FindCountry {
  constructor(
    @DInject(CountryRepositoryToken)
    protected repository: CountryRepository
  ) {}

  findAll() {
    return this.repository.findAll();
  }
}
