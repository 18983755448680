import { getService } from "../../../core/features/dependencyInjection";
import { FindConceptConcernedSubject } from "../../../../useCase/document/concernedSubject/FindConceptConcernedSubject";
import { PaginationConfig } from "../../../../lib/contract/Pagination";

export const useHasConceptConcernedSubjects = async (id: string) => {
  const findUseCase = getService(FindConceptConcernedSubject);
  const pagination: PaginationConfig = { page: 0, itemsPerPage: 10 };
  const result = await findUseCase.findPaginated(id, pagination);
  return result.items.length > 0;
};
