import { ConfigurationRepositoryToken } from "domain/di/token/configuration";
import type { ConfigurationRepository } from "domain/service/configuration/ConfigurationRepository";
import {
  DInject,
  DInjectable
} from "presentation/core/features/dependencyInjection";

@DInjectable()
export class GetConfiguration {
  constructor(
    @DInject(ConfigurationRepositoryToken)
    protected repository: ConfigurationRepository
  ) {}

  getConfiguration() {
    return this.repository.getConfiguration();
  }
}
