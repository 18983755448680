import {
  DInject,
  DInjectable
} from "presentation/core/features/dependencyInjection";
import { ApiUrls } from "infrastructure/api/struct/ApiTypesV2";
import { HttpClient, HttpMethod } from "infrastructure/service/HttpClient";
import { RetentionProposalsRepository } from "domain/service/recordRetention/RetentionProposalsRepository";
import {
  ApiRepositoryRetentionProposalViewResponse,
  RetentionProposalsApiMapper
} from "./RetentionProposalsApiMapper";
import { PaginatedResult, PaginationConfig } from "lib/contract/Pagination";
import { SortingConfig } from "lib/contract/Sorting";
import { RetentionProtocolOrProposalOrErased } from "../../../domain/struct/recordRetention/RetentionProtocolOrProposalOrErased";
import { UploadFromDA } from "../../../domain/struct/recordRetention/UploadFromDA";
import { ApiRepositoryRetentionProtocolsUploadAcceptanceFromDA } from "./RetentionProtocolsApiMapper";

const URL: ApiUrls = {
  GetRepositoryRetentionProposals: "/api/app/v2/shredding/proposals",
  GetRepositoryRetentionProposalById:
    "/api/app/v2/shredding/proposal-protocol/{nodeId}",
  UploadDecisionFromDA:
    "/api/app/v2/shredding/proposal/{nodeId}/decision-da/import"
};
@DInjectable()
export class RetentionProposalsApiRepository
  implements RetentionProposalsRepository {
  constructor(@DInject(HttpClient) protected httpClient: HttpClient) {}

  getRetentionProposals(
    pagination: PaginationConfig,
    sorting?: SortingConfig
  ): Promise<PaginatedResult<RetentionProtocolOrProposalOrErased>> {
    return this.httpClient
      .fetchPaginated<ApiRepositoryRetentionProposalViewResponse>({
        url: URL.GetRepositoryRetentionProposals,
        pagination,
        sorting
      })
      .then(RetentionProposalsApiMapper.mapListToDomain);
  }

  findRetentionProposalById(
    nodeId: string
  ): Promise<RetentionProtocolOrProposalOrErased> {
    return this.httpClient
      .fetchWithThrow<ApiRepositoryRetentionProposalViewResponse>(
        URL.GetRepositoryRetentionProposalById,
        HttpMethod.GET,
        {
          urlWildCards: {
            nodeId
          }
        }
      )
      .then(RetentionProposalsApiMapper.mapToDomain);
  }
  uploadDecisionFromDA(nodeId: string, file: File): Promise<UploadFromDA> {
    const bodyFormData = new FormData();
    bodyFormData.append("fileData", file);

    return this.httpClient.fetchWithThrow<ApiRepositoryRetentionProtocolsUploadAcceptanceFromDA>(
      URL.UploadDecisionFromDA,
      HttpMethod.Post,
      {
        bodyFormData,
        urlWildCards: {
          nodeId
        }
      }
    );
  }
}
