import { LockOutlined } from "@mui/icons-material";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import React, { useMemo } from "react";
import { getFileExt } from "../../../../helpers/file";
import { StyledUnsupportedFileWrapper } from "./FilePreview.styles";
import { ContentType, PreviewersType } from "./_types";
import { HtmlPreview } from "./htmlPreview/HtmlPreview";
import { PdfPreviewLazy } from "./pdfPreview/PdfPreview.lazy";
import { TxtPreview } from "./txtPreview/TxtPreview";

interface OwnProps {
  name: string;
  content?: ContentType;
  fileIsEncrypted: boolean;
}

const previewers: PreviewersType = {
  jpeg: PdfPreviewLazy,
  jpg: PdfPreviewLazy,
  json: TxtPreview,
  pdf: PdfPreviewLazy,
  png: PdfPreviewLazy,
  txt: TxtPreview,
  xml: TxtPreview,
  eml: TxtPreview,
  html: HtmlPreview
};

export const FilePreview = React.memo(
  ({ name, content, fileIsEncrypted }: OwnProps) => {
    const PreviewCom = useMemo(() => {
      const ext = getFileExt(name);

      return ext ? previewers[ext] : null;
    }, [name]);

    if (fileIsEncrypted) {
      return (
        <StyledUnsupportedFileWrapper>
          {t(
            translationPath(lang.nodePreview.filePreview.componentIsEncrypted)
          )}
          <LockOutlined style={{ marginTop: "10px", width: "390px" }} />
        </StyledUnsupportedFileWrapper>
      );
    }

    if (!PreviewCom) {
      return (
        <StyledUnsupportedFileWrapper>
          {t(translationPath(lang.nodePreview.filePreview.unsupportedFile))}
        </StyledUnsupportedFileWrapper>
      );
    }

    if (!content) {
      return (
        <div>{t(translationPath(lang.nodePreview.filePreview.emptyFile))}</div>
      );
    }

    return (
      <PreviewCom
        name={name}
        content={content}
        fileIsEncrypted={fileIsEncrypted}
      />
    );
  }
);
