import { useStyles } from "presentation/core/components/dialog/Dialog.styles";
import { DialogContentType } from "presentation/core/components/dialog/_types";
import React from "react";
import { ChangePasswordForm } from "./ChangePasswordForm";
import { ChangePasswordFormValues } from "./_types";
import { changePasswordValidationSchema } from "./_validations";
import { ChannelForm } from "../ChannelForm/ChannelForm";

export const ChangePasswordDialog: DialogContentType["content"] = ({
  channel
}) => {
  const classes = useStyles();

  return (
    <div className={classes.modalSmall}>
      <ChannelForm<ChangePasswordFormValues>
        initialValues={{
          newPassword: "",
          newPassword2: "",
          oldPassword: ""
        }}
        validationSchema={changePasswordValidationSchema}
        channel={channel}
        component={ChangePasswordForm}
      />
    </div>
  );
};
