import { ErrorBoundary } from "../errorBoundary";
import { DocumentViewError } from "../errorBoundary/errorTypes/DocumentViewError";
import DocumentView, { OwnProps } from "./DocumentView";
import { DocumentViewType } from "./_types";
import React from "react";

const DocumentViewWrapper = <T extends DocumentViewType>(
  props: OwnProps<T>
) => {
  return (
    <ErrorBoundary Component={DocumentViewError}>
      <DocumentView<T> {...props} />
    </ErrorBoundary>
  );
};

export default DocumentViewWrapper;
