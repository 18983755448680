import { GetRemoteDataClb } from "@isfg/react-components/build/Table/RemoteTable";
import {
  createColumn,
  createDateTimeColumn
} from "@isfg/react-components/build/Table/column/column";
import { NextWeek } from "@mui/icons-material";
import { TableRowSelection } from "antd/lib/table/interface";
import { getActiveComponentsForMainActions } from "presentation/core/components/dialog/tabs/components/api";
import { FileMetaType } from "presentation/core/entities";
import { useModal } from "presentation/designSystem/Modal/useModal";
import {
  ColumnType,
  TableActions,
  useRemoteTableApi
} from "presentation/designSystem/Table/Table";
import { SortDirection } from "presentation/govDesignSystem/lib/contract/Sorting";
import { useGetDocumentDetailsModal } from "presentation/modules/evidence/hooks/useGetDocumentDetailsModal";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { DateTimeFormat } from "../../../../../../../lib/dateTime";
import { RemoteTable } from "../../../../../../designSystem/Table/RemoteTable";
import { DocumentState, DocumentType } from "../../../../../../enums";
import { actionShowDetail } from "../../../../../../share/components/table/actionFactory";
import { translationPath } from "../../../../../../share/utils/getPath";
import { lang, t } from "../../../../../../translation/i18n";
import { useGetTypeFileDocuments } from "../../hooks/useGetTypeFileDocuments";
import { TypeFileDocumentsResponse } from "../../types";
import { SettleTypeFileModal } from "./SettleTypeFileModal";

const COLUMNS: ColumnType<TypeFileDocumentsResponse>[] = [
  createColumn({
    dataIndex: "pid",
    title: t(translationPath(lang.general.identifier)),
    sortDirections: ["ascend", "descend", "ascend"],
    defaultSortOrder: "ascend",
    sorter: true
  }),
  createColumn({
    dataIndex: "ssid",
    title: t(translationPath(lang.general.referenceNumber)),
    sortDirections: ["ascend", "descend", "ascend"],
    sorter: true
  }),
  createColumn({
    dataIndex: "subject",
    title: t(translationPath(lang.general.subject)),
    sortDirections: ["ascend", "descend", "ascend"],
    sorter: true
  }),
  createDateTimeColumn(
    {
      dataIndex: "evidenceDate",
      title: t(translationPath(lang.general.dateOfEvidence))
    },
    DateTimeFormat.FullDateTime
  ),
  createColumn({
    dataIndex: "listCountAttachments",
    title: t(translationPath(lang.general.attachmentsAndList)),
    sortDirections: ["ascend", "descend", "ascend"],
    sorter: true,
    render: (text, row) => {
      const form = row?.form;
      if (form === DocumentType.Analog) {
        const attachmentsCount = row?.attachmentsCount;
        const listCount = row?.listCount;
        const listCountAttachments = row?.listCountAttachments;
        let attachmentLabel =
          (Boolean(attachmentsCount) ? attachmentsCount : "-") +
          "/" +
          (Boolean(listCount) ? listCount : "-") +
          "/" +
          (Boolean(listCountAttachments) ? listCountAttachments : "-");
        return attachmentLabel;
      }
      if (form === DocumentType.Concept) {
        return "";
      }
      return row?.attachmentsCount;
    }
  }),
  createColumn({
    dataIndex: "form",
    title: t(translationPath(lang.general.documentForm)),
    sortDirections: ["ascend", "descend", "ascend"],
    render: (text, row) => {
      const form = row?.form;
      let formText = "";
      if (form === DocumentType.Analog) {
        formText = t(translationPath(lang.enums.documentType.analog));
      }
      if (form === DocumentType.Digital) {
        formText = t(translationPath(lang.enums.documentType.digital));
      }
      if (form === DocumentType.AD) {
        formText = t(translationPath(lang.enums.documentType.conversionAD));
      }
      if (form === DocumentType.DA) {
        formText = t(translationPath(lang.enums.documentType.conversionDA));
      }
      return formText;
    },
    sorter: true
  })
];
interface TypeFilesContentTableProps {
  nodeId: string;
  sectionId: string;
  partId: string;
  isOpenContent: boolean;
  validateClosePartButton(): void;
}

export const TypeFilesContentTable = React.memo(
  ({
    nodeId,
    sectionId,
    partId,
    isOpenContent,
    validateClosePartButton
  }: TypeFilesContentTableProps) => {
    const [tableApi, onRemoteTableApi] = useRemoteTableApi();
    const [selectedRows, setSelectedRows] = useState(
      [] as TypeFileDocumentsResponse[]
    );
    const [selectedRowKeys, setSelectedRowKeys] = useState([] as React.Key[]);
    const { detailsModal, getDetails } = useGetDocumentDetailsModal({
      nodeId: selectedRows[0]?.nodeId!,
      onOk: () => {
        tableApi.refetch();
      }
    });
    const [settleModal, settleModalApi] = useModal(SettleTypeFileModal, {
      onOk: () => {
        tableApi.refetch();
        validateClosePartButton?.();
      }
    });

    const getTypeFileDocuments = useGetTypeFileDocuments();

    const ACTIONS: TableActions<TypeFileDocumentsResponse> = useMemo(
      () => ({
        single: [
          actionShowDetail({
            action: getDetails
          }),
          {
            key: "settle-document",
            action(selected: TypeFileDocumentsResponse[]) {
              return settleModalApi.open({ nodeId: selected[0].nodeId! });
            },
            icon: <NextWeek />,
            canBeDisplayed: () => isOpenContent,
            tooltip: t(translationPath(lang.general.settle)),
            validation: async (items: TypeFileDocumentsResponse[]) => {
              if (items[0]?.state === DocumentState.Settled) {
                return t(translationPath(lang._validations.forClosedDocuments));
              }

              const validationMessages = [];

              if (!items[0]?.fileMark) {
                validationMessages.push(
                  t(translationPath(lang._validations.requiredFileMark))
                );
              }
              if (items[0]?.isEncrypted) {
                return t(
                  translationPath(
                    lang._validations.documentHasEncryptedComponent
                  )
                );
              }
              if (!items[0]?.filePlan) {
                validationMessages.push(
                  t(translationPath(lang._validations.requiredFilePlan))
                );
              }
              if (!items[0]?.retentionMode) {
                validationMessages.push(
                  t(translationPath(lang._validations.requiredRetentionMode))
                );
              }
              if (validationMessages.length > 0) {
                return validationMessages.join("\n");
              }

              if (
                items.filter(
                  (document) => document?.form === DocumentType.Analog
                ).length === items.length &&
                !items[0].listCount
              ) {
                return t(translationPath(lang._validations.requiredListCount));
              } else if (
                items.filter(
                  (document) => document?.form === DocumentType.Analog
                ).length === items.length &&
                !items[0].listCountAttachments
              ) {
                return t(
                  translationPath(
                    lang._validations.requiredListCountAttachments
                  )
                );
              }
              if (
                items.filter((document) => document?.form === DocumentType.DA)
                  .length === items.length &&
                !items[0].attachmentsCount
              ) {
                return t(
                  translationPath(lang._validations.requiredAttachmentsCount)
                );
              } else if (!items[0]?.form) {
                return t(
                  translationPath(lang._validations.requiredFormDocument)
                );
              } else if (
                !!items[0]?.forSealUser ||
                !!items[0]?.forSignatureUser
              ) {
                return t(translationPath(lang._validations.inSignatureBook));
              } else if (items[0]?.documentType === DocumentType.Concept) {
                const isComponent = await getActiveComponentsForMainActions(
                  items[0]?.nodeId!,
                  {
                    page: 1,
                    itemsPerPage: 100
                  },
                  [
                    {
                      property: "name",
                      direction: SortDirection.Ascending
                    }
                  ]
                ).then((result) => result.items.length > 0);
                if (!isComponent) {
                  return t(
                    translationPath(lang._validations.requiredComponent)
                  );
                }
                return "";
              } else {
                if (
                  items[0]?.documentType === DocumentType.Databox ||
                  items[0]?.documentType === DocumentType.TechnicalDataCarries
                ) {
                  const checkTheComponent =
                    await getActiveComponentsForMainActions(
                      items[0]?.nodeId!,
                      {
                        page: 1,
                        itemsPerPage: 100
                      },
                      [
                        {
                          property: "name",
                          direction: SortDirection.Ascending
                        }
                      ]
                    ).then((result) => {
                      if (result.items.length > 0) {
                        const settle = result.items.filter(
                          (item) =>
                            item.type === FileMetaType.main && item.isReadable
                        );
                        if (settle.length > 0) {
                          return false;
                        }
                      } else if (
                        result.items.length <= 0 &&
                        items[0]?.documentType ===
                          DocumentType.TechnicalDataCarries
                      ) {
                        return false;
                      }
                      return true;
                    });
                  if (checkTheComponent) {
                    if (items[0]?.documentType === DocumentType.Databox) {
                      return t(
                        translationPath(lang._validations.componentsForDatabox)
                      );
                    } else {
                      return t(
                        translationPath(
                          lang._validations.componentsForTechnicalDataCarrier
                        )
                      );
                    }
                  } else {
                    return "";
                  }
                } else {
                  return "";
                }
              }
            }
          }
        ]
      }),
      [settleModalApi, isOpenContent, getDetails]
    );

    const getRemoteData: GetRemoteDataClb<TypeFileDocumentsResponse> =
      useCallback(
        ({ pagination, sorting }) =>
          getTypeFileDocuments(nodeId, sectionId, partId, pagination, sorting),
        [getTypeFileDocuments, nodeId, sectionId, partId]
      );

    const onRowSelection: TableRowSelection<TypeFileDocumentsResponse> = {
      onChange: (selectedRowKeys, selectedRows) => {
        setSelectedRowKeys(selectedRowKeys);
        setSelectedRows(selectedRows);
      },
      selectedRowKeys
    };

    const queryConfig = useMemo(() => {
      return { enabled: Boolean(sectionId && partId) };
    }, [sectionId, partId]);

    useEffect(() => {
      setSelectedRowKeys([]);
      setSelectedRows([]);
    }, [sectionId, partId]);

    return (
      <>
        {detailsModal}
        {settleModal}
        <TableStyled>
          <RemoteTable<TypeFileDocumentsResponse>
            name={`repository/typeFileDocuments/${nodeId}/${sectionId}/${partId}`}
            rowKey="pid"
            actions={ACTIONS}
            columns={COLUMNS}
            getRemoteData={getRemoteData}
            rowSelection={onRowSelection}
            queryConfig={queryConfig}
            onRemoteTableApi={onRemoteTableApi}
            title={t(
              translationPath(lang.table.openTypeFilesTable.contentOfPart)
            )}
          />
        </TableStyled>
      </>
    );
  }
);
const TableStyled = styled("div")`
  div > .ant-row {
    height: 50px;
  }
`;
