import { getService } from "../../../../../../core/features/dependencyInjection";
import { SavePerformShreddingProcedure } from "../../../../../../../useCase/recordRetention/SavePerformShreddingProcedure";

export const usePerformShreddingProcedure = () => {
  const savePerformShreddingProcedure = getService(
    SavePerformShreddingProcedure
  );

  return savePerformShreddingProcedure;
};
