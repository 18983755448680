import { HttpClient } from "infrastructure/service/HttpClient";
import {
  DInject,
  DInjectable
} from "presentation/core/features/dependencyInjection";
import { HouseNumberRepository } from "../../../domain/service/codelist/HouseNumberRepository";
import { HouseNumberType } from "../../../domain/struct/codelist/HouseNumberType";
import { HouseNumberApiMapper, ApiHouseNumbers } from "./HouseNumberApiMapper";

const URL = "/api/app/v1/codelists/house-number-types";

@DInjectable()
export class HouseNumberApiRepository implements HouseNumberRepository {
  constructor(@DInject(HttpClient) protected httpClient: HttpClient) {}

  findAll(): Promise<HouseNumberType[]> {
    return this.httpClient
      .fetchWithThrow<ApiHouseNumbers[]>(URL)
      .then(HouseNumberApiMapper.mapToDomain);
  }
}
