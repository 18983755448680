import { ApiTypes } from "../struct/ApiTypes";
import { User } from "../../../domain/struct/user/User";

export type ApiUser = ApiTypes["ISFG.SpisUm.ClientSide.Models.User.Response.UserResponse"];

export class UserApiMapper {
  static mapToDomain(apiUser: ApiUser): User {
    return {
      id: apiUser.id!,
      mainGroup: apiUser.mainGroup!,
      displayName: `${apiUser.firstName} ${apiUser.lastName}`
    };
  }
}
