import { RentedDocumentRepository } from "../../../domain/service/repository/RentedDocumentRepository";
import {
  DInject,
  DInjectable
} from "../../../presentation/core/features/dependencyInjection";
import {
  PaginatedResult,
  PaginationConfig
} from "../../../lib/contract/Pagination";
import { RentedDocument } from "../../../domain/struct/repository/RentedDocument";
import { HttpClient } from "../../service/HttpClient";
import {
  ApiRepositoryBorrowedFilesViewResponse,
  RentedDocumentApiMapper
} from "./RentedDocumentApiMapper";
import { ApiUrls } from "../struct/ApiTypesV2";

const URL: ApiUrls = {
  GetRepositoryBorrowedFiles: "/api/app/v2/repository/borrowed"
};

@DInjectable()
export class RentedDocumentApiRepository implements RentedDocumentRepository {
  constructor(@DInject(HttpClient) protected httpClient: HttpClient) {}

  getRentedDocuments(
    pagination: PaginationConfig
  ): Promise<PaginatedResult<RentedDocument>> {
    return this.httpClient
      .fetchPaginated<ApiRepositoryBorrowedFilesViewResponse>({
        url: URL.GetRepositoryBorrowedFiles,
        pagination
      })
      .then(RentedDocumentApiMapper.mapListToDomain);
  }
}
