import { SortingConfig } from "@isfg/lib/build/contract/Sorting";
import { HttpClient, HttpMethod } from "infrastructure/service/HttpClient";
import { OverViewsForSignature } from "../types/OverViewsForSignature";
import { PaginatedResult, PaginationConfig } from "lib/contract/Pagination";
import { ApiUrls } from "../../../../../../../infrastructure/api/struct/ApiTypesV2";
import { Mappers } from "./mappers";

export interface PostWithdrawApplicationForSignatureSealParams {
  nodeId: string;
  reason: string;
}

const URL: ApiUrls = {
  GetOverviewsForSignature: "/api/app/v2/overview/for-signature",
  PostWithdrawApplicationForSignature:
    "/api/app/v2/overview/document/{nodeId}/withdrawal"
};

export const getOverviewsForSignature = (
  pagination: PaginationConfig,
  sorting?: SortingConfig
): Promise<PaginatedResult<OverViewsForSignature>> => {
  const httpClient = new HttpClient();
  return httpClient
    .fetchPaginated<OverViewsForSignature>({
      url: URL.GetOverviewsForSignature,
      pagination,
      sorting
    })
    .then(Mappers.mapListToDomain);
};

export const postWithdrawApplicationForSignatureSeal = (
  params: PostWithdrawApplicationForSignatureSealParams
) => {
  const { nodeId, ...rest } = params;
  const httpClient = new HttpClient();
  return httpClient.fetchWithThrow<PostWithdrawApplicationForSignatureSealParams>(
    URL.PostWithdrawApplicationForSignature,
    HttpMethod.Post,
    {
      bodyJSON: rest,
      urlWildCards: { nodeId }
    }
  );
};
