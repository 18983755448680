import { ApiListResponseMapper } from "../mapper/ApiListResponseMapper";
import { ApiListResponse, ApiTypes } from "../struct/ApiTypesV2";
import { History } from "domain/struct/history/History";
import { stringOrDefault } from "lib/string";

export type ApiHistory = ApiTypes["ISFG.SpisUm.ClientSide.Models.V2.Common.TransactionHistory"];

export class HistoryApiMapper {
  static mapListToDomain(list: ApiListResponse<ApiHistory>) {
    return ApiListResponseMapper.mapListToPagination(
      list,
      HistoryApiMapper.mapToDomain
    );
  }

  static mapToDomain(history: ApiHistory): History {
    return {
      id: history.id,
      pid: "" + history.pid,
      occurredAt: stringOrDefault(history.occuredAt),
      description: stringOrDefault(history.description),
      userId: stringOrDefault(history.userId),
      eventType: stringOrDefault(history.eventType)
    };
  }
}
