import { FormHelperText } from "@mui/material";
import styled from "presentation/styles";

export const StyledEditorDiv = styled("div")<{}>(() => ({
  display: "flex",
  flexBasis: "100%",
  flexDirection: "column"
}));

export const StyledWarningHelperText = styled(FormHelperText)<{}>(() => ({
  bottom: "-15px !important",
  color: "#f44336"
}));
