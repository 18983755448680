import {
  lastPathMember,
  translationPath
} from "presentation/share/utils/getPath";
import { validateErrors } from "presentation/share/utils/validation";
import { lang, t } from "presentation/translation/i18n";
import * as yup from "yup";
import { UserFormValuesProxy } from "./_types";
import { User } from "../../../../../domain/struct/administration/User";

export const getValidationMethod = (isUserEdit: boolean) => {
  return isUserEdit ? userEditValidation : userCreateValidation;
};

const defaultValidationSchema = {
  [lastPathMember(UserFormValuesProxy.firstName).path]: yup
    .string()
    .trim()
    .min(3, t(translationPath(lang.dialog.errors.minLen), { len: 3 }))
    .required(t(translationPath(lang._validations.required))),
  [lastPathMember(UserFormValuesProxy.lastName).path]: yup
    .string()
    .trim()
    .required(t(translationPath(lang._validations.required))),
  [lastPathMember(UserFormValuesProxy.email).path]: yup
    .string()
    .trim()
    .email(t(translationPath(lang.dialog.errors.invalidEmailFormat)))
    .required(t(translationPath(lang._validations.required))),
  [lastPathMember(UserFormValuesProxy.mainGroup).path]: yup
    .string()
    .trim()
    .required(t(translationPath(lang._validations.required))),
  [lastPathMember(UserFormValuesProxy.lastName).path]: yup
    .string()
    .trim()
    .required(t(translationPath(lang._validations.required))),
  [lastPathMember(UserFormValuesProxy.degreeBefore).path]: yup
    .string()
    .trim()
    .max(20, t(translationPath(lang.dialog.errors.maxLen), { len: 20 })),
  [lastPathMember(UserFormValuesProxy.degreeAfter).path]: yup
    .string()
    .trim()
    .max(20, t(translationPath(lang.dialog.errors.maxLen), { len: 20 })),
  [lastPathMember(UserFormValuesProxy.userJob).path]: yup
    .string()
    .trim()
    .required(t(translationPath(lang._validations.required)))
};

const createUserValidationSchema = {
  ...defaultValidationSchema,
  [lastPathMember(UserFormValuesProxy.id).path]: yup
    .string()
    .trim()
    .test(
      lastPathMember(UserFormValuesProxy.id).path,
      t(translationPath(lang.dialog.errors.incorrectFormat), {
        val: t(translationPath(lang.general.id))
      }),
      (val) => !!(val && val.match(/^[A-Za-z0-9\-_.]+$/))
    )
    .required(t(translationPath(lang._validations.required)))
};

const editUserValidationSchema = {
  ...defaultValidationSchema
};

export const userCreateValidation = (values: User) =>
  validateErrors(yup.object().shape(createUserValidationSchema), values);

export const userEditValidation = (values: User) =>
  validateErrors(yup.object().shape(editUserValidationSchema), values);
