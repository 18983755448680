import React from "react";

import { PersonTable } from "presentation/modules/nameRegister/tables/PersonTable/PersonTable";
import MenuLayout from "presentation/core/components/layout/MenuLayout";

export const PersonPage = () => (
  <MenuLayout>
    <PersonTable />
  </MenuLayout>
);
