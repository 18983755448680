import { useStyles } from "presentation/core/components/dialog/Dialog.styles";
import { DialogContentType } from "presentation/core/components/dialog/_types";
import React from "react";
import { CreateOrganizationUnitForm } from "./CreateOrganizationUnitForm";
import { CreateOrganizationUnitFormValues } from "./_types";
import { validate } from "./_validations";
import { ChannelForm } from "../ChannelForm/ChannelForm";

export const CreateOrganizationUnitDialog: DialogContentType["content"] = ({
  channel
}) => {
  const classes = useStyles();
  const initialValues: CreateOrganizationUnitFormValues = {
    id: "",
    name: "",
    type: ""
  };

  return (
    <div className={classes.modalSmall}>
      <div className="body-midsize">
        <ChannelForm<CreateOrganizationUnitFormValues>
          initialValues={initialValues}
          validate={validate}
          channel={channel}
          component={CreateOrganizationUnitForm}
        />
      </div>
    </div>
  );
};
