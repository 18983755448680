import { DocumentConcernedSubject } from "../../../../../domain/struct/document/DocumentConcernedSubject";
import { isPerson } from "../../../../../domain/struct/nameRegistry/Person";
import { SubjectType } from "../../../../../domain/struct/nameRegistry/SubjectType";
import { isLegalEntity } from "../../../../../domain/struct/nameRegistry/LegalEntity";
import { ConcernedSubjectTableProps } from "./ConcernedSubjectTable";
import { useModal } from "../../../../designSystem/Modal/useModal";
import { SubjectModal } from "../../../../modules/nameRegister/modal/SubjectModal/SubjectModal";
import { useMutation } from "../../../hook/query/useQM";
import { Modal } from "../../../../designSystem/Modal/Modal";
import { SearchSubjectModal } from "../../../../modules/nameRegister/modal/SearchSubjectModal/SearchSubjectModal";
import { getErrorCodeTranslation } from "../../../utils/errorCodeTranslation";
import { lang, t } from "../../../../translation/i18n";
import { translationPath } from "../../../utils/getPath";
import { useRemoteTableApiContext } from "presentation/designSystem/Table/contexts/RemoteTableApiContextProvider";

export const useLogic = ({
  readonly,
  addConcernedSubjects,
  removeConcernedSubjects
}: ConcernedSubjectTableProps) => {
  const { refreshData, setTableApi } = useRemoteTableApiContext();

  const [subjectDetailModal, subjectDetailModalApi] = useModal(SubjectModal);

  const [searchSubjectModal, searchSubjectModalApi] = useModal(
    SearchSubjectModal,
    {
      onOk(result) {
        const { id } = result?.subject || {};

        if (id) {
          return triggerAddConcernedSubjects([id]);
        }

        return undefined;
      }
    }
  );

  const { mutate: triggerAddConcernedSubjects } = useMutation(
    addConcernedSubjects,
    {
      onErrorNotification: (error: any) => ({
        message: getErrorCodeTranslation(error?.code)
      })
    }
  );
  const { mutate: triggerRemoveConcernedSubjects } = useMutation(
    removeConcernedSubjects
  );

  const onShowDetail = (selected: DocumentConcernedSubject[]) => {
    const { subject } = selected[0];

    if (isPerson(subject)) {
      return subjectDetailModalApi.open({
        type: SubjectType.Person,
        person: subject
      });
    } else if (isLegalEntity(subject)) {
      return subjectDetailModalApi.open({
        type: SubjectType.LegalEntity,
        legalEntity: subject
      });
    }

    return undefined;
  };

  const onAddConcernedSubjects = () =>
    searchSubjectModalApi.open({
      returnOnlySubject: true
    });

  const onRemoveConcernedSubjects = (selected: DocumentConcernedSubject[]) => {
    return Modal.confirm({
      title: t(
        translationPath(lang.dialog.title.confirmationDocumentConcernedSubject)
      ),
      content: t(
        translationPath(
          lang.dialog.content.confirmationDocumentConcernedSubject
        )
      ),
      onOk() {
        return triggerRemoveConcernedSubjects(
          selected.map((s) => s.subject?.id || ""),
          {
            onSuccess: () => {
              refreshData();
            }
          }
        );
      }
    }).promise;
  };

  return {
    onShowDetail,
    onAddConcernedSubjects,
    onRemoveConcernedSubjects,
    modal: [subjectDetailModal, searchSubjectModal],
    refreshData,
    setTableApi
  };
};
