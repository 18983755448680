import { ApiListResponse, ApiTypes } from "../struct/ApiTypesV2";
import { ApiListResponseMapper } from "../mapper/ApiListResponseMapper";
import { stringOrDefault } from "../../../lib/string";
import { RetentionProtocolsErased } from "../../../domain/struct/recordRetention/RetentionProtocolsErased";
import { RetentionProtocolOrProposalOrErased } from "../../../domain/struct/recordRetention/RetentionProtocolOrProposalOrErased";
import { RetentionArchivedOrDestroyed } from "../../../domain/struct/recordRetention/RetentionArchivedOrDestroyed";

export type ApiRepositoryRetentionProtocolsErasedViewResponse = ApiTypes["ISFG.SpisUm.ClientSide.Models.V2.Shredding.Response.ShreddingProtocolErasedViewResponse"];
export type ApiRepositoryRetentionErasedProtocolViewResponseOne = ApiTypes["ISFG.SpisUm.ClientSide.Models.V2.Shredding.Response.ShreddingProposalProtocolDetailResponse"];
export type ApiRepositoryRetentionArchivedOrDestroyedViewResponse = ApiTypes["ISFG.SpisUm.ClientSide.Models.V2.Shredding.Response.ShreddingErasedObjectsResponse"];

export class RetentionProtocolsErasedApiMapper {
  static mapListToDomain(
    response: ApiListResponse<ApiRepositoryRetentionProtocolsErasedViewResponse>
  ) {
    return ApiListResponseMapper.mapListToPagination(
      response,
      RetentionProtocolsErasedApiMapper.mapToDomain
    );
  }
  static mapArchivedOrDestroyedListToDomain(
    response: ApiListResponse<ApiRepositoryRetentionArchivedOrDestroyedViewResponse>
  ) {
    return ApiListResponseMapper.mapListToPagination(
      response,
      RetentionProtocolsErasedApiMapper.mapToDomainArchivedOrDestroyed
    );
  }

  static mapToDomain(
    response: ApiRepositoryRetentionProtocolsErasedViewResponse
  ): RetentionProtocolsErased {
    const {
      pid,
      nodeId,
      name,
      createdDate,
      authorFullName,
      shreddingObjectsCount,
      shreddingOperationDateTime,
      reviewerFullName,
      eraseDate
    } = response;
    return {
      nodeId: stringOrDefault(nodeId),
      pid: stringOrDefault(pid),
      name: stringOrDefault(name),
      createdDate: stringOrDefault(createdDate),
      authorFullName: stringOrDefault(authorFullName),
      shreddingObjectsCount,
      shreddingOperationDateTime: stringOrDefault(shreddingOperationDateTime),
      reviewerFullName: stringOrDefault(reviewerFullName),
      eraseDate: stringOrDefault(eraseDate)
    };
  }

  static mapToDomainOne(
    response: ApiRepositoryRetentionErasedProtocolViewResponseOne
  ): RetentionProtocolOrProposalOrErased {
    const {
      pid,
      nodeId,
      name,
      createdDate,
      authorFullName,
      shreddingObjectsCount,
      shreddingOperationDateTime,
      reviewerFullName,
      erasedDate,
      nodeType
    } = response;
    return {
      nodeId: stringOrDefault(nodeId),
      nodeType: stringOrDefault(nodeType),
      pid: stringOrDefault(pid),
      name: stringOrDefault(name),
      createdDate: stringOrDefault(createdDate),
      authorFullName: stringOrDefault(authorFullName),
      shreddingObjectsCount,
      shreddingOperationDateTime: stringOrDefault(shreddingOperationDateTime),
      reviewerFullName: stringOrDefault(reviewerFullName),
      erasedDate: stringOrDefault(erasedDate)
    };
  }

  static mapToDomainArchivedOrDestroyed(
    response: ApiRepositoryRetentionArchivedOrDestroyedViewResponse
  ): RetentionArchivedOrDestroyed {
    const {
      nodeId,
      nodeType,
      pid,
      ssidFileIdent,
      subject,
      filePlan,
      fileMark,
      retentionMode,
      sipForSubmit,
      sipForArchive,
      decisionDa,
      identifierDa
    } = response;
    return {
      nodeId: stringOrDefault(nodeId),
      nodeType: stringOrDefault(nodeType),
      pid: stringOrDefault(pid),
      ssidFileIdent: stringOrDefault(ssidFileIdent),
      subject: stringOrDefault(subject),
      filePlan: stringOrDefault(filePlan),
      fileMark: stringOrDefault(fileMark),
      retentionMode: stringOrDefault(retentionMode),
      sipForSubmit,
      sipForArchive,
      decisionDa: stringOrDefault(decisionDa),
      identifierDa: stringOrDefault(identifierDa)
    };
  }
}
