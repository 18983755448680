import { Chip } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import clsx from "clsx";
import { Form, Formik, FormikHelpers } from "formik";
import { Select, TextField } from "formik-mui";
import { createUserDefaultFormValues } from "presentation/core/api/user/_sagas";
import { MetaFormProps } from "presentation/core/components/MetaForm/_types";
import {
  StyledField,
  StyledFieldId,
  StyledFormControl,
  useStyles
} from "presentation/core/components/dialog/Dialog.styles";
import { BaseField } from "presentation/core/components/form/fields/BaseField";
import FormControlWithError from "presentation/core/components/formControlWithError";
import { RootStateType } from "presentation/reducers";
import { WithTranslation, lang, t } from "presentation/translation/i18n";
import React, { useEffect, useMemo, useState } from "react";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { User } from "../../../../../domain/struct/administration/User";
import { Group } from "../../../../../domain/struct/user/Group";
import { lastPathMember, translationPath } from "../../../utils/getPath";
import { UserFormValuesProxy } from "./_types";
import { getValidationMethod } from "./_validations";
import { useApplicableGroups } from "./useApplicableGroups";
import { useUserGroups } from "./useUserGroups";

/**
 * According to this answer, the "chrome-off" should work:
 * @link https://stackoverflow.com/a/30976223
 */
const AUTOCOMPLETE_OFF_PROPS = { autoComplete: "chrome-off" };

export const CreateUserForm = ({
  initialValues,
  formRef
}: MetaFormProps<User> & WithTranslation) => {
  const classes = useStyles();
  const [isUserEdit, setIsUserEdit] = useState<boolean>(false);
  const [domain, setDomain] = useState<string>("");

  const { data: availableGroupsResponse } = useApplicableGroups();
  const { data: userGroups } = useUserGroups(initialValues.id);

  const availableGroups = availableGroupsResponse || {
    groups: [],
    mainGroups: [],
    signGroups: []
  };

  const stateDomain = useSelector((state: RootStateType) =>
    state.loginReducer.session.domain
      ? "@" + state.loginReducer.session.domain
      : ""
  );
  const onSubmit = (values: User, { setSubmitting }: FormikHelpers<User>) => {
    setSubmitting(false);
  };

  const renderMultipleChips = (groups: Group[]) => (selected: string[]) => (
    <div>
      {selected.map((value) => {
        const group = groups.find((grp) => grp.id === value);
        return (
          <Chip key={value} style={{ margin: 2 }} label={group?.displayName} />
        );
      })}
    </div>
  );

  useEffect(() => {
    setIsUserEdit(!!initialValues.id);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!isUserEdit) {
      setDomain(stateDomain);
    } else {
      setDomain("");
    }
  }, [isUserEdit]); // eslint-disable-line react-hooks/exhaustive-deps

  const formValues = useMemo(() => {
    return {
      ...(initialValues || createUserDefaultFormValues),
      ...userGroups
    };
  }, [initialValues, userGroups]);

  if (!userGroups) {
    return null;
  }

  return (
    <div className={classes.modalBodyFullSize}>
      <div className="body-fullsize">
        <Formik<User>
          initialValues={formValues}
          validate={getValidationMethod(isUserEdit)}
          innerRef={formRef}
          onSubmit={onSubmit}
        >
          {({ values, setFieldValue }) => {
            const onExternalUserChange = (
              event: React.ChangeEvent<HTMLInputElement>
            ) => {
              setFieldValue(
                lastPathMember(UserFormValuesProxy.externalUser).path,
                event.target.checked
              );
            };

            return (
              <Form className={classes.userForm}>
                <StyledFieldId
                  component={TextField}
                  data-test-id="create-user-id"
                  name={lastPathMember(UserFormValuesProxy.id).path}
                  disabled={isUserEdit}
                  required={!isUserEdit}
                  type="text"
                  label={t(translationPath(lang.general.login))}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {isUserEdit ? "" : domain}
                      </InputAdornment>
                    ),
                    ...AUTOCOMPLETE_OFF_PROPS
                  }}
                />
                <span style={{ marginLeft: "5.3%" }}></span>
                <StyledField
                  component={TextField}
                  data-test-id="create-user-email"
                  name={lastPathMember(UserFormValuesProxy.email).path}
                  required={true}
                  type="text"
                  label={t(translationPath(lang.general.email))}
                  InputProps={AUTOCOMPLETE_OFF_PROPS}
                />

                <div
                  className={clsx(
                    classes.fullWidth,
                    classes.alignItemsEnd,
                    classes.form
                  )}
                >
                  <StyledField
                    component={TextField}
                    data-test-id="create-user-userOrgId"
                    name={lastPathMember(UserFormValuesProxy.degreeBefore).path}
                    type="text"
                    label={t(translationPath(lang.general.titleBeforeName))}
                  />
                  <StyledField
                    component={TextField}
                    data-test-id="create-user-firstName"
                    name={lastPathMember(UserFormValuesProxy.firstName).path}
                    required={true}
                    type="text"
                    label={t(translationPath(lang.general.firstName))}
                  />
                  <StyledField
                    component={TextField}
                    data-test-id="create-user-lastName"
                    name={lastPathMember(UserFormValuesProxy.lastName).path}
                    type="text"
                    required={true}
                    label={t(translationPath(lang.general.lastName))}
                  />
                  <StyledField
                    component={TextField}
                    data-test-id="create-user-userOrgName"
                    name={lastPathMember(UserFormValuesProxy.degreeAfter).path}
                    type="text"
                    label={t(translationPath(lang.general.titleAfterName))}
                  />
                </div>
                <div
                  className={clsx(
                    classes.fullWidth,
                    classes.alignItemsEnd,
                    classes.form
                  )}
                >
                  <StyledField
                    component={TextField}
                    data-test-id="create-user-userJob"
                    name={lastPathMember(UserFormValuesProxy.userJob).path}
                    type="text"
                    required={true}
                    label={t(translationPath(lang.general.senderJob))}
                  />
                  <FormControlWithError
                    name={lastPathMember(UserFormValuesProxy.groups).path}
                    component={StyledFormControl}
                  >
                    <InputLabel
                      htmlFor={lastPathMember(UserFormValuesProxy.groups).path}
                    >
                      {t(
                        translationPath(lang.dialog.handover.organizationlUnit)
                      )}
                    </InputLabel>
                    <BaseField
                      className={classes.multiSelectAutoGrow}
                      component={Select}
                      data-test-id="create-user-groups"
                      name={lastPathMember(UserFormValuesProxy.groups).path}
                      inputProps={{
                        id: lastPathMember(UserFormValuesProxy.groups).path
                      }}
                      multiple={true}
                      renderValue={renderMultipleChips(availableGroups.groups)}
                    >
                      {availableGroups.groups.map(({ id, displayName }) => (
                        <MenuItem
                          className={classes.selectedItem}
                          key={id}
                          value={id}
                        >
                          {displayName}
                        </MenuItem>
                      ))}
                    </BaseField>
                  </FormControlWithError>

                  <FormControlWithError
                    name={lastPathMember(UserFormValuesProxy.mainGroup).path}
                    component={StyledFormControl}
                  >
                    <InputLabel
                      htmlFor={
                        lastPathMember(UserFormValuesProxy.mainGroup).path
                      }
                      classes={{
                        root: values.mainGroup
                          ? ""
                          : "MuiFormLabel-root--long-text"
                      }}
                      style={{ maxWidth: values.mainGroup ? "initial" : 175 }}
                      required={true}
                    >
                      {t(translationPath(lang.general.mainGroup))}
                    </InputLabel>
                    <BaseField
                      component={Select}
                      data-test-id="create-user-mainGroup"
                      name={lastPathMember(UserFormValuesProxy.mainGroup).path}
                      inputProps={{
                        id: lastPathMember(UserFormValuesProxy.mainGroup).path
                      }}
                    >
                      {availableGroups.mainGroups.map(({ id, displayName }) => (
                        <MenuItem key={id} value={id}>
                          {displayName}
                        </MenuItem>
                      ))}
                    </BaseField>
                  </FormControlWithError>

                  <FormControlWithError
                    name={lastPathMember(UserFormValuesProxy.signGroups).path}
                    component={StyledFormControl}
                  >
                    <InputLabel
                      htmlFor={
                        lastPathMember(UserFormValuesProxy.signGroups).path
                      }
                      classes={{
                        root: values?.signGroups?.length
                          ? ""
                          : "MuiFormLabel-root--long-text"
                      }}
                      style={{
                        maxWidth: values?.signGroups?.length ? "initial" : 160
                      }}
                    >
                      {t(translationPath(lang.general.signInOrgGroup))}
                    </InputLabel>
                    <BaseField
                      className={classes.multiSelectAutoGrow}
                      component={Select}
                      data-test-id="create-user-signGroups"
                      name={lastPathMember(UserFormValuesProxy.signGroups).path}
                      inputProps={{
                        id: lastPathMember(UserFormValuesProxy.signGroups).path
                      }}
                      multiple={true}
                      renderValue={renderMultipleChips(
                        availableGroups.signGroups
                      )}
                      disabled={!availableGroups.groups.length}
                    >
                      {availableGroups.signGroups.map(({ id, displayName }) => (
                        <MenuItem
                          className={classes.selectedItem}
                          key={id}
                          value={id}
                        >
                          {displayName}
                        </MenuItem>
                      ))}
                    </BaseField>
                  </FormControlWithError>
                </div>
                <div style={{ marginTop: "3rem" }}>
                  <FormControlLabel
                    className={""}
                    control={
                      <Checkbox
                        checked={values.externalUser}
                        onChange={onExternalUserChange}
                        name={
                          lastPathMember(UserFormValuesProxy.externalUser).path
                        }
                      />
                    }
                    label={t(translationPath(lang.general.externalUser))}
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export const CreateUserMetadataForm = withTranslation()(
  React.memo(CreateUserForm)
);
