import { HelpOutline } from "@mui/icons-material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Tooltip } from "@mui/material";
import { Form } from "formik";
import { TextField } from "formik-mui";
import {
  StyledFieldWide,
  useStyles
} from "presentation/core/components/dialog/Dialog.styles";
import {
  lastPathMember,
  translationPath
} from "presentation/share/utils/getPath";
import { t } from "presentation/translation/i18n";
import lang from "presentation/translation/lang";
import React from "react";
import {
  StyledVisibilityIconButton,
  StyledVisibilityIconButtonIeFix
} from "../../../../core/features/login/FormRenderer.form.styles";
import { ChangePasswordFormValuesProxy } from "./_types";

export const ChangePasswordForm = React.memo(() => {
  const classes = useStyles();

  const [showPasswordOld, setShowPasswordOld] = React.useState({
    showPasswordOld: false
  });
  const [showPasswordNew, setShowPasswordNew] = React.useState({
    showPasswordNew: false
  });
  const [showPasswordNewConfirm, setShowPasswordNewConfirm] = React.useState({
    showPasswordNewConfirm: false
  });

  const handleClickShowPasswordOld = () => {
    setShowPasswordOld({
      showPasswordOld: !showPasswordOld.showPasswordOld
    });
  };
  const handleClickShowPasswordNew = () => {
    setShowPasswordNew({
      showPasswordNew: !showPasswordNew.showPasswordNew
    });
  };
  const handleClickShowPasswordNewConfirm = () => {
    setShowPasswordNewConfirm({
      showPasswordNewConfirm: !showPasswordNewConfirm.showPasswordNewConfirm
    });
  };

  return (
    <Form className={classes.form}>
      <StyledFieldWide
        component={TextField}
        data-test-id="meta-input-oldPassword"
        label={t(translationPath(lang.general.oldPassword))}
        name={lastPathMember(ChangePasswordFormValuesProxy.oldPassword).path}
        required={true}
        type={showPasswordOld.showPasswordOld ? "text" : "password"}
        InputProps={{
          autoComplete: "",
          endAdornment: (
            <StyledVisibilityIconButtonIeFix position="end">
              <StyledVisibilityIconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPasswordOld}
              >
                {showPasswordOld.showPasswordOld ? (
                  <Visibility />
                ) : (
                  <VisibilityOff />
                )}
              </StyledVisibilityIconButton>
            </StyledVisibilityIconButtonIeFix>
          )
        }}
      />

      <StyledFieldWide
        className={classes.mtGap}
        component={TextField}
        data-test-id="meta-input-newPassword"
        label={t(translationPath(lang.general.newPassword))}
        name={lastPathMember(ChangePasswordFormValuesProxy.newPassword).path}
        required={true}
        type={showPasswordNew.showPasswordNew ? "text" : "password"}
        InputProps={{
          autoComplete: "",
          endAdornment: (
            <StyledVisibilityIconButtonIeFix position="end">
              <Tooltip
                title={t(
                  translationPath(
                    lang.dialog.notifications.passwordComplexityRequired
                  )
                )}
                placement="top"
              >
                <HelpOutline />
              </Tooltip>
              <StyledVisibilityIconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPasswordNew}
              >
                {showPasswordNew.showPasswordNew ? (
                  <Visibility />
                ) : (
                  <VisibilityOff />
                )}
              </StyledVisibilityIconButton>
            </StyledVisibilityIconButtonIeFix>
          )
        }}
      />

      <StyledFieldWide
        component={TextField}
        data-test-id="meta-input-newPassword2"
        label={t(translationPath(lang.general.newPassword2))}
        name={lastPathMember(ChangePasswordFormValuesProxy.newPassword2).path}
        required={true}
        type={
          showPasswordNewConfirm.showPasswordNewConfirm ? "text" : "password"
        }
        InputProps={{
          autoComplete: "",
          endAdornment: (
            <StyledVisibilityIconButtonIeFix position="end">
              <Tooltip
                title={t(
                  translationPath(
                    lang.dialog.notifications.passwordComplexityRequired
                  )
                )}
                placement="top"
              >
                <HelpOutline />
              </Tooltip>
              <StyledVisibilityIconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPasswordNewConfirm}
              >
                {showPasswordNewConfirm.showPasswordNewConfirm ? (
                  <Visibility />
                ) : (
                  <VisibilityOff />
                )}
              </StyledVisibilityIconButton>
            </StyledVisibilityIconButtonIeFix>
          )
        }}
      />
    </Form>
  );
});
