import { getService } from "presentation/core/features/dependencyInjection";
import { PaginationConfig } from "../../../../lib/contract/Pagination";
import { useCallback } from "react";
import { FindConcernedContent } from "../../../../useCase/nameRegistry/concernedContent/FindConcernedContent";

export const useFindConcernedContent = (id: string) => {
  const findUseCase = getService(FindConcernedContent);

  return useCallback(
    (config: PaginationConfig) => findUseCase.findAllPaginated(id, config),
    [findUseCase, id]
  );
};
