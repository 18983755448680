import {
  DInject,
  DInjectable
} from "presentation/core/features/dependencyInjection";
import { ApiUrls } from "infrastructure/api/struct/ApiTypesV2";
import { HttpClient, HttpMethod } from "infrastructure/service/HttpClient";
import { ResetPasswordService } from "domain/service/authentication/ResetPasswordService";

const URL: ApiUrls = {
  ResetPassword: "/api/app/v2/reset-password/request",
  ValidatePassword: "/api/app/v2/reset-password/validate",
  ChangePassword: "/api/app/v2/reset-password"
};

@DInjectable()
export class PasswordApiRepository implements ResetPasswordService {
  constructor(@DInject(HttpClient) protected httpClient: HttpClient) {}
  requestPasswordReset(userId: string): Promise<void> {
    return this.httpClient.fetchWithThrow(URL.ResetPassword, HttpMethod.Post, {
      bodyJSON: {
        userId
      }
    });
  }
  validatePasswordReset(token: string): Promise<void> {
    return this.httpClient.fetchWithThrow(
      URL.ValidatePassword,
      HttpMethod.Post,
      {
        bodyJSON: {
          token
        }
      }
    );
  }
  changePassword(token: string, password: string): Promise<void> {
    return this.httpClient.fetchWithThrow(URL.ChangePassword, HttpMethod.Post, {
      bodyJSON: {
        token,
        password
      }
    });
  }
}
