import { UpdateDocument } from "../hooks/useUpdateDocument";
import { UpdateAnalogFormFieldTypes } from "../types/types";

export const mapDocumentToApi = (
  input: UpdateAnalogFormFieldTypes
): UpdateDocument => {
  return {
    attachmentsCount: input.attachmentsCount,
    deliveryDate: input.deliveryDate?.toISOString(),
    attachmentsType: input.attachmentsType,
    deliveryMode: input.deliveryMode,
    senderAddressId: input.senderAddressId,
    senderContactDataboxId: input.senderContactDataboxId,
    senderContactEmailId: input.senderContactEmailId,
    senderContactPhoneId: input.senderContactPhoneId,
    senderId: input.senderId ? Number(input.senderId) : undefined,
    senderIdent: input.senderIdent,
    senderSsid: input.senderSsid,
    settleToDate: input.settleToDate?.toISOString(),
    subject: input.subject,
    listCount: input.listCount ? Number(input.listCount) : undefined,
    listCountAttachments: input.listCountAttachments
      ? Number(input.listCountAttachments)
      : undefined,
    senderCompanyEmployeeUnit: input.senderCompanyEmployeeUnit,
    senderCompanyEmployeeJob: input.senderCompanyEmployeeJob,
    senderCompanyEmployeeName: input.senderCompanyEmployeeName
  };
};
