import { callAsyncAction } from "presentation/core/action";
import { createRetentionProposalAction } from "presentation/core/api/retention/_actions";
import { secondaryAction } from "presentation/core/components/dialog/lib/actionsFactory";
import {
  DialogContentType,
  DialogType
} from "presentation/core/components/dialog/_types";
import { documentViewAction__Refresh } from "presentation/core/components/documentView/_actions";
import NamedTitle from "presentation/core/components/namedTitle";
import { GenericDocument } from "presentation/core/types";
import React from "react";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import { CreateRetentionProposalDialog } from "./CreateRetentionProposalDialog";
import { RetentionProposalValues } from "./_types";
import { ModalSize } from "presentation/designSystem/Modal/Modal";

export const createRetentionProposalDialogContent: DialogContentType = {
  actions: () => [
    secondaryAction(
      t(translationPath(lang.dialog.form.confirm)),
      ({ dispatch, channels, dialogProps, onClose, buttonState }) => {
        const ids = (dialogProps.data as GenericDocument[]).map(
          (selected) => selected?.id
        );

        if (!ids.length) return;

        const onSuccess = () => {
          onClose();
          dispatch(documentViewAction__Refresh(true));
          buttonState.setIsPending(false);
        };

        const onError = () => {
          buttonState.setIsPending(false);
        };

        buttonState.setIsPending(true);

        const formValues = channels.contentTab?.state
          ?.formValues as RetentionProposalValues;

        dispatch(
          callAsyncAction({
            action: createRetentionProposalAction,
            onError,
            onSuccess,
            onErrorNotification: (error) => {
              return {
                message: error.message
              };
            },
            payload: {
              ids,
              name: formValues.name
            }
          })
        );
      }
    )
  ],
  content: CreateRetentionProposalDialog,
  title: (props) => (
    <NamedTitle
      text={t(translationPath(lang.general.createShreddingProposal))}
      {...props}
    />
  ),
  type: DialogType.CreateRetentionProposal,
  size: ModalSize.Small
};
