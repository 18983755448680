import { makeStyles } from "@mui/styles";

export const useStyles: Function = makeStyles((theme: any) => ({
  root: {
    alignItems: "center",
    display: "flex"
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative"
  }
}));
