import { TableCell } from "@mui/material";
import { ColumnProps } from "./Column";
import React from "react";

export function HtmlColumn<Row, ColumnValue>({
  columnIndex,
  columnValue
}: ColumnProps<Row, ColumnValue>) {
  return (
    <TableCell
      key={columnIndex}
      dangerouslySetInnerHTML={{ __html: columnValue as unknown as string }}
    />
  );
}
