import { secondaryAction } from "presentation/core/components/dialog/lib/actionsFactory";
import {
  DialogContentType,
  DialogType
} from "presentation/core/components/dialog/_types";
import NamedTitle from "presentation/core/components/namedTitle";
import { Notification } from "presentation/designSystem/notification/Notification";
import { setActiveGroupAction } from "presentation/core/features/login/_actions";
import React from "react";
import { lang, t } from "presentation/translation/i18n";
import { translationPath } from "../../../utils/getPath";
import { GroupChangeContentDialog } from "./GroupChangeContentDialog";
import { GroupChangeFormValuesType } from "./_types";
import { ModalSize } from "../../../../designSystem/Modal/Modal";
import { documentViewAction__Refresh } from "../../../../core/components/documentView/_actions";

export const groupChangeDialogContent: DialogContentType = {
  actions: () => [
    secondaryAction(
      t(translationPath(lang.dialog.buttons.confirm)),
      ({ channels, dispatch, onClose, buttonState }) => {
        onClose();
        buttonState.setIsPending(true);

        const activeGroupId = (channels?.contentTab?.state
          ?.formValues as GroupChangeFormValuesType)?.activeGroup;

        dispatch(setActiveGroupAction(activeGroupId));
        dispatch(documentViewAction__Refresh(true));
        Notification.success({
          message: t(translationPath(lang.dialog.notifications.actionSucceeded))
        });
      }
    )
  ],
  content: GroupChangeContentDialog,
  title: (props) => (
    <NamedTitle
      text={t(translationPath(lang.general.changeGroup))}
      {...props}
    />
  ),
  type: DialogType.GroupChange,
  size: ModalSize.Small
};
