import React, { useEffect } from "react";
import { RemoteTable as RemoteTableOriginal } from "@isfg/react-components/build/Table/RemoteTable";
import { useRefreshTableOnNewNotification } from "./hook/useRefreshTableOnNewNotification";
import { useRemoteTableApi } from "./hook/useRemoteTableApi";
import { documentViewAction__Refresh } from "../../core/components/documentView/_actions";
import { useDispatch, useSelector } from "react-redux";
import { RootStateType } from "../../reducers";

export * from "@isfg/react-components/build/Table/RemoteTable";

export const RemoteTable: typeof RemoteTableOriginal = (props) => {
  const [remoteTableApi, setRemoteTableApi] = useRemoteTableApi();
  const shouldRefreshTable = useSelector(
    (state: RootStateType) => state.documentViewReducer.shouldRefreshTable
  );
  const dispatch = useDispatch();

  useRefreshTableOnNewNotification(remoteTableApi);

  useEffect(() => {
    props.onRemoteTableApi?.(remoteTableApi);
  }, [props, remoteTableApi]);

  useEffect(
    () => {
      if (shouldRefreshTable) {
        remoteTableApi.refetch();
        dispatch(documentViewAction__Refresh(false));
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [shouldRefreshTable]
  );

  return (
    <RemoteTableOriginal {...props} onRemoteTableApi={setRemoteTableApi} />
  );
};
