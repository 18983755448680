import { getService } from "../../../../core/features/dependencyInjection";
import { FindGroup } from "../../../../../useCase/user/FindGroup";
import { useQM } from "../../../hook/query/useQM";

const KEY = "user/applicableGroupBag";

export const useApplicableGroups = () => {
  const findGroup = getService(FindGroup);

  return useQM(KEY, () => findGroup.getApplicableGroupsForUser());
};
