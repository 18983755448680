import { ReloadOutlined } from "@ant-design/icons";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import React from "react";
import styled from "styled-components";
import { IconButton } from "../Button/IconButton";
import { Modal, ModalProps, ModalWithTabs } from "./Modal";

const StyledTitleWrapper = styled("div")({
  display: "flex",
  position: "relative"
});

const StyledTitleActionsWrapper = styled("div")(
  ({ theme }: { theme: any }) => ({
    position: "absolute",
    right: theme.margin.lg,
    top: -theme.margin.xss,
    // buttons are close to the "close" modal button, sometimes when an user
    // clicks the nearest button to the "close" modal button, it closes the dialog.
    // So we need to provide bigger z-index to avoid this situation
    zIndex: 11
  })
);

interface ModalWithRefreshProps extends ModalProps {
  title: string;
  isShowRefresh?: boolean;
  hasTabs?: boolean;
  onRefresh?(): void;
}

export const ModalWithRefresh: React.FC<ModalWithRefreshProps> = ({
  title,
  children,
  hasTabs = false,
  onRefresh,
  ...props
}) => {
  const ModalComponent = hasTabs ? ModalWithTabs : Modal;

  return (
    <ModalComponent
      {...props}
      title={
        onRefresh ? (
          <StyledTitleWrapper>
            {title}
            <StyledTitleActionsWrapper>
              <IconButton
                icon={<ReloadOutlined rev={"default"} />}
                tooltip={t(translationPath(lang.dialog.form.refresh))}
                onClick={onRefresh}
                style={{ top: "-1px" }}
              />
            </StyledTitleActionsWrapper>
          </StyledTitleWrapper>
        ) : (
          title
        )
      }
    >
      {children}
    </ModalComponent>
  );
};
