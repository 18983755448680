import { GroupInfo } from "./group";
import { PresentationModules } from "../entities/PresentationModule";
import { UserRepositoryRights } from "../../share/utils/user";
import { DefineAbilityFn } from "./abilityType";

interface Opts {
  isAdmin: boolean;
  activeGroup?: string;
  groupInfo: GroupInfo;
}
// This function defines which presentation modules can an user see
export const definePresentationModuleAbility: DefineAbilityFn<Opts> = (
  allow,
  forbid,
  { isAdmin, groupInfo }
) => {
  const {
    groupIsDispatch,
    groupIsRepository,
    groupIsNameRegister,
    groupIsMailroom,
    userCanSignInThisGroup,
    userCanSealInThisGroup,
    userRepositoryRights
  } = groupInfo;

  const allowedPresentationModules = isAdmin
    ? [PresentationModules.Administration]
    : [
        groupIsNameRegister && PresentationModules.NameRegister,
        groupIsMailroom && PresentationModules.MailRoom,
        !groupIsNameRegister &&
          !groupIsDispatch &&
          !groupIsRepository &&
          PresentationModules.Evidence,
        groupIsDispatch && PresentationModules.Dispatch,
        groupIsRepository && PresentationModules.Repository,
        userCanSignInThisGroup && PresentationModules.SignatureBook,
        userCanSealInThisGroup && PresentationModules.SignatureBook,
        userRepositoryRights !== UserRepositoryRights.None &&
          PresentationModules.RetentionProcess
      ].filter(Boolean);

  allow("view", "PresentationModule", {
    name: {
      $in: allowedPresentationModules
    }
  });
};
