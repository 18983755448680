import { getService } from "presentation/core/features/dependencyInjection";
import { FindHistory } from "../../../../useCase/nameRegistry/history/FindHistory";
import { PaginationConfig } from "../../../../lib/contract/Pagination";
import { useCallback } from "react";
import { SortingConfig } from "lib/contract/Sorting";

export const useFindHistory = (id: string) => {
  const findUseCase = getService(FindHistory);

  return useCallback(
    (config: PaginationConfig, sorting?: SortingConfig) =>
      findUseCase.findAllPaginated(id, config, sorting),
    [findUseCase, id]
  );
};
