import {
  DInject,
  DInjectable
} from "../../../presentation/core/features/dependencyInjection";
import {
  PaginatedResult,
  PaginationConfig
} from "../../../lib/contract/Pagination";
import { HttpClient } from "../../service/HttpClient";
import { ApiRepositoryBorrowedFilesViewResponse } from "../repository/RentedDocumentApiMapper";
import { ApiUrls } from "../struct/ApiTypesV2";
import { RentedEvidenceDocumentRepository } from "../../../domain/service/evidence/RentedEvidenceDocumentRepository";
import { RentedEvidenceDocument } from "../../../domain/struct/evidence/RentedEvidenceDocument";
import { RentedEvidenceDocumentApiMapper } from "./RentedEvidenceDocumentApiMapper";

const URL: ApiUrls = {
  GetBorrowedOverview: "/api/app/v2/overview/borrowed"
};

@DInjectable()
export class RentedEvidenceDocumentApiRepository
  implements RentedEvidenceDocumentRepository {
  constructor(@DInject(HttpClient) protected httpClient: HttpClient) {}

  getRentedDocuments(
    pagination: PaginationConfig
  ): Promise<PaginatedResult<RentedEvidenceDocument>> {
    return this.httpClient
      .fetchPaginated<ApiRepositoryBorrowedFilesViewResponse>({
        url: URL.GetBorrowedOverview,
        pagination
      })
      .then(RentedEvidenceDocumentApiMapper.mapListToDomain);
  }
}
