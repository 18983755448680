import {
  createSafeAction,
  createSafeAsyncAction
} from "presentation/share/utils/typesafeActions";
import { ErrorType } from "presentation/types";
import { ActionType } from "typesafe-actions";
import {
  AllGroupType,
  FilePlan,
  LoginRequestType,
  LoginResponseType,
  LoginSetSessionType,
  SslPathsType,
  UserGroupType
} from "./_types";
import { User } from "../../../../domain/struct/user/User";

export const allGroupsAction = createSafeAsyncAction(
  "@allGroupAction/REQUEST",
  "@allGroupAction/SUCESS",
  "@allGroupAction/FAILURE"
)<void, AllGroupType, ErrorType>();

export const FilePlanAction = createSafeAsyncAction(
  "@codeLists/FILE_PLANS_REQUEST",
  "@codeLists/FILE_PLANS_SUCESS",
  "@codeLists/FILE_PLANS_FAILURE"
)<void, FilePlan[], ErrorType>();

export const AdminFilePlanAction = createSafeAsyncAction(
  "@codeLists/A_FILE_PLANS_REQUEST",
  "@codeLists/A_FILE_PLANS_SUCESS",
  "@codeLists/A_FILE_PLANS_FAILURE"
)<void, FilePlan[], ErrorType>();

export const loginAction = createSafeAsyncAction(
  "@login/LOGIN_ACTION_REQUEST",
  "@login/LOGIN_ACTION_SUCESS",
  "@login/LOGIN_ACTION_FAILURE"
)<LoginRequestType, LoginResponseType, ErrorType>();

export const loginAction__Clear = createSafeAction(
  "@login/LOGIN_ACTION_CLEAR"
)();

export const loginKeepAction = createSafeAsyncAction(
  "@login/LOGIN_KEEP_ACTION_REQUEST",
  "@login/LOGIN_KEEP_ACTION_SUCESS",
  "@login/LOGIN_KEEP_ACTION_FAILURE"
)<void, void, ErrorType>();

export const loginSetSessionTokenAction = createSafeAction(
  "@login/SET_SESSION_TOKEN"
)<LoginSetSessionType>();

export const loginSetDomainAction = createSafeAction(
  "@login/SET_DOMAIN"
)<string>();

export const loginUpdateExpireInAction = createSafeAction(
  "@login/UPDATE_EXPIRE"
)();

export const pathsInfoAction = createSafeAsyncAction(
  "@pathsInfo/REQUEST",
  "@pathsInfo/SUCESS",
  "@pathsInfo/FAILURE"
)<void, SslPathsType[], ErrorType>();

export const setActiveGroupAction = createSafeAction(
  "@user/ACTIVE_GROUP"
)<string>();

export const userInfoAction = createSafeAsyncAction(
  "@userInfo/REQUEST",
  "@userInfo/SUCESS",
  "@userInfo/FAILURE"
)<void, User, ErrorType>();

export const userGroupAction = createSafeAsyncAction(
  "@userGroup/REQUEST",
  "@userGroup/SUCESS",
  "@userGroup/FAILURE"
)<void, { groups: UserGroupType[]; myGroups: UserGroupType[] }, ErrorType>();

export type LoginActionType = ActionType<
  | typeof allGroupsAction
  | typeof FilePlanAction
  | typeof AdminFilePlanAction
  | typeof loginAction
  | typeof loginAction__Clear
  | typeof loginKeepAction
  | typeof loginUpdateExpireInAction
  | typeof loginSetSessionTokenAction
  | typeof loginSetDomainAction
  | typeof pathsInfoAction
  | typeof setActiveGroupAction
  | typeof userInfoAction
  | typeof userGroupAction
>;
