import { callAsyncAction } from "presentation/core/action";
import { componentUpdateAction } from "presentation/core/api/components/_actions";
import { ComponentType } from "presentation/core/api/components/_types";
import {
  componentClearAction,
  componentCreateAction,
  componentDeleteAction,
  componentDownloadAction
} from "presentation/core/api/concept/components/_actions";
import {
  DataColumn,
  ValueType
} from "presentation/core/components/dataTable/_types";
import { useMetaFormDocument } from "presentation/core/components/dialog/hooks/useMetaFormDocument";
import {
  DialogDataGenericData,
  DialogTabContentPropsType
} from "presentation/core/components/dialog/_types";
import { File } from "presentation/core/entities";
import { useModal } from "presentation/designSystem/Modal/useModal";
import { Notification } from "presentation/designSystem/notification/Notification";
import { SitePaths } from "presentation/enums";
import { EncryptedComponentsPasswordModal } from "presentation/modules/mailroom/features/income/digitalDocument/modals/EncryptedComponentsPasswordModal";
import { RootStateType } from "presentation/reducers";
import { renameComponentAction } from "presentation/share/components/dialog/renameComponentDialog/_actions";
import { getErrorCodeTranslation } from "presentation/share/utils/errorCodeTranslation";
import { translationPath } from "presentation/share/utils/getPath";
import { getRelativePath } from "presentation/share/utils/query";
import { lang, t } from "presentation/translation/i18n";
import { ErrorType, ErrorTypeWithFailedIds } from "presentation/types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NotificationSeverity } from "../../../../core/components/notifications/_types";
import { SortDirection } from "../../../../govDesignSystem/lib/contract/Sorting";
import ComponentsTab from "./EvidenceComponentTab";
import { useGetActiveConceptComponents } from "./hooks/useGetActiveConceptComponents";
import { SelectedComponentsFnType } from "./_types";

const COMPANY_HOME_PREFIX = "/Company Home/";

type CommentsTabContainerState = {
  pageNumber: number;
  rowsPerPage: number;
  sortAsc?: boolean;
  sortColumnIndex?: number;
  sortKeys?: string[];
};

const initialState: CommentsTabContainerState = {
  pageNumber: 0,
  rowsPerPage: 100
};

type OwnProps = DialogTabContentPropsType & {
  fileIsEncrypted: boolean;
  nodeId: string;
  isReadonly?: boolean;
};

const EvidenceComponentsTabContainer = React.memo(
  ({ fileIsEncrypted, channel, isReadonly, nodeId, dialogProps }: OwnProps) => {
    const dispatch = useDispatch();
    const [
      { pageNumber, rowsPerPage, sortKeys, sortColumnIndex, sortAsc },
      setState
    ] = useState<CommentsTabContainerState>(initialState);

    useEffect(
      () => () => {
        dispatch(componentClearAction());
      },
      [dispatch]
    );

    const nodeType = (dialogProps.data as DialogDataGenericData)?.nodeType;
    const { error } = useSelector(
      (state: RootStateType) => state.conceptComponentsReducer
    );
    const metaFormDocument = useMetaFormDocument();
    const componentsDir = useSelector((state: RootStateType) =>
      getRelativePath(
        state.loginReducer.global.paths,
        null,
        SitePaths.Components
      )
    );

    const { data, refetch, isFetching } = useGetActiveConceptComponents(
      nodeId,
      true,
      {
        page: pageNumber ? pageNumber : 1,
        itemsPerPage: rowsPerPage ? rowsPerPage : 10
      },
      [
        {
          property: sortKeys && sortKeys.length ? sortKeys![0] : "name",
          direction: sortAsc
            ? SortDirection.Ascending
            : SortDirection.Descending
        }
      ]
    );

    const sortedComponents = useMemo(() => {
      return data ? data.items : [];
    }, [data, sortKeys]);
    const fetchComponents = () => {
      refetch();
    };

    useEffect(() => {
      channel.refreshData = fetchComponents;
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      fetchComponents();
    }, [nodeId, rowsPerPage, pageNumber, sortColumnIndex, sortAsc]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      if (!sortedComponents.length || channel.previewItem) {
        return;
      }

      channel.setPreviewItem(sortedComponents[0].isEncrypted || false, {
        ...sortedComponents[0],
        entityId: nodeId,
        nodeType
      });
    }, [channel, nodeId, nodeType, sortedComponents]);

    const [
      encryptedComponentsPasswordModal,
      encryptedComponentsPasswordModalApi
    ] = useModal(EncryptedComponentsPasswordModal, { onOk: fetchComponents });

    const onError = useCallback((file: globalThis.File, error: ErrorType) => {
      Notification.error({
        message: error.code
          ? getErrorCodeTranslation(error.code)
          : Array.isArray(error.messages) && error.messages.length > 0
          ? error.messages[0]
          : t(translationPath(lang.dialog.notifications.actionFailed))
      });
    }, []);

    const handleOpenPopUpPassword = (file: File) =>
      encryptedComponentsPasswordModalApi.open({
        componentType: ComponentType.Concept,
        nodeId,
        componentId: file.id
      });

    const handleChangePage: (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
      page: number
    ) => void = useCallback((_, page) => {
      setState((state) => ({
        ...state,
        pageNumber: page
      }));
    }, []);

    const handleChangeRowsPerPage: (
      event: React.ChangeEvent<HTMLInputElement>
    ) => void = useCallback((event) => {
      setState((state) => ({
        ...state,
        pageNumber: 0,
        rowsPerPage: parseInt(event.target.value, 10)
      }));
    }, []);
    const handleDeleteComponent = useCallback(
      (selectedComponents: File[]) => {
        const onErrorMessage =
          selectedComponents.length > 1
            ? t(
                translationPath(
                  lang.dialog.notifications.componentDeleteOneOrMoreFailed
                )
              )
            : t(translationPath(lang.dialog.notifications.actionFailed));

        const onError = (errorResponse: ErrorTypeWithFailedIds) => {
          if (
            errorResponse.ids &&
            errorResponse.ids.length < selectedComponents.length
          ) {
            fetchComponents();
          }
        };
        dispatch(
          callAsyncAction({
            action: componentDeleteAction,
            onError,
            onErrorNotification: {
              message: onErrorMessage,
              severity: NotificationSeverity.Error
            },
            onSuccess: fetchComponents,
            payload: {
              componentIds: selectedComponents.map((c) => c.id),
              nodeId
            }
          })
        );
      },
      [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
    );
    const handleUploadComponent = useCallback(
      (files: globalThis.File[]) => {
        const onSuccess = () => {
          Notification.success({
            message: t(
              translationPath(
                lang.dialog.notifications.componentUploadWasSuccessful
              )
            )
          });
        };

        dispatch(
          componentCreateAction.request({
            files,
            nodeId,
            onEnd: fetchComponents,
            onError,
            onSuccess
          })
        );
      },
      [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const handleSwapComponentContent = useCallback(
      (selected: File[], files: globalThis.File[]) => {
        const onSuccess = () => {
          Notification.success({
            message: t(
              translationPath(
                lang.dialog.notifications.componentUploadWasSuccessful
              )
            )
          });
        };

        dispatch(
          componentCreateAction.request({
            componentId: selected[0].id,
            files,
            nodeId,
            onEnd: fetchComponents,
            onError,
            onSuccess
          })
        );
      },
      [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const handleDownloadComponent = useCallback(
      (selectedComponents: File[]) => {
        Notification.info({
          message: t(
            translationPath(
              lang.dialog.notifications.componentDownloadIsPreparing
            )
          )
        });
        dispatch(
          callAsyncAction({
            action: componentDownloadAction,
            payload: {
              componentIds: selectedComponents.map((c) => c.id),
              nodeId,
              nodeType
            }
          })
        );
      },
      [dispatch, nodeId, nodeType]
    );

    const handleColumnChange = useCallback(
      (row: File, column: DataColumn<File>, value: ValueType) => {
        dispatch(
          callAsyncAction({
            action: componentUpdateAction,
            payload: {
              ...row,
              [column.keys[0]]: value,
              documentId: metaFormDocument.documentId,
              nodeType: metaFormDocument.nodeType
            }
          })
        );
      },
      [dispatch, metaFormDocument.documentId, metaFormDocument.nodeType]
    );

    const handleRenameComponent: SelectedComponentsFnType = useCallback(
      (selectedComponents) => {
        dispatch(renameComponentAction(selectedComponents[0]));
      },
      [dispatch]
    );

    const handleIsComponentDeletable = (component: File) => {
      return !!component.path?.startsWith(
        `${COMPANY_HOME_PREFIX}${componentsDir}`
      );
    };

    const handleShowPreview = useCallback(
      (selected: File[]) => {
        if (!selected[0]) {
          return;
        }
        channel.setPreviewItem(
          selected[0].isEncrypted || false,
          {
            ...selected[0],
            entityId: nodeId,
            nodeType
          },
          true
        );
      },
      [channel, nodeId, nodeType]
    );

    const handleSelectionChange = useCallback(
      (selected: File[]) => {
        if (!selected[0]) {
          return;
        }
        channel.setPreviewItem(selected[0].isEncrypted || false, {
          ...selected[0],
          entityId: nodeId,
          nodeType
        });
      },
      [channel, nodeId, nodeType]
    );

    const handleSortingChange: (
      index: number,
      keys: string[]
    ) => (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void = (
      index,
      keys
    ) => (event) => {
      setState((state) => ({
        ...state,
        sortAsc: index === state.sortColumnIndex ? !sortAsc : false,
        sortColumnIndex: index,
        sortKeys: keys
      }));
    };

    if (error) {
      return <div />;
    }

    return (
      <>
        {encryptedComponentsPasswordModal}
        <ComponentsTab
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleColumnChange={handleColumnChange}
          handleDeleteComponent={handleDeleteComponent}
          handleDownloadComponent={handleDownloadComponent}
          handleIsComponentDeletable={handleIsComponentDeletable}
          handleRenameComponent={handleRenameComponent}
          handleShowPreview={handleShowPreview}
          handleSelectionChange={handleSelectionChange}
          handleSwapComponentContent={handleSwapComponentContent}
          handleSortingChange={handleSortingChange}
          handleUploadComponent={handleUploadComponent}
          handleOpenPopUpPassword={handleOpenPopUpPassword}
          isLoading={isFetching}
          isReadonly={isReadonly || !!dialogProps.isReadonly}
          items={sortedComponents}
          pageNumber={data ? data.page - 1 : 0}
          refreshTable={fetchComponents}
          rowsPerPage={data ? data.itemsPerPage : 10}
          sortAsc={sortAsc}
          sortColumnIndex={sortColumnIndex}
          totalItems={data?.total || 0}
        />
      </>
    );
  }
);

export default EvidenceComponentsTabContainer;
