import {
  DInject,
  DInjectable
} from "presentation/core/features/dependencyInjection";
import { ApiUrls } from "infrastructure/api/struct/ApiTypesV2";
import { HttpClient, HttpMethod } from "infrastructure/service/HttpClient";
import { PaginatedResult, PaginationConfig } from "lib/contract/Pagination";
import { SortingConfig } from "lib/contract/Sorting";
import {
  CreateRetentionProcessPayload,
  RetentionForShreddingRepository,
  ValidateRetentionProcessPayload
} from "../../../domain/service/recordRetention/RetentionForShreddingRepository";
import {
  ApiRepositoryShreddingForShreddingResponse,
  RetentionForShreddingApiMapper
} from "./RetentionForShreddingApiMapper";
import { RetentionForShredding } from "../../../domain/struct/recordRetention/RetentionForShredding";

const URL: ApiUrls = {
  GetRetentionForShredding: "/api/app/v2/shredding/for-shredding",
  CreateRetentionProcess: "/api/app/v2/shredding/proposal/create",
  ValidateRetentionProcess: "/api/app/v2/shredding/proposal/create/validate"
};

@DInjectable()
export class RetentionForShreddingApiRepository
  implements RetentionForShreddingRepository {
  constructor(@DInject(HttpClient) protected httpClient: HttpClient) {}

  validateRetentionProcess(
    payload: ValidateRetentionProcessPayload
  ): Promise<void> {
    return this.httpClient.fetchWithThrow(
      URL.ValidateRetentionProcess,
      HttpMethod.POST,
      {
        bodyJSON: payload
      }
    );
  }

  getRetentionForShredding(
    pagination: PaginationConfig,
    sorting?: SortingConfig
  ): Promise<PaginatedResult<RetentionForShredding>> {
    return this.httpClient
      .fetchPaginated<ApiRepositoryShreddingForShreddingResponse>({
        url: URL.GetRetentionForShredding,
        pagination,
        sorting
      })
      .then(RetentionForShreddingApiMapper.mapListToDomain);
  }

  createRetentionProcess(
    payload: CreateRetentionProcessPayload
  ): Promise<void> {
    return this.httpClient.fetchWithThrow(
      URL.CreateRetentionProcess,
      HttpMethod.POST,
      {
        bodyJSON: payload
      }
    );
  }
}
