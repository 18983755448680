import { FilePlanGroups } from "presentation/core/features/login/_types";
import React from "react";
import FileMark from "./FileMark";

interface FileMarksProps {
  filePlanGroups: FilePlanGroups[];
  level: number;
  isSearch: boolean;
}

export const FileMarks = (props: FileMarksProps) => {
  const { filePlanGroups, level, isSearch, ...restProps } = props;

  const fileMarks = filePlanGroups.map((mark) => {
    return (
      <React.Fragment key={mark.fullyDeterminedFileMark}>
        <FileMark
          isSearch={isSearch}
          level={level}
          mark={mark}
          {...restProps}
        />
      </React.Fragment>
    );
  });
  return <>{fileMarks}</>;
};
