import { secondaryAction } from "presentation/core/components/dialog/lib/actionsFactory";
import { CommentsTab } from "presentation/core/components/dialog/tabs/comments";
import {
  DialogContentType,
  DialogType
} from "presentation/core/components/dialog/_types";
import NamedTitle from "presentation/core/components/namedTitle";
import React from "react";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import { createDocumentDialog } from "../baseDocumentDialog/documentDialogFactory";
import { ConceptComponentsTab } from "./ConceptComponentsTab";
import { MetaDataTab } from "./MetaDataTab";
import { conceptConcernedSubjectTab } from "../../dialogTabs/ConcernedSubject";

export const evidenceCreateConceptDialogContent: DialogContentType = createDocumentDialog(
  {
    actions: () => [
      secondaryAction(
        t(translationPath(lang.dialog.form.confirm)),
        ({ dispatch, channels, dialogProps, onClose, buttonState }) => {}
      )
    ],
    tabs: [
      {
        content: MetaDataTab,
        label: t(translationPath(lang.dialog.tabs.metadata))
      },
      {
        content: ConceptComponentsTab,
        label: t(translationPath(lang.dialog.tabs.components))
      },
      conceptConcernedSubjectTab,
      {
        content: CommentsTab,
        label: t(translationPath(lang.dialog.tabs.notes))
      }
    ],
    title: (props) => (
      <NamedTitle
        text={t(translationPath(lang.general.addConcept))}
        {...props}
      />
    ),
    type: DialogType.CreateConcept
  }
);
