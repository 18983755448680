import { Address } from "../../../../../../domain/struct/nameRegistry/Address";
import { Contact } from "../../../../../../domain/struct/nameRegistry/Contact";
import { Subject } from "../../../../../../domain/struct/nameRegistry/Subject";
import { SubjectPersistenceLogic } from "./SubjectPersistenceLogic";
import {
  DInject,
  DInjectable
} from "../../../../../core/features/dependencyInjection";
import { PaginatedResult, PaginationConfig } from "lib/contract/Pagination";
import { FindContact } from "../../../../../../useCase/nameRegistry/contact/FindContact";
import { SaveContact } from "../../../../../../useCase/nameRegistry/contact/SaveContact";
import { FindAddress } from "../../../../../../useCase/nameRegistry/address/FindAddress";
import { SaveAddress } from "../../../../../../useCase/nameRegistry/address/SaveAddress";
import { SavePerson } from "../../../../../../useCase/nameRegistry/SavePerson";
import { isPerson } from "../../../../../../domain/struct/nameRegistry/Person";
import { SaveLegalEntity } from "../../../../../../useCase/nameRegistry/SaveLegalEntity";

@DInjectable()
export class UpdateSubjectLogic implements SubjectPersistenceLogic {
  subject: Subject | undefined;

  constructor(
    @DInject(SaveContact) protected saveContact: SaveContact,
    @DInject(FindContact) protected findContact: FindContact,
    @DInject(FindAddress) protected findAddress: FindAddress,
    @DInject(SaveAddress) protected saveAddress: SaveAddress,
    @DInject(SavePerson) protected savePerson: SavePerson,
    @DInject(SaveLegalEntity) protected saveLegalEntity: SaveLegalEntity
  ) {}
  getContactsFromState(): Contact[] | null {
    return null;
  }

  getAddressesFromState(): Address[] | null {
    return null;
  }

  disableSubjectTypeSelect(): boolean {
    return true;
  }

  updateContact(contact: Contact): Promise<void> {
    return this.saveContact.update(this.getSubjectId(), contact);
  }

  updateAddress(address: Address): Promise<void> {
    return this.saveAddress.update(this.getSubjectId(), address);
  }

  addAddress(address: Address): Promise<void> {
    return this.saveAddress.create(this.getSubjectId(), address);
  }

  addContact(contact: Contact): Promise<void> {
    return this.saveContact.create(this.getSubjectId(), contact);
  }

  removeAddress(address: Address): Promise<void> {
    return this.saveAddress.delete(this.getSubjectId(), address);
  }

  removeContact(contact: Contact): Promise<void> {
    return this.saveContact.delete(this.getSubjectId(), contact);
  }

  save(): Promise<void> {
    if (isPerson(this.subject)) {
      return this.savePerson.update(this.subject);
    }
    return this.saveLegalEntity.update(this.subject!);
  }

  setSubjectData(subject: Subject): void {
    this.subject = { ...this.subject, ...subject };
  }

  getAddresses(
    pagination: PaginationConfig
  ): Promise<PaginatedResult<Address>> {
    return this.findAddress.findBySubjectId(this.getSubjectId(), pagination);
  }

  async getContacts(
    pagination: PaginationConfig
  ): Promise<PaginatedResult<Contact>> {
    return this.findContact.findBySubjectId(this.getSubjectId(), pagination);
  }

  getSubjectId() {
    if (!this.subject?.id) {
      throw new Error("Subject id is not set");
    }

    return this.subject?.id;
  }

  getSubjectData(): Subject | undefined {
    return this.subject;
  }

  resetState() {
    this.subject = undefined;
  }
}
