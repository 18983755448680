import React from "react";
import { translationPath } from "presentation/share/utils/getPath";
import { ColumnType } from "presentation/designSystem/Table/Table";
import {
  createColumn,
  createDateTimeColumn
} from "presentation/designSystem/Table/column/column";
import { lang, t } from "presentation/translation/i18n";
import { DateTimeFormat } from "lib/dateTime";
import {
  GetRemoteDataClb,
  RemoteTable,
  RemoteTableProps
} from "presentation/designSystem/Table/RemoteTable";
import { History } from "domain/struct/history/History";

const COLUMNS: ColumnType<History>[] = [
  createColumn({
    dataIndex: "pid",
    title: t(translationPath(lang.table.historyTable.identifier)),
    sortDirections: ["descend", "ascend", "descend"],
    sorter: true
  }),
  createDateTimeColumn(
    {
      dataIndex: "occurredAt",
      title: t(translationPath(lang.table.historyTable.dateOfChange)),
      defaultSortOrder: "descend",
      sortDirections: ["descend", "ascend", "descend"],
      sorter: true
    },
    DateTimeFormat.FullDateTime
  ),
  createColumn({
    dataIndex: "description",
    title: t(translationPath(lang.table.historyTable.description))
  }),
  createColumn({
    dataIndex: "userId",
    title: t(translationPath(lang.table.historyTable.authorOfChange)),
    sortDirections: ["descend", "ascend", "descend"],
    sorter: true
  }),
  createColumn({
    dataIndex: "eventType",
    title: t(translationPath(lang.table.historyTable.typeOfChange)),
    sortDirections: ["descend", "ascend", "descend"],
    sorter: true
  })
];

export interface HistoryTableProps
  extends Pick<
    RemoteTableProps<History>,
    "getRemoteData" | "onRemoteTableApi" | "queryConfig"
  > {
  id: string | number;
}

export const HistoryTable: React.FC<HistoryTableProps> = ({
  id,
  getRemoteData,
  onRemoteTableApi,
  queryConfig
}) => (
  <RemoteTable<History>
    name={`history/historyTable/${id}`}
    columns={COLUMNS}
    getRemoteData={getRemoteData}
    onRemoteTableApi={onRemoteTableApi}
    queryConfig={queryConfig}
  />
);
