import { FindConceptHistory } from "useCase/concept/FindConceptHistory";

import { ConceptHistoryRepositoryToken } from "domain/di/token/concept";
import { ConceptHistoryApiRepository } from "infrastructure/api/concept/ConceptHistoryApiRepository";

export const conceptProvider = [
  {
    provide: ConceptHistoryRepositoryToken,
    useClass: ConceptHistoryApiRepository
  },
  FindConceptHistory
];
