import { CommentsTab } from "presentation/core/components/dialog/tabs/comments";
import { HistoryTab } from "presentation/core/components/dialog/tabs/history";
import { ProcessingOrClosingTab } from "presentation/core/components/dialog/tabs/processingOrClosing/ProcessingOrClosingTab";
import { SaveAndDiscardTab } from "presentation/core/components/dialog/tabs/saveAndDiscard/SaveAndDiscardTab";
import { ShipmentTab } from "presentation/core/components/dialog/tabs/shipment";
import { TableOfContentsTab } from "presentation/core/components/dialog/tabs/tableOfContents";
import {
  DialogContentType,
  DialogType
} from "presentation/core/components/dialog/_types";
import NamedTitle from "presentation/core/components/namedTitle";
import { GenericDocument } from "presentation/core/types";
import React from "react";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import { createDocumentDialog } from "../baseDocumentDialog/documentDialogFactory";
import MetadataFormTab from "../fileDetailsDialog/MetadataFormTab";
import { fileConcernedSubjectTab } from "../../dialogTabs/ConcernedSubject";
// import { SettleTab } from "../../../../core/components/dialog/tabs/settle/SettleTab";

export const fileDetailsReadonlyDialogContent: DialogContentType = createDocumentDialog(
  {
    tabs: [
      {
        content: MetadataFormTab,
        label: t(translationPath(lang.dialog.tabs.metadata))
      },
      {
        content: TableOfContentsTab,
        label: t(translationPath(lang.dialog.tabs.content))
      },
      fileConcernedSubjectTab,
      {
        content: ProcessingOrClosingTab,
        filter: ({ dialogProps }) => {
          const { state } =
            (dialogProps.data as GenericDocument)?.properties?.ssl || {};
          return ProcessingOrClosingTab.filter(state);
        },
        label: t(translationPath(lang.dialog.tabs.processOrClose))
      },
      {
        content: ShipmentTab,
        label: t(translationPath(lang.dialog.tabs.shipment))
      },
      // ----------- Item "Vyrížení" was deleted, because it shouldn't be here. Task SV1-2915 -----------
      // {
      //   content: SettleTab,
      //   filter: ({ dialogProps }) => {
      //     const { state } =
      //       (dialogProps.data as GenericDocument)?.properties?.ssl || {};

      //     return SettleTab.filter(state);
      //   },
      //   label: t(translationPath(lang.dialog.tabs.settle))
      // },
      {
        content: SaveAndDiscardTab,
        filter: ({ dialogProps }) => {
          const { state } =
            (dialogProps.data as GenericDocument)?.properties?.ssl || {};

          return SaveAndDiscardTab.filter(state);
        },
        label: t(translationPath(lang.dialog.tabs.saveAndDiscard))
      },
      {
        content: CommentsTab,
        label: t(translationPath(lang.dialog.tabs.notes))
      },
      {
        content: HistoryTab,
        label: t(translationPath(lang.dialog.tabs.history))
      }
    ],
    title: (props) => (
      <NamedTitle
        text={t(translationPath(lang.dialog.title.fileDetail))}
        {...props}
      />
    ),
    type: DialogType.FileDetailsReadonly
  }
);
