import { useQM } from "presentation/share/hook/query/useQM";
import { useModal } from "presentation/designSystem/Modal/useModal";
import {
  TypeFileDetailModalOpen,
  TypeFileDetailModalOpenProps
} from "../modal/TypeFilesDetailModalOpen";
import { getDetailTypeFile } from "../api";
import { ActionResult } from "@isfg/lib/build/contract/Action";
import { DetailTypeFileDocument } from "../types";
import { RemoteTableApi } from "presentation/designSystem/Table/RemoteTable";

export interface UseGetTypeFileOpenParams {
  id: string;
  refreshOpenTypeFiles: RemoteTableApi["refetch"];
}

export interface UseGetTypeFileReturn {
  isLoading: boolean;
  modal: false | JSX.Element;
  open(props: TypeFileDetailModalOpenProps): Promise<ActionResult | undefined>;
  data: DetailTypeFileDocument | undefined;
}

export const useGetTypeFileOpen = ({
  id,
  refreshOpenTypeFiles
}: UseGetTypeFileOpenParams): UseGetTypeFileReturn => {
  const [modal, modalApi] = useModal(TypeFileDetailModalOpen);
  const { isLoading, data } = useQM(
    ["typeFile/detail", id],
    () => getDetailTypeFile(id),
    {
      enabled: id !== "",
      onSuccess: (data) => {
        modalApi.open({
          document: data,
          refreshOpenTypeFiles
        });
      }
    }
  );

  return {
    isLoading,
    modal,
    open: modalApi.open,
    data
  };
};
