import { ApiListResponse, ApiTypes } from "../struct/ApiTypesV2";
import { ApiListResponseMapper } from "../mapper/ApiListResponseMapper";
import { stringOrDefault } from "../../../lib/string";
import { RentedEvidenceDocument } from "../../../domain/struct/evidence/RentedEvidenceDocument";

export type ApiRepositoryBorrowedFilesViewResponse = ApiTypes["ISFG.SpisUm.ClientSide.Models.V2.Repository.RepositoryBorrowedFilesViewResponse"];

export class RentedEvidenceDocumentApiMapper {
  static mapListToDomain(
    response: ApiListResponse<ApiRepositoryBorrowedFilesViewResponse>
  ) {
    return ApiListResponseMapper.mapListToPagination(
      response,
      RentedEvidenceDocumentApiMapper.mapToDomain
    );
  }

  static mapToDomain(
    response: ApiRepositoryBorrowedFilesViewResponse
  ): RentedEvidenceDocument {
    const {
      pid,
      borrowDate,
      borrower,
      fileIdentifier,
      fileMark,
      filePlanId,
      location,
      nodeId,
      retentionMode,
      senderName,
      shreddingYear,
      ssid,
      subject,
      createdAt,
      form,
      repositoryGroup
    } = response;
    return {
      pid: stringOrDefault(pid),
      borrowDate: stringOrDefault(borrowDate),
      borrower: stringOrDefault(borrower),
      fileIdentifier: stringOrDefault(fileIdentifier),
      fileMark: stringOrDefault(fileMark),
      filePlanId: stringOrDefault(filePlanId),
      location: stringOrDefault(location),
      nodeId: stringOrDefault(nodeId),
      retentionMode: stringOrDefault(retentionMode),
      senderName: stringOrDefault(senderName),
      shreddingYear: stringOrDefault(shreddingYear),
      ssid: stringOrDefault(ssid),
      subject: stringOrDefault(subject),
      createdAt: stringOrDefault(createdAt),
      form: stringOrDefault(form),
      repositoryGroup: stringOrDefault(repositoryGroup)
    };
  }
}
