import { metaFormAction__Update } from "presentation/core/components/MetaForm/_actions";
import { dialogOpenAction } from "presentation/core/components/dialog/_actions";
import {
  DialogDataType,
  DialogType
} from "presentation/core/components/dialog/_types";
import { SpisumNodeTypes } from "presentation/enums";
import { getNameTypeMap } from "presentation/share/utils/mapper";
import { call, put, takeLatest } from "redux-saga/effects";
import { ActionType, getType } from "typesafe-actions";
import { ShipmentId } from "../../../../../domain/struct/shipment/Shipment";
import { FindShipment } from "../../../../../useCase/shipment/FindShipment";
import { getService } from "../../../../core/features/dependencyInjection";
import { Notification } from "../../../../designSystem/notification/Notification";
import { lang, t } from "../../../../translation/i18n";
import { translationPath } from "../../../utils/getPath";
import { shipmentDetailDialogOpen } from "./_action";
import {
  ShipmentDetailDataBoxInitialValues,
  ShipmentDetailEmailInitialValues,
  ShipmentDetailPersonallyInitialValues,
  ShipmentDetailPostInitialValues,
  ShipmentDetailPublishInitialValues
} from "./mappers";

const initialValuesMap = {
  [SpisumNodeTypes.ShipmentDatabox]: ShipmentDetailDataBoxInitialValues,
  [SpisumNodeTypes.ShipmentEmail]: ShipmentDetailEmailInitialValues,
  [SpisumNodeTypes.ShipmentPost]: ShipmentDetailPostInitialValues,
  [SpisumNodeTypes.ShipmentPersonally]: ShipmentDetailPersonallyInitialValues,
  [SpisumNodeTypes.ShipmentPublish]: ShipmentDetailPublishInitialValues
};

export function* watchShipmentDetailDialogAction() {
  yield takeLatest(
    getType(shipmentDetailDialogOpen),
    function* ({ payload }: ActionType<typeof shipmentDetailDialogOpen>) {
      if (!payload) {
        return;
      }

      const { selected, documentId, onClose, readonly, componentReadonly } =
        payload;

      const sslPostType = selected.properties?.ssl?.postType;
      let formData;
      if (
        selected.nodeType === SpisumNodeTypes.ShipmentPersonally ||
        selected.nodeType === SpisumNodeTypes.ShipmentPost
      ) {
        formData = {
          ...initialValuesMap[selected.nodeType],
          ...selected.properties?.ssl,
          postType: sslPostType ? sslPostType.split(",") : [],
          addressStreet: `${selected.properties?.ssl?.recipientAddressStreet} ${selected.properties?.ssl?.recipientAddressHouseNumber}`,
          addressCity: selected.properties?.ssl?.recipientAddressCity,
          addressZip: selected.properties?.ssl?.recipientAddressZipCode
        };
      } else {
        formData = {
          ...(initialValuesMap[
            selected.nodeType as keyof typeof initialValuesMap
          ] as any),
          ...selected.properties?.ssl,
          postType: sslPostType ? sslPostType.split(",") : []
        };
      }
      const nodeTypeName = getNameTypeMap(selected.nodeType);

      try {
        const shipment: any = yield call(getShipmentById, selected.id);

        yield put(
          metaFormAction__Update({
            documentId: selected.id,
            formValues: {
              ...formData,
              createdAt: selected.createdAt,
              nodeType: selected.nodeType as SpisumNodeTypes,
              nodeTypeName
            },
            nodeType: selected.nodeType
          })
        );

        yield put(
          dialogOpenAction({
            dialogProps: {
              data: {
                ...selected,
                properties: {
                  ...selected.properties,
                  ssl: {
                    ...selected.properties?.ssl,
                    components: shipment.componentIds
                  }
                },
                documentId
              } as DialogDataType,
              isComponentReadonly: componentReadonly,
              isReadonly: !!readonly,
              onClose
            },
            dialogType: DialogType.OpenShipmentDetail
          })
        );
      } catch {
        Notification.error({
          message: t(translationPath(lang.dialog.notifications.actionFailed))
        });
      }
    }
  );
}

const getShipmentById = (id: ShipmentId) => {
  const findShipment = getService(FindShipment);

  return findShipment.getById(id);
};
