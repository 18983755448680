export const CONFIG = {
  development: {
    // servers on which the application should act like it is in development mode
    hosts: ["dev.spisum.cz", "test.spisum.cz"]
  },
  app: {
    signerEnabled: false,
    tokenExpiration: 30 // 30s
  }
};
