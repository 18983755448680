import { ContactRepositoryToken } from "../../../domain/di/token/nameRegistry";
import type { ContactRepository } from "../../../domain/service/nameRegistry/ContactRepository";
import { PaginationConfig } from "../../../lib/contract/Pagination";
import {
  DInject,
  DInjectable
} from "../../../presentation/core/features/dependencyInjection";

@DInjectable()
export class FindContact {
  constructor(
    @DInject(ContactRepositoryToken) protected repository: ContactRepository
  ) {}

  findBySubjectId(subjectId: string, pagination: PaginationConfig) {
    return this.repository.findBySubjectId(subjectId, pagination);
  }
}
