import { useQM } from "../../../../../share/hook/query/useQM";
import { getTypeFileStructure } from "../api";
import { FileTypeStructure } from "../types";

export interface UseGetTypeFileReturn {
  isLoading: boolean;
  data: FileTypeStructure[] | undefined;
  refetch(): void;
}

export const useGetTypeFileStructure = (id: string): UseGetTypeFileReturn => {
  const { isLoading, data, refetch } = useQM(
    ["typeFile/structure", id],
    () => getTypeFileStructure(id),
    {
      enabled: id !== ""
    }
  );

  return {
    isLoading,
    data,
    refetch
  };
};
