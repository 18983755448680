import { Breadcrumbs, Typography } from "@mui/material";
import { translationPath } from "presentation/share/utils/getPath";
import styled, { theme } from "presentation/styles";
import { lang, t } from "presentation/translation/i18n";
import { useLocation } from "react-router-dom";
import React from "react";

interface BreadcrumbProps {
  title: string;
}

const tranformToTranslatable = (item: string) => {
  const [first, ...rest] = item.split("-");
  if (rest && rest.length) {
    return (
      first +
      rest.reduce((acc, curr) => {
        return acc + curr.charAt(0).toUpperCase() + curr.slice(1);
      }, "")
    );
  }
  return first;
};
export const BreadcrumbForTable = ({ title }: BreadcrumbProps) => {
  const { pathname } = useLocation();
  const breadcrumbItems = pathname.split("/").filter(Boolean);
  return (
    <BreadcrumbStyled>
      <TitleStyled className="title" variant="h5" gutterBottom={true}>
        {t(
          translationPath(
            lang.breadcrumb[
              tranformToTranslatable(title) as keyof typeof lang.breadcrumb
            ]
          )
        )}
      </TitleStyled>
      <PathStyled separator="›" aria-label="breadcrumb">
        {breadcrumbItems &&
          breadcrumbItems.length &&
          breadcrumbItems.map((item: string) => (
            <Typography key={item} color="textPrimary">
              {t(
                translationPath(
                  lang.breadcrumb[
                    tranformToTranslatable(item) as keyof typeof lang.breadcrumb
                  ]
                )
              )}
            </Typography>
          ))}
      </PathStyled>
    </BreadcrumbStyled>
  );
};

export const BreadcrumbStyled = styled("div")`
  position: relative;
  height: 5%;
  padding: 20px;
`;

export const TitleStyled = styled(Typography)(() => ({
  color: theme.colors.green,
  fontWeight: 600
}));
export const PathStyled = styled(Breadcrumbs)(() => ({
  "& p": { color: theme.colors.gray },
  color: theme.colors.gray
}));
