import { PaginatedResult, PaginationConfig } from "lib/contract/Pagination";
import {
  DInject,
  DInjectable
} from "presentation/core/features/dependencyInjection";
import { ShipmentComponentRepositoryToken } from "../../domain/di/token/shipment";
import type { ShipmentComponentRepository } from "../../domain/service/shipment/ShipmentComponentRepository";
import { DocumentId } from "../../domain/struct/document/Document";
import { ShipmentId } from "../../domain/struct/shipment/Shipment";
import { ShipmentComponent } from "../../domain/struct/shipment/ShipmentComponent";

@DInjectable()
export class FindShipmentComponent {
  constructor(
    @DInject(ShipmentComponentRepositoryToken)
    protected repository: ShipmentComponentRepository
  ) {}

  getShipableComponents(
    documentId: DocumentId,
    pagination: PaginationConfig
  ): Promise<PaginatedResult<ShipmentComponent>> {
    return this.repository.getShipableComponents(documentId, pagination);
  }

  getShipmentComponents(
    shipmentId: ShipmentId,
    pagination: PaginationConfig
  ): Promise<PaginatedResult<ShipmentComponent>> {
    return this.repository.getShipmentComponents(shipmentId, pagination);
  }
}
