import { UseQueryOptions } from "react-query";
import { useQM } from "presentation/share/hook/query/useQM";
import { validateDocumentComponents } from "../api";
import { DocumentComponentsValidateResponse } from "../types";

export interface UseGetDigitDeliveredValidParams
  extends UseQueryOptions<DocumentComponentsValidateResponse[]> {
  documentId?: string;
}

const QUERY_KEY = "document/componentsValidation";

export const useGetDocumentComponentsValidation = ({
  documentId,
  ...optionsProps
}: UseGetDigitDeliveredValidParams) => {
  const { isLoading: isValidateLoading, refetch: validate } = useQM<
    DocumentComponentsValidateResponse[]
  >([QUERY_KEY, documentId], () => validateDocumentComponents(documentId!), {
    ...optionsProps,
    enabled: documentId !== undefined
  });

  return {
    isValidateLoading,
    validate
  };
};
