import { LegalEntityRepositoryToken } from "../../domain/di/token/nameRegistry";
import type {
  CreatePayload,
  LegalEntityRepository
} from "../../domain/service/nameRegistry/LegalEntityRepository";
import { LegalEntity } from "../../domain/struct/nameRegistry/LegalEntity";
import {
  DInject,
  DInjectable
} from "../../presentation/core/features/dependencyInjection";
import { FindDataBox } from "./isds/FindDataBox";

@DInjectable()
export class SaveLegalEntity {
  constructor(
    @DInject(LegalEntityRepositoryToken)
    protected repository: LegalEntityRepository,
    protected findDataBox: FindDataBox
  ) {}

  async create(payload: CreatePayload) {
    return this.repository.create(payload);
  }

  async update(legalEntity: LegalEntity) {
    return this.repository.update(legalEntity);
  }
}
