import { Form } from "formik";
import { TextField } from "formik-mui";
import {
  StyledFieldWide,
  useStyles
} from "presentation/core/components/dialog/Dialog.styles";
import { DialogContentPropsType } from "presentation/core/components/dialog/_types";
import { sslPropsProxy } from "presentation/core/types";
import {
  lastPathMember,
  translationPath
} from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import { ChannelForm } from "../ChannelForm/ChannelForm";
import { ChangeLocationValues } from "./_types";
import { validate } from "./_validations";
import React from "react";

export const ChangeLocationDialog = ({ channel }: DialogContentPropsType) => {
  const classes = useStyles();
  return (
    <ChannelForm<ChangeLocationValues>
      initialValues={{
        location: ""
      }}
      validate={validate}
      channel={channel}
    >
      <Form className={classes.modalSmall}>
        <StyledFieldWide
          component={TextField}
          name={lastPathMember(sslPropsProxy.location).path}
          // required={true}
          type="text"
          label={t(translationPath(lang.general.location))}
        />
      </Form>
    </ChannelForm>
  );
};
