import {
  FileTextFilled,
  MinusCircleOutlined,
  PlusCircleOutlined
} from "../../../designSystem/Icon/Icon";
import { TableAction } from "../../../designSystem/Table/Table";
import { t } from "../../../translation/i18n";
import React from "react";

type TableActionConfig<T> = Omit<TableAction<T>, "key" | "icon" | "tooltip"> & {
  key?: string; // make the key optional (actions will have default key set)
  tooltip?: TableAction<T>["tooltip"];
};

export function actionAdd<T>(action: TableActionConfig<T>): TableAction<T> {
  return {
    key: "add",
    icon: <PlusCircleOutlined rev={"default"} />,
    tooltip: t("general:add"),
    ...action
  };
}

export function actionRemove<T>(action: TableActionConfig<T>): TableAction<T> {
  return {
    key: "remove",
    icon: <MinusCircleOutlined rev={"default"} />,
    tooltip: t("general:remove"),
    ...action
  };
}

export function actionShowDetail<T>(
  action: TableActionConfig<T>
): TableAction<T> {
  return {
    key: "show-detail",
    icon: <FileTextFilled rev={"default"} />,
    tooltip: t("general:showDetails"),
    ...action
  };
}
