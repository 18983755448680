import { HttpClient } from "infrastructure/service/HttpClient";
import { DInjectable } from "presentation/core/features/dependencyInjection";
import {
  PaginatedResult,
  PaginationConfig
} from "../../../lib/contract/Pagination";
import { ApiUrls } from "../struct/ApiTypesV2";
import { RetentionProtocolsOrProposalsContentRepository } from "../../../domain/service/recordRetention/RetentionProtocolsOrProposalsContentRepository";
import { RetentionProtocolProposalContent } from "../../../domain/struct/recordRetention/RetentionProtocolProposalContent";
import {
  ApiProtocolOrProposalContent,
  RetentionProtocolsOrProposalsContentApiMapper
} from "./RetentionProtocolsOrProposalsContentApiMapper";

const URL: ApiUrls = {
  GetRetentionProtocolsOrProposalsContent:
    "/api/app/v2/shredding/proposal-protocol/{nodeId}/content"
};

@DInjectable()
export class RetentionProtocolsOrProposalsContentApiRepository
  implements RetentionProtocolsOrProposalsContentRepository {
  constructor(protected httpClient: HttpClient) {}

  findAllPaginated(
    nodeId: string,
    pagination: PaginationConfig
  ): Promise<PaginatedResult<RetentionProtocolProposalContent>> {
    return this.httpClient
      .fetchPaginated<ApiProtocolOrProposalContent>({
        url: URL.GetRetentionProtocolsOrProposalsContent,
        pagination,
        config: {
          urlWildCards: {
            nodeId: nodeId
          }
        }
      })
      .then(RetentionProtocolsOrProposalsContentApiMapper.mapListToDomain);
  }
}
