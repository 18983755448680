import React, { useCallback } from "react";
import { ColumnType } from "../../../../../designSystem/Table/Table";
import { Person } from "../../../../../../domain/struct/nameRegistry/Person";
import { LegalEntity } from "../../../../../../domain/struct/nameRegistry/LegalEntity";
import { createColumn } from "../../../../../designSystem/Table/column/column";
import { lang, t } from "../../../../../translation/i18n";
import { translationPath } from "../../../../../share/utils/getPath";
import {
  GetRemoteDataClb,
  RemoteTable
} from "../../../../../designSystem/Table/RemoteTable";
import {
  TableActions,
  TableRowSelection
} from "../../../../../designSystem/Table/Table";
import { useFindSearchedSubjectsPersons } from "../../../hooks/useFindSearchedSubject";

interface SearchSubjectPersonTableProps {
  actions: TableActions<Person | LegalEntity> | undefined;
  remoteQueryParams: Record<string, string> | undefined;
  onRowSelection: TableRowSelection<Person | LegalEntity> | undefined;
}

const COLUMNS: ColumnType<Person>[] = [
  createColumn({
    dataIndex: "fullName",
    title: t(translationPath(lang.table.searchSubjectTable.nameOfSubject))
  }),
  createColumn({
    dataIndex: "firstName",
    title: t(translationPath(lang.table.searchSubjectTable.name))
  }),
  createColumn({
    dataIndex: "surname",
    title: t(translationPath(lang.table.searchSubjectTable.surname))
  })
];

export const SearchSubjectPersonTable = ({
  actions,
  remoteQueryParams,
  onRowSelection
}: SearchSubjectPersonTableProps) => {
  const findPersons = useFindSearchedSubjectsPersons();
  const loading =
    remoteQueryParams?.keyword && remoteQueryParams.keyword.length > 0;
  const getRemoteDataPersons: GetRemoteDataClb<Person> = useCallback(
    ({ pagination, params }) => findPersons(pagination, params),
    [findPersons]
  );

  return (
    <RemoteTable<Person>
      name="nameRegistry/searchedSubjectTable"
      actions={actions}
      loading={false}
      columns={COLUMNS}
      getRemoteData={getRemoteDataPersons}
      remoteQueryParams={remoteQueryParams}
      rowSelection={onRowSelection}
      rowKey="id"
    />
  );
};
