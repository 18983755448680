import MenuLayout from "presentation/core/components/layout/MenuLayout";
import React from "react";
import { withTranslation } from "presentation/translation/i18n";
import { StoredDocumentsTable } from "./components/StoredDocumentsTable";

const StoredDocuments = () => {
  return (
    <MenuLayout>
      <StoredDocumentsTable />
    </MenuLayout>
  );
};

export default withTranslation()(StoredDocuments);
