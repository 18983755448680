import { ErasedFileRepositoryToken } from "domain/di/token/recordRetention";
import { ErasedFileApiRepository } from "infrastructure/api/recordRetention/ErasedFileApiRepository";
import { FindErasedDocument } from "useCase/recordRetention/FindErasedDocument";

import { ErasedDocumentRepositoryToken } from "domain/di/token/recordRetention";
import { ErasedDocumentApiRepository } from "infrastructure/api/recordRetention/ErasedDocumentApiRepository";
import {
  CancelProposalRepositoryToken,
  PerformDeletionToken,
  PerformShreddingProcedureToken,
  RetentionForShreddingRepositoryToken,
  RetentionProposalRemoveObjectsRepositoryToken,
  RetentionProposalsRepositoryToken,
  RetentionProtocolsErasedRepositoryToken,
  RetentionProtocolsOrProposalsRepositoryTokenContent,
  RetentionProtocolsOrProposalsRepositoryTokenHistory,
  RetentionProtocolsRepositoryToken
} from "domain/di/token/recordRetention";
import { CancelProposal } from "useCase/recordRetention/CancelProposal";
import { CancelProposalApiRepository } from "infrastructure/api/recordRetention/CancelProposalApiRepository";
import { FindRetentionProtocolsErased } from "useCase/recordRetention/FindRetentionProtocolsErased";
import { RetentionProtocolsErasedApiRepository } from "infrastructure/api/recordRetention/RetentionProtocolsErasedApiRepository";
import { FindRetentionProtocols } from "useCase/recordRetention/FindRetentionProtocols";
import { RetentionProtocolsApiRepository } from "infrastructure/api/recordRetention/RetentionProtocolsApiRepository";
import { RetentionProposalsApiRepository } from "infrastructure/api/recordRetention/RetentionProposalsApiRepository";
import { FindRetentionProposals } from "useCase/recordRetention/FindRetentionProposals";
import { FindRetentionProtocolsOrProposalsContent } from "../../useCase/recordRetention/FindRetentionProtocolsOrProposalsContent";
import { RetentionProtocolsOrProposalsContentApiRepository } from "../../infrastructure/api/recordRetention/RetentionProtocolsOrProposalsContentApiRepository";
import { RetentionForShreddingApiRepository } from "../../infrastructure/api/recordRetention/RetentionForShreddingApiRepository";
import { FindRetentionForShredding } from "../../useCase/recordRetention/FindRetentionForShredding";
import { RetentionProtocolsOrProposalsHistoryApiRepository } from "../../infrastructure/api/recordRetention/RetentionProtocolsOrProposalsHistoryApiRepository";
import { FindRetentionProtocolsOrProposalsHistory } from "../../useCase/recordRetention/FindRetentionProtocolsOrProposalsHistory";
import { SavePerformShreddingProcedure } from "../../useCase/recordRetention/SavePerformShreddingProcedure";
import { PerformShreddingProcedureApiRepository } from "../../infrastructure/api/recordRetention/PerformShreddingProcedureApiRepository";
import { PerformDeletionApiRepository } from "../../infrastructure/api/recordRetention/PerformDeletionApiRepository";
import { SavePerformDeletion } from "../../useCase/recordRetention/SavePerformDeletion";
import { RetentionProposalRemoveObjectsApiRepository } from "infrastructure/api/recordRetention/RetentionProposalRemoveObjectsApiRepository";
import { RemoveRetentionProposalObjects } from "../../useCase/recordRetention/RemoveRetentionProposalObjects";
import { FindErasedFile } from "../../useCase/recordRetention/FindErasedFile";
import { FindErasedDocuments } from "../../useCase/recordRetention/FindErasedFileDocuments";

export const recordRetentionProviders = [
  // repository
  {
    provide: ErasedFileRepositoryToken,
    useClass: ErasedFileApiRepository
  },
  {
    provide: ErasedDocumentRepositoryToken,
    useClass: ErasedDocumentApiRepository
  },
  {
    provide: RetentionProposalsRepositoryToken,
    useClass: RetentionProposalsApiRepository
  },
  {
    provide: RetentionProtocolsRepositoryToken,
    useClass: RetentionProtocolsApiRepository
  },
  {
    provide: RetentionProtocolsErasedRepositoryToken,
    useClass: RetentionProtocolsErasedApiRepository
  },
  {
    provide: RetentionProtocolsOrProposalsRepositoryTokenContent,
    useClass: RetentionProtocolsOrProposalsContentApiRepository
  },
  {
    provide: RetentionProtocolsOrProposalsRepositoryTokenHistory,
    useClass: RetentionProtocolsOrProposalsHistoryApiRepository
  },
  {
    provide: RetentionForShreddingRepositoryToken,
    useClass: RetentionForShreddingApiRepository
  },
  {
    provide: CancelProposalRepositoryToken,
    useClass: CancelProposalApiRepository
  },
  {
    provide: PerformShreddingProcedureToken,
    useClass: PerformShreddingProcedureApiRepository
  },
  {
    provide: PerformDeletionToken,
    useClass: PerformDeletionApiRepository
  },
  {
    provide: RetentionProposalRemoveObjectsRepositoryToken,
    useClass: RetentionProposalRemoveObjectsApiRepository
  },

  // use case
  FindErasedFile,
  FindErasedDocument,
  FindErasedDocuments,
  FindRetentionForShredding,
  FindRetentionProposals,
  FindRetentionProtocols,
  FindRetentionProtocolsErased,
  FindRetentionProtocolsOrProposalsContent,
  FindRetentionProtocolsOrProposalsHistory,
  // retention-proposals
  CancelProposal,
  FindRetentionProtocolsOrProposalsHistory,
  SavePerformShreddingProcedure,
  SavePerformDeletion,
  RemoveRetentionProposalObjects
  // presentation
];
