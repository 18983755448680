import { PaginatedResult } from "../../../lib/contract/Pagination";
import { ApiListResponse } from "../struct/ApiTypes";

export class ApiListResponseMapper {
  static mapListToPagination<ResponseItem, Item>(
    response: ApiListResponse<ResponseItem>,
    mapper: (responseItem: ResponseItem) => Item
  ): PaginatedResult<Item> {
    const { results } = response;

    return ApiListResponseMapper.toPaginatedResult(
      results ? ApiListResponseMapper.mapEntries(results, mapper) : [],
      response
    );
  }

  static mapEntries<ResponseItem, Item>(
    entries: ResponseItem[],
    mapper: (responseItem: ResponseItem) => Item
  ) {
    return entries.map(mapper);
  }

  static toPaginatedResult<ResponseItem, Item>(
    items: Item[],
    response: ApiListResponse<ResponseItem>
  ): PaginatedResult<Item> {
    return {
      items,
      page: response.currentPage,
      itemsPerPage: response.pageSize,
      total: response.recordCount,
      totalCount: response.totalCount
    };
  }
}
