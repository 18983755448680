import {
  StyledFormControlWide,
  useStyles
} from "presentation/core/components/dialog/Dialog.styles";
import { TabAndDialogChannelType } from "presentation/core/components/dialog/_types";
import { Form } from "formik";
import React from "react";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import { ChangeFileMarkFormValues } from "./_types";
import { ChannelForm } from "../ChannelForm/ChannelForm";
import { FormState } from "../../../../designSystem/Form/Form";
import { useSelector } from "react-redux";
import { RootStateType } from "../../../../reducers";
import moment from "moment";
import { FilePlanGroups } from "presentation/core/features/login/_types";
import { GeneralFileMarkField } from "../../form/fields/GeneralFileMarkField";

interface OwnProps {
  originalFileMark?: string;
  filePlanId?: string;
  channel: TabAndDialogChannelType;
  settleDate?: Date;
  closureDate?: Date;
}

export const ChangeFileMarkForm = ({
  channel,
  filePlanId,
  originalFileMark = "",
  settleDate,
  closureDate
}: OwnProps) => {
  const classes = useStyles();
  const { filePlans } = useSelector(
    (state: RootStateType) => state.loginReducer.global
  );

  const filePlan = filePlans.find((plan) => plan.nodeId === filePlanId);

  return (
    <ChannelForm<ChangeFileMarkFormValues>
      initialValues={{
        fileMark: originalFileMark
      }}
      channel={channel}
    >
      {({ values }: FormState<ChangeFileMarkFormValues>) => {
        const fileMark = filePlan?.filePlanGroups.find(
          (item) => item.identificator === values.fileMark
        );

        const retentionMode = fileMark
          ? `${fileMark.retentionMark}/${fileMark.retentionPeriod}`
          : "";

        const fileMarksFilteringFunction = (
          fileMarkToFilter: FilePlanGroups
        ) => {
          const { validTo, validFrom } = fileMarkToFilter;
          if (settleDate && closureDate) {
            return (
              moment(validFrom).isSameOrBefore(moment(settleDate)) &&
              moment(settleDate).isSameOrBefore(moment(validTo))
            );
          }
          return true;
        };

        return (
          <Form className={classes.modalSmall}>
            <GeneralFileMarkField
              Component={StyledFormControlWide}
              isReadonly={!filePlan}
              values={values}
              fileMarks={filePlan?.filePlanGroups || []}
              filteringFunction={fileMarksFilteringFunction}
            />
            {retentionMode && (
              <div>
                {t(translationPath(lang.general.retentionMode))}
                {": "}
                <strong>{retentionMode}</strong>
              </div>
            )}
          </Form>
        );
      }}
    </ChannelForm>
  );
};
