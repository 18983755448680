import { BaseModalProps } from "presentation/designSystem/Modal/useModal";
import React, { useEffect } from "react";
import { Address } from "../../../../../domain/struct/nameRegistry/Address";
import { SubjectType } from "../../../../../domain/struct/nameRegistry/SubjectType";
import { useForm } from "../../../../designSystem/Form/v2/Form";
import { Modal } from "../../../../designSystem/Modal/Modal";
import { useMutation } from "../../../../share/hook/query/useQM";
import { translationPath } from "../../../../share/utils/getPath";
import { lang, t } from "../../../../translation/i18n";
import { useSubjectModalContext } from "../../contexts/SubjectModalContextProvider";
import { AddressForm } from "../../form/AddressesForm/AddressForm";
import { useFindAddresses } from "../../hooks/useFindAddresses";

export interface CreateAddressModalProps extends BaseModalProps {
  selected?: boolean;
  onCreate?: (values: AddressFormFields) => void;
  address?: Address;
}

const TITLE_MAP = {
  create: t(translationPath(lang.dialog.addressDialog.create)),
  edit: t(translationPath(lang.dialog.addressDialog.edit))
};

export interface AddressFormFields {
  addressType: string;
  street: string;
  houseNumberType: string;
  houseNumber: string;
  orientationHouseNumberAddendum: string;
  orientationHouseNumber: string;
  city: string;
  cityPart: string;
  postalCode: string;
  country: string;
}

const DEFAULT_COUNTRY = "CZE";

export const AddressModal = ({
  onCancel,
  onOk,
  address
}: CreateAddressModalProps) => {
  const [addressForm] = useForm<Address>();
  const { persistence } = useSubjectModalContext();
  const { data: addressTypes } = useFindAddresses({ retry: false });

  const getNotificationForError = () => ({
    message:
      persistence?.getSubjectData()?.subjectType === SubjectType.Person
        ? t(translationPath(lang.dialog.notifications.addressTypeAlreadyExists))
        : t(
            translationPath(
              lang.dialog.notifications
                .subjectCanHaveOneCompanyHeadquartersAddress
            )
          )
  });

  const { isLoading, mutate: addAddress } = useMutation(
    (address: Address) => persistence.addAddress(address),
    {
      onSuccess() {
        onOk?.();
      },
      onErrorNotification: getNotificationForError,
      onSuccessNotification: {
        message: t(translationPath(lang.dialog.notifications.addressWasAdded))
      }
    }
  );

  const { isLoading: isLoadingUpdate, mutate: updateAddress } = useMutation(
    (address: Address) => persistence.updateAddress(address),
    {
      onSuccess() {
        onOk?.();
      },
      onErrorNotification: getNotificationForError,
      onSuccessNotification: {
        message: t(translationPath(lang.dialog.notifications.addressWasEdited))
      }
    }
  );

  useEffect(() => {
    const {
      city,
      cityPart,
      postalCode,
      houseNumber,
      houseNumberType,
      street,
      orientationHouseNumber,
      orientationHouseNumberAddendum,
      addressType,
      country
    } = address || { country: DEFAULT_COUNTRY };
    addressForm.setFieldsValue({
      city,
      cityPart,
      postalCode,
      houseNumber,
      houseNumberType,
      street,
      orientationHouseNumber,
      orientationHouseNumberAddendum,
      addressType,
      country
    });
  }, [address, addressForm]);

  const handleOk = async () => {
    try {
      await addressForm.validateFields();
      if (address) {
        await updateAddress({
          ...address,
          ...addressForm.getFieldsValue()
        });
      } else {
        await addAddress(addressForm.getFieldsValue());
      }
    } catch {}
  };

  return (
    <Modal
      open={true}
      title={address ? TITLE_MAP.edit : TITLE_MAP.create}
      onCancel={onCancel}
      onOk={handleOk}
      confirmLoading={isLoading || isLoadingUpdate}
      okText={t(translationPath(lang.modal.ok))}
      cancelText={t(translationPath(lang.modal.cancel))}
    >
      <AddressForm form={addressForm} address={address} />
    </Modal>
  );
};
