import { ApiTypes } from "../struct/ApiTypes";
import { Contact } from "../../../domain/struct/codelist/Contact";

export type ApiContacts = ApiTypes["ISFG.SpisUm.ClientSide.Models.NameRegister.CodeLists.ContactType"];

export class ContactTypeApiMapper {
  static mapToDomain(response: ApiContacts[]): Contact[] {
    return response.map((apiContact) => ({
      code: apiContact.code || "",
      czechName: apiContact.czechName || ""
    }));
  }
}
