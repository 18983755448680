import { ApiListResponseMapper } from "infrastructure/api/mapper/ApiListResponseMapper";
import { ApiListResponse } from "infrastructure/api/struct/ApiTypesV2";
import { stringOrDefault } from "lib/string";
import { File } from "presentation/core/entities";
import {
  ActiveComponent,
  ActiveComponentBody
} from "../../../../../core/components/dialog/tabs/components/_types";

export class Mappers {
  static mapDocumentForSealListToDomain(list: ApiListResponse<File>) {
    return ApiListResponseMapper.mapListToPagination(
      list,
      Mappers.mapDocumentToDomain
    );
  }

  static mapDocumentToDomain(document: File): File {
    return {
      ...document,
      mimeType: stringOrDefault(document.mimeType)
    };
  }

  static mapToApi(activeComponent: ActiveComponent): ActiveComponentBody {
    return {
      type: activeComponent.type || null,
      isReadable: activeComponent.isReadable || null
    };
  }
}
