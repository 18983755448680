import { SortingConfig } from "@isfg/lib/build/contract/Sorting";
import { HttpClient } from "infrastructure/service/HttpClient";
import { ReturnForRework } from "../types/ReturnForRework";
import { PaginatedResult, PaginationConfig } from "lib/contract/Pagination";
import { ApiUrls } from "../../../../../../../infrastructure/api/struct/ApiTypesV2";
import { Mappers } from "./mappers";

const URL: ApiUrls = {
  GetOverviewsForSignature: "/api/app/v2/overview/return-for-rework"
};

export const getReturnForRework = (
  pagination: PaginationConfig,
  sorting?: SortingConfig
): Promise<PaginatedResult<ReturnForRework>> => {
  const httpClient = new HttpClient();
  return httpClient
    .fetchPaginated<ReturnForRework>({
      url: URL.GetOverviewsForSignature,
      pagination,
      sorting
    })
    .then(Mappers.mapListToDomain);
};
