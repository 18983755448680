import { Select } from "antd";
import { DateTimeFormat } from "lib/dateTime";
import moment from "moment";
import { FilePlanGroups } from "presentation/core/features/login/_types";
import { DatePicker } from "presentation/designSystem/DatePicker/DatePicker";
import {
  Form,
  FormInstance,
  Item
} from "presentation/designSystem/Form/v2/Form";
import { Col, Row } from "presentation/designSystem/Grid/Grid";
import { Input } from "presentation/designSystem/Input/Input";
import { FileMarkField } from "presentation/modules/evidence/features/typeFiles/components/FileMarkField";
import {
  lastPathMember,
  translationPath
} from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { sslPropsProxy } from "../../../../../core/types";
import { useIsValidOrExpireFilePlan } from "../../../../../share/hook/filePlan/useIsValidOrExpireFilePlan";
import { SelectLockMethod } from "../modals/LockTypeFile";
import { DetailFormFieldTypes, DetailTypeFileDocument } from "../types";

interface TypeFileDetailFormProps {
  formData: DetailTypeFileDocument;
  form: FormInstance<DetailFormFieldTypes>;
}

export const TypeFileDetailClosingForm = ({
  form,
  formData
}: TypeFileDetailFormProps) => {
  const createdAt = moment(formData.createdAt!);
  const closureDate = moment(formData.closureDate!);
  const formInitialValues: DetailFormFieldTypes = {
    ...formData,
    createdAt: moment(createdAt),
    closureDate: moment(closureDate)
  };

  const filePlans = useIsValidOrExpireFilePlan("");
  const [fileMarks, setFileMarks] = useState<FilePlanGroups[]>(
    [] as FilePlanGroups[]
  );

  useEffect(() => {
    setFileMarks(filePlans[0]?.filePlanGroups || []);
  }, [filePlans]);

  const handleMarkChange = (value: string) => {
    form.setFieldsValue({
      fileMarkId: value
    });
  };

  return (
    <>
      <Form initialValues={formInitialValues} form={form}>
        <Row gutter={6}>
          <Col span={6}>
            <StyledSelectItem
              name="settleMethod"
              label={t(
                translationPath(
                  lang.table.openTypeFilesTable.methodOfSettlement
                )
              )}
            >
              <Select disabled>
                {Object.keys(SelectLockMethod).map((key) => (
                  <option key={key} value={key}>
                    {SelectLockMethod[key as keyof typeof SelectLockMethod]}
                  </option>
                ))}
              </Select>
            </StyledSelectItem>
          </Col>
          <Col span={6}>
            <Item
              name="closureDate"
              label={t(translationPath(lang.general.fileClosureDate))}
            >
              <DatePicker
                disabled={true}
                format={DateTimeFormat.DateTimeNoSeconds}
                name={lastPathMember(sslPropsProxy.settleDate).path}
                style={{ width: "15rem" }}
              />
            </Item>
          </Col>
          <Col span={6}>
            <StyledSelectItem
              name="fileMarkId"
              label={t(translationPath(lang.general.fileMark))}
            >
              <FileMarkField
                readOnly={true}
                initialFileMark={formData?.fileMarkId}
                fileMarks={fileMarks}
                onChange={handleMarkChange}
              />
            </StyledSelectItem>
          </Col>
          <Col span={6}>
            <StyledSelectItem label={t(translationPath(lang.general.filePlan))}>
              <Input value={filePlans[0].name} disabled />
            </StyledSelectItem>
          </Col>
        </Row>
      </Form>
    </>
  );
};

const StyledSelectItem = styled(Item)`
  .ant-select {
    max-width: 100%;
  }
`;
