import { FileConcernedSubjectRepositoryToken } from "../../domain/di/token/file";
import type { FileConcernedSubjectRepository } from "../../domain/service/file/FileConcernedSubjectRepository";
import { PaginationConfig } from "../../lib/contract/Pagination";
import {
  DInject,
  DInjectable
} from "../../presentation/core/features/dependencyInjection";

@DInjectable()
export class FindFileConcernedSubject {
  constructor(
    @DInject(FileConcernedSubjectRepositoryToken)
    protected repository: FileConcernedSubjectRepository
  ) {}

  findAllPaginated(nodeId: string, config: PaginationConfig) {
    return this.repository.findAllPaginated(nodeId, config);
  }
}
