import { callAsyncAction } from "presentation/core/action";
import {
  documentRegisterActionType,
  documentSaveAndUpdateActionType,
  documentUpdateActionType
} from "presentation/core/api/document/_actions";
import { SslProperties } from "presentation/core/api/models";
import {
  primaryAction,
  secondaryAction
} from "presentation/core/components/dialog/lib/actionsFactory";
import { CommentsTab } from "presentation/core/components/dialog/tabs/comments";
import { ComponentsTab } from "presentation/core/components/dialog/tabs/components";
import { SettleTab } from "presentation/core/components/dialog/tabs/settle/SettleTab";
import {
  DialogContentType,
  DialogType
} from "presentation/core/components/dialog/_types";
import { documentViewAction__Refresh } from "presentation/core/components/documentView/_actions";
import NamedTitle from "presentation/core/components/namedTitle";
import { GenericDocument } from "presentation/core/types";
import React from "react";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import { createDocumentDialog } from "../baseDocumentDialog/documentDialogFactory";
import { handoverDocument } from "../documentHandoverDialog/_actions";
import { HandoverDocumentPayloadType } from "../documentHandoverDialog/_types";
import MetaDataFormTab from "./MetadataFormTab";
import { documentConcernedSubjectTab } from "../../dialogTabs/ConcernedSubject";

export const analogDetailsDialogContent: DialogContentType = createDocumentDialog(
  {
    actions: () => [
      primaryAction(
        t(translationPath(lang.dialog.form.toRegister)),
        ({ dispatch, channels, onClose, buttonState }) => {
          const formValues = channels?.Metadata?.state?.formValues;
          if (!formValues) {
            return;
          }

          const onSuccess = () => {
            // set all tabs to saved to close modal
            for (const key in channels) {
              if (!channels.hasOwnProperty(key)) continue;
              channels[key].setIsSaved(true);
            }

            dispatch(documentViewAction__Refresh(true));
            onClose();
          };

          const onError = () => {
            buttonState.setIsPending(false);
            channels?.Metadata?.setIsSaved(false);
          };

          buttonState.setIsPending(true);

          dispatch(
            callAsyncAction({
              action: documentSaveAndUpdateActionType,
              onError,
              onSuccess,
              payload: {
                payload: {
                  body: {
                    properties: formValues as SslProperties
                  },
                  nodeId: channels?.Metadata?.state?.id as string
                },
                registerAction: documentRegisterActionType,
                updateAction: documentUpdateActionType
              }
            })
          );
        }
      ),
      secondaryAction(
        t(translationPath(lang.dialog.form.toRegisterAndRefer)),
        ({ dispatch, channels, onClose, buttonState }) => {
          const formValues = channels?.Metadata?.state?.formValues;

          if (!formValues) {
            document.getElementById("Ssl-delivery-mode")?.scrollIntoView();

            return;
          }

          const onSuccess = () => {
            // set all tabs to saved to close modal
            for (const key in channels) {
              if (!channels.hasOwnProperty(key)) continue;
              channels[key].setIsSaved(true);
            }

            dispatch(
              handoverDocument({
                data: {
                  id: channels?.Metadata?.state?.id
                },
                onClose: () => {
                  dispatch(documentViewAction__Refresh(true));
                }
              } as HandoverDocumentPayloadType)
            );
            onClose();
          };

          const onError = () => {
            buttonState.setIsPending(false);
            channels?.Metadata?.setIsSaved(false);
          };

          buttonState.setIsPending(true);

          dispatch(
            callAsyncAction({
              action: documentSaveAndUpdateActionType,
              onError,
              onSuccess,
              payload: {
                payload: {
                  body: {
                    properties: formValues as SslProperties
                  },
                  nodeId: channels?.Metadata?.state?.id as string
                },
                registerAction: documentRegisterActionType,
                updateAction: documentUpdateActionType
              }
            })
          );
        }
      )
    ],
    tabs: [
      {
        content: MetaDataFormTab,
        label: t(translationPath(lang.dialog.tabs.metadata))
      },
      // Znemožnění nahrání komponenty k analogovému dokumentu task - SV1-3134
      // {content: ComponentsTab,
      //   label: t(translationPath(lang.dialog.tabs.components))},
      documentConcernedSubjectTab,
      {
        content: CommentsTab,
        label: t(translationPath(lang.dialog.tabs.notes))
      },
      {
        content: SettleTab,
        filter: ({ dialogProps }) => {
          const { state } =
            (dialogProps.data as GenericDocument)?.properties?.ssl || {};

          return SettleTab.filter(state);
        },
        label: t(translationPath(lang.dialog.tabs.settle))
      }
    ],
    title: (props) => (
      <NamedTitle
        text={t(translationPath(lang.dialog.title.analogDocumentIncome))}
        {...props}
      />
    ),
    type: DialogType.AnalogDetails
  }
);
