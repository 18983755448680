import { NationalityRepository } from "../../../domain/service/codelist/NationalityRepository";
import { Nationality } from "../../../domain/struct/codelist/Nationality";
import {
  DInject,
  DInjectable
} from "../../../presentation/core/features/dependencyInjection";
import { HttpClient } from "../../service/HttpClient";
import { ApiNationality, NationalityApiMapper } from "./NationalityApiMapper";
import { ApiUrl } from "../struct/ApiTypes";

const URL: ApiUrl = "/api/app/v1/codelists/nationalities";

@DInjectable()
export class NationalityApiRepository implements NationalityRepository {
  constructor(@DInject(HttpClient) protected httpClient: HttpClient) {}

  findAll(): Promise<Nationality[]> {
    return this.httpClient
      .fetchWithThrow<ApiNationality[]>(URL)
      .then(NationalityApiMapper.mapToDomain);
  }
}
