import GestureIcon from "@mui/icons-material/Gesture";
import { MenuItemType } from "presentation/core/components/menu/_types";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import SignatureBookForSeal from "./features/forSeal/SignatureBookForSeal";
import SignatureBookForSignature from "./features/forSignature/SignatureBookForSignature";
import { SignatureBookRoutes } from "./routes";
import React from "react";

export const signatureBookMenuItems: MenuItemType[] = [
  {
    __testId: "menu-main-signaturebook",
    icon: <GestureIcon />,
    submenu: [
      {
        __testId: "menu-main-signaturebook-forsignature",
        content: <SignatureBookForSignature />,
        text: t(translationPath(lang.menu.items.forSignature)),
        url: SignatureBookRoutes.SIGNATUREBOOK_FORSIGNATURE
      },
      {
        __testId: "menu-main-signaturebook-forseal",
        content: <SignatureBookForSeal />,
        text: t(translationPath(lang.menu.items.forSeal)),
        url: SignatureBookRoutes.SIGNATUREBOOK_FORSEAL
      }
      /*, v1.1
      {
        __testId: "menu-main-signaturebook-signed",
        content: <Signed />,
        text: t(translationPath(lang.menu.items.signed)),
        url: SignatureBookRoutes.SIGNATUREBOOK_SIGNED
      }*/
    ],
    text: t(translationPath(lang.menu.items.signatureBook))
  }
];
