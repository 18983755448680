import { ColumnType } from "@isfg/react-components/build/Table/Table";
import { FileSize } from "presentation/share/components/file/FileSize/FileSize";
import React from "react";

export * from "@isfg/react-components/build/Table/column/column";

export const createFileSizeColumn = <Row,>(
  column: ColumnType<Row>
): ColumnType<Row> => ({
  ...column,
  render(value) {
    return <FileSize size={Number(value)} />;
  }
});
