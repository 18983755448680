import { DocumentHistoryRepository } from "domain/service/document/DocumentHistoryRepository";
import { DInjectionToken } from "../../../presentation/core/features/dependencyInjection";
import { DocumentConcernedSubjectRepository } from "../../service/document/DocumentConcernedSubjectRepository";
import { ConceptConcernedSubjectRepository } from "../../service/document/ConceptConcernedSubjectRepository";

export const DocumentConcernedSubjectRepositoryToken = new DInjectionToken<DocumentConcernedSubjectRepository>(
  "domain.document.document-concerned-subject-repository"
);

export const ConceptConcernedSubjectRepositoryToken = new DInjectionToken<ConceptConcernedSubjectRepository>(
  "domain.document.concept-concerned-subject-repository"
);

export const DocumentHistoryRepositoryToken = new DInjectionToken<DocumentHistoryRepository>(
  "domain.document.DocumentHistory-repository"
);
