import { FindSignerGroups } from "useCase/user/FindSignerGroups";
import { SignerGroupRepositoryToken } from "domain/di/token/user";
import { SignerGroupApiRepository } from "infrastructure/api/user/SignerGroupApiRepository";
import { GetUserInfo } from "useCase/user/GetUserInfo";
import { UserRepositoryToken } from "domain/di/token/user";
import { UserApiRepository } from "infrastructure/api/user/UserApiRepository";
import { FindGroup } from "useCase/user/FindGroup";
import { GroupRepositoryToken } from "domain/di/token/user";
import { GroupApiRepository } from "infrastructure/api/user/GroupApiRepository";
export const userProviders = [
  // repository
  {
    provide: UserRepositoryToken,
    useClass: UserApiRepository
  },

  {
    provide: GroupRepositoryToken,
    useClass: GroupApiRepository
  },
  {
    provide: SignerGroupRepositoryToken,
    useClass: SignerGroupApiRepository
  },
  // use case
  GetUserInfo,
  FindSignerGroups,
  FindGroup
];
