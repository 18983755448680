import styled from "styled-components";

export const StyledNotificationLayout = styled("div")(() => ({
  ".MuiSnackbar-root:not(:last-child)": {
    marginBottom: 15
  },
  bottom: 0,
  position: "absolute",
  right: 0
}));
