import { PaginationConfig } from "lib/contract/Pagination";
import { useQM } from "presentation/share/hook/query/useQM";
import { getVersions } from "../api";

export const useGetVersions = (
  nodeId: string,
  config: PaginationConfig,
  enabled?: boolean
) => {
  return useQM(
    ["documents/versions", nodeId, config],
    () => getVersions(nodeId, config),
    { enabled }
  );
};
