import { ApiTypes } from "../struct/ApiTypes";
import { ApiListResponse } from "../struct/ApiTypesV2";
import { ApiListResponseMapper } from "../mapper/ApiListResponseMapper";
import { ConversionAll } from "../../../domain/struct/administration/ConversionAll";
import { PaginatedResult } from "lib/contract/Pagination";
import { DateTimeFormat, formatDate } from "lib/dateTime";

export type ApiConversionAll = ApiTypes["ISFG.SpisUm.ClientSide.Models.V1.Evidence.ConversionResponse"];

export class ConversionAllApiMapper {
  static mapListToDomain(
    apiConversionAll: ApiListResponse<ApiConversionAll>
  ): PaginatedResult<ConversionAll> {
    return ApiListResponseMapper.mapListToPagination(
      apiConversionAll,
      ConversionAllApiMapper.mapToDomain
    );
  }

  static mapToDomain(apiConversionAll: ApiConversionAll): ConversionAll {
    return {
      conversionId: apiConversionAll.conversionId,
      conversionDate: apiConversionAll.conversionDate || undefined,
      component:
        apiConversionAll.component +
          "\n" +
          formatDate(
            apiConversionAll.conversionDate,
            DateTimeFormat.FullDateTime
          ) || undefined,
      author: apiConversionAll.author || undefined,
      removalDate: apiConversionAll.removalDate || undefined,
      type: apiConversionAll.type || undefined,
      ssid: apiConversionAll.ssid || undefined
    };
  }
}
