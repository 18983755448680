import { isObject } from "../../../lib/type";
import { SubjectType } from "./SubjectType";

export type PersonId = string;

export interface Person {
  readonly id?: PersonId;
  readonly identifier?: string;
  readonly fullName?: string;
  readonly firstName?: string;
  readonly surname?: string;
  readonly title?: string;
  readonly degree?: string;
  readonly bornDate?: string;
  readonly bornPlace?: string;
  readonly citizenship?: string;
  readonly maidenName?: string;
  readonly processedAt?: string;
  readonly subjectType: SubjectType;
}

export const isPerson = (maybePerson: unknown): maybePerson is Person => {
  if (!isObject(maybePerson)) {
    return false;
  }

  const reTyped: { subjectType?: SubjectType } = maybePerson;

  return reTyped.subjectType === SubjectType.Person;
};
