import moment from "moment";
import type { Moment } from "moment";
export { default as dateTime } from "moment";

export type DateTime = Moment;

export enum DateTimeFormat {
  FullDateTime = "DD.MM.YYYY HH:mm:ss",
  DateTimeNoSeconds = "DD.MM.YYYY HH:mm",
  Date = "DD.MM.YYYY",
  HoursMinutes = "HH:mm",
  HoursMinutesSeconds = "HH:mm:ss",
  Year = "YYYY"
}

export const formatDate = (
  date: DateTime | string | number | null | undefined,
  format: DateTimeFormat | string = DateTimeFormat.Date
) => {
  if (!date) {
    return undefined;
  }

  return moment(date).format(format);
};

export const toDate = (
  date: DateTime | string | number | null | undefined,
  format?: DateTimeFormat | string
) => moment(date, format);
