import { GenericDocument } from "presentation/core/types";
import { t } from "presentation/translation/i18n";
import { DataColumn } from "presentation/core/components/dataTable/_types";
import React from "react";
import { FilePlanColumnValue } from "./FilePlanColumnValue";

export const filePlanColumn: DataColumn<GenericDocument> = {
  keys: ["properties.ssl.filePlan"],
  label: t("general:filePlan"),
  getValue(item) {
    const filePlanId = item.properties?.ssl?.filePlan;

    if (!filePlanId) {
      return;
    }

    return <FilePlanColumnValue filePlanId={filePlanId} />;
  }
};
