import { formatDate as formatDateOriginal } from "../../lib/dateTime";

const NULL_DATE_PATTERN = "0001-01-01T00:00:00";

export enum ApiDateTimeFormat {
  Date = "YYYY-MM-DD"
}

export const formatDate: typeof formatDateOriginal = (date) =>
  formatDateOriginal(date, ApiDateTimeFormat.Date);

export const mapNullDateValue = (value: string | undefined | null) =>
  value === NULL_DATE_PATTERN ? null : value;
