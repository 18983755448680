import {
  DInject,
  DInjectable
} from "presentation/core/features/dependencyInjection";
import { ApiUrls } from "infrastructure/api/struct/ApiTypes";
import { HttpClient, HttpMethod } from "infrastructure/service/HttpClient";
import { LogoutRepository } from "domain/service/authentication/LogoutRepository";

const URL: ApiUrls = {
  Logout: "/api/auth/v1/authentication/logout"
};

@DInjectable()
export class LogoutApiRepository implements LogoutRepository {
  constructor(@DInject(HttpClient) protected httpClient: HttpClient) {}
  logout(): Promise<void> {
    return this.httpClient.fetchWithThrow(URL.Logout, HttpMethod.Post, {});
  }
}
