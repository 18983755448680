import { DInjectable } from "presentation/core/features/dependencyInjection";
import { Address } from "../../../../domain/struct/nameRegistry/Address";
import { t } from "../../../translation/i18n";
import { CountryType } from "../../../../domain/struct/codelist/CountryType";

@DInjectable()
export class AddressFormatHelpers {
  formatAddress(
    {
      addressType,
      street,
      houseNumber,
      orientationHouseNumber,
      orientationHouseNumberAddendum,
      city,
      cityPart,
      country,
      postalCode
    }: Address,
    countryCodesAndNames?: CountryType[] | undefined
  ) {
    const countryCzechName =
      countryCodesAndNames &&
      countryCodesAndNames.find(
        (countryCodeAndName) => country === countryCodeAndName.code
      );

    return `${street ?? ""} ${houseNumber ?? ""}${
      orientationHouseNumber ? "/" + orientationHouseNumber : ""
    }${orientationHouseNumberAddendum ? orientationHouseNumberAddendum : ""}, ${
      postalCode ?? ""
    } ${cityPart ? cityPart : ""}, ${city ?? ""}, ${
      countryCzechName?.czechName ?? ""
    } ${this.getAddressTypeTranslation(addressType)} `;
  }

  getAddressTypeTranslation(addressType?: string) {
    if (!addressType) {
      return "";
    }

    const translation = t(`enums:addressType.${addressType}`);

    return `(${translation})`;
  }
}
