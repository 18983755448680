import { getService } from "presentation/core/features/dependencyInjection";
import { useMutation } from "../../../share/hook/query/useQM";
import { RequestPasswordReset } from "../../../../useCase/authentication/RequestPasswordReset";
import { t } from "../../../translation/i18n";
import { translationPath } from "../../../share/utils/getPath";
import lang from "../../../translation/lang";

export const useVerifyTokenPassword = () => {
  const requestPasswordReset = getService(RequestPasswordReset);

  return useMutation(
    (token: string) => requestPasswordReset.validatePasswordReset(token),
    {
      onSuccessNotification: null,
      onErrorNotification: {
        message: t(translationPath(lang.dialog.notifications.actionFailed))
      }
    }
  );
};
