import { fetchDocument } from "presentation/core/action";
import { SslConcept } from "presentation/core/api/models";
import { dialogOpenAction } from "presentation/core/components/dialog/_actions";
import { DialogType } from "presentation/core/components/dialog/_types";
import { metaFormAction__Update } from "presentation/core/components/MetaForm/_actions";
import { Notification } from "presentation/designSystem/notification/Notification";
import { Concept } from "presentation/core/types";
import { put, takeLatest } from "redux-saga/effects";
import { translationPath } from "presentation/share/utils/getPath";
import { handleResponse } from "presentation/share/utils/typesafeActions";
import { t } from "presentation/translation/i18n";
import lang from "presentation/translation/lang";
import { getType } from "typesafe-actions";
import {
  dialogOpenConceptDetails,
  DialogOpenConceptDetailsActionType
} from "./_actions";

export function* watchDialogOpenConceptDetailsAction() {
  yield takeLatest(
    getType(dialogOpenConceptDetails),
    function* ({ payload }: DialogOpenConceptDetailsActionType) {
      const document = payload!.data as Concept;

      yield put(
        fetchDocument.request({
          id: document.id,
          nodeType: document.nodeType
        })
      );

      const [successResponse, , success] = yield handleResponse(fetchDocument);

      if (!success) {
        Notification.error({
          message: t(translationPath(lang.dialog.notifications.actionFailed))
        });
        return;
      }

      yield put(
        metaFormAction__Update({
          documentId: successResponse.entry?.id,
          formValues: {
            subject: "",
            ...successResponse.entry?.properties?.ssl,
            createdAt: successResponse.entry?.createdAt,
            owner: successResponse.entry?.properties?.cm?.owner?.displayName
          } as SslConcept,
          isLoading: false,
          isLocked: successResponse.entry?.isLocked,
          nodeType: successResponse.entry?.nodeType
        })
      );

      yield put(
        dialogOpenAction({
          dialogProps: payload,
          dialogType: DialogType.ConceptDetails
        })
      );
    }
  );
}
