import { ApiListResponse, ApiTypes } from "../struct/ApiTypesV2";
import { PaginatedResult } from "../../../lib/contract/Pagination";
import { History } from "../../../domain/struct/nameRegistry/History";
import { stringOrDefault } from "../../../lib/string";
import { ApiListResponseMapper } from "../mapper/ApiListResponseMapper";

export type ApiHistory = ApiTypes["ISFG.SpisUm.ClientSide.Models.V2.Common.TransactionHistory"];

export class HistoryApiMapper {
  static mapToDomain(history: ApiHistory): History {
    return {
      id: history.id,
      pid: "" + history.pid,
      occuredAt: stringOrDefault(history.occuredAt),
      description: stringOrDefault(history.description),
      userId: stringOrDefault(history.userId),
      eventType: stringOrDefault(history.eventType)
    };
  }

  static mapHistoryToDomain(
    history: ApiListResponse<ApiHistory>
  ): PaginatedResult<History> {
    return ApiListResponseMapper.mapListToPagination(
      history,
      HistoryApiMapper.mapToDomain
    );
  }
}
