import { ApiTypes } from "../struct/ApiTypes";
import { HouseNumberType } from "../../../domain/struct/codelist/HouseNumberType";

export type ApiHouseNumbers = ApiTypes["ISFG.SpisUm.ClientSide.Models.NameRegister.CodeLists.HouseNumberType"];

export class HouseNumberApiMapper {
  static mapToDomain(response: ApiHouseNumbers[]): HouseNumberType[] {
    return response.map((apiContact) => ({
      code: apiContact.code || "",
      czechName: apiContact.czechName || ""
    }));
  }
}
