import {
  AddBoxOutlined,
  Alarm,
  AlarmOff,
  AllInbox,
  SwapHoriz
} from "@mui/icons-material";
import { TableRowSelection } from "antd/lib/table/interface";
import { DateTimeFormat } from "lib/dateTime";
import { GetRemoteDataClb } from "presentation/designSystem/Table/RemoteTable";
import {
  createColumn,
  createDateTimeColumn
} from "presentation/designSystem/Table/column/column";
import React, { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { RetentionForShredding } from "../../../../../../domain/struct/recordRetention/RetentionForShredding";
import { dialogOpenAction } from "../../../../../core/components/dialog/_actions";
import { DialogType } from "../../../../../core/components/dialog/_types";
import { getNodeTypeByIdentifier } from "../../../../../core/mappers/api/general";
import { GenericDocument } from "../../../../../core/types";
import { BreadcrumbForTable } from "../../../../../designSystem/Breadcrumb/Breadcrumb";
import { RemoteTable } from "../../../../../designSystem/Table/RemoteTable";
import {
  ColumnType,
  TableActions,
  useRemoteTableApi
} from "../../../../../designSystem/Table/Table";
import { DocumentType } from "../../../../../enums";
import { actionShowDetail } from "../../../../../share/components/table/actionFactory";
import { FileMarkColumnValue } from "../../../../../share/components/table/column/document/FilePlan/FileMarkColumnValue";
import { FilePlanColumnValue } from "../../../../../share/components/table/column/document/FilePlan/FilePlanColumnValue";
import { getDetailActionForDocumentNewDataModel } from "../../../../../share/utils/action/dialog/documentDetail";
import { translationPath } from "../../../../../share/utils/getPath";
import { lang, t } from "../../../../../translation/i18n";
import { useFindRetentionForShredding } from "../hooks/useFindRetentionForShredding";

const COLUMNS: ColumnType<RetentionForShredding>[] = [
  createColumn({
    dataIndex: "pid",
    title: t(translationPath(lang.general.identifier))
  }),
  createColumn({
    dataIndex: "fileIdentifier",
    title: `${t(translationPath(lang.general.referenceNumber))}/${t(
      translationPath(lang.general.fileIdentifier)
    )}`,
    render: (text, row) => {
      if (row.fileIdentifier) {
        return row.fileIdentifier;
      }
      return row.ssid;
    }
  }),
  createColumn({
    dataIndex: "subject",
    title: t(translationPath(lang.general.subject))
  }),
  createColumn({
    dataIndex: "filePlan",
    title: t(translationPath(lang.general.filePlan)),
    render: (text, row) => {
      const { filePlan } = row;
      if (!filePlan) {
        return null;
      }
      return <FilePlanColumnValue filePlanId={filePlan} />;
    }
  }),
  createColumn({
    dataIndex: "fileMark",
    title: t(translationPath(lang.general.fileMark)),
    render: (text, row) => {
      const { fileMark } = row;
      if (!fileMark) {
        return null;
      }
      return <FileMarkColumnValue fileMarkId={fileMark} />;
    }
  }),
  createColumn({
    dataIndex: "retentionMode",
    title: t(translationPath(lang.general.retentionMode))
  }),
  createDateTimeColumn(
    {
      dataIndex: "shreddingYear",
      title: t(translationPath(lang.general.yearOfShredding)),
      defaultSortOrder: "ascend",
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: true
    },
    DateTimeFormat.Year
  ),
  createColumn({
    dataIndex: "location",
    title: t(translationPath(lang.general.location))
  })
];

const ForShreddingTable = React.memo(() => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
  const [tableApi, onRemoteTableApi] = useRemoteTableApi();

  const dispatch = useDispatch();
  const findRetentionForShredding = useFindRetentionForShredding();

  const ACTIONS: TableActions<RetentionForShredding> = useMemo(
    () => ({
      single: [
        actionShowDetail({
          action(selected: RetentionForShredding[]) {
            const nodeType = selected[0].nodeType || "";
            const action = getDetailActionForDocumentNewDataModel(nodeType);
            action &&
              dispatch(
                action({
                  canUploadComponents: false,
                  data: {
                    id: selected[0].nodeId,
                    nodeType
                  } as any,
                  hideManageShipmentsIcon: true,
                  initiator: nodeType,
                  isReadonly: true
                })
              );
          }
        }),
        {
          key: "change-file-mark",
          icon: <SwapHoriz />,
          tooltip: t(translationPath(lang.general.changeFileMark)),
          action(selected: RetentionForShredding[]) {
            dispatch(
              dialogOpenAction({
                dialogProps: {
                  data: {
                    id: selected[0].nodeId,
                    properties: {
                      ssl: {
                        fileMark: selected[0].fileMark,
                        filePlan: selected[0].filePlan
                      }
                    },
                    nodeType: getNodeTypeByIdentifier(selected[0])
                  }
                },
                dialogType: DialogType.ChangeFileMark
              })
            );
          }
        },
        {
          key: "shredding-discard",
          icon: <Alarm />,
          tooltip: t(translationPath(lang.general.discardFromShredding)),
          canBeDisplayed: (selected) => !selected?.discardTo,
          action(selected: RetentionForShredding[]) {
            dispatch(
              dialogOpenAction({
                dialogProps: {
                  data: {
                    id: selected[0].nodeId,
                    nodeType: getNodeTypeByIdentifier(selected[0])
                  },
                  onClose: () => {
                    setSelectedRowKeys([]);
                    tableApi.refetch();
                  }
                },
                dialogType: DialogType.ShreddingDiscard
              })
            );
          }
        },
        {
          key: "cancel-discard",
          icon: <AlarmOff />,
          tooltip: t(translationPath(lang.general.cancelDiscard)),
          canBeDisplayed: (selected) => !!selected.discardTo,
          action(selected: RetentionForShredding[]) {
            dispatch(
              dialogOpenAction({
                dialogProps: {
                  data: {
                    id: selected[0].nodeId,
                    nodeType: getNodeTypeByIdentifier(selected[0])
                  },
                  onClose: () => {
                    setSelectedRowKeys([]);
                    tableApi.refetch();
                  }
                },
                dialogType: DialogType.CancelDiscardDialog
              })
            );
          }
        },
        {
          key: "change-location",
          icon: <AllInbox />,
          tooltip: t(translationPath(lang.general.editStorageLocation)),
          canBeDisplayed: (selected) =>
            selected.form === DocumentType.Analog ||
            selected.form === DocumentType.Hybrid ||
            selected.form === DocumentType.AD,
          action(selected: RetentionForShredding[]) {
            dispatch(
              dialogOpenAction({
                dialogProps: {
                  data: {
                    id: selected[0].nodeId,
                    nodeType: getNodeTypeByIdentifier(selected[0])
                  }
                },
                dialogType: DialogType.ChangeLocation
              })
            );
          }
        },
        {
          key: "create-shredding-proposal",
          icon: <AddBoxOutlined />,
          tooltip: t(translationPath(lang.general.createShreddingProposal)),
          action(selected: RetentionForShredding[]) {
            dispatch(
              dialogOpenAction({
                dialogProps: {
                  data: [
                    {
                      id: selected[0].nodeId,
                      nodeType: getNodeTypeByIdentifier(selected[0])
                    } as GenericDocument
                  ]
                },
                dialogType: DialogType.CreateRetentionProposal
              })
            );
          }
        }
      ],
      multi: [
        {
          key: "create-shredding-proposal",
          icon: <AddBoxOutlined />,
          tooltip: t(translationPath(lang.general.createShreddingProposal)),
          action(selected: RetentionForShredding[]) {
            dispatch(
              dialogOpenAction({
                dialogProps: {
                  data: selected.map((item) => {
                    return {
                      id: item.nodeId,
                      nodeType: getNodeTypeByIdentifier(item)
                    } as GenericDocument;
                  })
                },
                dialogType: DialogType.CreateRetentionProposal
              })
            );
          }
        }
      ]
    }),
    [dispatch, tableApi]
  );

  const getRemoteData: GetRemoteDataClb<RetentionForShredding> = useCallback(
    ({ pagination, sorting }) => findRetentionForShredding(pagination, sorting),
    [findRetentionForShredding]
  );

  const onRowSelection: TableRowSelection<RetentionForShredding> = {
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys);
    },
    selectedRowKeys
  };

  return (
    <>
      <BreadcrumbForTable title={"toDispose"}></BreadcrumbForTable>
      <TableStyled>
        <RemoteTable<RetentionForShredding>
          name="retention/RetentionForShredding"
          rowKey="pid"
          actions={ACTIONS}
          columns={COLUMNS}
          getRemoteData={getRemoteData}
          rowSelection={onRowSelection}
          onRemoteTableApi={onRemoteTableApi}
        />
      </TableStyled>
    </>
  );
});

export default ForShreddingTable;

const TableStyled = styled("div")`
  div > .ant-row {
    height: 50px;
  }
`;
