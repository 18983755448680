import React, { useMemo } from "react";
import { AddressTypeCode } from "../../../../../domain/struct/codelist/AddressType";
import { Address } from "../../../../../domain/struct/nameRegistry/Address";
import { Contact } from "../../../../../domain/struct/nameRegistry/Contact";
import { ContactType } from "../../../../../domain/struct/nameRegistry/ContactType";
import { SubjectType } from "../../../../../domain/struct/nameRegistry/SubjectType";
import { getService } from "../../../../core/features/dependencyInjection";
import { Form, Item, useForm } from "../../../../designSystem/Form/v2/Form";
import { Modal, ModalSize } from "../../../../designSystem/Modal/Modal";
import { Select } from "../../../../designSystem/Select/Select";
import { SearchSubjectResult } from "../../../../share/components/dialog/createShipmentDialog/CreateShipmentForm";
import { translationPath } from "../../../../share/utils/getPath";
import { lang, t } from "../../../../translation/i18n";
import { useFindCountries } from "../../hooks/useFindCountries";
import { AddressFormatHelpers } from "../../service/AddressFormatHelpers";
import { AddressListHelpers } from "../../service/AddressListHelpers";
import { ContactListHelpers } from "../../service/ContactListHelpers";

type OnCancelClb = () => void;
export type SubjectContactDetailsModalProps = {
  subjectType: SubjectType;
  contacts?: Contact[];
  addresses?: Address[];
  onOk?: (result?: SearchSubjectResult) => void;
  onCancel?: OnCancelClb;
};

export const SubjectContactDetailsModal = ({
  subjectType,
  contacts,
  addresses,
  onCancel,
  onOk
}: SubjectContactDetailsModalProps) => {
  const addressListHelpers = getService(AddressListHelpers);
  const addressFormatHelpers = getService(AddressFormatHelpers);
  const contactListHelpers = getService(ContactListHelpers);

  const { data: countryCodesAndNames } = useFindCountries({ retry: false });

  const initialValues = useMemo(
    () => ({
      addressId:
        addresses &&
        getDefaultAddressType(addressListHelpers, subjectType, addresses),
      contactTypeDataBox:
        contacts &&
        contactListHelpers.findContactByType(contacts, ContactType.DataBoxId)
          ?.id,
      contactTypeEmail:
        contacts &&
        contactListHelpers.findContactByType(contacts, ContactType.EmailAddress)
          ?.id,
      contactTypePhone:
        contacts &&
        contactListHelpers.findContactByType(contacts, ContactType.PhoneNumber)
          ?.id
    }),
    [addressListHelpers, addresses, contactListHelpers, contacts, subjectType]
  );

  const [form] = useForm<typeof initialValues>();

  const optionsAddresses = useMemo(
    () =>
      addresses
        ? addresses.map((address) => ({
            label: addressFormatHelpers.formatAddress(
              address,
              countryCodesAndNames
            ),
            value: address.id || ""
          }))
        : [],

    [addressFormatHelpers, addresses, countryCodesAndNames]
  );

  const optionsDataBox = useMemo(
    () =>
      getContactOptions(contactListHelpers, contacts, ContactType.DataBoxId),
    [contactListHelpers, contacts]
  );

  const optionsEmail = useMemo(
    () =>
      getContactOptions(contactListHelpers, contacts, ContactType.EmailAddress),
    [contactListHelpers, contacts]
  );

  const optionsPhone = useMemo(
    () =>
      getContactOptions(contactListHelpers, contacts, ContactType.PhoneNumber),
    [contactListHelpers, contacts]
  );

  const handleOnOk = async () => {
    const {
      addressId,
      contactTypeDataBox,
      contactTypeEmail,
      contactTypePhone
    } = form.getFieldsValue();

    const address =
      addresses && addressListHelpers.findAddressById(+addressId!, addresses);

    const email =
      contactTypeEmail &&
      contacts &&
      contactListHelpers.findContactById(contacts, contactTypeEmail);

    const phone =
      contactTypePhone &&
      contacts &&
      contactListHelpers.findContactById(contacts, contactTypePhone);

    const dataBox =
      contactTypeDataBox &&
      contacts &&
      contactListHelpers.findContactById(contacts, contactTypeDataBox);

    onOk?.({
      ...(address && { addresses: [address] }),
      contacts: [email, phone, dataBox].filter(
        (address) => address !== undefined
      ) as Contact[]
    });
  };

  return (
    <Modal
      open={true}
      title={"Údaje o subjektu"}
      onCancel={onCancel}
      onOk={handleOnOk}
      // confirmLoading={
      //   (!contact ? isLoadingAdd : isLoadingUpdate) || isDataBoxFetching
      // }
      size={ModalSize.Small}
    >
      <Form initialValues={initialValues} form={form}>
        <Item
          name="addressId"
          label={t(
            translationPath(lang.dialog.subjectContactDetailsModal.address)
          )}
          // rules={validationRules}
        >
          <Select
            style={{ minWidth: "11rem" }}
            options={optionsAddresses}
            disabled={addresses && addresses.length <= 1}
          />
        </Item>
        <Item
          name="contactTypeDataBox"
          label={t(
            translationPath(lang.dialog.subjectContactDetailsModal.dataBoxId)
          )}
          // rules={validationRules}
        >
          <Select
            style={{ minWidth: "11rem" }}
            showSearch
            options={optionsDataBox}
            defaultActiveFirstOption
            disabled={optionsDataBox.length <= 1}
          />
        </Item>
        <Item
          name="contactTypeEmail"
          label={t(
            translationPath(lang.dialog.subjectContactDetailsModal.emailAddress)
          )}
          // rules={validationRules}
        >
          <Select
            style={{ minWidth: "11rem" }}
            showSearch
            options={optionsEmail}
            disabled={optionsEmail.length <= 1}
            defaultActiveFirstOption
          />
        </Item>
        <Item
          name="contactTypePhone"
          label={t(
            translationPath(lang.dialog.subjectContactDetailsModal.phoneNumber)
          )}
          // rules={validationRules}
        >
          <Select
            style={{ minWidth: "11rem" }}
            showSearch
            options={optionsPhone}
            defaultActiveFirstOption
            disabled={optionsPhone.length <= 1}
          />
        </Item>
      </Form>
    </Modal>
  );
};

const getContactOptions = (
  contactListHelpers: ContactListHelpers,
  contacts: Contact[] | undefined,
  type: ContactType
) => {
  if (!contacts) {
    return [];
  }

  return contactListHelpers
    .findContactsByType(contacts, type)
    .map(({ id, contact }) => ({
      value: id || 0,
      label: contact
    }));
};

const subjectTypeToAddressType = {
  [SubjectType.Person]: AddressTypeCode.PermanentResidence,
  [SubjectType.LegalEntity]: AddressTypeCode.CompanyHeadquarters
};

const getDefaultAddressType = (
  addressListHelpers: AddressListHelpers,
  subjectType: SubjectType,
  addresses: Address[]
) => {
  const id = addressListHelpers.findAddressByType(
    addresses,
    subjectTypeToAddressType[subjectType]
  )?.id;
  if (subjectType === SubjectType.Person) {
    return id ? id : addresses[0].id;
  }
  return id;
};
