import {
  DInject,
  DInjectable
} from "../../../presentation/core/features/dependencyInjection";
import {
  PaginatedResult,
  PaginationConfig
} from "../../../lib/contract/Pagination";
import { HttpClient } from "../../service/HttpClient";
import { ApiUrls } from "../struct/ApiTypesV2";
import { SearchedRepository } from "../../../domain/service/search/SearchedRepository";
import { SearchResult } from "../../../domain/struct/search/SearchResult";
import {
  ApiRepositorySearchedResponse,
  SearchedApiMapper
} from "./SearchedApiMapper";
import { SortingConfig } from "../../../lib/contract/Sorting";

const URL: ApiUrls = {
  Search: "/api/app/v2/search"
};

@DInjectable()
export class SearchedApiRepository implements SearchedRepository {
  constructor(@DInject(HttpClient) protected httpClient: HttpClient) {}

  getSearched(
    searchedTerm: string,
    pagination: PaginationConfig,
    sorting?: SortingConfig
  ): Promise<PaginatedResult<SearchResult>> {
    return this.httpClient
      .fetchPaginated<ApiRepositorySearchedResponse>({
        url: URL.Search,
        pagination,
        sorting,
        config: {
          params: {
            keyword: searchedTerm
          }
        }
      })
      .then(SearchedApiMapper.mapListToDomain);
  }
}
