import { ApiUrls } from "../struct/ApiTypesV2";
import {
  DInject,
  DInjectable
} from "../../../presentation/core/features/dependencyInjection";
import { StoredDocumentRepository } from "../../../domain/service/repository/StoredDocumentRepository";
import { HttpClient } from "../../service/HttpClient";
import { PaginatedResult, PaginationConfig } from "lib/contract/Pagination";
import { StoredDocument } from "../../../domain/struct/repository/StoredDocument";
import {
  ApiRepositoryStoredDocumentViewResponse,
  StoredDocumentApiMapper
} from "./StoredDocumentApiMapper";
import { SortingConfig } from "lib/contract/Sorting";

const URL: ApiUrls = {
  GetRepositoryStoredDocuments: "/api/app/v2/repository/stored-documents"
};

@DInjectable()
export class StoredDocumentApiRepository implements StoredDocumentRepository {
  constructor(@DInject(HttpClient) protected httpClient: HttpClient) {}

  getStoredDocuments(
    pagination: PaginationConfig,
    sorting?: SortingConfig
  ): Promise<PaginatedResult<StoredDocument>> {
    return this.httpClient
      .fetchPaginated<ApiRepositoryStoredDocumentViewResponse>({
        url: URL.GetRepositoryStoredDocuments,
        pagination,
        sorting
      })
      .then(StoredDocumentApiMapper.mapListToDomain);
  }
}
