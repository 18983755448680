import { NameRegisterConfigurationRepositoryToken } from "domain/di/token/nameRegistry";
import type { NameRegisterConfigurationRepository } from "domain/service/nameRegistry/NameRegisterConfigurationRepository";
import { NameRegisterConfiguration } from "domain/struct/nameRegistry/NameRegisterConfiguration";
import {
  DInject,
  DInjectable
} from "presentation/core/features/dependencyInjection";

@DInjectable()
export class UpdateNameRegisterConfiguration {
  constructor(
    @DInject(NameRegisterConfigurationRepositoryToken)
    protected repository: NameRegisterConfigurationRepository
  ) {}

  updateNameRegisterConfiguration(values: NameRegisterConfiguration) {
    return this.repository.update(values);
  }

  findNameRegisterConfiguration() {
    return this.repository.find();
  }
}
