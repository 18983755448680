import { ConversionAllRepository } from "domain/service/administration/ConversionAllRepository";
import { ImprintRepository } from "domain/service/administration/ImprintRepository";
import { UserRepository } from "domain/service/administration/UserRepository";
import { DInjectionToken } from "presentation/core/features/dependencyInjection";
export const UserRepositoryToken = new DInjectionToken<UserRepository>(
  "domain.administration.User-repository"
);
export const ImprintRepositoryToken = new DInjectionToken<ImprintRepository>(
  "domain.administration.Imprint-repository"
);
export const ConversionAllRepositoryToken = new DInjectionToken<ConversionAllRepository>(
  "domain.administration.conversionAll-repository"
);
