import { TabPropsType } from "../../../../core/components/dialog/tabs/_types";
import { DocumentConcernedSubject } from "./DocumentConcernedSubject";
import { ConceptConcernedSubject } from "./ConceptConcernedSubject";
import { t } from "../../../../translation/i18n";
import { translationPath } from "../../../utils/getPath";
import lang from "../../../../translation/lang";
import { FileConcernedSubject } from "./FileConcernedSubject";

const TITLE = t(translationPath(lang.dialog.tabs.concernedSubjects));

export const documentConcernedSubjectTab: TabPropsType = {
  label: TITLE,
  content: DocumentConcernedSubject
};

export const conceptConcernedSubjectTab: TabPropsType = {
  label: TITLE,
  content: ConceptConcernedSubject
};

export const fileConcernedSubjectTab: TabPropsType = {
  label: TITLE,
  content: FileConcernedSubject
};
