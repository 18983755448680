import { PaginatedResult } from "@isfg/lib/build/contract/Pagination";
import { ApiListResponseMapper } from "infrastructure/api/mapper/ApiListResponseMapper";
import { ApiListResponse } from "infrastructure/api/struct/ApiTypesV2";
import { CodeListResponse, CodeListValueResponse } from "../types";

export const mapCodeListResponsePaginated = (
  apiResponse: ApiListResponse<CodeListResponse>
): PaginatedResult<CodeListResponse> => {
  return ApiListResponseMapper.mapListToPagination(
    apiResponse,
    (response) => response
  );
};

export const mapCodeListValueResponsePaginated = (
  apiResponse: ApiListResponse<CodeListValueResponse>
): PaginatedResult<CodeListValueResponse> => {
  return ApiListResponseMapper.mapListToPagination(
    apiResponse,
    (response) => response
  );
};
