import { PaginationConfig } from "lib/contract/Pagination";
import { SortingConfig } from "lib/contract/Sorting";
import { useQM } from "presentation/share/hook/query/useQM";
import { getActiveComponents } from "../api";

export const ACTIVE_DOCUMENTS_QUERY_KEY = "documents/activeComponents";
export const useGetDocumentsActiveComponents = (
  nodeId: string,
  nodeType: string,
  isActive: boolean = false,
  pagination: PaginationConfig,
  sorting?: SortingConfig
) => {
  return useQM(
    [ACTIVE_DOCUMENTS_QUERY_KEY, nodeId, nodeType, pagination, sorting],
    () => getActiveComponents(nodeId, nodeType, pagination, sorting),
    {
      enabled: isActive
    }
  );
};
