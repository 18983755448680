import { ApiListResponseMapper } from "infrastructure/api/mapper/ApiListResponseMapper";
import {
  ApiListResponse,
  ApiTypes
} from "infrastructure/api/struct/ApiTypesV2";
import { stringOrDefault } from "lib/string";
import { ForSeal } from "../types/ForSeal";

export type ApiOverviewsForSealResponse = ApiTypes["ISFG.SpisUm.ClientSide.Models.V2.Overview.OverviewForSealResponse"];

export class Mappers {
  static mapListToDomain(
    response: ApiListResponse<ApiOverviewsForSealResponse>
  ) {
    return ApiListResponseMapper.mapListToPagination(
      response,
      Mappers.mapToDomain
    );
  }

  static mapToDomain(response: ApiOverviewsForSealResponse): ForSeal {
    const {
      nodeId,
      pid,
      ssid,
      subject,
      deliveryDate,
      forSealDate,
      attachmentsCount,
      owner
    } = response;
    return {
      nodeId: stringOrDefault(nodeId),
      pid: stringOrDefault(pid),
      ssid: stringOrDefault(ssid),
      subject: stringOrDefault(subject),
      deliveryDate: stringOrDefault(deliveryDate),
      forSealDate: stringOrDefault(forSealDate),
      attachmentsCount,
      owner: stringOrDefault(owner)
    };
  }
}
