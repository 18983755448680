import { PaginationConfig } from "lib/contract/Pagination";
import { useCallback } from "react";
import { SortingConfig } from "lib/contract/Sorting";
import { getSignatureBookForSignature } from "../api";

export const useFindSignatureBookForSignature = () => {
  return useCallback(
    (config: PaginationConfig, sorting?: SortingConfig) =>
      getSignatureBookForSignature(config, sorting),
    []
  );
};
