import { ApiTypes } from "../struct/ApiTypes";
import { Nationality } from "../../../domain/struct/codelist/Nationality";

export type ApiNationality = ApiTypes["ISFG.SpisUm.ClientSide.Models.NameRegister.CodeLists.Nationality"];

export class NationalityApiMapper {
  static mapToDomain(response: ApiNationality[]): Nationality[] {
    return response.map((apiNationality) => ({
      code: apiNationality.code || "",
      czechName: apiNationality.czechName || ""
    }));
  }
}
