import { ApiListResponse, ApiTypes } from "../struct/ApiTypesV2";
import { ApiListResponseMapper } from "../mapper/ApiListResponseMapper";
import { StoredDocument } from "../../../domain/struct/repository/StoredDocument";
import { stringOrDefault } from "../../../lib/string";

export type ApiRepositoryStoredDocumentViewResponse = ApiTypes["ISFG.SpisUm.ClientSide.Models.V2.Repository.RepositoryStoredDocumentViewResponse"];

export class StoredDocumentApiMapper {
  static mapListToDomain(
    response: ApiListResponse<ApiRepositoryStoredDocumentViewResponse>
  ) {
    return ApiListResponseMapper.mapListToPagination(
      response,
      StoredDocumentApiMapper.mapToDomain
    );
  }

  static mapToDomain(
    response: ApiRepositoryStoredDocumentViewResponse
  ): StoredDocument {
    const {
      nodeId,
      pid,
      ssid,
      subject,
      senderName,
      form,
      filePlanId,
      fileMark,
      retentionMode,
      location,
      shreddingYear
    } = response;
    return {
      nodeId: stringOrDefault(nodeId),
      pid: stringOrDefault(pid),
      ssid: stringOrDefault(ssid),
      subject: stringOrDefault(subject),
      senderName: stringOrDefault(senderName),
      form: stringOrDefault(form),
      filePlanId: stringOrDefault(filePlanId),
      fileMark: stringOrDefault(fileMark),
      retentionMode: stringOrDefault(retentionMode),
      location: stringOrDefault(location),
      shreddingYear: stringOrDefault(shreddingYear)
    };
  }
}
