import {
  Address,
  DESCRIPTIVE_HOUSE_NUMBER_TYPE
} from "../../../struct/nameRegistry/Address";

export const applyAddressRules = (address: Address): Address => {
  if (!address) {
    throw new Error("Address should be defined for update");
  }
  return {
    ...address,
    orientationHouseNumber:
      address.houseNumberType === DESCRIPTIVE_HOUSE_NUMBER_TYPE
        ? address.orientationHouseNumber
        : "",
    orientationHouseNumberAddendum:
      address.houseNumberType === DESCRIPTIVE_HOUSE_NUMBER_TYPE &&
      address.orientationHouseNumber
        ? address.orientationHouseNumberAddendum
        : ""
  };
};
