import { CancelScheduleSend, Description, Send } from "@mui/icons-material";
import { openDocumentReadonlyDetailsAction } from "presentation/core/api/document/_actions";
import {
  ControlsBarType,
  DataColumn
} from "presentation/core/components/dataTable/_types";
import DocumentView from "presentation/core/components/documentView";
import MenuLayout from "presentation/core/components/layout/MenuLayout";
import { GenericDocument, genericDocumentProxy } from "presentation/core/types";
import { SitePaths } from "presentation/enums";
import { RootStateType } from "presentation/reducers";
import { handoverDocument } from "presentation/share/components/dialog/documentHandoverDialog/_actions";
import { dialogOpenHandoverBack } from "presentation/share/components/dialog/handoverBackDialog/_actions";
import { classPath, translationPath } from "presentation/share/utils/getPath";
import { getRelativePath } from "presentation/share/utils/query";
import { traverseNodeType } from "presentation/share/utils/utils";
import { validateItems } from "presentation/share/utils/validation";
import { lang, t, withTranslation } from "presentation/translation/i18n";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { DisplaySenderNameByType } from "../../../../share/components/table/column/DisplaySenderNameByType";
import React from "react";

const defaultColumn: DataColumn<GenericDocument> = {
  isDateTime: true,
  keys: [classPath(genericDocumentProxy.properties!.ssl!.deliveryDate).path],
  label: t(translationPath(lang.general.deliveryDate))
};

export const columns: DataColumn<GenericDocument>[] = [
  {
    keys: [classPath(genericDocumentProxy.properties!.ssl!.pid).path],
    label: t(translationPath(lang.general.identifier))
  },
  {
    keys: [classPath(genericDocumentProxy.properties!.ssl!.ssid).path],
    label: t(translationPath(lang.general.referenceNumber))
  },
  {
    keys: [classPath(genericDocumentProxy.properties!.ssl!.subject).path],
    label: t(translationPath(lang.general.subject))
  },
  {
    getValue: (row) => <DisplaySenderNameByType ssl={row?.properties?.ssl} />,
    keys: [
      classPath(genericDocumentProxy.properties!.ssl!.senderCompanyName).path,
      classPath(genericDocumentProxy.properties!.ssl!.senderFullName).path
    ],
    label: t(translationPath(lang.general.sender))
  },
  defaultColumn,
  {
    getValue: (x) =>
      x.properties?.ssl?.nextOwner === "-group-"
        ? x.properties?.ssl?.nextGroup
        : x.properties?.ssl?.nextOwner,
    keys: [classPath(genericDocumentProxy.properties!.ssl!.nextOwner).path],
    label: t(translationPath(lang.general.nextOwner))
  }
];

const WaitingForTakeover = () => {
  const dispatch = useDispatch();
  const relativePath = useSelector((state: RootStateType) =>
    getRelativePath(
      state.loginReducer.global.paths,
      null,
      SitePaths.Mailroom,
      SitePaths.WaitingForTakeOver
    )
  );
  const dispatchOpenDialog: (row: GenericDocument) => void = (row) => {
    dispatch(
      openDocumentReadonlyDetailsAction({
        data: row,
        hideShipmentsTab: true
      })
    );
  };

  const controls: ControlsBarType<GenericDocument> = {
    single: {
      items: [
        {
          action: (selected: GenericDocument[]) => {
            dispatchOpenDialog(selected[0]);
          },
          icon: <Description />,
          title: t(translationPath(lang.general.showDetails))
        },
        {
          action: (selected: GenericDocument[]) => {
            dispatch(
              dialogOpenHandoverBack({
                data: {
                  ...selected[0],
                  id: selected[0].properties?.ssl?.waitingRef || selected[0].id,
                  nodeType: traverseNodeType(selected[0].nodeType)
                }
              })
            );
          },
          icon: <CancelScheduleSend />,
          title: t(translationPath(lang.general.giveBack)),
          validation: (items) =>
            validateItems(items, {
              [classPath(genericDocumentProxy.properties!.ssl!.nextGroup).path]:
                yup
                  .string()
                  .required(
                    t(
                      translationPath(
                        lang._validations.nodeHandoverIsBeingCancelled
                      )
                    )
                  )
            })
        },
        {
          action: (selected: GenericDocument[]) => {
            dispatch(
              handoverDocument({
                cancelDocumentOwner: true,
                data: {
                  ...selected[0],
                  id: selected[0].properties?.ssl?.waitingRef || selected[0].id,
                  nodeType: traverseNodeType(selected[0].nodeType)
                }
              })
            );
          },
          icon: <Send />,
          title: t(translationPath(lang.general.giveNext))
        }
      ]
    }
  };

  return (
    <MenuLayout>
      <DocumentView
        children={{
          relativePath
        }}
        columns={columns}
        controls={controls}
        customTitle={t(translationPath(lang.menu.items.waitingForTakeover))}
        defaultSortAsc={true}
        defaultSortColumn={defaultColumn}
        handleDoubleClick={dispatchOpenDialog}
      />
    </MenuLayout>
  );
};

export default withTranslation()(WaitingForTakeover);
