import { ConcernedContentRepositoryToken } from "domain/di/token/nameRegistry";
import {
  DInject,
  DInjectable
} from "presentation/core/features/dependencyInjection";
import type { ConcernedContentRepository } from "../../../domain/service/nameRegistry/ConcernedContentRepository";
import { ConcernedContent } from "../../../domain/struct/nameRegistry/ConcernedContent";
import {
  PaginatedResult,
  PaginationConfig
} from "../../../lib/contract/Pagination";

@DInjectable()
export class FindConcernedContent {
  constructor(
    @DInject(ConcernedContentRepositoryToken)
    protected concernedContentRepository: ConcernedContentRepository
  ) {}

  findAllPaginated(
    subjectId: string,
    config: PaginationConfig
  ): Promise<PaginatedResult<ConcernedContent>> {
    return this.concernedContentRepository.findBySubjectId(subjectId, config);
  }
}
