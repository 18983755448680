import { PaginationConfig } from "lib/contract/Pagination";
import { useCallback } from "react";
import { SortingConfig } from "lib/contract/Sorting";
import { getOverviewsForSeal } from "../api";

export const useFindOverViewsForSeal = () => {
  return useCallback(
    (config: PaginationConfig, sorting?: SortingConfig) =>
      getOverviewsForSeal(config, sorting),
    [getOverviewsForSeal]
  );
};
