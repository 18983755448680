import { useMutation } from "presentation/share/hook/query/useMutation";
import { updateUnsettledDocument } from "../api";

interface UpdateDocumentVariables {
  id: string;
  input: any;
}

export const useUpdateDocument = () => {
  const {
    isLoading: isUpdateLoading,
    mutate: update
  } = useMutation(({ id, input }: UpdateDocumentVariables) =>
    updateUnsettledDocument(id, input)
  );

  return {
    isUpdateLoading,
    update
  };
};
