import MenuLayout from "presentation/core/components/layout/MenuLayout";
import React from "react";
import { withTranslation } from "presentation/translation/i18n";
import { DailyImprintTable } from "../../tables/DailyImprint/DailyImprintTable";

const DailyImprint = () => {
  return (
    <MenuLayout>
      <DailyImprintTable />
    </MenuLayout>
  );
};

export default withTranslation()(DailyImprint);
