import { ApiUrls } from "infrastructure/api/struct/ApiTypesV2";
import { HttpClient, HttpMethod } from "infrastructure/service/HttpClient";
import { ComponentType } from "presentation/core/api/components/_types";
import { DocumentComponentsValidateResponse } from "../types";

const URL: ApiUrls = {
  GetValidate: "/api/app/v2/document/{nodeId}/validate",
  PostConceptComponentDecrypt:
    "/api/app/v2/concept/{nodeId}/component/{componentId}/decrypt",
  PostDocumentComponentDecrypt:
    "/api/app/v2/document/{nodeId}/component/{componentId}/decrypt"
};

export interface PasswordProps {
  componentType: ComponentType;
  nodeId: string;
  componentId: string;
  password: string;
}

export const validateDocumentComponents = (id: string) => {
  const httpClient = new HttpClient();
  return httpClient.fetchWithThrow<DocumentComponentsValidateResponse[]>(
    URL.GetValidate,
    HttpMethod.Get,
    {
      urlWildCards: {
        nodeId: id
      }
    }
  );
};

export const postDecryptedComponent = (params: PasswordProps) => {
  const { componentType, nodeId, componentId, ...rest } = params;
  const httpClient = new HttpClient();
  const url =
    componentType === ComponentType.Concept
      ? URL.PostConceptComponentDecrypt
      : URL.PostDocumentComponentDecrypt;
  return httpClient.fetchWithThrow(url, "POST", {
    bodyJSON: rest,
    urlWildCards: { nodeId, componentId }
  });
};
