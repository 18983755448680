import React from "react";
import { TabPropsType } from "../../../../core/components/dialog/tabs/_types";
import { useDocumentId } from "../../../../core/components/dialog/hooks/useDocumentId";
import { FileConcernedSubjectTable } from "../../../tables/file/FileConcernedSubjectTable";

export const FileConcernedSubject: TabPropsType["content"] = (props) => {
  const documentId = useDocumentId(props);

  if (documentId) {
    return (
      <div className="body-fullsize">
        <FileConcernedSubjectTable
          documentId={documentId}
          channel={props.channel}
        />
      </div>
    );
  }

  return <></>;
};
