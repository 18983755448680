import DataTable from "presentation/core/components/dataTable";
import {
  DataColumn,
  ValueType
} from "presentation/core/components/dataTable/_types";
import { useStyles } from "presentation/core/components/dialog/Dialog.styles";
import { DataTableValues } from "presentation/core/components/documentView/_types";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { File } from "../../../../entities";
import { generateColumns } from "./columns";
import { controls, readOnlyControls } from "./controls";
import { findMainFile } from "./methods";
import { SelectedComponentsFnType } from "./_types";

export type ChangePageFnType = (
  event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
  page: number
) => void;

export type ChangeRowsPerPageFnType = (
  event: React.ChangeEvent<HTMLInputElement>
) => void;

export type SortChangeFnType = (
  index: number,
  keys: string[]
) => (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void;

interface OwnProps {
  handleChangePage: ChangePageFnType;
  handleChangeRowsPerPage: ChangeRowsPerPageFnType;
  handleColumnChange: (
    row: File,
    column: DataColumn<File>,
    value: ValueType
  ) => void;
  handleCanShowPreview: (component: File) => boolean;
  handleDownloadComponent: SelectedComponentsFnType;
  handleSelectionChange: SelectedComponentsFnType;
  handleShowPreview: SelectedComponentsFnType;
  handleSortingChange: SortChangeFnType;

  handleActivateComponent: SelectedComponentsFnType;
  handleCanActivateComponent: (component: File) => boolean;
  isLoading?: boolean;
  isReadonly?: boolean;
  items: File[];
  pageNumber: number;
  refreshTable: VoidFunction;
  rowsPerPage: number;
  sortAsc?: boolean;
  sortColumnIndex?: number | null;
  totalItems: number;
}

const InactiveComponentsTab = ({
  handleCanShowPreview,
  handleChangePage,
  handleChangeRowsPerPage,
  handleColumnChange,

  handleDownloadComponent,

  handleSelectionChange,
  handleShowPreview,
  handleSortingChange,

  handleActivateComponent,
  handleCanActivateComponent,
  isLoading = false,
  isReadonly,
  items,
  pageNumber,
  refreshTable,
  rowsPerPage,
  sortAsc,
  sortColumnIndex,
  totalItems
}: OwnProps) => {
  const dataTableValues: DataTableValues = {
    resetIcons: true
  };
  useTranslation();
  const dialogClasses = useStyles();

  const cols = useMemo(() => generateColumns(findMainFile(items)), [items]);
  return (
    <div className="body-fullsize">
      <DataTable
        controls={
          !isReadonly
            ? controls({
                handleCanShowPreview,
                handleDownloadComponent,
                handleShowPreview,
                handleActivateComponent,
                handleCanActivateComponent
              })
            : readOnlyControls({
                handleCanShowPreview,
                handleDownloadComponent,
                handleShowPreview
              })
        }
        breadcrumbs={[]}
        columns={cols}
        dataTableValues={dataTableValues}
        defaultActionFirst={true}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleColumnChange={handleColumnChange}
        handleSelectionChange={handleSelectionChange}
        handleSortingChange={handleSortingChange}
        pageNumber={pageNumber}
        paginationClassName={dialogClasses.tablePagination}
        pending={isLoading}
        readonly={!!isReadonly}
        refreshTable={refreshTable}
        rows={items}
        rowsCount={totalItems}
        rowsPerPage={rowsPerPage}
        sortAsc={sortAsc}
        sortColumnIndex={sortColumnIndex}
        tableLayoutClassName="dialog__table-layout"
      />
    </div>
  );
};

export default React.memo(InactiveComponentsTab);
