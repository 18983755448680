import { ErrorCodeList } from "presentation/enums";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";

const codeToTranslateMap = {
  [ErrorCodeList.SettleOutputReadableType]: translationPath(
    lang.dialog.notifications.settleOutputReadableType
  ),
  [ErrorCodeList.SettleOutputFormat]: translationPath(
    lang.dialog.notifications.settleOutputFormat
  ),
  [ErrorCodeList.SettleReadableType]: translationPath(
    lang.dialog.notifications.settleReadableType
  ),
  [ErrorCodeList.SettleInvalidFilePlan]: translationPath(
    lang.dialog.notifications.invalidFilePlan
  ),
  [ErrorCodeList.SettleInvalidFileMark]: translationPath(
    lang.dialog.notifications.invalidFileMark
  ),
  [ErrorCodeList.CloseInvalidFilePlan]: translationPath(
    lang.dialog.notifications.invalidFilePlan
  ),
  [ErrorCodeList.CloseInvalidFileMark]: translationPath(
    lang.dialog.notifications.invalidFileMark
  ),
  [ErrorCodeList.AntivirusHarmfulContent]: translationPath(
    lang.dialog.notifications.antivirusHarmfulContent
  ),
  [ErrorCodeList.AntivirusNotWorking]: translationPath(
    lang.dialog.notifications.antivirusNotWorking
  ),
  [ErrorCodeList.DataBoxDoesNotMatch]: translationPath(
    lang.dialog.notifications.dataBoxDoesNotMatch
  ),
  [ErrorCodeList.DataBoxIsMissing]: translationPath(
    lang.dialog.notifications.dataBoxIsMissing
  ),
  [ErrorCodeList.DataBoxNotFound]: translationPath(
    lang.dialog.notifications.dataBoxIdNotFound
  ),
  [ErrorCodeList.EmailAddressDoesNotMatch]: translationPath(
    lang.dialog.notifications.emailAddressDoesNotMatch
  ),
  [ErrorCodeList.EmailAddressIsMissing]: translationPath(
    lang.dialog.notifications.emailAddressIsMissing
  ),
  [ErrorCodeList.AddressTypeAlreadyExists]: translationPath(
    lang.dialog.notifications.addressTypeAlreadyExists
  ),
  [ErrorCodeList.ContactTypeAlreadyExists]: translationPath(
    lang.dialog.notifications.contactTypeDataboxAlreadyExists
  ),
  [ErrorCodeList.NameMustNotBeEmpty]: translationPath(
    lang.dialog.notifications.nameMustNotBeEmpty
  ),
  [ErrorCodeList.SurnameMustNotBeEmpty]: translationPath(
    lang.dialog.notifications.surnameMustNotBeEmpty
  ),
  [ErrorCodeList.SubjectMustHaveAtLeastOneContact]: translationPath(
    lang.dialog.notifications.subjectMustHaveAtLeastOneContact
  ),
  [ErrorCodeList.SubjectMustHaveAtLeastOneAddress]: translationPath(
    lang.dialog.notifications.subjectMustHaveAtLeastOneAddress
  ),
  [ErrorCodeList.AtLeastOneAddressMustBeTypeOfCOMPANY_HEADQUARTERS]:
    translationPath(
      lang.dialog.notifications
        .atLeastOneAddressMustBeTypeOfCOMPANY_HEADQUARTERS
    ),
  [ErrorCodeList.CompanyNameMustNotBeEmpty]: translationPath(
    lang.dialog.notifications.companyNameMustNotBeEmpty
  ),
  [ErrorCodeList.VATMustBeANumber]: translationPath(
    lang.dialog.notifications.VATMustBeANumber
  ),
  [ErrorCodeList.RelationConcernedSubjectExists]: translationPath(
    lang.general.relationConcernedSubjectExists
  ),
  [ErrorCodeList.CompanyHeadquartersCantBeDeleted]: translationPath(
    lang.dialog.notifications.CompanyHeadquartersCantBeDeleted
  ),
  [ErrorCodeList.DocumentMustHaveFormOfAnalog]: translationPath(
    lang.dialog.notifications.documentMustHaveFormOfAnalog
  ),
  [ErrorCodeList.RelationsConcernedSubjectExist]: translationPath(
    lang.dialog.notifications.relationsConcernedSubjectExist
  ),
  [ErrorCodeList.SubjectHasToHaveAddress]: translationPath(
    lang.dialog.notifications.subjectMustHaveAtLeastOneAddress
  ),
  [ErrorCodeList.ShreddingProposalCancelDecisionDA]: translationPath(
    lang.dialog.notifications.objectHaveDaDecision
  ),
  [ErrorCodeList.ShreddingProposalCancelSIP]: translationPath(
    lang.dialog.notifications.objectHaveGeneratedSIP
  ),
  [ErrorCodeList.ShreddingProposalRemoveDecisionDA]: translationPath(
    lang.dialog.notifications.removeObjectHaveDaDecision
  ),
  [ErrorCodeList.ShreddingProposalRemoveSIP]: translationPath(
    lang.dialog.notifications.removeObjectHaveGeneratedSIP
  ),
  [ErrorCodeList.ShreddingMissingLocation]: translationPath(
    lang.dialog.notifications.shreddingMissingLocation
  ),
  [ErrorCodeList.ShreddingBorrowed]: translationPath(
    lang.dialog.notifications.shreddingBorrowed
  ),
  [ErrorCodeList.ShreddingDiscarded]: translationPath(
    lang.dialog.notifications.shreddingDiscarded
  ),
  [ErrorCodeList.WrongImportStructure]: translationPath(
    lang.dialog.notifications.wrongImportStructure
  ),
  [ErrorCodeList.NumberOfContactsNotAllowed]: translationPath(
    lang.dialog.notifications.contactTypeDataboxAlreadyExists
  ),
  [ErrorCodeList.LegalSubjectExists]: translationPath(
    lang.dialog.notifications.legalSubjectExists
  ),
  [ErrorCodeList.UserDoesNotExist]: translationPath(
    lang.dialog.notifications.userDoesNotExist
  ),
  [ErrorCodeList.IncorrectPassword]: translationPath(
    lang.dialog.notifications.incorrectPassword
  ),
  [ErrorCodeList.PasswordDuplicity]: translationPath(
    lang.dialog.notifications.passwordDuplicity
  ),
  [ErrorCodeList.PasswordComplexity]: translationPath(
    lang.dialog.notifications.passwordComplexity
  ),
  [ErrorCodeList.PasswordLength]: translationPath(
    lang.dialog.notifications.passwordLength
  ),
  [ErrorCodeList.SettleMandatoryFields]: translationPath(
    lang.dialog.notifications.settleMandatoryFields
  ),
  [ErrorCodeList.EmailIsDuplicity]: translationPath(
    lang.dialog.notifications.emailIsDuplicity
  ),
  [ErrorCodeList.IsNotPDF]: translationPath(
    lang.dialog.notifications.wrongFormatDokuments
  ),
  [ErrorCodeList.PdfFileIsEncrypted]: translationPath(
    lang.dialog.notifications.componentHasPassword
  ),
  [ErrorCodeList.PageCountGreaterZero]: translationPath(
    lang.dialog.notifications.addComponent
  ),
  [ErrorCodeList.MissingComponent]: translationPath(
    lang.dialog.convertions.digitalToAnalog.missingComponent
  ),
  [ErrorCodeList.DataBoxNotDelivered]: translationPath(
    lang.dialog.notifications.dataBoxNotDelivered
  ),
  defaultError: translationPath(lang.dialog.notifications.actionFailed)
};

export const getErrorCodeTranslation = (code: string) => {
  const translationKey =
    codeToTranslateMap[code as keyof typeof codeToTranslateMap] ||
    codeToTranslateMap.defaultError;

  return t(translationKey);
};
