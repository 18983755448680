import { ApiListResponse, ApiTypes } from "../struct/ApiTypesV2";
import { ApiListResponseMapper } from "../mapper/ApiListResponseMapper";
import { stringOrDefault } from "../../../lib/string";
import { RetentionProtocolOrProposalOrErased } from "../../../domain/struct/recordRetention/RetentionProtocolOrProposalOrErased";

export type ApiRepositoryRetentionProposalViewResponse = ApiTypes["ISFG.SpisUm.ClientSide.Models.V2.Shredding.Response.ShreddingProposalViewResponse"];

export class RetentionProposalsApiMapper {
  static mapListToDomain(
    response: ApiListResponse<ApiRepositoryRetentionProposalViewResponse>
  ) {
    return ApiListResponseMapper.mapListToPagination(
      response,
      RetentionProposalsApiMapper.mapToDomain
    );
  }

  static mapToDomain(
    response: ApiRepositoryRetentionProposalViewResponse
  ): RetentionProtocolOrProposalOrErased {
    const {
      pid,
      nodeId,
      name,
      createdDate,
      authorFullName,
      shreddingObjectsCount
    } = response;
    return {
      nodeId: stringOrDefault(nodeId),
      pid: stringOrDefault(pid),
      name: stringOrDefault(name),
      createdAt: stringOrDefault(createdDate),
      authorFullName: stringOrDefault(authorFullName),
      shreddingObjectsCount
    };
  }
}
