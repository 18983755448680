// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ck-editor__editable {
  min-height: 150px !important;
}
`, "",{"version":3,"sources":["webpack://./src/presentation/core/components/ckEditor/CkEditor.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;AAC9B","sourcesContent":[".ck-editor__editable {\n  min-height: 150px !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
