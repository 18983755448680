import { useStyles } from "presentation/core/components/dialog/Dialog.styles";
import { DialogContentType } from "presentation/core/components/dialog/_types";
import React from "react";
import { ForSignatureForm } from "./ForSignatureForm";
import { ForSignatureFormValues } from "./_types";
import { validate } from "./_validations";
import { ChannelForm } from "../ChannelForm/ChannelForm";

export const ForSignatureContentDialog: DialogContentType["content"] = ({
  channel
}) => {
  const classes = useStyles();

  return (
    <div className={classes.modalSmall}>
      <ChannelForm<ForSignatureFormValues>
        initialValues={{
          group: "",
          user: ""
        }}
        validate={validate}
        channel={channel}
        component={ForSignatureForm}
      />
    </div>
  );
};
