import React, { useMemo } from "react";

import { Select } from "../../../../../designSystem/Select/Select";
import { useFindNationalities } from "../../../hooks/useFindNationalities";

interface CitizenshipSelectProps {
  onChange?: (value: string) => void;
}

export const CitizenshipSelect: React.FC<CitizenshipSelectProps> = (props) => {
  const { data, isFetching } = useFindNationalities();

  const options = useMemo(
    () =>
      data
        ? data.map(({ code, czechName }) => ({
            label: czechName,
            value: code
          }))
        : [],
    [data]
  );

  return (
    <Select
      loading={isFetching}
      showSearch
      optionFilterProp="label"
      options={options}
      allowClear
      {...props}
    />
  );
};
