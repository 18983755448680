import React from "react";
import InfiniteScroll from "react-infinite-scroller";

import BookshelfLoader from "presentation/core/components/bookshelfLoader";
import { Comment as CommentComponent } from "./Comment";
import {
  StyledCommentChildContainer,
  StyledCommentsContainer
} from "./component.styles";
import NewCommentContainer from "./NewCommentContainer";
import { Comment, Pagination } from "../../../../api/models";

interface CommentsPropsType {
  comments?: { entry: Comment }[];
  isLoading: boolean;
  isReadonly: boolean;
  onAddClick: (comment: Comment) => void;
  onLoadMore: () => void;
  pagination?: Pagination;
}

const CommentsTab = ({
  comments,
  isLoading,
  isReadonly,
  onAddClick,
  onLoadMore,
  pagination
}: CommentsPropsType) => {
  return (
    <StyledCommentsContainer className={"body-fullsize"}>
      {isLoading ? (
        <BookshelfLoader />
      ) : (
        <>
          <NewCommentContainer
            onAddClick={onAddClick}
            isReadonly={isReadonly}
          />
          <StyledCommentChildContainer className="comments__child-container">
            {comments?.length ? (
              <InfiniteScroll
                hasMore={pagination && pagination.hasMoreItems}
                initialLoad={false}
                loadMore={onLoadMore}
                pageStart={0}
                threshold={300}
                useWindow={false}
              >
                {comments?.map((comment, i) => (
                  <CommentComponent key={i} comment={comment} />
                ))}
              </InfiniteScroll>
            ) : null}
          </StyledCommentChildContainer>
        </>
      )}
    </StyledCommentsContainer>
  );
};

export default CommentsTab;
