import React, { useRef } from "react";
import { useHover as useHoverAhooks } from "ahooks";
import { Options } from "ahooks/lib/useHover";

export type UseHoverResult = [
  JSX.Element,
  {
    isHovering: boolean;
  }
];

export function useHover<Props extends {}>(
  Component: React.ComponentType<Props>,
  props: Props,
  hoverOptions: Options
): UseHoverResult {
  const ref = useRef<HTMLElement>(null);
  const isHovering = useHoverAhooks(ref, hoverOptions);
  const element = <Component ref={ref} {...props} />;

  return [
    element,
    {
      isHovering
    }
  ];
}
