import { HttpClient, HttpMethod } from "infrastructure/service/HttpClient";
import { PaginatedResult, PaginationConfig } from "lib/contract/Pagination";
import {
  mapInactiveComponentListToDomain,
  mapToApi,
  mapToComponentIds
} from "./mappers";
import { SortingConfig } from "lib/contract/Sorting";
import { ApiUrls } from "infrastructure/api/struct/ApiTypesV2";
import { File } from "presentation/core/entities";
import { ActiveComponent } from "../_types";
import { SpisumNodeTypes } from "../../../../../../enums";

const URL: ApiUrls = {
  GetActiveComponents: "/api/app/v2/document/{nodeId}/components/active",
  DigitalDeliveredActiveComponents:
    "/api/app/v2/digitally-delivered/{nodeId}/components/active",
  UpdateActiveComponents:
    "/api/app/v2/document/{nodeId}/component/{componentId}/update",
  DeActivateComponent: "/api/app/v2/document/{nodeId}/components/deactivate"
};

export const getActiveComponents = (
  nodeId: string,
  nodeType: string,
  pagination: PaginationConfig,
  sorting?: SortingConfig
): Promise<PaginatedResult<File>> => {
  const httpClient = new HttpClient();
  const activeUrl =
    nodeType === SpisumNodeTypes.Databox || nodeType === SpisumNodeTypes.Email
      ? URL.DigitalDeliveredActiveComponents
      : URL.GetActiveComponents;
  return httpClient
    .fetchPaginated<File>({
      url: activeUrl,
      pagination,
      sorting,
      config: {
        urlWildCards: {
          nodeId: nodeId
        }
      }
    })
    .then(mapInactiveComponentListToDomain);
};

export const getActiveComponentsForMainActions = (
  nodeId: string,
  pagination: PaginationConfig,
  sorting?: SortingConfig
): Promise<PaginatedResult<File>> => {
  const httpClient = new HttpClient();
  return httpClient
    .fetchPaginated<File>({
      url: URL.GetActiveComponents,
      pagination,
      sorting,
      config: {
        urlWildCards: {
          nodeId: nodeId
        }
      }
    })
    .then(mapInactiveComponentListToDomain);
};

export const updateActiveComponents = (
  nodeId: string,
  body: ActiveComponent
): Promise<void> => {
  const httpClient = new HttpClient();

  return httpClient.fetchWithThrow(
    URL.UpdateActiveComponents,
    HttpMethod.Post,
    {
      bodyJSON: mapToApi(body),
      urlWildCards: {
        nodeId,
        componentId: body.componentId
      }
    }
  );
};

export const postDeActivateComponents = (
  nodeId: string,
  components: File[]
): Promise<void> => {
  const httpClient = new HttpClient();

  return httpClient.fetchWithThrow(URL.DeActivateComponent, HttpMethod.Post, {
    bodyJSON: mapToComponentIds(components),
    urlWildCards: {
      nodeId
    }
  });
};
