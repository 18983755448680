import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { Field } from "formik";
import { Select } from "formik-mui";
import React from "react";
import { StyledFormControlFifth } from "../../../../core/components/dialog/Dialog.styles";
import FormControlWithError from "../../../../core/components/formControlWithError";
import { FilePlan } from "../../../../core/features/login/_types";
import { sslPropsProxy } from "../../../../core/types";
import { lang, t } from "../../../../translation/i18n";
import { lastPathMember, translationPath } from "../../../utils/getPath";
import { isExpiredFilePlan } from "./isExpiredFilePlan";

interface FilePlanFieldInterface {
  validFilePlans: FilePlan[];
  isReadonly: boolean;
  handlePlanChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const FilePlanField = ({
  validFilePlans,
  isReadonly,
  handlePlanChange
}: FilePlanFieldInterface) => {
  return (
    <FormControlWithError
      component={StyledFormControlFifth}
      name={lastPathMember(sslPropsProxy.filePlan).path}
    >
      <InputLabel htmlFor="filePlan">
        {t(translationPath(lang.general.filePlan))}
      </InputLabel>
      <Field
        component={Select}
        data-test-id="meta-input-filePlan"
        disabled={isReadonly}
        name={lastPathMember(sslPropsProxy.filePlan).path}
        inputProps={{
          id: "filePlan",
          onChange: handlePlanChange
        }}
      >
        <MenuItem style={{ minHeight: "33px" }} value="" />
        {validFilePlans.length ? (
          validFilePlans.map((plan) => {
            return (
              <MenuItem
                value={plan.nodeId}
                key={plan.nodeId}
                disabled={
                  validFilePlans.length > 0 ? isExpiredFilePlan(plan) : false
                }
              >
                {plan.name}
              </MenuItem>
            );
          })
        ) : (
          <MenuItem
            disabled={true}
            value={t(translationPath(lang.dialog.form.filePlanNotFound))}
          >
            {t(translationPath(lang.dialog.form.filePlanNotFound))}
          </MenuItem>
        )}
      </Field>
    </FormControlWithError>
  );
};

export default FilePlanField;
