import React from "react";

import { Item } from "../../../../../designSystem/Form/v2/Form";
import { Select } from "../../../../../designSystem/Select/Select";
import { SubjectType } from "../../../../../../domain/struct/nameRegistry/SubjectType";
import { lang, t } from "../../../../../translation/i18n";
import { translationPath } from "../../../../../share/utils/getPath";

export interface SubjectTypeInputProps {
  name?: string;
  onChange?: (value: SubjectType) => void;
  readonly?: boolean;
}

export const SubjectTypeInput = ({
  name,
  onChange,
  readonly
}: SubjectTypeInputProps) => (
  <Item
    name={name || "subjectType"}
    label={t(translationPath(lang.dialog.form.personForm.subjectType))}
    initialValue={SubjectType.Person}
  >
    <Select<SubjectType> onChange={onChange} disabled={readonly}>
      <Select.Option value={SubjectType.Person}>
        {t(translationPath(lang.dialog.form.personForm.person))}
      </Select.Option>
      <Select.Option value={SubjectType.LegalEntity}>
        {t(translationPath(lang.dialog.form.personForm.legalEntity))}
      </Select.Option>
    </Select>
  </Item>
);
