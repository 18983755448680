import { chain, merge } from "lodash";
import { getType } from "typesafe-actions";
import { logoutAction, LogoutActionType } from "../logout/_actions";
import {
  AdminFilePlanAction,
  allGroupsAction,
  FilePlanAction,
  loginAction,
  loginAction__Clear,
  LoginActionType,
  loginKeepAction,
  loginSetDomainAction,
  loginSetSessionTokenAction,
  loginUpdateExpireInAction,
  setActiveGroupAction,
  userGroupAction,
  userInfoAction
} from "./_actions";
import {
  FileMarkMap,
  FilePlan,
  FilePlanGroups,
  LoginStateType,
  SessionStatus
} from "./_types";
import moment from "moment";
import { CONFIG } from "../../../../config";
import { pathsTree } from "./pathsTree";

export const initialState: LoginStateType = {
  error: null,
  expireIn: null,
  configuration: CONFIG.app,
  global: {
    expire: 1800 * 1000,
    fileMarkMap: {},
    filePlans: [],
    filePlansFlat: [],

    groups: {
      dispatch: [],
      main: [],
      repository: [],
      fileNodes: []
    },
    maxItems: 50,
    paths: pathsTree,
    validFilePlans: []
  },
  keepPending: false,
  logoutPending: false,
  pending: false,
  session: {
    domain: "",
    groups: [],
    isAdmin: false,
    myGroups: [],
    remember: null,
    status: SessionStatus.UNAUTHORIZED,
    token: undefined,
    user: undefined
  }
};

const loginReducer = (
  state: LoginStateType = initialState,
  action: LoginActionType | LogoutActionType
): LoginStateType => {
  switch (action.type) {
    case getType(allGroupsAction.request):
      return {
        ...state
      };

    case getType(allGroupsAction.success):
      return {
        ...state,
        global: {
          ...state.global,
          groups: action.payload
        }
      };

    case getType(allGroupsAction.failure):
      return {
        ...initialState,
        error: action.payload
      };

    case getType(FilePlanAction.success):
      const filePlans = action.payload;
      const validFilePlans = filePlans.filter((plan) => {
        if (plan.validTo) {
          return (
            moment(plan.validFrom)
              .add(1, "seconds")
              .isBefore(moment(new Date())) &&
            moment(plan.validTo)
              .add(23, "hours")
              .add(59, "minutes")
              .add(59, "seconds")
              .isAfter(moment(new Date()))
          );
        } else {
          return moment(plan.validFrom)
            .add(1, "seconds")
            .isBefore(moment(new Date()));
        }
      });
      const fileMarkMap = createFileMarkMap(filePlans);
      return {
        ...state,
        global: {
          ...state.global,
          fileMarkMap,
          filePlans,
          filePlansFlat: mapFilePLans(filePlans),
          validFilePlans
        }
      };

    case getType(AdminFilePlanAction.success):
      return {
        ...state,
        global: {
          ...state.global,
          filePlansFlat: mapFilePLans(action.payload)
        }
      };

    case getType(loginAction.request):
      return {
        ...state,
        error: null,
        pending: true,
        session: {
          ...initialState.session,
          remember: (state.session && state.session.remember) || null
        }
      };

    case getType(loginAction.success):
      const { configuration } = action.payload;

      const expire = configuration.tokenExpiration
        ? configuration.tokenExpiration * 60 * 1000
        : state.global.expire;

      return {
        ...state,
        error: null,
        expireIn: new Date().getTime() + expire,
        configuration,
        global: {
          ...state.global,
          expire
        },
        pending: false,
        session: {
          ...state.session,
          remember: action.payload.remember,
          status: SessionStatus.AUTHORIZED
        }
      };

    case getType(loginAction.failure):
      return {
        ...state,
        error: action.payload,
        pending: false,
        session: {
          ...initialState.session,
          remember: (state.session && state.session.remember) || null
        }
      };

    case getType(loginAction__Clear):
      return {
        ...state,
        error: null,
        pending: false
      };

    case getType(loginKeepAction.request):
      return { ...state, keepPending: true };

    case getType(loginKeepAction.success):
    case getType(loginKeepAction.failure):
      return { ...state, keepPending: false };

    case getType(loginSetSessionTokenAction):
      return {
        ...state,
        session: {
          ...state.session,
          domain: action.payload.domain,
          isAdmin: action.payload.isAdmin,
          token: action.payload.token
        }
      };

    case getType(loginSetDomainAction):
      return {
        ...state,
        session: {
          ...state.session,
          domain: action.payload
        }
      };

    case getType(loginUpdateExpireInAction):
      return {
        ...state,
        expireIn: new Date().getTime() + state.global.expire
      };

    case getType(logoutAction.request):
      return { ...state, logoutPending: true };

    case getType(logoutAction.success):
      return merge(initialState, {
        logoutPending: false,
        session: {
          remember: state.session.remember
        }
      });

    case getType(logoutAction.failure):
      return { ...state, logoutPending: false };

    case getType(setActiveGroupAction):
      return {
        ...state,
        session: {
          ...state.session,
          activeGroup: action.payload
        }
      };

    case getType(userInfoAction.request):
      return {
        ...state
      };

    case getType(userInfoAction.success):
      return {
        ...state,
        session: {
          ...state.session,
          activeGroup: action.payload.mainGroup,
          user: action.payload
        }
      };

    case getType(userInfoAction.failure):
      return {
        ...initialState,
        error: action.payload
      };

    case getType(userGroupAction.request):
      return {
        ...state
      };

    case getType(userGroupAction.success):
      return {
        ...state,
        session: {
          ...state.session,
          groups: action.payload.groups,
          myGroups: action.payload.myGroups
        }
      };

    case getType(userGroupAction.failure):
      return {
        ...initialState,
        error: action.payload
      };

    default:
      return state;
  }
};

const mapFilePLans = (filePlans: FilePlan[]): FilePlan[] => {
  return filePlans.map((fp) => {
    return {
      ...fp,
      filePlanGroups: concatFilePLanGroupIntoFlatArray(fp.filePlanGroups)
    };
  });
};

const concatFilePLanGroupIntoFlatArray = (
  filePlanGroups: FilePlanGroups[]
): FilePlanGroups[] => {
  return filePlanGroups.reduce((acc: FilePlanGroups[], filePlanGroup) => {
    acc.push(filePlanGroup);

    return filePlanGroup.filePlanGroups
      ? acc.concat(
          concatFilePLanGroupIntoFlatArray(filePlanGroup.filePlanGroups)
        )
      : acc;
  }, []);
};

const createFileMarkMap = (filePlans: FilePlan[]): FileMarkMap => {
  return chain(filePlans)
    .map((fp) => fp.filePlanGroups)
    .flatten()
    .keyBy((flg) => flg.identificator)
    .value();
};

export default loginReducer;
