import styled from "styled-components";
import React from "react";
import { AddressFormEnum } from "../../../../../../domain/struct/nameRegistry/AddressFormEnums";
import { Item } from "../../../../../designSystem/Form/v2/Form";
import { Select } from "../../../../../designSystem/Select/Select";

interface AddressTypesSelectProps {
  type: string;
  onChange?: (value: string) => void;
  isFetching: boolean;
  options: {
    label: string;
    value: string;
  }[];
}

const validationAddressTypeRules = [{ required: true }];

export const AddressTypeSelect = ({
  type,
  onChange,
  isFetching,
  options
}: AddressTypesSelectProps) => {
  return (
    <StyledSelectItem
      name="addressType"
      label={AddressFormEnum.AddressType}
      rules={validationAddressTypeRules}
    >
      <Select
        onChange={onChange}
        loading={isFetching}
        showSearch
        optionFilterProp="label"
        options={options}
      />
    </StyledSelectItem>
  );
};

const StyledSelectItem = styled(Item)`
  .ant-select {
    max-width: 100%;
  }
`;
