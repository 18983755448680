import { DocumentState } from "presentation/enums";
import React from "react";
import { SettleTabForm } from "./SettleTabForm";
import { useMetaFormValuesWithFilePlans } from "../../hooks/useMetaFormValuesWithFilePlans";
import { useMetaFormDocument } from "../../hooks/useMetaFormDocument";
import { SslProperties } from "presentation/core/api/models";

export const SettleTab = () => {
  const initialValues = useMetaFormValuesWithFilePlans();
  const { formValues } = useMetaFormDocument();

  return (
    <div className="body-fullsize">
      <SettleTabForm
        readonly={true}
        initialValues={{
          ...initialValues,
          fileMark: (formValues as SslProperties).fileMark
        }}
      />
    </div>
  );
};

const allowedStates = [
  DocumentState.Settled,
  DocumentState.Closed,
  DocumentState.ReferedToArchive,
  DocumentState.ReferedToRepository,
  DocumentState.Shredded
];

SettleTab.filter = (state: string | undefined) => {
  return state ? allowedStates.includes(state as DocumentState) : false;
};
