import { useMutation } from "presentation/share/hook/query/useQM";
import { useModal } from "@isfg/react-components/build/Modal/useModal";
import { HttpClient } from "../../../../../../infrastructure/service/HttpClient";
import { Concept } from "../types";
import { UpdateConceptModal } from "../modals/UpdateConceptModal";
import { DocumentType, SpisumNodeTypes } from "../../../../../enums";
import { GenericDocument } from "../../../../../core/types";
import { ApiTypes } from "../../../../../../infrastructure/api/struct/ApiTypes";

export type ApiConcept = ApiTypes["ISFG.Alfresco.Api.Models.Generated.Fixed.CoreApi.NodeEntryFixed"];

const getConcept = (node: GenericDocument): Promise<Concept> => {
  const httpClient = new HttpClient();
  return httpClient
    .fetchWithThrow<ApiConcept>("/api/app/v1/concept/{nodeId}", "GET", {
      urlWildCards: {
        nodeId: node.id
      }
    })
    .then((response: ApiConcept) => {
      const { entry } = response;
      return {
        nodeId: entry.id,
        nodeType: entry.nodeType,
        subject: entry?.properties?.["ssl:subject"],
        pid: entry?.properties?.["ssl:pid"],
        owner: entry?.properties?.["cm:owner"],
        createdAt: new Date(entry.createdAt)
      };
    });
};

export const useDetailConcept = () => {
  const [modalUpdate, modalApi] = useModal(UpdateConceptModal, {});
  const { isLoading, mutate: registerUpdate } = useMutation(
    (node: GenericDocument) => getConcept(node),
    {
      onSuccess: (data) => {
        modalApi.open({
          document: data,
          nodeId: data.nodeId,
          documentType: DocumentType.Digital,
          nodeType: SpisumNodeTypes.Concept
        });
      }
    }
  );

  return {
    isLoading,
    registerUpdate,
    modalUpdate
  };
};
