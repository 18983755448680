import React from "react";
import { FilePlanGroups } from "presentation/core/features/login/_types";
import { FileMarkField, FileMarkFieldProps } from "./FileMarkField";

export const GeneralFileMarkField = ({
  fileMarks,
  ...props
}: FileMarkFieldProps) => {
  const fileMarksWithFilter = fileMarks.filter(
    (mark: FilePlanGroups) => mark.fullyDeterminedFileMark !== "99"
  );

  return <FileMarkField {...props} fileMarks={fileMarksWithFilter} />;
};
