import { TimePicker } from "antd";
import {
  ProcessMethod,
  SettleMethod
} from "domain/struct/document/DocumentsForProcessingEnums";
import { DateTimeFormat } from "lib/dateTime";
import moment from "moment";
import { documentViewAction__Refresh } from "presentation/core/components/documentView/_actions";
import { sslPropsProxy } from "presentation/core/types";
import { DatePicker } from "presentation/designSystem/DatePicker/DatePicker";
import { Form, Item, useForm } from "presentation/designSystem/Form/v2/Form";
import { Row } from "presentation/designSystem/Grid/Grid";
import { Input } from "presentation/designSystem/Input/Input";
import { reasonValidation } from "presentation/designSystem/Input/InputValidations";
import { BaseModalProps } from "presentation/designSystem/Modal/useModal";
import { Select } from "presentation/designSystem/Select/Select";
import { useMutation } from "presentation/share/hook/query/useQM";
import { getErrorCodeTranslation } from "presentation/share/utils/errorCodeTranslation";
import {
  lastPathMember,
  translationPath
} from "presentation/share/utils/getPath";
import { t } from "presentation/translation/i18n";
import lang from "presentation/translation/lang";
import { ErrorType } from "presentation/types";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import {
  Modal,
  ModalSize
} from "../../../../../../../../packages/react-components/build/Modal/Modal";
import { SettleDocumentParams, settleDocument } from "../api/settleDocument";
import { useGetOtherSettleMethods } from "../hooks/useGetOtherSettleMethods";

interface ProcessDocumentFormFieldTypes
  extends Omit<SettleDocumentParams, "nodeId"> {
  processedAt: moment.Moment;
  processedTime: moment.Moment;
}

export interface ProcessDocumentModals extends BaseModalProps {
  nodeId: string;
  onChange?: (value: SettleMethod) => void;
  isFetching?: boolean;
  options?: {
    label: string;
    value: string;
  }[];
  required?: boolean;
}

export const ProcessDocumentModal = ({
  onCancel,
  onChange,
  nodeId,
  onOk
}: ProcessDocumentModals) => {
  const dispatch = useDispatch();
  const getNotificationForError = (error: ErrorType) => ({
    message: error.code
      ? getErrorCodeTranslation(error.code)
      : Array.isArray(error.messages) && error.messages.length > 0
        ? error.messages[0]
        : t(translationPath(lang.dialog.notifications.actionFailed))
  });
  const [isOtherSettleReasonVisible, setIsOtherSettleReasonVisible] =
    useState(false);
  const [form] = useForm<ProcessDocumentFormFieldTypes>();
  const onConfirm = async () => {
    try {
      await form.validateFields();
      const { settleMethod, otherSettleMethod, otherSettleReason } =
        form.getFieldsValue();
      mutate({ settleMethod, otherSettleMethod, otherSettleReason, nodeId });
    } catch {}
  };
  const { isOtherSettleMethodsLoading, otherSettleMethodsData } =
    useGetOtherSettleMethods();

  const { isLoading, mutate } = useMutation(
    (params: SettleDocumentParams) => settleDocument(params),
    {
      onSuccess() {
        dispatch(documentViewAction__Refresh(true));
        onOk?.();
      },
      onSuccessNotification: {
        message: t(
          translationPath(
            lang.dialog.notifications.documentForSealFormSucceeded
          )
        )
      },
      onErrorNotification: getNotificationForError
    }
  );

  const handleChange = (value: string) =>
    setIsOtherSettleReasonVisible(
      SettleMethod[value as keyof typeof SettleMethod] ===
        SettleMethod.jinyZpusob
    );

  return (
    <Modal
      open={true}
      title={t(translationPath(lang.dialog.title.settle))}
      onCancel={onCancel}
      onOk={onConfirm}
      confirmLoading={isLoading}
      className={""}
      size={ModalSize.Small}
      okText={t(translationPath(lang.modal.ok))}
      cancelText={t(translationPath(lang.modal.cancel))}
    >
      <Form
        form={form}
        initialValues={{ processedAt: moment(), processedTime: moment() }}
      >
        <RowStyled justify="space-between">
          <StyledSelectItem
            name="settleMethod"
            label={ProcessMethod.ProcessMethod}
            rules={[{ required: true }]}
            required={true}
          >
            <Select onChange={handleChange} style={{ width: "15rem" }}>
              {Object.keys(SettleMethod).map((key) => (
                <Select.Option key={key} value={key}>
                  {SettleMethod[key as keyof typeof SettleMethod]}
                </Select.Option>
              ))}
            </Select>
          </StyledSelectItem>

          <Item
            name="processedAt"
            label={t(translationPath(lang.general.settleDate))}
            required={true}
          >
            <DatePicker
              disabled={true}
              format={DateTimeFormat.DateTimeNoSeconds}
              name={lastPathMember(sslPropsProxy.settleDate).path}
              style={{ width: "15rem" }}
            />
          </Item>

          <Item
            name="processedTime"
            label={t(translationPath(lang.general.settleTime))}
            required={true}
          >
            <TimePicker
              disabled={true}
              format={DateTimeFormat.HoursMinutes}
              name={lastPathMember(sslPropsProxy.settleTime).path}
              style={{ width: "15rem" }}
            />
          </Item>

          {isOtherSettleReasonVisible && (
            <StyledSelectItem
              name="otherSettleMethod"
              label={t(translationPath(lang.general.customSettleMethod))}
              rules={[{ required: true }]}
              required={true}
            >
              <Select
                onChange={onChange}
                style={{ width: "15rem" }}
                loading={isOtherSettleMethodsLoading}
              >
                {(otherSettleMethodsData || []).map(({ value }) => (
                  <Select.Option key={value} value={value}>
                    {value}
                  </Select.Option>
                ))}
              </Select>
            </StyledSelectItem>
          )}
        </RowStyled>
        {isOtherSettleReasonVisible && (
          <Item
            name="otherSettleReason"
            label={t(translationPath(lang.general.justification))}
            rules={[{ required: true }, reasonValidation]}
            required={true}
          >
            <Input style={{ width: "100%" }} />
          </Item>
        )}
      </Form>
    </Modal>
  );
};

const StyledSelectItem = styled(Item)`
  .ant-select {
    max-width: 100%;
  }
`;

const RowStyled = styled(Row)`
  align-items: flex-end;
`;
