import { getService } from "presentation/core/features/dependencyInjection";
import { useQM } from "../../../../../../share/hook/query/useQM";
import { FindRetentionProtocolsErased } from "../../../../../../../useCase/recordRetention/FindRetentionProtocolsErased";

const KEY = "retentionProposals/findOneRetentionProtocol";

export const useFindOneRetentionErasedProtocol = (nodeId: string) => {
  const findRetentionProtocol = getService(FindRetentionProtocolsErased);
  return useQM([KEY, nodeId], () =>
    findRetentionProtocol.findOneRetentionErasedProtocol(nodeId)
  );
};
