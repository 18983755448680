import { getDic } from "./core/features/dependencyInjection";
import { register } from "../config/di";
import mailRoomModule from "./modules/mailroom";
import * as administrationModule from "./modules/administration";

let booted = false;

export const boot = () => {
  if (booted) {
    return;
  }

  booted = true;

  const dic = getDic();

  register(dic);
  mailRoomModule.register();
  administrationModule.register(dic);
};
