import { useCallback } from "react";
import { postComponentsForSeal } from "../api";

export const usePostComponentsForSeal = () => {
  return useCallback(
    (nodeId: string, componentId: string) =>
      postComponentsForSeal(nodeId, componentId),
    [postComponentsForSeal]
  );
};
