import React, { useCallback, useContext, useMemo } from "react";
import { RemoteTableApi } from "../../../../../packages/react-components/build/Table/RemoteTable";
import { useRemoteTableApi } from "../hook/useRemoteTableApi";

export interface RemoteTableApiState {
  refreshData(): void;
  setTableApi(_api: RemoteTableApi): void;
}

export const RemoteTableApiContext = React.createContext<RemoteTableApiState>({
  refreshData: () => {},
  setTableApi: () => {}
});

export const RemoteTableApiContextProvider: React.FC<any> = ({ children }) => {
  const [tableApi, setTableApi] = useRemoteTableApi();

  const refreshData = useCallback(() => {
    tableApi.refetch();
  }, [tableApi]);

  const memoizedValues = useMemo(
    () => ({
      setTableApi,
      refreshData
    }),
    [setTableApi, refreshData]
  );

  return (
    <RemoteTableApiContext.Provider value={memoizedValues}>
      {children}
    </RemoteTableApiContext.Provider>
  );
};

export const useRemoteTableApiContext = () => {
  const context = useContext(RemoteTableApiContext);
  if (!context) {
    throw new Error("Check if provider set up properly");
  }
  return context;
};
