import {
  AddBoxOutlined,
  Alarm,
  AlarmOff,
  AllInbox,
  Description,
  SwapHoriz
} from "@mui/icons-material";
import { openDocumentWithSaveButtonsAction } from "presentation/core/api/document/_actions";
import {
  ControlsBarType,
  DataColumn
} from "presentation/core/components/dataTable/_types";
import { dialogOpenAction } from "presentation/core/components/dialog/_actions";
import { DialogType } from "presentation/core/components/dialog/_types";
import { openFileDetailsAction } from "presentation/core/components/dialog/tabs/tableOfContents/_actions";
import MenuLayout from "presentation/core/components/layout/MenuLayout";
import { GenericDocument, genericDocumentProxy } from "presentation/core/types";
import { DocumentType, SitePaths, SpisumNodeTypes } from "presentation/enums";
import { RootStateType } from "presentation/reducers";
import { fileMarkColumn } from "presentation/share/components/table/column/document/FilePlan/fileMarkColumn";
import { filePlanColumn } from "presentation/share/components/table/column/document/FilePlan/filePlanColumn";
import { classPath, translationPath } from "presentation/share/utils/getPath";
import { getQueryPath } from "presentation/share/utils/query";
import { convertDateToYear } from "presentation/share/utils/utils";
import { lang, t, withTranslation } from "presentation/translation/i18n";
import { useDispatch, useSelector } from "react-redux";
import ForShreddingTable from "./tables/ForShreddingTable";
import React from "react";

const defaultColumn: DataColumn<GenericDocument> = {
  getValue: (x) => convertDateToYear(x?.properties?.ssl?.shreddingYear),
  keys: [classPath(genericDocumentProxy.properties!.ssl!.shreddingYear).path],
  label: t(translationPath(lang.general.yearOfShredding))
};

export const columns: DataColumn<GenericDocument>[] = [
  {
    keys: [classPath(genericDocumentProxy.properties!.ssl!.pid).path],
    label: t(translationPath(lang.general.identifier))
  },
  {
    getValue: (item) =>
      item.nodeType === SpisumNodeTypes.Document
        ? item.properties?.ssl?.ssid
        : item.properties?.ssl?.fileIdentifier,
    keys: [
      classPath(genericDocumentProxy.properties!.ssl!.ssid).path,
      classPath(genericDocumentProxy.properties!.ssl!.fileIdentifier).path
    ],
    label: `${t(translationPath(lang.general.referenceNumber))}/${t(
      translationPath(lang.general.fileIdentifier)
    )}`
  },
  {
    keys: [classPath(genericDocumentProxy.properties!.ssl!.subject).path],
    label: t(translationPath(lang.general.subject))
  },
  filePlanColumn,
  fileMarkColumn,
  {
    keys: [classPath(genericDocumentProxy.properties!.ssl!.retentionMode).path],
    label: t(translationPath(lang.general.retentionMode))
  },
  defaultColumn,
  {
    keys: [classPath(genericDocumentProxy.properties!.ssl!.location).path],
    label: t(translationPath(lang.general.location))
  }
];

const ForShredding = () => {
  const dispatch = useDispatch();
  const path = useSelector(
    (state: RootStateType) =>
      getQueryPath(state.loginReducer.global.paths, null, SitePaths.Repository)
        ?.path || ""
  );

  const dispatchOpenDialog: (row: GenericDocument) => void = (row) => {
    if (row.nodeType === SpisumNodeTypes.Document) {
      dispatch(
        openDocumentWithSaveButtonsAction({
          canUploadComponents: false,
          data: {
            ...row,
            nodeType: SpisumNodeTypes.Document
          },
          hideManageShipmentsIcon: true,
          initiator: SpisumNodeTypes.File,
          isReadonly: true
        })
      );
    } else if (row.nodeType === SpisumNodeTypes.File) {
      dispatch(
        openFileDetailsAction({
          data: {
            ...row,
            nodeType: SpisumNodeTypes.File
          },
          hideManageShipmentsIcon: true,
          initiator: SpisumNodeTypes.File,
          isReadonly: true
        })
      );
    }
  };

  const controls: ControlsBarType<GenericDocument> = {
    multi: {
      items: [
        {
          action: (selected) => {
            dispatch(
              dialogOpenAction({
                dialogProps: {
                  data: selected
                },
                dialogType: DialogType.CreateRetentionProposal
              })
            );
          },
          icon: <AddBoxOutlined />,
          title: t(translationPath(lang.general.createShreddingProposal))
        }
      ]
    },
    single: {
      items: [
        {
          action: (selected: GenericDocument[]) =>
            dispatchOpenDialog(selected[0]),
          icon: <Description />,
          title: t(translationPath(lang.general.showDetails))
        },
        {
          action: (selected: GenericDocument[]) => {
            dispatch(
              dialogOpenAction({
                dialogProps: {
                  data: selected[0]
                },
                dialogType: DialogType.ChangeFileMark
              })
            );
          },
          icon: <SwapHoriz />,
          filter: (document) => !!document.properties?.ssl?.discardTo,
          title: t(translationPath(lang.general.changeFileMark))
        },
        {
          action: (selected: GenericDocument[]) => {
            dispatch(
              dialogOpenAction({
                dialogProps: { data: selected[0] },
                dialogType: DialogType.ShreddingDiscard
              })
            );
          },
          filter: (x) => !x.properties?.ssl?.discardTo,
          icon: <Alarm />,
          title: t(translationPath(lang.general.discardFromShredding))
        },
        {
          action: (selected: GenericDocument[]) => {
            dispatch(
              dialogOpenAction({
                dialogProps: { data: selected[0] },
                dialogType: DialogType.CancelDiscardDialog
              })
            );
          },
          filter: (x) => !!x.properties?.ssl?.discardTo,
          icon: <AlarmOff />,
          title: t(translationPath(lang.general.cancelDiscard))
        },
        {
          action: (selected: GenericDocument[]) => {
            dispatch(
              dialogOpenAction({
                dialogProps: { data: selected[0] },
                dialogType: DialogType.ChangeLocation
              })
            );
          },
          filter: (document) =>
            document?.properties?.ssl?.form === DocumentType.Analog ||
            document?.properties?.ssl?.form === DocumentType.Hybrid,
          icon: <AllInbox />,
          title: t(translationPath(lang.general.editStorageLocation))
        },
        {
          action: (selected: GenericDocument[]) => {
            dispatch(
              dialogOpenAction({
                dialogProps: { data: selected },
                dialogType: DialogType.CreateRetentionProposal
              })
            );
          },
          filter: (x) => !x.properties?.ssl?.discardTo,
          icon: <AddBoxOutlined />,
          title: t(translationPath(lang.general.createShreddingProposal))
        }
      ]
    }
  };

  return (
    <MenuLayout>
      {/*<>*/}
      {/*  <DocumentView*/}
      {/*    columns={columns}*/}
      {/*    controls={controls}*/}
      {/*    customTitle={t(*/}
      {/*      translationPath(lang.table.documentsFilesForShredding)*/}
      {/*    )}*/}
      {/*    defaultSortAsc={true}*/}
      {/*    defaultSortColumn={defaultColumn}*/}
      {/*    handleDoubleClick={dispatchOpenDialog}*/}
      {/*    search={{*/}
      {/*      query: {*/}
      {/*        query: alfrescoQuery({*/}
      {/*          paths: [path],*/}
      {/*          type: [SpisumNodeTypes.Document, SpisumNodeTypes.File],*/}
      {/*          where: `${*/}
      {/*            SpisumNames.ShreddingYear*/}
      {/*          }:[MIN TO '${new Date().toISOString()}']`*/}
      {/*        })*/}
      {/*      }*/}
      {/*    }}*/}
      {/*  />*/}
      {/*</>*/}
      <ForShreddingTable />
    </MenuLayout>
  );
};

export default withTranslation()(ForShredding);
