import { TextField } from "formik-mui";
import React from "react";

import { StyledFieldFifth } from "../../../../../../core/components/dialog/Dialog.styles";
import { lang, t } from "../../../../../../translation/i18n";
import { lastPathMember, translationPath } from "../../../../../utils/getPath";
import { CreateShipmentFormValuesProxy } from "../../_types";

interface AmountToSendProps {
  readonly?: boolean;
}

const AmountToSend: React.FC<AmountToSendProps> = ({ readonly }) => {
  return (
    <>
      <h2 style={{ width: "100%" }}>
        {t(translationPath(lang.general.postItemAmount))}
      </h2>
      <StyledFieldFifth
        component={TextField}
        data-test-id="create-shipment-databox-legalTitleLaw"
        disabled={readonly}
        name={lastPathMember(CreateShipmentFormValuesProxy.listCount).path}
        type="number"
        required={true}
        label={t(translationPath(lang.general.postItemListNumber))}
      />
      <StyledFieldFifth
        component={TextField}
        data-test-id="create-shipment-databox-legalTitleYear"
        disabled={readonly}
        name={
          lastPathMember(CreateShipmentFormValuesProxy.attachmentsCount).path
        }
        type="number"
        required={true}
        label={t(translationPath(lang.general.postItemAttachmentsNumber))}
      />
      <StyledFieldFifth
        component={TextField}
        data-test-id="create-shipment-databox-legalTitleYear"
        disabled={readonly}
        name={
          lastPathMember(CreateShipmentFormValuesProxy.listCountAttachments)
            .path
        }
        type="number"
        required={true}
        label={t(translationPath(lang.general.postItemListAttachmentsNumber))}
      />
      <StyledFieldFifth
        component={TextField}
        data-test-id="create-shipment-databox-legalTitlePar"
        disabled={readonly}
        name={
          lastPathMember(CreateShipmentFormValuesProxy.attachmentsType).path
        }
        type="text"
        label={t(translationPath(lang.general.postItemAttachmentsKind))}
      />
    </>
  );
};

export default AmountToSend;
