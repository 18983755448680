import contentDisposition from "content-disposition";
import fileDownload from "js-file-download";
import { ErrorResponseDataType } from "./fetch";

type Response = string | boolean | Blob | ErrorResponseDataType;

const defaultMimeType = "application/pdf";

export const downloadDocument = (
  response: Response,
  headers: Headers,
  mime: string = defaultMimeType
) => {
  const parsedResponseHeaders = headers.get("content-disposition");
  if (parsedResponseHeaders) {
    const disposition = contentDisposition.parse(parsedResponseHeaders);
    if (disposition && response && response instanceof Blob) {
      fileDownload(response, disposition.parameters.filename, mime);
    }
  }
};
