import { useStyles } from "presentation/core/components/dialog/Dialog.styles";
import { DialogContentType } from "presentation/core/components/dialog/_types";
import React from "react";
import { DontRegisterDocumentForm } from "./DontRegisterDocumentForm";
import { DontRegisterDocumentFormValues } from "./_types";
import { validate } from "./_validations";
import { ChannelForm } from "../ChannelForm/ChannelForm";

export const DontRegisterDocumentContentDialog: DialogContentType["content"] = ({
  channel
}) => {
  const classes = useStyles();

  return (
    <div className={classes.modalSmall}>
      <ChannelForm<DontRegisterDocumentFormValues>
        initialValues={{
          reason: ""
        }}
        validate={validate}
        channel={channel}
        component={DontRegisterDocumentForm}
      />
    </div>
  );
};
