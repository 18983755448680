import { getService } from "presentation/core/features/dependencyInjection";
import { useCallback } from "react";
import { FindRetentionProposals } from "../../../../../../../useCase/recordRetention/FindRetentionProposals";

export const useUploadDecisionFromDA = () => {
  const uploadDecision = getService(FindRetentionProposals);

  return useCallback(
    (id: string, file: File) => uploadDecision.uploadDecisionFromDA(id, file),
    [uploadDecision]
  );
};
