export const findIndexByProp = <T>(
  items: T[],
  item: T,
  prop: keyof T
): number => {
  const maybeItem = findByProp(items, item, prop);

  if (!maybeItem) {
    return -1;
  }

  return items.indexOf(maybeItem);
};

export const findByIdentityOrProp = <T>(
  items: T[],
  item: T,
  prop: keyof T
): T | undefined => {
  const index = items.indexOf(item);

  if (index) {
    return items[index];
  }

  return findByProp(items, item, prop);
};

export const findByProp = <T>(
  items: T[],
  item: T,
  prop: keyof T
): T | undefined => items.find((it) => it[prop] === item[prop]);

export const removeByIdentity = <T>(arr: T[], item: T) =>
  [...arr].splice(arr.indexOf(item), 1);
