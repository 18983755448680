import { GroupBag } from "../../../domain/struct/user/GroupBag";
import { ApiTypes } from "../struct/ApiTypes";
import { UserGroups } from "../../../domain/struct/user/UserGroups";
import { GroupsToHandover } from "../../../domain/struct/user/GroupsToHandover";

export type ApiApplicableGroupsForUserResponse = ApiTypes["ISFG.SpisUm.ClientSide.Models.V1.User.Response.UserGroupsResponse"];
export type ApiUserGroups = ApiTypes["ISFG.SpisUm.ClientSide.Models.V1.User.Response.UserGroupsResponse"];
export type ApiGroupsToHandover = ApiTypes["ISFG.SpisUm.ClientSide.Models.Group.GroupMember"];

export class GroupApiMapper {
  static mapApplicableGroupsForUser(
    response: ApiApplicableGroupsForUserResponse
  ): GroupBag {
    return {
      groups: findGroupsByIds(response, response.fileNodes || []),
      mainGroups: findGroupsByIds(response, response.mainFileNodes || []),
      signGroups: findGroupsByIds(response, response.signFileNodes || [])
    };
  }

  static mapUserGroupsToDomain(response: ApiUserGroups): UserGroups {
    return {
      groups: response.fileNodes || [],
      signGroups: response.signFileNodes || []
    };
  }

  static mapGroupsToHandover(
    response: ApiGroupsToHandover[]
  ): GroupsToHandover[] {
    return response.map((resp) => ({
      id: resp.id || "",
      displayName: resp.displayName || ""
    }));
  }
}

const findGroupsByIds = (
  response: ApiApplicableGroupsForUserResponse,
  ids: string[]
) =>
  (response.groups?.list?.entries || [])
    .filter(({ entry }) => ids.includes(entry.id))
    .map(({ entry }) => entry);
