import { DialogContentType } from "presentation/core/components/dialog/_types";
import React from "react";
import { CreateShipmentForm } from "./CreateShipmentForm";

export const CreateShipment: DialogContentType["content"] = ({
  channel,
  dialogProps
}) => (
  <CreateShipmentForm
    channel={channel}
    dialogProps={dialogProps}
    readonly={!!dialogProps.isReadonly}
  />
);
