import { ApiListResponse, ApiTypes } from "../struct/ApiTypesV2";
import { ApiListResponseMapper } from "../mapper/ApiListResponseMapper";
import { stringOrDefault } from "../../../lib/string";
import { SearchResult } from "../../../domain/struct/search/SearchResult";

export type ApiRepositorySearchedResponse = ApiTypes["ISFG.SpisUm.ClientSide.Models.V2.Search.SearchResponse"];

export class SearchedApiMapper {
  static mapListToDomain(
    response: ApiListResponse<ApiRepositorySearchedResponse>
  ) {
    return ApiListResponseMapper.mapListToPagination(
      response,
      SearchedApiMapper.mapToDomain
    );
  }

  static mapToDomain(response: ApiRepositorySearchedResponse): SearchResult {
    const {
      nodeId,
      nodeType,
      pid,
      ssid,
      fileIdent,
      subject,
      filePlan,
      fileMark,
      retentionMode,
      sender,
      owner,
      ownerGroup,
      settleToDate,
      evidenceDate
    } = response;
    return {
      nodeId: stringOrDefault(nodeId),
      nodeType: stringOrDefault(nodeType),
      pid: stringOrDefault(pid),
      ssid: stringOrDefault(ssid),
      fileIdent: stringOrDefault(fileIdent),
      subject: stringOrDefault(subject),
      filePlan: stringOrDefault(filePlan),
      fileMark: stringOrDefault(fileMark),
      retentionMode: stringOrDefault(retentionMode),
      sender: stringOrDefault(sender),
      owner: stringOrDefault(owner),
      ownerGroup: stringOrDefault(ownerGroup),
      settleToDate: stringOrDefault(settleToDate),
      evidenceDate: stringOrDefault(evidenceDate)
    };
  }
}
