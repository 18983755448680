import { ItemOfMenu, ItemsOfMenu } from "./_types";
import { trimEnd } from "../../../../lib/string";

export const getActiveItemId = (location: string, itemsOfMenu: ItemsOfMenu) => {
  for (const item of itemsOfMenu) {
    const index = itemsOfMenu.indexOf(item);

    if (
      isActiveItem(location, item.url) ||
      hasActiveSubItem(location, item.submenu)
    ) {
      return index;
    }
  }
  return -1;
};

const isActiveItem = (location: string, itemUrl = "") =>
  trimEnd(itemUrl.toLowerCase(), "/") === location;

const hasActiveSubItem = (
  location: string,
  itemsOfMenu = [] as ItemsOfMenu
): boolean =>
  itemsOfMenu.some((item: ItemOfMenu) => {
    return (
      isActiveItem(location, item.url) ||
      hasActiveSubItem(location, item.submenu)
    );
  });

export const getIdForMenuPath = (
  location: string,
  itemsOfMenu: ItemsOfMenu
) => {
  for (const item of itemsOfMenu) {
    if (
      isActiveItem(location, item.url) ||
      (item.submenu &&
        item.submenu.length &&
        getIdForMenuPath(location, item.submenu) !== -1)
    ) {
      return itemsOfMenu.indexOf(item);
    }
  }
  return -1;
};
