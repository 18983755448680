import { ApiAlfrescoListResponse, ApiTypes } from "../struct/ApiTypes";
import { PaginatedResult } from "../../../lib/contract/Pagination";
import {
  DocumentConcernedSubject,
  DocumentSubjectRelationType
} from "../../../domain/struct/document/DocumentConcernedSubject";
import { AlfrescoListResponseMapper } from "../mapper/AlfrescoListResponseMapper";
import { Subject } from "../../../domain/struct/nameRegistry/Subject";
import { ApiSubject, SubjectApiMapper } from "../nameRegistry/SubjectApiMapper";

export type ApiDocumentConcernedSubject = ApiTypes["ISFG.SpisUm.ClientSide.Models.NameRegister.DocumentConcernedInfo"];

export class DocumentConcernedSubjectApiMapper {
  static mapFindAll(
    response: ApiAlfrescoListResponse<ApiDocumentConcernedSubject>
  ): PaginatedResult<DocumentConcernedSubject> {
    return AlfrescoListResponseMapper.mapListToPagination(
      response,
      DocumentConcernedSubjectApiMapper.mapToDomain
    );
  }

  static mapToDomain(
    apiDocumentConcernedSubject: ApiDocumentConcernedSubject
  ): DocumentConcernedSubject {
    return {
      id: apiDocumentConcernedSubject.relationId,
      documentId: apiDocumentConcernedSubject.documentId || undefined,
      relationType:
        ((apiDocumentConcernedSubject.relationType as unknown) as DocumentSubjectRelationType) ||
        undefined,
      ...(apiDocumentConcernedSubject.subject && {
        subject: DocumentConcernedSubjectApiMapper.mapSubjectToDomain(
          apiDocumentConcernedSubject.subject
        )
      })
    };
  }

  static mapSubjectToDomain(subject: ApiSubject): Subject {
    return SubjectApiMapper.mapToDomain(subject);
  }
}
