import { classPath, translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import { File, FileMetaType, fileProxy } from "../../../../entities";
import { DataColumn, DataColumnType } from "../../../dataTable/_types";

const readableValues = [
  {
    text: "---",
    value: ""
  },
  { value: "true", text: t(translationPath(lang.general.yes)) },
  { value: "false", text: t(translationPath(lang.general.no)) }
];

const dmFileMetaTypeValues = [
  {
    text: "---",
    value: ""
  },
  {
    text: t(translationPath(lang.enums.fileMetaTypes.deliveryNote)),
    value: FileMetaType.deliveryNote
  },
  {
    text: t(translationPath(lang.enums.fileMetaTypes.signature)),
    value: FileMetaType.signature
  },
  {
    text: t(translationPath(lang.enums.fileMetaTypes.meta)),
    value: FileMetaType.meta
  },
  {
    text: t(translationPath(lang.enums.fileMetaTypes.enclosure)),
    value: FileMetaType.enclosure
  },
  {
    text: t(
      translationPath(lang.enums.fileMetaTypes.signatureVerificationProtocol)
    ),
    value: FileMetaType.signatureVerificationProtocol
  }
];

const getMetaTypeItems = (mainFile: File | undefined) => (row: File) => {
  // there is no main file, or we are generating dropdown items for the main file
  return dmFileMetaTypeValues;
};

export const generateColumns = (
  mainFile: File | undefined
): DataColumn<File>[] => {
  return [
    {
      keys: [classPath(fileProxy.name).path],
      label: t(translationPath(lang.general.name)),
      sortKeys: ["name"]
    },
    {
      getDropdownItems: getMetaTypeItems(mainFile),
      keys: [classPath(fileProxy.type).path],
      label: t(translationPath(lang.general.type)),
      type: DataColumnType.dropdown
    },
    {
      keys: [classPath(fileProxy.sizeInBytes).path],
      label: t(translationPath(lang.general.size)),
      sortKeys: ["sizeInBytes"],
      type: DataColumnType.fileSize
    },
    {
      dropdownItems: readableValues,
      keys: [classPath(fileProxy.isReadable).path],
      label: t(translationPath(lang.general.readable)),
      type: DataColumnType.dropdown
    }
  ];
};
