import { getService } from "presentation/core/features/dependencyInjection";
import { FindLegalEntity } from "../../../../useCase/nameRegistry/FindLegalEntity";
import { PaginationConfig } from "../../../../lib/contract/Pagination";
import { useCallback } from "react";
import { SortingConfig } from "lib/contract/Sorting";

export const useFindLegalEntities = () => {
  const findUseCase = getService(FindLegalEntity);

  return useCallback(
    (pagination: PaginationConfig, sorting?: SortingConfig) =>
      findUseCase.findAllPaginated(pagination, sorting),
    [findUseCase]
  );
};
