import {
  addCommentsAction,
  getCommentsAction
} from "presentation/core/api/comments/_actions";
import { put, race, take, takeLatest } from "redux-saga/effects";
import { translationPath } from "presentation/share/utils/getPath";
import { t } from "presentation/translation/i18n";
import lang from "presentation/translation/lang";
import { ActionType, getType } from "typesafe-actions";
import { addCommentsInTab, fetchCommentsInTab } from "./_actions";
import { Notification } from "presentation/designSystem/notification/Notification";

export function* watchCommentsAction() {
  yield takeLatest(
    getType(fetchCommentsInTab.request),
    function* ({ payload }: ActionType<typeof fetchCommentsInTab.request>) {
      const { append, ...rest } = payload;

      yield put(getCommentsAction.request(rest));

      const [successResponse, errorResponse] = yield race([
        take(getCommentsAction.success),
        take(getCommentsAction.failure)
      ]);

      if (errorResponse) {
        yield put(fetchCommentsInTab.failure(errorResponse));

        Notification.error({
          message: t(translationPath(lang.dialog.notifications.somethingFailed))
        });

        return;
      }

      yield put(
        fetchCommentsInTab.success({ ...successResponse.payload, append })
      );
    }
  );

  yield takeLatest(
    getType(addCommentsInTab.request),
    function* ({ payload }: ActionType<typeof addCommentsInTab.request>) {
      yield put(addCommentsAction.request(payload));

      const [successResponse, errorResponse] = yield race([
        take(addCommentsAction.success),
        take(addCommentsAction.failure)
      ]);

      if (errorResponse) {
        yield put(addCommentsInTab.failure(errorResponse));
        Notification.error({
          message: t(translationPath(lang.dialog.notifications.somethingFailed))
        });
        return;
      }

      yield put(addCommentsInTab.success({ ...successResponse.payload }));
    }
  );
}
