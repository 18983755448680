import {
  CreatePayload,
  PersonRepository
} from "domain/service/nameRegistry/PersonRepository";
import { Person, PersonId } from "domain/struct/nameRegistry/Person";
import { HttpClient, HttpMethod } from "infrastructure/service/HttpClient";
import { DInjectable } from "presentation/core/features/dependencyInjection";
import {
  PaginatedResult,
  PaginationConfig
} from "../../../lib/contract/Pagination";
import { ApiPerson, PersonApiMapper } from "./PersonApiMapper";
import { ApiUrls } from "../struct/ApiTypes";
import { ApiUrls as ApiUrls2 } from "../struct/ApiTypesV2";
import { SortingConfig } from "lib/contract/Sorting";

const URL: ApiUrls = {
  CreatePerson: "/api/app/v1/name-register/subject/individual-subject/create",
  UpdatePerson:
    "/api/app/v1/name-register/individual-subject/{subjectId}/update",
  GetPersonBasic: "/api/app/v1/name-register/subject/{subjectId}/basic",
  GetPersonFull: "/api/app/v1/name-register/subject/{subjectId}/full"
};
const URL2: ApiUrls2 = {
  GetPersons: "/api/app/v2/name-register/individual-subjects"
};

@DInjectable()
export class PersonApiRepository implements PersonRepository {
  constructor(protected httpClient: HttpClient) {}

  findAllPaginated(
    pagination: PaginationConfig,
    sorting?: SortingConfig
  ): Promise<PaginatedResult<Person>> {
    return this.httpClient
      .fetchPaginated<ApiPerson>({ url: URL2.GetPersons, pagination, sorting })
      .then(PersonApiMapper.mapFindAllResponse);
  }

  create(payload: CreatePayload): Promise<void> {
    return this.httpClient.fetchWithThrow(URL.CreatePerson, HttpMethod.Post, {
      bodyJSON: PersonApiMapper.mapCreatePerson(payload)
    });
  }

  update(person: Person): Promise<void> {
    return this.httpClient.fetchWithThrow(URL.UpdatePerson, HttpMethod.Post, {
      bodyJSON: PersonApiMapper.mapUpdatePerson(person),
      urlWildCards: {
        subjectId: person.id!
      }
    });
  }

  findById(id: PersonId): Promise<Person> {
    return this.httpClient
      .fetchWithThrow<ApiPerson>(URL.GetPersonBasic, HttpMethod.Get, {
        urlWildCards: {
          subjectId: id
        }
      })
      .then(PersonApiMapper.mapToDomain);
  }

  findFullById(id: PersonId): Promise<Person> {
    return this.httpClient
      .fetchWithThrow<ApiPerson>(URL.GetPersonFull, HttpMethod.Get, {
        urlWildCards: {
          subjectId: id
        }
      })
      .then(PersonApiMapper.mapToDomain);
  }
}
