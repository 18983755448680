import { omitBy } from "lodash";
import { SslProperties, SslProperty } from "../../api/models";

const SENDER_EDITABLE_PROPERTIES: SslProperty[] = [
  "senderId",
  "senderContactDataboxId",
  "senderContactEmailId",
  "senderContactPhoneId",
  "senderAddressId",
  "senderCompanyEmployeeId",
  "senderCompanyEmployeeName",
  "senderCompanyEmployeeJob",
  "senderCompanyEmployeeUnit",
  "senderSSID",
  "senderIdent"
];

const SENDER_PREFIX = "sender";

/**
 * We want to filter out every property which starts with the "sender" string
 * and is not included in the SENDER_EDITABLE_PROPERTIES whitelist
 */
export const filterSenderProps = <T extends SslProperties>(document: T): T =>
  omitBy(
    document,
    (value, key) =>
      isSenderKey(key) &&
      !SENDER_EDITABLE_PROPERTIES.includes(key as SslProperty)
  ) as T;

const isSenderKey = (key: string) => key.startsWith(SENDER_PREFIX);
