import { NameRegisterConfigurationApiRepository } from "infrastructure/api/nameRegistry/NameRegisterConfigurationApiRepository";
import { UpdateNameRegisterConfiguration } from "useCase/nameRegistry/UpdateNameRegisterConfiguration";
import {
  AddressRepositoryToken,
  ConcernedContentRepositoryToken,
  ContactRepositoryToken,
  DataBoxRepositoryToken,
  HistoryRepositoryToken,
  LegalEntityRepositoryToken,
  NameRegisterConfigurationRepositoryToken,
  PersonRepositoryToken,
  SearchSubjectLegalEntityRepositoryToken,
  SearchSubjectPersonRepositoryToken
} from "../../domain/di/token/nameRegistry";
import { AddressApiRepository } from "../../infrastructure/api/nameRegistry/AddressApiRepository";
import { ConcernedContentApiRepository } from "../../infrastructure/api/nameRegistry/ConcernedContentApiRepository";
import { ContactApiRepository } from "../../infrastructure/api/nameRegistry/ContactApiRepository";
import { HistoryApiRepository } from "../../infrastructure/api/nameRegistry/HistoryApiRepository";
import { DataBoxApiRepository } from "../../infrastructure/api/nameRegistry/isds/DataBoxApiRepository";
import { LegalEntityApiRepository } from "../../infrastructure/api/nameRegistry/LegalEntityApiRepository";
import { PersonApiRepository } from "../../infrastructure/api/nameRegistry/PersonApiRepository";
import {
  SearchedSubjectLegalEntityApiRepository,
  SearchedSubjectPersonApiRepository
} from "../../infrastructure/api/nameRegistry/SearchedSubjectApiRepository";
import { CreateSubjectLogic } from "../../presentation/modules/nameRegister/modal/SubjectModal/lib/CreateSubjectLogic";
import { UpdateSubjectLogic } from "../../presentation/modules/nameRegister/modal/SubjectModal/lib/UpdateSubjectLogic";
import { FindAddress } from "../../useCase/nameRegistry/address/FindAddress";
import { SaveAddress } from "../../useCase/nameRegistry/address/SaveAddress";
import { FindConcernedContent } from "../../useCase/nameRegistry/concernedContent/FindConcernedContent";
import { FindContact } from "../../useCase/nameRegistry/contact/FindContact";
import { SaveContact } from "../../useCase/nameRegistry/contact/SaveContact";
import { FindLegalEntity } from "../../useCase/nameRegistry/FindLegalEntity";
import { FindPerson } from "../../useCase/nameRegistry/findPerson/FindPerson";
import { FindSearchedSubjectLegalEntity } from "../../useCase/nameRegistry/findSearchedSubject/FindSearchedSubjectLegalEntity";
import { FindSearchedSubjectPerson } from "../../useCase/nameRegistry/findSearchedSubject/FindSearchedSubjectPerson";
import { FindHistory } from "../../useCase/nameRegistry/history/FindHistory";
import { FindDataBox } from "../../useCase/nameRegistry/isds/FindDataBox";
import { SaveLegalEntity } from "../../useCase/nameRegistry/SaveLegalEntity";
import { SavePerson } from "../../useCase/nameRegistry/SavePerson";

export const nameRegisterProviders = [
  // repository
  {
    provide: PersonRepositoryToken,
    useClass: PersonApiRepository
  },
  {
    provide: LegalEntityRepositoryToken,
    useClass: LegalEntityApiRepository
  },
  {
    provide: ContactRepositoryToken,
    useClass: ContactApiRepository
  },
  {
    provide: AddressRepositoryToken,
    useClass: AddressApiRepository
  },
  {
    provide: DataBoxRepositoryToken,
    useClass: DataBoxApiRepository
  },
  {
    provide: SearchSubjectPersonRepositoryToken,
    useClass: SearchedSubjectPersonApiRepository
  },
  {
    provide: SearchSubjectLegalEntityRepositoryToken,
    useClass: SearchedSubjectLegalEntityApiRepository
  },
  {
    provide: HistoryRepositoryToken,
    useClass: HistoryApiRepository
  },
  {
    provide: ConcernedContentRepositoryToken,
    useClass: ConcernedContentApiRepository
  },
  {
    provide: NameRegisterConfigurationRepositoryToken,
    useClass: NameRegisterConfigurationApiRepository
  },

  // use case
  FindPerson,
  FindLegalEntity,
  SavePerson,
  SaveLegalEntity,
  SaveContact,
  FindContact,
  SaveAddress,
  FindAddress,
  FindSearchedSubjectPerson,
  FindSearchedSubjectLegalEntity,
  FindDataBox,
  FindHistory,
  FindConcernedContent,
  UpdateNameRegisterConfiguration,
  // presentation
  CreateSubjectLogic,
  UpdateSubjectLogic
];
