import { lastPathMember, translationPath } from "../../../../../utils/getPath";
import { CreateShipmentFormValuesProxy } from "../../_types";
import {
  attachmentsCount,
  listCount,
  listCountAttachments
} from "../../../../../../validation";
import * as yup from "yup";
import { lang, t } from "../../../../../../translation/i18n";

const MAX_STRING_LENGTH = 50;

export const amountToSendValidations = {
  [lastPathMember(CreateShipmentFormValuesProxy.listCount)
    .path]: listCount().required(
    t(translationPath(lang._validations.required))
  ),
  [lastPathMember(CreateShipmentFormValuesProxy.attachmentsCount)
    .path]: attachmentsCount().required(
    t(translationPath(lang._validations.required))
  ),
  [lastPathMember(CreateShipmentFormValuesProxy.listCountAttachments)
    .path]: listCountAttachments().required(
    t(translationPath(lang._validations.required))
  ),
  [lastPathMember(CreateShipmentFormValuesProxy.attachmentsType)
    .path]: yup
    .string()
    .max(
      MAX_STRING_LENGTH,
      t(translationPath(lang.dialog.errors.maxLen), { len: MAX_STRING_LENGTH })
    )
};
