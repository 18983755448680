import { Field, useFormikContext } from "formik";
import type { FieldAttributes } from "formik";
import React from "react";

// props type is copied from Formik's types, so the any is inevitable
// tslint:disable-next-line
export const BaseField = (props: FieldAttributes<any>) => {
  const { isSubmitting } = useFormikContext<any>();

  return <Field {...props} disabled={props.disabled || isSubmitting} />;
};
