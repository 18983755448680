import MenuLayout from "presentation/core/components/layout/MenuLayout";
import React from "react";
import { UserTable } from "../../tables/UserTable/UserTable";

const Users = () => (
  <MenuLayout>
    <UserTable />
  </MenuLayout>
);

export default Users;
