export const isString = (maybeString: unknown): maybeString is string =>
  typeof maybeString === "string";

export const isObject = (maybeObject: unknown): maybeObject is object => {
  return (
    maybeObject !== null &&
    maybeObject !== undefined &&
    typeof maybeObject === "object"
  );
};
