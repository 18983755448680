import { FilePlan } from "../../../../core/features/login/_types";
import moment from "moment";

export const isExpiredFilePlan = (plan: FilePlan): boolean => {
  if (plan.validTo && plan.validFrom) {
    return !(
      moment(plan.validFrom).add(1, "seconds").isBefore(moment(new Date())) &&
      moment(plan.validTo)
        .add(23, "hours")
        .add(59, "minutes")
        .add(59, "seconds")
        .isAfter(moment(new Date()))
    );
  }
  return false;
};
