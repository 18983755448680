import { callAsyncAction } from "presentation/core/action";
import { changePasswordAction } from "presentation/core/api/user/_actions";
import { secondaryAction } from "presentation/core/components/dialog/lib/actionsFactory";
import {
  DialogContentPropsType,
  DialogType
} from "presentation/core/components/dialog/_types";
import NamedTitle from "presentation/core/components/namedTitle";
import React from "react";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import { ChangePasswordDialog } from "./ChangePasswordDialog";
import { ChangePasswordFormValues } from "./_types";
import { ModalSize } from "presentation/designSystem/Modal/Modal";
import { getErrorCodeTranslation } from "../../../utils/errorCodeTranslation";

export const changePasswordDialogContent = {
  actions: () => [
    secondaryAction(
      t(translationPath(lang.dialog.buttons.confirm)),
      ({ dispatch, channels, onClose, buttonState }) => {
        const onSuccess = () => {
          onClose();
        };

        const getNotificationForError = (error: any) => ({
          message: getErrorCodeTranslation(error.code)
        });

        const onError = () => {
          buttonState.setIsPending(false);
        };

        const { oldPassword, newPassword } = channels.contentTab.state
          ?.formValues as ChangePasswordFormValues;

        buttonState.setIsPending(true);

        dispatch(
          callAsyncAction({
            action: changePasswordAction,
            onError,
            onSuccess,
            onErrorNotification: getNotificationForError,
            payload: {
              body: {
                newPassword,
                oldPassword
              }
            }
          })
        );
      }
    )
  ],
  content: ChangePasswordDialog,
  title: (props: DialogContentPropsType) => (
    <NamedTitle
      text={t(translationPath(lang.dialog.title.changePassword))}
      {...props}
    />
  ),
  type: DialogType.ChangePassword,
  size: ModalSize.Small
};
