import { Subject } from "../nameRegistry/Subject";

export enum DocumentSubjectRelationType {
  ConcernedSubject = "CONCERNED",
  Sender = "SENDER",
  Recipient = "RECIPIENT"
}

export interface DocumentConcernedSubject {
  readonly id?: number;
  readonly relationType?: DocumentSubjectRelationType;
  readonly documentId?: string;
  readonly subject?: Subject;
}
