import React from "react";
import { FilePlan } from "presentation/core/features/login/_types";
import { Select } from "presentation/designSystem/Select/Select";

interface FilePlanFieldInterface {
  validFilePlans: FilePlan[];
  isReadonly?: boolean;
  handlePlanChange?: (value: string) => void;
  defaultValue?: string;
}

export const FilePlanField = ({
  validFilePlans,
  isReadonly,
  handlePlanChange,
  defaultValue
}: FilePlanFieldInterface) => {
  return (
    <Select
      defaultValue={defaultValue}
      disabled={isReadonly}
      onChange={handlePlanChange}
    >
      {validFilePlans.map((plan) => {
        return (
          <Select.Option key={plan.nodeId} value={plan.nodeId}>
            {plan.name}
          </Select.Option>
        );
      })}
    </Select>
  );
};
