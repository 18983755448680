import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import clsx from "clsx";
import { Form, Formik, FormikHelpers } from "formik";
import { Select, TextField } from "formik-mui";
import { SslEmail } from "presentation/core/api/models";
import Datepicker from "presentation/core/components/datepicker/DatePicker";
import {
  StyledField,
  StyledFieldWide,
  StyledFormControl,
  useStyles
} from "presentation/core/components/dialog/Dialog.styles";
import { SenderForm } from "presentation/core/components/senderForm/SenderForm";
import Timepicker from "presentation/core/components/timepicker/TimePicker";
import { sslEmailPropsProxy } from "presentation/core/types";
import {
  DateTimeFormats,
  DeliveryMode,
  DocumentType
} from "presentation/enums";
import {
  lastPathMember,
  translationPath
} from "presentation/share/utils/getPath";
import { WithTranslation, lang, t } from "presentation/translation/i18n";
import React from "react";
import { withTranslation } from "react-i18next";
import { BaseField } from "../../../../core/components/form/fields/BaseField";
import { SslDeliveryMode } from "../../form/fields/SSLDeliveryMode";
import { MetaFormProps } from "../_types";
import { validate } from "./_validations";

const Component = ({
  initialValues,
  formRef,
  readonly
}: MetaFormProps<SslEmail> & WithTranslation) => {
  const classes = useStyles();
  const onSubmit = (
    values: SslEmail,
    formikHelpers: FormikHelpers<SslEmail>
  ) => {};

  return (
    <Formik<SslEmail>
      initialValues={initialValues}
      innerRef={formRef}
      onSubmit={onSubmit}
      validate={validate}
    >
      {({ setFieldValue }: any) => {
        return (
          <Form className={classes.form}>
            <div className={clsx(classes.fullWidth)}>
              <Datepicker
                className={classes.gapRight}
                disabled={true}
                disableFuture={true}
                required={true}
                data-test-id="meta-input-deliveryDate"
                name={lastPathMember(sslEmailPropsProxy.deliveryDate).path}
                label={t(translationPath(lang.documentMetaForm.deliveryDate))}
              />
              <Timepicker
                className={classes.gapRight}
                disabled={true}
                required={true}
                format={DateTimeFormats.HoursMinutesSeconds}
                data-test-id="meta-input-deliveryTime"
                name="deliveryTime"
                label={t(translationPath(lang.general.deliveryTime))}
              />

              <SslDeliveryMode
                className={classes.gapRight}
                allowedModes={[DeliveryMode.Email]}
                disabled={true}
                required={true}
              />

              <StyledField
                component={TextField}
                disabled={true}
                required={true}
                data-test-id="meta-input-pid"
                name={lastPathMember(sslEmailPropsProxy.pid).path}
                type="text"
                label={t(translationPath(lang.documentMetaForm.pid))}
              />
            </div>

            <div className={clsx(classes.fullWidth)}>
              <StyledFormControl className={classes.gapRight}>
                <InputLabel
                  htmlFor={lastPathMember(sslEmailPropsProxy.form).path}
                >
                  {t(translationPath(lang.documentMetaForm.form))}
                </InputLabel>
                <BaseField
                  disabled={true}
                  required={true}
                  component={Select}
                  data-test-id="meta-input-form"
                  name={lastPathMember(sslEmailPropsProxy.form).path}
                  inputProps={{
                    id: lastPathMember(sslEmailPropsProxy.form).path
                  }}
                >
                  <MenuItem value={DocumentType.Digital}>
                    {t(translationPath(lang.general.digital))}
                  </MenuItem>
                </BaseField>
              </StyledFormControl>
              <StyledField
                className={classes.gapRight}
                component={TextField}
                data-test-id="meta-input-attachmentsCount"
                name={lastPathMember(sslEmailPropsProxy.attachmentsCount).path}
                min={0}
                type="number"
                label={t(translationPath(lang.general.attachmentsCount))}
              />
              <StyledField
                className={classes.gapRight}
                component={TextField}
                data-test-id="meta-input-attachmentsType"
                name={lastPathMember(sslEmailPropsProxy.attachmentsType).path}
                type="text"
                label={t(translationPath(lang.general.attachmentsType))}
              />
              <Datepicker
                data-test-id="meta-input-settleToDate"
                name={lastPathMember(sslEmailPropsProxy.settleToDate).path}
                label={t(translationPath(lang.general.settleToDate))}
                disablePast={true}
              />
            </div>

            <div className={clsx(classes.fullWidth, classes.mtGap)}>
              <StyledField
                component={TextField}
                className={clsx(classes.gapRight, classes.longLabel)}
                data-test-id="carries-meta-input-senderIdent"
                disabled={readonly}
                name={lastPathMember(sslEmailPropsProxy.senderIdent).path}
                type="text"
                label={t(translationPath(lang.general.senderIdent))}
              />
              <StyledField
                component={TextField}
                className={classes.longLabel}
                data-test-id="carries-meta-input-senderSSID"
                disabled={readonly}
                name={lastPathMember(sslEmailPropsProxy.senderSSID).path}
                type="text"
                label={t(translationPath(lang.general.senderSSID))}
              />
            </div>
            <StyledFieldWide
              component={TextField}
              data-test-id="meta-input-subject"
              name={lastPathMember(sslEmailPropsProxy.subject).path}
              type="text"
              label={t(translationPath(lang.general.subject))}
            />
            <SenderForm
              initialValues={initialValues}
              readonly={readonly}
              hidden={{ own: !readonly }}
              mode={DeliveryMode.Email}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export const RegisterEmailForm = withTranslation()(React.memo(Component));
