import { conceptCreateActionType } from "presentation/core/api/concept/_actions";
import { dialogOpenAction } from "presentation/core/components/dialog/_actions";
import { DialogType } from "presentation/core/components/dialog/_types";
import { metaFormAction__Update } from "presentation/core/components/MetaForm/_actions";
import { Notification } from "presentation/designSystem/notification/Notification";
import { SpisumNodeTypes } from "presentation/enums";
import { put, takeLatest } from "redux-saga/effects";
import { lang, t } from "presentation/translation/i18n";
import { getType } from "typesafe-actions";
import { translationPath } from "../../../utils/getPath";
import { handleResponse } from "../../../utils/typesafeActions";
import { evidenceCreateConceptDialogOpen } from "./_actions";

export function* watchEvidenceCreateConceptDialogOpenAction() {
  yield takeLatest(getType(evidenceCreateConceptDialogOpen), function* () {
    yield put(
      dialogOpenAction({
        dialogProps: {
          data: {
            nodeType: SpisumNodeTypes.Concept
          }
        },
        dialogType: DialogType.CreateConcept
      })
    );

    yield put(conceptCreateActionType.request());

    const [successResponse, errorResponse] = yield handleResponse(
      conceptCreateActionType
    );

    if (errorResponse) {
      Notification.error({
        message: t(translationPath(lang.dialog.notifications.actionFailed))
      });
      return;
    }

    yield put(
      metaFormAction__Update({
        documentId: successResponse.id,
        nodeType: SpisumNodeTypes.Concept,
        formValues: {
          pid: successResponse?.pid,
          subject: ""
        }
      })
    );
  });
}
