import { FindDocumentHistory } from "useCase/document/FindDocumentHistory";

import { DocumentHistoryRepositoryToken } from "domain/di/token/document";
import { DocumentHistoryApiRepository } from "infrastructure/api/document/DocumentHistoryApiRepository";
import { DocumentOwnership } from "useCase/document/DocumentOwnership";
import {
  ConceptConcernedSubjectRepositoryToken,
  DocumentConcernedSubjectRepositoryToken
} from "../../domain/di/token/document";
import { DocumentConcernedSubjectApiRepository } from "../../infrastructure/api/document/DocumentConcernedSubjectApiRepository";
import { FindConcernedSubject } from "../../useCase/document/concernedSubject/FindConcernedSubject";
import { AddConcernedSubject } from "../../useCase/document/concernedSubject/AddConcernedSubject";
import { RemoveConcernedSubject } from "../../useCase/document/concernedSubject/RemoveConcernedSubject";
import { ConceptConcernedSubjectApiRepository } from "../../infrastructure/api/document/ConceptConcernedSubjectApiRepository";
import { FindConceptConcernedSubject } from "../../useCase/document/concernedSubject/FindConceptConcernedSubject";
import { AddConceptConcernedSubject } from "../../useCase/document/concernedSubject/AddConceptConcernedSubject";
import { RemoveConceptConcernedSubject } from "../../useCase/document/concernedSubject/RemoveConceptConcernedSubject";

export const documentProviders = [
  // repository
  {
    provide: DocumentHistoryRepositoryToken,
    useClass: DocumentHistoryApiRepository
  },
  {
    provide: DocumentConcernedSubjectRepositoryToken,
    useClass: DocumentConcernedSubjectApiRepository
  },
  {
    provide: ConceptConcernedSubjectRepositoryToken,
    useClass: ConceptConcernedSubjectApiRepository
  },
  // use case
  FindDocumentHistory,
  DocumentOwnership,
  FindConcernedSubject,
  AddConcernedSubject,
  RemoveConcernedSubject,
  FindConceptConcernedSubject,
  AddConceptConcernedSubject,
  RemoveConceptConcernedSubject
];
