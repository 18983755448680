import { historyProviders } from "./di/history";
import { shipmentProviders } from "./di/shipment";
import { administrationProviders } from "./di/administration";
import { nameRegisterModuleProviders } from "./di/presentation/nameRegister";
import { HttpClient } from "../infrastructure/service/HttpClient";
import { DependencyInjection } from "../presentation/core/features/dependencyInjection/services/DependencyInjection";
import { codelistProviders } from "./di/codelist";
import { documentProviders } from "./di/document";
import { fileProviders } from "./di/file";
import { nameRegisterProviders } from "./di/nameRegistry";
import { configurationProviders } from "./di/configuration";
import { userProviders } from "./di/user";
import { repositoryProviders } from "./di/repository";
import { evidenceProviders } from "./di/evidence";
import { recordRetentionProviders } from "./di/recordRetention";
import { authenticationProviders } from "./di/authentication";
import { searchProviders } from "./di/search";
import { conceptProvider } from "./di/concept";

export const register = (dic: DependencyInjection) => {
  dic.registerProviders([
    // shared services
    HttpClient,
    // domain scoped providers
    ...historyProviders,
    ...shipmentProviders,
    ...administrationProviders,
    ...userProviders,
    ...searchProviders,
    ...fileProviders,
    ...conceptProvider,
    ...documentProviders,
    ...codelistProviders,
    ...nameRegisterProviders,
    ...recordRetentionProviders,
    ...configurationProviders,
    ...repositoryProviders,
    ...evidenceProviders,
    ...authenticationProviders,
    // presentation modules providers
    ...nameRegisterModuleProviders
  ]);
};
