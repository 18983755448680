import { DialogDataProps } from "presentation/core/components/dialog/_types";
import { createSafeAction } from "presentation/share/utils/typesafeActions";
import { ActionType } from "typesafe-actions";

export const dialogOpenEmailDetails = createSafeAction(
  "@dialog/OPEN_EMAIL_DETAILS"
)<DialogDataProps>();

export const dialogOpenEmailNotRegisterDetails = createSafeAction(
  "@dialog/OPEN_EMAIL_NOT_REGISTER_DETAILS"
)<DialogDataProps>();

export type DialogOpenEmailDetailsActionType = ActionType<
  typeof dialogOpenEmailDetails
>;

export type DialogOpenEmailNotRegisterDetailsActionType = ActionType<
  typeof dialogOpenEmailNotRegisterDetails
>;
