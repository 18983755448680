import { ApiListResponseMapper } from "infrastructure/api/mapper/ApiListResponseMapper";
import {
  ApiListResponse,
  ApiTypes
} from "infrastructure/api/struct/ApiTypesV2";
import { stringOrDefault } from "lib/string";
import { DocumentForSignature, ForSignature } from "../types/ForSignature";

export type ApiSignatureBookForSignatureResponse = ApiTypes["ISFG.SpisUm.ClientSide.Models.V2.Overview.OverviewForSignatureResponse"];
export type ApiDocumentForSignatureResponse = ApiTypes["ISFG.SpisUm.ClientSide.Models.V2.SignatureBook.SignatureBookGetComponentsResponse"];

export const mapListToDomain = (
  response: ApiListResponse<ApiSignatureBookForSignatureResponse>
) => {
  return ApiListResponseMapper.mapListToPagination(response, mapToDomain);
};

export const mapToDomain = (
  response: ApiSignatureBookForSignatureResponse
): ForSignature => {
  const {
    nodeId,
    pid,
    ssid,
    subject,
    deliveryDate,
    forSignatureDate,
    attachmentsCount,
    owner
  } = response;
  return {
    nodeId: stringOrDefault(nodeId),
    pid: stringOrDefault(pid),
    ssid: stringOrDefault(ssid),
    subject: stringOrDefault(subject),
    deliveryDate: stringOrDefault(deliveryDate),
    forSignatureDate: stringOrDefault(forSignatureDate),
    attachmentsCount,
    owner: stringOrDefault(owner)
  };
};

export const mapDocumentForSignatureListToDomain = (
  list: ApiListResponse<ApiDocumentForSignatureResponse>
) => {
  return ApiListResponseMapper.mapListToPagination(list, mapDocumentToDomain);
};

export const mapDocumentToDomain = (
  document: ApiDocumentForSignatureResponse
): DocumentForSignature => {
  return {
    componentId: stringOrDefault(document.componentId),
    canBeSigned: document.canBeSigned,
    fileName: stringOrDefault(document.fileName),
    isSign: document.isSign,
    isSealed: document.isSealed,
    usedTimeSign: stringOrDefault(document.usedTimeSign),
    usedTimeSeal: stringOrDefault(document.usedTimeSeal)
  };
};

export const transformParams = (files: string[]): string => {
  return files?.reduce((acc, currVal) => {
    return `${acc}&componentIds=${currVal}`;
  }, "");
};
