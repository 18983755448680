import { ShipmentHistoryRepositoryToken } from "domain/di/token/shipment";
import type { ShipmentHistoryRepository } from "domain/service/shipment/ShipmentHistoryRepository";
import { ShipmentId } from "domain/struct/shipment/Shipment";
import { PaginatedResult, PaginationConfig } from "lib/contract/Pagination";
import { SortingConfig } from "lib/contract/Sorting";
import {
  DInject,
  DInjectable
} from "presentation/core/features/dependencyInjection";
import { History } from "../../domain/struct/history/History";

@DInjectable()
export class FindShipmentHistory {
  constructor(
    @DInject(ShipmentHistoryRepositoryToken)
    protected readonly repository: ShipmentHistoryRepository
  ) {}

  findPaginated(
    id: ShipmentId,
    paginationConfig: PaginationConfig,
    sortingConfig?: SortingConfig
  ): Promise<PaginatedResult<History>> {
    return this.repository.findPaginated(id, paginationConfig, sortingConfig);
  }
}
