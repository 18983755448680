import { useModal } from "@isfg/react-components/build/Modal/useModal";
import { useMutation } from "presentation/share/hook/query/useQM";
import { useHistory } from "react-router-dom";
import { createAnalogDocument } from "../api";
import { AnalogDetailAntModal } from "../modal/AnalogDetailsAntModal";

export const useCreateAnalogDocument = () => {
  const { goBack } = useHistory();
  const [modal, modalApi] = useModal(AnalogDetailAntModal, {
    onClose: goBack
  });
  const { isLoading, mutate: create } = useMutation(
    () => createAnalogDocument(),
    {
      onSuccess: (data) => {
        modalApi.open({
          document: data
        });
      }
    }
  );

  return {
    isLoading,
    create,
    modal
  };
};
