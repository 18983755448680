import { useCallback } from "react";
import { updateActiveComponents } from "../api";
import { ActiveComponent } from "../_types";

export const useUpdateActiveComponents = () => {
  return useCallback(
    (nodeId: string, body: ActiveComponent) =>
      updateActiveComponents(nodeId, body),
    [updateActiveComponents]
  );
};
