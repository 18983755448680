import React from "react";
import {
  Form,
  Item,
  FormInstance
} from "presentation/designSystem/Form/v2/Form";
import { Col, Row } from "presentation/designSystem/Grid/Grid";
import { Input } from "presentation/designSystem/Input/Input";
import { Select } from "presentation/designSystem/Select/Select";
import { DatePicker } from "presentation/designSystem/DatePicker/DatePicker";
import { lang, t } from "presentation/translation/i18n";
import { translationPath } from "presentation/share/utils/getPath";
import { DateTimeFormat } from "lib/dateTime";
import { DeliveryMode, DocumentType } from "presentation/enums";
import moment from "moment";
import { CreateDataboxRegistration } from "../types";
import { TimePicker } from "antd";
import { useSenderForm } from "presentation/share/components/form/hooks/useSenderForm";
import { SenderFormNewProps } from "presentation/share/components/form/SenderFormAnt";

interface RegisterDataboxDigitalFormProps {
  formData: CreateDataboxRegistration;
  form: FormInstance;
  onSenderChange: SenderFormNewProps["onSenderChange"];
}

export const RegisterDataboxDigitalForm = ({
  formData,
  form,
  onSenderChange
}: RegisterDataboxDigitalFormProps) => {
  const deliveryDate = formData.deliveryDate && new Date(formData.deliveryDate);
  const { senderFormData, senderForm } = useSenderForm({
    senderData: formData.sender,
    form,
    initialDatabox: formData.digitalDeliverySender,
    deliveryMode: DeliveryMode.Databox,
    onSenderChange
  });
  const formInitialValues = {
    ...formData,
    deliveryDate: moment(deliveryDate),
    deliveryTime: moment(deliveryDate, DateTimeFormat.HoursMinutesSeconds),
    deliveryMode: DeliveryMode.Databox,
    ...senderFormData
  };

  return (
    <>
      <Form initialValues={formInitialValues} form={form}>
        <Row gutter={6}>
          <Col span={6}>
            <Item
              name="deliveryDate"
              label={t(translationPath(lang.documentMetaForm.deliveryDate))}
            >
              <DatePicker
                disabled
                format={DateTimeFormat.Date}
                style={{ width: "100%" }}
              />
            </Item>
          </Col>
          <Col span={6}>
            <Item
              name="deliveryTime"
              label={t(translationPath(lang.general.deliveryTime))}
            >
              <TimePicker disabled style={{ width: "100%" }} />
            </Item>
          </Col>
          <Col span={6}>
            <Item
              name="deliveryMode"
              label={t(translationPath(lang.general.deliveryMode))}
            >
              <Select disabled>
                <Select.Option value={DeliveryMode.Databox}>
                  {t(translationPath(lang.enums.deliveryMode.databox))}
                </Select.Option>
              </Select>
            </Item>
          </Col>
          <Col span={6}>
            <Item
              name="pid"
              label={t(translationPath(lang.documentMetaForm.pid))}
            >
              <Input disabled />
            </Item>
          </Col>
        </Row>
        <Row gutter={6}>
          <Col span={6}>
            <Item
              name="form"
              label={t(translationPath(lang.documentMetaForm.form))}
            >
              <Select disabled>
                <Select.Option value={DocumentType.Digital}>
                  {t(translationPath(lang.general.digital))}
                </Select.Option>
              </Select>
            </Item>
          </Col>
          <Col span={6}>
            <Item
              name="attachmentCount"
              label={t(translationPath(lang.general.attachmentsCount))}
            >
              <Input type="number" />
            </Item>
          </Col>
          <Col span={6}>
            <Item
              name="attachmentsType"
              label={t(translationPath(lang.general.attachmentsType))}
            >
              <Input />
            </Item>
          </Col>
          <Col span={6}>
            <Item
              name="settleToDate"
              label={t(translationPath(lang.general.settleToDate))}
            >
              <DatePicker
                format={DateTimeFormat.Date}
                style={{ width: "100%" }}
                disabledDate={(current) => {
                  return current < moment().add(-1);
                }}
              />
            </Item>
          </Col>
        </Row>
        <Row gutter={6}>
          <Col span={6}>
            <Item
              name="senderIdent"
              label={t(translationPath(lang.general.senderIdent))}
            >
              <Input />
            </Item>
          </Col>
          <Col span={6}>
            <Item
              name="senderSsid"
              label={t(translationPath(lang.general.senderSSID))}
            >
              <Input />
            </Item>
          </Col>
        </Row>
        <Row gutter={6}>
          <Col span={24}>
            <Item
              name="subject"
              label={t(translationPath(lang.general.subject))}
            >
              <Input />
            </Item>
          </Col>
        </Row>
        {senderForm}
      </Form>
    </>
  );
};
