import { useForm } from "@isfg/react-components/build/Form/Form";
import { BaseModalProps } from "@isfg/react-components/build/Modal/useModal";
import { Tabs } from "@isfg/react-components/build/Tabs/Tabs";
import { Component } from "presentation/core/types";
import { ModalWithPreview } from "presentation/designSystem/Modal/ModalWithPreview";
import { useModal } from "presentation/designSystem/Modal/useModal";
import {
  RemoteTableApiContextProvider,
  useRemoteTableApiContext
} from "presentation/designSystem/Table/contexts/RemoteTableApiContextProvider";
import { DocumentType, SpisumNodeTypes } from "presentation/enums";
import { useEncryptedComponentsModal } from "presentation/modules/mailroom/features/income/digitalDocument/hooks/useEncryptedComponentsModal";
import { EncryptedComponentsPasswordModal } from "presentation/modules/mailroom/features/income/digitalDocument/modals/EncryptedComponentsPasswordModal";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import React, { useCallback, useState } from "react";
import { ComponentType } from "../../../../../core/api/components/_types";
import CommentsTabContainer from "../../../../../core/components/dialog/tabs/comments";
import { useEvidenceComponentsV2TabContainer } from "../../../../../modules/evidence/components/components/hooks/useEvidenceComponentsV2TabContainer";
import { ConceptConcernedSubjectTable } from "../../../../tables/document/ConcernedSubjectTable/ConceptConcernedSubjectTable";
import { UpdateConceptForm } from "../forms/UpdateConceptForm";
import { UpdateConceptFormFieldTypes } from "../forms/UpdateConceptFormFieldTypes";
import { useUpdateConcept } from "../hooks/useUpdateConcept";
import { Concept } from "../types";

interface UpdateConceptDigitalModalProps {
  onCancel?: BaseModalProps["onCancel"];
  onOk?: BaseModalProps["onOk"];
  document: Concept;
  documentType: DocumentType;
  nodeType: SpisumNodeTypes;
  nodeId?: string;
}

enum UpdateConceptModalTabs {
  Metadata = "0",
  Components = "1",
  ConcernedSubjects = "2",
  Comments = "3"
}

export const UpdateConceptModal = (props: UpdateConceptDigitalModalProps) => {
  return (
    <RemoteTableApiContextProvider>
      <UpdateConceptModalInner {...props} />
    </RemoteTableApiContextProvider>
  );
};

export const UpdateConceptModalInner = ({
  onCancel,
  onOk,
  document,
  nodeId,
  documentType,
  nodeType
}: UpdateConceptDigitalModalProps) => {
  const [activeTab, setActiveTab] = useState("0");
  const changeActiveTab = (activeTabIndex: string) => {
    setActiveTab(activeTabIndex);
  };
  const [form] = useForm<UpdateConceptFormFieldTypes>();
  const { register } = useUpdateConcept();
  const { refreshData: refreshConcernedSubjects } = useRemoteTableApiContext();

  const handleCancel = useCallback(() => {
    onCancel?.();
  }, [onCancel]);

  const updateConceptData = () => {
    const { subject } = form.getFieldsValue();
    register({ nodeId: nodeId!, subject: subject! });
    handleCancel();
  };

  const [showPreview, setShowPreview] = useState(false);
  const [previewItem, setPreviewItem] = useState({
    id: "",
    name: "",
    nodeType: SpisumNodeTypes.Concept,
    entityId: "",
    fileIsEncrypted: false
  });

  const onUploadFinished = (components: Component[]) => {
    const encryptedFiles = components.filter(
      (component: Component) => component.properties?.ssl?.fileIsEncrypted
    );

    if (encryptedFiles.length === 1) {
      openEncryptedComponentsModal({
        documentType: DocumentType.Concept,
        encryptedComponents: [encryptedFiles[0].id]
      });
    }
  };

  const [componentsV2TabContainer, { fetchComponents }] =
    useEvidenceComponentsV2TabContainer({
      componentType: ComponentType.Concept,
      isActive: activeTab === UpdateConceptModalTabs.Components,
      isReadonly: false,
      dialogProps: {
        canUploadComponents: true,
        isReadonly: false,
        data: { nodeType: SpisumNodeTypes.Concept }
      },
      nodeId: nodeId!,
      showDelete: true,
      showPreview,
      previewItem,
      setPreviewItem,
      setShowPreview,
      onUploadFinished
    });

  const [
    encryptedComponentsPasswordModal,
    encryptedComponentsPasswordModalApi
  ] = useModal(EncryptedComponentsPasswordModal, { onOk: fetchComponents });

  const { encryptedComponentsModal, openEncryptedComponentsModal } =
    useEncryptedComponentsModal({
      onOk: (encryptedComponents) => {
        if (encryptedComponents && encryptedComponents.length === 1) {
          encryptedComponentsPasswordModalApi.open({
            componentType: ComponentType.Concept,
            nodeId: nodeId!,
            componentId: encryptedComponents[0]
          });
        }
      }
    });

  const tabsWithRefresh = {
    [UpdateConceptModalTabs.Components]: fetchComponents,
    [UpdateConceptModalTabs.ConcernedSubjects]: refreshConcernedSubjects
  };

  return (
    <>
      {encryptedComponentsModal}
      {encryptedComponentsPasswordModal}
      <ModalWithPreview
        open={true}
        hasTabs
        onRefresh={tabsWithRefresh[activeTab as keyof typeof tabsWithRefresh]}
        title={t(translationPath(lang.dialog.title.conceptDetails))}
        onCancel={onCancel}
        onOk={updateConceptData}
        confirmLoading={false}
        handleShowPreviewChange={() => setShowPreview(false)}
        showPreview={showPreview}
        previewItem={previewItem}
        setPreviewItem={setPreviewItem}
      >
        <Tabs onChange={changeActiveTab}>
          <Tabs.TabPane
            tab={t(translationPath(lang.dialog.subjectDialog.tabs.metadata))}
            key={UpdateConceptModalTabs.Metadata}
          >
            <UpdateConceptForm form={form} formData={document} />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={t(translationPath(lang.dialog.tabs.components))}
            key={UpdateConceptModalTabs.Components}
          >
            {componentsV2TabContainer}
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={t(translationPath(lang.dialog.tabs.concernedSubjects))}
            key={UpdateConceptModalTabs.ConcernedSubjects}
          >
            <div className="body-fullsize">
              <ConceptConcernedSubjectTable
                documentId={nodeId!}
                readonly={false}
              />
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={t(translationPath(lang.dialog.tabs.notes))}
            key={UpdateConceptModalTabs.Comments}
          >
            <CommentsTabContainer
              nodeId={nodeId!}
              isActive={activeTab === UpdateConceptModalTabs.Comments}
              dialogProps={{ data: { nodeType } }}
            />
          </Tabs.TabPane>
        </Tabs>
      </ModalWithPreview>
    </>
  );
};
