import { Modal, ModalSize } from "presentation/designSystem/Modal/Modal";
import { BaseModalProps } from "presentation/designSystem/Modal/useModal";
import { Notification } from "presentation/designSystem/notification/Notification";
import styled from "styled-components";
import { useMutation } from "../../../share/hook/query/useMutation";
import { getErrorCodeTranslation } from "../../../share/utils/errorCodeTranslation";
import { translationPath } from "../../../share/utils/getPath";
import { lang, t } from "../../../translation/i18n";
import { ErrorType } from "../../../types";
import { usePerformDeletion } from "../features/recordRetentionProcess/retentionProtocols/hooks/usePerformDeletion";
import React from "react";

interface PerformShreddingProcedureModalProps extends BaseModalProps {
  selectedNodeId: string;
}

export const PerformDeletionModal = ({
  selectedNodeId,
  onCancel,
  onOk
}: PerformShreddingProcedureModalProps) => {
  const performDeletion = usePerformDeletion();

  const { mutate: performDeletionMutation } = useMutation(
    async () => {
      await performDeletion.savePerformDeletion(selectedNodeId);
    },
    {
      onError(error: ErrorType) {
        if (error.message === "Failed to fetch") {
          Notification.error({
            message: t(translationPath(lang.dialog.notifications.actionFailed))
          });
          return;
        }
        if (error?.messages) {
          error.messages.map((error: string) =>
            Notification.error({
              message: getErrorCodeTranslation(error)
            })
          );
        } else {
          if (error.message) {
            return Notification.error({
              message: error.message
            });
          }
          Notification.error({
            message: t(
              translationPath(
                lang.dialog.notifications.notPossibleToSaveSubject
              )
            )
          });
        }
      },
      onSuccess() {
        Notification.success({
          message: t(translationPath(lang.dialog.notifications.actionSucceeded))
        });
        onOk?.();
      },
      onErrorNotification: null,
      onSuccessNotification: null
    }
  );

  const savePerformDeletionMutation = () => {
    performDeletionMutation(undefined);
  };

  return (
    <Modal
      open={true}
      title={t(translationPath(lang.general.performDeletion))}
      onOk={savePerformDeletionMutation}
      size={ModalSize.Small}
      onCancel={onCancel}
      okText={t(translationPath(lang.modal.ok))}
      cancelText={t(translationPath(lang.modal.cancel))}
    >
      <StyledP>
        {t(
          translationPath(
            lang.dialog.performDeletionModal.doYouReallyWantToPerformDeletion
          )
        )}
      </StyledP>
    </Modal>
  );
};

const StyledP = styled.p`
  margin-top: 1rem;
`;
