import { GroupMember } from "presentation/core/api/models";
import { useStyles } from "presentation/core/components/dialog/Dialog.styles";
import { DialogContentType } from "presentation/core/components/dialog/_types";
import React from "react";
import { UpdateOrganizationUnitForm } from "./UpdateOrganizationUnitForm";
import { UpdateOrganizationUnitFormValues } from "./_types";
import { validate } from "./_validations";
import { ChannelForm } from "../ChannelForm/ChannelForm";

export const UpdateOrganizationUnitDialog: DialogContentType["content"] = ({
  channel,
  dialogProps
}) => {
  const classes = useStyles();

  const initialValues: UpdateOrganizationUnitFormValues = {
    name: (dialogProps.data as GroupMember)?.displayName
  };

  return (
    <div className={classes.modalSmall}>
      <div className="body-midsize">
        <ChannelForm<UpdateOrganizationUnitFormValues>
          initialValues={initialValues}
          validate={validate}
          channel={channel}
          component={UpdateOrganizationUnitForm}
        />
      </div>
    </div>
  );
};
