import { Button } from "presentation/designSystem/Button/Button";
import { ModalWithTabs } from "presentation/designSystem/Modal/Modal";
import { BaseModalProps } from "presentation/designSystem/Modal/useModal";
import { Tabs } from "presentation/designSystem/Tabs/Tabs";
import { translationPath } from "../../../share/utils/getPath";
import { lang, t } from "../../../translation/i18n";
import { RetentionArchivedTable } from "../features/recordRetentionProcess/retentionProtocolsErased/tables/RetentionArchivedTable";
import { RetentionDestroyedTable } from "../features/recordRetentionProcess/retentionProtocolsErased/tables/RetentionDestroyedTable";
import { RetentionProposalOrProtocolHistoryTable } from "../features/table/RetentionProposalOrProtocolHistoryTable";
import { RetentionProtocolErasedMetadataForm } from "./form/RetentionProtocoErasedMetadataForm";
import React from "react";

interface ShowDetailsOfRetentionProposalOrProtocolProps extends BaseModalProps {
  nodeId: string;
  dialogType: string;
}

export const DetailsOfErasedRetentionProtocolOrProposalModal = ({
  nodeId,
  dialogType,
  onCancel
}: ShowDetailsOfRetentionProposalOrProtocolProps) => {
  const TITLE_MAP = {
    titleProtocol: t(
      translationPath(
        lang.dialog.showDetailsOfRetentionProposalOrProtocolModal.titleProtocol
      )
    ),
    titleProposal: t(
      translationPath(
        lang.dialog.showDetailsOfRetentionProposalOrProtocolModal.titleProposal
      )
    )
  };
  const tabsAndForms = [
    {
      tab: t(translationPath(lang.table.retentionProposalTable.metadata)),
      form: <RetentionProtocolErasedMetadataForm id={nodeId} />
    },
    {
      tab: t(translationPath(lang.table.retentionProposalTable.archived)),
      form: <RetentionArchivedTable id={nodeId} />
    },
    {
      tab: t(translationPath(lang.table.retentionProposalTable.destroyed)),
      form: <RetentionDestroyedTable id={nodeId} />
    },
    {
      tab: t(translationPath(lang.table.retentionProposalTable.history)),
      form: <RetentionProposalOrProtocolHistoryTable id={nodeId} />
    }
  ];

  return (
    <ModalWithTabs
      open={true}
      title={
        dialogType === "proposal"
          ? TITLE_MAP.titleProposal
          : TITLE_MAP.titleProtocol
      }
      onCancel={onCancel}
      footer={[
        <Button onClick={onCancel}>
          {t(translationPath(lang.modal.cancel))}
        </Button>
      ]}
      okText={t(translationPath(lang.modal.ok))}
      cancelText={t(translationPath(lang.modal.cancel))}
    >
      <Tabs>
        {tabsAndForms.map((tabAndForm, index) => {
          return (
            <Tabs.TabPane tab={tabAndForm.tab} key={index}>
              <div>{tabAndForm.form}</div>
            </Tabs.TabPane>
          );
        })}
      </Tabs>
    </ModalWithTabs>
  );
};
