import { nodeChildrenAction } from "presentation/core/api/node/_actions";
import { Notification } from "presentation/designSystem/notification/Notification";
import { put, takeLatest } from "redux-saga/effects";
import { translationPath } from "presentation/share/utils/getPath";
import { handleResponse } from "presentation/share/utils/typesafeActions";
import { t } from "presentation/translation/i18n";
import lang from "presentation/translation/lang";
import { ActionType, getType } from "typesafe-actions";
import { nodeShipmentAction } from "./_actions";

export function* watchShipmentTabActionsAction() {
  yield takeLatest(
    getType(nodeShipmentAction.request),
    function* ({ payload }: ActionType<typeof nodeShipmentAction.request>) {
      yield put(nodeChildrenAction.request(payload));

      const [successResponse, errorResponse, success] = yield handleResponse(
        nodeChildrenAction
      );

      if (!success) {
        yield put(nodeShipmentAction.failure(errorResponse));

        Notification.error({
          message: t(translationPath(lang.dialog.notifications.somethingFailed))
        });

        return;
      }

      yield put(nodeShipmentAction.success(successResponse));
    }
  );
}
