import { HttpClient } from "infrastructure/service/HttpClient";
import {
  DInject,
  DInjectable
} from "presentation/core/features/dependencyInjection";
import { AddressType } from "../../../domain/struct/codelist/AddressType";
import { AddressTypeRepository } from "../../../domain/service/codelist/AddressTypeRepository";
import { AddressTypeApiMapper, ApiAddressTypes } from "./AddressTypeApiMapper";
import { ApiUrl } from "../struct/ApiTypes";

const URL: ApiUrl = `/api/app/v1/codelists/address-types`;

@DInjectable()
export class AddressTypesApiRepository implements AddressTypeRepository {
  constructor(@DInject(HttpClient) protected httpClient: HttpClient) {}

  findAll(): Promise<AddressType[]> {
    return this.httpClient
      .fetchWithThrow<ApiAddressTypes[]>(URL)
      .then(AddressTypeApiMapper.mapToDomain);
  }
}
