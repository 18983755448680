import React from "react";
import { Address } from "../../../../../domain/struct/nameRegistry/Address";
import { useFindNationalities } from "../../hooks/useFindNationalities";

type CountryRowProps = Pick<Address, "country">;

export const CountryRow: React.FC<CountryRowProps> = ({ country }) => {
  const { data } = useFindNationalities();

  const value = data?.find((dataItem) => dataItem.code === country);

  return <>{value?.czechName}</>;
};
