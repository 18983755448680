import { ConfigurationRepositoryToken } from "domain/di/token/configuration";
import { ConfigurationApiRepository } from "infrastructure/api/signing/ConfigurationApiRepository";
import { GetConfiguration } from "useCase/configuration/GetConfiguration";

export const configurationProviders = [
  // repository
  {
    provide: ConfigurationRepositoryToken,
    useClass: ConfigurationApiRepository
  },
  GetConfiguration
];
