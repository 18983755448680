import { ApiListResponse, ApiTypes } from "../struct/ApiTypesV2";
import { ApiListResponseMapper } from "../mapper/ApiListResponseMapper";
import { RentedDocument } from "../../../domain/struct/repository/RentedDocument";
import { stringOrDefault } from "../../../lib/string";

export type ApiRepositoryBorrowedFilesViewResponse = ApiTypes["ISFG.SpisUm.ClientSide.Models.V2.Repository.RepositoryBorrowedFilesViewResponse"];

export class RentedDocumentApiMapper {
  static mapListToDomain(
    response: ApiListResponse<ApiRepositoryBorrowedFilesViewResponse>
  ) {
    return ApiListResponseMapper.mapListToPagination(
      response,
      RentedDocumentApiMapper.mapToDomain
    );
  }

  static mapToDomain(
    response: ApiRepositoryBorrowedFilesViewResponse
  ): RentedDocument {
    const {
      pid,
      borrowDate,
      borrower,
      fileIdentifier,
      fileMark,
      filePlanId,
      location,
      nodeId,
      retentionMode,
      senderName,
      shreddingYear,
      ssid,
      subject
    } = response;
    return {
      pid: stringOrDefault(pid),
      borrowDate: stringOrDefault(borrowDate),
      borrower: stringOrDefault(borrower),
      fileIdentifier: stringOrDefault(fileIdentifier),
      fileMark: stringOrDefault(fileMark),
      filePlanId: stringOrDefault(filePlanId),
      location: stringOrDefault(location),
      nodeId: stringOrDefault(nodeId),
      retentionMode: stringOrDefault(retentionMode),
      senderName: stringOrDefault(senderName),
      shreddingYear: stringOrDefault(shreddingYear),
      ssid: stringOrDefault(ssid),
      subject: stringOrDefault(subject)
    };
  }
}
