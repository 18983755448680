import { DateTimeFormats } from "presentation/enums";
import moment from "moment";

// Helpers need to have this format to moment be able to parse them
const MOMENT_HELPER_DATE_FORMAT = "YYYY-MM-DD";
type DateTime = Date | moment.Moment | string | null | undefined;

export const extractDateString = (date: DateTime): string => {
  if (!date) {
    return "";
  }

  return moment(date).format(MOMENT_HELPER_DATE_FORMAT);
};

export const extractTimeString = (date: DateTime): string => {
  if (!date) {
    return "";
  }

  return moment(date).format(DateTimeFormats.HoursMinutesSeconds);
};

export const mergeDateTime = (
  date?: DateTime,
  time?: DateTime
): moment.Moment | undefined => {
  if (!date || !time) {
    return;
  }

  return moment(`${extractDateString(date)} ${extractTimeString(time)}`);
};

export const removeTimeFromDate = (
  date: DateTime
): moment.Moment | undefined => {
  if (!date) {
    return;
  }

  return moment(moment(date).format(MOMENT_HELPER_DATE_FORMAT));
};
