import { dialogOpenAction } from "presentation/core/components/dialog/_actions";
import { DialogType } from "presentation/core/components/dialog/_types";
import { put, takeLatest } from "redux-saga/effects";
import { ActionType, getType } from "typesafe-actions";
import { handoverDocument } from "./_actions";

export function* watchDocumentHandoverAction() {
  yield takeLatest(
    getType(handoverDocument),
    function* ({ payload }: ActionType<typeof handoverDocument>) {
      yield put(
        dialogOpenAction({
          dialogProps: payload,
          dialogType: DialogType.HandoverDocument
        })
      );
    }
  );
}
