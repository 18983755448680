import { getService } from "../../../../core/features/dependencyInjection";
import { useQM } from "../../../hook/query/useQM";
import { FindSignerGroups } from "../../../../../useCase/user/FindSignerGroups";

const KEY = "user/signerGroup";

export const useSignerGroups = () => {
  const findSignerGroup = getService(FindSignerGroups);

  return useQM(
    [KEY],
    () => {
      return findSignerGroup.getSignerGroups();
    },
    {
      cacheTime: 0
    }
  );
};
