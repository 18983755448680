import MenuLayout from "presentation/core/components/layout/MenuLayout";
import React from "react";
import { withTranslation } from "presentation/translation/i18n";
import { StoredFilesTable } from "./tables/StoredFilesTable";

const StoredFiles = () => {
  return (
    <MenuLayout>
      <StoredFilesTable />
    </MenuLayout>
  );
};

export default withTranslation()(StoredFiles);
