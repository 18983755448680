import { ApiListResponse, ApiTypes } from "../struct/ApiTypesV2";
import { RetentionProtocolProposalContent } from "../../../domain/struct/recordRetention/RetentionProtocolProposalContent";
import { stringOrDefault } from "../../../lib/string";
import { ApiListResponseMapper } from "../mapper/ApiListResponseMapper";

export type ApiProtocolOrProposalContent = ApiTypes["ISFG.SpisUm.ClientSide.Models.V2.Shredding.Response.ShreddingErasedObjectsResponse"];

export class RetentionProtocolsOrProposalsContentApiMapper {
  static mapListToDomain(
    response: ApiListResponse<ApiProtocolOrProposalContent>
  ) {
    return ApiListResponseMapper.mapListToPagination(
      response,
      RetentionProtocolsOrProposalsContentApiMapper.mapToDomain
    );
  }

  static mapToDomain(
    retentionProtocolOrProposalContent: ApiProtocolOrProposalContent
  ): RetentionProtocolProposalContent {
    return {
      nodeId: stringOrDefault(retentionProtocolOrProposalContent.nodeId),
      nodeType: stringOrDefault(retentionProtocolOrProposalContent.nodeType),
      pid: stringOrDefault(retentionProtocolOrProposalContent.pid),
      ssidFileIdent: stringOrDefault(
        retentionProtocolOrProposalContent.ssidFileIdent
      ),
      filePlan: stringOrDefault(retentionProtocolOrProposalContent.filePlan),
      subject: stringOrDefault(retentionProtocolOrProposalContent.subject),
      fileMark: stringOrDefault(retentionProtocolOrProposalContent.fileMark),
      retentionMode: stringOrDefault(
        retentionProtocolOrProposalContent.retentionMode
      ),
      sipForSubmit: retentionProtocolOrProposalContent.sipForSubmit,
      sipForArchive: retentionProtocolOrProposalContent.sipForArchive,
      decisionDa: stringOrDefault(
        retentionProtocolOrProposalContent.decisionDa
      ),
      identifierDa: stringOrDefault(
        retentionProtocolOrProposalContent.identifierDa
      ),
      form: stringOrDefault(retentionProtocolOrProposalContent.form)
    };
  }
}
