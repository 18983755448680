import { ApiListResponseMapper } from "infrastructure/api/mapper/ApiListResponseMapper";
import { ApiListResponse } from "infrastructure/api/struct/ApiTypesV2";
import { stringOrDefault } from "lib/string";
import { File } from "presentation/core/entities";

export function mapInactiveComponentToDomain(list: ApiListResponse<File>) {
  return ApiListResponseMapper.mapListToPagination(list, mapDocumentToDomain);
}

function mapDocumentToDomain(document: File): File {
  return {
    id: document.id,
    name: document.name,
    type: document.type,
    isReadable: document.isReadable,
    mimeType: stringOrDefault(document.mimeType),
    sizeInBytes: document.sizeInBytes,
    isEncrypted: document.isEncrypted
  };
}
export function mapToComponentIds(
  components: File[]
): { componentIds: string[] } {
  return {
    componentIds: components.map(function (component) {
      return component["id"];
    })
  };
}
