import { CreateEmailRegistration } from "./../../income/digitalDocument/emailBox/unprocessed/types";
import { CreateDataboxRegistration } from "./../../income/digitalDocument/dataBox/unprocessed/types";
import { AnalogDocument } from "./../../income/analog/types/types";
import { TechnicalDataCarrierDocument } from "./../../income/digitalDocument/technicalDataCarries/types/types";
import { RegisterEmailDigitalModal } from "./../../income/digitalDocument/emailBox/unprocessed/modals/RegisterEmailDigitalModal";
import { RegisterDataboxDigitalModal } from "./../../income/digitalDocument/dataBox/unprocessed/modals/RegisterDataboxDigitalModal";
import { useMutation } from "presentation/share/hook/query/useQM";
import { useModal } from "presentation/designSystem/Modal/useModal";
import { DocumentType } from "presentation/enums";
import { getUnfinishedDocument } from "../api";
import { SpisumNodeTypes } from "../../../../../enums";
import { TechnicalDataCarrierModal } from "../../income/digitalDocument/technicalDataCarries/modal/TechnicalDataCarrierModal";
import { AnalogDetailAntModal } from "../../income/analog/modal/AnalogDetailsAntModal";
import { ErrorType } from "../../../../../types";
import { lang, t } from "../../../../../translation/i18n";
import { translationPath } from "../../../../../share/utils/getPath";

export const useGetUnfinishedDocument = () => {
  const [analogModal, analogModalApi] = useModal(AnalogDetailAntModal, {});
  const [emailModal, emailModalApi] = useModal(RegisterEmailDigitalModal, {});
  const [databoxModal, databoxModalApi] = useModal(
    RegisterDataboxDigitalModal,
    {}
  );
  const [technicalCarrierModal, technicalCarrierModalApi] = useModal(
    TechnicalDataCarrierModal,
    {}
  );

  const { isLoading, mutate: register } = useMutation(
    ({ id, documentType }: { id: string; documentType: string }) =>
      getUnfinishedDocument(id, documentType),
    {
      onSuccess: (data, { documentType }) => {
        switch (documentType) {
          case DocumentType.Analog:
            analogModalApi.open({
              document: data as AnalogDocument
            });
            break;
          case DocumentType.Email:
            emailModalApi.open({
              document: data as CreateEmailRegistration,
              nodeId: data.nodeId!,
              documentType: documentType as DocumentType,
              nodeType: data.nodeType as SpisumNodeTypes
            });
            break;
          case DocumentType.Databox:
            databoxModalApi.open({
              document: data as CreateDataboxRegistration,
              nodeId: data.nodeId!,
              documentType: documentType as DocumentType,
              nodeType: data.nodeType as SpisumNodeTypes
            });
            break;
          case DocumentType.TechnicalDataCarries:
            technicalCarrierModalApi.open({
              document: data as TechnicalDataCarrierDocument,
              nodeId: data.nodeId!,
              nodeType: data.nodeType as SpisumNodeTypes
            });
            break;
          default:
            return;
        }
      },
      onErrorNotification: (error: ErrorType) => ({
        message:
          Array.isArray(error.messages) && error.messages.length > 0
            ? error.messages[0]
            : t(translationPath(lang.dialog.notifications.actionFailed))
      })
    }
  );

  return {
    isLoading,
    register,
    modal: [analogModal, emailModal, databoxModal, technicalCarrierModal]
  };
};
