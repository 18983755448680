import { Configuration } from "domain/struct/configuration/Configuration";
import { ApiTypes } from "../struct/ApiTypes";

export type SigningConfigurationResponse = ApiTypes["ISFG.SpisUm.ClientSide.Models.FeConfiguration"];
export class ConfigurationApiMapper {
  static mapToDomain(response: SigningConfigurationResponse): Configuration {
    return {
      signerEnabled: response.signerEnabled,
      tokenExpiration: response.tokenExpiration || undefined
    };
  }
}
