import {
  DInject,
  DInjectable
} from "presentation/core/features/dependencyInjection";
import { StoredFilesRepositoryToken } from "../../domain/di/token/repository";
import { StoredFilesApiRepository } from "../../infrastructure/api/repository/StoredFilesApiRepository";
import { SortingConfig } from "lib/contract/Sorting";
import { PaginationConfig } from "lib/contract/Pagination";

@DInjectable()
export class FindStoredFiles {
  constructor(
    @DInject(StoredFilesRepositoryToken)
    protected repository: StoredFilesApiRepository
  ) {}

  findAllPaginated(config: PaginationConfig, sorting?: SortingConfig) {
    return this.repository.getStoredFiles(config, sorting);
  }
}
