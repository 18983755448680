import CreateIcon from "@mui/icons-material/Create";
import { TextField } from "formik-mui";
import { StyledFieldWide } from "presentation/core/components/dialog/Dialog.styles";
import { lang, t } from "presentation/translation/i18n";
import { lastPathMember, translationPath } from "../../../../../utils/getPath";
import { CreateShipmentFormValuesProxy } from "../../_types";
import AmountToSend from "../common/AmountToSend";
import React from "react";

interface OwnProps {
  readonly: boolean;
  onSearchSubject?: () => void;
}

export const PersonallyFormFields = ({
  readonly,
  onSearchSubject
}: OwnProps) => {
  return (
    <>
      <div style={{ display: "flex", position: "relative", width: "100%" }}>
        <StyledFieldWide
          component={TextField}
          data-test-id="create-shipment-post-address1"
          disabled={readonly}
          required={true}
          name={lastPathMember(CreateShipmentFormValuesProxy.address1).path}
          type="text"
          label={t(translationPath(lang.general.addressee))}
        />
        <CreateIcon
          style={{
            margin: "9px",
            position: "absolute",
            right: 0,
            top: "1.25rem",
            cursor: "pointer"
          }}
          onClick={onSearchSubject}
        />
      </div>
      <StyledFieldWide
        component={TextField}
        data-test-id="create-shipment-post-recipientAddressId"
        disabled={readonly}
        required={false}
        name={
          lastPathMember(CreateShipmentFormValuesProxy.recipientAddressId).path
        }
        type="text"
        style={{ display: "none" }}
      />
      <StyledFieldWide
        component={TextField}
        data-test-id="create-shipment-address2"
        disabled={readonly}
        name={lastPathMember(CreateShipmentFormValuesProxy.address2).path}
        type="text"
      />
      <StyledFieldWide
        component={TextField}
        data-test-id="create-shipment-address3"
        disabled={readonly}
        name={lastPathMember(CreateShipmentFormValuesProxy.address3).path}
        type="text"
      />
      <StyledFieldWide
        component={TextField}
        data-test-id="create-shipment-address4"
        disabled={readonly}
        name={lastPathMember(CreateShipmentFormValuesProxy.address4).path}
        type="text"
      />
      <StyledFieldWide
        component={TextField}
        data-test-id="create-shipment-address-street"
        disabled={true}
        required={true}
        name={lastPathMember(CreateShipmentFormValuesProxy.addressStreet).path}
        type="text"
        label={t(translationPath(lang.general.streetAndStreetNumber))}
      />
      <StyledFieldWide
        component={TextField}
        data-test-id="create-shipment-address-city"
        disabled={true}
        required={true}
        name={lastPathMember(CreateShipmentFormValuesProxy.addressCity).path}
        type="text"
        label={t(translationPath(lang.general.municipality))}
      />
      <StyledFieldWide
        component={TextField}
        data-test-id="create-shipment-address-zip"
        disabled={true}
        required={true}
        name={lastPathMember(CreateShipmentFormValuesProxy.addressZip).path}
        type="text"
        label={t(translationPath(lang.general.zipCode))}
      />
      <StyledFieldWide
        component={TextField}
        data-test-id="create-shipment-address-state"
        disabled={true}
        name={lastPathMember(CreateShipmentFormValuesProxy.addressState).path}
        type="text"
        label={t(translationPath(lang.general.addressState))}
      />

      <AmountToSend readonly={readonly} />
    </>
  );
};
