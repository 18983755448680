import { useForm } from "presentation/designSystem/Form/v2/Form";
import { Modal, ModalSize } from "presentation/designSystem/Modal/Modal";
import { BaseModalProps } from "presentation/designSystem/Modal/useModal";
import { Notification } from "presentation/designSystem/notification/Notification";
import React, { useState } from "react";
import { useMutation } from "../../../../../share/hook/query/useMutation";
import { getErrorCodeTranslation } from "../../../../../share/utils/errorCodeTranslation";
import { translationPath } from "../../../../../share/utils/getPath";
import { lang, t } from "../../../../../translation/i18n";
import { ErrorType } from "../../../../../types";
import { Whitelist } from "../_types";
import { updateDetailWhiteListEmail } from "../api";
import { DetailWhitelistForm } from "../form/DetailWhitelistForm";

export interface AddEmailToWhitelistModalInterface {
  onCancel?: BaseModalProps["onCancel"];
  onOk?: BaseModalProps["onOk"];
  selectedWhiteList: Whitelist;
}

export const DetailWhitelistModal = ({
  onCancel,
  selectedWhiteList
}: AddEmailToWhitelistModalInterface) => {
  const [errors, setErrors] = useState<any>([]);
  const [form] = useForm();
  const onChange = async () => {
    try {
      await form.validateFields();
      setErrors([]);
    } catch (error: any) {
      setErrors(error.errorFields);
    }
  };

  const { mutate: update } = useMutation(
    async () => {
      await updateDetailWhiteListEmail(selectedWhiteList.emailId!, {
        email: form.getFieldsValue().email,
        comment: form.getFieldsValue().comment
      });
    },
    {
      onError(error: ErrorType) {
        if (error.message === "Failed to fetch") {
          Notification.error({
            message: t(
              translationPath(
                lang.dialog.notifications.emailWasNotSuccessfullyEdited
              )
            )
          });
          return;
        }
        if (error.code) {
          return Notification.error({
            message: getErrorCodeTranslation(error.code)
          });
        }
        if (error?.messages) {
          error.messages.map((error: string) =>
            Notification.error({
              message: getErrorCodeTranslation(error)
            })
          );
        } else {
          if (error.message) {
            return Notification.error({
              message: error.message
            });
          }
          Notification.error({
            message: t(translationPath(lang.dialog.notifications.actionFailed))
          });
        }
      },
      onSuccess() {
        Notification.success({
          message: t(
            translationPath(
              lang.dialog.notifications.emailWasSuccessfullyEdited
            )
          )
        });
        // onOk?.();
        onCancel?.();
      },
      onErrorNotification: null,
      onSuccessNotification: null
    }
  );

  const onOk = () => {
    selectedWhiteList.email === form.getFieldsValue().email &&
    selectedWhiteList.comment === form.getFieldsValue().comment
      ? onCancel?.()
      : update(null);
  };

  return (
    <Modal
      open={true}
      title={t(translationPath(lang.dialog.whitelistDialogs.detail.title))}
      onCancel={onCancel}
      onOk={onOk}
      okButtonProps={{
        disabled:
          errors?.filter((error: any) => error?.errors?.length)?.length > 0
      }}
      size={ModalSize.Small}
      centered={true}
      okText={t(translationPath(lang.modal.ok))}
      cancelText={t(translationPath(lang.modal.cancel))}
    >
      <DetailWhitelistForm
        form={form}
        onChange={onChange}
        selectedWhiteList={selectedWhiteList}
      />
    </Modal>
  );
};
