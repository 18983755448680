import { useForm } from "@isfg/react-components/build/Form/Form";
import { BaseModalProps } from "@isfg/react-components/build/Modal/useModal";
import { Tabs } from "@isfg/react-components/build/Tabs/Tabs";
import { SslEmail } from "presentation/core/api/models";
import { dialogOpenAction } from "presentation/core/components/dialog/_actions";
import { DialogType } from "presentation/core/components/dialog/_types";
import { useComponentsV2TabContainer } from "presentation/core/components/dialog/tabs/components/hooks/useComponentsV2TabContainer";
import { EmailDocument } from "presentation/core/types";
import { Button } from "presentation/designSystem/Button/Button";
import { RemoteTableApiContextProvider } from "presentation/designSystem/Table/contexts/RemoteTableApiContextProvider";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { ModalWithPreview } from "../../../../../../../../designSystem/Modal/ModalWithPreview";
import { SpisumNodeTypes } from "../../../../../../../../enums";
import { EmailDetailsForm } from "../forms/EmailDetailsForm";
import { UseCreateEmailRegistrationReturn } from "../hooks/useCreateEmailRegistration";

interface EmailDetailsModalProps {
  onCancel?: BaseModalProps["onCancel"];
  onOk?: BaseModalProps["onOk"];
  data: EmailDocument;
  register: UseCreateEmailRegistrationReturn["register"];
}

enum EmailDetailsModalTabs {
  Metadata = "0",
  Components = "1"
}

export const EmailDetailsModal = (props: EmailDetailsModalProps) => {
  return (
    <RemoteTableApiContextProvider>
      <EmailDetailsModalInner {...props} />
    </RemoteTableApiContextProvider>
  );
};

export const EmailDetailsModalInner = ({
  onCancel,
  data,
  register
}: EmailDetailsModalProps) => {
  const [activeTab, setActiveTab] = useState("0");
  const [form] = useForm<SslEmail>();
  const formData = data.properties!.ssl!;
  const dispatch = useDispatch();

  const changeActiveTab = (activeTabIndex: string) => {
    setActiveTab(activeTabIndex);
  };

  const onNotReadable = () => {
    dispatch(
      dialogOpenAction({
        dialogProps: {
          data,
          onSuccess: onCancel
        },
        dialogType: DialogType.IncompleteDocument
      })
    );
  };

  const onNotRegister = () => {
    dispatch(
      dialogOpenAction({
        dialogProps: { data, onSuccess: onCancel },
        dialogType: DialogType.DontRegisterDocument
      })
    );
  };

  const onRegister = () => {
    register(data.id);
    onCancel?.();
  };

  const [showPreview, setShowPreview] = useState(false);
  const [previewItem, setPreviewItem] = useState({
    id: "",
    name: "",
    nodeType: SpisumNodeTypes.Email,
    entityId: "",
    fileIsEncrypted: false
  });

  const [componentsV2TabContainer, { fetchComponents }] =
    useComponentsV2TabContainer({
      nodeId: data.id,
      isActive: activeTab === EmailDetailsModalTabs.Components,
      dialogProps: {
        canUploadComponents: false,
        data
      },
      isReadonly: true,
      showPreview,
      previewItem,
      setPreviewItem,
      setShowPreview
    });

  const tabsWithRefresh = {
    [EmailDetailsModalTabs.Components]: fetchComponents
  };

  return (
    <ModalWithPreview
      open={true}
      hasTabs
      onRefresh={tabsWithRefresh[activeTab as keyof typeof tabsWithRefresh]}
      title={t(translationPath(lang.dialog.title.emailDetails))}
      onCancel={onCancel}
      confirmLoading={false}
      handleShowPreviewChange={() => setShowPreview(false)}
      showPreview={showPreview}
      previewItem={previewItem}
      setPreviewItem={setPreviewItem}
      footer={[
        <Button key="back" onClick={onCancel}>
          {t(translationPath(lang.modal.cancel))}
        </Button>,
        <Button key="notReadable" onClick={onNotReadable}>
          {t(translationPath(lang.dialog.form.notReadable))}
        </Button>,
        <Button key="notRegister" onClick={onNotRegister}>
          {t(translationPath(lang.dialog.form.notRegister))}
        </Button>,
        <Button key="register" type="primary" onClick={onRegister}>
          {t(translationPath(lang.dialog.form.register))}
        </Button>
      ]}
    >
      <Tabs onChange={changeActiveTab}>
        <Tabs.TabPane
          tab={t(translationPath(lang.dialog.subjectDialog.tabs.metadata))}
          key={EmailDetailsModalTabs.Metadata}
        >
          <EmailDetailsForm formData={formData} form={form} />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={t(translationPath(lang.dialog.tabs.components))}
          key={EmailDetailsModalTabs.Components}
        >
          {componentsV2TabContainer}
        </Tabs.TabPane>
      </Tabs>
    </ModalWithPreview>
  );
};
