import { useFormikContext } from "formik";
import React, { useEffect } from "react";
import { SendModeValues } from "../../../../enums";
import { SearchSubjectResult } from "./CreateShipmentForm";
import { useFindCountries } from "../../../../modules/nameRegister/hooks/useFindCountries";
import { Validator } from "../../../../designSystem/Form/Form";

interface MuiFormConnectionProps {
  searchSubjectResult?: SearchSubjectResult;
  sendMode?: SendModeValues;
}

export const MuiFormikShipmentHelper: React.FC<MuiFormConnectionProps> = ({
  searchSubjectResult,
  sendMode
}) => {
  const { setFieldValue, setSubmitting } = useFormikContext();
  const { data: countryCodesAndNames } = useFindCountries({ retry: false });

  useEffect(() => {
    setSubmitting?.(false);
    setFieldValue?.("recipientId", searchSubjectResult?.subject?.id || "");
    if (sendMode === SendModeValues.Email) {
      const maybeMail = searchSubjectResult?.contacts?.find(
        (contact) => contact.contactType === "EMAIL"
      );
      setFieldValue?.("recipientContactEmail", maybeMail?.contact || "");
      setFieldValue?.("recipientContactEmailBoxId", maybeMail?.id || "");
    } else if (sendMode === SendModeValues.Databox) {
      const maybeDatabox = searchSubjectResult?.contacts?.find(
        (contact) => contact.contactType === "DATABOX"
      );
      setFieldValue?.("recipientContactDatabox", maybeDatabox?.contact || "");
      setFieldValue?.("recipientContactDataboxId", maybeDatabox?.id || "");
    } else if (
      sendMode === SendModeValues.Post ||
      sendMode === SendModeValues.Personally
    ) {
      const maybeAddress = searchSubjectResult?.addresses?.[0];
      const maybeSubjectName = searchSubjectResult?.subject?.fullName ?? "";
      setFieldValue?.("address1", maybeSubjectName);

      if (maybeAddress) {
        const {
          street,
          orientationHouseNumber,
          orientationHouseNumberAddendum,
          houseNumber,
          cityPart,
          city,
          postalCode,
          country
        } = maybeAddress;

        const countryCzechName =
          countryCodesAndNames &&
          countryCodesAndNames.find(
            (countryCodeAndName) => country === countryCodeAndName.code
          );

        const streetFullName = [street, " ", houseNumber];
        if (orientationHouseNumber) {
          streetFullName.push(...["/", orientationHouseNumber]);
          if (orientationHouseNumberAddendum) {
            streetFullName.push(orientationHouseNumberAddendum);
          }
        }
        setFieldValue?.("addressStreet", streetFullName.join(""));

        const cityFullName = [];
        if (cityPart) {
          cityFullName.push(cityPart, ", ");
        }
        if (city) {
          cityFullName.push(city);
        }
        setFieldValue?.("addressCity", cityFullName.join(""));

        if (postalCode) {
          setFieldValue?.("addressZip", postalCode);
        }

        if (country) {
          setFieldValue?.("addressState", countryCzechName?.czechName);
        }
        setFieldValue?.("recipientAddressId", maybeAddress?.id || "");
      }
    }
  }, [
    countryCodesAndNames,
    searchSubjectResult,
    sendMode,
    setFieldValue,
    setSubmitting
  ]);

  return <Validator />;
};
