import { useMutation } from "presentation/share/hook/query/useMutation";
import { registerDocumentFromAnalog, updateUnfinishedDocument } from "../api";
import { UpdateAnalogFormFieldTypes } from "../types/types";
import { getErrorCodeTranslation } from "../../../../../../share/utils/errorCodeTranslation";
import { ErrorType } from "../../../../../../types";
import { lang, t } from "../../../../../../translation/i18n";
import { translationPath } from "../../../../../../share/utils/getPath";

export interface UpdateDocument {
  readonly deliveryDate?: string;
  readonly deliveryMode?: string;
  readonly attachmentsCount?: number;
  readonly attachmentsType?: string;
  readonly listCount?: number;
  readonly listCountAttachments?: number;
  readonly settleToDate?: string;
  readonly senderIdent?: string;
  readonly senderSsid?: string;
  readonly subject?: string;
  readonly senderId?: number;
  readonly senderAddressId?: number;
  readonly senderContactDataboxId?: number;
  readonly senderContactEmailId?: number;
  readonly senderContactPhoneId?: number;
  readonly senderCompanyEmployeeName?: string;
  readonly senderCompanyEmployeeUnit?: string;
  readonly senderCompanyEmployeeJob?: string;
}

interface UpdateDocumentVariables {
  id: string;
  input: UpdateAnalogFormFieldTypes;
}

export const useUpdateDocument = () => {
  const { isLoading: registrationLoading, mutate: register } = useMutation(
    (id: string) => registerDocumentFromAnalog(id),
    {
      onSuccess: () => {
        return Promise.resolve();
      },
      onErrorNotification: (error: ErrorType) => ({
        message:
          Array.isArray(error.messages) && error.messages.length > 0
            ? error.messages[0]
            : t(translationPath(lang.dialog.notifications.actionFailed))
      })
    }
  );

  const { isLoading: updateLoading, mutate: update } = useMutation(
    ({ id, input }: UpdateDocumentVariables) =>
      updateUnfinishedDocument(id, input),
    {
      onSuccess: () => {
        return Promise.resolve();
      },
      onErrorNotification: (error: ErrorType) => ({
        message:
          Array.isArray(error.messages) && error.messages.length > 0
            ? error.messages[0]
            : t(translationPath(lang.dialog.notifications.actionFailed))
      })
    }
  );

  const {
    isLoading: updateAndRegisterLoading,
    mutate: updateAndRegister
  } = useMutation(
    async ({ id, input }: UpdateDocumentVariables) => {
      await updateUnfinishedDocument(id, input);
      await registerDocumentFromAnalog(id);
    },
    {
      onSuccess: () => {
        return Promise.resolve();
      },
      onErrorNotification: (error: ErrorType) => ({
        message: error.code
          ? getErrorCodeTranslation(error.code)
          : Array.isArray(error.messages) && error.messages.length > 0
          ? error.messages[0]
          : t(translationPath(lang.dialog.notifications.fileTypeSettledFailed))
      })
    }
  );

  return {
    registrationLoading,
    updateLoading,
    updateAndRegisterLoading,
    update,
    register,
    updateAndRegister
  };
};
