import React, { useCallback } from "react";
import { HistoryTable, HistoryTableProps } from "../../history/HistoryTable";
import { useConceptHistory } from "./useConceptHistory";
import { FileId } from "../../../../../domain/struct/file/File";

export interface FileHistoryTableProps
  extends Pick<HistoryTableProps, "onRemoteTableApi"> {
  id: FileId;
}

export const ConceptHistoryTable: React.FC<FileHistoryTableProps> = React.memo(
  ({ id, onRemoteTableApi }) => {
    const findHistory = useConceptHistory(id);

    const getRemoteData: HistoryTableProps["getRemoteData"] = useCallback(
      ({ pagination, sorting }) => findHistory(pagination, sorting),
      [findHistory]
    );

    return (
      <HistoryTable
        id={id}
        getRemoteData={getRemoteData}
        onRemoteTableApi={onRemoteTableApi}
      />
    );
  }
);
