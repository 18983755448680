import { ColumnType } from "presentation/designSystem/Table/Table";
import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { LegalEntity } from "../../../../../domain/struct/nameRegistry/LegalEntity";
import { SubjectType } from "../../../../../domain/struct/nameRegistry/SubjectType";
import { DateTimeFormat } from "../../../../../lib/dateTime";
import { BreadcrumbForTable } from "../../../../designSystem/Breadcrumb/Breadcrumb";
import {
  FileTextFilled,
  PlusCircleOutlined
} from "../../../../designSystem/Icon/Icon";
import { useModal } from "../../../../designSystem/Modal/useModal";
import {
  GetRemoteDataClb,
  RemoteTable
} from "../../../../designSystem/Table/RemoteTable";
import { createDateTimeColumn } from "../../../../designSystem/Table/column/column";
import { translationPath } from "../../../../share/utils/getPath";
import { lang, t } from "../../../../translation/i18n";
import { useFindLegalEntities } from "../../hooks/useFindLegalEntities";
import { SubjectModal } from "../../modal/SubjectModal/SubjectModal";

const COLUMNS: ColumnType<LegalEntity>[] = [
  {
    dataIndex: "identifier",
    title: t(translationPath(lang.table.legalEntityTable.identifier)),
    sorter: true,
    sortDirections: ["ascend", "descend", "ascend"]
  },
  {
    dataIndex: "fullName",
    title: t(translationPath(lang.table.legalEntityTable.subjectName)),
    sorter: true,
    sortDirections: ["ascend", "descend", "ascend"]
  },
  {
    dataIndex: "companyFullName",
    title: t(translationPath(lang.table.legalEntityTable.organizationName)),
    defaultSortOrder: "ascend",
    sortDirections: ["ascend", "descend", "ascend"],
    sorter: true
  },
  {
    dataIndex: "identificationNumber",
    title: t(translationPath(lang.table.legalEntityTable.identificationNumber)),
    sorter: true,
    sortDirections: ["ascend", "descend", "ascend"]
  },
  createDateTimeColumn(
    {
      dataIndex: "processedAt",
      title: t(translationPath(lang.table.personTable.creationDate)),
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"]
    },
    DateTimeFormat.DateTimeNoSeconds
  )
];

export const LegalEntityTable = () => {
  const [subjectModal, subjectModalApi] = useModal(SubjectModal, {
    onClose() {
      setSelectedRowKeys([]);
    }
  });
  const findLegalEntities = useFindLegalEntities();

  const ACTIONS = useMemo(
    () => ({
      default: [
        {
          key: "create-subject",
          icon: <PlusCircleOutlined rev={"default"} />,
          tooltip: "Vytvořit subjekt",
          action() {
            return subjectModalApi.open({
              type: SubjectType.LegalEntity
            });
          }
        }
      ],
      single: [
        {
          key: "show-details",
          icon: <FileTextFilled rev={"default"} />,
          tooltip: "Zobrazit detaily",
          action(selected: LegalEntity[]) {
            return subjectModalApi.open({
              type: SubjectType.LegalEntity,
              legalEntity: selected[0]
            });
          }
        }
      ]
    }),
    [subjectModalApi]
  );

  const getRemoteData: GetRemoteDataClb<LegalEntity> = useCallback(
    ({ pagination, sorting }) => findLegalEntities(pagination, sorting),
    [findLegalEntities]
  );

  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
  const onRowSelection = {
    onChange: (selectedRowKeys: any[]) => {
      setSelectedRowKeys(selectedRowKeys);
    },
    selectedRowKeys
  };

  return (
    <>
      {subjectModal}
      <BreadcrumbForTable title={"legalEntity"}></BreadcrumbForTable>
      <TableStyled>
        <RemoteTable<LegalEntity>
          actions={ACTIONS}
          columns={COLUMNS}
          name="legalEntity"
          getRemoteData={getRemoteData}
          rowKey="id"
          rowSelection={onRowSelection}
        />
      </TableStyled>
    </>
  );
};
const TableStyled = styled("div")`
  div > .ant-row {
    height: 50px;
  }
`;
