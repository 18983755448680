import { BaseModalProps } from "@isfg/react-components/build/Modal/useModal";
import { useInactiveComponentsV2TabContainer } from "presentation/core/components/dialog/tabs/InactivateComponents/hooks/useInactiveComponentsV2TabContainer";
import CommentsTabContainer from "presentation/core/components/dialog/tabs/comments";
import { useComponentsV2TabContainer } from "presentation/core/components/dialog/tabs/components/hooks/useComponentsV2TabContainer";
import { useShipmentTab } from "presentation/core/components/dialog/tabs/shipment/hooks/useShipmentTab";
import { useVersionTab } from "presentation/core/components/dialog/tabs/version/hooks/useVersionTab";
import { Button } from "presentation/designSystem/Button/Button";
import { useForm } from "presentation/designSystem/Form/v2/Form";
import { ModalWithPreview } from "presentation/designSystem/Modal/ModalWithPreview";
import { useRemoteTableApi } from "presentation/designSystem/Table/Table";
import { Tabs } from "presentation/designSystem/Tabs/Tabs";
import { DocumentState, SpisumNodeTypes } from "presentation/enums";
import { SearchSubjectResult } from "presentation/modules/nameRegister/modal/SearchSubjectModal/SearchSubjectModal";
import { useDocumentConcernedSubjectTable } from "presentation/share/tables/document/ConcernedSubjectTable/useDocumentConcernedSubjectTable";
import { DocumentHistoryTable } from "presentation/share/tables/document/DocumentHistoryTable/DocumentHistoryTable";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import React, { useState } from "react";
import { EvidenceDocumentDetails } from "../_types";
import {
  DocumentDetailsForm,
  DocumentDetailsFormValues
} from "../forms/DocumentDetailsForm";
import { useUpdateDocument } from "../hooks/useUpdateDocument";

export interface DocumentDetailsModalProps extends BaseModalProps {
  document: EvidenceDocumentDetails;
}

enum TabKeys {
  Metadata = "0",
  Components = "1",
  InactiveComponents = "2",
  ConcernedSubjects = "3",
  Version = "4",
  Shipment = "5",
  Comments = "6",
  History = "7"
}

export const DocumentDetailsModal = ({
  onCancel,
  onOk,
  document
}: DocumentDetailsModalProps) => {
  const [form] = useForm<DocumentDetailsFormValues>();
  const [activeTab, setActiveTab] = useState("0");
  const [tableApi, onRemoteTableApi] = useRemoteTableApi();

  const isReadonly = document.state === DocumentState.Settled;

  const changeActiveTab = (activeTabIndex: string) => {
    const activeTabKey = Object.values(TabKeys).find(
      (value) => value === activeTabIndex
    );
    setActiveTab(activeTabKey ?? TabKeys.Metadata);
  };

  const [showPreview, setShowPreview] = useState(false);
  const [previewItem, setPreviewItem] = useState({
    id: "",
    name: "",
    nodeType: document.nodeType || "",
    entityId: "",
    fileIsEncrypted: false
  });
  const [showInactiveComponents, setShowInactiveComponents] = useState(false);

  const handleShowDeactivateComponent = () => {
    setShowInactiveComponents(true);
    setActiveTab(TabKeys.InactiveComponents);
  };

  const [componentsV2TabContainer, { fetchComponents }] =
    useComponentsV2TabContainer({
      nodeId: document.nodeId!,
      isActive: activeTab === TabKeys.Components,
      dialogProps: {
        canUploadComponents: false,
        data: { nodeType: SpisumNodeTypes.Document }
      },
      showPreview,
      previewItem,
      setPreviewItem,
      setShowPreview,
      handleShowDeactivateComponent,
      isReadonly
    });
  const [inactiveComponentsV2TabContainer, { inActiveFetchComponent }] =
    useInactiveComponentsV2TabContainer({
      nodeId: document.nodeId!,
      isActive: activeTab === TabKeys.InactiveComponents,
      dialogProps: {
        canUploadComponents: false,
        data: { nodeType: SpisumNodeTypes.Document }
      },
      showPreview,
      previewItem,
      setPreviewItem,
      setShowPreview,
      isReadonly
    });

  const [
    documentConcernedSubjectTable,
    { setSenderAsConcernedSubject, refreshDocConcernedSubjs }
  ] = useDocumentConcernedSubjectTable({
    documentId: document.nodeId!,
    readonly: isReadonly
  });

  const [versionTab, { fetchVersion }] = useVersionTab({
    nodeId: document.nodeId!,
    isActive: activeTab === TabKeys.Version
  });

  const [shipmentTab, { fetchShipments }] = useShipmentTab({
    nodeId: document.nodeId!,
    isActive: activeTab === TabKeys.Shipment
  });

  const onSenderChange = (sender: SearchSubjectResult) => {
    setSenderAsConcernedSubject(sender.subject);
  };

  const { isUpdateLoading, update } = useUpdateDocument();

  const onSave = () => {
    update(
      { id: document.nodeId!, input: form.getFieldsValue(true) },
      {
        onSuccess: () => {
          onOk?.();
        }
      }
    );
  };

  const tabsWithRefresh = {
    [TabKeys.Components]: fetchComponents,
    [TabKeys.InactiveComponents]: inActiveFetchComponent,
    [TabKeys.ConcernedSubjects]: refreshDocConcernedSubjs,
    [TabKeys.Version]: fetchVersion,
    [TabKeys.Shipment]: fetchShipments,
    [TabKeys.History]: tableApi.refetch
  };

  return (
    <>
      <ModalWithPreview
        open={true}
        hasTabs
        onRefresh={tabsWithRefresh[activeTab as keyof typeof tabsWithRefresh]}
        title={t(translationPath(lang.dialog.title.documentDetail))}
        onCancel={onCancel}
        confirmLoading={false}
        handleShowPreviewChange={() => setShowPreview(false)}
        showPreview={showPreview}
        previewItem={previewItem}
        setPreviewItem={setPreviewItem}
        footer={[
          <Button key="back" onClick={onCancel}>
            {t(translationPath(lang.modal.cancel))}
          </Button>,
          ...(isReadonly
            ? []
            : [
                <Button
                  key="save"
                  type="primary"
                  onClick={onSave}
                  loading={isUpdateLoading}
                >
                  {t(translationPath(lang.general.save))}
                </Button>
              ])
        ]}
      >
        <Tabs onChange={changeActiveTab} activeKey={activeTab}>
          <Tabs.TabPane
            tab={t(translationPath(lang.dialog.tabs.metadata))}
            key={TabKeys.Metadata}
          >
            <DocumentDetailsForm
              form={form}
              formData={document}
              onSenderChange={onSenderChange}
              isReadonly={isReadonly}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={t(translationPath(lang.dialog.tabs.components))}
            key={TabKeys.Components}
          >
            {componentsV2TabContainer}
          </Tabs.TabPane>
          {showInactiveComponents && (
            <Tabs.TabPane
              tab={t(translationPath(lang.dialog.tabs.InactiveComponents))}
              key={TabKeys.InactiveComponents}
            >
              {inactiveComponentsV2TabContainer}
            </Tabs.TabPane>
          )}
          <Tabs.TabPane
            tab={t(translationPath(lang.dialog.tabs.concernedSubjects))}
            key={TabKeys.ConcernedSubjects}
          >
            <div className="body-fullsize">{documentConcernedSubjectTable}</div>
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={t(translationPath(lang.dialog.tabs.version))}
            key={TabKeys.Version}
          >
            {versionTab}
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={t(translationPath(lang.dialog.tabs.shipment))}
            key={TabKeys.Shipment}
          >
            {shipmentTab}
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={t(translationPath(lang.dialog.tabs.notes))}
            key={TabKeys.Comments}
          >
            <CommentsTabContainer
              nodeId={document.nodeId!}
              isActive={activeTab === TabKeys.Comments}
              dialogProps={{
                data: { nodeType: SpisumNodeTypes.Document },
                isReadonly
              }}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={t(translationPath(lang.dialog.tabs.history))}
            key={TabKeys.History}
          >
            <DocumentHistoryTable
              id={document.nodeId!}
              onRemoteTableApi={onRemoteTableApi}
              queryConfig={{ refetchOnWindowFocus: true }}
            />
          </Tabs.TabPane>
        </Tabs>
      </ModalWithPreview>
    </>
  );
};
