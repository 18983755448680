import MenuLayout from "presentation/core/components/layout/MenuLayout";
import React from "react";
import { withTranslation } from "presentation/translation/i18n";
import { ForSealTable } from "./tables/ForSealTable";

const OverviewsForSeal = () => {
  return (
    <MenuLayout>
      <ForSealTable />
    </MenuLayout>
  );
};

export default withTranslation()(OverviewsForSeal);
