import {
  HttpClient,
  HttpMethod
} from "../../../../../../infrastructure/service/HttpClient";
import { SortingConfig } from "lib/contract/Sorting";

import { PaginatedResult, PaginationConfig } from "lib/contract/Pagination";

import { Mappers } from "./mappers";
import {
  ApiTypes,
  ApiUrl
} from "../../../../../../infrastructure/api/struct/ApiTypesV2";
import { Whitelist } from "../_types";

const URL: ApiUrl = "/api/admin/v2/email-whitelist";

export type EmailWhitelistResponse = ApiTypes["ISFG.SpisUm.ClientSide.Models.V2.Administration.EmailWhitelist.EmailWhitelistResponse"];

export const fetchPaginatedWhiteList = (
  pagination: PaginationConfig,
  sorting?: SortingConfig
): Promise<PaginatedResult<Whitelist>> => {
  const httpClient = new HttpClient();
  return httpClient
    .fetchPaginated<EmailWhitelistResponse>({
      url: URL,
      pagination,
      sorting
    })
    .then(Mappers.mapListToDomain);
};

export const createNewWhiteListEmail = (
  payload: Whitelist
): Promise<Whitelist> => {
  const httpClient = new HttpClient();
  return httpClient.fetchWithThrow<Whitelist>(
    "/api/admin/v2/email-whitelist/create",
    "POST",
    { bodyJSON: payload }
  );
};

export const updateDetailWhiteListEmail = (
  emailId: number,
  body: {
    email?: string;
    comment?: string;
  }
): Promise<Whitelist> => {
  const httpClient = new HttpClient();
  return httpClient.fetchWithThrow<Whitelist>(
    "/api/admin/v2/email-whitelist/{emailId}/update",
    "POST",
    {
      urlWildCards: { emailId },
      bodyJSON: { ...body }
    }
  );
};
export const deleteWhiteListEmail = (
  emailIds?: number[]
): Promise<Whitelist> => {
  const httpClient = new HttpClient();
  return httpClient.fetchWithThrow<Whitelist>(
    "/api/admin/v2/email-whitelist",
    HttpMethod.Delete,
    {
      bodyJSON: { emailIds }
    }
  );
};
