export enum AddressTypeCode {
  Permanent = "PERMANENT_ADDRESS",
  PermanentResidence = "PERMANENT_RESIDENCE",
  TemporaryResidence = "TEMPORARY_RESIDENCE",
  CompanyHeadquarters = "COMPANY_HEADQUARTERS",
  CompanyBranch = "COMPANY_BRANCH",
  RepresentationAddress = "REPRESENTATION_ADDRESS",
  ResidenceAddressInCz = "RESIDENCE_ADDRESS_IN_CZ",
  GuardiansAddress = "GUARDIANS_ADDRESS",
  Mailing = "MAILING",
  POBOX = "P_O_BOX",
  Other = "OTHER"
}

export interface AddressType {
  readonly code: string;
  readonly czechName: string;
  readonly subjectType: string;
  readonly minOccurrence?: number;
  readonly maxOccurrence?: number;
}
