import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import GavelIcon from "@mui/icons-material/Gavel";
import { TableRowSelection } from "antd/lib/table/interface";
import { useModal } from "presentation/designSystem/Modal/useModal";
import {
  GetRemoteDataClb,
  RemoteTable
} from "presentation/designSystem/Table/RemoteTable";
import { ColumnType } from "presentation/designSystem/Table/Table";
import {
  createColumn,
  createDateTimeColumn
} from "presentation/designSystem/Table/column/column";
import { Notification } from "presentation/designSystem/notification/Notification";
import { actionShowDetail } from "presentation/share/components/table/actionFactory";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { RetentionProtocolOrProposalOrErased } from "../../../../../../../domain/struct/recordRetention/RetentionProtocolOrProposalOrErased";
import { StoredDocument } from "../../../../../../../domain/struct/repository/StoredDocument";
import { DateTimeFormat } from "../../../../../../../lib/dateTime";
import { DA_ALLOWED_EXTENSIONS } from "../../../../../../core/api/daimport/constants";
import { ComponentUpload } from "../../../../../../core/components/dialog/tabs/components/ComponentUpload";
import { BreadcrumbForTable } from "../../../../../../designSystem/Breadcrumb/Breadcrumb";
import { RootStateType } from "../../../../../../reducers";
import { useMutation } from "../../../../../../share/hook/query/useMutation";
import { getErrorCodeTranslation } from "../../../../../../share/utils/errorCodeTranslation";
import { translationPath } from "../../../../../../share/utils/getPath";
import {
  UserRepositoryRights,
  getUserRepositoryRights
} from "../../../../../../share/utils/user";
import { lang, t } from "../../../../../../translation/i18n";
import { ErrorType } from "../../../../../../types";
import { DetailsOfRetentionProtocolModal } from "../../../../modal/DetailsOfRetentionProtocolModal";
import { PerformDeletionModal } from "../../../../modal/PerformDeletionModal";
import { useFindRetentionProtocols } from "../hooks/useFindRetentionProtocols";
import { useUploadAcceptanceFromDA } from "../hooks/useUploadAcceptanceFromDA";

const COLUMNS: ColumnType<RetentionProtocolOrProposalOrErased>[] = [
  createColumn({
    dataIndex: "pid",
    title: t(translationPath(lang.general.identifier))
  }),
  createColumn({
    dataIndex: "name",
    title: t(translationPath(lang.general.name))
  }),
  createDateTimeColumn(
    {
      dataIndex: "createdDate",
      title: t(translationPath(lang.general.createdDateAndTime))
    },
    DateTimeFormat.FullDateTime
  ),
  createColumn({
    dataIndex: "shreddingObjectsCount",
    title: t(translationPath(lang.general.shreddingObjectsCount))
  }),
  createDateTimeColumn(
    {
      dataIndex: "ShreddingOperationDate",
      title: t(translationPath(lang.general.shreddingOperationDateTime)),
      defaultSortOrder: "descend",
      sortDirections: ["descend", "ascend", "descend"],
      sorter: true
    },
    DateTimeFormat.FullDateTime
  ),
  createColumn({
    dataIndex: "reviewerFullName",
    title: t(translationPath(lang.general.reviewerFullName))
  }),
  createColumn({
    dataIndex: "authorFullName",
    title: t(translationPath(lang.general.authorFullName))
  })
];

export const RetentionProtocolsTable = React.memo(() => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);

  const dispatch = useDispatch();
  const session = useSelector(
    (state: RootStateType) => state.loginReducer.session
  );
  const findRetentionProposal = useFindRetentionProtocols();

  const [selectNodeId, setNodeId] = useState<string | undefined>(undefined);

  const fileRef = useRef<HTMLInputElement>(null);

  const uploadFile = useUploadAcceptanceFromDA();

  const [modal, modalApi] = useModal(DetailsOfRetentionProtocolModal);

  const [performDeletionModal, performDeletionModalApi] =
    useModal(PerformDeletionModal);

  const { mutate: uploadAcceptanceFromDAMutation } = useMutation(
    (file: File) => uploadFile(selectNodeId!, file!),
    {
      onError(error: ErrorType) {
        if (error.message === "Failed to fetch") {
          Notification.error({
            message: t(translationPath(lang.dialog.notifications.actionFailed))
          });
          return;
        }
        if (error?.messages) {
          error.messages.map((error: string) =>
            Notification.error({
              message: getErrorCodeTranslation(error)
            })
          );
        } else if (error.code) {
          return Notification.error({
            message: getErrorCodeTranslation(error.code)
          });
        } else if (error.message) {
          return Notification.error({
            message: error.message
          });
        }
        Notification.error({
          message: t(
            translationPath(
              lang.dialog.notifications.notPossibleToLoadAcceptanceFromDA
            )
          )
        });
      },
      onSuccess() {
        Notification.success({
          message: t(
            translationPath(
              lang.dialog.notifications.componentUploadWasSuccessful
            )
          )
        });
      },
      onErrorNotification: null,
      onSuccessNotification: null
    }
  );

  const handleUploadComponent = useCallback(
    async (files: globalThis.File[]) => {
      await uploadAcceptanceFromDAMutation(files[0]!);
    },
    [selectNodeId]
  );

  const ACTIONS = useMemo(
    () => ({
      single: [
        actionShowDetail({
          action(selected: StoredDocument[]) {
            const selectedNodeId = selected[0]?.nodeId;

            if (selectedNodeId) {
              modalApi.open({
                nodeId: selectedNodeId,
                dialogType: "protocol"
              });
            }
          }
        }),

        {
          key: "load-acceptance-from-da",
          icon: (
            <ComponentUpload
              ref={fileRef}
              allowedExtensions={DA_ALLOWED_EXTENSIONS}
              multipleFilesAllowed={false}
              handleUploadComponent={handleUploadComponent}
              title={t(translationPath(lang.general.loadAcceptanceFromDA))}
              icon={<GavelIcon />}
            />
          ),
          tooltip: "",
          action(selected: RetentionProtocolOrProposalOrErased[]) {
            setNodeId(selected[0]?.nodeId);
            fileRef.current?.click();
          }
        },
        {
          key: "generate-sip-for-archiving",
          icon: <FileCopyIcon />,
          tooltip: t(translationPath(lang.general.generateSIPforArchiving)),
          action(selected: RetentionProtocolOrProposalOrErased[]) {}
        },
        {
          key: "delete",
          icon: <DeleteForeverIcon />,
          tooltip: t(translationPath(lang.general.delete)),
          action(selected: RetentionProtocolOrProposalOrErased[]) {
            setSelectedRowKeys([]);
            return performDeletionModalApi.open({
              selectedNodeId: selected[0].nodeId || ""
            });
          },
          canBeDisplayed: () => {
            const rights = getUserRepositoryRights(
              session.activeGroup!,
              session.myGroups
            );
            return rights === UserRepositoryRights.Full;
          }
        }
      ]
    }),
    [
      handleUploadComponent,
      modalApi,
      performDeletionModalApi,
      session.activeGroup,
      session.myGroups
    ]
  );

  const getRemoteData: GetRemoteDataClb<RetentionProtocolOrProposalOrErased> =
    useCallback(
      ({ pagination, sorting }) => findRetentionProposal(pagination, sorting),
      [findRetentionProposal]
    );

  const onRowSelection: TableRowSelection<RetentionProtocolOrProposalOrErased> =
    {
      onChange: (selectedRowKeys) => {
        setSelectedRowKeys(selectedRowKeys);
      },
      selectedRowKeys
    };

  return (
    <>
      {modal}
      {performDeletionModal}
      <BreadcrumbForTable
        title={"retentionBeforeDeletion"}
      ></BreadcrumbForTable>
      <TableStyled>
        <RemoteTable<RetentionProtocolOrProposalOrErased>
          name="retention/RetentionProtocols"
          rowKey="pid"
          actions={ACTIONS}
          columns={COLUMNS}
          getRemoteData={getRemoteData}
          rowSelection={onRowSelection}
        />
      </TableStyled>
    </>
  );
});

const TableStyled = styled("div")`
  div > .ant-row {
    height: 50px;
  }
`;
