import {
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup
} from "@mui/material";
import clsx from "clsx";
import { Form, useField, useFormikContext } from "formik";
import { TextField } from "formik-mui";
import {
  StyledFieldWide,
  useStyles
} from "presentation/core/components/dialog/Dialog.styles";
import { FormState } from "presentation/core/components/reactiveFormik/_types";
import {
  lastPathMember,
  translationPath
} from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import React, { useState } from "react";
import { BaseField } from "../../../../core/components/form/fields/BaseField";
import {
  CreateOrganizationUnitFormValues,
  CreateOrganizationUnitFormValuesProxy,
  RadioTypes
} from "./_types";

export const CreateOrganizationUnitForm = React.memo(
  ({ values, initialValues }: FormState<CreateOrganizationUnitFormValues>) => {
    const dialogClasses = useStyles();
    const { setFieldValue } = useFormikContext();
    const [formState, setFormState] =
      useState<CreateOrganizationUnitFormValues>({
        id: initialValues.id,
        name: initialValues.name,
        type: initialValues.type
      });
    const radioGroupName = lastPathMember(
      CreateOrganizationUnitFormValuesProxy.type
    ).path;
    const [radioField] = useField(radioGroupName);

    const onRadioFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setFormState({
        id: formState.id,
        name: formState.name,
        type: e.target.value as string
      });
      setFieldValue(radioField.name, e.target.value);
    };
    return (
      <Form className={dialogClasses.form}>
        <StyledFieldWide
          component={TextField}
          data-test-id="create-organizationl-unit-name"
          name={lastPathMember(CreateOrganizationUnitFormValuesProxy.name).path}
          required={true}
          type="text"
          label={t(translationPath(lang.general.name))}
        />
        <StyledFieldWide
          component={TextField}
          data-test-id="create-organizationl-unit-id"
          name={lastPathMember(CreateOrganizationUnitFormValuesProxy.id).path}
          required={true}
          type="text"
          label={t(translationPath(lang.general.id))}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {t(translationPath(lang.general.groupPrefix))}
              </InputAdornment>
            )
          }}
        />
        <BaseField
          className={clsx(dialogClasses.fullWidth, dialogClasses.mtGap)}
          component={RadioGroup}
          name={radioField}
          value={formState.type}
          onChange={onRadioFieldChange}
        >
          <FormControlLabel
            className={dialogClasses.radioAlignLeft}
            control={<Radio />}
            label={t(translationPath(lang.general.unclassified))}
            value={RadioTypes.unclassified}
            labelPlacement="start"
          />

          <FormControlLabel
            className={dialogClasses.radioAlignLeft}
            control={<Radio />}
            label={t(translationPath(lang.general.isDispatchBig))}
            value={RadioTypes.isDispatch}
            labelPlacement="start"
          />

          <FormControlLabel
            className={dialogClasses.radioAlignLeft}
            control={<Radio />}
            label={t(translationPath(lang.general.isRepositoryBig))}
            value={RadioTypes.isRepository}
            labelPlacement="start"
          />
        </BaseField>
      </Form>
    );
  }
);
