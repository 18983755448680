import React, { useCallback } from "react";
import { HistoryTable, HistoryTableProps } from "../../history/HistoryTable";
import { useShipmentHistory } from "./useShipmentHistory";
import { ShipmentId } from "../../../../../domain/struct/shipment/Shipment";

export interface ShipmentHistoryTableProps
  extends Pick<HistoryTableProps, "onRemoteTableApi"> {
  id: ShipmentId;
}

export const ShipmentHistoryTable: React.FC<ShipmentHistoryTableProps> = React.memo(
  ({ id, onRemoteTableApi }) => {
    const findHistory = useShipmentHistory(id);

    const getRemoteData: HistoryTableProps["getRemoteData"] = useCallback(
      ({ pagination, sorting }) => findHistory(pagination, sorting),
      [findHistory]
    );

    return (
      <HistoryTable
        id={id}
        getRemoteData={getRemoteData}
        onRemoteTableApi={onRemoteTableApi}
      />
    );
  }
);
