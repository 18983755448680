import { ContactListHelpers } from "presentation/modules/nameRegister/service/ContactListHelpers";

import { AddressFormatHelpers } from "presentation/modules/nameRegister/service/AddressFormatHelpers";
import { AddressListHelpers } from "presentation/modules/nameRegister/service/AddressListHelpers";

export const nameRegisterModuleProviders = [
  ContactListHelpers,

  AddressListHelpers,
  AddressFormatHelpers
];
