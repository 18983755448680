import { PerformDeletionToken } from "../../domain/di/token/recordRetention";
import type { PerformDeletionRepository } from "../../domain/service/recordRetention/PerformDeletionRepository";
import {
  DInject,
  DInjectable
} from "../../presentation/core/features/dependencyInjection";

@DInjectable()
export class SavePerformDeletion {
  constructor(
    @DInject(PerformDeletionToken)
    protected repository: PerformDeletionRepository
  ) {}

  savePerformDeletion(id: string) {
    return this.repository.savePerformDeletion(id);
  }
}
