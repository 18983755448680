import { getService } from "presentation/core/features/dependencyInjection";
import { PaginationConfig } from "lib/contract/Pagination";
import { useCallback } from "react";
import { SortingConfig } from "lib/contract/Sorting";
import { FindRetentionProtocols } from "../../../../../../../useCase/recordRetention/FindRetentionProtocols";

export const useFindRetentionProtocols = () => {
  const findRetentionProtocols = getService(FindRetentionProtocols);

  return useCallback(
    (config: PaginationConfig, sorting?: SortingConfig) =>
      findRetentionProtocols.findAllPaginated(config, sorting),
    [findRetentionProtocols]
  );
};
