import React, { useEffect } from "react";
import { Form, Item, useForm } from "presentation/designSystem/Form/v2/Form";
import { Input } from "presentation/designSystem/Input/Input";
import { useFindRetentionProposalById } from "../../hooks/useFindRetentionProposal";
import { t } from "../../../../translation/i18n";
import { translationPath } from "../../../../share/utils/getPath";
import lang from "../../../../translation/lang";
import { DateTimeFormat, formatDate } from "../../../../../lib/dateTime";
import { BaseProtocolOrProposalForm } from "./fields/BaseProtocolOrProposalForm";
import { RetentionProtocolOrProposalOrErased } from "../../../../../domain/struct/recordRetention/RetentionProtocolOrProposalOrErased";

interface RetentionProposalMetadataFormProps {
  id: string;
}
export const RetentionProposalMetadataForm = ({
  id
}: RetentionProposalMetadataFormProps) => {
  const [form] = useForm<RetentionProtocolOrProposalOrErased>();
  const { data } = useFindRetentionProposalById(id);

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        ...data,
        createdAt: formatDate(data.createdAt, DateTimeFormat.FullDateTime)
      });
    }
  }, [data, form]);

  return (
    <Form form={form} name="retentionProposal">
      <BaseProtocolOrProposalForm data={data} form={form} />
      <Item
        name="name"
        label={t(
          translationPath(
            lang.dialog.form.ShowDetailsOfRetentionProposalOrProtocolForm.name
          )
        )}
      >
        <Input disabled={true} size={"large"} />
      </Item>
    </Form>
  );
};
