import { DocumentConcernedSubjectRepositoryToken } from "../../../domain/di/token/document";
import type { DocumentConcernedSubjectRepository } from "../../../domain/service/document/DocumentConcernedSubjectRepository";
import { PaginationConfig } from "../../../lib/contract/Pagination";
import {
  DInject,
  DInjectable
} from "../../../presentation/core/features/dependencyInjection";

@DInjectable()
export class FindConcernedSubject {
  constructor(
    @DInject(DocumentConcernedSubjectRepositoryToken)
    protected repository: DocumentConcernedSubjectRepository
  ) {}

  findPaginated(nodeId: string, config: PaginationConfig) {
    return this.repository.findPaginated(nodeId, config);
  }
}
