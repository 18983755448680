import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import { Logout } from "../../../useCase/authentication/Logout";
import { useMutation } from "../../share/hook/query/useMutation";
import { getService } from "../features/dependencyInjection";
import { logoutAction } from "../features/logout/_actions";
import { CoreRoutes } from "../routes";

export const useUserLogout = () => {
  const dispatch = useDispatch();
  const logoutUser = getService(Logout);

  const removeCacheBeforeLogout = () => {
    dispatch(logoutAction.success());
    dispatch(push(CoreRoutes.LOGIN) as any);
  };

  return useMutation(() => logoutUser.logout(), {
    onSettled() {
      removeCacheBeforeLogout();
    },
    onErrorNotification: null,
    onSuccessNotification: null
  });
};
