import React, { useState } from "react";
import VersionTab from "../VersionTab";
import { useGetVersions } from "./useGetVersions";

interface UseVersionTabState {
  pageNumber: number;
  rowsPerPage: number;
}

const initialState: UseVersionTabState = {
  pageNumber: 0,
  rowsPerPage: 50
};

export interface UseVersionTabContextProviderProps {
  nodeId: string;
  isActive: boolean;
}

export type UseVersionTabContainerResult = [
  JSX.Element,
  {
    fetchVersion(): void;
  }
];

export const useVersionTab = ({
  nodeId,
  isActive
}: UseVersionTabContextProviderProps): UseVersionTabContainerResult => {
  const [state, setState] = useState<UseVersionTabState>(initialState);

  const { data, refetch, isFetching } = useGetVersions(
    nodeId,
    {
      page: state.pageNumber ? state.pageNumber : 1,
      itemsPerPage: state.rowsPerPage ? state.rowsPerPage : 10
    },
    isActive
  );

  const handleChangePage: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => void = (_, page) => {
    setState((state) => ({
      pageNumber: page,
      rowsPerPage: state.rowsPerPage
    }));
  };

  const handleChangeRowsPerPage: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void = (event) => {
    setState(() => ({
      pageNumber: 0,
      rowsPerPage: parseInt(event.target.value, 10)
    }));
  };

  const versionTabEl = (
    <VersionTab
      items={data?.items || []}
      {...state}
      totalItems={data?.items.length || 0}
      refreshTable={refetch}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      isLoading={isFetching}
    />
  );

  return [
    versionTabEl,
    {
      fetchVersion: refetch
    }
  ];
};
