import { getService } from "../../../../core/features/dependencyInjection";
import { useCallback } from "react";
import { PaginationConfig } from "lib/contract/Pagination";
import { FindConceptHistory } from "useCase/concept/FindConceptHistory";
import { ConceptId } from "domain/struct/concept/Concept";
import { SortingConfig } from "lib/contract/Sorting";

export const useConceptHistory = (id: ConceptId) => {
  const findHistory = getService(FindConceptHistory);

  return useCallback(
    (paginationConfig: PaginationConfig, sortingConfig?: SortingConfig) =>
      findHistory.findPaginated(id, paginationConfig, sortingConfig),
    [findHistory, id]
  );
};
