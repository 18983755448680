import DataTable from "presentation/core/components/dataTable";
import {
  DataColumn,
  ValueType
} from "presentation/core/components/dataTable/_types";
import { useStyles } from "presentation/core/components/dialog/Dialog.styles";
import { DataTableValues } from "presentation/core/components/documentView/_types";
import { GenericDocument } from "presentation/core/types";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { File } from "../../../../entities";
import { DialogDataProps } from "../../_types";
import { generateColumns } from "./columns";
import { controls, readOnlyControls } from "./controls";
import { findMainFile } from "./methods";
import { SelectedComponentsFnType } from "./_types";

export type ChangePageFnType = (
  event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
  page: number
) => void;

export type ChangeRowsPerPageFnType = (
  event: React.ChangeEvent<HTMLInputElement>
) => void;

export type SortChangeFnType = (
  index: number,
  keys: string[]
) => (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void;

interface OwnProps {
  handleChangePage: ChangePageFnType;
  handleChangeRowsPerPage: ChangeRowsPerPageFnType;
  handleColumnChange: (
    row: File,
    column: DataColumn<File>,
    value: ValueType
  ) => void;
  handleCanConvertToOutputFormat: (component: File) => boolean;
  handleCanCreateComponent: () => boolean;
  handleCanDeleteComponent: () => boolean;
  handleCanRenameComponent: () => boolean;
  handleCanShowPreview: (component: File) => boolean;
  handleCanUploadNewVersion: (component: File) => boolean;
  handleConvertToOutputFormat: SelectedComponentsFnType;
  handleDeleteComponent?: SelectedComponentsFnType;
  handleDownloadComponent: SelectedComponentsFnType;
  handleRenameComponent: SelectedComponentsFnType;
  handleSelectionChange: SelectedComponentsFnType;
  handleShowPreview: SelectedComponentsFnType;
  handleSwapComponentContent: (
    components: File[],
    files: globalThis.File[]
  ) => void;
  handleSortingChange: SortChangeFnType;
  handleUploadComponent: (files: globalThis.File[]) => void;
  handleShowDeactivateComponent?(): void;
  handleDeactivateComponent: SelectedComponentsFnType;
  handleCanShowDeactivateComponent: () => boolean;
  handleValidateCanShowDeactivateComponent: () => Promise<string>;
  handleCanDeactivateComponent: (component: File) => boolean;
  handleOpenPopUpPassword: (file: File) => void;
  isLoading?: boolean;
  isReadonly?: boolean;
  items: File[];
  pageNumber: number;
  refreshTable: VoidFunction;
  rowsPerPage: number;
  sortAsc?: boolean;
  sortColumnIndex?: number | null;
  totalItems: number;
  dialogProps?: DialogDataProps;
}

const ComponentsTab = ({
  handleCanConvertToOutputFormat,
  handleCanCreateComponent,
  handleCanDeleteComponent,
  handleCanRenameComponent,
  handleCanShowPreview,
  handleCanUploadNewVersion,
  handleChangePage,
  handleChangeRowsPerPage,
  handleColumnChange,
  handleConvertToOutputFormat,
  handleDeleteComponent,
  handleDownloadComponent,
  handleRenameComponent,
  handleSelectionChange,
  handleShowPreview,
  handleSortingChange,
  handleSwapComponentContent,
  handleUploadComponent,
  handleShowDeactivateComponent,
  handleCanShowDeactivateComponent,
  handleCanDeactivateComponent,
  handleDeactivateComponent,
  handleValidateCanShowDeactivateComponent,
  handleOpenPopUpPassword,
  isLoading = false,
  isReadonly,
  items,
  pageNumber,
  refreshTable,
  rowsPerPage,
  sortAsc,
  sortColumnIndex,
  totalItems,
  dialogProps
}: OwnProps) => {
  const dataTableValues: DataTableValues = useMemo(() => {
    return { resetIcons: true };
  }, []);

  useTranslation();
  const dialogClasses = useStyles();

  const cols = useMemo(() => {
    return generateColumns(findMainFile(items), handleOpenPopUpPassword);
  }, [handleOpenPopUpPassword, items]);

  const getIsRowReadOnly = (row: File) => {
    const document = dialogProps?.data as GenericDocument;
    const signedDocument = document?.properties?.ssl?.isSign;
    const sealedDocument = document?.properties?.ssl?.isSealed;

    return row.type === "main" && Boolean(sealedDocument || signedDocument);
  };

  return (
    <>
      <div className="body-fullsize">
        <DataTable
          controls={
            !isReadonly
              ? controls({
                  handleCanConvertToOutputFormat,
                  handleCanCreateComponent,
                  handleCanDeleteComponent,
                  handleCanRenameComponent,
                  handleCanShowPreview,
                  handleCanUploadNewVersion,
                  handleConvertToOutputFormat,
                  handleDeleteComponent,
                  handleDownloadComponent,
                  handleRenameComponent,
                  handleShowPreview,
                  handleSwapComponentContent,
                  handleUploadComponent,
                  handleShowDeactivateComponent,
                  handleDeactivateComponent,
                  handleCanDeactivateComponent,
                  handleCanShowDeactivateComponent,
                  handleValidateCanShowDeactivateComponent
                })
              : readOnlyControls({
                  handleCanShowPreview,
                  handleDownloadComponent,
                  handleShowPreview,
                  handleShowDeactivateComponent,
                  handleCanShowDeactivateComponent,
                  handleValidateCanShowDeactivateComponent
                })
          }
          breadcrumbs={[]}
          columns={cols}
          dataTableValues={dataTableValues}
          defaultActionFirst={true}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleColumnChange={handleColumnChange}
          handleSelectionChange={handleSelectionChange}
          handleSortingChange={handleSortingChange}
          pageNumber={pageNumber}
          paginationClassName={dialogClasses.tablePagination}
          pending={isLoading}
          readonly={!!isReadonly}
          refreshTable={refreshTable}
          rows={items}
          rowsCount={totalItems}
          rowsPerPage={rowsPerPage}
          sortAsc={sortAsc}
          sortColumnIndex={sortColumnIndex}
          tableLayoutClassName="dialog__table-layout"
          isRowReadOnly={getIsRowReadOnly}
        />
      </div>
    </>
  );
};

export default React.memo(ComponentsTab);
