import {
  DInject,
  DInjectable
} from "presentation/core/features/dependencyInjection";
import { RetentionProposalRemoveObjectsRepositoryToken } from "../../domain/di/token/recordRetention";
import type { RetentionProposalRemoveObjectsRepository } from "../../domain/service/recordRetention/RetentionProposalRemoveObjectsRepository";
import { RetentionProposalRemoveObjectsId } from "../../domain/struct/recordRetention/RetentionProposalRemoveObjects";

@DInjectable()
export class RemoveRetentionProposalObjects {
  constructor(
    @DInject(RetentionProposalRemoveObjectsRepositoryToken)
    protected repository: RetentionProposalRemoveObjectsRepository
  ) {}
  removeObject(
    nodeId: string,
    objectIds: RetentionProposalRemoveObjectsId[],
    reason: string
  ) {
    return this.repository.removeRetentionProposalObjects(
      nodeId,
      objectIds,
      reason
    );
  }
}
