import { DocumentConcernedSubjectRepository } from "../../../domain/service/document/DocumentConcernedSubjectRepository";
import {
  PaginatedResult,
  PaginationConfig
} from "../../../lib/contract/Pagination";
import { DocumentConcernedSubject } from "../../../domain/struct/document/DocumentConcernedSubject";
import {
  DInject,
  DInjectable
} from "../../../presentation/core/features/dependencyInjection";
import { HttpClient, HttpMethod } from "../../service/HttpClient";
import { ApiUrls } from "../struct/ApiTypes";
import {
  ApiDocumentConcernedSubject,
  DocumentConcernedSubjectApiMapper
} from "./DocumentConcernedSubjectApiMapper";
import { SubjectId } from "../../../domain/struct/nameRegistry/Subject";

const URL: ApiUrls = {
  FindAll: "/api/app/v1/document/{nodeId}/concerned-subjects",
  AddConcernedSubjects: "/api/app/v1/document/{nodeId}/concerned-subject/add",
  RemoveConcernedSubject:
    "/api/app/v1/document/{nodeId}/concerned-subject/{subjectId}/remove"
};

@DInjectable()
export class DocumentConcernedSubjectApiRepository
  implements DocumentConcernedSubjectRepository {
  constructor(@DInject(HttpClient) protected httpClient: HttpClient) {}

  findPaginated(
    nodeId: string,
    config: PaginationConfig
  ): Promise<PaginatedResult<DocumentConcernedSubject>> {
    return this.httpClient
      .fetchPaginatedAlfresco<ApiDocumentConcernedSubject>(
        URL.FindAll,
        config,
        {
          urlWildCards: {
            nodeId
          }
        }
      )
      .then(DocumentConcernedSubjectApiMapper.mapFindAll);
  }

  addConcernedSubjects(nodeId: string, subjectIds: SubjectId[]): Promise<void> {
    return this.httpClient.fetchWithThrow(
      URL.AddConcernedSubjects,
      HttpMethod.Post,
      {
        urlWildCards: {
          nodeId
        },
        bodyJSON: {
          subjectIds
        }
      }
    );
  }

  removeConcernedSubjects(
    nodeId: string,
    subjectIds: SubjectId[]
  ): Promise<void> {
    return Promise.all(
      subjectIds.map((subjectId) =>
        this.removeConcernedSubject(nodeId, subjectId)
      )
    ).then(() => undefined);
  }

  removeConcernedSubject(nodeId: string, subjectId: SubjectId): Promise<void> {
    return this.httpClient.fetchWithThrow(
      URL.RemoveConcernedSubject,
      HttpMethod.Delete,
      {
        urlWildCards: {
          nodeId,
          subjectId
        }
      }
    );
  }
}
