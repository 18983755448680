import { GroupMember } from "presentation/core/api/models";
import { Group } from "presentation/core/entities";
import { createProxy } from "presentation/share/utils/getPath";

export type BorrowFormValues = {
  group: Group["id"];
  user: GroupMember["id"];
};

export const BorrowFormValuesProxy = createProxy<BorrowFormValues>();
