import React, { useMemo } from "react";
import { StyledIconBox } from "../../../core/features/dashboard/Dashboard.styles";

interface PDFLicenceProps {
  fileName: string;
  icon?: React.ReactNode;
  linkName?: string;
}

export const PDFLoader: React.FC<PDFLicenceProps> = ({
  fileName,
  icon,
  linkName
}) => {
  const downloadUrl = useMemo(() => {
    const {
      location: { protocol, host }
    } = window || {};
    return `${protocol}//${host}/${fileName}`;
  }, [fileName]);

  return (
    <StyledIconBox>
      <a href={downloadUrl} download={fileName}>
        {icon}
      </a>
      <span>{linkName}</span>
    </StyledIconBox>
  );
};
