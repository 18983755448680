import {
  DInject,
  DInjectable
} from "presentation/core/features/dependencyInjection";
import { ErasedDocumentRepositoryToken } from "../../domain/di/token/recordRetention";
import type { ErasedDocumentRepository } from "../../domain/service/recordRetention/ErasedDocumentRepository";
import { ErasedDocumentId } from "../../domain/struct/recordRetention/ErasedDocument";

@DInjectable()
export class FindErasedDocument {
  constructor(
    @DInject(ErasedDocumentRepositoryToken)
    protected repository: ErasedDocumentRepository
  ) {}

  findErasedDocumentById(id: ErasedDocumentId) {
    return this.repository.findErasedDocumentById(id);
  }
}
