import { DInjectionToken } from "../../../presentation/core/features/dependencyInjection";
import { RentedDocumentRepository } from "../../service/repository/RentedDocumentRepository";
import { StoredDocumentRepository } from "../../service/repository/StoredDocumentRepository";
import { StoredFilesRepository } from "domain/service/repository/StoredFilesRepository";

export const RentedDocumentRepositoryToken = new DInjectionToken<RentedDocumentRepository>(
  "domain.document.rented-document-repository"
);
export const StoredFilesRepositoryToken = new DInjectionToken<StoredFilesRepository>(
  "domain.repository.StoredFiles-repository"
);

export const StoredDocumentRepositoryToken = new DInjectionToken<StoredDocumentRepository>(
  "domain.repository.stored-documents-repository"
);
