import { callAsyncAction } from "presentation/core/action";
import { databoxDontRegisterActionType } from "presentation/core/api/databox/_actions";
import { emailDontRegisterActionType } from "presentation/core/api/email/_actions";
import { secondaryAction } from "presentation/core/components/dialog/lib/actionsFactory";
import {
  DialogContentType,
  DialogType
} from "presentation/core/components/dialog/_types";
import { documentViewAction__Refresh } from "presentation/core/components/documentView/_actions";
import NamedTitle from "presentation/core/components/namedTitle";
import { GenericDocument } from "presentation/core/types";
import { ModalSize } from "presentation/designSystem/Modal/Modal";
import { SpisumNodeTypes } from "presentation/enums";
import { getErrorCodeTranslation } from "presentation/share/utils/errorCodeTranslation";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import { ErrorType } from "presentation/types";
import React from "react";
import { DontRegisterDocumentContentDialog } from "./DontRegisterDocumentContentDialog";
import { DontRegisterDocumentFormValues } from "./_types";

export const dontRegisterDocumentDialogContent: DialogContentType = {
  actions: () => [
    secondaryAction(
      t(translationPath(lang.dialog.form.confirm)),
      ({ dispatch, channels, dialogProps, onClose, buttonState }) => {
        const onSuccess = () => {
          onClose();
          dispatch(documentViewAction__Refresh(true));
          dialogProps.onSuccess?.();
        };

        const onError = (error: ErrorType) => {
          buttonState.setIsPending(false);
          dialogProps.onError?.();
        };

        const onErrorNotification = (error: ErrorType) => ({
          message: error.code
            ? getErrorCodeTranslation(error.code)
            : Array.isArray(error.messages) && error.messages.length > 0
            ? error.messages[0]
            : t(translationPath(lang.dialog.notifications.actionFailed))
        });

        const { nodeType, id } = dialogProps.data as GenericDocument;

        const action =
          nodeType === SpisumNodeTypes.Email
            ? emailDontRegisterActionType
            : nodeType === SpisumNodeTypes.Databox
            ? databoxDontRegisterActionType
            : undefined;

        if (!action) {
          return;
        }

        buttonState.setIsPending(true);

        dispatch(
          callAsyncAction({
            action,
            onError,
            onErrorNotification,
            onSuccess,
            payload: {
              body: {
                reason: (channels.contentTab?.state
                  ?.formValues as DontRegisterDocumentFormValues).reason
              },
              nodeId: id
            }
          })
        );
      }
    )
  ],
  content: DontRegisterDocumentContentDialog,
  title: (props) => (
    <NamedTitle
      text={t(translationPath(lang.dialog.title.dontRegister))}
      {...props}
    />
  ),
  type: DialogType.DontRegisterDocument,
  size: ModalSize.Small
};
