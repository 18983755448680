import {
  AddCircleOutlineOutlined,
  Delete,
  Description,
  Publish,
  Send
} from "@mui/icons-material";
import {
  ControlsBarType,
  DataColumn
} from "presentation/core/components/dataTable/_types";
import { dialogOpenAction } from "presentation/core/components/dialog/_actions";
import { DialogType } from "presentation/core/components/dialog/_types";
import DocumentView from "presentation/core/components/documentView";
import MenuLayout from "presentation/core/components/layout/MenuLayout";
import { SessionType } from "presentation/core/features/login/_types";
import { GenericDocument, genericDocumentProxy } from "presentation/core/types";
import { SitePaths, SpisumNodeTypes } from "presentation/enums";
import { RootStateType } from "presentation/reducers";
import { handoverDocument } from "presentation/share/components/dialog/documentHandoverDialog/_actions";
import { evidenceCancelDialogOpen } from "presentation/share/components/dialog/evidenceCancelDialog/_actions";
import { classPath, translationPath } from "presentation/share/utils/getPath";
import { getRelativePath } from "presentation/share/utils/query";
import { isUserInLeadership } from "presentation/share/utils/user";
import { lang, t, withTranslation } from "presentation/translation/i18n";
import { useDispatch, useSelector } from "react-redux";
import { metaFormAction__Update } from "../../../../core/components/MetaForm/_actions";
import { useCreateConcept } from "../../../../share/components/dialog/createConceptDialog/hooks/useCreateConcept";
import { useDetailConcept } from "../../../../share/components/dialog/updateConceptDialog/hooks/useDetailConcept";
import React from "react";

const defaultColumn: DataColumn<GenericDocument> = {
  isDateTime: true,
  keys: [classPath(genericDocumentProxy.createdAt).path],
  label: t(translationPath(lang.general.dateOfCreation))
};

const getColumns = (session: SessionType): DataColumn<GenericDocument>[] => {
  const columns: DataColumn<GenericDocument>[] = [
    {
      keys: [classPath(genericDocumentProxy.properties!.ssl!.pid).path],
      label: t(translationPath(lang.general.identifier))
    },
    {
      keys: [classPath(genericDocumentProxy.properties!.ssl!.subject).path],
      label: t(translationPath(lang.general.subject))
    },
    defaultColumn
  ];

  if (isUserInLeadership(session)) {
    columns.push({
      keys: [
        classPath(genericDocumentProxy.properties!.cm!.owner?.displayName).path
      ],
      label: t(translationPath(lang.general.owner))
    });
  }

  return columns;
};

const EvidenceConcepts = () => {
  const dispatch = useDispatch();
  const { modal, register } = useCreateConcept();
  const { modalUpdate, registerUpdate } = useDetailConcept();
  const session = useSelector(
    (state: RootStateType) => state.loginReducer.session
  );
  const relativePath = useSelector((state: RootStateType) =>
    getRelativePath(
      state.loginReducer.global.paths,
      state.loginReducer.session.activeGroup,
      SitePaths.Evidence,
      SitePaths.Concepts
    )
  );

  const dispatchOpenDialog: (row: GenericDocument) => void = (row) => {
    dispatch(
      metaFormAction__Update({
        formValues: row?.properties?.ssl
      })
    );
    registerUpdate(row);
  };

  const controls: ControlsBarType<GenericDocument> = {
    default: {
      items: [
        {
          action: (selected: GenericDocument[]) => {
            dispatch(
              metaFormAction__Update({
                formValues: selected[0]?.properties?.ssl
              })
            );
            register(null);
          },
          icon: <AddCircleOutlineOutlined data-test-id="add-concept" />,
          title: t(translationPath(lang.general.addConcept))
        }
      ]
    },
    single: {
      items: [
        {
          action: (selected: GenericDocument[]) => registerUpdate(selected[0]),
          icon: <Description />,
          title: t(translationPath(lang.general.showDetails))
        },
        {
          action: (selected: GenericDocument[]) => {
            dispatch(
              dialogOpenAction({
                dialogProps: { data: selected[0] },
                dialogType: DialogType.PromoteConceptToDocument
              })
            );
          },
          icon: <Publish data-test-id="promote-concept-to-document" />,
          title: t(translationPath(lang.general.toDocument))
        },
        /* V2
        {
          action: () => {},
          icon: <CreateNewFolder />,
          title: t(translationPath(lang.general.pasteToFile))
        },
        */
        {
          action: (selected: GenericDocument[]) => {
            dispatch(handoverDocument({ data: selected[0] }));
          },
          icon: <Send />,
          title: t(translationPath(lang.general.handOVer))
        }
      ],
      more: [
        {
          action: (selected: GenericDocument[]) => {
            dispatch(evidenceCancelDialogOpen({ data: selected[0] }));
          },
          icon: <Delete />,
          title: t(translationPath(lang.general.cancel))
        }
      ]
    }
  };

  return (
    <>
      {modal}
      {modalUpdate}
      <MenuLayout>
        <DocumentView
          children={{
            relativePath,
            where: `(nodeType='${SpisumNodeTypes.Concept}')`
          }}
          columns={getColumns(session)}
          controls={controls}
          customTitle={t(translationPath(lang.table.concepts))}
          defaultSortAsc={true}
          defaultSortColumn={defaultColumn}
          handleDoubleClick={dispatchOpenDialog}
        />
      </MenuLayout>
    </>
  );
};

export default withTranslation()(EvidenceConcepts);
