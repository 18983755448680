import { getService } from "../../../../core/features/dependencyInjection";
import { FindGroup } from "../../../../../useCase/user/FindGroup";
import { useQM } from "../../../hook/query/useQM";
import { UserId } from "../../../../../domain/struct/user/User";

const KEY = "user/groups";

export const useUserGroups = (userId?: UserId) => {
  const findGroup = getService(FindGroup);

  return useQM(
    [KEY, userId],
    () => {
      if (!userId) {
        return Promise.resolve({
          groups: [],
          signGroups: []
        });
      }

      return findGroup.getUserGroups(userId);
    },
    {
      cacheTime: 0
    }
  );
};
