import BrightnessHighIcon from "@mui/icons-material/BrightnessHigh";
import { TableRowSelection } from "antd/lib/table/interface";
import { DateTimeFormat } from "lib/dateTime";
import {
  GetRemoteDataClb,
  RemoteTable
} from "presentation/designSystem/Table/RemoteTable";
import {
  ColumnType,
  useRemoteTableApi
} from "presentation/designSystem/Table/Table";
import {
  createColumn,
  createDateTimeColumn
} from "presentation/designSystem/Table/column/column";
import { RootStateType } from "presentation/reducers";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useDocumentsForSeal } from "../hooks/useDocumentsForSeal";
import { DocumentForSeal } from "../types/ForSeal";

import {
  BaseModalProps,
  useModal
} from "presentation/designSystem/Modal/useModal";
import { useQueryClient } from "react-query";
import { useStyles } from "../../../../../core/components/dialog/Dialog.styles";
import { NodePreviewContainer } from "../../../../../core/features/nodePreview/components/nodePreview/NodePreviewContainer";
import { SendComponentsForSealModal } from "../modal/SendComponentsForSeal/SendComponentsForSealModal";

interface DocumentsForSeal extends BaseModalProps {
  selectedNodeId: string;
  nodeType: string;
}
const COLUMNS: ColumnType<DocumentForSeal>[] = [
  createColumn({
    dataIndex: "fileName",
    title: t(translationPath(lang.general.sealFileName))
  }),
  createColumn({
    dataIndex: "isSign",
    title: t(translationPath(lang.general.isSign)),
    render: (text, row) => {
      return row.isSign
        ? t(translationPath(lang.general.yes))
        : t(translationPath(lang.general.no));
    }
  }),
  createColumn({
    dataIndex: "isSealed",
    title: t(translationPath(lang.general.isSealed)),
    render: (text, row) => {
      return row.isSealed
        ? t(translationPath(lang.general.yes))
        : t(translationPath(lang.general.no));
    }
  }),
  createDateTimeColumn(
    {
      dataIndex: "usedTimeSeal",
      title: t(translationPath(lang.general.usedTime))
    },
    DateTimeFormat.FullDateTime
  )
];

export const DocumentsForSealTable = React.memo(
  ({ selectedNodeId, nodeType }: DocumentsForSeal) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [tableApi, onRemoteTableApi] = useRemoteTableApi();
    const findDocumentsForSeal = useDocumentsForSeal();
    const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
    const [selectedRowData, setSelectedRowData] = useState<any>([]);

    const [modal, modalApi] = useModal(SendComponentsForSealModal, {
      onOk() {
        tableApi.refetch();
      }
    });

    const session = useSelector(
      (state: RootStateType) => state.loginReducer.session
    );

    const ACTIONS = useMemo(
      () => ({
        single: [
          {
            key: "signature-book-for-Sealing",
            icon: <BrightnessHighIcon />,
            tooltip: t(translationPath(lang.general.forComponentSeal)),
            action(selected: DocumentForSeal[]) {
              modalApi.open({
                nodeId: selectedNodeId,
                componentId: selected[0].componentId!
              });
            }
          }
        ]
      }),
      [modalApi, selectedNodeId]
    );
    const onRowSelection: TableRowSelection<DocumentForSeal> = {
      onChange: (selectedRowKeys, selectedRows) => {
        setSelectedRowKeys(selectedRowKeys);
        setSelectedRowData(selectedRows);
      },
      getCheckboxProps: (record) => ({
        disabled: record.canBeSigned ? false : true
      }),
      selectedRowKeys
    };

    const queryClient = useQueryClient();
    useEffect(() => {
      const unsubscribe = queryClient.getQueryCache().subscribe((listener) => {
        const data: any = listener?.query?.state?.data;

        setSelectedRowData(data?.items);
      });

      return () => unsubscribe();
    }, [findDocumentsForSeal, queryClient]);

    const rowData = selectedRowData?.filter(
      (row: any) => row.componentId === selectedRowKeys[0]
    );

    const getRemoteData: GetRemoteDataClb<DocumentForSeal> = useCallback(
      ({ pagination, sorting }) =>
        findDocumentsForSeal(selectedNodeId, pagination, sorting),
      [findDocumentsForSeal, selectedNodeId]
    );

    return (
      <div style={{ display: "flex", width: "100%" }}>
        {modal}
        {selectedRowData && selectedRowData.length > 0 && (
          <StyledPreviewWrapper>
            <NodePreviewContainer
              nodeId={
                rowData.length
                  ? rowData[0].componentId
                  : selectedRowData[0].componentId
              }
              name={
                rowData.length
                  ? rowData[0].fileName
                  : selectedRowData[0].componentId
              }
              nodeType={nodeType}
              componentId={selectedNodeId}
            />
          </StyledPreviewWrapper>
        )}
        <TableStyled
          style={
            selectedRowData && selectedRowData.length
              ? { width: "50%" }
              : { width: "100%" }
          }
        >
          <RemoteTable<DocumentForSeal>
            name="signatureBook/SignatureBookForSeal"
            rowKey="componentId"
            rowSelection={onRowSelection}
            actions={ACTIONS}
            columns={COLUMNS}
            onRemoteTableApi={onRemoteTableApi}
            getRemoteData={getRemoteData}
            rowClassName={(record) => (record.canBeSigned ? "white" : "gray")}
          />
        </TableStyled>
      </div>
    );
  }
);

const TableStyled = styled("div")`
  div > .ant-row {
    height: 50px;
  }
  .white {
    background: #ffff;
  }

  .gray {
    background: #d9d9d921;
  }
`;
const StyledPreviewWrapper = styled("div")(({ theme }) => ({
  borderRight: "1px solid rgba(0, 0, 0, 0.4)",
  marginRight: theme.margin.sm,
  maxWidth: "50%",
  flex: 1
}));
