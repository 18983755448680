import { useQM } from "presentation/share/hook/query/useQM";
import {
  BaseModalProps,
  useModal
} from "presentation/designSystem/Modal/useModal";
import { getEvidenceDocument } from "../api";
import { DocumentDetailsModal } from "../modals/DocumentDetailsModal";

export interface UseGetDocumentDetailsModalProps extends BaseModalProps {
  nodeId: string;
}

export interface UseGetDocumentDetailsModalReturn {
  isDetailsLoading: boolean;
  getDetails(): void;
  detailsModal: false | JSX.Element;
}

export const useGetDocumentDetailsModal = ({
  nodeId,
  ...props
}: UseGetDocumentDetailsModalProps): UseGetDocumentDetailsModalReturn => {
  const [detailsModal, detailsModalAPi] = useModal(DocumentDetailsModal, props);
  const { isLoading: isDetailsLoading, refetch: getDetails } = useQM(
    ["typeFile/detail", nodeId],
    () => getEvidenceDocument(nodeId),
    {
      enabled: false,
      onSuccess: (data) => {
        detailsModalAPi.open({
          document: data
        });
      }
    }
  );

  return {
    isDetailsLoading,
    getDetails,
    detailsModal
  };
};
