import { ConceptConcernedSubjectRepositoryToken } from "../../../domain/di/token/document";
import type { ConceptConcernedSubjectRepository } from "../../../domain/service/document/ConceptConcernedSubjectRepository";
import { PaginationConfig } from "../../../lib/contract/Pagination";
import {
  DInject,
  DInjectable
} from "../../../presentation/core/features/dependencyInjection";

@DInjectable()
export class FindConceptConcernedSubject {
  constructor(
    @DInject(ConceptConcernedSubjectRepositoryToken)
    protected repository: ConceptConcernedSubjectRepository
  ) {}

  findPaginated(conceptId: string, config: PaginationConfig) {
    return this.repository.findPaginated(conceptId, config);
  }
}
