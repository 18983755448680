import { ConfigProvider } from "@isfg/react-components/build/config/ConfigProvider";
import { ThemeConfig } from "antd";
import React, { ReactNode } from "react";
import {
  csCZLocale,
  formValidationMessages,
  isfgComponentsDefaults,
  theme
} from "../../../config/isfgComponents";
import { queryClient } from "./ReactQueryProvider";

interface ISFGComponentsConfigProviderProps {
  children: ReactNode;
}

export const ISFGComponentsConfigProvider: React.FC<
  ISFGComponentsConfigProviderProps
> = ({ children }) => {
  return (
    <ConfigProvider
      locale={csCZLocale}
      form={{ validateMessages: formValidationMessages }}
      theme={
        {
          ...theme,
          token: {
            colorPrimary: theme.color.primary
          }
        } as ThemeConfig
      }
      defaults={isfgComponentsDefaults}
      queryClient={queryClient}
    >
      {children}
    </ConfigProvider>
  );
};
