import { ConfigurationRepository } from "domain/service/configuration/ConfigurationRepository";
import { Configuration } from "domain/struct/configuration/Configuration";
import { ApiUrls } from "infrastructure/api/struct/ApiTypes";
import { HttpClient } from "infrastructure/service/HttpClient";
import {
  DInject,
  DInjectable
} from "presentation/core/features/dependencyInjection";
import {
  ConfigurationApiMapper,
  SigningConfigurationResponse
} from "./ConfigurationApiMapper";

const URL: ApiUrls = { GetConfiguration: "/api/app/v1/config/fe" };

@DInjectable()
export class ConfigurationApiRepository implements ConfigurationRepository {
  constructor(@DInject(HttpClient) protected httpClient: HttpClient) {}

  getConfiguration(): Promise<Configuration> {
    return this.httpClient
      .fetchWithThrow<SigningConfigurationResponse>(URL.GetConfiguration)
      .then(ConfigurationApiMapper.mapToDomain);
  }
}
