import { PaginatedResult } from "./contract/Pagination";

export class PaginationMapper {
  static mapArrayToPagination<T>(arr: T[]): PaginatedResult<T> {
    return {
      items: arr,
      itemsPerPage: arr.length,
      page: 1,
      total: arr.length
    };
  }
}
