import { useMutation } from "presentation/share/hook/query/useQM";
import { HttpClient } from "../../../../../../infrastructure/service/HttpClient";
import { Concept } from "../types";
import { useDispatch } from "react-redux";
import { tableActionViewAction__Refresh } from "../../../../../core/components/dataTable/components/_action";
import { documentViewAction__Refresh } from "../../../../../core/components/documentView/_actions";

interface NewConcept {
  nodeId: string;
  subject: string;
}

const updateConcept = (values: NewConcept): Promise<Concept> => {
  const httpClient = new HttpClient();
  return httpClient.fetchWithThrow<Concept>(
    "/api/app/v2/concept/{nodeId}/update",
    "POST",
    {
      urlWildCards: {
        nodeId: values.nodeId
      },
      bodyJSON: {
        subject: values.subject
      }
    }
  );
};

export const useUpdateConcept = () => {
  const dispatch = useDispatch();
  const { isLoading, mutate: register } = useMutation(
    (values: NewConcept) => updateConcept(values),
    {
      onSuccess: (data) => {
        dispatch(documentViewAction__Refresh(true));
        dispatch(tableActionViewAction__Refresh(true));
      }
    }
  );

  return {
    isLoading,
    register
  };
};
