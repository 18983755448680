import { getService } from "presentation/core/features/dependencyInjection";
import { PaginationConfig } from "../../../../lib/contract/Pagination";
import { useCallback } from "react";
import { FindRetentionProtocolsOrProposalsHistory } from "../../../../useCase/recordRetention/FindRetentionProtocolsOrProposalsHistory";
import { SortingConfig } from "lib/contract/Sorting";

export const useFindRetentionProtocolOrProposalHistory = (nodeId: string) => {
  const findRetentionProtocolOrProposalUseCase = getService(
    FindRetentionProtocolsOrProposalsHistory
  );

  return useCallback(
    (config: PaginationConfig, sorting?: SortingConfig) =>
      findRetentionProtocolOrProposalUseCase.findAllPaginated(
        nodeId,
        config,
        sorting
      ),
    [findRetentionProtocolOrProposalUseCase, nodeId]
  );
};
