import { useMutation } from "presentation/share/hook/query/useMutation";
import { canClosePartTypeFile } from "../api";

interface Variables {
  nodeId: string;
  partId: string;
}

export const useGetTypeFilePartValidate = () => {
  const {
    isLoading: isValidatePartLoading,
    mutate: validatePart
  } = useMutation(
    ({ nodeId, partId }: Variables) => canClosePartTypeFile({ nodeId, partId }),
    { onErrorNotification: null, onSuccessNotification: null }
  );

  return {
    isValidatePartLoading,
    validatePart
  };
};
