import { CircularProgress } from "@mui/material";
import { StyledWrapper } from "./LoadingIndicator.styles";
import React from "react";

export const LoadingIndicator = () => {
  return (
    <StyledWrapper>
      <CircularProgress />
    </StyledWrapper>
  );
};
