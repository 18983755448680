import { componentDownloadAction } from "presentation/core/api/components/_actions";
import {
  DataColumn,
  ValueType
} from "presentation/core/components/dataTable/_types";
import { GenericDocument } from "presentation/core/types";
import { DocumentType, SenderType } from "presentation/enums";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStateType } from "presentation/reducers";
import { classPath, translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import { callAsyncAction } from "../../../../action";
import { ComponentType } from "../../../../api/components/_types";
import { SslProperties } from "../../../../api/models";
import { File, FileMetaType, fileProxy } from "../../../../entities";
import { Notification } from "presentation/designSystem/notification/Notification";
import { useMetaFormDocument } from "../../hooks/useMetaFormDocument";
import { DialogDataGenericData, DialogTabContentPropsType } from "../../_types";
import InactiveComponentsTab from "./InactiveComponentsTab";
import { SortDirection } from "../../../../../govDesignSystem/lib/contract/Sorting";
import {
  INACTIVE_DOCUMENTS_QUERY_KEY,
  useGetInactiveComponents
} from "./hooks/useGetInactiveComponents";
import { useMutation } from "../../../../../share/hook/query/useMutation";
import { getErrorCodeTranslation } from "../../../../../share/utils/errorCodeTranslation";
import { useUpdateActiveComponents } from "../components/hooks/useUpdateActiveComponents";
import { ActiveComponent } from "../components/_types";
import { postActivateComponents } from "./api";
import { useQueryClient } from "presentation/govDesignSystem/Table/hook/query/useQuery";
import { ACTIVE_DOCUMENTS_QUERY_KEY } from "../components/hooks/useGetActiveComponents";

type CommentsTabContainerState = {
  pageNumber: number;
  rowsPerPage: number;
  sortAsc?: boolean;
  sortColumnIndex?: number;
  sortKeys?: string[];
};

const initialState: CommentsTabContainerState = {
  pageNumber: 0,
  rowsPerPage: 100
};

type OwnProps = DialogTabContentPropsType & {
  nodeId: string;
  isReadonly?: boolean;
  componentType?: ComponentType;
};

const InactiveComponentsTabContainer = React.memo(
  ({
    channel,
    componentType = ComponentType.Document,
    dialogProps,
    isReadonly,
    nodeId
  }: OwnProps) => {
    const dispatch = useDispatch();
    const [
      { pageNumber, rowsPerPage, sortKeys, sortColumnIndex, sortAsc },
      setState
    ] = useState<CommentsTabContainerState>(initialState);

    const { error } = useSelector(
      (state: RootStateType) => state.componentsReducer
    );
    const metaFormDocument = useMetaFormDocument();
    const nodeType =
      (dialogProps.data as DialogDataGenericData)?.nodeType ||
      metaFormDocument?.nodeType;
    const { data, refetch, isFetching } = useGetInactiveComponents(
      nodeId,
      true,
      {
        page: pageNumber ? pageNumber : 1,
        itemsPerPage: rowsPerPage ? rowsPerPage : 10
      },
      [
        {
          property: sortKeys && sortKeys.length ? sortKeys![0] : "name",
          direction: sortAsc
            ? SortDirection.Ascending
            : SortDirection.Descending
        }
      ]
    );
    const getNotificationForError = (error: any) => ({
      message: getErrorCodeTranslation(error.code)
    });
    const updateInActiveComponents = useUpdateActiveComponents();
    const { isLoading, mutate: updatingActiveComponent } = useMutation(
      (body: ActiveComponent) => updateInActiveComponents(nodeId, body),
      {
        onSuccess() {
          fetchComponents();
        },
        onSuccessNotification: null,
        onErrorNotification: getNotificationForError
      }
    );
    const queryClient = useQueryClient();
    const { mutate: activateComponents } = useMutation(
      (components: File[]) => postActivateComponents(nodeId, components),
      {
        onSuccess() {
          queryClient.invalidateQueries(INACTIVE_DOCUMENTS_QUERY_KEY);
          queryClient.invalidateQueries(ACTIVE_DOCUMENTS_QUERY_KEY);
        },
        onSuccessNotification: {
          message: t(
            translationPath(lang.dialog.notifications.componentsActivated)
          )
        },
        onErrorNotification: {
          message: t(
            translationPath(
              lang.dialog.notifications.componentsActivationFailed
            )
          )
        }
      }
    );
    const sortedComponents = useMemo(() => {
      return data ? data.items : [];
    }, [data, sortKeys]);
    const fetchComponents = () => {
      refetch();
    };
    useEffect(() => {
      channel.refreshData = fetchComponents;
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      refetch();
    }, [nodeId, rowsPerPage, pageNumber, sortColumnIndex, sortAsc]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      if (
        !sortedComponents.length ||
        !handleCanShowPreview(sortedComponents[0]) ||
        channel.previewItem
      ) {
        return;
      }

      channel.setPreviewItem(sortedComponents[0].isEncrypted || false, {
        ...sortedComponents[0],
        entityId: nodeId,
        nodeType
      });
    }, [channel, nodeId, nodeType, sortedComponents]);

    const handleChangePage: (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
      page: number
    ) => void = useCallback((_, page) => {
      setState((state) => ({
        ...state,
        pageNumber: page
      }));
    }, []);

    const handleChangeRowsPerPage: (
      event: React.ChangeEvent<HTMLInputElement>
    ) => void = useCallback((event) => {
      setState((state) => ({
        ...state,
        pageNumber: 0,
        rowsPerPage: parseInt(event.target.value, 10)
      }));
    }, []);

    const handleDownloadComponent = useCallback(
      (selectedComponents: File[]) => {
        Notification.success({
          message: t(
            translationPath(
              lang.dialog.notifications.componentDownloadIsPreparing
            )
          )
        });

        dispatch(
          callAsyncAction({
            action: componentDownloadAction,
            payload: {
              componentIds: selectedComponents.map((c) => c.id),
              nodeId,
              nodeType
            }
          })
        );
      },
      [dispatch, nodeId, nodeType]
    );

    const handleColumnChange = useCallback(
      (row: File, column: DataColumn<File>, value: ValueType) => {
        let updateComponentObject = {
          [column.keys[0]]: value,
          componentId: row.id
        };
        if (column.keys[0] === classPath(fileProxy.type).path) {
          updateComponentObject = {
            ...updateComponentObject,
            isReadable: row.isReadable
          };
        } else {
          updateComponentObject = { ...updateComponentObject, type: row.type };
        }
        updatingActiveComponent(updateComponentObject);
      },
      [dispatch, metaFormDocument.documentId, metaFormDocument.nodeType]
    );

    const handleActivateComponent = useCallback(
      (selectedComponents: File[]) => {
        activateComponents(selectedComponents);
      },
      [
        dispatch,
        metaFormDocument.documentId,
        metaFormDocument.nodeType,
        nodeId,
        nodeType
      ]
    );
    const showActions = () => {
      if (isReadonly || dialogProps.canUploadComponents === false) {
        return false;
      }

      const document = dialogProps.data as GenericDocument;

      const { isLocked, formValues } = metaFormDocument;
      const typedFormValues = formValues as SslProperties;
      const form = document?.properties?.ssl?.form || typedFormValues?.form;
      const file = document?.isFile;

      const senderType =
        document?.properties?.ssl?.senderType || typedFormValues?.senderType;
      const documentType =
        document?.properties?.ssl?.documentType ||
        typedFormValues?.documentType;
      const documentIsLocked = document?.isLocked || isLocked;

      return (
        (form === DocumentType.Analog ||
          (DocumentType.Digital &&
            (senderType === SenderType.Own ||
              (documentType === DocumentType.TechnicalDataCarries &&
                !file)))) &&
        !documentIsLocked
      );
    };

    const handleCanActivateComponent = (file: File) =>
      file?.type !== FileMetaType.main ? true : false;

    const handleCanShowPreview = (component: File) => {
      // filter by extensions
      return true;
    };

    const handleShowPreview = useCallback(
      (selected: File[]) => {
        if (!selected[0]) {
          return;
        }

        channel.setPreviewItem(
          selected[0]?.isEncrypted || false,
          {
            ...selected[0],
            entityId: nodeId,
            nodeType
          },
          true
        );
      },
      [channel, nodeId, nodeType]
    );

    const handleSelectionChange = useCallback(
      (selected: File[]) => {
        if (!selected[0] || !handleCanShowPreview(selected[0])) {
          return;
        }

        channel.setPreviewItem(selected[0].isEncrypted || false, {
          ...selected[0],
          entityId: nodeId,
          nodeType
        });
      },
      [channel, nodeId, nodeType]
    );

    const handleSortingChange: (
      index: number,
      keys: string[]
    ) => (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void = (
      index,
      keys
    ) => (event) => {
      setState((state) => ({
        ...state,
        sortAsc: index === state.sortColumnIndex ? !sortAsc : false,
        sortColumnIndex: index,
        sortKeys: keys
      }));
    };

    if (error) {
      return <div />;
    }

    return (
      <InactiveComponentsTab
        handleCanShowPreview={handleCanShowPreview}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleColumnChange={handleColumnChange}
        handleDownloadComponent={handleDownloadComponent}
        handleSelectionChange={handleSelectionChange}
        handleShowPreview={handleShowPreview}
        handleSortingChange={handleSortingChange}
        handleActivateComponent={handleActivateComponent}
        handleCanActivateComponent={handleCanActivateComponent}
        isLoading={isFetching || isLoading}
        isReadonly={isReadonly || !!dialogProps.isReadonly}
        items={sortedComponents}
        pageNumber={data ? data.page - 1 : 0}
        refreshTable={fetchComponents}
        rowsPerPage={data ? data.itemsPerPage : 10}
        sortAsc={sortAsc}
        sortColumnIndex={sortColumnIndex}
        totalItems={data?.total || 0}
      />
    );
  }
);

export default InactiveComponentsTabContainer;
