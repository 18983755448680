import { HttpClient } from "infrastructure/service/HttpClient";
import { PaginatedResult, PaginationConfig } from "lib/contract/Pagination";
import { ApiUrls } from "../../../../../../../infrastructure/api/struct/ApiTypesV2";
import { Mappers } from "./mappers";
import { ForSeal } from "../types/ForSeal";
import { SortingConfig } from "lib/contract/Sorting";

const URL: ApiUrls = {
  GetOverviewsForSeal: "/api/app/v2/overview/for-seal"
};

export const getOverviewsForSeal = (
  pagination: PaginationConfig,
  sorting?: SortingConfig
): Promise<PaginatedResult<ForSeal>> => {
  const httpClient = new HttpClient();
  return httpClient
    .fetchPaginated<ForSeal>({
      url: URL.GetOverviewsForSeal,
      pagination,
      sorting
    })
    .then(Mappers.mapListToDomain);
};
