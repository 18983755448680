import { PaginatedResult } from "lib/contract/Pagination";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataColumn } from "../../core/components/dataTable/_types";
import { documentViewAction__Refresh } from "../../core/components/documentView/_actions";
import { RootStateType } from "../../reducers";
import { SortingConfig } from "../lib/contract/Sorting";
import DataTable, { ViewType } from "./DataTable";
import {
  TableAction,
  TableActions
} from "./components/TableActionsBar/contract";
import { QueryConfig } from "./hook/query/useQuery";
import { useRefreshTableOnNewNotification } from "./hook/useRefreshTableOnNewNotification";
import { useRemoteData } from "./hook/useRemoteData";
import { useRemoteTableApi } from "./hook/useRemoteTableApi";

export interface PaginationProps {
  total?: number;
  defaultCurrent?: number;
  disabled?: boolean;
  current?: number;
  defaultPageSize?: number;
  pageSize?: number;
  onChange?: (page: number, pageSize?: number) => void;
  hideOnSinglePage?: boolean;
  showSizeChanger?: boolean;
  pageSizeOptions?: string[];
  onShowSizeChange?: (current: number, size: number) => void;
  showQuickJumper?:
    | boolean
    | {
        goButton?: React.ReactNode;
      };
  showTitle?: boolean;
  showTotal?: (total: number, range: [number, number]) => React.ReactNode;
  size?: "default" | "small";
  responsive?: boolean;
  simple?: boolean;
  style?: React.CSSProperties;
  locale?: Object;
  className?: string;
  prefixCls?: string;
  selectPrefixCls?: string;
  itemRender?: (
    page: number,
    type: "page" | "prev" | "next" | "jump-prev" | "jump-next",
    originalElement: React.ReactElement<HTMLElement>
  ) => React.ReactNode;
  role?: string;
  showLessItems?: boolean;
}
declare type TablePaginationPosition =
  | "topLeft"
  | "topCenter"
  | "topRight"
  | "bottomLeft"
  | "bottomCenter"
  | "bottomRight";
export interface TablePaginationConfig extends PaginationProps {
  position?: TablePaginationPosition[];
}
export interface PaginationConfig {
  page: number;
  itemsPerPage: number;
}

export interface RemoteDataConfig {
  pagination: PaginationConfig;
  sorting?: SortingConfig;
  params?: Record<string, string>;
}

export type GetRemoteDataClb<Row> = (
  config: RemoteDataConfig
) => Promise<PaginatedResult<Row>>;

export type OnRemoteTableApiClb = (api: RemoteTableApi) => void;

export interface RemoteTableProps<Row> {
  name: string;
  title: string;
  getRemoteData: GetRemoteDataClb<Row>;
  onFetchErrorMessage?: string;
  columns: DataColumn<Row>[];
  defaultSortAsc?: boolean;
  defaultSortColumn?: DataColumn<Row>;
  onRemoteTableApi?: OnRemoteTableApiClb;
  queryConfig?: QueryConfig<any>;
  remoteQueryParams?: Record<string, string>;
  pagination?: false | PaginationConfig;
  actions?: TableActions<Row>;
  onActionFinished?: (
    action: TableAction<Row>,
    dataWasMutated: boolean
  ) => void;
  isRowDisabled?(item: Row): boolean;
}

export interface RemoteTableApi {
  refetch(): void;
}

export function RemoteTable<Row extends ViewType>(
  props: RemoteTableProps<Row>
) {
  const {
    name,
    title,
    getRemoteData,
    onFetchErrorMessage,
    onRemoteTableApi,
    queryConfig,
    columns,
    defaultSortAsc,
    defaultSortColumn,
    remoteQueryParams,
    pagination,
    onActionFinished: onActionFinishedOriginal,
    ...tableProps
  } = props;

  const [remoteTableApi] = useRemoteTableApi();
  const {
    data,
    isFetching,
    refetch,
    sortAsc,
    sortColumnIndex,
    handleChangePage,
    handleChangeRowsPerPage,
    dataTableValues,
    handleSortingChange,
    setSelected
  } = useRemoteData(props);
  const onActionFinished: RemoteTableProps<Row>["onActionFinished"] =
    useCallback(
      (action: TableAction<Row>, dataWasMutated: boolean) => {
        onActionFinishedOriginal?.(action, dataWasMutated);

        if (!dataWasMutated) {
          return;
        }

        refetch();
      },
      [onActionFinishedOriginal, refetch]
    );
  const shouldRefreshTable = useSelector(
    (state: RootStateType) => state.documentViewReducer.shouldRefreshTable
  );
  const dispatch = useDispatch();

  useRefreshTableOnNewNotification(remoteTableApi);

  useEffect(() => {
    props.onRemoteTableApi?.(remoteTableApi);
  }, [props, remoteTableApi]);

  useEffect(
    () => {
      if (shouldRefreshTable) {
        remoteTableApi.refetch();
        dispatch(documentViewAction__Refresh(false));
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [shouldRefreshTable]
  );

  useEffect(() => {
    onRemoteTableApi?.({
      refetch
    });
  }, [onRemoteTableApi, refetch]);

  return (
    <DataTable<Row>
      {...tableProps}
      title={title}
      rows={data?.items}
      columns={columns}
      rowsCount={data?.total}
      pending={isFetching}
      rowsPerPage={data?.itemsPerPage}
      pageNumber={data?.page}
      handleSelectionChange={setSelected}
      handleSortingChange={handleSortingChange}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      dataTableValues={dataTableValues}
      sortAsc={!!sortAsc}
      sortColumnIndex={sortColumnIndex}
      onActionFinished={onActionFinished}
    />
  );
}
