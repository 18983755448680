import {
  DInject,
  DInjectable
} from "../../presentation/core/features/dependencyInjection";
import { PaginationConfig } from "../../lib/contract/Pagination";
import { SearchedRepositoryToken } from "../../domain/di/token/search";
import { SearchedApiRepository } from "../../infrastructure/api/search/SearchedApiRepository";
import { SortingConfig } from "../../lib/contract/Sorting";

@DInjectable()
export class FindSearched {
  constructor(
    @DInject(SearchedRepositoryToken)
    protected repository: SearchedApiRepository
  ) {}

  async findAllPaginated(
    searchedTerm: string,
    config: PaginationConfig,
    sorting?: SortingConfig
  ) {
    const result = await this.repository.getSearched(
      searchedTerm,
      config,
      sorting
    );
    localStorage.setItem("totalCount", result?.totalCount?.toString() ?? "");
    localStorage.setItem("allowedRecord", result?.total?.toString() ?? "");
    return result;
  }
}
