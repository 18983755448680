import { getService } from "presentation/core/features/dependencyInjection";
import { useQM } from "presentation/share/hook/query/useQM";
import { FindNationality } from "../../../../useCase/codelist/FindNationality";

const QUERY_KEY = "nameRegister/findNationalities";

export const useFindNationalities = () => {
  const findUseCase = getService(FindNationality);

  return useQM(QUERY_KEY, () => findUseCase.findAll());
};
