import { secondaryAction } from "presentation/core/components/dialog/lib/actionsFactory";
import { ReadOnlyComponentsTab } from "presentation/core/components/dialog/tabs/components";
import {
  DialogContentType,
  DialogType
} from "presentation/core/components/dialog/_types";
import NamedTitle from "presentation/core/components/namedTitle";
import { DataboxDocument } from "presentation/core/types";
import { DocumentType, SitePaths, SpisumNodeTypes } from "presentation/enums";
import { documentRegisterAction } from "presentation/modules/mailroom/features/income/_actions";
import React from "react";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import { createDocumentDialog } from "../baseDocumentDialog/documentDialogFactory";
import { MetadataFormTab } from "./../databoxDetailsDialog/MetadataFormTab";

export const databoxNotRegisterDetailsDialogContent: DialogContentType = createDocumentDialog(
  {
    actions: () => [
      secondaryAction(
        t(translationPath(lang.dialog.form.register)),
        ({ dispatch, dialogProps, onClose }) => {
          onClose();
          // A little hack to avoid stale state, looks like there are some differences between MUI a Ant modals
          // rendering, so it will be fixed when Ant replaces MUI in application
          setTimeout(() => {
            dispatch(
              documentRegisterAction({
                dialogType: DialogType.RegisterDatabox,
                document: dialogProps.data as DataboxDocument,
                nodeType: SpisumNodeTypes.Databox,
                documentType: DocumentType.Digital,
                sitePath: SitePaths.Unprocessed,
                onSuccess: onClose
              })
            );
          }, 200);
        }
      )
    ],
    tabs: [
      {
        content: MetadataFormTab,
        label: t(translationPath(lang.dialog.tabs.metadata))
      },
      {
        content: ReadOnlyComponentsTab,
        label: t(translationPath(lang.dialog.tabs.components))
      }
    ],
    title: (props) => (
      <NamedTitle
        text={t(translationPath(lang.dialog.title.databoxDetails))}
        {...props}
      />
    ),
    type: DialogType.DataboxNotRegisterDetails
  }
);
