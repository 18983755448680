import { ApiListResponse, ApiTypes } from "../struct/ApiTypesV2";
import { PaginatedResult } from "../../../lib/contract/Pagination";
import { ApiListResponseMapper } from "../mapper/ApiListResponseMapper";
import { Imprint } from "../../../domain/struct/administration/Imprint";

export type ApiImprint = ApiTypes["ISFG.SpisUm.ClientSide.Models.V2.Administration.TransactionHistory.ImprintResponse"];
export class ImprintApiMapper {
  static mapListToDomain(
    apiapiImprints: ApiListResponse<ApiImprint>
  ): PaginatedResult<Imprint> {
    return ApiListResponseMapper.mapListToPagination(
      apiapiImprints,
      ImprintApiMapper.mapToDomain
    );
  }

  static mapToDomain(apiImprint: ApiImprint): Imprint {
    return {
      id: apiImprint.nodeId || undefined,
      fileName: apiImprint.fileName || undefined,
      createdAt: apiImprint.createdAt || undefined
    };
  }
}
