import { callAsyncAction } from "presentation/core/action";
import { closeFileAction } from "presentation/core/api/file/_actions";
import { secondaryAction } from "presentation/core/components/dialog/lib/actionsFactory";
import {
  DialogContentType,
  DialogType
} from "presentation/core/components/dialog/_types";
import { documentViewAction__Refresh } from "presentation/core/components/documentView/_actions";
import NamedTitle from "presentation/core/components/namedTitle";
import { NotificationSeverity } from "presentation/core/components/notifications/_types";
import { GenericDocument } from "presentation/core/types";
import React from "react";
import { getErrorCodeTranslation } from "presentation/share/utils/errorCodeTranslation";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import { ErrorType } from "presentation/types";
import { SettleDocumentContentDialog } from "../settleDocumentDialog/SettleDocumentContentDialog";
import { SettleDocumentFormValues } from "../settleDocumentDialog/_types";
import { ModalSize } from "presentation/designSystem/Modal/Modal";

export const closeFileDialogContent: DialogContentType = {
  actions: () => [
    secondaryAction(
      t(translationPath(lang.dialog.form.confirm)),
      ({ dispatch, channels, dialogProps, onClose, buttonState }) => {
        const onSuccess = () => {
          onClose();
          dispatch(documentViewAction__Refresh(true));
          buttonState.setIsPending(false);
          dialogProps.onSuccess?.();
        };

        const onError = () => {
          buttonState.setIsPending(false);
          dialogProps.onError?.();
        };

        const onErrorNotification = (payload: ErrorType) => {
          const code = payload?.code || "";
          return {
            message: getErrorCodeTranslation(code),
            severity: NotificationSeverity.Error
          };
        };

        const { id } = dialogProps.data as GenericDocument;

        buttonState.setIsPending(true);

        const formValues = channels.contentTab?.state
          ?.formValues as SettleDocumentFormValues;

        dispatch(
          callAsyncAction({
            action: closeFileAction,
            onError,
            onErrorNotification,
            onSuccess,
            payload: {
              body: {
                customSettleMethod: formValues.customSettleMethod,
                settleDate: formValues.settleDate,
                settleMethod: formValues.settleMethod,
                settleReason: formValues.settleReason
              },
              nodeId: id
            }
          })
        );
      }
    )
  ],
  content: SettleDocumentContentDialog,
  title: (props) => (
    <NamedTitle
      text={t(translationPath(lang.dialog.title.closed))}
      {...props}
    />
  ),
  type: DialogType.CloseFile,
  size: ModalSize.Small
};
