import {
  DInject,
  DInjectable
} from "../../../presentation/core/features/dependencyInjection";
import { HttpClient } from "../../service/HttpClient";
import { ApiUrls } from "../struct/ApiTypes";
import {
  PaginatedResult,
  PaginationConfig
} from "../../../lib/contract/Pagination";
import { ConcernedContentRepository } from "../../../domain/service/nameRegistry/ConcernedContentRepository";
import { ConcernedContent } from "../../../domain/struct/nameRegistry/ConcernedContent";
import {
  ApiConcernedContent,
  ConcernedContentApiMapper
} from "./ConcernedContentApiMapper";

const URL: ApiUrls = {
  ConcernedContent: "/api/app/v1/name-register/subject/{subjectId}/relations"
};

@DInjectable()
export class ConcernedContentApiRepository
  implements ConcernedContentRepository {
  constructor(@DInject(HttpClient) protected httpClient: HttpClient) {}

  findBySubjectId(
    subjectId: string,
    pagination: PaginationConfig
  ): Promise<PaginatedResult<ConcernedContent>> {
    return this.httpClient
      .fetchPaginatedAlfresco<ApiConcernedContent>(
        URL.ConcernedContent,
        pagination,
        {
          urlWildCards: {
            subjectId
          }
        }
      )
      .then(ConcernedContentApiMapper.mapConcernedContentToDomain);
  }
}
