import { Location as LocationContract } from "../contract/Location";
import { CheckPermissionsBag } from "../../permissions/contract/CheckPermissionsBag";
import { PermissionsChecker } from "presentation/modules/nameRegister/lib/permissions/contract/PermissionsChecker";
import React from "react";

export interface LocationConfig {
  readonly name: string;
  readonly url: string;
  readonly component: React.ElementType;
  readonly parent?: LocationContract;
  readonly checkPermissions?: PermissionsChecker;
}

export class Location implements LocationContract {
  public readonly name: string;
  public readonly children: LocationContract[] = [];
  public readonly url: string;
  public readonly component: React.ElementType;
  protected _parent?: LocationContract;
  protected readonly _checkPermissions?: PermissionsChecker;

  constructor(location: LocationConfig) {
    this._checkPermissions = location.checkPermissions;
    this.component = location.component;
    this.name = this.generateName(location.name, location.parent);
    this.url = this.generateUrl(location.url, location.parent);
    this.addParent(location.parent);
  }

  static create(location: LocationConfig): LocationContract {
    return new this(location);
  }

  get parent(): LocationContract | undefined {
    return this._parent;
  }

  addChildren(location: LocationContract): void {
    this.children.push(location);
  }

  checkPermissions(bag: CheckPermissionsBag): boolean {
    if (!this._checkPermissions) {
      return true;
    }

    return this._checkPermissions(bag);
  }

  protected generateName(name: string, parent?: LocationContract): string {
    if (!parent) {
      return name;
    }

    return `${parent.name}.${name}`;
  }

  protected generateUrl(url: string, parent?: LocationContract): string {
    if (!parent) {
      return `/${url}`;
    }

    return `${parent.url}/${url}`;
  }

  protected addParent(parent?: LocationContract) {
    if (!parent) {
      return;
    }

    this._parent = parent;
    parent.addChildren(this);
  }
}
