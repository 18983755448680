import MenuItem from "@mui/material/MenuItem";
import React, { ReactNode } from "react";
import { FilePlanGroups } from "../../../../core/features/login/_types";

// Necessary to satisfy Material-UI Select API which expects
// flat structure of menu items => MUST NOT be a component!

export const renderHiddenFileMarksOptions = (
  fileMarks: FilePlanGroups[]
): ReactNode[] => {
  return fileMarks.map((mark) => {
    if (mark.isParent && mark.filePlanGroups?.length) {
      return renderHiddenFileMarksOptions(mark.filePlanGroups);
    }
    return (
      <MenuItem
        style={{ display: "none" }}
        value={mark.identificator}
        key={mark.identificator}
      >
        {`${mark.fullyDeterminedFileMark} - ${mark.name}`}
      </MenuItem>
    );
  });
};
