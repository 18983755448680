import { sslPropsProxy } from "presentation/core/types";
import {
  lastPathMember,
  translationPath
} from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import * as yup from "yup";

export const senderFormValidationObject = {
  [lastPathMember(sslPropsProxy.senderFullName).path]: yup
    .string()
    .max(100, t(translationPath(lang.dialog.errors.maxLen), { len: 100 })),
  [lastPathMember(sslPropsProxy.senderAddressId).path]: yup
    .string()
    .max(255, t(translationPath(lang.dialog.errors.maxLen), { len: 255 })),
  [lastPathMember(sslPropsProxy.senderCompanyName).path]: yup
    .string()
    .max(100, t(translationPath(lang.dialog.errors.maxLen), { len: 100 })),
  [lastPathMember(sslPropsProxy.senderCompanyEmployeeUnit)
    .path]: yup
    .string()
    .max(100, t(translationPath(lang.dialog.errors.maxLen), { len: 100 })),
  [lastPathMember(sslPropsProxy.senderCompanyEmployeeJob)
    .path]: yup
    .string()
    .max(100, t(translationPath(lang.dialog.errors.maxLen), { len: 100 }))
};
