import { createProxy } from "presentation/share/utils/getPath";

export enum RadioTypes {
  legal = "legal",
  own = "own"
}

export interface SenderFieldsTypes {
  senderAddressId: "senderAddressId";
  senderCompanyEmployeeJob: "senderCompanyEmployeeJob";
  senderFullName: "senderFullName";
  senderCompanyName: "senderCompanyName";
  senderCompanyEmployeeUnit: "senderCompanyEmployeeUnit";
}

createProxy<SenderFieldsTypes>();
