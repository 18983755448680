import {
  GetRemoteDataClb,
  RemoteTable
} from "presentation/designSystem/Table/RemoteTable";
import {
  createColumn,
  createDateTimeColumn
} from "presentation/designSystem/Table/column/column";
import React, { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { RentedDocument } from "../../../../../../domain/struct/repository/RentedDocument";
import { dialogOpenAction } from "../../../../../core/components/dialog/_actions";
import { DialogType } from "../../../../../core/components/dialog/_types";
import { getNodeTypeByIdentifier } from "../../../../../core/mappers/api/general";
import { BreadcrumbForTable } from "../../../../../designSystem/Breadcrumb/Breadcrumb";
import {
  FileTextFilled,
  MenuFoldOutlined
} from "../../../../../designSystem/Icon/Icon";
import { ColumnType } from "../../../../../designSystem/Table/Table";
import { SpisumNodeTypes } from "../../../../../enums";
import { FileMarkColumnValue } from "../../../../../share/components/table/column/document/FilePlan/FileMarkColumnValue";
import { FilePlanColumnValue } from "../../../../../share/components/table/column/document/FilePlan/FilePlanColumnValue";
import { getDetailActionForDocumentNewDataModel } from "../../../../../share/utils/action/dialog/documentDetail";
import { translationPath } from "../../../../../share/utils/getPath";
import { lang, t } from "../../../../../translation/i18n";
import { createSenderNameColumn } from "../../../factory/table/columnFactory";
import { useFindRentedDocuments } from "../hooks/useFindRentedDocuments";

const COLUMNS: ColumnType<RentedDocument>[] = [
  createColumn({
    dataIndex: "pid",
    title: t(translationPath(lang.general.identifier))
  }),
  createColumn({
    dataIndex: "fileIdentifier",
    title: `${t(translationPath(lang.general.referenceNumber))}/${t(
      translationPath(lang.general.fileIdentifier)
    )}`,
    render: (text, row) => {
      if (row.fileIdentifier) {
        return row.fileIdentifier;
      }
      return row.ssid;
    }
  }),
  createColumn({
    dataIndex: "subject",
    title: t(translationPath(lang.general.subject))
  }),
  createSenderNameColumn(),
  createColumn({
    dataIndex: "filePlan",
    title: t(translationPath(lang.general.filePlan)),
    render: (text, row) => {
      const { filePlanId } = row;
      if (!filePlanId) {
        return null;
      }
      return <FilePlanColumnValue filePlanId={filePlanId} />;
    }
  }),
  createColumn({
    dataIndex: "fileMark",
    title: t(translationPath(lang.general.fileMark)),
    render: (text, row) => {
      const { fileMark } = row;
      if (!fileMark) {
        return null;
      }
      return <FileMarkColumnValue fileMarkId={fileMark} />;
    }
  }),
  createColumn({
    dataIndex: "retentionMode",
    title: t(translationPath(lang.general.retentionMode))
  }),
  createColumn({
    dataIndex: "borrower",
    title: t(translationPath(lang.general.borrower))
  }),
  createDateTimeColumn({
    dataIndex: "borrowDate",
    title: t(translationPath(lang.general.borrowDate))
  })
];

export const RentedDocumentsTable = React.memo(() => {
  const dispatch = useDispatch();
  const findRentedDocuments = useFindRentedDocuments();

  const ACTIONS = useMemo(
    () => ({
      single: [
        {
          key: "show-details",
          icon: <FileTextFilled rev={"default"} />,
          tooltip: t(translationPath(lang.general.showDetails)),
          action(selected: RentedDocument[]) {
            const nodeType = getNodeTypeByIdentifier(selected[0]);
            const action = getDetailActionForDocumentNewDataModel(nodeType);
            dispatch(
              action({
                canUploadComponents: false,
                data: {
                  id: selected[0].nodeId,
                  nodeType
                },
                hideManageShipmentsIcon: true,
                initiator: SpisumNodeTypes.File,
                isReadonly: true
              })
            );
          }
        },
        {
          key: "return",
          icon: <MenuFoldOutlined rev={"default"} />,
          tooltip: t(translationPath(lang.general.return)),
          action(selected: RentedDocument[]) {
            dispatch(
              dialogOpenAction({
                dialogProps: {
                  data: {
                    id: selected[0].nodeId,
                    nodeType: getNodeTypeByIdentifier(selected[0])
                  }
                },
                dialogType: DialogType.ReturnToRepository
              })
            );
          }
        }
      ]
    }),
    [dispatch]
  );

  const getRemoteData: GetRemoteDataClb<RentedDocument> = useCallback(
    ({ pagination }) => findRentedDocuments(pagination),
    [findRentedDocuments]
  );

  return (
    <>
      <BreadcrumbForTable title={"rented"}></BreadcrumbForTable>
      <TableStyled>
        <RemoteTable<RentedDocument>
          name="repository/rentedDocuments"
          rowKey="pid"
          actions={ACTIONS}
          columns={COLUMNS}
          getRemoteData={getRemoteData}
        />
      </TableStyled>
    </>
  );
});

const TableStyled = styled("div")`
  div > .ant-row {
    height: 50px;
  }
`;
