import { compareDesc } from "date-fns";
import { findIndexByProp } from "lib/array";
import { FilePlan } from "presentation/core/types";

export const returnNextAndPrevSelectedItem = (
  items: FilePlan[],
  selected: FilePlan
) => {
  const itemsSortedAccordingValidToDate = sortAccordingToValidFrom(items);

  const indexOfSelectedItem = findIndexByProp(
    itemsSortedAccordingValidToDate,
    selected,
    "id"
  );

  return {
    nextSelectedItem: itemsSortedAccordingValidToDate[indexOfSelectedItem - 1],
    previousSelectedItem:
      itemsSortedAccordingValidToDate[indexOfSelectedItem + 1]
  };
};

const sortAccordingToValidFrom = (items: FilePlan[]) =>
  [...items].sort((objectA, objectB) => {
    const dateA = new Date(objectA?.properties?.ssl?.validFrom!);
    const dateB = new Date(objectB?.properties?.ssl?.validFrom!);

    return compareDesc(dateA, dateB);
  });

export const hasLastItemValidToDate = (items: FilePlan[]) => {
  const itemsSortedAccordingValidFromDate = sortAccordingToValidFrom(items);
  const lastItemValidTo =
    itemsSortedAccordingValidFromDate[0]?.properties?.ssl?.validTo;

  return !!lastItemValidTo;
};

export const errorTranslations = {
  DEFAULT_ERROR: "dialog:errors.invalidOperationException",
  SP_IMPORT_INVALID_ENDDATE: "dialog:errors.invalidEndDate",
  SP_IMPORT_INVALID_SG_CREATEDATE: "dialog:errors.invalidSgCreateDate",
  SP_IMPORT_INVALID_SG_ENDDATE: "dialog:errors.invalidSgEndDate",
  SP_IMPORT_INVALID_SG_ENDDATE_PARENT: "dialog:errors.invalidSgEndDateParent",
  SP_IMPORT_INVALID_SG_STARTDATE_PARENT:
    "dialog:errors.invalidSgStartDateParent",
  SP_IMPORT_INVALID_STARTDATE: "dialog:errors.invalidStartDate",
  SP_IMPORT_INVALID_STARTDATE_FIRST: "dialog:errors.invalidStartDateFirst",
  SP_IMPORT_OLD_ENDDATE: "dialog:errors.oldEndDate",
  SP_IMPORT_SS_DUPLICATE: "dialog:errors.importSsDuplicate",
  SP_IMPORT_STRUCTURE: "dialog:errors.importStructure"
};

export const getErrorTranslationKey = (code: string) =>
  errorTranslations[code as keyof typeof errorTranslations] ||
  errorTranslations.DEFAULT_ERROR;
