import { PaginationConfig } from "lib/contract/Pagination";
import { useCallback } from "react";
import { SortingConfig } from "lib/contract/Sorting";
import { getDocumentForSeal } from "../api";

export const useDocumentsForSeal = () => {
  return useCallback(
    (nodeId: string, config: PaginationConfig, sorting?: SortingConfig) =>
      getDocumentForSeal(nodeId, config, sorting),
    [getDocumentForSeal]
  );
};
