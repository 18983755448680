import { ShipmentHistoryRepository } from "domain/service/shipment/ShipmentHistoryRepository";
import { ShipmentRepository } from "domain/service/shipment/ShipmentRepository";
import { ShipmentComponentRepository } from "domain/service/shipment/ShipmentComponentRepository";
import { DInjectionToken } from "presentation/core/features/dependencyInjection";
export const ShipmentComponentRepositoryToken = new DInjectionToken<ShipmentComponentRepository>(
  "domain.shipment.ShipmentComponent-repository"
);
export const ShipmentRepositoryToken = new DInjectionToken<ShipmentRepository>(
  "domain.shipment.Shipment-repository"
);
export const ShipmentHistoryRepositoryToken = new DInjectionToken<ShipmentHistoryRepository>(
  "domain.shipment.ShipmentHistory-repository"
);
