import { HttpClient } from "infrastructure/service/HttpClient";
import { RegisterEmailDigitalFormValues } from "../forms/RegisterEmailDigitalForm";
import { CreateEmailRegistration } from "../types";
import { mapDocumentToApi } from "./mappers";

export const registerDocumentFromEmail = (id: string): Promise<void> => {
  const httpClient = new HttpClient();
  return httpClient.fetchWithThrow<void>(
    "/api/app/v2/document/{nodeId}/register",
    "POST",
    {
      urlWildCards: {
        nodeId: id
      }
    }
  );
};

export const updateUnfinishedDocument = (
  id: string,
  input: RegisterEmailDigitalFormValues
) => {
  const httpClient = new HttpClient();
  const documentToApi = mapDocumentToApi(input);
  return httpClient.fetchWithThrow<void>(
    "/api/app/v2/document/{nodeId}/update/unfinished",
    "POST",
    {
      bodyJSON: documentToApi,
      urlWildCards: {
        nodeId: id
      }
    }
  );
};

export const createDocumentFromEmail = (
  id: string
): Promise<CreateEmailRegistration> => {
  const httpClient = new HttpClient();
  return httpClient.fetchWithThrow<CreateEmailRegistration>(
    "/api/app/v2/document/create/digital/{nodeId}/email",
    "POST",
    {
      urlWildCards: {
        nodeId: id
      }
    }
  );
};
