import ArchiveIcon from "@mui/icons-material/Archive";
import { MenuItemType } from "presentation/core/components/menu/_types";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import ToDispose from "./features/forShredding";
import RetentionProposal from "./features/recordRetentionProcess/retentionProposal/RetentionProposal";
import RecordRetentionProcessRetentionProtocols from "./features/recordRetentionProcess/retentionProtocols";
import RecordRetentionProcessRetentionProtocolsErased from "./features/recordRetentionProcess/retentionProtocolsErased";
import { RecordRetentionProcessRoutes } from "./routes";
import React from "react";

export const recordRetentionProcessMenuItems: MenuItemType[] = [
  {
    __testId: "menu-main-recordretentionprocess",
    icon: <ArchiveIcon />,
    submenu: [
      {
        __testId: "menu-main-recordretentionprocess-todispose",
        content: <ToDispose />,
        text: t(translationPath(lang.menu.items.toDispose)),
        url: RecordRetentionProcessRoutes.RECORDRETENTIONPROCESS_TODISPOSE
      },
      {
        __testId: "menu-main-recordretentionprocess-retentionproposal",
        content: <RetentionProposal />,
        text: t(translationPath(lang.menu.items.retentionProposal)),
        url: RecordRetentionProcessRoutes.RECORDRETENTIONPROCESS_RETENTIONPROPOSAL
      },
      {
        __testId: "menu-main-recordretentionprocess-recordretentionprocess",
        submenu: [
          {
            __testId:
              "menu-main-recordretentionprocess-recordretentionprocess-retentionprotocols",
            content: <RecordRetentionProcessRetentionProtocols />,
            text: t(translationPath(lang.menu.items.retentionBeforeDeletion)),
            url: RecordRetentionProcessRoutes.RECORDRETENTIONPROCESS_RETENTIONPROTOCOL
          },
          {
            __testId:
              "menu-main-recordretentionprocess-recordretentionprocess-retentionprotocols-erased",
            content: <RecordRetentionProcessRetentionProtocolsErased />,
            text: t(translationPath(lang.menu.items.retentionAfterDeletion)),
            url: RecordRetentionProcessRoutes.RECORDRETENTIONPROCESS_RETENTIONPROPOSALS_ERASED
          }
        ],
        text: t(translationPath(lang.menu.items.retentionProtocol))
      }
    ],
    text: t(translationPath(lang.menu.items.recordRetentionProcess))
  }
];
