import {
  DInject,
  DInjectable
} from "presentation/core/features/dependencyInjection";
import { ErasedFileRepositoryToken } from "../../domain/di/token/recordRetention";
import type { ErasedFileRepository } from "../../domain/service/recordRetention/ErasedFileRepository";
import { ErasedFileId } from "../../domain/struct/recordRetention/ErasedFile";

@DInjectable()
export class FindErasedFile {
  constructor(
    @DInject(ErasedFileRepositoryToken)
    protected repository: ErasedFileRepository
  ) {}

  findErasedFileById(id: ErasedFileId) {
    return this.repository.findErasedFileById(id);
  }
}
