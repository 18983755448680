import { Modal, ModalSize } from "presentation/designSystem/Modal/Modal";
import { BaseModalProps } from "presentation/designSystem/Modal/useModal";
import { useMutation } from "presentation/share/hook/query/useQM";
import { getErrorCodeTranslation } from "presentation/share/utils/errorCodeTranslation";
import { translationPath } from "presentation/share/utils/getPath";
import styled from "presentation/styles";
import { t } from "presentation/translation/i18n";
import lang from "presentation/translation/lang";
import { ErrorType } from "presentation/types";
import { DeactivateValueParams, postDeactivateValue } from "../api";
import React from "react";

export interface DeactivateValueInterface
  extends BaseModalProps,
    DeactivateValueParams {
  onOk?: BaseModalProps["onOk"];
}

export const DeactivateValueModal = ({
  onCancel,
  onOk,
  codeList,
  value
}: DeactivateValueInterface) => {
  const getNotificationForError = (error: ErrorType) => {
    return {
      message: error.code
        ? getErrorCodeTranslation(error.code)
        : t(translationPath(lang.dialog.notifications.valueNotDeactivated))
    };
  };
  const onConfirm = async () => {
    try {
      mutate({ codeList, value });
    } catch {}
    onOk?.();
  };

  const { isLoading, mutate } = useMutation(
    (params: DeactivateValueParams) => postDeactivateValue(params),
    {
      onSuccess() {
        onOk?.();
      },
      onSuccessNotification: {
        message: t(
          translationPath(lang.dialog.notifications.valueSuccessDeactivated)
        )
      },
      onErrorNotification: getNotificationForError
    }
  );

  return (
    <Modal
      open={true}
      title={t(translationPath(lang.general.deactivate))}
      onCancel={onCancel}
      onOk={onConfirm}
      confirmLoading={isLoading}
      size={ModalSize.Small}
      okText={t(translationPath(lang.modal.ok))}
      cancelText={t(translationPath(lang.modal.cancel))}
    >
      <StyledDiv>
        {t(translationPath(lang.dialog.content.isDeactivateValue))}
      </StyledDiv>
    </Modal>
  );
};

const StyledDiv = styled.div`
  align-items: center;
  font-size: 16px;
`;
