import { Node, SslEmail } from "presentation/core/api/models";
import { useStyles } from "presentation/core/components/dialog/Dialog.styles";
import { DialogContentType } from "presentation/core/components/dialog/_types";
import React from "react";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import { IncompleteDocumentForm } from "./IncompleteDocumentForm";
import { IncompleteDocumentFormValues } from "./_types";
import { validate } from "./_validations";
import { ChannelForm } from "../ChannelForm/ChannelForm";

export const IncompleteDocumentContentDialog: DialogContentType["content"] = ({
  channel,
  dialogProps
}) => {
  const classes = useStyles();
  const document = dialogProps.data as Node<SslEmail>;

  const initialValues: IncompleteDocumentFormValues = {
    body: "",
    recipient: document.properties?.ssl?.emailSender || "",
    subject: document.properties?.ssl?.digitalDeliverySubject || ""
  };

  return (
    <div className={classes.modalSmall}>
      <p>
        <b>{t(translationPath(lang.dialog.content.incompleteText))}</b>
      </p>
      <ChannelForm<IncompleteDocumentFormValues>
        initialValues={initialValues}
        validate={validate}
        channel={channel}
        component={IncompleteDocumentForm}
      />
    </div>
  );
};
