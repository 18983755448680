import { ApiListResponse, ApiTypes } from "../struct/ApiTypesV2";
import { stringOrDefault } from "../../../lib/string";
import { ApiListResponseMapper } from "../mapper/ApiListResponseMapper";
import { RetentionProtocolProposalHistory } from "../../../domain/struct/recordRetention/RetentionProtocolProposalHistory";

export type ApiProtocolOrProposalHistory = ApiTypes["ISFG.SpisUm.ClientSide.Models.V2.Common.TransactionHistory"];

export class RetentionProtocolsOrProposalsHistoryApiMapper {
  static mapListToDomain(
    response: ApiListResponse<ApiProtocolOrProposalHistory>
  ) {
    return ApiListResponseMapper.mapListToPagination(
      response,
      RetentionProtocolsOrProposalsHistoryApiMapper.mapToDomain
    );
  }

  static mapToDomain(
    retentionProtocolOrProposalHistory: ApiProtocolOrProposalHistory
  ): RetentionProtocolProposalHistory {
    return {
      description: stringOrDefault(
        retentionProtocolOrProposalHistory.description
      ),
      eventType: stringOrDefault(retentionProtocolOrProposalHistory.eventType),
      eventSource: stringOrDefault(
        retentionProtocolOrProposalHistory.eventSource
      ),
      id: retentionProtocolOrProposalHistory.id,
      nodeId: stringOrDefault(retentionProtocolOrProposalHistory.nodeId),
      nodeType: stringOrDefault(retentionProtocolOrProposalHistory.nodeType),
      sslNodeType: stringOrDefault(
        retentionProtocolOrProposalHistory.sslNodeType
      ),
      occurredAt: stringOrDefault(retentionProtocolOrProposalHistory.occuredAt),
      pid: stringOrDefault(retentionProtocolOrProposalHistory.pid),
      userId: stringOrDefault(retentionProtocolOrProposalHistory.userId),
      userGroupId: stringOrDefault(
        retentionProtocolOrProposalHistory.userGroupId
      )
    };
  }
}
