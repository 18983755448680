import { AlfrescoListResponseMapper } from "infrastructure/api/mapper/AlfrescoListResponseMapper";
import { ApiAlfrescoListResponse } from "infrastructure/api/struct/ApiTypes";
import { PaginatedResult } from "lib/contract/Pagination";
import { NodeVersion } from "presentation/core/api/models";

export const mapGetVersions = (
  response: ApiAlfrescoListResponse<NodeVersion>
): PaginatedResult<NodeVersion> => {
  return AlfrescoListResponseMapper.mapListToPagination(
    response,
    (response) => response
  );
};
