import { ApiTypes } from "../struct/ApiTypesV2";
import { RententionProposalRemoveObject } from "domain/struct/recordRetention/RententionProposalRemoveObject";

export type ApiRemoveObject = ApiTypes["ISFG.SpisUm.ClientSide.Models.V2.Shredding.Response.ShreddingProposalRemoveObjectsResponse"];

export class RetentionProposalRemoveObjectsApiMapper {
  static mapToRemoveObject(
    apiRemoveObject: ApiRemoveObject
  ): RententionProposalRemoveObject {
    return {
      isShreddingProposalCanceled: apiRemoveObject.isShreddingProposalCanceled
    };
  }
}
