import { AllGroupType, UserGroupType } from "../features/login/_types";
import {
  getUserRepositoryRights,
  userHasSealGroup,
  userHasSignGroup,
  UserRepositoryRights
} from "../../share/utils/user";
import { SpisumGroups } from "../../enums";

export interface GroupInfo {
  groupIsDispatch: boolean;
  groupIsRepository: boolean;
  groupIsNameRegister: boolean;
  groupIsMailroom: boolean;
  userCanSignInThisGroup: boolean;
  userCanSealInThisGroup: boolean;
  userRepositoryRights: UserRepositoryRights;
}

export const getGroupInfo = (
  activeGroup: string = "",
  allGroups: AllGroupType,
  userGroups: UserGroupType[]
): GroupInfo => {
  const dispatchGroups = allGroups.dispatch.map((grp) => grp.id);
  const repositoryGroups = allGroups.repository.map((grp) => grp.id);

  const groupIsDispatch = dispatchGroups.includes(activeGroup);
  const groupIsRepository = repositoryGroups.includes(activeGroup);
  const groupIsNameRegister = activeGroup === SpisumGroups.NameRegister;
  const groupIsMailroom = activeGroup === SpisumGroups.Mailroom;

  const userRepositoryRights = groupIsRepository
    ? getUserRepositoryRights(activeGroup, userGroups)
    : UserRepositoryRights.None;

  return {
    groupIsDispatch,
    groupIsRepository,
    groupIsNameRegister,
    groupIsMailroom,
    userCanSignInThisGroup: userHasSignGroup(activeGroup, userGroups),
    userCanSealInThisGroup: userHasSealGroup(activeGroup, userGroups),
    userRepositoryRights
  };
};
