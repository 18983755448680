import { Location } from "./lib/location/entity/Location";
import { NameRegisterPage } from "./page/NameRegisterPage/NameRegisterPage";
import { PersonPage } from "./page/PersonPage/PersonPage";
import { LegalEntityPage } from "./page/LegalEntityPage/LegalEntityPage";

export const nameRegisterLocation = Location.create({
  name: "nameRegister",
  url: "name-register",
  component: NameRegisterPage
});

export const personsLocation = Location.create({
  parent: nameRegisterLocation,
  name: "persons",
  url: "persons",
  component: PersonPage
});

export const legalEntityLocation = Location.create({
  parent: nameRegisterLocation,
  name: "legal-entity",
  url: "legal-entity",
  component: LegalEntityPage
});
