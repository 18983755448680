import { ApiUrl } from "infrastructure/api/struct/ApiTypesV2";
import { HttpClient } from "infrastructure/service/HttpClient";
import { TechnicalDataCarrierDocument } from "../types/types";

const URL: ApiUrl = "/api/app/v2/document/create/technical-data-carrier";

export const registerTechnicalDataCarriesDocument = () => {
  const httpClient = new HttpClient();
  return httpClient.fetchWithThrow<TechnicalDataCarrierDocument>(URL, "POST");
};
