import { DialogDataProps } from "presentation/core/components/dialog/_types";
import { createSafeAction } from "presentation/share/utils/typesafeActions";
import { ActionType } from "typesafe-actions";

export const dialogOpenDataboxDetails = createSafeAction(
  "@dialog/OPEN_DATABOX_DETAILS"
)<DialogDataProps>();

export type DialogOpenDataboxDetailsActionType = ActionType<
  typeof dialogOpenDataboxDetails
>;

export const dialogOpenDataboxNotRegisterDetails = createSafeAction(
  "@dialog/OPEN_DATABOX_NOT_REGISTER_DETAILS"
)<DialogDataProps>();

export type DialogOpenDataboxNotRegisterDetailsActionType = ActionType<
  typeof dialogOpenDataboxNotRegisterDetails
>;
