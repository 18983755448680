import { User } from "domain/struct/administration/User";
import { ApiListResponse, ApiTypes } from "../struct/ApiTypes";
import { PaginatedResult } from "../../../lib/contract/Pagination";
import { ApiListResponseMapper } from "../mapper/ApiListResponseMapper";

export type ApiUser = ApiTypes["ISFG.SpisUm.ClientSide.Models.User.Response.UserResponse"];

export class UserApiMapper {
  static mapListToDomain(
    apiUsers: ApiListResponse<ApiUser>
  ): PaginatedResult<User> {
    return ApiListResponseMapper.mapListToPagination(
      apiUsers,
      UserApiMapper.mapToDomain
    );
  }

  static mapToDomain(apiUser: ApiUser): User {
    return {
      id: apiUser.id || undefined,
      mainGroup: apiUser.mainGroup!,
      // these are getting from separate endpoint
      groups: [],
      signGroups: [],
      email: apiUser.email || undefined,
      firstName: apiUser.firstName || undefined,
      lastName: apiUser.lastName || undefined,
      userName: apiUser.userName || undefined,
      degreeBefore: apiUser.degreeBefore || undefined,
      degreeAfter: apiUser.degreeAfter || undefined,
      userJob: apiUser.userJob || undefined,
      externalUser: apiUser.externalUser || undefined,
      enabled: apiUser.enabled || undefined,
      systemUser: apiUser.systemUser || undefined,
      readOnly: apiUser.readOnly
    };
  }
}
