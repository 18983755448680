import { GenericDocument } from "presentation/core/types";
import { SpisumNodeTypes } from "presentation/enums";
import { ApiTypes } from "infrastructure/api/struct/ApiTypesV2";
import { ExcludeNull } from "presentation/modules/mailroom/features/unfinished/types";

export enum ChangeDocumentIsFavoriteActionType {
  Add = "add",
  Remove = "remove"
}

export type ChangeDocumentIsFavoriteRequestType = {
  items: GenericDocument[];
  nodeType: SpisumNodeTypes;
  actionType: ChangeDocumentIsFavoriteActionType;
};

export type DocumentUpdateUnsettledRequest = ApiTypes["ISFG.SpisUm.ClientSide.Models.V2.Document.Request.DocumentUpdateUnsettledRequest+BodyDefinition"];
export type DocumentGetEvidenceResponse = ApiTypes["ISFG.SpisUm.ClientSide.Models.V2.Document.Response.DocumentGetEvidenceResponse"];
export type EvidenceDocumentDetails = ExcludeNull<DocumentGetEvidenceResponse>;
