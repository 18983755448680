import CreateIcon from "@mui/icons-material/Create";
import InputLabel from "@mui/material/InputLabel";
import clsx from "clsx";
import { useFormikContext } from "formik";
import { Select, TextField } from "formik-mui";
import {
  StyledFieldWide,
  StyledFormControl,
  useStyles
} from "presentation/core/components/dialog/Dialog.styles";
import { BaseField } from "presentation/core/components/form/fields/BaseField";
import FormControlWithError from "presentation/core/components/formControlWithError";
import { PostItemType, PostType } from "presentation/enums";
import { lang, t } from "presentation/translation/i18n";
import React from "react";
import { lastPathMember, translationPath } from "../../../../../utils/getPath";
import { StyledMenuItem } from "../../CreateShipment.styles";
import {
  CreateShipmentFormValues,
  CreateShipmentFormValuesProxy
} from "../../_types";
import AmountToSend from "../common/AmountToSend";
import { PostPriceField } from "./PostPriceField";
import { PostTypeField } from "./PostTypeField";

interface OwnProps {
  values: CreateShipmentFormValues;
  readonly: boolean;
  onSearchSubject?: () => void;
}

export const PostFormFields = ({
  values,
  readonly,
  onSearchSubject
}: OwnProps) => {
  const classes = useStyles();
  const { setFieldValue } = useFormikContext();

  const handlePostItemTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value !== PostItemType.other) {
      setFieldValue(
        lastPathMember(CreateShipmentFormValuesProxy.postItemTypeOther).path,
        ""
      );
    }
  };
  return (
    <>
      <div style={{ display: "flex", position: "relative", width: "100%" }}>
        <StyledFieldWide
          component={TextField}
          data-test-id="create-shipment-post-address1"
          disabled={readonly}
          required={true}
          name={lastPathMember(CreateShipmentFormValuesProxy.address1).path}
          type="text"
          label={t(translationPath(lang.general.addressee))}
        />
        <CreateIcon
          style={{
            margin: "9px",
            position: "absolute",
            right: 0,
            top: "1.25rem",
            cursor: "pointer"
          }}
          onClick={onSearchSubject}
        />
      </div>
      <StyledFieldWide
        component={TextField}
        data-test-id="create-shipment-post-recipientAddressId"
        disabled={readonly}
        required={false}
        name={
          lastPathMember(CreateShipmentFormValuesProxy.recipientAddressId).path
        }
        type="text"
        style={{ display: "none" }}
      />
      <StyledFieldWide
        component={TextField}
        data-test-id="create-shipment-post-address2"
        disabled={readonly}
        required={false}
        name={lastPathMember(CreateShipmentFormValuesProxy.address2).path}
        type="text"
      />
      <StyledFieldWide
        component={TextField}
        data-test-id="create-shipment-post-address3"
        disabled={readonly}
        required={false}
        name={lastPathMember(CreateShipmentFormValuesProxy.address3).path}
        type="text"
      />
      <StyledFieldWide
        component={TextField}
        data-test-id="create-shipment-post-address4"
        disabled={readonly}
        required={false}
        name={lastPathMember(CreateShipmentFormValuesProxy.address4).path}
        type="text"
      />

      <StyledFieldWide
        component={TextField}
        data-test-id="create-shipment-post-addressStreet"
        disabled={true}
        required={true}
        name={lastPathMember(CreateShipmentFormValuesProxy.addressStreet).path}
        type="text"
        label={t(translationPath(lang.general.addressStreet))}
        style={{ width: "30%" }}
      />

      <StyledFieldWide
        component={TextField}
        data-test-id="create-shipment-post-addressCity"
        disabled={true}
        required={true}
        name={lastPathMember(CreateShipmentFormValuesProxy.addressCity).path}
        type="text"
        label={t(translationPath(lang.general.city))}
        style={{ width: "30%" }}
      />

      <StyledFieldWide
        component={TextField}
        data-test-id="create-shipment-post-addressZip"
        disabled={true}
        required={true}
        name={lastPathMember(CreateShipmentFormValuesProxy.addressZip).path}
        type="text"
        label={t(translationPath(lang.general.zipCode))}
        style={{ width: "30%" }}
      />

      <StyledFieldWide
        component={TextField}
        data-test-id="create-shipment-post-addressState"
        disabled={true}
        required={false}
        name={lastPathMember(CreateShipmentFormValuesProxy.addressState).path}
        type="text"
        label={t(translationPath(lang.general.addressState))}
        style={{ width: "30%" }}
      />

      <div
        className={clsx(classes.fullWidth, classes.alignItemsEnd, classes.form)}
      >
        <PostTypeField readonly={readonly} className={classes.gapRight} />

        {values?.postType?.includes(PostType.cashOnDelivery) && (
          <PostPriceField
            readonly={readonly}
            name={
              lastPathMember(
                CreateShipmentFormValuesProxy.postItemCashOnDelivery
              ).path
            }
            label={t(translationPath(lang.general.cashOnDeliveryPrice))}
          />
        )}

        {values?.postType?.includes(PostType.price) && (
          <PostPriceField
            readonly={readonly}
            name={
              lastPathMember(CreateShipmentFormValuesProxy.postItemStatedPrice)
                .path
            }
            label={t(translationPath(lang.enums.postType.price))}
          />
        )}

        <StyledFieldWide
          component={TextField}
          data-test-id="create-shipment-post-postTypeOther"
          disabled={!values?.postType?.includes(PostType.other)}
          required={values?.postType?.includes(PostType.other)}
          name={
            lastPathMember(CreateShipmentFormValuesProxy.postTypeOther).path
          }
          type="text"
          label={t(translationPath(lang.general.postTypeOther))}
          style={{ width: "70%" }}
        />
      </div>

      <div
        className={clsx(classes.fullWidth, classes.alignItemsEnd, classes.form)}
      >
        <FormControlWithError
          component={StyledFormControl}
          name={lastPathMember(CreateShipmentFormValuesProxy.postItemType).path}
        >
          <InputLabel
            htmlFor={
              lastPathMember(CreateShipmentFormValuesProxy.postItemType).path
            }
          >
            {t(translationPath(lang.general.postItemType))}
          </InputLabel>
          <BaseField
            component={Select}
            data-test-id="create-shipment-postItemType"
            disabled={readonly}
            name={
              lastPathMember(CreateShipmentFormValuesProxy.postItemType).path
            }
            inputProps={{
              id: lastPathMember(CreateShipmentFormValuesProxy.postItemType)
                .path,
              onChange: handlePostItemTypeChange
            }}
          >
            {PostItemType &&
              Object.keys(PostItemType)?.map((key) => (
                <StyledMenuItem
                  key={key}
                  value={
                    PostItemType[key as keyof typeof PostItemType] || undefined
                  }
                >
                  {(PostItemType[key as keyof typeof PostItemType] &&
                    t(
                      translationPath(
                        lang.enums.postItemType[
                          key as keyof typeof lang.enums.postItemType
                        ]
                      )
                    )) ||
                    " "}
                </StyledMenuItem>
              ))}
          </BaseField>
        </FormControlWithError>

        <StyledFieldWide
          component={TextField}
          data-test-id="create-shipment-post-postItemTypeOther"
          disabled={!values?.postItemType?.includes(PostType.other)}
          required={values?.postItemType?.includes(PostType.other)}
          name={
            lastPathMember(CreateShipmentFormValuesProxy.postItemTypeOther).path
          }
          type="text"
          label={t(translationPath(lang.general.postItemTypeOther))}
          style={{ width: "70%" }}
        />
      </div>
      <AmountToSend readonly={readonly} />
    </>
  );
};
