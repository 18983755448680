import {} from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

export const useLocalStyles: Function = makeStyles(() =>
  createStyles({
    inputAutocomplete: {
      marginBottom: "10px",
      marginLeft: "20px"
    },
    radioGroupWrapper: {
      width: "100%"
    }
  })
);
