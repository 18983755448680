import { PaginatedResult } from "../../../lib/contract/Pagination";
import {
  ApiAlfrescoListEntry,
  ApiAlfrescoListResponse,
  ApiAlfrescoPagination
} from "../struct/ApiTypes";

export class AlfrescoListResponseMapper {
  static mapListToPagination<ResponseItem, Item>(
    response: ApiAlfrescoListResponse<ResponseItem>,
    mapper: (responseItem: ResponseItem) => Item
  ): PaginatedResult<Item> {
    const { pagination, entries } = response.list;

    return AlfrescoListResponseMapper.toPaginatedResult(
      entries ? AlfrescoListResponseMapper.mapEntries(entries, mapper) : [],
      pagination
    );
  }

  static mapEntries<ResponseItem, Item>(
    entries: ApiAlfrescoListEntry<ResponseItem>[],
    mapper: (responseItem: ResponseItem) => Item
  ) {
    return entries.map((e) => mapper(e.entry));
  }

  static toPaginatedResult<Item>(
    items: Item[],
    pagination?: ApiAlfrescoPagination
  ): PaginatedResult<Item> {
    return {
      items,
      page: AlfrescoListResponseMapper.calculatePage(
        pagination?.maxItems,
        pagination?.skipCount
      ),
      itemsPerPage: pagination?.maxItems || 0,
      total: pagination?.totalItems || 0
    };
  }

  static calculatePage(maxItems: number = 0, skipCount: number = 0) {
    if (!maxItems) {
      return 1;
    }

    return (maxItems + skipCount) / maxItems;
  }
}
