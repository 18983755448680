import {
  DInject,
  DInjectable
} from "presentation/core/features/dependencyInjection";
import { ApiUrls } from "infrastructure/api/struct/ApiTypes";
import { HttpClient, HttpMethod } from "infrastructure/service/HttpClient";
import { UserRepository } from "domain/service/user/UserRepository";
import { ApiUser, UserApiMapper } from "./UserApiMapper";
import { User, UserId } from "../../../domain/struct/user/User";

const ApiUrl: ApiUrls = {
  UserInfo: "/api/app/v1/user/{userId}"
};

@DInjectable()
export class UserApiRepository implements UserRepository {
  constructor(@DInject(HttpClient) protected httpClient: HttpClient) {}

  getById(id: UserId): Promise<User> {
    return this.httpClient
      .fetchWithThrow<ApiUser>(ApiUrl.UserInfo, HttpMethod.Get, {
        urlWildCards: {
          userId: id
        }
      })
      .then(UserApiMapper.mapToDomain);
  }
}
