import { getService } from "presentation/core/features/dependencyInjection";
import { useCallback } from "react";
import { PaginationConfig } from "lib/contract/Pagination";
import { FindErasedDocuments } from "../../../../useCase/recordRetention/FindErasedFileDocuments";

export const useFindFileErasedDocuments = (nodeId: string) => {
  const findErasedFileDocuments = getService(FindErasedDocuments);

  return useCallback(
    (config: PaginationConfig) =>
      findErasedFileDocuments.findErasedDocumentsByFileId(nodeId, config),
    [findErasedFileDocuments, nodeId]
  );
};
