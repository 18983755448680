import {
  DInject,
  DInjectable
} from "presentation/core/features/dependencyInjection";
import { ApiUrls } from "infrastructure/api/struct/ApiTypesV2";
import { HttpClient, HttpMethod } from "infrastructure/service/HttpClient";
import { RetentionProposalRemoveObjectsRepository } from "domain/service/recordRetention/RetentionProposalRemoveObjectsRepository";
import { RetentionProposalRemoveObjectsId } from "../../../domain/struct/recordRetention/RetentionProposalRemoveObjects";
import {
  ApiRemoveObject,
  RetentionProposalRemoveObjectsApiMapper
} from "./RetentionProposalRemoveObjectsApiMapper";
import { RententionProposalRemoveObject } from "../../../domain/struct/recordRetention/RententionProposalRemoveObject";

const URL: ApiUrls = {
  RemoveObject: "/api/app/v2/shredding/proposal/{nodeId}/remove-objects"
};

@DInjectable()
export class RetentionProposalRemoveObjectsApiRepository
  implements RetentionProposalRemoveObjectsRepository {
  constructor(@DInject(HttpClient) protected httpClient: HttpClient) {}
  removeRetentionProposalObjects(
    nodeId: string,
    objectIds: RetentionProposalRemoveObjectsId[],
    reason: string
  ): Promise<RententionProposalRemoveObject> {
    return this.httpClient
      .fetchWithThrow<ApiRemoveObject>(URL.RemoveObject, HttpMethod.Post, {
        bodyJSON: { ids: objectIds, reason: reason },
        urlWildCards: {
          nodeId: nodeId
        }
      })
      .then(RetentionProposalRemoveObjectsApiMapper.mapToRemoveObject);
  }
}
