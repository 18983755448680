import { SignerGroupRepository } from "domain/service/user/SignerGroupRepository";
import { UserRepository } from "domain/service/user/UserRepository";
import { GroupRepository } from "domain/service/user/GroupRepository";
import { DInjectionToken } from "presentation/core/features/dependencyInjection";
export const SignerGroupRepositoryToken = new DInjectionToken<SignerGroupRepository>(
  "domain.user.SignerGroup-repository"
);
export const GroupRepositoryToken = new DInjectionToken<GroupRepository>(
  "domain.user.group-repository"
);
export const UserRepositoryToken = new DInjectionToken<UserRepository>(
  "domain.user.User-repository"
);
