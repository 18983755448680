import React from "react";
import { useSelector } from "react-redux";
import { RootStateType } from "../../../../../../reducers";

interface RepositoryGroupRowProps {
  groupName?: string;
}

const RepositoryGroupRow = ({ groupName }: RepositoryGroupRowProps) => {
  const groups = useSelector(
    (state: RootStateType) => state.loginReducer.global.groups.main
  );
  const displayGroup = groups.find((group) => group.id === groupName);
  if (!displayGroup) {
    return null;
  }
  return <div>{displayGroup.displayName}</div>;
};

export default RepositoryGroupRow;
