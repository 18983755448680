import { administrationMenuItems } from "presentation/modules/administration/administrationMenuItems";
import { dispatchMenuItems } from "presentation/modules/dispatch/dispatchMenuItems";
import { evidenceMenuItems } from "presentation/modules/evidence/evidenceMenuItems";
import { mailRoomMenuItems as mailroomMenuItems } from "presentation/modules/mailroom/mailRoomMenuItems";
import { menuItems as nameRegisterMenuItems } from "presentation/modules/nameRegister/menuItems";
import { recordRetentionProcessMenuItems } from "presentation/modules/recordRetentionProcess/recordRetentionProcessMenuItems";
import { repositoryMenuItems } from "presentation/modules/repository/repositoryMenuItems";
import { signatureBookMenuItems } from "presentation/modules/signatureBook/signatureBookMenuItems";
import { useMemo } from "react";
import {
  PresentationModule,
  PresentationModules
} from "../entities/PresentationModule";
import { useAuthorization } from "./useAuthorization";

export const rootMenuItems = {
  [PresentationModules.MailRoom]: mailroomMenuItems[0],
  [PresentationModules.Evidence]: evidenceMenuItems[0],
  [PresentationModules.Dispatch]: dispatchMenuItems[0],
  [PresentationModules.Repository]: repositoryMenuItems[0],
  [PresentationModules.SignatureBook]: signatureBookMenuItems[0],
  [PresentationModules.RetentionProcess]: recordRetentionProcessMenuItems[0],
  [PresentationModules.NameRegister]: nameRegisterMenuItems,
  [PresentationModules.Administration]: administrationMenuItems[0]
};

export const useMenuItemsWithPermissions = () => {
  const authorization = useAuthorization();

  return useMemo(() => {
    const canViewModule = (name: PresentationModules) =>
      authorization.can("view", new PresentationModule(name));

    const menuItemsOrder = Object.keys(rootMenuItems) as PresentationModules[];

    const menuItems = menuItemsOrder
      .filter(canViewModule)
      .map((moduleName) => rootMenuItems[moduleName]);
    return menuItems;
  }, [authorization]);
};
