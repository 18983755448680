import { getService } from "presentation/core/features/dependencyInjection";
import { PaginationConfig } from "../../../../../lib/contract/Pagination";
import { useCallback } from "react";
import { FindFileConcernedSubject } from "../../../../../useCase/file/FindFileConcernedSubject";

export const useFindFileConcernedSubjects = () => {
  const findFileConcernedSubject = getService(FindFileConcernedSubject);

  return useCallback(
    (id: string, config: PaginationConfig) =>
      findFileConcernedSubject.findAllPaginated(id, config),
    [findFileConcernedSubject]
  );
};
