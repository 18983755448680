import { isObject } from "../../../lib/type";
import { SubjectType } from "./SubjectType";

export type LegalEntityId = string;

export interface LegalEntity {
  readonly id?: LegalEntityId;
  readonly identifier?: string;
  readonly companyFullName?: string;
  readonly fullName?: string;
  readonly firstName?: string;
  readonly identificationNumber?: string;
  readonly taxIdentificationNumber?: string;
  readonly processedAt?: string;
  readonly subjectType: SubjectType;
}

export const isLegalEntity = (
  maybeLegalEntity: unknown
): maybeLegalEntity is LegalEntity => {
  if (!isObject(maybeLegalEntity)) {
    return false;
  }

  const reTyped: { subjectType?: SubjectType } = maybeLegalEntity;

  return reTyped.subjectType === SubjectType.LegalEntity;
};
