import {
  DInject,
  DInjectable
} from "../../../presentation/core/features/dependencyInjection";
import { HttpClient, HttpMethod } from "../../service/HttpClient";
import { ApiUrls } from "../struct/ApiTypesV2";
import { PerformDeletionRepository } from "../../../domain/service/recordRetention/PerformDeletionRepository";

const URL: ApiUrls = {
  PerformDeletionPost: "/api/app/v2/shredding/protocol/{nodeId}/erase"
};

@DInjectable()
export class PerformDeletionApiRepository implements PerformDeletionRepository {
  constructor(@DInject(HttpClient) protected httpClient: HttpClient) {}

  savePerformDeletion(id: string): Promise<void> {
    return this.httpClient.fetchWithThrow(
      URL.PerformDeletionPost,
      HttpMethod.Post,
      {
        urlWildCards: {
          nodeId: id
        }
      }
    );
  }
}
