import { useQM } from "presentation/share/hook/query/useQM";
import { getOtherSettleMethods } from "../api";

export const useGetOtherSettleMethods = () => {
  const {
    data: otherSettleMethodsData,
    isLoading: isOtherSettleMethodsLoading
  } = useQM(["codeList/otherSettleMethods"], () => getOtherSettleMethods());

  return {
    otherSettleMethodsData,
    isOtherSettleMethodsLoading
  };
};
