import AddSharpIcon from "@mui/icons-material/AddSharp";
import RemoveSharpIcon from "@mui/icons-material/RemoveSharp";
import { MenuItem } from "@mui/material";
import { useField } from "formik";
import React, { SyntheticEvent, useEffect, useState } from "react";
import styled from "styled-components";
import { FilePlanGroups } from "../../../../core/features/login/_types";
import { FileMarks } from "./FileMarks";

type FileMarkProps = {
  mark: FilePlanGroups;
  level: number;
  isSearch: boolean;
  onClick?: (event: SyntheticEvent) => void;
};

const itemName = (mark: FilePlanGroups) => {
  let baseName = `${mark.fullyDeterminedFileMark} - ${mark.name}`;
  if (mark.retentionMark && mark.retentionPeriod) {
    baseName += ` (${mark.retentionMark}/${mark.retentionPeriod})`;
  }
  return baseName;
};

const FileMark = ({
  mark,
  level = 0,
  isSearch,
  ...restProps
}: FileMarkProps) => {
  const [openFileMarks, setOpenFileMarks] = useState(isSearch);
  useEffect(() => {
    setOpenFileMarks(isSearch);
  }, [isSearch]);
  const [, , { setValue }] = useField("fileMark");

  const [, , { setValue: setRetentionModeValue }] = useField("retentionMode");

  const openFileMarksOnClick = () => {
    setOpenFileMarks(!openFileMarks);
  };

  const onClick = (event: SyntheticEvent) => {
    restProps?.onClick?.(event);
    setValue(mark.identificator);
    setRetentionModeValue(`${mark.retentionMark}/${mark.retentionPeriod}`);
  };

  return (
    <StyledFakeButton
      key={mark.fullyDeterminedFileMark}
      value={mark.identificator}
    >
      <StyledMenuItemAndIconWrapper level={level}>
        {mark.isParent && (
          <StyledAccordionDiv onClick={openFileMarksOnClick}>
            {openFileMarks ? (
              <RemoveSharpIcon style={{ fontSize: 15 }} />
            ) : (
              <AddSharpIcon style={{ fontSize: 15, cursor: "pointer" }} />
            )}
          </StyledAccordionDiv>
        )}
        {!mark.isParent && (
          <StyledAccordionDiv>
            <AddSharpIcon style={{ fontSize: 15, visibility: "hidden" }} />
          </StyledAccordionDiv>
        )}
        <MenuItem
          key={mark.fullyDeterminedFileMark}
          disabled={mark.isParent}
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: 0
          }}
          value={mark.identificator}
          onClick={onClick}
        >
          {itemName(mark)}
        </MenuItem>
      </StyledMenuItemAndIconWrapper>
      <NestedFileMarksWrapper>
        {openFileMarks && mark.filePlanGroups && (
          <FileMarks
            isSearch={isSearch}
            filePlanGroups={mark.filePlanGroups}
            level={level + 1}
            {...restProps}
          />
        )}
      </NestedFileMarksWrapper>
    </StyledFakeButton>
  );
};

export default FileMark;

const StyledFakeButton = styled.button`
  background-color: transparent;
  border: none;

  &:focus {
    outline: none;
  }
`;

const StyledAccordionDiv = styled.div`
  margin-right: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledMenuItemAndIconWrapper = styled.section<{ level: number }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: ${(props) => props.level}rem;
`;
const NestedFileMarksWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
