import { ApiListResponse, ApiTypes } from "../struct/ApiTypesV2";
import { ApiListResponseMapper } from "../mapper/ApiListResponseMapper";
import { stringOrDefault } from "../../../lib/string";
import { StoredFile } from "../../../domain/struct/repository/StoredFile";

export type ApiRepositoryStoredFilesViewResponse = ApiTypes["ISFG.SpisUm.ClientSide.Models.V2.Repository.RepositoryStoredFilesViewResponse"];

export class StoredFilesApiMapper {
  static mapListToDomain(
    response: ApiListResponse<ApiRepositoryStoredFilesViewResponse>
  ) {
    return ApiListResponseMapper.mapListToPagination(
      response,
      StoredFilesApiMapper.mapToDomain
    );
  }

  static mapToDomain(
    response: ApiRepositoryStoredFilesViewResponse
  ): StoredFile {
    const {
      pid,
      nodeId,
      fileIdentifier,
      subject,
      senderName,
      filePlanId,
      fileMark,
      retentionMode,
      location,
      shreddingYear
    } = response;
    return {
      id: stringOrDefault(nodeId),
      pid: stringOrDefault(pid),
      fileIdentifier: stringOrDefault(fileIdentifier),
      subject: stringOrDefault(subject),
      senderName: stringOrDefault(senderName),
      filePlanId: stringOrDefault(filePlanId),
      fileMark: stringOrDefault(fileMark),
      retentionMode: stringOrDefault(retentionMode),
      location: stringOrDefault(location),
      shreddingYear: stringOrDefault(shreddingYear)
    };
  }
}
