import { TimePicker } from "antd";
import {
  ProcessMethod,
  SettleMethod,
  SettleMethodOther
} from "domain/struct/document/DocumentsForProcessingEnums";
import { DateTimeFormat } from "lib/dateTime";
import moment from "moment";
import { sslPropsProxy } from "presentation/core/types";
import { DatePicker } from "presentation/designSystem/DatePicker/DatePicker";
import { Form, Item, useForm } from "presentation/designSystem/Form/v2/Form";
import { Row } from "presentation/designSystem/Grid/Grid";
import { Input } from "presentation/designSystem/Input/Input";
import { BaseModalProps } from "presentation/designSystem/Modal/useModal";
import { Select } from "presentation/designSystem/Select/Select";
import { useMutation } from "presentation/share/hook/query/useQM";
import { getErrorCodeTranslation } from "presentation/share/utils/errorCodeTranslation";
import {
  lastPathMember,
  translationPath
} from "presentation/share/utils/getPath";
import { t } from "presentation/translation/i18n";
import lang from "presentation/translation/lang";
import { ErrorType } from "presentation/types";
import React, { useState } from "react";
import styled from "styled-components";
import {
  Modal,
  ModalSize
} from "../../../../../../../../packages/react-components/build/Modal/Modal";
import { settleTypeFile } from "../../api";
import {
  SettleTypeFileFormFieldTypes,
  SettleTypeFilesParams
} from "../../types";

export interface SettleTypeFileModals extends BaseModalProps {
  nodeId: string;
  onChange?: (value: SettleMethod) => void;
  isFetching?: boolean;
  options?: {
    label: string;
    value: string;
  }[];
  required?: boolean;
}

const validationDocumentTypeRules = [{ required: true }];

export const SettleTypeFileModal = ({
  onCancel,
  onChange,
  nodeId,
  onOk
}: SettleTypeFileModals) => {
  const getNotificationForError = (error: ErrorType) => {
    return {
      message: error.code
        ? getErrorCodeTranslation(error.code)
        : t(translationPath(lang.dialog.notifications.fileTypeSettledFailed))
    };
  };

  const [visibleOtherSettleReason, setVisibleOtherSettleReason] =
    useState(false);

  const [form] = useForm<SettleTypeFileFormFieldTypes>();

  const onConfirm = async () => {
    try {
      await form.validateFields();
      const { settleMethod, otherSettleMethod, otherSettleReason } =
        form.getFieldsValue();
      mutate({ settleMethod, otherSettleMethod, otherSettleReason, nodeId });
    } catch {}
  };
  const { isLoading, mutate } = useMutation(
    (params: SettleTypeFilesParams) => settleTypeFile(params),
    {
      onSuccess() {
        onOk?.();
      },
      onSuccessNotification: {
        message: t(translationPath(lang.dialog.notifications.fileTypeSettled))
      },
      onErrorNotification: getNotificationForError || {
        message: t(
          translationPath(lang.dialog.notifications.fileTypeSettledFailed)
        )
      }
    }
  );

  function handleChange(value: string) {
    const isSettleMethodOther =
      SettleMethod[value as keyof typeof SettleMethod] ===
      SettleMethod.jinyZpusob;
    setVisibleOtherSettleReason(isSettleMethodOther);
  }

  return (
    <Modal
      open={true}
      title={t(translationPath(lang.dialog.title.typeFileSettleDocument))}
      onCancel={onCancel}
      onOk={onConfirm}
      confirmLoading={isLoading}
      size={ModalSize.Small}
      okText={t(translationPath(lang.modal.ok))}
      cancelText={t(translationPath(lang.modal.cancel))}
    >
      <Form
        form={form}
        initialValues={{
          processedAt: moment(),
          processedTime: moment()
        }}
      >
        <RowStyled justify="space-between">
          <StyledSelectItem
            name="settleMethod"
            label={ProcessMethod.ProcessMethod}
            rules={validationDocumentTypeRules}
            required={true}
          >
            <Select onChange={handleChange} style={{ width: "15rem" }}>
              {Object.keys(SettleMethod).map((key) => (
                <Select.Option key={key} value={key}>
                  {SettleMethod[key as keyof typeof SettleMethod]}
                </Select.Option>
              ))}
            </Select>
          </StyledSelectItem>

          <Item
            name="processedAt"
            label={t(translationPath(lang.general.settleDate))}
            required={true}
          >
            <DatePicker
              disabled={true}
              format={DateTimeFormat.Date}
              name={lastPathMember(sslPropsProxy.settleDate).path}
              style={{ width: "15rem" }}
            />
          </Item>

          <Item
            name="processedTime"
            label={t(translationPath(lang.general.settleTime))}
            required={true}
          >
            <TimePicker
              disabled={true}
              format={DateTimeFormat.HoursMinutes}
              name={lastPathMember(sslPropsProxy.settleTime).path}
              style={{ width: "15rem" }}
            />
          </Item>

          {visibleOtherSettleReason && (
            <StyledSelectItem
              name="otherSettleMethod"
              label={t(translationPath(lang.general.customSettleMethod))}
              rules={validationDocumentTypeRules}
              required={true}
            >
              <Select onChange={onChange} style={{ width: "15rem" }}>
                {Object.keys(SettleMethodOther).map((key) => (
                  <Select.Option key={key} value={key}>
                    {SettleMethodOther[key as keyof typeof SettleMethodOther]}
                  </Select.Option>
                ))}
              </Select>
            </StyledSelectItem>
          )}
        </RowStyled>
        {visibleOtherSettleReason && (
          <Item
            name="otherSettleReason"
            label={t(translationPath(lang.general.justification))}
            rules={[{ max: 30 }, { min: 4 }, { required: true }]}
            required={true}
          >
            <Input style={{ width: "100%" }} />
          </Item>
        )}
      </Form>
    </Modal>
  );
};

const StyledSelectItem = styled(Item)`
  .ant-select {
    max-width: 100%;
  }
`;

const RowStyled = styled(Row)`
  align-items: flex-end;
`;
