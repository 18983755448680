export enum ApiURL {
  ADMIN_CHANGE_PASSWORD = "/api/admin/v1/users/change-password",
  ADMIN_GROUP_MEMBERS = "/api/admin/v1/group/:group/members",
  ADMIN_GROUP_DELETE = "/api/admin/v1/group/:group/delete ",
  ADMIN_MEMBERS = "/api/admin/v1/group/:group/members",
  ADMIN_GROUPS = "/api/admin/v1/group/members",
  ADMIN_USERS_GROUPS = "/api/admin/v1/users/:userId/groups",
  ADMIN_FILE_PLANS = "/api/admin/v1/Sites/Administration/documentLibrary/FilePlans",
  CANCEL_SHIPMENT = "/api/app/v1/shipment/cancel",
  FILE_PLAN_FILE_PLAN_PRINT = "/api/app/v1/file-plan/file-plan-print",
  FILE_PLANS = "/api/app/v1/file-plan/file-plans",
  A_FILE_PLAN_FILE_PLAN_PRINT = "/api/admin/v1/file-plan/file-plan-print",
  A_FILE_PLANS = "/api/admin/v1/file-plan/file-plans",
  COMMENT_CREATE = "/api/app/v1/:nodeType/:nodeId/comment/create",
  COMMENTS = "/api/app/v1/:nodeType/:nodeId/comments",
  CONCEPT = "/api/app/v1/concept/:nodeId",
  CONCEPT_COMPONENT_CONTENT = "/api/app/v1/concept/:nodeId/component/:componentId/content",
  CONCEPT_COMPONENT_CREATE = "/api/app/v1/concept/:nodeId/component/create",
  CONCEPT_COMPONENT_DELETE = "/api/app/v1/concept/:nodeId/component/delete",
  CONCEPT_CANCEL = "/api/app/v1/concept/:nodeId/cancel",
  CONCEPT_CREATE = "/api/app/v1/concept/create",
  CONCEPT_RECOVER = "/api/app/v1/concept/recover",
  CONCEPT_REVERT = "/api/app/v1/concept/:nodeId/revert/:versionId",
  CONCEPT_TO_DOCUMENT = "/api/app/v1/concept/:nodeId/to-document",
  CONCEPT_UPDATE = "/api/app/v1/concept/:nodeId/update",
  DATABOX_ACCOUNTS = "/api/app/v1/databox/accounts",
  DATABOX_DONT_REGISTER = "/api/app/v1/databox/:nodeId/dont-register",
  DATABOX_REFRESH = "/api/app/v1/databox/refresh",
  DATABOX_SHIPMENT_UPDATE = "/api/app/v1/shipment/:nodeId/update/databox",
  DATABOX_STATUS = "/api/app/v1/databox/status",
  DOCUMENT = "/api/app/v1/document/:nodeId",
  DOCUMENT_ADD_TO_FAVORITE = "/api/app/v1/document/favorite/add",
  DOCUMENT_BORROW = "/api/app/v1/document/:nodeId/borrow",
  DOCUMENT_CANCEL = "/api/app/v1/document/:nodeId/cancel",
  DOCUMENT_CANCEL_DISCARD = "/api/app/v1/document/:nodeId/shredding/cancel-discard",
  FILE = "/api/app/v1/file/:nodeId",
  FILE_PLAN_IMPORT = "/api/admin/v1/file-plan/import",
  FILE_EXPORT = "/api/app/v2/file/:nodeId/summary/export",
  DA_IMPORT = "/api/app/v2/shredding/proposal/:nodeId/decision-da/import",
  FILE_CANCEL_DISCARD = "/api/app/v1/file/:nodeId/shredding/cancel-discard",
  DOCUMENT_CANCEL_PROCESSING = "/api/app/v1/document/:nodeId/settle/cancel",
  DOCUMENT_CHANGE_FILE_MARK = "/api/app/v1/document/:nodeId/change-file-mark",
  DOCUMENT_CHANGE_LOCATION = "/api/app/v1/document/:nodeId/change-location",
  DOCUMENT_CHANGE_TO_A = "/api/app/v1/document/:nodeId/change-to-a",
  DOCUMENT_CHANGE_TO_S = "/api/app/v1/document/:nodeId/change-to-s",
  DOCUMENT_COMPONENT_CONTENT = "/api/app/v1/document/:nodeId/component/:componentId/content",
  DOCUMENT_COMPONENT_CREATE = "/api/app/v1/document/:nodeId/component/create",
  DOCUMENT_COMPONENT_DELETE = "/api/app/v1/document/:nodeId/component/delete",
  COMPONENT_UPDATE = "/api/app/v1/:nodeType/:nodeId/component/:componentId/update",
  DOCUMENT_CREATE = "/api/app/v1/document/create",
  DOCUMENT_FOR_SIGNATURE = "/api/app/v1/document/:nodeId/for-signature",
  DOCUMENT_FOUND = "/api/app/v1/document/found",
  DOCUMENT_FROM_SIGNATURE = "/api/app/v1/document/:nodeId/from-signature",
  DOCUMENT_LOST_DESTROYED = "/api/app/v1/document/:nodeId/lost-destroyed",
  OWNER_ACCEPT = "/api/app/v1/:nodeType/:documentId/owner/accept",
  OWNER_CANCEL = "/api/app/v1/:nodeType/:nodeId/owner/cancel",
  OWNER_DECLINE = "/api/app/v1/:nodeType/:nodeId/owner/decline",
  OWNER_HANDOVER = "/api/app/v1/:nodeType/:nodeId/owner/handover",
  DOCUMENT_RECOVER = "/api/app/v1/document/recover",
  DOCUMENT_REGISTER = "/api/app/v2/document/:nodeId/register", // "/api/app/v1/document/:nodeId/register" - used in analog/techn. data carriers
  DOCUMENT_REMOVE_FROM_FAVORITE = "/api/app/v1/document/favorite/remove",
  DOCUMENT_REMOVE_FROM_FILE = "/api/app/v1/file/:nodeId/document/remove",
  DOCUMENT_RETURN_FOR_REWORK = "/api/app/v1/document/:nodeId/return-for-rework",
  DOCUMENT_REVERT = "/api/app/v1/document/:nodeId/revert/:versionId",
  DOCUMENT_SETTLE = "/api/app/v1/document/:nodeId/settle",
  DOCUMENT_SHREDDING_DISCARD = "/api/app/v1/document/:nodeId/shredding/discard",
  DOCUMENT_TO_REPOSITORY = "/api/app/v1/document/to-repository",
  DOCUMENT_UPDATE = "/api/app/v1/document/:nodeId/update",
  EMAIL_ACCOUNTS = "/api/app/v1/email/accounts",
  EMAIL_DONT_REGISTER = "/api/app/v1/email/:nodeId/dont-register",
  EMAIL_INCOMPLETE = "/api/app/v1/email/:nodeId/incomplete",
  EMAIL_REFRESH = "/api/app/v1/email/refresh",
  EMAIL_SHIPMENT_UPDATE = "/api/app/v1/shipment/:nodeId/update/email",
  EMAIL_STATUS = "/api/app/v1/email/status",
  FILE_ADD_TO_FAVORITE = "/api/app/v1/file/favorite/add",
  FILE_BORROW = "/api/app/v1/file/:nodeId/borrow",
  FILE_CANCEL = "/api/app/v1/file/:nodeId/cancel",
  FILE_CHANGE_LOCATION = "/api/app/v1/file/:nodeId/change-location",
  FILE_CHANGE_FILE_MARK = "/api/app/v1/file/:nodeId/change-file-mark",
  FILE_CHANGE_TO_A = "/api/app/v1/file/:nodeId/change-to-a",
  FILE_CHANGE_TO_S = "/api/app/v1/file/:nodeId/change-to-s",
  FILE_CHANGE_VALIDITY_DATE = "/api/admin/v1/file-plan/:nodeId/update-validity",
  FILE_CLOSE = "/api/app/v1/file/:nodeId/close",
  FILE_CREATE = "/api/app/v1/file/create",
  FILE_DOCUMENT_ADD = "/api/app/v1/file/:nodeId/document/add",
  FILE_FOUND = "/api/app/v1/file/found",
  FILE_LOST_DESTROYED = "/api/app/v1/file/:nodeId/lost-destroyed",
  FILE_OPEN = "/api/app/v1/file/:nodeId/open",
  DOCUMENT_CONVERT = "/api/app/v1/document/:nodeId/component/:componentId/convert",
  FILE_RECOVER = "/api/app/v1/file/recover",
  FILE_REMOVE_FROM_FAVORITE = "/api/app/v1/file/favorite/remove",
  FILE_SHREDDING_DISCARD = "/api/app/v1/file/:nodeId/shredding/discard",
  FILE_TO_REPOSITORY = "/api/app/v1/file/to-repository",
  FILE_UPDATE = "/api/app/v1/file/:nodeId/update",
  GROUP_MEMBERS = "/api/app/v1/group/handover-group/:groupId/users",
  SIGHNER_GROUP_MEMBERS = "/api/app/v1/group/sign-group/:groupId/users",
  GROUPS = "/api/app/v1/group/:groupId/members",
  GROUPS_ALL = "/api/app/v1/group/all",
  LOGIN = "/api/auth/v1/authentication/login",
  NODE_CHILDREN = "/api/app/v1/node/:nodeId/children",
  NODE_CONTENT = "/api/app/v1/:nodeType/:nodeId/component/:componentId/content",
  NODE_HISTORY = "/api/app/v1/:nodeType/:nodeId/history",
  SIGNER_CREATE = "/api/app/v1/signer/create",
  SIGNER_GET_STATUS = "/api/app/v1/signer/status",
  SIGNER_CREATE_V2 = "/api/app/v2/signature-book/sign/create",
  SIGNER_GET_STATUS_V2 = "/api/app/v2/signature-book/sign/status",
  SEAL_DOCUMENT = "/api/app/v2/signature-book/document/{nodeId}/component/{componentId}/seal",
  NODE_SECONDARY_CHILDREN = "/api/app/v1/node/:nodeId/secondary-children",
  NODE_DIGITALLY_DELIVERED_PREVIEW = "/api/app/v2/digitally-delivered/{nodeId}/component/{componentId}/preview",
  NODE_DOCUMENT_PREVIEW = "/api/app/v2/document/{nodeId}/component/{componentId}/preview",
  NODE_SHIPMENT_PREVIEW = "/api/app/v2/shipment/{nodeId}/component/{componentId}/preview",
  NODE_CONCEPT_PREVIEW = "/api/app/v2/concept/{nodeId}/component/{componentId}/preview",
  NODE_VERSIONS = "/api/app/v1/node/:nodeId/versions",
  NODES_DOWNLOAD = "/api/app/v1/:nodeType/:nodeId/component/download",
  ORGANIZATION_UNIT_CREATE = "/api/admin/v1/group/create",
  ORGANIZATION_UNIT_UPDATE = "/api/admin/v1/group/:groupId/update",
  PATHS_INFO = "/api/app/v1/paths/all",
  PERSONALLY_SHIPMENT_UPDATE = "/api/app/v1/shipment/:nodeId/update/personally",
  POST_SHIPMENT_UPDATE = "/api/app/v1/shipment/:nodeId/update/post",
  PUBLISH_SHIPMENT_UPDATE = "/api/app/v1/shipment/:nodeId/update/publish",
  RETURN_TO_REPOSITORY = "/api/app/v1/:nodeType/:nodeId/return",
  RETENTION_CREATE_RETENTION_PROPOSAL = "/api/app/v2/shredding/proposal/create",
  SEARCH = "/api/app/v1/search",
  SHIPMENT_CREATE = "/api/app/v1/:nodeType/:nodeId/shipment/create/:sendMode",
  SHIPMENT_DISPATCH_POST = "/api/app/v1/shipment/:nodeId/post/dispatch",
  SHIPMENT_DISPATCH_PUBLISH = "/api/app/v1/shipment/publish/dispatch",
  SHIPMENT_HISTORY = "/api/app/v1/shipment/:nodeId/history",
  SHIPMENT_RESEND = "/api/app/v1/shipment/resend",
  SHIPMENT_RETURN = "/api/app/v1/shipment/return",
  SHIPMENT_SEND = "/api/app/v1/:shipmentType/:nodeId/shipment/send",
  USER_CREATE = "/api/admin/v1/users/create",
  USER_DEACTIVATE = "/api/admin/v1/users/:userId/deactivate",
  USER_GROUPS = "/api/app/v1/user/:personId/groups",
  USER_INFO = "/api/app/v1/user/:personId",
  USER_CHANGE_PASSWORD = "/api/app/v1/user/change-password",
  USER_UPDATE = "/api/admin/v1/users/:userId/update",
  VALIDATE = "/api/auth/v1/authentication/validate",
  QUERIES_PEOPLE = "/api/app/v1/queries/people",
  ADMIN_USERS = "/api/admin/v1/users",
  ADMIN_NODE_CHILDREN = "/api/admin/v1/node/:nodeId/children",
  ADMIN_NODES_DOWNLOAD = "/api/admin/v1/node/download",
  ADMIN_FILE_PLAN_DOWNLOAD = "/api/admin/v1/file-plan/:nodeId/export",
  EXPORT_SHREDDING_PROPOSAL = "/api/app/v2/shredding/proposal/:nodeId/export"
}
