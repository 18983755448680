export enum SortDirection {
  Ascending = "ASC",
  Descending = "DESC"
}

export interface SortingByProperty {
  property: string;
  direction: SortDirection;
}

export type SortingConfig = SortingByProperty[];
