import { PaginatedResult } from "lib/contract/Pagination";
import { PaginationConfig } from "@isfg/lib/build/contract/Pagination";
import { SortingConfig } from "@isfg/lib/build/contract/Sorting";
import { ApiUrls } from "infrastructure/api/struct/ApiTypesV2";
import {
  HttpClient,
  HttpMethod
} from "../../../../../../infrastructure/service/HttpClient";
import {
  ImprintSearchResponse,
  mapImprintSearchResponsePaginated
} from "./mapper";

const URL: ApiUrls = {
  // Selects
  GetSearchGroups: "/api/admin/v2/transaction-history/search/groups",
  GetSearchUsers: "/api/admin/v2/transaction-history/search/users",
  GetSearchEventTypes: "/api/admin/v2/transaction-history/search/event-types",
  GetSearchNodeTypes: "/api/admin/v2/transaction-history/search/node-types",
  // Table
  GetImprintSearch: "/api/admin/v2/transaction-history/search",
  // Export
  GetImprintExport: "/api/admin/v2/transaction-history/search/export"
};

export interface SearchGroupsParams {
  id: string;
  displayName: string;
}

export interface SearchUsersParams {
  id: string;
  displayName: string;
}

export interface SearchEventTypesParams {
  code: string;
  description: string;
}

export interface SearchNodeTypesParams {
  code: string;
  description: string;
}

export interface ImprintSearchParams {
  occuredAtMin?: string;
  occuredAtMax?: string;
  userGroupId?: string;
  userId?: string;
  nodeTypeCode?: string;
  eventTypeCode?: string;
  description?: string;
  pid?: string;
}
//Fetch selects

export const getSearchGroups = (): Promise<SearchGroupsParams[]> => {
  const httpClient = new HttpClient();
  return httpClient.fetchWithThrow<SearchGroupsParams[]>(
    URL.GetSearchGroups,
    "GET"
  );
};

export const getSearchUsers = (): Promise<SearchUsersParams[]> => {
  const httpClient = new HttpClient();
  return httpClient.fetchWithThrow<SearchUsersParams[]>(
    URL.GetSearchUsers,
    "GET"
  );
};

export const getSearchNodeTypes = (): Promise<SearchNodeTypesParams[]> => {
  const httpClient = new HttpClient();
  return httpClient.fetchWithThrow<SearchNodeTypesParams[]>(
    URL.GetSearchNodeTypes,
    "GET"
  );
};

export const getSearchEventTypes = (): Promise<SearchEventTypesParams[]> => {
  const httpClient = new HttpClient();
  return httpClient.fetchWithThrow<SearchEventTypesParams[]>(
    URL.GetSearchEventTypes,
    "GET"
  );
};

// Fetch table
export const getImprintSearch = (
  pagination: PaginationConfig,
  sorting?: SortingConfig,
  params?: ImprintSearchParams
): Promise<PaginatedResult<ImprintSearchResponse>> => {
  const httpClient = new HttpClient();
  return httpClient
    .fetchPaginated<ImprintSearchResponse>({
      url: URL.GetImprintSearch,
      pagination,
      sorting,
      config: {
        params
      }
    })
    .then(mapImprintSearchResponsePaginated);
};

export const getImprintExport = (
  params?: ImprintSearchParams
): Promise<{
  response: ImprintSearchResponse;
  headers: Headers | undefined;
}> => {
  const httpClient = new HttpClient();
  return httpClient.fetchWithReponseHeaders<ImprintSearchResponse>(
    URL.GetImprintExport,
    HttpMethod.Get,
    {
      params
    }
  );
};
