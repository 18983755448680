import { callAsyncAction } from "presentation/core/action";
import { emailIncompleteActionType } from "presentation/core/api/email/_actions";
import { secondaryAction } from "presentation/core/components/dialog/lib/actionsFactory";
import {
  DialogContentType,
  DialogType
} from "presentation/core/components/dialog/_types";
import { documentViewAction__Refresh } from "presentation/core/components/documentView/_actions";
import NamedTitle from "presentation/core/components/namedTitle";
import { GenericDocument } from "presentation/core/types";
import React from "react";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import { IncompleteDocumentContentDialog } from "./IncompleteDocumentContentDialog";
import { IncompleteDocumentFormValues } from "./_types";

export const incompleteDocumentDialogContent: DialogContentType = {
  actions: () => [
    secondaryAction(
      t(translationPath(lang.dialog.buttons.confirm)),
      ({ dispatch, channels, dialogProps, onClose, buttonState }) => {
        const onSuccess = () => {
          onClose();
          dispatch(documentViewAction__Refresh(true));
          dialogProps.onSuccess?.();
        };

        const onError = () => {
          buttonState.setIsPending(false);
          dialogProps.onError?.();
        };

        buttonState.setIsPending(true);

        const { id } = dialogProps.data as GenericDocument;
        const formValues = channels.contentTab?.state
          ?.formValues as IncompleteDocumentFormValues;

        dispatch(
          callAsyncAction({
            action: emailIncompleteActionType,
            onError,
            onSuccess,
            payload: {
              body: formValues.body,
              files: formValues.files,
              nodeId: id,
              subject: formValues.subject
            }
          })
        );
      }
    )
  ],
  content: IncompleteDocumentContentDialog,
  title: (props) => (
    <NamedTitle
      text={t(translationPath(lang.dialog.title.contactTheSender))}
      {...props}
    />
  ),
  type: DialogType.IncompleteDocument
};
