import { createSafeAction } from "../../../../share/utils/typesafeActions";
import { ActionType } from "typesafe-actions";

export const tableActionViewAction__Refresh = createSafeAction(
  "@tableActionView/TABLE_ACTION_LIST_REFRESH_ACTION"
)<boolean>();

export const tableActionViewAction__Clear = createSafeAction(
  "@tableActionView/TABLE_ACTION_LIST_CLEAR_ACTION"
)<boolean>();

export type TableActionType = ActionType<
  typeof tableActionViewAction__Refresh | typeof tableActionViewAction__Clear
>;
