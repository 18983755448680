import { getService } from "presentation/core/features/dependencyInjection";

import { useQM } from "presentation/share/hook/query/useQM";

import { FindHouseNumber } from "../../../../useCase/codelist/findHouseNumber/FindHouseNumber";
import { Config } from "./useFindPersons";

const QUERY_KEY = "codeLists/findHouseNumbers";

export const useFindHouseNumbers = (config: Config) => {
  const findUseCase = getService(FindHouseNumber);

  return useQM(QUERY_KEY, () => findUseCase.findAll(), config);
};
