import { PaginatedResult } from "@isfg/lib/build/contract/Pagination";
import { ApiListResponseMapper } from "infrastructure/api/mapper/ApiListResponseMapper";
import {
  ApiListResponse,
  ApiTypes
} from "infrastructure/api/struct/ApiTypesV2";

export type ImprintSearchResponse = ApiTypes["ISFG.SpisUm.ClientSide.Models.V2.Administration.TransactionHistory.SearchResponse"];

export const mapImprintSearchResponsePaginated = (
  apiResponse: ApiListResponse<ImprintSearchResponse>
): PaginatedResult<ImprintSearchResponse> => {
  return ApiListResponseMapper.mapListToPagination(
    apiResponse,
    (response) => response
  );
};
