import { Subject } from "../../../../../../domain/struct/nameRegistry/Subject";
import { Address } from "../../../../../../domain/struct/nameRegistry/Address";
import { Contact } from "../../../../../../domain/struct/nameRegistry/Contact";
import { SubjectPersistenceLogic } from "./SubjectPersistenceLogic";
import {
  DInject,
  DInjectable
} from "../../../../../core/features/dependencyInjection";
import { SavePerson } from "../../../../../../useCase/nameRegistry/SavePerson";
import { isPerson } from "../../../../../../domain/struct/nameRegistry/Person";
import { SaveLegalEntity } from "../../../../../../useCase/nameRegistry/SaveLegalEntity";
import { PaginatedResult, PaginationConfig } from "lib/contract/Pagination";
import { PaginationMapper } from "../../../../../../lib/PaginationMapper";
import { SaveContact } from "../../../../../../useCase/nameRegistry/contact/SaveContact";
import { applyAddressRules } from "../../../../../../domain/service/nameRegistry/address/addressDomainRules";
import { SubjectType } from "../../../../../../domain/struct/nameRegistry/SubjectType";

@DInjectable()
export class CreateSubjectLogic implements SubjectPersistenceLogic {
  subject: Subject | undefined;
  addresses: Address[] = [];
  contacts: Contact[] = [];

  constructor(
    @DInject(SavePerson) protected savePerson: SavePerson,
    @DInject(SaveLegalEntity) protected saveLegalEntity: SaveLegalEntity,
    @DInject(SaveContact) protected saveContact: SaveContact
  ) {}

  getContactsFromState(): Contact[] | null {
    return this.contacts;
  }
  getAddressesFromState(): Address[] | null {
    return this.addresses;
  }

  disableSubjectTypeSelect(): boolean {
    const isFO = this.subject?.subjectType === SubjectType.Person;
    const disableDueToAddresses = isFO
      ? !!this.addresses.filter(
          (address) =>
            address.addressType === "PERMANENT_RESIDENCE" ||
            address.addressType === "TEMPORARY_RESIDENCE" ||
            address.addressType === "RESIDENCE_ADDRESS_IN_CZ" ||
            address.addressType === "GUARDIANS_ADDRESS"
        ).length
      : !!this.addresses.filter(
          (address) =>
            address.addressType === "COMPANY_HEADQUARTERS" ||
            address.addressType === "COMPANY_BRANCH"
        ).length;

    return (
      !!this.contacts.filter((contact) => contact.contactType === "DATABOX")
        .length ||
      !!this.subject?.id ||
      disableDueToAddresses
    );
  }

  updateContact(contact: Contact): Promise<void> {
    this.contacts = [
      ...this.contacts.filter((contactItem) => contactItem.id !== contact.id),
      contact
    ];
    return Promise.resolve();
  }

  updateAddress(address: Address): Promise<void> {
    this.addresses = [
      ...this.addresses.filter((addressItem) => addressItem.id !== address.id),
      address
    ];
    return Promise.resolve();
  }

  addAddress(address: Address): Promise<void> {
    this.addresses = [
      ...this.addresses,
      {
        ...address,
        id: this.addresses.length
      }
    ];

    return Promise.resolve();
  }

  addContact(contact: Contact): Promise<void> {
    this.contacts = [
      ...this.contacts,
      {
        ...contact,
        id: this.contacts.length
      }
    ];

    return Promise.resolve();
  }

  removeAddress(address: Address): Promise<void> {
    this.addresses = [
      ...this.addresses.filter((addressItem) => addressItem.id !== address.id)
    ];
    return Promise.resolve();
  }

  removeContact(contact: Contact): Promise<void> {
    this.contacts = [
      ...this.contacts.filter((contactItem) => contactItem.id !== contact.id)
    ];
    return Promise.resolve();
  }

  async getAddresses(
    pagination: PaginationConfig
  ): Promise<PaginatedResult<Address>> {
    return PaginationMapper.mapArrayToPagination(this.addresses);
  }

  async getContacts(
    pagination: PaginationConfig
  ): Promise<PaginatedResult<Contact>> {
    return PaginationMapper.mapArrayToPagination(this.contacts);
  }

  save(): Promise<void> {
    if (!this.subject) {
      return Promise.reject(new Error("Subject is empty"));
    }

    const addressesToUpdate = this.addresses.map(applyAddressRules);
    if (isPerson(this.subject)) {
      return this.savePerson.create({
        person: this.subject,
        addresses: addressesToUpdate,
        contacts: this.contacts
      });
    }

    return this.saveLegalEntity.create({
      legalEntity: this.subject,
      addresses: addressesToUpdate,
      contacts: this.contacts
    });
  }

  setSubjectData(subject: Subject): void {
    this.subject = subject;
  }

  getSubjectData(): Subject | undefined {
    return this.subject;
  }

  resetState(): void {
    this.subject = undefined;
    this.addresses = [];
    this.contacts = [];
  }
}
