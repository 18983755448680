import { getService } from "presentation/core/features/dependencyInjection";
import { useCallback } from "react";
import { FindRetentionProtocols } from "../../../../../../../useCase/recordRetention/FindRetentionProtocols";

export const useUploadAcceptanceFromDA = () => {
  const uploadAcceptance = getService(FindRetentionProtocols);

  return useCallback(
    (id: string, file: File) =>
      uploadAcceptance.uploadAcceptanceFromDA(id, file),
    [uploadAcceptance]
  );
};
