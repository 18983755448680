import { openDocumentWithSaveButtonsAction } from "presentation/core/api/document/_actions";
import {
  ControlsBarType,
  DataColumn
} from "presentation/core/components/dataTable/_types";
import { dialogOpenAction } from "presentation/core/components/dialog/_actions";
import { DialogType } from "presentation/core/components/dialog/_types";
import DocumentView from "presentation/core/components/documentView";
import MenuLayout from "presentation/core/components/layout/MenuLayout";
import { SessionType } from "presentation/core/features/login/_types";
import { GenericDocument, genericDocumentProxy } from "presentation/core/types";
import {
  SenderType,
  SitePaths,
  SpisumNamesWithoutPrefix,
  SpisumNodeTypes
} from "presentation/enums";
import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStateType } from "presentation/reducers";
import { handoverDocument } from "presentation/share/components/dialog/documentHandoverDialog/_actions";
import { evidenceCancelDialogOpen } from "presentation/share/components/dialog/evidenceCancelDialog/_actions";
import { lostDestroyedDialogOpen } from "presentation/share/components/dialog/lostDestroyedDialog/_actions";
import { classPath, translationPath } from "presentation/share/utils/getPath";
import { getRelativePath } from "presentation/share/utils/query";
import { isUserInLeadership } from "presentation/share/utils/user";
import { lang, t, withTranslation } from "presentation/translation/i18n";
import { getControls } from "./controls";
import { filePlanColumn } from "presentation/share/components/table/column/document/FilePlan/filePlanColumn";
import { fileMarkColumn } from "presentation/share/components/table/column/document/FilePlan/fileMarkColumn";
import { DisplaySenderNameByType } from "../../../../../share/components/table/column/DisplaySenderNameByType";
import { useModal } from "presentation/designSystem/Modal/useModal";
import { ProcessDocumentModal } from "./modal/ProcessDocumentModal";
import { DocumentsForSealModal } from "./modals/DocumentsForSealModal";
import { ConvertDigitalToAnalogModal } from "../../../../../share/components/modals/conversions/ConvertDigitalToAnalogModal";
import { ConvertAnalogToDigitalModal } from "presentation/share/components/modals/conversions/ConvertAnalogToDigitalModal";
import { CreateNewDocumentFileModal } from "./modals/CreateNewDocumentFileModal";
import { documentViewAction__Refresh } from "presentation/core/components/documentView/_actions";

const defaultColumn: DataColumn<GenericDocument> = {
  getValue: (x) =>
    x?.properties?.ssl?.senderType === "own"
      ? x?.createdAt
      : x?.properties?.ssl?.deliveryDate,
  isDateTime: true,
  keys: [
    classPath(genericDocumentProxy.properties!.ssl!.deliveryDate).path,
    classPath(genericDocumentProxy.createdAt).path
  ],
  label: t(translationPath(lang.general.dateOfEvidence))
};

const getColumns = (session: SessionType): DataColumn<GenericDocument>[] => {
  const columns: DataColumn<GenericDocument>[] = [
    {
      keys: [classPath(genericDocumentProxy.properties!.ssl!.pid).path],
      label: t(translationPath(lang.general.identifier))
    },
    {
      keys: [classPath(genericDocumentProxy.properties!.ssl!.ssid).path],
      label: t(translationPath(lang.general.referenceNumber))
    },
    {
      keys: [classPath(genericDocumentProxy.properties!.ssl!.subject).path],
      label: t(translationPath(lang.general.subject))
    },
    {
      getValue: (row) => <DisplaySenderNameByType ssl={row?.properties?.ssl} />,
      keys: [
        classPath(genericDocumentProxy.properties!.ssl!.senderCompanyName).path,
        classPath(genericDocumentProxy.properties!.ssl!.senderFullName).path
      ],
      label: t(translationPath(lang.general.sender))
    },
    defaultColumn,
    filePlanColumn,
    fileMarkColumn,
    {
      keys: [
        classPath(genericDocumentProxy.properties!.ssl!.retentionMode).path
      ],
      label: t(translationPath(lang.general.retentionMode))
    },
    {
      isDate: true,
      keys: [
        classPath(genericDocumentProxy.properties!.ssl!.settleToDate).path
      ],
      label: t(translationPath(lang.general.settleToDate))
    }
  ];

  if (isUserInLeadership(session)) {
    columns.push({
      keys: [
        classPath(genericDocumentProxy.properties!.cm!.owner?.displayName).path
      ],
      label: t(translationPath(lang.general.owner))
    });
  }

  return columns;
};

const DocumentsForProcessing = () => {
  const [controls, setControls] = useState<ControlsBarType<GenericDocument>>();
  const dispatch = useDispatch();

  const [documentsForSealModal, documentsForSealModalApi] = useModal(
    DocumentsForSealModal
  );
  const [createNewDocumentFileModal, createNewDocumentFileModalApi] = useModal(
    CreateNewDocumentFileModal
  );
  const [processModal, processModalApi] = useModal(ProcessDocumentModal);

  const session = useSelector(
    (state: RootStateType) => state.loginReducer.session
  );
  const userId = useSelector(
    (state: RootStateType) => state.loginReducer.session.user?.id
  );

  const dispatchOpenDialog: (row: GenericDocument) => void = (row) => {
    dispatch(
      openDocumentWithSaveButtonsAction({
        canUploadComponents: row.properties?.ssl?.senderType === SenderType.Own,
        data: row,
        onSuccess: () => {
          dispatch(documentViewAction__Refresh(true));
        }
      })
    );
  };

  const relativePath = useSelector((state: RootStateType) =>
    getRelativePath(
      state.loginReducer.global.paths,
      state.loginReducer.session.activeGroup,
      SitePaths.Evidence,
      SitePaths.Documents,
      SitePaths.ForProcessing
    )
  );

  const handleOpenDescription = (selected: GenericDocument[]) =>
    dispatchOpenDialog(selected[0]);

  const handleCreateNewDocumentFile = (selected: GenericDocument[]) => {
    return createNewDocumentFileModalApi.open({
      document: selected[0],
      nodeId: selected[0].id
    });
  };

  const handleHandoverDocument = (selected: GenericDocument[]) => {
    dispatch(handoverDocument({ data: selected[0] }));
  };

  const handleForSignatureDocument = (selected: GenericDocument[]) => {
    dispatch(
      dialogOpenAction({
        dialogProps: { data: selected[0] },
        dialogType: DialogType.ForSignature
      })
    );
  };
  const handleForSealDocument = (selected: GenericDocument[]) => {
    return documentsForSealModalApi.open({
      nodeId: selected[0].id!
    });
  };

  const handleSendShipment = (selected: GenericDocument[]) => {
    dispatch(
      dialogOpenAction({
        dialogProps: { data: selected[0] },
        dialogType: DialogType.SendShipment
      })
    );
  };

  const handleOpenLostDestroyedDocumentDialog = (
    selected: GenericDocument[]
  ) => {
    dispatch(lostDestroyedDialogOpen({ data: selected[0] }));
  };

  // vyridit dokument
  const handleOpenSettleDocumentDialog = (selected: GenericDocument[]) => {
    processModalApi.open({
      nodeId: selected[0].id
    });
  };

  const handleOpenCancelDialog = (selected: GenericDocument[]) => {
    dispatch(evidenceCancelDialogOpen({ data: selected[0] }));
  };

  const [
    convertDigitalToAnalogModal,
    convertDigitalToAnalogModalApi
  ] = useModal(ConvertDigitalToAnalogModal);

  const [
    convertAnalogToDigitalModal,
    convertAnalogToDigitalModalApi
  ] = useModal(ConvertAnalogToDigitalModal);

  const handleConvertDigitalToAnalog = (selected: GenericDocument[]) => {
    convertDigitalToAnalogModalApi.open({
      nodeId: selected[0].id
    });
  };

  const handleConvertAnalogToDigital = (selected: GenericDocument[]) => {
    convertAnalogToDigitalModalApi.open({
      nodeId: selected[0].id
    });
  };

  const onDataUpdated = useCallback(
    () =>
      getControls({
        handleCreateNewDocumentFile,
        handleForSignatureDocument,
        handleForSealDocument,
        handleHandoverDocument,
        handleOpenCancelDialog,
        handleOpenDescription,
        handleOpenLostDestroyedDocumentDialog,
        handleOpenSettleDocumentDialog,
        handleSendShipment,
        handleConvertDigitalToAnalog,
        handleConvertAnalogToDigital,
        userId
      })
        .then(setControls)
        .catch(() => {
          //noop
        }),

    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <>
      {convertDigitalToAnalogModal}
      {convertAnalogToDigitalModal}
      {documentsForSealModal}
      {createNewDocumentFileModal}
      {processModal}
      <MenuLayout>
        <DocumentView
          children={{
            include: [SpisumNamesWithoutPrefix.IsFavorite],
            relativePath,
            where: `(nodeType='${SpisumNodeTypes.Document}')`
          }}
          columns={getColumns(session)}
          controls={controls}
          customTitle={t(translationPath(lang.table.documentsForProcessing))}
          defaultSortAsc={true}
          defaultSortColumn={defaultColumn}
          handleDoubleClick={dispatchOpenDialog}
          onDataRefreshed={onDataUpdated}
        />
      </MenuLayout>
    </>
  );
};

export default withTranslation()(DocumentsForProcessing);
