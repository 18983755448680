import { AlfrescoListResponseMapper } from "infrastructure/api/mapper/AlfrescoListResponseMapper";
import { ApiAlfrescoListResponse } from "infrastructure/api/struct/ApiTypes";
import { PaginatedResult } from "lib/contract/Pagination";
import { ShipmentDocument } from "presentation/core/types";

export const mapGetShipments = (
  response: ApiAlfrescoListResponse<ShipmentDocument>
): PaginatedResult<ShipmentDocument> => {
  return AlfrescoListResponseMapper.mapListToPagination(
    response,
    (response) => response
  );
};
