import { HttpClient } from "infrastructure/service/HttpClient";
import { DInjectable } from "presentation/core/features/dependencyInjection";
import {
  PaginatedResult,
  PaginationConfig
} from "../../../lib/contract/Pagination";
import { ApiUrls } from "../struct/ApiTypesV2";
import { RetentionProtocolProposalHistory } from "../../../domain/struct/recordRetention/RetentionProtocolProposalHistory";
import { RetentionProtocolsOrProposalsHistoryRepository } from "../../../domain/service/recordRetention/RetentionProtocolsOrProposalsHistoryRepository";
import {
  ApiProtocolOrProposalHistory,
  RetentionProtocolsOrProposalsHistoryApiMapper
} from "./RetentionProtocolsOrProposalsHistoryApiMapper";
import { SortingConfig } from "lib/contract/Sorting";

const URL: ApiUrls = {
  GetRetentionProtocolsOrProposalsHistory:
    "/api/app/v2/shredding/proposal-protocol/{nodeId}/history"
};

@DInjectable()
export class RetentionProtocolsOrProposalsHistoryApiRepository
  implements RetentionProtocolsOrProposalsHistoryRepository {
  constructor(protected httpClient: HttpClient) {}

  findAllPaginated(
    nodeId: string,
    pagination: PaginationConfig,
    sorting?: SortingConfig
  ): Promise<PaginatedResult<RetentionProtocolProposalHistory>> {
    return this.httpClient
      .fetchPaginated<ApiProtocolOrProposalHistory>({
        url: URL.GetRetentionProtocolsOrProposalsHistory,
        pagination,
        sorting,
        config: {
          urlWildCards: {
            nodeId: nodeId
          }
        }
      })
      .then(RetentionProtocolsOrProposalsHistoryApiMapper.mapListToDomain);
  }
}
