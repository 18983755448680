import { RegisterEmailDigitalFormValues } from "../forms/RegisterEmailDigitalForm";
import { UpdateDocument } from "../../../dataBox/unprocessed/hooks/useUpdateDocument";

export const mapDocumentToApi = (
  input: RegisterEmailDigitalFormValues
): UpdateDocument => {
  return {
    attachmentsCount: input.attachmentCount,
    deliveryDate: input.deliveryDate?.toISOString(),
    attachmentsType: input.attachmentType,
    deliveryMode: input.deliveryMode,
    senderAddressId: input.senderAddressId,
    senderContactDataboxId: input.senderContactDataboxId,
    senderContactEmailId: input.senderContactEmailId,
    senderContactPhoneId: input.senderContactPhoneId,
    senderId: input.senderId ? Number(input.senderId) : undefined,
    senderIdent: input.senderIdent,
    senderSsid: input.senderSsid,
    settleToDate: input.settleToDate?.toISOString(),
    subject: input.subject,
    senderCompanyEmployeeUnit: input.senderCompanyEmployeeUnit,
    senderCompanyEmployeeJob: input.senderCompanyEmployeeJob,
    senderCompanyEmployeeName: input.senderCompanyEmployeeJob
  };
};
