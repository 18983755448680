import { useSelector } from "react-redux";
import { RootStateType } from "../../reducers";

export const useActiveGroup = () => {
  const { activeGroup } = useSelector(
    (state: RootStateType) => state?.loginReducer?.session
  );

  return activeGroup;
};
