import { HttpClient, HttpMethod } from "infrastructure/service/HttpClient";
import { DInjectable } from "presentation/core/features/dependencyInjection";
import { ApiUrl } from "../../struct/ApiTypes";
import { DataBoxRepository } from "../../../../domain/service/nameRegistry/isds/DataBoxRepository";
import { DataBox } from "../../../../domain/struct/nameRegistry/DataBox";
import { ApiDataBox, DataBoxApiMapper } from "./DataBoxApiMapper";

const URL: ApiUrl = "/api/app/v1/databox/search";

@DInjectable()
export class DataBoxApiRepository implements DataBoxRepository {
  constructor(protected httpClient: HttpClient) {}

  findOne(id: string, subjectId: string): Promise<DataBox> {
    const params: { databoxId: string; subjectId?: string } = {
      databoxId: id
    };
    if (subjectId) {
      params.subjectId = subjectId;
    }
    return this.httpClient
      .fetchWithThrow<ApiDataBox>(URL, HttpMethod.Get, {
        params
      })
      .then(DataBoxApiMapper.mapToDomain);
  }
}
