import { PaginationConfig } from "lib/contract/Pagination";
import { useCallback } from "react";
import { SortingConfig } from "lib/contract/Sorting";
import { getDocumentForSignature } from "../api";

export const useDocumentsForSignature = () => {
  return useCallback(
    (nodeId: string, config: PaginationConfig, sorting?: SortingConfig) =>
      getDocumentForSignature(nodeId, config, sorting),
    []
  );
};
