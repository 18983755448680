import { t } from "presentation/translation/i18n";
import { Rule } from "../Form/v2/Form";
import { stringMinMax } from "@isfg/react-components/build/Input/InputValidations";
export * from "@isfg/react-components/build/Input/InputValidations";

const getTranslation = (path: string) => t(`_validations:form.${path}`);

export const emailValidation = {
  pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
  message: getTranslation("email")
};
export const phoneNumberValidation = {
  pattern: /^\+?\d+$/,
  message: getTranslation("phoneNumber")
};

export const positiveNumbers = {
  pattern: /^[0-9]+$/,
  message: getTranslation("number.positiveNumbers")
};

export const smallCapitalCharactersWithoutDiacritics = {
  pattern: /^[A-Za-z]+$/,
  message: getTranslation("string.smallCapitalCharactersWithoutDiacritics")
};

export const fiveDigits = {
  pattern: /^\d{5}$/,
  message: getTranslation("number.fiveDigits")
};

export const identificationNumber: Rule[] = [
  {
    type: "string",
    len: 8
  },
  positiveNumbers
];

export const taxIdentificationNumber: Rule = stringMinMax(8, 12);

export const reasonValidation: Rule = stringMinMax(4, 30);
