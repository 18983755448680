// This file doesn't work, it not used anywhere

import { useDocumentId } from "presentation/core/components/dialog/hooks/useDocumentId";
import { DialogTabContentPropsType } from "presentation/core/components/dialog/_types";
import EvidenceComponentsTabContainer from "presentation/modules/evidence/components/components/EvidenceComponentsTabContainer";
import React from "react";

export const ConceptComponentsTab = (props: DialogTabContentPropsType) => {
  const documentId = useDocumentId(props);

  if (documentId) {
    return (
      <EvidenceComponentsTabContainer
        fileIsEncrypted={false}
        nodeId={documentId}
        {...props}
      />
    );
  }

  return <></>;
};
