import {
  DInject,
  DInjectable
} from "presentation/core/features/dependencyInjection";
import { HttpClient, HttpMethod } from "infrastructure/service/HttpClient";
import { RetentionProtocolsErasedRepository } from "domain/service/recordRetention/RetentionProtocolsErasedRepository";
import {
  ApiRepositoryRetentionArchivedOrDestroyedViewResponse,
  ApiRepositoryRetentionErasedProtocolViewResponseOne,
  ApiRepositoryRetentionProtocolsErasedViewResponse,
  RetentionProtocolsErasedApiMapper
} from "./RetentionProtocolsErasedApiMapper";
import { ApiUrls } from "infrastructure/api/struct/ApiTypesV2";
import { PaginatedResult, PaginationConfig } from "lib/contract/Pagination";
import { SortingConfig } from "lib/contract/Sorting";
import { RetentionProtocolsErased } from "../../../domain/struct/recordRetention/RetentionProtocolsErased";
import { RetentionProtocolOrProposalOrErased } from "../../../domain/struct/recordRetention/RetentionProtocolOrProposalOrErased";
import { RetentionArchivedOrDestroyed } from "../../../domain/struct/recordRetention/RetentionArchivedOrDestroyed";

const URL: ApiUrls = {
  GetRepositoryRetentionProtocolsErased:
    "/api/app/v2/shredding/protocols-erased",
  GetOneErasedProtocol: "/api/app/v2/shredding/proposal-protocol/{nodeId}",
  GetArchived:
    "/api/app/v2/shredding/protocols-erased/{nodeId}/archived/content",
  GetDestroyed: "/api/app/v2/shredding/protocols-erased/{nodeId}/erased/content"
};
@DInjectable()
export class RetentionProtocolsErasedApiRepository
  implements RetentionProtocolsErasedRepository {
  constructor(@DInject(HttpClient) protected httpClient: HttpClient) {}

  getRetentionProtocolsErased(
    pagination: PaginationConfig,
    sorting?: SortingConfig
  ): Promise<PaginatedResult<RetentionProtocolsErased>> {
    return this.httpClient
      .fetchPaginated<ApiRepositoryRetentionProtocolsErasedViewResponse>({
        url: URL.GetRepositoryRetentionProtocolsErased,
        pagination,
        sorting
      })
      .then(RetentionProtocolsErasedApiMapper.mapListToDomain);
  }

  findRetentionErasedProtocolById(
    nodeId: string
  ): Promise<RetentionProtocolOrProposalOrErased> {
    return this.httpClient
      .fetchWithThrow<ApiRepositoryRetentionErasedProtocolViewResponseOne>(
        URL.GetOneErasedProtocol,
        HttpMethod.GET,
        {
          urlWildCards: {
            nodeId
          }
        }
      )
      .then(RetentionProtocolsErasedApiMapper.mapToDomainOne);
  }
  findArchived(
    pagination: PaginationConfig,
    nodeId: string
  ): Promise<PaginatedResult<RetentionArchivedOrDestroyed>> {
    return this.httpClient
      .fetchPaginated<ApiRepositoryRetentionArchivedOrDestroyedViewResponse>({
        url: URL.GetArchived,
        pagination,
        config: {
          urlWildCards: {
            nodeId
          }
        }
      })
      .then(
        RetentionProtocolsErasedApiMapper.mapArchivedOrDestroyedListToDomain
      );
  }
  findDestroyed(
    pagination: PaginationConfig,
    nodeId: string
  ): Promise<PaginatedResult<RetentionArchivedOrDestroyed>> {
    return this.httpClient
      .fetchPaginated<ApiRepositoryRetentionArchivedOrDestroyedViewResponse>({
        url: URL.GetDestroyed,
        pagination,
        config: {
          urlWildCards: {
            nodeId
          }
        }
      })
      .then(
        RetentionProtocolsErasedApiMapper.mapArchivedOrDestroyedListToDomain
      );
  }
}
