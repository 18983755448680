import React from "react";
import { useDocumentId } from "../../hooks/useDocumentId";
import { DialogContentPropsType } from "../../_types";
import FormSignatureTabContainer from "./FormSignatureTabContainer";

export const ComponentsFromSignatureTab = (props: DialogContentPropsType) => {
  const documentId = useDocumentId(props);

  if (documentId) {
    return <FormSignatureTabContainer nodeId={documentId} {...props} />;
  }

  return <></>;
};
