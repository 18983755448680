import { useCallback } from "react";
import { getService } from "presentation/core/features/dependencyInjection";
import { PaginationConfig } from "lib/contract/Pagination";
import { SortingConfig } from "lib/contract/Sorting";
import { FindRetentionForShredding } from "useCase/recordRetention/FindRetentionForShredding";

export const useFindRetentionForShredding = () => {
  const findRetentionForShredding = getService(FindRetentionForShredding);

  return useCallback(
    (config: PaginationConfig, sorting?: SortingConfig) =>
      findRetentionForShredding.findAllPaginated(config, sorting),
    [findRetentionForShredding]
  );
};
