import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStateType } from "presentation/reducers";
import { Dispatch } from "redux";
import { StyledNotificationLayout } from "./NotificationsList.styles";
import { notificationAction__Clear, NotificationActionType } from "./_actions";
import { NotificationComponent } from "./_types";
import { Notification } from "./notification/Notification";
import { FileUploadProgressNotification } from "./notification/fileUploadProgressNotification/FileUploadProgressNotification";
// New govDesign notification
// import { Notification } from "presentation/govDesignSystem/Notification/Notification";
// import { uploadNotification } from "presentation/govDesignSystem/Notification/UploadNotification";

const notificationComponents = {
  [NotificationComponent.Default]: Notification,
  [NotificationComponent.FileUploadProgress]: FileUploadProgressNotification
  // New govDesign upload notification
  // [NotificationComponent.FileUploadProgress]: uploadNotification
};

const NotificationsList = () => {
  const dispatch = useDispatch<Dispatch<NotificationActionType>>();
  const queue = useSelector(
    (state: RootStateType) => state.notificationReducer.queue
  );

  const onClose = useCallback(
    (notificationId: string) =>
      dispatch(notificationAction__Clear([notificationId])),
    [dispatch]
  );

  const notifications = useMemo(
    () =>
      queue.map((notification) => {
        const NotificationCom =
          notificationComponents[
            notification.type || NotificationComponent.Default
          ];

        return (
          <NotificationCom
            key={notification.id}
            {...notification}
            onClose={onClose}
          />
        );
      }),
    [onClose, queue]
  );

  return <StyledNotificationLayout>{notifications}</StyledNotificationLayout>;
};

export default NotificationsList;
