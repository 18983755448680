import { AddressTypeCode } from "../../../domain/struct/codelist/AddressType";

/* eslint-disable no-template-curly-in-string */
const dictionary = {
  _common: {
    codes: {
      "0": "Chyba",
      "400": "Chyba 400",
      "401": "Chyba 401 autorizace",
      "404": "Chyba 404",
      "500": "Chyba 500",
      AUTH: "Zadané jméno nebo heslo není správné!"
    }
  },
  _validations: {
    noneOfContent: "Spis neobsahuje žádné dokumenty",
    componentEncrypted: "Komponenta je zaheslovaná",
    componentsNotInOutputMode:
      "Nejsou převedeny všechny komponenty do výstupního formátu",
    componentsForDatabox:
      "Dokument neobsahuje hlavní komponentu nebo není čitelná",
    componentsForEmail: "Dokument neobsahuje čitelné komponenty",
    componentsForTechnicalDataCarrier:
      "Dokument neobsahuje hlavní komponentu nebo není čitelná",
    componentsForOwnSign:
      "Dokument neobsahuje hlavní komponentu nebo není čitelná",
    componentsForOwnSeal:
      "Dokument neobsahuje hlavní komponentu nebo není čitelná",
    noInactiveComponents: "Dokument neobsahuje neaktivní komponenty",
    dateCannotBeOlderThanToday: "Datum nesmí být starší než dnešní",
    dateIsNotValid: "Datum je v nevalidním formátu",
    datesAreTheSame:
      "Datum platnosti od nesmí být stejné jako datum platnosti do",
    forClosedDocuments: "Dokument je vyřízený",
    forClosedFiles: "Spis je uzavřený",
    inSignatureBook: "Dokument je v podpisové knize",
    incorrectPassword: "Špatně zadané heslo",
    inputPassword: "Zadejte heslo",
    inputUsername: "Zadejte uživatelské jméno",
    nodeHandoverIsBeingAccepted: "Probíhá přebírání",
    nodeHandoverIsBeingCancelled: "Předávání se ruší",
    nodeHandoverIsBeingRefused: "Převzetí se odmítá",
    number: "Zadejte číslo",
    positiveNumber: "Zadejte kladné číslo",
    required: "Povinné",
    requiredAdress: "Adresát není vyplněn",
    requiredAttachmentsCount: "Počet příloh není vyplněn",
    listCountAttachments: "Počet listů příloh není vyplněn",
    requiredBody: "Tělo zprávy není vyplněné",
    requiredDateFrom: "Datum od není vyplněn",
    requiredDays: "Počet dní není vyplněn",
    requiredFileMark: "Spisový znak není vyplněn",
    requiredFilePlan: "Spisový plán není vyplněn",
    documentHasEncryptedComponent: "Dokument obsahuje zaheslovanou komponentu",
    requiredSignatureOrSeal: "Dokument je v podpisové knize",
    requiredFormDocument: "Forma dokumentu není vyplněná",
    requiredListCount: "Počet listů není vyplněn",
    requiredListCountAttachments: "Počet listů příloh není vyplněn",
    requiredOwner: "Vlastník není vyplněn",
    requiredPid: "Identifikátor není vyplněn",
    requiredRetentionMode: "Skartační režim není vyplněn",
    requiredSender: "Odesílatel není vyplněn",
    requiredSettleMethod: "Způsob vyřízení není vyplněn",
    requiredSsid: "Číslo jednací není vyplněné",
    requiredSubject: "Předmět není vyplněn",
    requiredComponent: "Dokument neobsahuje žádnou komponentu",
    validFromDateIsOlderThenValidFromDateOfPreviousPlan:
      'Zadané datum nesmí být mladší než datum "platnosti od" předchozího plánu +2 dny',
    validFromDateIsSameOrAfterValidToDate:
      "Zadané datum nesmí být starší než datum platnosti do",
    validToCannotBeEmptyOnNotLastFilePlan:
      "Platnost do nelze smazat - navazuje další spisový plán",
    validToDateIsOlderThenValidToDateOfNextPlan:
      'Zadané datum nesmí být starší než datum "platnosti do" následujícího plánu -2 dny',
    validToDateIsSameOrBeforeValidFromDate:
      "Zadané datum nesmí být mladší než datum platnosti od",
    form: {
      default: "Pole je nevalidní",
      required: "Pole je povinné",
      date: {
        format: "Zadané datum je ve špatném formátu",
        parse: "Zadané datum není validní",
        invalid: "Zadané datum není validní"
      },
      types: {
        string: "Může obsahovat pouze text",
        number: "Může obsahovat pouze čísla",
        url: "Zadaná url není validní",
        email: "Zadaný E-Mail není validní"
      },
      string: {
        len: "Počet znaků musí být ${len}",
        min: "Minimální počet znaků je ${min}",
        max: "Maximální počet znaků je ${max}",
        range: "Počet znaků musí být v rozmezí ${min} až ${max}",
        smallCapitalCharactersWithoutDiacritics:
          "Povolené znaky jsou pouze písmena bez diakritiky."
      },
      number: {
        len: "Zadané číslo musí být ${len}",
        min: "Zadané číslo nesmí být menší než ${min}",
        max: "Zadané číslo nesmí být větší než ${max}",
        range: "Zadané číslo musí být v rozmezí ${min} - ${max}",
        positiveNumbers: "Povolené znaky jsou pouze kladné číslice.",
        fiveDigits: "Pole musí obsahovat 5 číslic."
      },
      email: "E-mail není ve správném formátu.",
      password:
        "Heslo musí obsahovat kombinaci alespoň 3 z těchto skupin: velkého písmena, malého písmena, čísla, speciálního znaku.",
      phoneNumber: "Povolené znaky jsou pouze “+” a číslice."
    }
  },
  comments: {
    add: "Přidat poznámku",
    label: "Text poznámky"
  },
  dashboard: {
    documentDigitization: "o digitalizaci dokumentů",
    isfgYoutubeChannel: "ISFG Youtube kanál",
    moveYourKnowledge: "Posuňte své znalosti",
    technicalSupport: "Technická podpora",
    license: "Licenční podmínky",
    toNewLevel: "na nový level!",
    usersManual: "Příručka"
  },
  dialog: {
    buttons: {
      confirm: "Potvrdit",
      finish: "Dokončit",
      cancel: "Zrušit"
    },
    content: {
      cancelDiscard:
        "Opravdu si přejete zrušit vyřazení tohoto objektu ze skartačního řízení?",
      cancelShipmentSending: "Opravdu si přejete odstranit vybranou zásilku?",

      changeToA: "Opravdu si přejete změnit skartační znak na A?",
      changeToS: "Opravdu si přejete změnit skartační znak na S?",
      conceptToDocument: "Přejete si vybraný koncept povýšit na dokument?",
      confirmHandoverBack: "Opravdu si přejete převzít zpět vybraný objekt?",
      confirmationConcernedSubject:
        "Tento koncept nemá uvedeny žádné dotčené subjekty. Přejete si přesto pokračovat?",
      confirmationDocumentConcernedSubject:
        "Opravdu si přejete odebrat vybraný dotčený subjekt?",
      deactivateUser:
        "Opravdu chcete deaktivovat uživatele “{{id}}”? Tato akce je nevratná",
      deleteOrganizationUnit:
        " Opravdu chcete smazat spisový uzel s názvem “{{name}}”?",
      dispatchPublishShipment: "Opravdu si přejete vypravit vybranou zásilku?",
      encryptedComponentsDatabox:
        "Přijatá datová zpráva obsahuje zaheslovanou komponentu. Přejete si zadat heslo?",
      encryptedComponentsEmail:
        "Přijatý e-mail obsahuje zaheslovanou komponentu. Přejete si zadat heslo?",
      encryptedComponent: "Komponenta je zaheslovaná. Přejete si zadat heslo?",
      incompleteText: `Pokud  jste  e-mail  vyhodnotili  jako  neúplný  nebo nečitelný,
            je  nutné  informovat  odesílatele  o této skutečnosti a případně ho vyzvat,
            aby zaslal nový e-mail a chybu napravil.
            Potvrzením odeslání zprávy pro odesílatele dojde k přesunu tohoto e-mailu do sekce Neevidované.`,
      notRegisterQuestion: "Opravdu chcete rozpracovaný dokument stornovat?",
      recoverDialogQuestion:
        "Opravdu chcete potvrdit nalezení vybraných záznamu ({{count}})?",
      resendShipment: "Přejete si předat vybranou zásilku k vypravení?",
      returnToRepository: "Opravdu si přejete vrátit objekt do spisovny?",
      signingInProgress:
        "Právě probíhá podepisování souboru. Vyčkejte na dokončení akce.",
      SealingInProgress:
        "Právě probíhá pečetění souboru. Vyčkejte na dokončení akce.",
      signingInProgressHelp:
        "Pokud se do 10 sekund, po uzavření podepisovací aplikace, nezobrazí výsledek podepsání,",
      signingInProgressHelpText:
        "klikněte na spodní hlavní lištu a následně se vraťte do okna prohlížeče.",
      submitToRepository:
        "Přejete si vybrané objekty {{count}} odevzdat spisovně?",
      takeOutFromFile:
        "Opravdu si přejete vyjmout vybrané dokumenty ({{count}}) ze spisu?",
      unsavedChanges:
        "Zavřením tohoto okna můžete přijít o neuložené změny. Chcete změny uložit?",
      isDeactivateValue:
        "Opravdu chcete hodnotu deaktivovat? Tato akce je nevratná."
    },
    errors: {
      dateFutureOnly: "Datum může být pouze v budoucnosti",
      datePastOnly: "Datum může být pouze v minulosti",
      importSsDuplicate:
        "Spisový plán obsahuje duplicitní spisový znak [Trideni - PlneUrcenySpisovyZnak].",
      importStructure: "Soubor neodpovídá požadované struktuře.",
      incorrectFormat: "{{val}} není ve správném formátu",
      invalidEmailFormat: "E-mail není ve správném formátu",
      invalidEndDate:
        "Konec platnosti importovaného spisového plánu (datum) nesmí být před jeho začátkem (datum).",
      invalidOperationException:
        "Nahráli jste dokument, který neodpovídá požadavkům.",
      invalidSgCreateDate:
        "Věcná skupina [Trideni - PlneUrcenySpisovyZnak] - [Popis - Nazev] má datum vytvoření (datum) stanoven až po datu otevření (datum).",
      invalidSgEndDate:
        "Věcná skupina [Trideni - PlneUrcenySpisovyZnak] - [Popis - Nazev] má datum otevření (datum) stanoven až po datu uzavření (datum).",
      invalidSgEndDateParent:
        "Datum uzavření věcné skupiny [Trideni - PlneUrcenySpisovyZnak] - [Popis - Nazev] (datum) není starší nebo rovno datu uzavření nadřazené entity (datum).",
      invalidSgStartDateParent:
        "Datum otevření věcné skupiny [Trideni - PlneUrcenySpisovyZnak] - [Popis - Nazev] (datum) není mladší nebo rovno datu otevření nadřazené entity (datum).",
      invalidStartDate:
        "Začátek platnosti importovaného spisového plánu (datum) nenavazuje na konec platnosti toho nejnovějšího (datum).",
      invalidStartDateFirst:
        "Začátek platnosti prvního importovaného spisového plánu (datum) musí být starší nebo rovno dnešnímu datu.",
      max: "Maximální hodnota je {{val}}",
      maxLen: "Maximální počet znaků je {{len}}",
      min: "Minimální hodnota je {{val}}",
      minLen: "Minimální počet znaků je {{len}}",
      newPasswordCannotBeTheSame:
        "'Nové heslo' musí být jiné než 'Původní heslo'",
      newPasswordsAreNotEqual: "'Nové heslo' a 'Nové heslo znovu' se neshodují",
      numberOnly: "Hodnota musí být číslo",
      oldEndDate:
        "Konec platnosti importovaného spisového plánu nesmí být v minulosti.",
      passwordsAreNotEqual: "Hesla se neshodují",
      timePastOnly: "Čas může být pouze v minulosti",
      wrongFormat: "Nesprávný formát"
    },
    subjectDialog: {
      create: "Vytvořit subjekt",
      edit: "Detail subjektu",
      tabs: {
        metadata: "Metadata",
        addresses: "Adresy",
        contacts: "Kontakty",
        history: "Historie",
        concernedContent: "Dotčený obsah"
      }
    },
    contactsDialog: {
      create: "Vytvořit kontakt",
      edit: "Upravit kontakt"
    },
    contactsDeleteDialog: {
      title: "Odebrat kontakt",
      doYouReallyWantToDeleteContact:
        "Opravdu si přejete odebrat vybraný kontakt od subjektu?"
    },
    addressDeleteDialog: {
      title: "Odebrat adresu",
      doYouReallyWantToDeleteAddress:
        "Opravdu si přejete odebrat vybranou adresu od subjektu?"
    },
    addressDialog: {
      create: "Vytvořit adresu",
      edit: "Upravit adresu"
    },
    retentionProposalCancelDaialog: {
      title: "Zrušit skartační návrh"
    },
    convertions: {
      digitalToAnalog: {
        title: "Konverze z digitální do analogové podoby",
        confirmation:
          "Opravdu si přejete převést dokument do analogové podoby?",
        missingFilePlan: "Dokument nemá vyplněný spisový znak.",
        missingComponent:
          "Dokument neobsahuje žádnou komponentu nebo její formát není podporován."
      },
      analogToDigital: {
        title: "Konverze z analogové podoby do digitální",
        missingFilePlan: "Dokument nemá vyplněný spisový znak.",
        missingComponent: "Vložte komponentu."
      }
    },
    forSeal: {
      title: "Žádost o pečeť"
    },
    components: {
      toActivate: "Dokument vyřízený"
    },
    withdrawApplication: {
      withdrawApplicationForSignatureModal: "Stáhnout žádost k podpisu",
      withdrawApplicationForSealModal: "Stáhnout žádost k opečetění",
      reasonForWithdrawal: "Důvod stažení",
      successWithdrawApplication: "Žádost byla stažena."
    },
    removeRetentionProposalObjectForm: {
      title: "Vyřadit ze skartačního návrhu"
    },
    searchSubjectDialog: {
      title: "Vyhledat subjekty"
    },
    subjectContactDetailsModal: {
      address: "Adresa",
      dataBoxId: "ID datové schránky ",
      emailAddress: "E-mailová adresa",
      phoneNumber: "Telefonní číslo"
    },
    showDetailsOfRetentionProposalOrProtocolModal: {
      titleProposal: "Detail návrhu",
      titleProtocol: "Detail protokolu"
    },
    documentForSealModal: {
      title: "K opečetění"
    },
    componentForSealModal: {
      title: "Pečetění",
      cancel: "Zavřít"
    },
    componentForSignModal: {
      title: "Podepisování",
      cancel: "Zavřít"
    },
    performShreddingProcedureModal: {
      reasonOfShreddingOrTransfer: "Důvod zničení nebo přenosu"
    },
    performDeletionModal: {
      doYouReallyWantToPerformDeletion: "Opravdu si přejete provést výmaz?"
    },
    whitelistDialogs: {
      create: {
        title: "Přidat e-mail do whitelistu"
      },
      detail: {
        title: "Detaily e-mailu z whitelistu",
        emailAddress: "E-mailová adresa",
        createdAt: "Datum a čas přidání",
        comment: "Poznámka"
      },
      delete: {
        title: "Odebrat z whitelistu",
        doYouReallyWantToDeleteEmailFromWhitelist:
          "Opravdu si přejete odebrat vybrané e-mailové adresy z whitelistu?"
      }
    },
    form: {
      cancel: "Storno",
      close: "Zavřít",

      confirm: "Potvrdit",
      dontSave: "Neuložit",
      fileMarkNotFound: "Nenalezeny platné spisové znaky.",
      filePlanNotFound: "Nenalezen platný spisový plán.",
      inputPassword: "Zadat heslo",
      notReadable: "Neúplný/nečitelný",
      notRegister: "Neevidovat",
      note: "Pozn.: Změna platnosti může mít vliv na platnost předešlého nebo navazujícího spisového plánu.",
      refresh: "Obnovit",
      register: "Evidovat",
      registerAndRefer: "Evidovat a předat",
      save: "Uložit",
      saveAndRefer: "Uložit a předat",
      sections: {
        attachments: "Přílohy"
      },
      toRegister: "Zaevidovat",
      toRegisterAndRefer: "Zaevidovat a předat",
      validFrom: "Platnost od",
      validTo: "Platnost do",
      personForm: {
        titleBeforeName: "Titul před jménem",
        name: "Jméno",
        surname: "Příjmení",
        titleAfterName: "Titul za jménem",
        dateOfBirth: "Datum narození",
        cityOfBirth: "Místo narození",
        nationality: "Státní příslušnost",
        birthSurname: "Rodné příjmení",
        legalEntity: "Právnická osoba",
        person: "Fyzická osoba",
        subjectType: "Typ subjektu"
      },
      contactForm: {
        contact: "Kontakt",
        emailAddress: "E-mailová adresa",
        phoneNumber: "Telefonní číslo",
        dataBoxID: "ID datové schránky",
        contactType: "Typ kontaktu"
      },
      ShowDetailsOfRetentionProposalOrProtocolForm: {
        pid: "Identifikátor",
        createdAt: "Datum a čas vytvoření",
        shreddingObjectsCount: "Počet objektů",
        authorFullName: "Autor",
        retentionModeDateAndTime: "Datum a čas skartačního řízení",
        reviewer: "Posuzovatel",
        deletionDateAndTime: "Datum a čas výmazu",
        name: "Název"
      },
      ShowDetailsOfErasedDocumentForm: {
        pid: "Identifikátor",
        createdAt: "Datum a čas vytvoření",
        digitalDeliveryDate: "Datum a čas doručení",
        senderSSID: "Číslo jednací odesílatele",
        shreddingObjectsCount: "Počet objektů",
        authorFullName: "Autor",
        retentionModeDateAndTime: "Datum a čas skartačního řízení",
        reviewer: "Posuzovatel",
        deletionDateAndTime: "Datum a čas výmazu",
        name: "Název",
        documentState: "Stav dokumentu"
      },
      dailyImprintSearchForm: {
        search: "Vyhledat",
        delete: "Vymazat"
      }
    },
    handover: {
      organizationlUnit: "Spisový uzel",
      worker: "Pracovník"
    },
    notifications: {
      actionFailed: "Operace se nezdařila.",
      actionSucceeded: "Operace byla úspěšně provedena.",
      addressWasDeleted: "Adresa byla smazána.",
      addressWasNotDeleted: "Subjekt musí mít alespoň jednu adresu.",
      addressTypeAlreadyExists:
        "Subjekt může mít maximálně jednu adresu od každého z těchto typů: trvalé a přechodné bydliště, adresa pobytu na území ČR, adresa opatrovníka.",
      antivirusHarmfulContent:
        "Komponentu nelze nahrát, protože byl zjištěn výskyt škodlivého obsahu.",
      antivirusNotWorking: "Komponentu se nepodařilo ověřit antivirem.",
      atLeastOneAddressMustBeTypeOfCOMPANY_HEADQUARTERS:
        "Alespoň jedna adresa musí být typu Sídlo firmy. ",
      autosaveFailed: "Automatické uložení se nezdařilo.",
      autosaveSucceeded: "Automaticky uloženo.",
      cancelFailed: "Dokument se nepodařilo stornovat.",
      cancelSucceeded: "Dokument byl úspěšně stornován.",
      cancelRetentionProposalFailed: "Operace se nezdařila.",
      cancelRetentionProposalSucceeded: "Operace byla úspěšně provedena.",
      companyNameMustNotBeEmpty: "Název organizace musí být vyplněn.",
      CompanyHeadquartersCantBeDeleted:
        "Subjekt musí mít uvedeno sídlo organizace.",
      componentDeleteOneOrMoreFailed:
        "Jednu nebo více komponent se nepodařilo smazat.",
      componentsDeactivated: "Komponenta byla deaktivována",
      componentsDeactivationFailed: "Komponentu nebylo možné deaktivovat.",
      componentsActivated: "Komponenta byla označena za aktivní.",
      componentsActivationFailed: "Komponentu nebylo možné označit za aktivní.",
      componentDownloadIsPreparing: "Stahování se připravuje.",
      componentUploadFailed: "Soubor se nepodařilo nahrát.",
      componentUploadWasSuccessful: "Soubor byl úspěšně nahrán.",
      contactTypeDataboxAlreadyExists:
        "Subjekt může mít maximálně jeden kontakt typu datová schránka.",
      contactAlreadyExists: "Zadaný kontakt již existuje.",
      contactWasAdded: "Kontakt byl přidán.",
      contactWasEdited: "Kontakt byl upraven.",
      addressWasAdded: "Adresa byla přidána.",
      addressWasEdited: "Adresa byla upravená.",
      contactWasDeleted: "Kontakt byl smazán.",
      contactWasNotAdded: "Kontakt se nepodařilo přidat.",
      addressWasNotAdded: "Adresu se nepodařilo přidat.",
      contactWasNotEdited: "Kontakt se nepodařilo editovat.",
      contactWasNotDeleted: "Subjekt musí mít alespoň jeden kontakt.",
      createOrganizationUnitFailed: "Spisový uzel se nepodařilo vytvořit.",
      createOrganizationUnitSuccess: "Spisový uzel byl vytvořen.",
      createUserFailed: "Uživatele se nepodařilo vytvořit.",
      createUserSucceeded: "Uživatel byl úspěšně vytvořen.",
      deactivateUserFailed: "Uživatele se nepodařilo deaktivovat.",
      deactivateUserSucceeded: "Uživatel byl deaktivován.",
      deleteOrganizationUnitFailed: "Spisový uzel se nepodařilo odstranit.",
      deleteOrganizationUnitSuccess: "Spisový uzel byl úspěšně odstraněn.",
      dataBoxDoesNotMatch:
        "Subjekt nelze vybrat jako odesílatele, neshodují se datové schránky.",
      dataBoxIsMissing: "Musíte vybrat subjekt s nahranou datovou schránkou.",
      dataBoxNotDelivered: "Datová zpráva dosud nebyla doručena.",
      dataBoxIdNotFound: "ID datove schranky se nepodařilo najit.",
      dataBoxDelivered: "Doručenka datové zprávy byla stažena.",
      documentMustHaveFormOfAnalog: "Dokument musí mít analogovou formu.",
      documentForSealFormSucceeded: "Operace byla úspěšně provedena.",
      documentTypeFileSucceeded:
        "Dokument byl úspěšně vložen do typového spisu.",
      documentForSealFormFailed: "Operace se nezdařila.",
      documentTypeFileFailed:
        "Dokument se nepodařilo vložit do typového spisu.",
      selectDifferentFileMark:
        "Zvolte jinou než aktuální hodnotu spisového znaku.",
      emailAddressDoesNotMatch:
        "Subjekt nelze vybrat jako odesílatele, neshodují se e-mailové adresy.",
      emailAddressIsMissing: "Musíte vybrat subjekt s nahraným e-mailem.",
      emailIsDuplicity: "Zadaná e-mailová adresa je již v seznamu.",
      emailWasAddedToWhiteList: "E-mail byl úspěšně přidán do seznamu.",
      emailWasNotAddedToWhiteList: "E-mail se nepodařilo přidat do seznamu.",
      emailWasDeletedFromWhiteList: "E-mail byl úspěšně odebrán ze seznamu.",
      emailWasNotDeletedFromWhiteList:
        "E-mail se nepodařilo odebrat ze seznamu.",
      emailWasSuccessfullyEdited: "E-mail byl úspěšně upraven.",
      emailWasNotSuccessfullyEdited: "E-mail se nepodařilo upravit.",
      fileTypeSettled: "Dokument byl úspěšně vyřízen.",
      fileTypeSettledFailed: "Dokument se nepodařilo vyřídit.",
      invalidFileMark:
        "Operaci nebylo možné vykonat, protože vybraný spisový znak není platný.",
      invalidFilePlan:
        "Operaci nebylo možné vykonat, protože vybraný spisový plán není platný.",
      invalidPassword: "Špatně zadané heslo.",
      legalSubjectExists: "Následující hodnoty již používá jiný subjekt: IČO",
      nameMustNotBeEmpty: "Jméno musí být vyplněno.",
      notFoundAnyRepositories: "Nebyla nalezena žádná spisovna.",
      notPossibleToSaveSubject: "Subjekt se nepodařilo uložit.",
      notPossibleToLoadAcceptanceFromDA: "Přejímku z DA se nepovedlo nahrát.",

      notReferred: "Dokument se nepodařilo předat.",
      notRegistered: "Dokument se nepodařilo zaevidovat.",
      notRegisteredAndReferred: "Dokument se nepodařilo zaevidovat a předat.",
      noShipmentSelected: "Nejdříve vyberte zásilku.",
      objectHaveDaDecision:
        "Návrh nelze zrušit - některé objekty již mají rozhodnutí DA.",
      removeObjectHaveDaDecision:
        "Nelze vyřadit objekty, které již mají rozhodnutí z DA.",
      objectHaveGeneratedSIP:
        "Návrh nelze zrušit - některé objekty již mají vygenerovaný SIP.",
      passwordChangedSuccessfully:
        "Heslo bylo úspěšně změněno, nyní se můžete přihlásit.",
      passwordResetTokenHasExpired:
        "Platnost odkazu vypršela. Proveďte žádost o obnovení hesla znovu.",
      passwordDuplicity: "Heslo nesmí být shodné s ID nebo jménem a příjmením.",
      passwordLength: "Heslo musí být dlouhé aspoň 8 znaků.",
      passwordComplexityRequired:
        "Heslo musí mít min. 8 znaků a musí obsahovat alespoň 3 kategorie znaků (malá a velká písmena, číslo a speciální znak).",
      passwordComplexity:
        "Heslo musí obsahovat kombinaci alespoň 3 z těchto skupin: velkého písmena, malého písmena, čísla, speciálního znaku.",
      subjectWasSaved: "Subjekt byl uložen.",
      removeObjectHaveGeneratedSIP:
        "Nelze vyřadit objekty, které již mají vygenerovaný SIP.",
      referred: "Dokument byl úspěšně předán.",
      refreshSucceeded:
        "Načítání nových zpráv dokončeno. Počet nových zpráv: {{count}}.",
      registered: "Dokument byl úspěšně zaevidován.",
      registeredAndReferred: "Dokument byl úspěšně zaevidován a předán.",
      relationsConcernedSubjectExist:
        "Vybraný subjekt je již stanoven jako dotčený subjekt.",
      removeRetentionProposalObjFailed: "Operace se nezdařila.",
      removeRetentionProposalObjSucceeded: "Operace byla úspěšně provedena.",
      postDocumentForSeal: "Operace byla úspěšně provedena.",
      settleOutputFormat:
        "Operaci nebylo možné vykonat, protože některé komponenty nejsou ve výstupním formátu.",
      settleOutputReadableType:
        "Operaci nebylo možné vykonat, protože některé komponenty nejsou ve výstupním formátu nebo nemají určen Typ nebo Čitelné.",
      settleReadableType:
        "Operaci nebylo možné vykonat, protože některé komponenty nemají určen Typ nebo Čitelné.",
      somethingFailed: "Něco se nepodařilo.",
      settleMandatoryFields:
        "Jeden nebo více dokumentů nemá vyplněný Počet listů, Počet listů příloh, Počet příloh.",
      closeFileSenderIdMissing: "Spis nemá vyplněno ID odesílatele.",
      subjectCanHaveOneCompanyHeadquartersAddress:
        "Subjekt může mít maximálně jednu adresu typu sídlo firmy.",
      subjectMustHaveAtLeastOneAddress:
        "Subjekt musí mít alespoň jednu adresu.",
      subjectMustHaveAtLeastOneContact:
        "Subjekt musí mít uveden alespoň jeden kontakt.",
      passwordSentOnEmail:
        "Na Vaši e-mailovou adresu byl zaslán odkaz pro obnovení hesla.",
      surnameMustNotBeEmpty: "Příjmení musí být vyplněno.",
      shreddingMissingLocation:
        "Nelze vytvořit návrh - některé objekty nemají uvedeno úložné místo",
      shreddingBorrowed:
        "Nelze vytvořit návrh - některé objekty jsou zapůjčeny.",
      shreddingDiscarded:
        "Nelze vytvořit návrh - některé objekty jsou dočasně vyřazeny.",
      updateOrganizationUnitFailed: "Spisový uzel se nepodařilo upravit.",
      updateOrganizationUnitSuccess: "Spisový uzel byl upraven.",
      updateUserFailed: "Uživatele se nepodařilo upravit.",
      userDoesNotExist: "Zadané uživatelské jméno nebylo nalezeno.",
      incorrectPassword: "Původní heslo není správné.",
      updateUserSucceeded: "Uživatel byl úspěšně upraven. ",
      VATMustBeANumber: "IČO musí být číslo.",
      wrongImportStructure: "Vybraný soubor neodpovídá požadované struktuře.",
      addComponent: "Vložte komponentu.",
      wrongFormatDokuments: "Vložte komponentu v předepsaném formátu PDF.",
      componentHasPassword: "Soubor nelze nahrát, je šifrován heslem.",
      typeFileNotClosed: "Typový spis se nepodařilo uzavřít.",
      applicationDoesNotWithdraw: "Žádost nebyla stažena.",
      valueNotDeactivated: "Hodnotu se nepodařilo deaktivovat.",
      valueSuccessDeactivated: "Hodnota byla úspěšně deaktivována.",
      valueUploaded: "Číselník byl importován."
    },
    renameComponent: {
      name: "Jméno"
    },

    tabs: {
      addresses: "Adresy",
      components: "Komponenty",
      InactiveComponents: "Neaktivní komponenty",
      concernedSubjects: "Subjekty",
      contacts: "Kontakty",
      content: "Obsah",
      closed: "Uzavření",
      history: "Historie",
      metadata: "Metadata",
      notes: "Poznámky",
      processOrClose: "Vyřízení/uzavření",
      renditions: "Ztvárnění",
      saveAndDiscard: "Ukládaní a vyřazování",
      settle: "Vyřízení",
      shipment: "Zásilky",
      transactionProtocol: "Transakční protokol",
      version: "Verze"
    },
    title: {
      acceptedDocument: "Přijatý dokument",
      analogDocumentIncome: "Příjem - Analogový dokument",
      borrow: "Zapůjčit",
      cancelProcessing: "Zrušit vyřízení",
      changePassword: "Změnit heslo",
      changeToA: "Změnit na A",
      changeToS: "Změnit na S",
      changeValidityDate: "Upravit platnost",
      closed: "Uzavřít",
      conceptDetails: "Detail konceptu",
      confirmationConcernedSubject: "Upozornění",
      confirmationDocumentConcernedSubject: "Upozornění",
      contactTheSender: "Kontaktovat odesílatele",
      convertToOutputFormat: "Převést do výstupního formátu",
      createSubject: "Vytvořit subjekt",
      databoxDetails: "Detaily zprávy z DS",
      declineHandover: "Odmítnout převzetí",
      deleteOrganizationUnit: "Smazání spisového uzlu",
      typeFileDetail: "Detail typového spisu",
      digitalDocumentIncome: "Příjem - Digitální dokument",
      dispatchShipment: "Vypravit zásilku",
      documentDetail: "Detail dokumentu",
      dontRegister: "Neevidovat",
      emailDetails: "Detaily e-mailu",
      encryptedComponents: "Upozornění",
      enterPassword: "Zadat heslo",
      enterPasswordInput: "Zadejte heslo",
      errorInImport: "Chyba v importu",
      fileDetail: "Detail spisu",
      forgotPassword: "Zapomenuté heslo",
      forSignature: "K podpisu",
      handover: "Předat",
      handoverBack: "Převzít zpět",
      handoverShipmentsToDispatch: "Předat zásilky k vypravení",
      openFile: "Otevřít spis",
      promoteConceptToDocument: "Povýšení konceptu na dokument",
      renameComponent: "Přejmenovat komponentu",
      repositoryAndShreddingPlan: "Spisový a skartační plán",
      resetPassword: "Obnovit heslo",
      resendShipment: "Předat k vypravení",
      returnDocumentForRework: "Vrátit dokument k přepracování",
      sendShipments: "Odeslat zásilky",
      settle: "Vyřídit",
      shipmentDetail: "Detaily zásilky",
      shreddingDiscard: "Vyřadit ze skartačního řízení",
      signatureRequest: "Žádost o podpis",
      signing: "Podepisování",
      submitToRepository: "Odevzdat spisovně",
      takingOutFromFile: "Vyjmutí ze spisu",
      typeFileSettleDocument: "Vyřídit dokument",
      unsavedChanges: "Neuložené změny",
      codeListValueDetail: "Detail číselníku",
      createNewValue: "Vytvořit novou hodnotu"
    }
  },
  documentMetaForm: {
    CDCount: "CD",
    USBCount: "USB",
    createdAt: "Datum a čas vytvoření",
    deliveryDate: "Datum doručení",
    deliveryMode: "Způsob doručení",
    description: "Stručný obsah",
    form: "Forma",
    listCount: "Počet listů",
    openSheetCount: "Volných listů",
    pid: "Identifikátor",
    sender: "Odesílatel",
    senderIdent: "Spisová značka odesílatele",
    senderSSID: "Číslo jednací odesílatele",
    serialNumber: "Podací číslo",
    settleDate: "Vyřídit do",
    ssid: "Číslo jednací",
    state: "Stav spisu",
    subject: "Předmět",
    subjectName: "Název",
    volumesCount: "Svazků"
  },
  enums: {
    deliveryMode: {
      currier: "Kurýr",
      databox: "Datová schránka",
      email: "E-mail",
      personally: "Osobní převzetí",
      post: "Pošta",
      publish: "Zveřejnění",
      takeover: "Převzetím"
    },

    formMode: {
      analog: "analogový",
      digital: "digitální",
      hybrid: "hybridní"
    },

    documentType: {
      analog: "A",
      digital: "D",
      conversionAD: "Konverze AD",
      conversionDA: "Konverze DA"
    },

    stateMode: {
      open: "open",
      closed: "closed"
    },

    documentState: {
      cancelled: "Stornován",
      closed: "Uzavřen",
      open: "Otevřít",
      notSettled: "Nevyřízen",
      referedToArchive: "Předán do archivu",
      referedToRepository: "Předán do spisovny",
      settled: "Vyřízen",
      shredded: "Skartován"
    },
    fileMetaTypes: {
      deliveryNote: "Doručenka",
      enclosure: "Příloha",
      main: "Hlavní komponenta",
      meta: "Metadata",
      signature: "Elektronický podpis",
      signatureVerificationProtocol: "Protokol ověření podpisu"
    },
    postItemType: {
      businessLetter: "Obchodní psaní",
      businessParcel: "Obchodní balík",
      insuredLetter: "Cenné psaní",
      insuredParcel: "Cenný balík",
      internationalBusinessLetter: "Obchodní psaní do zahraničí",
      internationalBusinessParcel: "Obchodní balík do zahraničí",
      internationalInsuredLetter: "Cenné psaní do zahraničí",
      internationalInsuredParcel: "Cenný balík do zahraničí",
      internationalOrdinaryMail: "Obyčejná zásilka do zahraničí",
      internationalOrdinaryMailForTheBlind:
        "Obyčejná slepecká zásilka do zahraničí",
      internationalParcelStandard: "Standartní balík do zahraničí",
      internationalRegisteredMail: "Doporučená zásilka do zahraničí",
      internationalRegisteredMailForTheBlind:
        "Doporučená slepecká zásilka do zahraničí",
      ordinaryLetter: "Obyčejné psaní",
      ordinaryLetterStandard: "Obyčejné psaní - standard",
      ordinaryMailForTheBlind: "Obyčejná slepecká zásilka",
      ordinaryParcel: "Obyčejný balík",
      other: "Jiná",
      registeredMail: "Doporučená zásilka",
      registeredMailForTheBlind: "Doporučená slepecká zásilka",
      registeredMailStandard: "Doporučená zásilka - standard"
    },
    postType: {
      cashOnDelivery: "Dobírka",
      deliveryReport: "Dodejka",
      dontPrologCollectionPeriod: "Neprodlužovat odběrní lhůtu",
      dontPrologPeriod: "Neprodlužovat lhůtu",
      dontPutInPostbox: "Nevkládat do schránky",
      dontReturn: "Nevracet",
      dontReturnPutInPostbox: "Nevracet, vložit do schránky",
      dontSend: "Nedosílat",
      dontStore: "Neukládat",
      fee: "Doplatné",
      firstClass: "Pilné",
      fragile: "Křehké",
      other: "Jiná",
      payout: "Termínovaná výplata",
      price: "Udaná cena",
      prolongCollectionPeriod1month: "Prodloužení odběrní lhůty na 1 měsíc",
      reduceCollectionPeriod10days: "Zkrácení odběrní lhůty na 10 dní",
      reduceCollectionPeriod1month: "Zkrácení odběrní lhůty na 7 dní",
      reduceCollectionPeriod3days: "Zkrácení odběrní lhůty na 3 dny",
      replyMails: "Odpovědní zásilky",
      restrictedDelivery: "Do vlastních rukou",
      restrictedDeliveryExplicitly: "Do vlastních rukou - výhradně",
      subscriptionBasic: "Opakované dodání - běžné",
      subscriptionSpecial: "Opakované dodání - zvláštní",
      unstorable: "Neskladné",
      unstorableII: "Neskladné II"
    },
    settleMethod: {
      document: "Dokumentem",
      documentNote: "Záznamem na dokumentu",
      forward: "Postoupením",
      other: "Jiný způsob",
      takeIntoAccount: "Vzetím na vědomí"
    },
    addressType: {
      [AddressTypeCode.Other]: "Jiná adresa",
      [AddressTypeCode.Mailing]: "Doručovací adresa",
      [AddressTypeCode.PermanentResidence]: "Trvalé bydliště",
      [AddressTypeCode.TemporaryResidence]: "Přechodné bydliště",
      [AddressTypeCode.CompanyHeadquarters]: "Sídlo firmy",
      [AddressTypeCode.CompanyBranch]: "Pobočka firmy",
      [AddressTypeCode.RepresentationAddress]: "Adresa zastoupení",
      [AddressTypeCode.ResidenceAddressInCz]: "Adresa pobytu na území ČR",
      [AddressTypeCode.GuardiansAddress]: "Adresa opatrovníka",
      [AddressTypeCode.POBOX]: "P.O.BOX"
    }
  },
  errorBoundary: {
    closeAndOpenDialog:
      "Nastala neočekávaná chyba, zkuste zavřít a otevřít dialog.",
    defaultMessage: "Nastala neočekávaná chyba, zkuste načíst aplikaci znovu.",
    error: "Chyba",
    globalError:
      "Nastala neočekávaná chyba, zkuste načíst aplikaci znovu nebo přejít na stránku s přihlášením.",
    goToLogin: "Přejít na přihlášení",
    loadContent: "Načíst obsah",
    loadingFailed: "Komponentu se nepodařilo načíst!",
    reload: "Načíst znovu",
    reloadDialogTab: "Nastala neočekávaná chyba, zkuste načíst obsah znovu."
  },
  general: {
    value: "Hodnota",
    CDCount: "CD",
    USBCount: "USB",
    active: "Aktivní",
    add: "Přidat",
    addConcept: "Vytvořit koncept",
    addOrganizationUnit: "Vytvořit spisový uzel",
    addUser: "Přidat uživatele",
    addEmail: "Přidat nový e-mail",
    address: "Adresa",
    addressState: "Stát",
    addressStreet: "Ulice a číslo",
    addressee: "Adresát",
    admin: "Administrátor",
    analog: "Analogová",
    konverzeAD: "Digitální (z analogu)",
    assume: "Převzít",
    attachments: "Přílohy",
    attachmentsCount: "Počet příloh",
    attachmentsAndList: "Počet příloh/listů",
    attachmentsType: "Druh příloh",
    authorFullName: "Vytvořil",
    conversionAuthorFullName: "Jméno a příjmení autora změny datového formátu",
    conversionType: "Druh konverze",
    conversionDocumentSsid: "Číslo jednací dokumentu",
    authorOfTheChange: "Autor změny",
    bookmark: "Označit jako oblíbené",
    bookmarkRemove: "Zrušit označení",
    borrow: "Zapůjčit",
    borrowDate: "Datum zápůjčky",
    borrowReturnDate: "Datum vrácení",
    borrower: "Vypůjčitel",
    cancel: "Stornovat",
    cancelDate: "Datum storna",
    cancelDiscard: "Zrušit vyřazení",
    cancelProcessing: "Zrušit vyřízení",
    cancelProcessingReason: "Důvod zrušení vyřízení",
    cancelReason: "Důvod storna",
    cancelSending: "Zrušit odeslání",
    cancelShipment: "Zrušit zásilku",
    cancelRetentionProposalReason: "Důvod vyřazení",
    removeRetentionProposalObjReason: "Důvod vyřazení",
    canceled: "Storno",
    cashOnDeliveryPrice: "Cena dobírky",
    changeBy: "Změnu provedl",
    changeDate: "Datum a čas změny",
    changeDateVersion: "Datum a čas změny verze",
    changeDescription: "Popis změny",
    changeFileMark: "Změnit spisový znak",
    changeGroup: "Změnit spisový uzel",
    changeToA: "Změnit na A",
    changeToS: "Změnit na S",
    city: "Obec",
    closing: "Uzavření",
    closureDate: "Datum uzavření",
    contact: "Kontakt",
    conversion: "Konverze",
    convertReason: "Důvod převedení",
    convertToOutputFormat: "Převést do výstupního formátu",
    createOrganizationUnit: "Vytvořit spisový uzel",
    createShipment: "Vytvořit zásilku",
    createShreddingProposal: "Vytvořit skartační návrh",
    createUser: "Vytvořit uživatele",
    createdDateAndTime: "Datum a čas vytvoření",
    createdShipments: "Vytvořené zásilky",
    creationDate: "Datum vzniku",
    createdAt: "Datum a čas vytvoření",
    conversionDate: "Datum provedení konverze",
    conversionTime: "Čas provedení konverze",
    component: "Označení vstupu a datum sepsání vstupu",
    currier: "Kurýr",
    customSettleMethod: "Jiný způsob vyřízení",

    cancelShreddingProposal: "Zrušit skartační návrh",
    givenConversion: "Pořadové číslo dané konverze",
    damaged: "Ztráta/Zničení",
    databox: "Datová schránka",
    dataBoxNotExists:
      "Subjekt nelze vybrat jako příjemce, nemá uveden kontakt typu Datová schránka.",
    dataBoxNotMatch:
      "Subjekt nelze vybrat jako odesílatele, neshodují se ID datové schránky.",
    dateAndTimeSafe: "Datum a čas validačního prvku",
    dateFrom: "Datum od",
    dateOfCreation: "Datum vytvoření",
    dateOfEvidence: "Datum evidence",
    dateOfRetention: "Datum skartace",
    deactivateValue: "Deaktivovat hodnotu",
    deleteSubjectsAfter: "Lhůta výmazu subjektů (v letech)",
    deleteLegalPerson: "Výmaz právnických subjektů",
    dateTo: "Datum do",
    daysCount: "Počet dní",
    deactivate: "Deaktivovat",
    activateComponent: "Označit za aktivní",
    decisionDA: "Rozhodnutí DA",
    declineReason: "Důvod odmítnutí",
    delete: "Provést výmaz",
    deleteShipment: "Odstranit zásilku",
    delivery: "Doručeno",
    deliveryDate: "Datum doručení",
    deliveryDateAndTime: "Datum a čas doručení",
    deliveryMode: "Způsob doručení",
    deliveryTime: "Čas doručení",
    description: "Stručný obsah",
    digital: "Digitální",
    discardFromShredding: "Vyřadit ze skartačního řízení",
    discardReason: "Důvod vyřazení",
    discardTill: "Vyřadit do",
    dispatch: "Vypravit",
    dispatchByPublishing: "Vypravení zveřejněním",
    dispatchDate: "Datum odeslání",
    dispatchedDate: "Datum vypravení",
    documentAuthor: "Autor dokumentu",
    documentState: "Stav dokumentu",
    documentsCount: "Počet dokumentů",
    download: "Stáhnout",
    downloadDeliveryShipment: "Stáhnout doručenku datové zprávy",
    showDeactivate: "Zobrazit neaktivní komponenty",
    editOrganizationUnit: "Upravit spisový uzel",
    editStorageLocation: "Upravit úložné místo",
    editUser: "Upravit uživatele",
    editValidity: "Upravit platnost",
    electronicSeal: "Elektronická pečeť",
    electronicSignatureVisualisation: "Vizualizace elektronického podpisu",
    email: "E-mail",
    emailNotMatch:
      "Subjekt nelze vybrat jako odesílatele, neshodují se e-mailové adresy.",
    emailAddress: "E-mailová adresa",
    emailAddressee: "E-mail Adresáta",
    emailOfTheSender: "E-mail Odesílatele",
    empowerment: "Zmocnění",
    erase: "Výmaz",
    eraseDate: "Datum a čas výmazu",
    eventType: "Typ události",
    evidenceDateTime: "Datum a čas evidence",
    existingFile: "Existující spis",
    existingTypeFile: "Existující typový spis",
    export: "Exportovat",
    exportToPDF: "Stáhnout PDF",
    exportToFilePlan: "Export spisového souhrnu",
    fileClosureDate: "Datum a čas uzavření spisu",
    fileIdentifier: "Spisová značka",
    fileMark: "Spisový znak",
    fileOpenReason: "Důvod otevření",
    filePlan: "Spisový plán",
    fileState: "Stav spisu",
    find: "Nalézt",
    firstName: "Jméno",
    firstPage: "První stránka",
    fileName: "Název",
    sealFileName: "Název komponenty",
    isSign: "Elektronický podpis",
    isSealed: "Elektronická pečeť",
    usedTime: "Datum a čas bezpečnostního prvku",
    forSignatureDate: "Datum předání k podpisu",
    forSeal: "K opečetění",
    forSignature: "K podepsání",
    forComponentSeal: "Opečetit a časově orazit",
    forComponentSignature: "Podepsat dokument",
    forSealDate: "Datum předání k opečetění",
    signDocumentForSealDate: "Datum žádosti",
    sealReturnForRework: "Vrátit k přepracování ",
    signatureReturnForRework: "Vrátit k přepracování ",
    forbidFictionDelivery: "Zakázat doručení fikcí",
    form: "Forma",
    documentForm: "Forma dokumentu",
    foundConfirmation: "Potvrzení nalezení",
    foundingDate: "Datum založení",
    from: "z",
    giveBack: "Převzít zpět",
    giveNext: "Předat jinam",
    group: "Spisový uzel",
    groupPrefix: "GROUP_",
    generateSIPwithoutComponents: "Vygenerovat SIP bez komponent",
    exportShreddingProposal: "Sestavit skartační návrh",
    generateCompleteSIP: "Vygenerovat úplný SIP",
    generateSIPforArchiving: "Vygenerovat SIP pro archivaci",
    handOVer: "Předat",
    convertDA: "Konverze D-A",
    convertAD: "Konverze A-D",
    hybrid: "Hybridní",
    id: "ID",
    idDA: "Identifikátor DA",
    login: "Login",
    idDR: "ID DS",
    identifier: "Identifikátor",
    import: "Importovat",
    inputTitleOrLabelOrId:
      "Zadejte Předmět, Spisovou značku nebo Identifikátor",
    isDispatch: "Je výpravna",
    isDispatchBig: "Je Výpravna",
    isMailroom: "Je podatelna",
    isRepository: "Je spisovna",
    isRepositoryBig: "Je Spisovna",
    justification: "Odůvodnění",
    lastName: "Příjmení",
    lastPage: "Poslední stránka",
    lastReadDate: "Datum posledního načtení: {{date}}",
    lawNumber: "Číslo zákona",
    letter: "Písmeno",
    listCount: "Počet listů",
    safertyElements: "Bezpečnostní prvky",
    listCountAttachments: "Počet listů příloh",
    loading: "Načítání...",
    location: "Úložné místo",
    lock: "Uzavřít",
    lostDate: "Datum ztráty/zničení",
    lostReason: "Důvod ztráty/zničení",
    loadDecisionOfTheDA: "Načíst rozhodnutí z DA",
    loadAcceptanceFromDA: "Načíst přejímku z DA",

    mailNotExists:
      "Subjekt nelze vybrat jako příjemce, nemá uveden kontakt typu E-mail.",
    mainGroup: "Hlavní spisový uzel",
    manageShipments: "Spravovat zásilky",
    messageBody: "Tělo zprávy",
    modifiedAtOfVersion: "Datum a čas vytvoření verze",
    moreThan: "více jak",
    municipality: "Obec",
    name: "Název",
    nameOfRetentionProposal: "Název skartačního návrhu",
    nameSurname: "Jméno a příjmení",
    narration: "Popis",
    newFile: "Nový spis",
    newFileDisabled: "Nejsou vyplněny údaje o odesílateli",
    newPassword: "Nové heslo",
    newPassword2: "Nové heslo znovu",
    nextOwner: "Nový zpracovatel",
    nextPage: "Další stránka",
    no: "Ne",
    noOptionsText: "Žádné možnosti",
    notRegister: "Neevidovat",
    notRegisterReason: "Důvod neevidování",
    notValid: "Neúplné/nečitelné",
    note: "Poznámka",
    oldPassword: "Původní heslo",
    open: "Otevřít",
    openSheetCount: "Volných listů",
    orgAddress: "Sídlo organizace",
    orgName: "Název organizace",
    orgUnit: "Organizační útvar",
    organizationUnit: "Spisovy uzel",
    originalDestinationHandover: "Původní cíl předání",
    originalDispatchDate: "Datum původního odeslání",
    own: "Vlastní",
    ownHands: "Vlastní ruce",
    owner: "Vlastník",
    paragraph: "Odstavec",
    passedFrom: "Předáno od",
    passwordAgain: "Heslo znovu",
    pasteToFile: "Vložit do spisu",
    personally: "Osobní převzetí",
    pid: "Identifikátor",
    post: "Pošta",
    postItemAmount: "Odesílané množství",
    postItemAttachmentsNumber: "Počet listů příloh",
    postItemAttachmentsKind: "Druh příloh",
    postItemListNumber: "Počet listů",
    postItemListAttachmentsNumber: "Počet příloh",
    postItemId: "ID zásilky",
    postItemNumber: "Podací číslo zásilky",
    postItemPrice: "Poplatek",
    postItemType: "Druh poštovní zásilky",
    postItemTypeOther: "Druh poštovní zásilky - jiná",
    postType: "Druh poštovní služby",
    postTypeOther: "Druh poštovní služby - jiná",
    previousPage: "Předchozí stránka",
    print: "Tisk",
    printEnvelopes: "Tisk spisové obálky",
    printInventoryDocuments: "Tisk soupisu dokumentů",
    printPostSheet: "Tisk poštovního archu",
    processor: "Zpracovatel",
    publish: "Povýšit na dokument",
    performShreddingProcedure: "Provést skartační řízení",
    performDeletion: "Provést výmaz",
    readable: "Čitelné",
    reasonForCancel: "Důvod storna",
    reasonForNotRegister: "Důvod neevidování",
    reasonForRecover: "Důvod zrušení storna",
    reasonForReturn: "Důvod vrácení",
    recipient: "Příjemce",
    recipientDataboxId: "ID datové schránky adresáta",
    referenceNumber: "Číslo jednací",
    refreshEmails: "Načíst e-maily",
    refreshMessages: "Načíst zprávy",
    refreshPendingTableText: "Probíhá načítání zpráv...",
    refuse: "Odmítnout",
    register: "Evidovat",
    relationConcernedSubjectExists:
      "Vybraný subjekt je již stanoven jako dotčený subjekt tohoto dokumentu.",
    remove: "Odebrat",
    removeOrganizationUnit: "Odstranit spisový uzel",
    removalDate: "Datum výmazu záznamu",
    rename: "Přejmenovat",
    repository: "Spisovna:",
    repositoryName: "Název spisovny",
    requestDate: "Datum žádosti",
    resend: "Znovu odeslat",
    restore: "Zrušit storno",
    retentionMode: "Skartační režim",
    return: "Vrátit",
    returnForRework: "Vrátit k přepracování",
    returnShipment: "Vrátit zásilku",
    returnedForReworkDate: "Datum vrácení k přepracování",
    returnedShipmentsFromDispatch: "Zásilky vrácené z výpravny",
    revertVersion: "Obnovit verzi",
    reviewerFullName: "Posuzovatel",
    save: "Uložit",
    search: "Vyhledávání",
    send: "Odeslat",
    sendFromDatabox: "Odeslat z datové schránky",
    sendFromEmail: "Odeslat z emailu",
    sendMode: "Způsob odeslání",
    sendTodispatchAgain: "Znovu předat k vypravení",
    sender: "Odesílatel",
    senderIdent: "Spisová značka odesílatele",
    senderIndividualPerson: "Fyzická osoba",
    senderJob: "Pracovní pozice",
    titleBeforeName: "Titul před jménem",
    titleAfterName: "Titul za jménem",
    externalUser: "Externí uživatel",
    senderLegalPerson: "Právnická osoba",
    senderOwn: "Vlastní",
    senderSSID: "Číslo jednací odesílatele",
    serialNumber: "Podací číslo",
    settle: "Vyřídit",
    settleDate: "Datum vyřízení",
    settleDateAndTime: "Datum a čas vyřízení",
    settleMethod: "Způsob vyřízení",
    settleReason: "Odůvodnění",
    settleTime: "Čas vyřízení",
    settleToDate: "Vyřídit do",
    showDetail: "Zobrazit detail",
    showDetails: "Zobrazit detaily",
    showPreview: "Zobrazit náhled",
    showShipment: "Zobrazit zásilku",
    shreddingDate: "Datum skartačního řízení",
    shreddingObjectsCount: "Počet objektů",
    shreddingResolution: "Výsledek skartačního řízení",
    shreddingYear: "Rok skartace",
    shreddingOperationDateTime: "Datum a čas skartačního řízení",
    signAndTimeStamp: "Podepsat a časově orazit",
    signInOrgGroup: "Podepisování ve sp. uzlu",
    signedAuthor: "Autor podpisu",
    signedDate: "Datum podepsaní",
    sip: "SIP",
    sipForArchive: "SIP pro archivaci",
    size: "Velikost",
    ssid: "Číslo jednací",
    state: "Stav",
    storageLocation: "Úložné místo",
    streetAndStreetNumber: "Ulice a číslo",
    subject: "Předmět",
    takeOutFromFile: "Vyjmout ze spisu",
    takeover: "Převzetím",
    targetRecipient: "Cílový příjemce",
    toApprove: "Ke schválení",
    toArchiveShreddingDate: "Datum a čas předání do archivu nebo skartaci",
    toDigitalDeliveryDate: "Datum a čas předání do DA",
    toDispatchDate: "Datum předání výpravně",
    toDocument: "Povýšit na dokument",
    toHands: "K rukám",
    toOwnHands: "Do vlastních rukou",
    toPublish: "Zveřejnit",
    toRegistry: "Odevzdat spisovně",
    toRepositoryDate: "Datum a čas předání do spisovny",
    toSign: "K podepsání",
    totalSize: "Celková velikost",
    triggerActionId: "Spouštěcí událost",
    triggerActionYear: "Rok spouštecí události",
    type: "Typ",
    typeOfDelivery: "Druh zásilky",
    unclassified: "Nezařazeno",
    updateOrganizationUnit: "Upravit spisový uzel",
    uploadAttachments: "Nahrát přílohy",
    uploadComponents: "Nahrát komponentu",
    uploadNewVersion: "Nahrát novou verzi",
    userId: "Identifikátor fyzické osoby",
    userOrgId: "Identifikátor organizace",
    validFrom: "Platnost od",
    validTo: "Platnost do",
    versionAuthor: "Autor verze",
    versionId: "Číslo verze",
    volumesCount: "Svazků",
    weight: "Váha",
    year: "Rok",
    yearOfShredding: "Rok skartace",
    yes: "Ano",
    zipCode: "PSČ"
  },
  login: {
    checking: "Kontrola",
    error: "Při přihlášení došlo k chybě",
    forgotPassword: "Zapomenuté heslo",
    loginFailed: "Přihlášení se nezdařilo",
    logoAlt: "spisUm",
    password: "Heslo",
    rememberMe: "Zapamatovat přihlášení",
    signUp: "Přihlásit se",
    username: "Uživatelské jméno"
  },
  logout: {
    automaticLogout: "Automatické odhlášení",
    dontLogout: "Neodhlašovat",
    error: "Při odhlašování došlo k chybě",
    logout: "Odhlásit",
    warningMinutes:
      "Z důvodu neaktivity budete odhlášeni do <b>{{minutes}} minut</b>",
    warningSeconds:
      "Z důvodu neaktivity budete odhlášeni za <b>{{seconds}}s</b>"
  },
  menu: {
    items: {
      administration: "Administrace",
      analogDocument: "Analogový dokument",
      archived: "Archivováno",
      borrowed: "Vypůjčené",
      byEmail: "E-mailem",
      byHandOver: "Převzetím",
      byPost: "Poštou",
      byPublishing: "Zveřejnění",
      cancel: "Stornované",
      certificate: "Správa certifikátů",
      closed: "Uzavřené",
      codeList: "Správa číselníků",
      value: "Hodnota",
      successCreatedValue: "Hodnota byla úspěšně vytvořena.",
      concepts: "Koncepty",
      conversion: "Evidence konverzí",
      created: "Vytvořena",
      dailyImprint: " Denní otisk",
      dailyImprintSearchOccuredMin: "Datum a čas od",
      dailyImprintSearchOccuredMax: "Datum a čas do",
      dailyImprintSearchDescription: "Popis změny",
      organizationlUnit: "Spisový uzel",
      authorOfChange: "Autor změny",
      objectType: "Typ objektu",
      eventType: "Typ změny",
      dataBox: "Datová schránka",
      dataRepository: "Datovou schránkou",
      decrypted: "Odheslování komponenty proběhlo úspěšně.",
      destroyed: "Zničeno",
      digitalDocument: "Digitální dokument",
      dispatch: "Výpravna",
      dispatched: "Vypravené",
      dispatching: "Odesílání",
      documents: "Dokumenty",
      emailBox: "E-mailová schránka",
      evidence: "Evidence",
      favorites: "Označené",
      filePlans: "Spisové plány",
      files: "Spisy",
      forProcessing: "K vyřízení",
      forSignature: "K podpisu",
      forSeal: "K opečetění",
      income: "Příjem",
      lostDestroyed: "Ztracené / zničené",
      mailroom: "Podatelna",
      notPassed: "Nepředané",
      notRegistered: "Neevidované",
      open: "Otevřené",
      organizationUnits: "Spisové uzly",
      overview: "Přehled",
      overviews: "Přehledy",
      processed: "Vyřízené",

      recordRetentionProcess: "Skartační řízení",
      rejectedByTakeover: "Odmítnuté převzetí",
      rented: "Zapůjčené",
      rentedDocuments: "Zapůjčené dokumenty",
      repository: "Spisovna",
      retentionProposal: "Skartační návrhy",
      retentionProtocol: "Skartační protokoly",
      retentionBeforeDeletion: "Před výmazem",
      retentionAfterDeletion: "Po výmazu",
      returnedForRework: "Vrácené k přepracování",
      returnedFromDispatch: "Vrácené z výpravny",
      sent: "Odeslané",
      signatureBook: "Podpisová kniha",
      signed: "Podepsané",
      storedDocuments: "Uložené dokumenty",
      storedFiles: "Uložené spisy",
      technicalDataCarries: "Technické nosiče dat",
      toDispatch: "K vypravení",
      toDispose: "Ke skartaci",
      toTakeOver: "K převzetí",
      transactionProtocol: "Transakční protokol",
      typeFiles: "Typové spisy",
      unfinished: "Rozpracované",
      unprocessed: "Nezpracované",
      users: "Uživatelé",
      nameRegister: "Jmenný rejstřík",
      waitingForTakeover: "Čeká na převzetí",
      dispatchWhiteList: "Whitelist podatelny"
    },
    logout: "Odhlásit se",
    packMenu: "Zabalit menu",
    unpackMenu: "Rozbalit menu"
  },
  modal: {
    cancel: "Zrušit",
    hidePreview: "Skrýt náhled",
    showPreview: "Zobrazit náhled",
    ok: "Potvrdit",
    okForSeal: "Dokončit",
    close: "Zavřít"
  },
  nodePreview: {
    errorState: "Nepodporovaný formát, komponentu nelze zobrazit.",
    filePreview: {
      emptyFile: "Soubor je prázdný",
      unsupportedFile: "Typ souboru není podporován",
      componentIsEncrypted: "Komponentu nelze zobrazit, protože je zaheslovaná."
    }
  },
  shipmentForm: {
    allowSubstDelivery: "Zakázat doručení fikcí",
    createdAt: "Datum a čas vytvoření",
    deliveryDate: "Datum a čas doručení",
    dispatchDate: "Datum a čas odeslání",
    dispatchedDate: "Datum a čas vypravení",
    dmID: "ID datové zprávy",
    fileIdentifier: "Naše značka",
    legalTitleLaw: "Zmocnění - číslo zákona",
    legalTitlePar: "Zmocnění - odstavec v paragrafu",
    legalTitlePoint: "Zmocnění - písmeno v odstavci",
    legalTitleSect: "Zmocnění - paragraf v zákoně",
    legalTitleYear: "Zmocnění - rok vydání zákona",
    personalDelivery: "Do vlastních rukou",
    recipientDataboxID: "ID datové schránky adresáta",
    senderDataboxID: "ID datové schránky odesílatele",
    senderIdent: "Vaše značka",
    senderSSID: "Vaše ČJ",
    shRef: "Nadřazený objekt",
    ssid: "Naše ČJ",
    state: "Stav zásilky",
    toDispatchDate: "Datum a čas předání do výpravny",
    toHands: "K rukám"
  },
  breadcrumb: {
    nameRegister: "Jmenný rejstřík",
    persons: "Fyzické osoby",
    legalEntity: "Právnické osoby",
    repository: "Spisovna",
    toTakeOver: "K převzetí",
    storedDocuments: "Uložené dokumenty",
    storedFiles: "Uložené spisy",
    rented: "Zapůjčené",
    toDispose: "Ke skartaci",
    retentionProposal: "Skartační návrhy",
    retentionBeforeDeletion: "Před výmazem",
    retentionAfterDeletion: "Po výmazu",
    beforeErased: "Před výmazem",
    afterErased: "Po výmazu",
    retentionProtocols: "Skartační protokoly",
    recordRetentionProcess: "Skartační řízení",
    evidence: "Evidence",
    overviews: "Přehledy",
    forSeal: "K opečetění",
    documentForSeal: "Dokumenty k opečetění",
    documentForSignature: "Dokumenty k podepsání",
    borrowed: "Vypůjčené",
    administration: "Administrace",
    overview: "Přehled",
    forSignature: "K podpisu",
    returnedForRework: "Vrácené k přepracování",
    users: "Uživatelé",
    dailyImprint: " Denní otisk",
    search: "Vyhledávání",
    searchResults: "Výsledky vyhledávání",
    conversion: "Evidence konverzí",
    retentionProtocol: "Skartační protokoly",
    signatureBook: "Podpisová kniha",
    dispatchWhitelist: "Whitelist podatelny",
    transactionProtocol: "Transakční protokol",
    typeFiles: "Typové spisy",
    open: "Otevřené",
    closed: "Uzavřené",
    openTypeFiles: "Otevřené typové spisy",
    closedTypeFiles: "Uzavřené typové spisy",
    codeList: "Správa číselníků"
  },
  table: {
    archivedDocumentsFiles: "Archivované dokumenty a spisy",
    borrowedDocuments: "Zapůjčené dokumenty/spisy",
    canceled: "Stornované",
    closedFiles: "Uzavřené spisy",
    concepts: "Koncepty",
    destroyedDocumentsFiles: "Zničené dokumenty a spisy",
    dispatchingByDataRepository: "Vypravení datovou schránkou",
    dispatchingByEmail: "Vypravení e-mailem",
    dispatchingByHandOver: "Vypravení osobním převzetím",
    dispatchingByPost: "Vypravení poštou",
    documentsFilesForShredding: "Dokumenty a spisy ke skartaci",
    documentsFilesForTakeOver: "Dokumenty/spisy k převzetí",
    documentsForProcessing: "Dokumenty k vyřízení",
    documentsForSignature: "Dokumenty k podepsání",
    favoriteFiles: "Označené spisy",
    forSignature: "K podpisu",
    lostDestroyed: "Ztracené / zničené",
    noData: "Nebyla nalezena žádná data",
    notPassed: "Nepředané dokumenty",
    notRegisteredDataboxes: "Neevidované zprávy z datové schránky",
    notRegisteredEmails: "Neevidované e-maily",
    open: "Otevřené",
    openFiles: "Otevřené spisy",
    processedDocuments: "Vyřízené dokumenty",
    rejectedTakeover: "Odmítnuté převzetí",
    returnedDispatch: "Vrácené z výpravny",
    returnedRework: "Vrácené k přepracování",
    rowsPerPage: "Záznamů na stránku:",
    signedDocuments: "Podepsané dokumenty",
    unprocessedDataboxes: "Nezpracované zprávy z datové schránky",
    unprocessedEmails: "Nezpracované e-maily",
    personTable: {
      identifier: "Identifikátor",
      subjectName: "Název subjektu",
      firstName: "Jméno",
      lastName: "Příjmení",
      creationDate: "Datum vytvoření"
    },
    legalEntityTable: {
      identifier: "Identifikátor",
      subjectName: "Název subjektu",
      organizationName: "Název organizace",
      identificationNumber: "IČO"
    },
    contactTable: {
      contact: "Kontakt",
      contactType: "Typ kontaktu",
      phone: "Telefonní číslo",
      databox: "ID datové schránky",
      email: "E-mailová adresa"
    },
    addressTable: {
      street: "Ulice",
      city: "Obec",
      postalCode: "PSČ",
      country: "Země",
      addressType: "Typ adresy"
    },
    searchSubjectTable: {
      nameOfSubject: "Název subjektu",
      name: "Jméno",
      surname: "Příjmení",
      nameOfOrganization: " Název organizace",
      identificationNumber: "IČO"
    },
    historyTable: {
      identifier: "Identifikátor",
      dateOfChange: "Datum změny",
      description: "Popis",
      authorOfChange: "Autor změny",
      typeOfChange: "Typ změny"
    },
    concernedContentTable: {
      documentPid: "Identifikátor",
      documentSsid: "Číslo jednací",
      documentSubject: "Předmět",
      relationType: "Typ vazby"
    },
    fileConcernedSubjectTable: {
      concernedContent: "Dotčený obsah"
    },
    concernedSubjectTable: {
      concerned: "Dotčený subjekt",
      sender: "Odesílatel",
      recipient: "Příjemce",
      subjectName: "Název subjektu",
      subjectType: "Typ subjektu",
      relationType: "Typ vazby"
    },
    retentionProposalOrProtocolContentTable: {
      pid: "Identifikátor",
      ssidFileIdent: "Číslo jednací/Spisová značka",
      subject: "Předmět",
      filePlan: "Spisový plán",
      fileMark: "Spisový znak",
      retentionMode: "Skartační režim",
      sipForSubmit: "SIP pro posouzení",
      sipForArchive: "SIP pro archivaci",
      decisionDa: "Rozhodnutí DA",
      identifierDa: "Identifikátor DA",
      generateSipWithoutComponents: "Vygenerovat SIP bez komponent",
      generateFullSip: "Vygenerovat úplný SIP",
      generateSipForArchiving: "Vygenerovat SIP pro archivaci",
      cancelShreddingProposal: "Vyřadit ze skartačního návrhu"
    },
    retentionProposalOrProtocolHistoryTable: {
      identifier: "Identifikátor",
      dateOfChange: "Datum změny",
      description: "Popis",
      authorOfChange: "Autor změny",
      typeOfChange: "Typ změny"
    },
    retentionProposalTable: {
      metadata: "Metadata",
      content: "Obsah",
      history: "Historie",
      archived: "Archivované",
      destroyed: "Zničené"
    },
    retentionArchivedTable: {
      identifier: "Identifikátor",
      ssidFileIdent: "Číslo jednací/Spisová značka",
      subject: "Předmět",
      filePlan: "Spisový plán",
      fileMark: "Spisový znak",
      retentionMode: "Skartační režim",
      identifierDA: "Identifikátor DA"
    },
    searchTable: {
      pid: "Identifikátor",
      fileIdent: "Číslo jednací/Spisová značka",
      subject: "Předmět",
      filePlan: "Spisový plán",
      fileMark: "Spisový znak",
      retentionMode: "Skartační režim",
      settleToDate: "Vyřídit do",
      evidenceDate: "Datum evidence",
      sender: "Odesílatel",
      owner: "Vlastník"
    },
    whiteListTable: {
      email: "E-mailová adresa",
      createdAt: "Datum a čas přidání",
      comment: "Poznámka"
    },
    dailyImprintSearchTable: {
      changedDateTime: "Datum a čas změny",
      typeOfLog: "Typ logu",
      typeOfChange: "Typ změny",
      descriptionOfChange: "Popis změny",
      typeOfObject: "Typ objektu",
      authorOfChange: "Autor změny",
      group: "Spisový uzel"
    },
    openTypeFilesTable: {
      pid: "Identifikátor",
      subject: "Název",
      createdAt: "Datum vytvoření",
      filePlanId: "Spisový plán",
      fileMark: "Spisový znak",
      createNewTypeFile: "Založit typový spis",
      closeTypeFile: "Uzavřít",
      methodOfSettlement: "Způsob vyřízení",
      typeFileName: "Název typového spisu",
      typeFileDetail: "Detail typového spisu",
      lockTypeFile: "Uzavřít typový spis",
      allComponentsNotClosed:
        "Uzavřít typový spis - nejsou uzavřené všechny součásti a díly",
      successCreatedFilePlan: "Typový spis byl založen.",
      successPartTypeFile: "Díl byl úspěšně uzavřen.",
      successLockedFilePlan: "Typový spis byl úspěšně uzavřen.",
      successCloseSectionOfFilePlan: "Součást byla úspěšně uzavřena.",
      errorPartTypeFile:
        "Díl nebylo možné uzavřít, protože obsahuje nevyřízené dokumenty.",
      section: "Součást",
      part: "Díl",
      contentOfPart: "Obsah dílu"
    },
    closedTypeFilesTable: {
      pid: "Identifikátor",
      subject: "Název",
      createdAt: "Datum vytvoření",
      closureDate: "Datum uzavření",
      filePlanId: "Spisový plán",
      fileMark: "Spisový znak",
      typeFileDetail: "Detail typového spisu"
    },
    codeListTable: {
      name: "Název",
      lastUpdate: "Datum poslední změny"
    }
  },
  tooltip: {
    closeTypeFileAction: "Uzavřít součást",
    closeTypePartAction: "Uzavřít díl",
    sectionContainsOpenPart:
      "Uzavřít součást - součást obsahuje neuzavřený díl",
    sectionClosed: "Uzavřít součást - součást již byla uzavřená",
    partClosed: "Uzavřít díl - díl již byl uzavřen",
    partClosedDisabled: "Uzavřít díl - nejsou vyřízené všechny dokumenty.",
    withdrawApplicationForSignature: "Stáhnout žádost k podpisu",
    withdrawApplicationForSeal: "Stáhnout žádost k opečetění"
  },
  dataBox: {
    title: "Na základě vybraného parametru byl v ISDS nalezen tento subjekt:",
    footer:
      "Přejete se vyplnit údaje o subjektu těmito údaji? U některých údajů může dojít k nesprávnému vyplnění, proto je nutné to zkontrolovat, případně opravit. ",
    subjectType: "Typ subjektu",
    modalTitle: "Vyhledávání v ISDS",
    address: "Adresa",
    dataBoxId: "ID schránky",
    ico: "IČO",
    name: "Název",
    fillInDataOfSubject: "Předvyplnit údaje o subjektu",
    saveAddressOfSubject: "Uložit adresu subjektu",
    databoxCannotBeAddedAsTypeOfSubjectIsDifferent:
      "Datovou schránku nelze přidat, protože držitelem je jiný typ subjektu."
  }
};

export default dictionary;
