import MenuLayout from "presentation/core/components/layout/MenuLayout";
import { BreadcrumbForTable } from "presentation/designSystem/Breadcrumb/Breadcrumb";
import { translationPath } from "presentation/share/utils/getPath";
import { theme } from "presentation/styles";
import { lang, t, withTranslation } from "presentation/translation/i18n";
import React, { useState } from "react";
import styled from "styled-components";
import { DailyImprintSearchForm } from "./forms/DailyImprintSearchForm";
import { DailyImprintSearchTable } from "./tables/DailyImprintSearchTable";

const DailyImprintSearch = () => {
  const [remoteQueryParams, setRemoteQueryParams] = useState<
    Record<string, string> | undefined
  >();

  return (
    <MenuLayout>
      <>
        <BreadcrumbForTable
          title={t(translationPath(lang.breadcrumb.search))}
        />
        <FormStyled>
          <DailyImprintSearchForm
            setRemoteQueryParams={(data: Record<string, string>) =>
              setRemoteQueryParams(data)
            }
          />
        </FormStyled>
        <TableStyled>
          <TitleStyled>
            {t(translationPath(lang.breadcrumb.searchResults))}
          </TitleStyled>
        </TableStyled>
        <DailyImprintSearchTable remoteQueryParams={remoteQueryParams} />
      </>
    </MenuLayout>
  );
};

const TableStyled = styled("div")`
  position: relative;
  height: 5%;
  padding: 20px;
`;

const TitleStyled = styled("h5")(() => ({
  color: theme.color.primary,
  fontSize: 23,
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  fontWeight: 700,
  lineHeight: 1.5,
  letterSpacing: "0.00938em"
}));

const FormStyled = styled("div")`
  padding: 0 20px 20px 20px;
`;

export default withTranslation()(DailyImprintSearch);
