import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { RootStateType } from "../../../reducers";
import { NotificationWithIdType } from "../../../core/components/notifications/_types";
import { RemoteTableApi } from "../RemoteTable";

// This hook will refresh the remote table every time a new notification is dispatched using redux.
//
// Why this hook exists?
// - we want to refresh the table after an old redux based dialog is closed and if some data was mutated on a server

// Why it is implemented this way?
// - old dialogs are opened using redux - the @dialog/OPEN action
// - we dont know which action will be dispatched (when user clicks a dialog button) and when it will finish
// - but we know, that almost every saga is dispatching a notification action after request is sent
//
export const useRefreshTableOnNewNotification = (
  remoteTableApi: RemoteTableApi
) => {
  const oldNotificationQueue = useRef<NotificationWithIdType[]>([]);
  const currentNotificationQueue = useSelector(
    (state: RootStateType) => state.notificationReducer.queue
  );

  useEffect(() => {
    // if new notification appeared, refresh table
    if (currentNotificationQueue.length > oldNotificationQueue.current.length) {
      remoteTableApi.refetch();
    }

    oldNotificationQueue.current = currentNotificationQueue;
  }, [remoteTableApi, currentNotificationQueue]);
};
