import { useMutation } from "presentation/share/hook/query/useQM";
import { useModal } from "presentation/designSystem/Modal/useModal";
import { HttpClient } from "../../../../../../infrastructure/service/HttpClient";
import { Concept } from "../types";
import { CreateConceptModal } from "../modals/CreateConceptModal";
import { DocumentType, SpisumNodeTypes } from "../../../../../enums";

const createConcept = (): Promise<Concept> => {
  const httpClient = new HttpClient();
  return httpClient.fetchWithThrow<Concept>(
    "/api/app/v2/concept/create",
    "POST"
  );
};

export const useCreateConcept = () => {
  const [modal, modalApi] = useModal(CreateConceptModal, {});
  const { isLoading, mutate: register } = useMutation(() => createConcept(), {
    onSuccess: (data) => {
      modalApi.open({
        document: data,
        nodeId: data.nodeId,
        documentType: DocumentType.Digital,
        nodeType: SpisumNodeTypes.Concept
      });
    }
  });

  return {
    isLoading,
    register,
    modal
  };
};
