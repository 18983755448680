import { HttpClient } from "infrastructure/service/HttpClient";
import { mapDocumentToApi } from "./mappers";
import {
  AnalogCreateDocument,
  UpdateAnalogFormFieldTypes
} from "../types/types";

export const registerDocumentFromAnalog = (id: string): Promise<void> => {
  const httpClient = new HttpClient();
  return httpClient.fetchWithThrow<void>(
    "/api/app/v2/document/{nodeId}/register",
    "POST",
    {
      urlWildCards: {
        nodeId: id
      }
    }
  );
};

export const updateUnfinishedDocument = (
  id: string,
  input: UpdateAnalogFormFieldTypes
) => {
  const httpClient = new HttpClient();
  const documentToApi = mapDocumentToApi(input);
  return httpClient.fetchWithThrow<void>(
    "/api/app/v2/document/{nodeId}/update/unfinished",
    "POST",
    {
      bodyJSON: documentToApi,
      urlWildCards: {
        nodeId: id
      }
    }
  );
};

export const createAnalogDocument = (): Promise<AnalogCreateDocument> => {
  const httpClient = new HttpClient();
  return httpClient.fetchWithThrow(
    "/api/app/v2/document/create/analog",
    "POST"
  );
};

/* this endpoint will be same for all the types of documents in the mailroom
  - can be defined on 1 place, no need to copy it to all api/index.ts files
 for specific types of documents - implementation needs to be finished
export const documentDetails = (id: string): Promise<void> => {
  const httpClient = new HttpClient();
  return httpClient.fetchWithThrow("/api/app/v2/document/{nodeId}/detail/registered");
};*/
