import { useQM } from "presentation/share/hook/query/useQM";
import { useModal } from "presentation/designSystem/Modal/useModal";
import { getDetailTypeFile } from "../api";
import {
  TypeFileDetailModalClose,
  TypeFileDetailModalCloseProps
} from "../modal/TypeFilesDetailModalClose";
import { DetailTypeFileDocument } from "../types";
import { ActionResult } from "@isfg/lib/build/contract/Action";

export interface UseGetTypeFileReturn {
  isLoading: boolean;
  modal: false | JSX.Element;
  open(props: TypeFileDetailModalCloseProps): Promise<ActionResult | undefined>;
  data: DetailTypeFileDocument | undefined;
}

export const useGetTypeFileClose = (id: string): UseGetTypeFileReturn => {
  const [modal, modalApi] = useModal(TypeFileDetailModalClose, {});
  const { isLoading, data } = useQM(
    ["typeFile/detail", id],
    () => getDetailTypeFile(id),
    {
      enabled: id !== "",
      onSuccess: (data) => {
        modalApi.open({
          document: data
        });
      }
    }
  );

  return {
    isLoading,
    modal,
    open: modalApi.open,
    data
  };
};
