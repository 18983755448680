import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

export const useStyles: Function = makeStyles(({ palette }: Theme) => {
  return createStyles({
    autocompleteFieldWrapper: {
      position: "relative"
    },
    loader: {
      bottom: "30px",
      position: "absolute",
      right: "25px"
    }
  });
});
