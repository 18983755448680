import { debounce } from "lodash";
import { useForm } from "presentation/designSystem/Form/v2/Form";
import { Modal, ModalSize } from "presentation/designSystem/Modal/Modal";
import { BaseModalProps } from "presentation/designSystem/Modal/useModal";
import { useMutation } from "presentation/share/hook/query/useQM";
import { getErrorCodeTranslation } from "presentation/share/utils/errorCodeTranslation";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { callAsyncAction } from "../../../../../../core/action";
import {
  addFileAction,
  createFileAction
} from "../../../../../../core/api/file/_actions";
import { documentViewAction__Refresh } from "../../../../../../core/components/documentView/_actions";
import { GenericDocument } from "../../../../../../core/types";
import { SitePaths, SpisumNodeTypes } from "../../../../../../enums";
import { RootStateType } from "../../../../../../reducers";
import {
  clearResultsAction,
  searchFilesAction
} from "../../../../../../share/components/dialog/createNewDocumentFileDialog/_actions";
import { FileOption } from "../../../../../../share/components/dialog/createNewDocumentFileDialog/_types";
import {
  alfrescoQuery,
  getQueryPath
} from "../../../../../../share/utils/query";
import { NewDocumentFileForm } from "../_types";
import { addTypeFile } from "../api";
import CreateNewDocumentFileForm from "../forms/CreateNewDocumentFileForm";

export interface CreateNewDocumentFileCancelProps extends BaseModalProps {
  nodeId?: string;
  document: GenericDocument;
}
const DEBOUNCE_WAIT = 500;
const MIN_TERM_LENGTH = 3;
const MAX_ITEMS = 25;

export const CreateNewDocumentFileModal = ({
  document,
  nodeId,
  onOk,
  onCancel
}: CreateNewDocumentFileCancelProps) => {
  /*Old Code*/

  // const dispatch = useDispatch<React.Dispatch<SearchFilesActionType>>();
  const dispatch = useDispatch();

  const pathFilesOpen = useSelector(
    (state: RootStateType) =>
      getQueryPath(
        state.loginReducer.global.paths,
        "*",
        SitePaths.Evidence,
        SitePaths.Files,
        SitePaths.Open
      )?.path || ""
  );

  const getSearchQuery = (term: string) => ({
    query: alfrescoQuery({
      paths: [pathFilesOpen],
      type: [SpisumNodeTypes.File],
      where: `(ssl:pid:'*${term}*' OR ssl:fileIdentifier:'*${term}*' OR ssl:subject:'*${term}*')`
    })
  });

  const onInputChange = debounce((value: string) => {
    if (value.length >= MIN_TERM_LENGTH) {
      dispatch(
        searchFilesAction.request({
          include: ["properties"],
          paging: {
            maxItems: MAX_ITEMS,
            skipCount: 0
          },
          query: getSearchQuery(value)
        })
      );
    }
  }, DEBOUNCE_WAIT);

  useEffect(() => {
    dispatch(clearResultsAction());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /*End of Old Code*/

  const getNotificationForError = (error: any) => ({
    message: getErrorCodeTranslation(error.code)
  });

  const [newDocumentFileForm] = useForm<NewDocumentFileForm>();

  const { mutate: documentFromTypeFile } = useMutation(
    (nodeId: string) =>
      addTypeFile(nodeId, {
        sectionId: newDocumentFileForm.getFieldValue("sectionId"),
        documentId: document.id
      }),
    {
      onSuccess() {
        dispatch(documentViewAction__Refresh(true));
        onOk?.();
      },
      onSuccessNotification: {
        message: t(
          translationPath(lang.dialog.notifications.documentTypeFileSucceeded)
        )
      },
      onErrorNotification: {
        message: t(
          translationPath(lang.dialog.notifications.documentTypeFileFailed)
        )
      }
    }
  );
  const onSuccess = () => {
    dispatch(documentViewAction__Refresh(true));
    onOk?.();
  };

  const onError = () => {
    return;
  };

  const handleOk = async () => {
    try {
      await newDocumentFileForm.validateFields();
      const formValues = newDocumentFileForm.getFieldsValue();

      if (formValues?.radioGroupName === FileOption.Create) {
        dispatch(
          callAsyncAction({
            action: createFileAction,
            onError,
            onSuccess,
            payload: {
              documentId: document.id
            }
          })
        );
      } else if (formValues?.radioGroupName === FileOption.Existing) {
        dispatch(
          callAsyncAction({
            action: addFileAction,
            onError,
            onSuccess,
            payload: {
              documentIds: [document.id],
              nodeId: formValues.existingFileKey
            }
          })
        );
      } else if (formValues?.radioGroupName === FileOption.ExistingTypeFile) {
        documentFromTypeFile(
          newDocumentFileForm.getFieldValue("existingFileTypeKey")
        );
      }
    } catch {}
  };

  return (
    <Modal
      open={true}
      title={t(translationPath(lang.general.pasteToFile))}
      onCancel={onCancel}
      onOk={handleOk}
      className={""}
      size={ModalSize.Small}
      okText={t(translationPath(lang.modal.ok))}
      cancelText={t(translationPath(lang.modal.cancel))}
    >
      <CreateNewDocumentFileForm
        form={newDocumentFileForm}
        initialValues={{
          selected: FileOption.Existing
        }}
        document={document}
        onFileSearchChange={onInputChange}
      />
    </Modal>
  );
};
