import React from "react";
import { Form, Item } from "presentation/designSystem/Form/v2/Form";
import { Col, Row } from "presentation/designSystem/Grid/Grid";
import { translationPath } from "../../../../utils/getPath";
import { lang, t } from "../../../../../translation/i18n";
import { Concept } from "../types";
import { FormInstance, Input } from "antd";

interface CreateConceptFormProps {
  formData: Concept;
  form: FormInstance;
}

export const CreateConceptForm = ({
  formData,
  form
}: CreateConceptFormProps) => {
  const formInitialValues = {
    ...formData
  };

  return (
    <>
      <Form initialValues={formInitialValues} form={form}>
        <Row gutter={3}>
          <Col span={6}>
            <Item
              name="pid"
              required
              label={t(translationPath(lang.documentMetaForm.pid))}
            >
              <Input disabled />
            </Item>
          </Col>
        </Row>

        <Row gutter={6}>
          <Col span={24}>
            <Item
              name="subject"
              label={t(translationPath(lang.general.subject))}
            >
              <Input max={255} />
            </Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};
