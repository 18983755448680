import { Formik } from "formik";
import { GlobalLayout } from "presentation/core/components/layout/GlobalLayout";
import {
  LoginCardWideStyled,
  LoginContentStyled,
  LoginStyled,
  StyledIe11FixerChild,
  StyledIe11FixerParent
} from "presentation/core/features/login/Login.styles";
import { RootStateType } from "presentation/reducers";
import { secretDecrypt } from "presentation/share/utils/byteOperations";
import { withTranslation } from "presentation/translation/i18n";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import renderForm from "./FormRenderer.form";
import { validate } from "./FormRenderer.form.validations";
import { submitLogin } from "./Login.methods";
import { LoginActionType, loginAction__Clear } from "./_actions";
import { LoginFormValuesType } from "./_types";

let initialRender = true;

const Login = () => {
  const dispatch = useDispatch<Dispatch<LoginActionType>>();
  const {
    error,
    pending,
    session: { remember }
  } = useSelector((state: RootStateType) => state.loginReducer);

  React.useEffect(() => {
    if (initialRender) {
      dispatch(loginAction__Clear());
      initialRender = false;
    }

    return () => {
      initialRender = true;
    };
  }, [dispatch]);

  return (
    <GlobalLayout>
      <LoginStyled>
        <LoginContentStyled>
          <StyledIe11FixerParent>
            <StyledIe11FixerChild>
              <LoginCardWideStyled>
                <Formik<LoginFormValuesType>
                  initialValues={{
                    password:
                      (remember && secretDecrypt(remember.password)) || "",
                    remember: remember !== null,
                    username:
                      (remember && secretDecrypt(remember.username)) || ""
                  }}
                  onSubmit={submitLogin(dispatch)}
                  validate={validate}
                >
                  {renderForm({ error, pending })}
                </Formik>
              </LoginCardWideStyled>
            </StyledIe11FixerChild>
          </StyledIe11FixerParent>
        </LoginContentStyled>
      </LoginStyled>
    </GlobalLayout>
  );
};

export default withTranslation()(Login);
