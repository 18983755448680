import { useQM } from "../../../hook/query/useQM";
import { getGroupsToHandover } from "../../../../../infrastructure/api/user/GroupApiRepository";

const KEY = "user/groupsToHandover";

export const useGroupsToHandover = () => {
  return useQM([KEY], () => getGroupsToHandover(), {
    cacheTime: 0
  });
};
