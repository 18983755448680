import React, {
  createContext,
  PropsWithChildren,
  useMemo,
  useState
} from "react";

export interface RemoteTableApiState<Row> {
  tableState: {
    pageNumber: number;
    itemsPerPage: number;
    selected: Row[];
    shouldRefreshTable: boolean | null;
    sortAsc?: boolean | null;
    sortColumnIndex?: number | null;
    sortColumnKey?: string | null;
    sortKeys?: string[] | null;
  };
  setTableState: React.Dispatch<
    React.SetStateAction<RemoteTableApiState<Row>["tableState"]>
  >;
}

function defaulRemoteTableSateValues<
  Row
>(): RemoteTableApiState<Row>["tableState"] {
  return {
    pageNumber: 0,
    itemsPerPage: 10,
    selected: [],
    shouldRefreshTable: null,
    sortAsc: null,
    sortColumnKey: null,
    sortColumnIndex: null,
    sortKeys: null
  };
}

let tableContext: any;

export function RemoteTableApiContext<Row>() {
  if (!tableContext) {
    tableContext = createContext<RemoteTableApiState<Row>>(
      {} as RemoteTableApiState<Row>
    );
  }
  return tableContext;
}

export function RemoteTableApiContextProvider<Row>(
  props: PropsWithChildren<{}>
) {
  const [tableState, setTableState] = useState(
    defaulRemoteTableSateValues<Row>()
  );
  const provideValue = useMemo(() => ({ tableState, setTableState }), [
    tableState,
    setTableState
  ]);
  const tableContext = RemoteTableApiContext<Row>();
  return (
    <tableContext.Provider value={provideValue}>
      {props.children}
    </tableContext.Provider>
  );
}
