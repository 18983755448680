import { TableRowSelection } from "antd/lib/table/interface";
import { BreadcrumbForTable } from "presentation/designSystem/Breadcrumb/Breadcrumb";
import {
  MinusCircleOutlined,
  PlusCircleOutlined
} from "presentation/designSystem/Icon/Icon";
import { useModal } from "presentation/designSystem/Modal/useModal";
import {
  GetRemoteDataClb,
  RemoteTable
} from "presentation/designSystem/Table/RemoteTable";
import {
  ColumnType,
  TableActions,
  useRemoteTableApi
} from "presentation/designSystem/Table/Table";
import {
  createColumn,
  createDateTimeColumn
} from "presentation/designSystem/Table/column/column";
import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { DateTimeFormat } from "../../../../../../lib/dateTime";
import { actionShowDetail } from "../../../../../share/components/table/actionFactory";
import { translationPath } from "../../../../../share/utils/getPath";
import { lang, t } from "../../../../../translation/i18n";
import { Whitelist } from "../_types";
import { fetchPaginatedWhiteList } from "../api";
import { CreateEmailToWhitelistModal } from "../modal/CreateEmailToWhitelistModal";
import { DeleteFromWhitelistModal } from "../modal/DeleteFromWhitelistModal";
import { DetailWhitelistModal } from "../modal/DetailWhitelistModal";

const COLUMNS: ColumnType<Whitelist>[] = [
  createColumn({
    dataIndex: "email",
    title: t(translationPath(lang.table.whiteListTable.email)),
    sorter: true,
    defaultSortOrder: "ascend",
    sortDirections: ["ascend", "descend", "ascend"]
  }),
  createDateTimeColumn(
    {
      dataIndex: "createdAt",
      title: t(translationPath(lang.table.whiteListTable.createdAt))
    },
    DateTimeFormat.FullDateTime
  ),
  createColumn({
    dataIndex: "comment",
    title: t(translationPath(lang.table.whiteListTable.comment))
  })
];

export const WhiteListTable: React.FC = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
  const [tableApi, onRemoteTableApi] = useRemoteTableApi();

  const [addEmailToWhitelistModal, addEmailToWhitelistModalApi] = useModal(
    CreateEmailToWhitelistModal,
    {
      onClose: () => {
        setSelectedRowKeys([]);
        tableApi.refetch();
      }
    }
  );
  const [detailsWhitelistModal, detailWhitelistModalApi] = useModal(
    DetailWhitelistModal,
    {
      onClose: () => {
        setSelectedRowKeys([]);
        tableApi.refetch();
      }
    }
  );

  const [deleteFromWhitelistModal, deleteFromWhitelistModalApi] = useModal(
    DeleteFromWhitelistModal,
    {
      onClose: () => {
        setSelectedRowKeys([]);
        tableApi.refetch();
      },
      onOk: () => {
        setSelectedRowKeys([]);
        tableApi.refetch();
      }
    }
  );

  const getData: GetRemoteDataClb<Whitelist> = useCallback(
    ({ pagination, sorting }) => fetchPaginatedWhiteList(pagination, sorting),
    [fetchPaginatedWhiteList]
  );

  const ACTIONS: TableActions<Whitelist> = useMemo(
    () => ({
      default: [
        {
          key: "add-contact",
          icon: <PlusCircleOutlined rev={"default"} />,
          tooltip: t(translationPath(lang.general.addEmail)),
          action() {
            return addEmailToWhitelistModalApi.open({});
          }
        }
      ],
      single: [
        actionShowDetail({
          action(selected: Whitelist[]) {
            return detailWhitelistModalApi.open({
              selectedWhiteList: selected[0],
              onCancel() {
                detailWhitelistModalApi.close();
              }
            });
          }
        }),
        {
          key: "delete-whitelist-email",
          icon: <MinusCircleOutlined rev={"default"} />,
          tooltip: "Odebrat z whitelistu",
          action(selected: Whitelist[]) {
            const selectedEmailIds = selected.reduce((acc, curr) => {
              if (curr && curr.emailId) {
                acc.push(curr.emailId);
                return acc;
              }
              return acc;
            }, [] as number[]);
            return deleteFromWhitelistModalApi.open({
              emailIds: selectedEmailIds,
              onCancel() {
                deleteFromWhitelistModalApi.close();
              }
            });
          }
        }
      ],
      multi: [
        {
          key: "delete-whitelist-email",
          icon: <MinusCircleOutlined rev={"default"} />,
          tooltip: "Odebrat z whitelistu",
          action(selected: Whitelist[]) {
            const selectedEmailIds = selected.reduce((acc, curr) => {
              if (curr && curr.emailId) {
                acc.push(curr.emailId);
                return acc;
              }
              return acc;
            }, [] as number[]);
            return deleteFromWhitelistModalApi.open({
              emailIds: selectedEmailIds,
              onCancel() {
                deleteFromWhitelistModalApi.close();
              }
            });
          }
        }
      ]
    }),
    [
      addEmailToWhitelistModalApi,
      deleteFromWhitelistModalApi,
      detailWhitelistModalApi
    ]
  );

  const onRowSelection: TableRowSelection<Whitelist> = {
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys);
    },
    selectedRowKeys
  };

  return (
    <>
      {addEmailToWhitelistModal}
      {detailsWhitelistModal}
      {deleteFromWhitelistModal}
      <BreadcrumbForTable title={"dispatchWhitelist"} />
      <TableStyled>
        <RemoteTable<Whitelist>
          rowKey="email"
          name="administration/WhitelistTable"
          columns={COLUMNS}
          actions={ACTIONS}
          getRemoteData={getData}
          onRemoteTableApi={onRemoteTableApi}
          rowSelection={onRowSelection}
        />
      </TableStyled>
    </>
  );
};

const TableStyled = styled("div")`
  div > .ant-row {
    height: 50px;
  }
`;
