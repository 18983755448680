import {
  AddressTypeRepositoryToken,
  ContactRepositoryToken,
  CountryRepositoryToken,
  HouseNumberRepositoryToken,
  NationalityRepositoryToken
} from "../../domain/di/token/codelist";
import { NationalityApiRepository } from "../../infrastructure/api/codelist/NationalityApiRepository";
import { AddressTypesApiRepository } from "../../infrastructure/api/codelist/AddressTypesApiRepository";
import { ContactTypeApiRepository } from "../../infrastructure/api/codelist/ContactTypeApiRepository";
import { HouseNumberApiRepository } from "../../infrastructure/api/codelist/HouseNumberApiRepository";
import { CountryApiRepository } from "../../infrastructure/api/codelist/CountryApiRepository";
import { FindNationality } from "../../useCase/codelist/FindNationality";
import { FindAddressType } from "../../useCase/codelist/findAddress/FindAddressType";
import { FindContact } from "../../useCase/codelist/findContact/FindContact";
import { FindHouseNumber } from "../../useCase/codelist/findHouseNumber/FindHouseNumber";
import { FindCountry } from "../../useCase/codelist/findCountry/FindCountry";

export const codelistProviders = [
  // repository
  {
    provide: NationalityRepositoryToken,
    useClass: NationalityApiRepository
  },
  {
    provide: AddressTypeRepositoryToken,
    useClass: AddressTypesApiRepository
  },
  {
    provide: ContactRepositoryToken,
    useClass: ContactTypeApiRepository
  },
  {
    provide: HouseNumberRepositoryToken,
    useClass: HouseNumberApiRepository
  },
  {
    provide: CountryRepositoryToken,
    useClass: CountryApiRepository
  },

  {
    provide: ContactRepositoryToken,
    useClass: ContactTypeApiRepository
  },
  // use case
  FindNationality,
  FindAddressType,
  FindContact,
  FindHouseNumber,
  FindCountry
];
