import { CancelRetentionProposal } from "domain/struct/recordRetention/CancelRetentionProposal";
import { Input } from "presentation/designSystem/Input/Input";
import { BaseModalProps } from "presentation/designSystem/Modal/useModal";
import styled from "styled-components";
import { CancelProposal } from "useCase/recordRetention/CancelProposal";
import { getService } from "../../../../core/features/dependencyInjection";
import { Form, Item, useForm } from "../../../../designSystem/Form/v2/Form";
import { Modal, ModalSize } from "../../../../designSystem/Modal/Modal";
import { useMutation } from "../../../../share/hook/query/useQM";
import { getErrorCodeTranslation } from "../../../../share/utils/errorCodeTranslation";
import { translationPath } from "../../../../share/utils/getPath";
import { lang, t } from "../../../../translation/i18n";
import React from "react";

export interface RetentionProposalCancelProps extends BaseModalProps {
  nodeId?: string;
}
const validationReasonRules = [{ required: true }, { min: 4 }, { max: 30 }];
const { TextArea } = Input;

export const RetentionProposalCancelModal = ({
  nodeId,
  onOk,
  onCancel
}: RetentionProposalCancelProps) => {
  const getNotificationForError = (error: any) => ({
    message: getErrorCodeTranslation(error.code)
  });

  const [cancelRetentionProposalForm] = useForm<CancelRetentionProposal>();
  const cancelProposal = getService(CancelProposal);

  const { isLoading: validateLoading, mutate: validateRetentionProposal } =
    useMutation((nodeId?: string) => cancelProposal.validateProposal(nodeId), {
      onSuccess() {
        cancelRetentionProposal(nodeId);
      },
      onErrorNotification: getNotificationForError || {
        message: t(
          translationPath(
            lang.dialog.notifications.cancelRetentionProposalFailed
          )
        )
      },
      onSuccessNotification: null
    });

  const { isLoading, mutate: cancelRetentionProposal } = useMutation(
    (nodeId?: string) =>
      cancelProposal.cancelProposal(
        nodeId,
        cancelRetentionProposalForm.getFieldsValue().reason
      ),
    {
      onSuccess() {
        onOk?.();
      },
      onSuccessNotification: {
        message: t(
          translationPath(
            lang.dialog.notifications.cancelRetentionProposalSucceeded
          )
        )
      },
      onErrorNotification: getNotificationForError || {
        message: t(
          translationPath(
            lang.dialog.notifications.cancelRetentionProposalFailed
          )
        )
      }
    }
  );

  const handleOk = async () => {
    try {
      await cancelRetentionProposalForm.validateFields();
      validateRetentionProposal(nodeId);
    } catch {}
  };

  return (
    <Modal
      open={true}
      title={t(
        translationPath(lang.dialog.retentionProposalCancelDaialog.title)
      )}
      onCancel={onCancel}
      onOk={handleOk}
      confirmLoading={isLoading || validateLoading}
      className={""}
      size={ModalSize.Small}
      okText={t(translationPath(lang.modal.ok))}
      cancelText={t(translationPath(lang.modal.cancel))}
    >
      <StyledDiv>
        <Form
          name="cancelRetentionProposalForm"
          form={cancelRetentionProposalForm}
          style={{ width: "100%" }}
        >
          <Item name="reason" rules={validationReasonRules}>
            <TextArea
              placeholder={t(
                translationPath(lang.general.cancelRetentionProposalReason)
              )}
            />
          </Item>
        </Form>
      </StyledDiv>
    </Modal>
  );
};

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1.5rem;
`;
