import { ErrorOutlineOutlined } from "@mui/icons-material";
import React from "react";

import { styled } from "@isfg/react-components/build/styled/styledComponent";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import { LoadingIndicator } from "../../../../components/loadingIndicator/LoadingIndicator";
import { PreviewState } from "../../_types";
import { useNodeContent } from "../../hooks/useNodeContent";
import { FilePreview } from "../filePreview/FilePreview";
import { StyledUnsupportedFileWrapper } from "../filePreview/FilePreview.styles";

interface OwnProps {
  name: string;
  nodeId: string;
  nodeType: string;
  componentId: string;
}

export const NodePreviewContainer = React.memo(
  ({ nodeId, name, nodeType, componentId }: OwnProps) => {
    const { state, file } = useNodeContent(nodeId, name, nodeType, componentId);

    if (state === PreviewState.Downloading) {
      return <LoadingIndicator />;
    }

    if (state === PreviewState.Error || !file) {
      return (
        <StyledUnsupportedFileWrapper>
          <StyledParagraph>
            {t(translationPath(lang.nodePreview.errorState))}
          </StyledParagraph>
          <ErrorOutlineOutlined />
        </StyledUnsupportedFileWrapper>
      );
    }

    return (
      <FilePreview
        name={file.name || ""}
        content={file.content}
        fileIsEncrypted={file.isEncrypted || false}
      />
    );
  }
);

const StyledParagraph = styled.p`
  text-align: center;
`;
