import { DocumentGetUnfinishedResponse, UnfinishedDocument } from "./../types";
import { HttpClient } from "infrastructure/service/HttpClient";
import {
  mapApiToAnalogDocument,
  mapApiToDataboxDocument,
  mapApiToEmailDocument,
  mapApiToTechnDataCarrierDocument
} from "./mappers";
import { DocumentType } from "presentation/enums";

export const getUnfinishedDocument = (
  id: string,
  documentType: string
): Promise<UnfinishedDocument> => {
  const httpClient = new HttpClient();

  return httpClient
    .fetchWithThrow<DocumentGetUnfinishedResponse>(
      "/api/app/v2/document/{nodeId}/detail/unfinished",
      "GET",
      {
        urlWildCards: {
          nodeId: id
        }
      }
    )
    .then((response) => {
      switch (documentType) {
        case DocumentType.Analog:
          return mapApiToAnalogDocument(response);
        case DocumentType.Email:
          return mapApiToEmailDocument(response);
        case DocumentType.Databox:
          return mapApiToDataboxDocument(response);
        case DocumentType.TechnicalDataCarries:
          return mapApiToTechnDataCarrierDocument(response);
        default:
          return mapApiToDataboxDocument(response);
      }
    });
};
