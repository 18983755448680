import { Contact } from "domain/struct/nameRegistry/Contact";
import { ContactType } from "../../../../../domain/struct/nameRegistry/ContactType";
import { Address } from "../../../../../domain/struct/nameRegistry/Address";
import { CountryType } from "../../../../../domain/struct/codelist/CountryType";
import { t } from "../../../../translation/i18n";
import { ApiTypes } from "infrastructure/api/struct/ApiTypesV2";
import { SenderFormFieldsValues } from "../SenderFormAnt";

export const findContactById = (contacts: Contact[], id: number) => {
  return contacts.find((contact) => contact.id === id);
};

export const findContactByType = (contacts: Contact[], type: string) => {
  return contacts.find((contact) => type === contact.contactType);
};

export const findContactsByType = (contacts: Contact[], type: ContactType) => {
  return contacts.filter(({ contactType }) => contactType === type);
};

export const formatAddress = (
  {
    addressType,
    street,
    houseNumber,
    orientationHouseNumber,
    orientationHouseNumberAddendum,
    city,
    cityPart,
    country,
    postalCode
  }: Address,
  countryCodesAndNames?: CountryType[] | undefined
) => {
  const countryCzechName =
    countryCodesAndNames &&
    countryCodesAndNames.find(
      (countryCodeAndName) => country === countryCodeAndName.code
    );

  return `${street ?? ""} ${houseNumber ?? ""}${
    orientationHouseNumber ? "/" + orientationHouseNumber : ""
  }${orientationHouseNumberAddendum ? orientationHouseNumberAddendum : ""}, ${
    postalCode ?? ""
  } ${cityPart ? cityPart : ""}, ${city ?? ""}, ${
    countryCzechName?.czechName ?? ""
  } ${getAddressTypeTranslation(addressType)} `;
};

export const getAddressTypeTranslation = (addressType?: string) => {
  if (!addressType) {
    return "";
  }

  const translation = t(`enums:addressType.${addressType}`);

  return `(${translation})`;
};

export type DocumentSenderResponse = ApiTypes["ISFG.SpisUm.ClientSide.Models.V1.Document.Recipient.DocumentSenderResponse"];
type ExcludeNull<T> = {
  [P in keyof T]: Exclude<ExcludeNull<T[P]>, null>;
};
export type DocumentSender = ExcludeNull<DocumentSenderResponse>;

export const mapDocumentToSenderForm = (
  sender: DocumentSender,
  countryCodesAndNames?: CountryType[] | undefined
): SenderFormFieldsValues => {
  return {
    senderId: sender?.id,
    subjectType: sender?.type,
    senderContactEmail: sender?.contactEmail,
    senderContactEmailId: Number(sender?.contactEmailId),
    senderContactDatabox: sender?.contactDatabox,
    senderContactDataboxId: Number(sender?.contactDataboxId),
    senderContactPhone: sender?.contactPhone,
    senderContactPhoneId: Number(sender?.contactPhoneId),
    senderFullName: sender?.fullName,
    senderCompanyName: sender?.fullName,
    senderCompanyEmployeeJob: sender?.companyEmployeeJob,
    senderCompanyEmployeeUnit: sender?.companyEmployeeUnit,
    senderCompanyEmployeeName: sender?.companyEmployeeName,
    ...(sender?.address?.id && {
      senderAddress: formatAddress(
        {
          addressType: sender.address.type,
          street: sender.address.street,
          houseNumber: sender.address.houseNumber,
          city: sender.address.city,
          cityPart: sender.address.cityDistrict,
          country: sender.address.countryCode,
          postalCode: sender.address.zipCode
        },
        countryCodesAndNames
      ),
      senderAddressId: Number(sender.address.id)
    })
  };
};
