import { getService } from "presentation/core/features/dependencyInjection";

import { useQM } from "presentation/share/hook/query/useQM";

import { FindCountry } from "../../../../useCase/codelist/findCountry/FindCountry";
import { Config } from "./useFindPersons";

const QUERY_KEY = "codeLists/findCountries";

export const useFindCountries = (config: Config) => {
  const findUseCase = getService(FindCountry);

  return useQM(QUERY_KEY, () => findUseCase.findAll(), config);
};
