import CreateIcon from "@mui/icons-material/Create";
import { Input, Radio, Space } from "antd";
import { Button } from "presentation/designSystem/Button/Button";
import { FormInstance, Item } from "presentation/designSystem/Form/v2/Form";
import { Col, Row } from "presentation/designSystem/Grid/Grid";
import { useModal } from "presentation/designSystem/Modal/useModal";
import { Notification } from "presentation/designSystem/notification/Notification";
import { DeliveryMode } from "presentation/enums";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ContactType } from "../../../../domain/struct/nameRegistry/ContactType";
import { LegalEntity } from "../../../../domain/struct/nameRegistry/LegalEntity";
import { SubjectType } from "../../../../domain/struct/nameRegistry/SubjectType";
import { RadioTypes } from "../../../core/components/senderForm/_types";
import {
  findContactByType,
  formatAddress
} from "../../../modules/mailroom/features/income/digitalDocument/dataBox/unprocessed/utils";
import { useFindCountries } from "../../../modules/nameRegister/hooks/useFindCountries";
import {
  SearchSubjectModal,
  SearchSubjectResult
} from "../../../modules/nameRegister/modal/SearchSubjectModal/SearchSubjectModal";
import { lang, t } from "../../../translation/i18n";
import { translationPath } from "../../utils/getPath";

export interface SenderFormFieldsValues {
  subjectType?: string;
  senderFullName?: string;
  senderId?: string;
  senderCompanyName?: string;
  senderContactEmail?: string;
  senderContactEmailId?: number;
  senderContactDatabox?: string;
  senderContactDataboxId?: number;
  senderContactPhone?: string;
  senderContactPhoneId?: number;
  senderAddress?: string;
  senderAddressId?: number;
  senderCompanyEmployeeUnit?: string;
  senderCompanyEmployeeJob?: string;
  senderCompanyEmployeeName?: string;
}

export interface SenderFormNewProps {
  form: FormInstance<SenderFormFieldsValues>;
  initialPhone?: string;
  initialEmail?: string;
  initialDatabox?: string;
  deliveryMode?: string;
  onSenderChange?(sender: SearchSubjectResult): void;
  isOwnDocument?: boolean;
  isReadonly?: boolean;
}

const SenderFormAnt = (props: SenderFormNewProps) => {
  const {
    form,
    initialDatabox,
    initialEmail,
    initialPhone,
    deliveryMode,
    onSenderChange,
    isOwnDocument,
    isReadonly
  } = props;

  const [selectedSubjectType, setSelectedSubjectType] = useState<
    SubjectType | undefined
  >();
  const [email, setEmail] = useState<string | undefined>(initialEmail);
  const [databox, setDatabox] = useState<string | undefined>(initialDatabox);
  const [phone, setPhone] = useState<string | undefined>(initialPhone);
  const { data: countryCodesAndNames } = useFindCountries({ retry: false });
  const [searchSubjectModal, searchSubjectModalApi] = useModal(
    SearchSubjectModal,
    {
      onOk(response) {
        if (!response) {
          return;
        }

        const { subject, addresses = [], contacts = [] } = response || {};
        if (
          deliveryMode === DeliveryMode.Databox &&
          databox &&
          !contacts.find((contact) => contact.contact === databox)
        ) {
          Notification.error({
            message: t(translationPath(lang.general.dataBoxNotMatch))
          });
          return Promise.reject();
        } else if (
          deliveryMode === DeliveryMode.Email &&
          email &&
          !contacts.find((contact) => contact.contact === email)
        ) {
          Notification.error({
            message: t(translationPath(lang.general.emailNotMatch))
          });
          return Promise.reject();
        }

        const emailContact = findContactByType(
          contacts,
          ContactType.EmailAddress
        );
        setEmail(emailContact?.contact);
        const databoxContact = findContactByType(
          contacts,
          ContactType.DataBoxId
        );
        setDatabox(databoxContact?.contact);
        const phoneContact = findContactByType(
          contacts,
          ContactType.PhoneNumber
        );
        setPhone(phoneContact?.contact);
        const [address] = addresses;
        setSelectedSubjectType(subject.subjectType);
        form.setFieldsValue({
          subjectType: subject.subjectType,
          senderId: subject.id,
          senderCompanyName: (subject as LegalEntity).companyFullName,
          ...(subject.subjectType === SubjectType.Person && {
            senderFullName: subject.fullName
          }),
          senderContactEmail: emailContact?.contact,
          senderContactEmailId: emailContact?.id,
          senderContactDatabox: databoxContact?.contact,
          senderContactDataboxId: databoxContact?.id,
          senderContactPhone: phoneContact?.contact,
          senderContactPhoneId: phoneContact?.id
        });
        if (address) {
          form.setFieldsValue({
            senderAddress: formatAddress(address, countryCodesAndNames),
            senderAddressId: address.id
          });
        }

        onSenderChange?.(response);

        return Promise.resolve();
      }
    }
  );

  const individualSenderForm = useMemo(() => {
    return (
      selectedSubjectType === SubjectType.Person && (
        <>
          <IndividualSenderForm />
        </>
      )
    );
  }, [selectedSubjectType]);

  const legalSenderForm = useMemo(() => {
    return (
      selectedSubjectType === SubjectType.LegalEntity && (
        <>
          <LegalSenderForm isReadonly={isReadonly} />
        </>
      )
    );
  }, [selectedSubjectType, isReadonly]);

  // Handlers
  const openDialog = useCallback(
    () => searchSubjectModalApi.open({ deliveryMode }),
    [deliveryMode, searchSubjectModalApi]
  );

  useEffect(() => {
    const subjectType: SubjectType | undefined =
      form.getFieldValue("subjectType");
    const senderContactEmail: string = form.getFieldValue("senderContactEmail");
    const senderContactDatabox: string = form.getFieldValue(
      "senderContactDatabox"
    );
    const senderContactPhone: string = form.getFieldValue("senderContactPhone");

    subjectType && setSelectedSubjectType(subjectType);
    senderContactEmail && setEmail(senderContactEmail);
    senderContactDatabox && setDatabox(senderContactDatabox);
    senderContactPhone && setPhone(senderContactPhone);
  }, [form]);

  return (
    <>
      {searchSubjectModal}
      <Row gutter={6}>
        <Col span={23}>
          <Space align="baseline">
            <h3 style={{ marginRight: 30, marginTop: 6 }}>
              {t(translationPath(lang.general.sender))}
            </h3>
            <Item name="subjectType">
              <Radio.Group disabled>
                <Radio value={SubjectType.Person}>
                  {t(translationPath(lang.general.senderIndividualPerson))}
                </Radio>
                <Radio value={SubjectType.LegalEntity}>
                  {t(translationPath(lang.general.senderLegalPerson))}
                </Radio>
                {isOwnDocument && (
                  <Radio value={RadioTypes.own}>
                    {t(translationPath(lang.general.senderOwn))}
                  </Radio>
                )}
              </Radio.Group>
            </Item>
          </Space>
        </Col>
        {!isReadonly && !isOwnDocument && (
          <Col span={1}>
            <Button
              type="text"
              shape="circle"
              size="large"
              icon={<CreateIcon />}
              onClick={openDialog}
              data-test-id="choose-sender"
            />
          </Col>
        )}
      </Row>
      <Item hidden name="senderId">
        <Input disabled />
      </Item>
      {databox && (
        <Row gutter={6}>
          <Col span={24}>
            <Item name="senderContactDatabox" label="ID datové schránky">
              <Input disabled />
            </Item>
            <Item hidden name="senderContactDataboxId">
              <Input disabled />
            </Item>
          </Col>
        </Row>
      )}

      {email && (
        <Row gutter={6}>
          <Col span={24}>
            <Item name="senderContactEmail" label="E-Mail">
              <Input disabled />
            </Item>
            <Item hidden name="senderContactEmailId">
              <Input disabled />
            </Item>
          </Col>
        </Row>
      )}
      {phone && (
        <Row gutter={6}>
          <Col span={24}>
            <Item name="senderContactPhone" label="Telefonní číslo">
              <Input disabled />
            </Item>
            <Item hidden name="senderContactPhoneId">
              <Input disabled />
            </Item>
          </Col>
        </Row>
      )}
      {individualSenderForm}
      {legalSenderForm}
    </>
  );
};

const LegalSenderForm = ({ isReadonly }: { isReadonly?: boolean }) => {
  return (
    <>
      <Row gutter={6}>
        <Col span={24}>
          <Item
            name="senderCompanyName"
            label={t(translationPath(lang.general.orgName))}
          >
            <Input disabled />
          </Item>
        </Col>
      </Row>
      <Row gutter={6}>
        <Col span={24}>
          <Item
            name="senderAddress"
            label={t(translationPath(lang.general.orgAddress))}
          >
            <Input disabled />
          </Item>
          <Item hidden name="senderAddressId">
            <Input disabled />
          </Item>
        </Col>
      </Row>
      <Row gutter={6}>
        <Col span={24}>
          <Item
            name="senderCompanyEmployeeUnit"
            label={t(translationPath(lang.general.orgUnit))}
          >
            <Input maxLength={100} disabled={isReadonly} />
          </Item>
        </Col>
      </Row>
      <Row gutter={6}>
        <Col span={24}>
          <Item
            name="senderCompanyEmployeeName"
            label={t(translationPath(lang.general.nameSurname))}
          >
            <Input maxLength={100} disabled={isReadonly} />
          </Item>
        </Col>
      </Row>
      <Row gutter={6}>
        <Col span={24}>
          <Item
            name="senderCompanyEmployeeJob"
            label={t(translationPath(lang.general.senderJob))}
          >
            <Input maxLength={100} disabled={isReadonly} />
          </Item>
        </Col>
      </Row>
    </>
  );
};

const IndividualSenderForm = () => {
  return (
    <>
      <Row gutter={6}>
        <Col span={24}>
          <Item
            name="senderFullName"
            label={t(translationPath(lang.general.nameSurname))}
          >
            <Input disabled maxLength={100} />
          </Item>
        </Col>
      </Row>
      <Row gutter={6}>
        <Col span={24}>
          <Item
            name="senderAddress"
            label={t(translationPath(lang.general.address))}
          >
            <Input disabled />
          </Item>
        </Col>
      </Row>
      <Row gutter={6}>
        <Col span={24}>
          <Item
            hidden
            name="senderAddressId"
            label={t(translationPath(lang.general.address))}
          >
            <Input disabled />
          </Item>
        </Col>
      </Row>
    </>
  );
};

export default SenderFormAnt;
