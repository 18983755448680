import {
  DInject,
  DInjectable
} from "presentation/core/features/dependencyInjection";
import { ApiUrls } from "infrastructure/api/struct/ApiTypesV2";
import { HttpClient } from "infrastructure/service/HttpClient";
import { FileHistoryRepository } from "domain/service/file/FileHistoryRepository";
import { FileId } from "../../../domain/struct/file/File";
import { History } from "domain/struct/history/History";
import { PaginatedResult, PaginationConfig } from "lib/contract/Pagination";
import { ApiHistory, HistoryApiMapper } from "../history/HistoryApiMapper";
import { SortingConfig } from "lib/contract/Sorting";

const URL: ApiUrls = {
  FindAll: "/api/app/v2/file/{nodeId}/history"
};

@DInjectable()
export class FileHistoryApiRepository implements FileHistoryRepository {
  constructor(@DInject(HttpClient) protected httpClient: HttpClient) {}

  findPaginated(
    id: FileId,
    paginationConfig: PaginationConfig,
    sortingConfig?: SortingConfig
  ): Promise<PaginatedResult<History>> {
    return this.httpClient
      .fetchPaginated<ApiHistory>({
        url: URL.FindAll,
        pagination: paginationConfig,
        sorting: sortingConfig,
        config: {
          urlWildCards: {
            nodeId: id
          }
        }
      })
      .then(HistoryApiMapper.mapListToDomain);
  }
}
