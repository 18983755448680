import {
  NodeChildAssociation,
  SslFile,
  SuccessResponseType
} from "presentation/core/api/models";
import { searchAction } from "presentation/core/api/search/_actions";
import { Notification } from "presentation/designSystem/notification/Notification";
import { put, takeEvery } from "redux-saga/effects";
import { convertResponse } from "presentation/share/utils/convert";
import { translationPath } from "presentation/share/utils/getPath";
import { handleResponse } from "presentation/share/utils/typesafeActions";
import { lang, t } from "presentation/translation/i18n";
import { ActionType, getType } from "typesafe-actions";
import { searchFilesAction } from "./_actions";

export function* watchDialogFileSearchAction() {
  yield takeEvery(
    getType(searchFilesAction.request),
    function* ({ payload }: ActionType<typeof searchFilesAction.request>) {
      yield put(searchAction.request(payload));

      const [successResponse, errorResponse, success] = yield handleResponse(
        searchAction
      );

      if (!success) {
        Notification.error({
          message: t(translationPath(lang.dialog.notifications.somethingFailed))
        });

        yield put(searchFilesAction.failure(errorResponse));
        return;
      }

      const response = convertResponse(successResponse) as SuccessResponseType<
        NodeChildAssociation<SslFile>
      >;

      yield put(
        searchFilesAction.success(response.list.entries?.map((e) => e.entry))
      );
    }
  );
}
