import { HttpClient, HttpMethod } from "infrastructure/service/HttpClient";
import { mapFindAll } from "./mappers";
import { SubjectId } from "domain/struct/nameRegistry/Subject";
import { ApiUrls } from "infrastructure/api/struct/ApiTypes";
import { PaginatedResult, PaginationConfig } from "lib/contract/Pagination";
import { DocumentConcernedSubject } from "domain/struct/document/DocumentConcernedSubject";
import { ApiDocumentConcernedSubject } from "infrastructure/api/document/DocumentConcernedSubjectApiMapper";

const URL: ApiUrls = {
  GetConcernedSubjects: "/api/app/v1/document/{nodeId}/concerned-subjects",
  AddConcernedSubjects: "/api/app/v1/document/{nodeId}/concerned-subject/add",
  RemoveConcernedSubject:
    "/api/app/v1/document/{nodeId}/concerned-subject/{subjectId}/remove"
};

export const getDocumentConcernedSubjects = (
  nodeId: string,
  config: PaginationConfig
): Promise<PaginatedResult<DocumentConcernedSubject>> => {
  const httpClient = new HttpClient();
  return httpClient
    .fetchPaginatedAlfresco<ApiDocumentConcernedSubject>(
      URL.GetConcernedSubjects,
      config,
      {
        urlWildCards: {
          nodeId
        }
      }
    )
    .then(mapFindAll);
};

export const addDocumentConcernedSubjects = (
  nodeId: string,
  subjectIds: SubjectId[]
): Promise<void> => {
  const httpClient = new HttpClient();
  return httpClient.fetchWithThrow(URL.AddConcernedSubjects, HttpMethod.Post, {
    urlWildCards: {
      nodeId
    },
    bodyJSON: {
      subjectIds
    }
  });
};

export const removeDocumentConcernedSubjects = (
  nodeId: string,
  subjectIds: SubjectId[]
): Promise<void> => {
  return Promise.all(
    subjectIds.map((subjectId) =>
      removeDocumentConcernedSubject(nodeId, subjectId)
    )
  ).then(() => undefined);
};

export const removeDocumentConcernedSubject = (
  nodeId: string,
  subjectId: SubjectId
): Promise<void> => {
  const httpClient = new HttpClient();
  return httpClient.fetchWithThrow(
    URL.RemoveConcernedSubject,
    HttpMethod.Delete,
    {
      urlWildCards: {
        nodeId,
        subjectId
      }
    }
  );
};
