import { ApiListResponse, ApiTypes } from "../struct/ApiTypesV2";
import { ApiListResponseMapper } from "../mapper/ApiListResponseMapper";
import { stringOrDefault } from "../../../lib/string";
import { RetentionForShredding } from "../../../domain/struct/recordRetention/RetentionForShredding";
import { mapNullDateValue } from "../dateTime";

export type ApiRepositoryShreddingForShreddingResponse = ApiTypes["ISFG.SpisUm.ClientSide.Models.V2.Shredding.Response.ShreddingObjectsForShreddingResponse"];

export class RetentionForShreddingApiMapper {
  static mapListToDomain(
    response: ApiListResponse<ApiRepositoryShreddingForShreddingResponse>
  ) {
    return ApiListResponseMapper.mapListToPagination(
      response,
      RetentionForShreddingApiMapper.mapToDomain
    );
  }

  static mapToDomain(
    response: ApiRepositoryShreddingForShreddingResponse
  ): RetentionForShredding {
    const {
      pid,
      nodeId,
      fileMark,
      filePlan,
      location,
      retentionMode,
      shreddingYear,
      ssid,
      fileIdentifier,
      subject,
      discardTo,
      discardReason,
      form,
      nodeType
    } = response;
    return {
      pid: stringOrDefault(pid),
      nodeId: stringOrDefault(nodeId),
      fileMark: stringOrDefault(fileMark),
      filePlan: stringOrDefault(filePlan),
      location: stringOrDefault(location),
      retentionMode: stringOrDefault(retentionMode),
      shreddingYear: stringOrDefault(shreddingYear),
      ssid: stringOrDefault(ssid),
      fileIdentifier: stringOrDefault(fileIdentifier),
      subject: stringOrDefault(subject),
      discardTo: stringOrDefault(mapNullDateValue(discardTo)),
      discardReason: stringOrDefault(discardReason),
      form: stringOrDefault(form),
      nodeType: stringOrDefault(nodeType)
    };
  }
}
