import { NameRegisterConfigurationRepository } from "domain/service/nameRegistry/NameRegisterConfigurationRepository";
import { PersonRepository } from "domain/service/nameRegistry/PersonRepository";
import { DInjectionToken } from "presentation/core/features/dependencyInjection";
import { AddressRepository } from "../../service/nameRegistry/AddressRepository";
import { ConcernedContentRepository } from "../../service/nameRegistry/ConcernedContentRepository";
import { ContactRepository } from "../../service/nameRegistry/ContactRepository";
import { HistoryRepository } from "../../service/nameRegistry/HistoryRepository";
import { DataBoxRepository } from "../../service/nameRegistry/isds/DataBoxRepository";
import { LegalEntityRepository } from "../../service/nameRegistry/LegalEntityRepository";
import {
  SearchedSubjectLegalEntityRepository,
  SearchedSubjectPersonRepository
} from "../../service/nameRegistry/SearchedSubjectRepository";
export const PersonRepositoryToken = new DInjectionToken<PersonRepository>(
  "domain.name-registry.person-repository"
);

export const LegalEntityRepositoryToken = new DInjectionToken<LegalEntityRepository>(
  "domain.name-registry.legal-entity-repository"
);

export const ContactRepositoryToken = new DInjectionToken<ContactRepository>(
  "domain.name-registry.contact-repository"
);

export const AddressRepositoryToken = new DInjectionToken<AddressRepository>(
  "domain.name-registry.address-repository"
);

export const DataBoxRepositoryToken = new DInjectionToken<DataBoxRepository>(
  "domain.name-registry.isds.data-box-repository"
);
export const SearchSubjectPersonRepositoryToken = new DInjectionToken<SearchedSubjectPersonRepository>(
  "domain.name-registry.search-subject-person"
);
export const SearchSubjectLegalEntityRepositoryToken = new DInjectionToken<SearchedSubjectLegalEntityRepository>(
  "domain.name-registry.search-subject-legal-entity"
);
export const HistoryRepositoryToken = new DInjectionToken<HistoryRepository>(
  "domain.name-registry.history"
);
export const ConcernedContentRepositoryToken = new DInjectionToken<ConcernedContentRepository>(
  "domain.name-registry.concerned-content"
);
export const NameRegisterConfigurationRepositoryToken = new DInjectionToken<NameRegisterConfigurationRepository>(
  "domain.nameRegisterConfiguration.NameRegisterConfiguration-repository"
);
