import {
  Description,
  LockOpen,
  Mail,
  Send,
  Storage,
  Whatshot
} from "@mui/icons-material";
import {
  ControlsBarType,
  DataColumn
} from "presentation/core/components/dataTable/_types";
import { dialogOpenAction } from "presentation/core/components/dialog/_actions";
import { DialogType } from "presentation/core/components/dialog/_types";
import { openFileDetailsAction } from "presentation/core/components/dialog/tabs/tableOfContents/_actions";
import DocumentView from "presentation/core/components/documentView";
import MenuLayout from "presentation/core/components/layout/MenuLayout";
import { SessionType } from "presentation/core/features/login/_types";
import {
  GenericDocument,
  ShipmentDocument,
  genericDocumentProxy
} from "presentation/core/types";
import {
  DocumentType,
  SitePaths,
  SpisumNamesWithoutPrefix,
  SpisumNodeTypes,
  SubmitToRepositoryDialog
} from "presentation/enums";
import { RootStateType } from "presentation/reducers";
import { handoverDocument } from "presentation/share/components/dialog/documentHandoverDialog/_actions";
import { lostDestroyedDialogOpen } from "presentation/share/components/dialog/lostDestroyedDialog/_actions";
import { submitToRepositoryDialogOpen } from "presentation/share/components/dialog/submitToRepository/_actions";
import { fileMarkColumn } from "presentation/share/components/table/column/document/FilePlan/fileMarkColumn";
import { filePlanColumn } from "presentation/share/components/table/column/document/FilePlan/filePlanColumn";
import { classPath, translationPath } from "presentation/share/utils/getPath";
import { getRelativePath } from "presentation/share/utils/query";
import { isUserInLeadership } from "presentation/share/utils/user";
import { lang, t, withTranslation } from "presentation/translation/i18n";
import { useDispatch, useSelector } from "react-redux";
import { DisplaySenderNameByType } from "../../../../../share/components/table/column/DisplaySenderNameByType";
import React from "react";

const defaultColumn: DataColumn<GenericDocument> = {
  isDateTime: true,
  keys: [classPath(genericDocumentProxy.properties!.ssl!.closureDate).path],
  label: t(translationPath(lang.general.closureDate))
};

const getColumns = (session: SessionType): DataColumn<GenericDocument>[] => {
  const columns: DataColumn<GenericDocument>[] = [
    {
      keys: [classPath(genericDocumentProxy.properties!.ssl!.pid).path],
      label: t(translationPath(lang.general.identifier))
    },
    {
      keys: [
        classPath(genericDocumentProxy.properties!.ssl!.fileIdentifier).path
      ],
      label: t(translationPath(lang.general.fileIdentifier))
    },
    {
      keys: [classPath(genericDocumentProxy.properties!.ssl!.subject).path],
      label: t(translationPath(lang.general.subject))
    },
    {
      getValue: (row) => <DisplaySenderNameByType ssl={row?.properties?.ssl} />,
      keys: [
        classPath(genericDocumentProxy.properties!.ssl!.senderCompanyName).path,
        classPath(genericDocumentProxy.properties!.ssl!.senderFullName).path
      ],
      label: t(translationPath(lang.general.sender))
    },
    {
      isDateTime: true,
      keys: [classPath(genericDocumentProxy.properties!.ssl!.createdDate).path],
      label: t(translationPath(lang.general.dateOfEvidence))
    },
    defaultColumn,
    {
      keys: [
        classPath(genericDocumentProxy.properties!.ssl!.associationCount).path
      ],
      label: t(translationPath(lang.general.documentsCount))
    },
    filePlanColumn,
    fileMarkColumn,
    {
      keys: [
        classPath(genericDocumentProxy.properties!.ssl!.retentionMode).path
      ],
      label: t(translationPath(lang.general.retentionMode))
    }
  ];

  if (isUserInLeadership(session)) {
    columns.push({
      keys: [
        classPath(genericDocumentProxy.properties!.cm!.owner?.displayName).path
      ],
      label: t(translationPath(lang.general.owner))
    });
  }

  return columns;
};

const ClosedFiles = () => {
  const dispatch = useDispatch();

  const controls: ControlsBarType<GenericDocument> = {
    multi: {
      items: [
        /*
        {
          action: (selected: GenericDocument[]) => {
            dispatch(
              changeDocumentIsFavoriteAction({
                actionType: ChangeDocumentIsFavoriteActionType.Add,
                items: selected,
                nodeType: SpisumNodeTypes.File
              })
            );
          },
          filter: (x) => !x.isFavorite,
          icon: <Star />,
          title: t(translationPath(lang.general.bookmark))
        },
        {
          action: (selected: GenericDocument[]) => {
            dispatch(
              changeDocumentIsFavoriteAction({
                actionType: ChangeDocumentIsFavoriteActionType.Remove,
                items: selected,
                nodeType: SpisumNodeTypes.File
              })
            );
          },
          filter: (x) => x.isFavorite === true,
          icon: <StarBorderOutlined />,
          title: t(translationPath(lang.general.bookmarkRemove))
        },
        */
        {
          action: (selected: GenericDocument[]) => {
            dispatch(
              submitToRepositoryDialogOpen({
                data: {
                  entityType: SubmitToRepositoryDialog.Files,
                  selected
                }
              })
            );
          },
          icon: <Storage />,
          title: t(translationPath(lang.general.toRegistry))
        }
      ]
    },
    single: {
      items: [
        {
          action: (selected: GenericDocument[]) => {
            dispatch(
              openFileDetailsAction({
                data: selected[0],
                initiator: SpisumNodeTypes.File,
                isReadonly: true
              })
            );
          },
          icon: <Description />,
          title: t(translationPath(lang.general.showDetails))
        },
        {
          action: (selected: GenericDocument[]) => {
            dispatch(handoverDocument({ data: selected[0] }));
          },
          icon: <Send />,
          title: t(translationPath(lang.general.handOVer))
        },
        /*
        {
          action: (selected: GenericDocument[]) => {
            dispatch(
              changeDocumentIsFavoriteAction({
                actionType: ChangeDocumentIsFavoriteActionType.Add,
                items: selected,
                nodeType: SpisumNodeTypes.File
              })
            );
          },
          filter: (x) => !x.isFavorite,
          icon: <Star />,
          title: t(translationPath(lang.general.bookmark))
        },
        {
          action: (selected: GenericDocument[]) => {
            dispatch(
              changeDocumentIsFavoriteAction({
                actionType: ChangeDocumentIsFavoriteActionType.Remove,
                items: selected,
                nodeType: SpisumNodeTypes.File
              })
            );
          },
          filter: (x) => x.isFavorite === true,
          icon: <StarBorderOutlined />,
          title: t(translationPath(lang.general.bookmarkRemove))
        },
        */
        {
          action: (selected: GenericDocument[]) => {
            dispatch(
              dialogOpenAction({
                dialogProps: { data: selected[0] },
                dialogType: DialogType.OpenFile
              })
            );
          },
          icon: <LockOpen />,
          title: t(translationPath(lang.general.open))
        },
        {
          action: (selected: GenericDocument[]) => {
            dispatch(
              dialogOpenAction({
                dialogProps: { data: selected[0] },
                dialogType: DialogType.SendShipment
              })
            );
          },
          icon: <Mail />,
          title: t(translationPath(lang.general.manageShipments))
        },
        {
          action: (selected: GenericDocument[]) => {
            dispatch(
              submitToRepositoryDialogOpen({
                data: {
                  entityType: SubmitToRepositoryDialog.Files,
                  selected
                }
              })
            );
          },
          icon: <Storage />,
          title: t(translationPath(lang.general.toRegistry))
        }
      ],
      more: [
        /* V2
        {
          action: () => {},
          icon: <Print />,
          title: t(translationPath(lang.general.printEnvelopes))
        },
        {
          action: () => {},
          icon: <Print />,
          title: t(translationPath(lang.general.printInventoryDocuments))
        },
        */
        {
          action: (selected: GenericDocument[]) => {
            dispatch(lostDestroyedDialogOpen({ data: selected[0] }));
          },
          filter: (x) =>
            x.properties?.ssl?.form === DocumentType.Analog ||
            x.properties?.ssl?.form === DocumentType.Hybrid,
          icon: <Whatshot />,
          title: t(translationPath(lang.general.damaged))
        }
      ]
    }
  };

  const session = useSelector(
    (state: RootStateType) => state.loginReducer.session
  );

  const relativePath = useSelector((state: RootStateType) =>
    getRelativePath(
      state.loginReducer.global.paths,
      state.loginReducer.session.activeGroup,
      SitePaths.Evidence,
      SitePaths.Files,
      SitePaths.Closed
    )
  );

  const handleDoubleClick = (row: ShipmentDocument) => {
    dispatch(openFileDetailsAction({ data: row, isReadonly: true }));
  };

  return (
    <>
      <MenuLayout>
        <DocumentView
          children={{
            include: [SpisumNamesWithoutPrefix.IsFavorite],
            relativePath,
            where: `(nodeType='${SpisumNodeTypes.File}')`
          }}
          columns={getColumns(session)}
          controls={controls}
          customTitle={t(translationPath(lang.table.closedFiles))}
          defaultSortAsc={true}
          defaultSortColumn={defaultColumn}
          handleDoubleClick={handleDoubleClick}
        />
      </MenuLayout>
    </>
  );
};

export default withTranslation()(ClosedFiles);
