import { DateTimeFormat } from "lib/dateTime";
import { camelCase } from "lodash";
import moment from "moment";
import { FilePlanGroups } from "presentation/core/features/login/_types";
import { DatePicker } from "presentation/designSystem/DatePicker/DatePicker";
import {
  Form,
  FormInstance,
  Item
} from "presentation/designSystem/Form/v2/Form";
import { Col, Row } from "presentation/designSystem/Grid/Grid";
import { Input, InputNumber } from "presentation/designSystem/Input/Input";
import { Select } from "presentation/designSystem/Select/Select";
import { DeliveryMode, DocumentState, DocumentType } from "presentation/enums";
import {
  SenderFormFieldsValues,
  SenderFormNewProps
} from "presentation/share/components/form/SenderFormAnt";
import { useSenderForm } from "presentation/share/components/form/hooks/useSenderForm";
import { useIsValidOrExpireFilePlan } from "presentation/share/hook/filePlan/useIsValidOrExpireFilePlan";
import { translationPath } from "presentation/share/utils/getPath";
import { lang, t } from "presentation/translation/i18n";
import React, { useState } from "react";
import { EvidenceDocumentDetails } from "../_types";
import { FileMarkField } from "../component/FileMarkField";
import { FilePlanField } from "../features/typeFiles/components/FilePlanField";

interface AnalogOwnDocumentDetailsFormProps {
  formData: EvidenceDocumentDetails;
  form: FormInstance<AnalogOwnDocumentDetailsFormValues>;
  onSenderChange: SenderFormNewProps["onSenderChange"];
  isReadonly: SenderFormNewProps["isReadonly"];
}

export interface AnalogOwnDocumentDetailsFormValues
  extends Omit<EvidenceDocumentDetails, "deliveryDate" | "settleToDate">,
    SenderFormFieldsValues {
  deliveryDate?: moment.Moment;
  settleToDate?: moment.Moment;
}

export const AnalogOwnDocumentDetailsForm = ({
  formData,
  form,
  onSenderChange,
  isReadonly
}: AnalogOwnDocumentDetailsFormProps) => {
  const deliveryDate = formData.deliveryDate && new Date(formData.deliveryDate);
  const { senderFormData, senderForm } = useSenderForm({
    senderData: formData.sender,
    form,
    initialEmail: formData.digitalDeliverySender,
    deliveryMode: DeliveryMode.Email,
    onSenderChange,
    isOwnDocument: true,
    isReadonly
  });
  const formInitialValues: AnalogOwnDocumentDetailsFormValues = {
    ...formData,
    deliveryDate: moment(deliveryDate),
    settleToDate: formData.settleToDate
      ? moment(new Date(formData.settleToDate))
      : undefined,
    ...senderFormData
  };
  const filePlans = useIsValidOrExpireFilePlan(formInitialValues.filePlan);
  const initialFilePlan = formInitialValues.filePlan
    ? filePlans.find((plan) => plan.nodeId === formInitialValues.filePlan)
    : undefined;
  const [fileMarks, setFileMarks] = useState<FilePlanGroups[]>(
    initialFilePlan?.filePlanGroups ?? []
  );

  const handlePlanChange = (value: string) => {
    const selectedFilePlan = filePlans.find((plan) => plan.nodeId === value);

    setFileMarks(selectedFilePlan?.filePlanGroups || []);

    form.setFieldsValue({
      filePlan: value,
      retentionMode: ""
    });
  };

  const findFileMark = (
    marks: FilePlanGroups[],
    id: string
  ): FilePlanGroups | null =>
    marks.reduce<FilePlanGroups | null>((acc, mark) => {
      if (mark.identificator === id) {
        acc = mark;
      }

      const nestedMark =
        mark.filePlanGroups && findFileMark(mark.filePlanGroups, id);
      if (nestedMark) {
        acc = nestedMark;
      }

      return acc;
    }, null);

  const handleMarkChange = (value: string) => {
    const selectedFileMark = findFileMark(fileMarks, value);

    form.setFieldsValue({
      retentionMode:
        selectedFileMark?.retentionMark && selectedFileMark.retentionPeriod
          ? `${selectedFileMark.retentionMark}/${selectedFileMark.retentionPeriod}`
          : ""
    });
  };

  return (
    <>
      <Form initialValues={formInitialValues} form={form}>
        <Row gutter={6}>
          <Col span={4}>
            <Item name="pid" label={t(translationPath(lang.general.pid))}>
              <Input disabled />
            </Item>
          </Col>

          <Col span={5}>
            <Item
              name="deliveryDate"
              label={t(translationPath(lang.general.createdDateAndTime))}
            >
              <DatePicker
                disabled
                format={DateTimeFormat.FullDateTime}
                style={{ width: "100%" }}
              />
            </Item>
          </Col>

          <Col span={5}>
            <Item name="form" label={t(translationPath(lang.general.form))}>
              <Select disabled>
                {Object.entries(DocumentType).map(([key, value]) => (
                  <Select.Option key={key} value={value}>
                    {t(
                      translationPath(
                        lang.general[value as keyof typeof lang.general]
                      )
                    )}
                  </Select.Option>
                ))}
              </Select>
            </Item>
          </Col>

          <Col span={5}>
            <Item name="ssid" label={t(translationPath(lang.general.ssid))}>
              <Input disabled />
            </Item>
          </Col>

          <Col span={5}>
            <Item
              name="settleToDate"
              label={t(translationPath(lang.general.settleToDate))}
            >
              <DatePicker
                format={DateTimeFormat.Date}
                style={{ width: "100%" }}
                disabledDate={(current) => {
                  return current < moment().add(-1);
                }}
                disabled={isReadonly}
              />
            </Item>
          </Col>
        </Row>
        <Row gutter={6}>
          <Col span={4}>
            <Item
              name="state"
              label={t(translationPath(lang.general.documentState))}
            >
              <Select disabled>
                {Object.entries(DocumentState).map(([key, value]) => (
                  <Select.Option key={key} value={value}>
                    {t(
                      translationPath(
                        lang.enums.documentState[
                          camelCase(
                            key
                          ) as keyof typeof lang.enums.documentState
                        ]
                      )
                    )}
                  </Select.Option>
                ))}
              </Select>
            </Item>
          </Col>

          <Col span={5}>
            <Item
              name="filePlan"
              label={t(translationPath(lang.general.filePlan))}
            >
              <FilePlanField
                validFilePlans={filePlans}
                handlePlanChange={handlePlanChange}
                isReadonly={isReadonly}
                defaultValue={formInitialValues.filePlan}
              />
            </Item>
          </Col>

          <Col span={5}>
            <Item
              name="fileMark"
              label={t(translationPath(lang.general.fileMark))}
            >
              <FileMarkField
                fileMarks={fileMarks}
                onChange={handleMarkChange}
                readOnly={isReadonly}
                initialFileMark={formInitialValues.fileMark}
              />
            </Item>
          </Col>

          <Col span={5}>
            <Item
              name="retentionMode"
              label={t(translationPath(lang.general.retentionMode))}
            >
              <Input disabled />
            </Item>
          </Col>

          <Col span={5}>
            <Item name="owner" label={t(translationPath(lang.general.owner))}>
              <Input disabled />
            </Item>
          </Col>
        </Row>
        <Row gutter={6}>
          <Col span={4}>
            <Item
              name="attachmentsCount"
              label={t(translationPath(lang.general.attachmentsCount))}
            >
              <InputNumber
                disabled={isReadonly}
                min={0}
                max={99999}
                style={{ width: "100%" }}
              />
            </Item>
          </Col>

          <Col span={5}>
            <Item
              name="attachmentsType"
              label={t(translationPath(lang.general.attachmentsType))}
            >
              <Input disabled={isReadonly} />
            </Item>
          </Col>

          <Col span={5}>
            <Item
              name="listCount"
              label={t(translationPath(lang.general.listCount))}
            >
              <InputNumber
                disabled={isReadonly}
                min={0}
                max={99999}
                style={{ width: "100%" }}
              />
            </Item>
          </Col>

          <Col span={5}>
            <Item
              name="senderSsid"
              label={t(translationPath(lang.general.senderSSID))}
            >
              <Input disabled={isReadonly} />
            </Item>
          </Col>

          <Col span={5}>
            <Item
              name="senderIdent"
              label={t(translationPath(lang.general.senderIdent))}
            >
              <Input disabled={isReadonly} />
            </Item>
          </Col>
        </Row>
        <Row gutter={6}>
          <Col span={4}>
            <Item
              name="listCountAttachments"
              label={t(translationPath(lang.general.listCountAttachments))}
            >
              <InputNumber
                disabled={isReadonly}
                min={0}
                max={99999}
                style={{ width: "100%" }}
              />
            </Item>
          </Col>
        </Row>
        <Row gutter={6}>
          <Col span={24}>
            <Item
              name="subject"
              label={t(translationPath(lang.general.subject))}
            >
              <Input disabled={isReadonly} />
            </Item>
          </Col>
        </Row>
        {senderForm}
      </Form>
    </>
  );
};
