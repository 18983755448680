import { HttpClient } from "infrastructure/service/HttpClient";
import {
  DInject,
  DInjectable
} from "presentation/core/features/dependencyInjection";
import { ContactRepository } from "../../../domain/service/codelist/ContactRepository";
import { Contact } from "../../../domain/struct/codelist/Contact";
import { ApiContacts, ContactTypeApiMapper } from "./ContactTypeApiMapper";
import { ApiUrl } from "../struct/ApiTypes";

const URL: ApiUrl = "/api/app/v1/codelists/contact-types";

@DInjectable()
export class ContactTypeApiRepository implements ContactRepository {
  constructor(@DInject(HttpClient) protected httpClient: HttpClient) {}

  findAll(): Promise<Contact[]> {
    return this.httpClient
      .fetchWithThrow<ApiContacts[]>(URL)
      .then(ContactTypeApiMapper.mapToDomain);
  }
}
